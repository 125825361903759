import React, { useState, useEffect } from 'react';
import UserWeatherChartWrapper from './UserWeatherChart.styles';
import LayoutWrapper from '@iso/components/utility/layoutWrapper.js';
import PageHeader from '@iso/components/utility/pageHeader';
import Box from '@iso/components/utility/box';
import IntlMessages from '@iso/components/utility/intlMessages';
import { configs } from '@iso/components/Tables/configs';
import { Button, Dropdown, Menu, Popconfirm, Select, Row, Col, Form } from 'antd';
import { PlusOutlined, DownOutlined, EditOutlined, PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import modalActions from '@iso/redux/modal/actions';
import NewLiveStockModal from '@iso/containers/Pages/PropertyMap/components/LiveStock/NewLiveStockModal';
import EditLiveStockModal from '@iso/containers/Pages/PropertyMap/components/Mob/EditLiveStockModal';
import AddHistoryModal from '@iso/containers/Pages/PropertyMap/components/Mob/AddHistoryModal';
import { livestockService, propertyService, activityService, weatherDataService } from '@iso/services';
import _ from 'lodash';
import { weatherTabTypes } from '@iso/constants/weatherTabTypes';
import { livestockSex } from '@iso/constants/livestockSex';
import moment from 'moment';
import Table from '@iso/components/collapsing-table/table';
import styled from "styled-components";
//import d3;
import ReactFC from "react-froalacharts";
import FroalaCharts from "froalacharts";
import FroalaTheme from "froalacharts/themes/froalacharts.theme.froala";

const { openModal } = modalActions;
ReactFC.fcRoot(FroalaCharts, FroalaTheme);
const { Option } = Select;

const UserWeatherChart = (props) => {
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const { data, primaryObjects, onSaved, firstPrimaryObjects } = props;
  const { weatherType, setWeatherType } = useState();
  const [chartConfig, setChartConfig] = useState({});
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [chartType, setChartType] = useState('rain');

  useEffect(() => {
    if (data) {
      let width = document.getElementById('weather-tab-chart').offsetWidth - 40;
      let height = Math.min(width, 450);
      setChartConfig({
        //Specify the chart type
        type: 'column',

        //Set the container object
        renderAt: 'weather_tab_data',

        //Specify chart dimensions
        width: width,
        height: height,

        //Set the type of data
        dataSource:
        {
          chart:
          {
            theme: "froala",
            paletteColors: "#f80e46 ",
            xAxisname: "Time",
            yAxisName: "Value",
            plotFillAlpha: "80",
            divLineIsDashed: "1",
            divLineDashLen: "1",
            exportEnabled: "1",
            divLineGapLen: "1",
            fillOpacity:'initial',
            numberSuffix: chartType === 'rain' ? 'mm' : "\u2103"
          },
          "categories": [{
            "category": data.label
          }],
          "dataset": [{
            "data": data.value
          }],
        }
      });
    }
  }, [data, chartType]);

  const renderOptionObject = (objects) => {
    let options = [];
    _.forEach(objects, (object, index) => {
      options.push(
        <Option key={index} value={object.id}>
          {object.name}
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select an object"
        allowClear
        size="large"
        style={{ width: "100%" }}
        onChange={handleOptionAreaClick}
      >
        {options}
      </Select>
    );
  }

  useEffect(() => {
    if (firstPrimaryObjects) {
      setFields([
        {
          name: ['weather_chart_data', 'weather_type'],
          value: 'rain'
        },
        {
          name: ['weather_chart_data', 'primary_object_id'],
          value: firstPrimaryObjects ? firstPrimaryObjects : '',
        },
      ]);
    }
  }, [firstPrimaryObjects]);

  const renderOptionWeatherTabTypes = () => {
    let options = [];
    _.forEach(weatherTabTypes, (type, index) => {
      options.push(
        <Option key={type.index} value={type.value}>
          {type.label}
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a type"
        allowClear
        size="large"
        style={{ width: "100%" }}
        defaultValue={'rain'}
        onChange={handleOptionTypeClick}
      >
        {options}
      </Select>
    );
  }

  const handleSubmit = () => {
    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  }

  const onStore = (values) => {
    weatherDataService.getDataForWeatherChart(values.weather_chart_data.primary_object_id, values.weather_chart_data.weather_type).then(res => {
      if (res.code === '0000') {
        onSaved(res.weather_data);
        setChartType(values.weather_chart_data.weather_type);
      }
    });
  }

  const handleOptionTypeClick = (value) => {
    handleSubmit();
  };

  const handleOptionAreaClick = (value) => {
    handleSubmit();
  };


  return (
    <UserWeatherChartWrapper>
      <div className='ctm-userweatherchart'>
        <Form
          form={form}
          layout="vertical"
          scrollToFirstError
          fields={fields}
        >
          <Row >
            <Col xl={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 8 }}>
              <Form.Item
                name={['weather_chart_data', 'weather_type']}
                className="isoInputWrapper"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
              >
                {renderOptionWeatherTabTypes()}
              </Form.Item>
            </Col>
            <Col xl={{ span: 8, offset: 8 }} md={{ span: 8, offset: 8 }} sm={{ span: 8, offset: 8 }} xs={{ span: 8, offset: 8 }}>
              <Form.Item
                name={['weather_chart_data', 'primary_object_id']}
                className="isoInputWrapper"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
              >
                {renderOptionObject(primaryObjects)}
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <br />
        <div id="weather_tab_data">
          <ReactFC {...chartConfig} />
        </div>
      </div>
    </UserWeatherChartWrapper>


  )
}

export default UserWeatherChart;