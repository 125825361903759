import axios from "axios";
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';


export const employeeService = {
    getList
}

function getList(propertyId = null, isAllInvitedUsers = null) {
    return (
        axios
            .get(
                `${apiEndpoint.users}?property_id=${propertyId}&is_all_invited_users=${isAllInvitedUsers}`,
                {
                    headers: authHeader(),
                }
            ).then(res => {
                if (res && res.status === 200 && res.data.code === '0000') {
                    return res.data;
                }
            })
    );
}