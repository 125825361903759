import styled from "styled-components";
import Modal from "@iso/components/Feedback/Modal";
import WithDirection from "@iso/lib/helpers/rtl";

const SARModalWrapper = styled(Modal)`
  &.user-access-modal {
    padding: 0px !important;
    margin : 0px !important;
    width: 640px !important;
  }

  .ant-modal-body {
    padding: 0;
  }
  .btn-success {
    background-color: #65bb38;
    border-color: #559f2f;
    margin-left: 10px;
  }
  .btn-cancel{
    
    background-color: rgb(63, 63, 68);
 
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    color: rgb(255, 255, 255);
    text-align: center;
    height: auto;
    border: transparent;
  }
  .btn-danger {
    background-color: #ea5252;
    border-color: #ea5252;
  }
  .form-title {
    padding: 16px 24px;
    border-bottom: 1px solid #eaeef1;
    h3 {
      font-size: 1rem;
      color: #f80e46;
    }
  }
  .form-body {
    padding: 12px 24px 12px 24px;
    .checkbox-contact {
      margin-bottom: 10px;
    }
    .footer-btn {
      display: flex;
    }
  }
  .footer-btn {
    display: flex;
    justify-content: flex-end;
  }
  ul.ant-pagination.ant-table-pagination.ant-table-pagination-right {
    margin-left: 1rem !important;
    justify-content: flex-start !important;
}
`;

export default WithDirection(SARModalWrapper);


