import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const StopDeviceAlarmRequestModalWrapper = styled(Modal)`
  .ant-modal-body{
    padding: 20;
    overflow: auto
  }
  .btn-success{
    background-color: #65bb38;
    border-color: #559f2f;
  }
  .cancel-button{
    width:100% !important;
  }
  .form-title{
    padding: 16px 24px;
    border-bottom: 1px solid #eaeef1;
    h3{
      font-size: 1rem;
      color: #729D68;
    }
  }
  .form-body{
    padding: 12px 24px 12px 24px;
  }
`;

export default WithDirection(StopDeviceAlarmRequestModalWrapper);