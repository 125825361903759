import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import Box from "@iso/components/utility/box";
import IntlMessages from "@iso/components/utility/intlMessages";
import { Button, Dropdown, Menu, Popconfirm } from "antd";
import {
    DownOutlined,
    EditOutlined,
    PlusCircleOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import modalActions from "@iso/redux/modal/actions";
import { livestockService, propertyService } from "@iso/services";
import _ from "lodash";
import { objectColors } from "@iso/constants/objectColors";
import moment from "moment";
import Table from "@iso/components/collapsing-table/table";
import styled from "styled-components";
import EditLiveStockModal from "@iso/containers/Pages/PropertyMap/components/Mob/EditLiveStockModal";
import AddHistoryModal from "@iso/containers/Pages/PropertyMap/components/Mob/AddHistoryModal";
import ManagePropertyPage from './Partial.styles'

const { openModal } = modalActions;

const LiveStockBox = (props) => {
    const [livestocks, setLivestocks] = useState([]);
    const dispatch = useDispatch();
    const activePropertyId = useSelector(
        (state) => state.property.activePropertyId
    );
    const activeProperty = localStorage.getItem("active_property");
    const activePermission = useSelector(
        (state) => state.permission.activePermission
    );

    const [liveStockData, setLiveStockData] = useState({});
    const [visibleEditLivestock, setVisibleEditLivestock] = useState(false);
    const [primaryObjects, setPrimaryObjects] = useState([]);
    const [visibleHistory, setVisibleHistory] = useState(false);
    const [viewHistory, setViewHistory] = useState(false);
    const [visibleSelectProperty, setVisiblitySelectProperty] = React.useState(
        false
    );
    const [totalPages, setTotalPages] = useState();
    const [currentPage, setCurrentPage] = useState();
    const [propertyPIC, setPropertyPIC] = useState("");

    const updatePropertyDetail = React.useCallback((id) => {
        propertyService.viewProperty(id).then((res) => {
            if (res.code === "0000") {
                setPrimaryObjects(res.primary_objects);
                setPropertyPIC(res.property.PIC);
            }
        });
    }, []);

    useEffect(() => {
        if (!activeProperty) {
            setVisiblitySelectProperty(true);
        }
    }, []);

    useEffect(() => {
        propertyService.getProperties().then((res) => {
            if (res.properties.length === 0) {
                setVisiblitySelectProperty(true);
            }
        });
    }, [activePropertyId]);

    useEffect(() => {
        if (activePropertyId) {
            updatePropertyDetail(activePropertyId);
        }
    }, [activePropertyId, updatePropertyDetail]);

    useEffect(() => {
        updateLiveStockData(1);
    }, [activePropertyId]);

    const cancelEditModal = () => {
        setVisibleEditLivestock(false);
        setViewHistory(false);
    };

    const onSaved = (savedLivestock) => {
        let tmpLivestocks = _.clone(livestocks);
        const Index = _.findIndex(tmpLivestocks, (livestock) => {
            return livestock.id === savedLivestock.id;
        });

        if (Index === -1) {
            tmpLivestocks.push(savedLivestock);
        } else {
            tmpLivestocks[Index] = savedLivestock;
        }
        setLivestocks(tmpLivestocks);
    };

    const updateLiveStockData = (page = 1) => {
        if (activePropertyId) {
            livestockService.getList(activePropertyId, page).then((res) => {
                if (res.code === "0000") {
                    setLivestocks(res.assets);
                    setTotalPages(res.total_page);
                    setCurrentPage(page);
                }
            });
        }
    };

    const menu = (mob) => (
        <Menu onClick={handleMenuClick.bind(this, mob)}>
            <Menu.Item key="editDetails" icon={<EditOutlined />}>
                <IntlMessages id="propertyPage.managePage.editDetails" />
            </Menu.Item>
            {(activePermission == "owner" ||
                activePermission == "admin" ||
                activePermission == "modify") && (
                <Menu.Item key="addHistory" icon={<PlusCircleOutlined />}>
                    <IntlMessages id="propertyPage.mobModal.historyTab.addHistory" />
                </Menu.Item>
            )}
            {(activePermission == "owner" || activePermission == "admin") && (
                <p style={{ cursor: "pointer" }} className="deleteButton">
                    <Popconfirm
                        placement="bottomRight"
                        title="You are about to delete individual record"
                        onConfirm={() => {
                            confirmDelete(mob);
                        }}
                        okText="Yes"
                        cancelText="No"
                        className="delete-button"
                    >
                        <a
                            style={{
                                color: "rgba(0, 0, 0, 0.65)",
                                marginLeft: "11px",
                            }}
                        >
                            {<DeleteOutlined />}&nbsp;&nbsp;
                            <IntlMessages id="propertyPage.managePage.delete" />
                        </a>
                    </Popconfirm>
                </p>
            )}
        </Menu>
    );
    const handleMenuClick = (livestock, e) => {
        if (e.key === "editDetails") {
            setLiveStockData(livestock);
            showEditModal();
        }

        if (e.key === "addHistory") {
            setLiveStockData(livestock);
            showAddHistoryModal();
        }
    };

    const confirmDelete = (del_livestock) => {
        if (del_livestock.id) {
            livestockService.destroy(del_livestock.id).then((res) => {
                if (res.code === "0000") {
                    let tmpLivestocks = _.clone(livestocks);
                    const Index = _.findIndex(tmpLivestocks, (livestock) => {
                        return livestock.id === res.asset.id;
                    });
                    delete tmpLivestocks[Index];
                    setLivestocks(tmpLivestocks);
                    updateLiveStockData();
                }
            });
        }
    };

    const showHistory = (livestock) => {
        setLiveStockData(livestock);
        setViewHistory(true);
        showEditModal();
    };

    const columns = [
        {
            label: <IntlMessages id="liveStockPage.table.tag_number_range" />,
            accessor: "tag_number_range",
            minWidth: 100,
            sortable: true,
            position: 1,
            priorityLevel: 1,
        },
        {
            label: <IntlMessages id="liveStockPage.table.tag_colour" />,
            accessor: "color",
            minWidth: 100,
            sortable: true,
            position: 2,
            priorityLevel: 2,
            CustomComponent: (cell) => {
                let livestock = cell.row;
                return objectColors.map((value, index) => {
                    if (value.value == livestock.tag_colour) {
                        return (
                            <div style={{ display: "inline-flex" }}>
                                <ColorItemPreview
                                    style={{ backgroundColor: value.color }}
                                ></ColorItemPreview>
                                &nbsp;&nbsp;
                                <div>{value.label}</div>
                            </div>
                        );
                    }
                });
            },
        },
        {
            label: <IntlMessages id="liveStockPage.table.NLIS" />,
            accessor: "NLIS",
            minWidth: 100,
            sortable: true,
            position: 3,
            priorityLevel: 3,
        },
        {
            label: <IntlMessages id="liveStockPage.table.date_of_birth" />,
            accessor: "date_of_birth",
            minWidth: 100,
            sortable: true,
            position: 4,
            priorityLevel: 4,
            CustomComponent: (cell) => {
                let livestock = cell.row;
                return livestock.date_of_birth
                    ? moment(livestock.date_of_birth).format("DD/MM/YYYY")
                    : livestock.month_of_birth &&
                          livestock.month_of_birth +
                              "/" +
                              livestock.year_of_birth;
            },
        },
        {
            label: <IntlMessages id="liveStockPage.table.mother" />,
            accessor: "mother",
            minWidth: 200,
            sortable: true,
            position: 5,
            priorityLevel: 5,
        },
        {
            label: <IntlMessages id="liveStockPage.table.siren" />,
            accessor: "siren",
            minWidth: 200,
            sortable: true,
            position: 6,
            priorityLevel: 6,
        },
        {
            label: <IntlMessages id="liveStockPage.table.uid" />,
            accessor: "uid",
            minWidth: 100,
            sortable: true,
            position: 7,
            priorityLevel: 7,
        },
        {
            label: <IntlMessages id="liveStockPage.table.breed" />,
            accessor: "breed",
            minWidth: 100,
            sortable: true,
            position: 8,
            priorityLevel: 8,
        },
        {
            label: <IntlMessages id="liveStockPage.table.sex" />,
            accessor: "sex_name",
            minWidth: 100,
            sortable: true,
            position: 9,
            priorityLevel: 9,
        },
        {
            label: <IntlMessages id="liveStockPage.table.lastActivity" />,
            accessor: "last_activity",
            minWidth: 100,
            sortable: true,
            position: 10,
            priorityLevel: 10,
        },
        {
            label: <IntlMessages id="liveStockPage.table.location" />,
            accessor: "primary_object",
            minWidth: 100,
            sortable: true,
            position: 11,
            priorityLevel: 11,
        },
        {
            label: "",
            accessor: "view_history",
            minWidth: 100,
            sortable: true,
            position: 12,
            priorityLevel: 12,
            noTitle: true,
            CustomComponent: (cell) => {
                let livestock = cell.row;
                return (
                    <Button
                        className="action-button view-history-button"
                        onClick={() => {
                            showHistory(livestock);
                        }}
                    >
                        <IntlMessages id="liveStockPage.table.viewHistory" />
                    </Button>
                );
            },
        },
        {
            label: "",
            accessor: "actions",
            minWidth: 100,
            sortable: true,
            position: 13,
            priorityLevel: 13,
            noTitle: true,
            CustomComponent: (cell) => {
                let livestock = cell.row;
                return (
                    <Dropdown overlay={menu(livestock)} trigger={["click"]}>
                        <Button className="btn-success ant-btn-primary">
                            <IntlMessages id="antTable.title.actions" />{" "}
                            <DownOutlined />
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    const showEditModal = () => {
        setVisibleEditLivestock(true);
    };

    const showAddHistoryModal = () => {
        setVisibleHistory(true);
    };

    return (
        <ManagePropertyPage>
        <Fragment>
            <Box id="containerBox">
                <div className="manage-property-header">
            <div className="mp-title">
              <h2 className="manage-property-title">Livestock</h2></div>
            </div>

                {props.search_result &&
                props.search_result.Asset &&
                props.search_result.Asset.length > 0 ? (
                    <div className="manage-table">
                    <Table
                        columns={columns}
                        rows={props.search_result.Asset}
                        containerId="containerBox"
                        showPagination={false}
                        rowSize={99999}
                    />
                    </div>
                ) : (
                    <h1 style={{ padding: "10px 10px 20px", fontSize: '18px' }}>
                    <IntlMessages id="dashboardPage.noData" />
                  </h1>
                )}
            </Box>
            <EditLiveStockModal
                updateLiveStockData={updateLiveStockData}
                onSaved={onSaved}
                liveStockData={liveStockData}
                propertyId={activePropertyId}
                visibleEditLivestock={visibleEditLivestock}
                cancelModal={cancelEditModal}
                primaryObjects={primaryObjects}
                viewHistory={viewHistory}
            />
        </Fragment>
        </ManagePropertyPage>
    );
};

export default LiveStockBox;

const ColorItemRow = styled.div`
    display: flex;
    align-items: center;
    line-height: 30px;
    div {
        margin: 4px;
    }
`;

const ColorItemPreview = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 50%;
`;
