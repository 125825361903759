import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Upload, message, Button, Input, Form, Table } from "antd";
import { apiEndpoint } from "@iso/lib/helpers/endpoint";
import { authHeader } from "@iso/lib/helpers/authHeader";
import SignaturePadWrapper from "./SignaturePad.style";
import SignatureComponentWrapper from "./SignatureComponent.style";
import { isDesktop, isTablet } from "react-device-detect";
import { imageService} from "@iso/services";
import {visitorFormService} from '../../services/visitorform.service'
import { commonHelper } from "@iso/lib/helpers/commonHelper";
import { PlusOutlined, DeleteFilled, SearchOutlined } from "@ant-design/icons";
import IntlMessages from "@iso/components/utility/intlMessages";
import { set } from "lodash";
import queryString from "query-string";

const SignaturePad = ({ value, onChange, initialValue = [], tableName = 'Name' }) => {
  const { dataURLtoFile, convertBase64 } = commonHelper;
  const sigCanvas = useRef();
  const uploadButton = useRef();
  const [signatures, setSignatures] = useState(value ? value.signatures : []);
  const [name, setName] = useState("");
  const [errMessage, setErrMessage] = useState();
  const [isShowModal, setIsShowModal] = useState(false);
  const [viewedSignature, setViewedSignature] = useState();
  const formToken = queryString.parse(window.location.search).token || "";
  const isUploadImageForVisitor = window.location.pathname === '/visitor-form' && formToken

  const save = () => {
    if (!name.trim()) {
      setErrMessage("This field is required");
      return;
    }
    const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    const formData = new FormData();
    formData.append("Image[file]", dataURLtoFile(URL, "signature"));
    if(isUploadImageForVisitor){
      formData.append("type", "signatures");
      formData.append("visitor_form_token", formToken);
    }

    const uploadServiceFn = isUploadImageForVisitor ? visitorFormService.uploadImage(formData) : imageService.storeOrUpdate(formData)

    uploadServiceFn.then((res) => {
      const signatureList = [
        ...signatures,
        ...[
          {
            name: name,
            imageId: res.image.id,
            path: res.image.path,
            full_path: res.image.full_path,
          },
        ],
      ];
      setSignatures(signatureList);
      onChange({ signatures: signatureList });
      setIsShowModal(false);
    });
  };

  useEffect(()=>{
    setSignatures(value ? value.signatures : [])
  }, [value])

  const uploadSignature = () => {
    uploadButton.current.click();
  };

  const handleCancel = () => {
    setIsShowModal(false);
  };

  const openModal = () => {
    setName("");
    setViewedSignature(null);
    setErrMessage(null);
    sigCanvas.current && sigCanvas.current.clear();
    setIsShowModal(true);
  };

  const uploadProps = {
    name: "Image[file]",
    action: isUploadImageForVisitor ? `${apiEndpoint.visitorForm}/upload-image` : apiEndpoint.images,
    headers: authHeader(true),
    data: {
      type: 'signatures'
    },
    beforeUpload: async (file) => {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
      }
      sigCanvas.current.fromDataURL(await convertBase64(file));
      return false;
    },
  };

  const columns = [
    {
      title: tableName,
      dataIndex: "name",
      key: "name",
      render: (text) => <div style={{ width: "90px" }}>{text}</div>,
    },
    {
      title: "",
      dataIndex: "imageId",
      key: "imageId",
      render: (imageId) => (
        <div className="signature-action-table">
          <DeleteFilled
            onClick={() => {
              removeSignature(imageId);
            }}
          />
          <SearchOutlined
            style={{ marginLeft: "10px" }}
            onClick={() => {
              viewSignature(imageId);
            }}
          />
        </div>
      ),
    },
  ];

  const removeSignature = (imageId) => {
    const newSignatureList = signatures.filter((signature) => {
      return signature.imageId != imageId;
    });
    setSignatures(newSignatureList);
    onChange({ signatures: newSignatureList });
    imageService.destroy(imageId);
    setIsShowModal(false);

  };

  const viewSignature = (imageId) => {
    const sig = signatures.filter((signature) => {
      return signature.imageId == imageId;
    });
    if (sig.length) {
      setViewedSignature(sig[0]);
      setName(sig[0]["name"]);
      setIsShowModal(true);
    }
  };

  const handleChangeName = (value) => {
    if (!value.trim()) {
      setErrMessage("This field is required");
    }
    setName(value);
  };

  const getModalFooter = () => {
    if(viewedSignature){
      return [
        <Button className="cancel-button" onClick={handleCancel}>
          <IntlMessages id="Cancel" />
        </Button>,
        <Button className="cancel-button remove-signature-btn" onClick={()=>removeSignature(viewedSignature.imageId)}>
        <IntlMessages id="Remove" />
      </Button>,
        ]
    }
    return [
      <Button className="cancel-button" onClick={handleCancel}>
        <IntlMessages id="Cancel" />
      </Button>,
      <></>,
      <Button
        className="btn-success"
        type="primary"
        onClick={() => sigCanvas.current.clear()}
      >
        <IntlMessages id="Clear Signature" />
      </Button>,
      <Button
        className="btn-success"
        type="primary"
        onClick={uploadSignature}
      >
        <IntlMessages id="Upload Signature" />
      </Button>,
      <Button className="btn-success" type="primary" onClick={save}>
        <IntlMessages id="Save" />
      </Button>,
      <div style={{ display: "none" }}>
        <Upload {...uploadProps}>
          <Button ref={uploadButton}>Upload</Button>
        </Upload>
      </div>,
    ];
  }

  return (
    <div>
      <Table
        className="signature-table"
        columns={columns}
        dataSource={signatures}
        containerId="containerBox"
        pagination={false}
      />
      <Button
        icon={<PlusOutlined />}
        type="primary"
        className="btn-success"
       
        size="large"
        onClick={openModal}
      >
        <IntlMessages id="AddSignature" />
      </Button>
      <SignaturePadWrapper
        onCancel={() => setIsShowModal(false)}
        open={isShowModal}
        maskClosable={false}
        title="Add Signature"
        footer={getModalFooter()}
      >
        <div className="modalContainer" id="signature-path">
          <div className="form-body">
            <div className="name-group">
              <label>Name*</label>
              <Input
                size="large"
                placeholder="Input your name"
                value={name}
                readOnly={viewedSignature ? true : false}
                onChange={(e) => {
                  handleChangeName(e.target.value);
                }}
              />
              {errMessage && (
                <span style={{ color: "#ff4d4f" }}>{errMessage}</span>
              )}
            </div>
            <div className="sigPadContainer">
              <label id="signature-label">Signature*</label>
              <div id="canvas-area">
                {viewedSignature ? (
                  <img className="preview-signature"
                    src={viewedSignature.full_path}
                  ></img>
                ) : (
                  <SignatureCanvas
                    penColor="black"
                    canvasProps={{
                      width: isDesktop ? 520 : isTablet ? 500 : 350,
                      height: 200,
                      className: "sigCanvas",
                      border: "1px solid #f80e46 !imortant",
                    }}
                    ref={sigCanvas}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </SignaturePadWrapper>
    </div>
  );
};

export default SignaturePad;