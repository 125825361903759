import React from 'react'
import { Button, Form } from 'antd';
import PreviewTemplateWrapper from './PreviewTemplate.styles';
import _ from 'lodash';

const PreviewFormTemplate = (props) => {
  const Template = props.template;
  const handleCancel = () => {
    props.cancelPreviewModal();
  }


    return (
      <PreviewTemplateWrapper
      width={props.type === 'bio_form' ? 1200: props.type === 'illness_form' ? 1000 : 800}
        title="Preview Form"
        open={props.isVisiblePreviewModal} 
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel} className="cancel-button" width='100%'>
            Cancel
          </Button>
        ]}
      >
        <Template  propertyId={props.propertyId}></Template>
      </PreviewTemplateWrapper>
    )
}

export default PreviewFormTemplate;