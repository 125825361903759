import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import ShareOptionsModalWrapper from './ShareOptionsModal.styles';
import { } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import _ from 'lodash';
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon
} from "react-share";

const ShareOptionsModal = (props) => {

  const cancelModal = () => {
    props.cancelShareOptionModal();
  }

  return (
    <>
    <ShareOptionsModalWrapper
      className="user-access-modal"
      forceRender={true}
      open={props.visibleShareOption}
      title={<IntlMessages id="topbar.share.title"/>}
      onCancel={cancelModal}
      footer={false}
    >
      <div className="form-body">
        <FacebookShareButton url={window.location.origin} className="share-button">
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
        <TwitterShareButton url={window.location.origin} className="share-button">
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
        <EmailShareButton url={window.location.origin} className="share-button">
          <EmailIcon size={32} round={true} />
        </EmailShareButton>
        <WhatsappShareButton url={window.location.origin} className="share-button">
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
        <FacebookMessengerShareButton url={window.location.origin} className="share-button" appId={process.env.REACT_APP_FACEBOOK_APP_ID}>
          <FacebookMessengerIcon size={32} round={true} />
        </FacebookMessengerShareButton>
      </div>
    </ShareOptionsModalWrapper>
    </>
  );
}

export default ShareOptionsModal;