import React from 'react';
import { Link } from 'react-router-dom';
import Input from '@iso/components/uielements/input';
import Button from '@iso/components/uielements/button';
import IntlMessages from '@iso/components/utility/intlMessages';
import ResetPasswordStyleWrapper from './ResetPassword.styles';
import logo from '@iso/assets/images/logo.png';
import { Form } from 'antd';
import { userService } from '@iso/services';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import Alert from '@iso/components/Feedback/Alert';

const ResetPassword  = (props) => {
  const [msgAlert, setMsgAlert] = React.useState("");
  const [typeAlert, setTypeAlert] = React.useState("");
  const [form] = Form.useForm();

  const marginBot = {
    marginBottom: '10px',
  };

  const token = queryString.parse(props.location.search).token || '';

  const onFinish = (values) => {
    userService.resetPassword(values.email, token, values.password).then(res => {
      if(res.code === '0000'){
        setTypeAlert('success');
        setMsgAlert('Your password has been updated');
      } else {
        setTypeAlert('error');
        setMsgAlert(res.message);
      }
      form.resetFields();
    });
    setTimeout(() => {
      setMsgAlert("");
    }, 5000);
  };

  return (
    <ResetPasswordStyleWrapper className="isoResetPassPage">
      <div className="isoFormContentWrapper">
        <div className="isoFormContent">
          <div className="isoLogoWrapper">
            <Link to="/dashboard">
              <img src={logo} height="40"/>
            </Link>
          </div>

          <div className="isoFormHeadText">
            <h3>
              <IntlMessages id="page.resetPassSubTitle" />
            </h3>
            <p>
              <IntlMessages id="page.resetPassDescription" />
            </p>
          </div>

          <div className="isoResetPassForm">
            {
              msgAlert && 
              <Alert
                message={msgAlert}
                type={typeAlert}
                style={marginBot}
              />
            }
            <Form
              form={form}
              name="register"
              onFinish={onFinish}
              scrollToFirstError
            >
              <Form.Item
                name="email"
                className="isoInputWrapper"
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid Email!',
                  },
                  {
                    required: true,
                    message: 'Please input your Email!',
                  },
                ]}
              >
                <Input size="large" placeholder="Email"/>
              </Form.Item>

              <Form.Item
                name="password"
                className="isoInputWrapper"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Password!',
                  },
                  {
                    min: 6,
                    message: 'Password must be minimum 6 characters.'
                  }
                ]}
              >
                <Input type="password" size="large" placeholder="New Password"/>
              </Form.Item>
              {/*
              <Form.Item
                name="password_confirmation"
                className="isoInputWrapper"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: 'Please input your Confirm Password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject('The two passwords that you entered do not match!');
                    },
                  }),
                ]}
              >
                <Input type="password" size="large" placeholder="Confirm Password"/>
              </Form.Item>
              */}
              <div className="isoInputWrapper">
                <Button type="primary" htmlType="submit" className="btn-success">
                  <IntlMessages id="page.resetPassSave" />
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </ResetPasswordStyleWrapper>
  );
}

export default withRouter(ResetPassword);