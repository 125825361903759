import React, { useEffect, useLayoutEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import LayoutWrapper from "@iso/components/utility/layoutWrapper.js";
import Box from "@iso/components/utility/box";
import PageHeader from "@iso/components/utility/pageHeader";
import IntlMessages from "@iso/components/utility/intlMessages";
import { useSelector, useDispatch } from "react-redux";
import { configs } from "@iso/components/Tables/configs";
import {
  Button,
  Dropdown,
  Menu,
  Popover,
  Select,
  Popconfirm,
  Pagination,
  message,
  Input,
  Space,
  Spin,
} from "antd";
// import PropertyManagePage from "./Property.styles";
import {
  SearchOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  PlusOutlined,
  EditOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  EyeOutlined,
  PlusCircleOutlined,
  SettingOutlined,
  FundViewOutlined,
  EllipsisOutlined,
  HistoryOutlined,
  UpCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import propertyActions from "@iso/redux/property/actions";
import PropertyModal from "./PropertyModal";
import SelectPropertyModal from "./SelectPropertyModal";
import UserAccessModal from "./UserAccessModal";
import PropertyTableFirstCell from "./PropertyTableFirstCell";
import _, { property } from "lodash";
import Table from "@iso/components/collapsing-table/table";
import { propertyService, permissionService } from "@iso/services";
import SubscriptionModal from "./SubscriptionModal";
import { subscriptionPlan } from "@iso/constants/subscriptionPlan";
import FormModal from "@iso/containers/Pages/Form/FormModal";
import PropertyHistoryModal from "./PropertyHistoryModal";
import ImportModal from "./ImportModal";
import OptiWeightModal from "./OptiWeightModal";
import IotDeviceModal from "./IotDeviceModal";
import ManagePropertyPage from "../Property/ManageProperty/ManageProperty.styles";
import "../Property/ManageProperty/ManageProperty.css";
import "../../../components/Paginations.css";
import Loader from "../../../components/collapsing-table/components/Loader";
const { changeActiveProperty } = propertyActions;

const Property = (props) => {
  console.log("property",props);
  let history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const activePropertyId = useSelector(
    (state) => state.property.activePropertyId
  );
  console.log("activePropertyId",activePropertyId);
  const activePropertySubscription = useSelector(
    (state) => state.property.activePropertySubscription
  );
  const activePermission = useSelector((state) => state.permission);
  const [visible, setVisiblity] = React.useState(false);
  // const activeProperty=localStorage.getItem( "active_property" );

  const [visibleUserAccess, setVisibleUserAccess] = React.useState(false);
  const [editProperty, setEditProperty] = React.useState({});
  const activeProperty = JSON.parse(localStorage.getItem("active_property"));

  const [property, setProperty] = React.useState({});
  const { Option } = Select;
  const [properties, setProperties] = React.useState([]);
  const [isSaved, setIsSaved] = React.useState(false);
  const [visibleSelectProperty, setVisiblitySelectProperty] =
    React.useState(false);
  const [visibleFormModal, setVisiblityFormModal] = React.useState(false);
  const [visibleHistoryModal, setVisiblityHistoryModal] = React.useState(false);
  const [propertyHistory, setPropertyHistory] = React.useState({});
  const [visibleImportModal, setVisibleImportModal] = React.useState(false);
  const [visibleOptiWeight, setVisibleOptiWeight] = React.useState(false);
  const [visibleIotDevice, setVisibleIotDevice] = React.useState(false);
  const [isOkButton, setIsOkButton] = React.useState(true);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [visibleUpgradeModal, setVisibleUpgradeModal] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);

  const [pageSize, setPageSize] = React.useState(10);
  const [loadingData, setLoadingData] = useState(false);
  const [searchQuery, setSearchQuery] = React.useState();
  const [searchInput, setsearchInput] = React.useState("");

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const displayedproperties = searchQuery?.slice(startIndex, endIndex);

  const handlePageSizeChange = (current, size) => {
    setCurrentPage(1);
    setPageSize(size);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setsearchInput(e.target.value);

    // Filter the employee data based on the search query
    const filteredEmployee = properties.filter((employee) => {
      return (
        (employee.name &&
          employee.name.toLowerCase().includes(searchInput.toLowerCase())) ||
        (employee.address &&
          employee.address.toLowerCase().includes(searchInput.toLowerCase())) ||
        (employee.subscription_label &&
          employee.subscription_label
            .toLowerCase()
            .includes(searchInput.toLowerCase()))
      );
    });
    setSearchQuery(filteredEmployee);
  };

  const handleCancelSearch = () => {
    // Clear the search input and reset the employee data
    setsearchInput("");
    setSearchQuery(properties);
    // getEmployee();
  };
  useEffect(() => {
    propertyService.getProperties().then((res) => {
      if (res.properties.length === 0) {
        if (!visibleSelectProperty) {
          setVisiblitySelectProperty(true);
        }
      } else {
        setProperties(res.properties);
        setSearchQuery(res.properties);
        if (res.properties.length === 1) {
          localStorage.setItem(
            "active_property",
            JSON.stringify(res.properties[0])
          );
          dispatch(changeActiveProperty(res.properties[0]));
        }
      }
    });
  }, [activePropertyId, isSaved, activePropertySubscription]);

  useEffect(() => {
    getProperties();
  }, [activePropertyId]);
  const getProperties = () => {
    setLoadingData(true);
    propertyService.getProperties().then((res) => {
      if (res.code === "0000") {
        setProperties(res.properties);
        setSearchQuery(res.properties);
        setLoadingData(false);
      }
    });
  };
  // const getDeviceTime = () =>{
  //   propertyService.getDeviceTime().then( (res) =>{
  //     {
  //       if ( res.code==="0000" )
  //       {
  //         console.log("res",res);
  //       }
  //     }
  //   })
  // }
  useEffect(() => {
    if (!activeProperty && !visibleSelectProperty) {
      setVisiblitySelectProperty(true);
    }
  }, []);

  const menu = (property) => (
    <Menu onClick={handleMenuClick.bind(this, property)}>
      <Menu.Item key="History" icon={<HistoryOutlined />}>
        <IntlMessages id="History" />
      </Menu.Item>
      {activePropertyId != property.id && (
        <Menu.Item key="editStatus" icon={<CheckCircleOutlined />}>
          <IntlMessages id="propertyPage.managePage.active" />
        </Menu.Item>
      )}
      {property.subscription_label !==
        process.env.REACT_APP_STRIPE_PLAN_GOLD && (
        <>
          <Menu.Item key="editDetails" icon={<EditOutlined />}>
            <IntlMessages id="Edit Details" />
          </Menu.Item>

          <Menu.Item key="userAccess" icon={<UserOutlined />}>
            <IntlMessages id="UserAccess" />
          </Menu.Item>
        </>
      )}
      {property.subscription_label ===
        process.env.REACT_APP_STRIPE_PLAN_GOLD && (
        <>
          <Menu.Item key="import" icon={<PlusOutlined />}>
            <IntlMessages id="mobPage.managePage.import" />
          </Menu.Item>
          <Menu.Item key="optiWeight" icon={<SettingOutlined />}>
            <IntlMessages id="antTable.optiWeight" />
          </Menu.Item>
        </>
      )}
      {activePropertyId != property.id && (
        <Menu.Item key="addForm" icon={<PlusCircleOutlined />}>
          <IntlMessages id="Add Form" />
        </Menu.Item>
      )}
      <Menu.Item key="viewForm" icon={<EyeOutlined />}>
        <IntlMessages id="View Form" />
      </Menu.Item>
      <Menu.Item key="upgrade" icon={<UpCircleOutlined />}>
        <IntlMessages id="Subscription upgrade" />
      </Menu.Item>
      <Menu.Item key="viewTiers" icon={<QuestionCircleOutlined />}>
        <IntlMessages id="View Tiers" />
      </Menu.Item>
      {property.stripe_id && (
        <Menu.Item key="manageSubscription" icon={<FundViewOutlined />}>
          <IntlMessages id="antTable.manageSubscription" />
        </Menu.Item>
      )}
      {property.subscription_label ===
        process.env.REACT_APP_STRIPE_PLAN_GOLD && (
        <Menu.Item key="iotDevice" icon={<SettingOutlined />}>
          <IntlMessages id="IOT Device" />
        </Menu.Item>
      )}
      <p style={{ cursor: "pointer" }} className="deleteButton">
        <Popconfirm
          placement="bottomRight"
          title="You are about to delete individual record"
          onConfirm={() => {
            confirmDelete(property);
          }}
          okText="Yes"
          cancelText="No"
          className="delete-button"
        >
          <a style={{ color: "rgba(0, 0, 0, 0.65)", marginLeft: "11px" }}>
            {<DeleteOutlined />}&nbsp;&nbsp;
            <IntlMessages id="propertyPage.managePage.delete" />
          </a>
        </Popconfirm>
      </p>
    </Menu>
  );
  const confirmDelete = (property) => {
    if (property.id) {
      propertyService.destroy(property.id).then((res) => {
        if (res.code === "0000") {
          getProperties();
        }
      });
    }
  };

  const handleMenuClick = (property, e) => {
    if (e.key === "editStatus") {
      localStorage.setItem("active_property", JSON.stringify(property));
      dispatch(changeActiveProperty(property));
      propertyService.active(property.id).then((res) => {
        if (res.code == "0000") {
          console.log("Success");
        }
      });
    }

    if (e.key === "History") {
      showHistory(property);
    }
    if (e.key === "editDetails") {
      setVisiblity(true);
      setEditProperty(property);
    }
    if (e.key === "userAccess") {
      setVisibleUserAccess(true);

      setProperty(property);
    }

    if (e.key === "upgrade") {
      setProperty(property);
      setVisibleUpgradeModal(true);
    }

    if (e.key === "viewTiers") {
      window.open("https://www.airagri.com.au/subscription-pricing-plans/");
    }

    if (e.key === "viewForm") {
      history.push("/manage-forms");
    }

    if (e.key === "addForm") {
      setProperty(property);
      setVisiblityFormModal(true);
    }

    if (e.key == "import") {
      showImportModal();
      setProperty(property);
    }
    if (e.key == "optiWeight") {
      setProperty(property);
      setVisibleOptiWeight(true);
    }
    if (e.key == "iotDevice") {
      setProperty(property);
      setVisibleIotDevice(true);
    }
    if (e.key == "manageSubscription") {
      propertyService.getStripePortalUrl(property.id).then((res) => {
        if (res.code === "0000") {
          window.open(res.url, "_blank");
        } else {
          message.error(res.message);
        }
      });
    }
  };

  const showModal = () => {
    setVisiblity(true);
  };

  const cancelModal = () => {
    setVisiblity(false);
    setEditProperty({});
  };

  const cancelSelectPropertyModal = () => {
    setVisiblitySelectProperty(false);

    if (location.state?.visible) {
      setVisiblity(true);
    }
  };

  const cancelUserAccessModal = () => {
    setVisibleUserAccess(false);
    setProperty({});
  };

  const cancelUpgradeModal = () => {
    setVisibleUpgradeModal(false);
    setProperty({});
  };

  const showHistory = (property) => {
    setPropertyHistory(property);
    setVisiblityHistoryModal(true);
  };

  const cancelHistory = () => {
    setPropertyHistory({});
    setVisiblityHistoryModal(false);
  };

  const columns = [
    {
      label: <IntlMessages id="antTable.title.property" />,
      accessor: "name",
      minWidth: 100,
      sortable: true,
      position: 1,
      priorityLevel: 1,
      CustomComponent: (cell) => {
        return (
          <PropertyTableFirstCell
            activePropertyId={activePropertyId}
            property={cell.row}
          />
        );
      },
    },
    {
      label: <IntlMessages id="antTable.title.address" />,
      accessor: "address",
      minWidth: 300,
      sortable: true,
      position: 2,
      priorityLevel: 2,
    },
    {
      label: <IntlMessages id="antTable.title.subscription_tier" />,
      accessor: "subscription_label",
      minWidth: 300,
      sortable: true,
      position: 3,
      priorityLevel: 3,
      CustomComponent: (cell) => {
        let row = cell.row;
        if (row.subscription_label) {
          return subscriptionPlan.map((value, index) => {
            if (value.value == row.subscription_label) {
              return <div key={index}>{value.label}</div>;
            }
          });
        } else {
          return (
            <div>
              {
                <IntlMessages id="subscription.prices.REACT_APP_STRIPE_PLAN_BRONZE" />
              }
            </div>
          );
        }
      },
    },

    {
      label: "",
      accessor: "dot-menu",
      minWidth: 100,
      sortable: true,
      position: 4,
      priorityLevel: 4,
      noTitle: true,
      CustomComponent: (cell) => {
        let row = cell.row;
        let permission = permissionService.getPermission(row.permission_id);
        return (
          <Dropdown overlay={menu(row, permission)} trigger={["click"]}>
            <div
              className="property-area-dot-btn"
              style={{ cursor: "pointer" }}
            >
              <EllipsisOutlined className="dotIcon" />
            </div>
          </Dropdown>
        );
      },
    },
  ];

  const handleOptionClick = (value) => {
    _.forEach(properties, (property) => {
      if (property?.id == value) {
        localStorage.setItem("active_property", JSON.stringify(property));
        dispatch(changeActiveProperty(property));
      }
    });
  };

  const renderOptionsProperty = () => {
    let options = [];
    _.forEach(properties, (property, index) => {
      options.push(
        <Option key={index} value={property?.id} className="property-option">
          {property?.name}
        </Option>
      );
    });

    return (
      <Select
        placeholder="Select Active Property"
        value={activeProperty?.name}
        size="large"
        style={{ width: 150 }}
        onChange={handleOptionClick}
      >
        {options}
      </Select>
    );
  };

  const onSaved = () => {
    setIsSaved(!isSaved);
  };

  const cancelFormModal = () => {
    setVisiblityFormModal(false);
  };

  const showImportModal = () => {
    setVisibleImportModal(true);
  };

  const cancelImportModal = () => {
    setVisibleImportModal(false);
    setProperty({});
  };
  const cancelOptiWeight = () => {
    setVisibleOptiWeight(false);
    setProperty({});
  };
  const cancelIotDevice = () => {
    setVisibleIotDevice(false);
    setProperty({});
  };
  const handleSort = (column) => {
    if (column.sortable) {
      if (sortedColumn === column.accessor) {
        setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      } else {
        setSortedColumn(column.accessor);
        setSortDirection("asc");
      }
    }
  };

  return (
    <LayoutWrapper>
      <ManagePropertyPage>
        <Box id="containerBox">
          <div className="manage-property-header">
            <div className="mp-title">
              <h2 className="manage-property-title">Manage Properties</h2>
            </div>
            <div className="button-group" style={{ gap: "12px" }}>
              <Input
                placeholder="Search…"
                suffix={
                  !searchInput && (
                    <SearchOutlined className="site-form-item-icon" />
                  )
                }
                prefix={
                  searchInput && (
                    <CloseCircleOutlined
                      onClick={handleCancelSearch}
                      className="site-form-item-cancel"
                    />
                  )
                }
                size="large"
                className="search-bar"
                value={searchInput}
                onChange={(e) => handleSearch(e)}
              />
              {renderOptionsProperty()}
              <Button
                icon={<PlusOutlined />}
                type="primary"
                className=""
                onClick={showModal}
                size="large"
                style={{
                  background: "rgb(248, 14, 70)",
                  borderRadius: "12px !important",
                }}
              >
                <IntlMessages id="propertyPage.managePage.addProperty" />
              </Button>
            </div>
          </div>
          {props.search_result &&
          props.search_result.Property &&
          props.search_result.Property.length > 0 ? (
            <div className="manage-table">
              <Table
                columns={columns}
                rows={props.search_result.Property}
                containerId="containerBox"
                showPagination={false}
                rowSize={99999}
              />
            </div>
          ) : properties.length > 0 ? (
            <>
              {loadingData ? (
                <Loader />
              ) : (
                <div className="manage-table">
                  <Table
                    columns={columns}
                    rows={displayedproperties}
                    containerId="containerBox"
                    showPagination={false}
                    rowSize="99999"
                  />
                  <div style={{ margin: "20px" }}>
                    <Pagination
                      current={currentPage}
                      total={searchQuery?.length}
                      pageSize={pageSize}
                      onChange={handlePageChange}
                      showSizeChanger
                      pageSizeOptions={["10", "20", "50", "100"]}
                      onShowSizeChange={handlePageSizeChange}
                    />
                  </div>
                </div>
              )}
            </>
          ) : (
            <h1 style={{ padding: "10px 10px 20px", fontSize: "18px" }}>
              <IntlMessages id="dashboardPage.noData" />
            </h1>
          )}
        </Box>
        <PropertyModal
          editProperty={editProperty}
          open={visible}
          cancelModal={cancelModal}
          onSaved={onSaved}
          getProperties={getProperties}
        />
        <UserAccessModal
          open={visibleUserAccess}
          propertyId={property.id}
          cancelModal={cancelUserAccessModal}
        />
        <SelectPropertyModal
          isOk={isOkButton}
          activeProperty={activeProperty}
          propertyPage={true}
          visibleSelectProperty={visibleSelectProperty}
          cancelSelectPropertyModal={cancelSelectPropertyModal}
        />
        <SubscriptionModal
          open={visibleUpgradeModal}
          property={property}
          cancelModal={cancelUpgradeModal}
          onSaved={onSaved}
        />

        <FormModal
          visibleFormModal={visibleFormModal}
          cancelModal={cancelFormModal}
          propertyId={property.id}
          fromProperty={true}
        />

        <PropertyHistoryModal
          visibleHistoryModal={visibleHistoryModal}
          cancelHistory={cancelHistory}
          propertyId={propertyHistory.id}
        />
        <ImportModal
          visibleImportModal={visibleImportModal}
          cancelImportModal={cancelImportModal}
          property={property}
        />
        <OptiWeightModal
          visibleOptiWeight={visibleOptiWeight}
          cancelOptiWeight={cancelOptiWeight}
          property={property}
        />
        <IotDeviceModal
          visibleIotDevice={visibleIotDevice}
          cancelIotDevice={cancelIotDevice}
          onSaved={onSaved}
          property={property}
          getProperties={getProperties}
        />
      </ManagePropertyPage>
    </LayoutWrapper>
  );
};

export default Property;
