import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const PreviewModalWrapper = styled(Modal)`
  .ant-modal-body{
    padding: 0;
  }
 
  .form-title{
    padding: 16px 24px;
    border-bottom: 1px solid #eaeef1;
    h3{
      font-size: 1.6rem;
      color: #f80e46;
    }
  }
  .form-body{
    padding: 20px 40px 20px 40px;
    #form-step {
      margin-top: 20px;
      margin-bottom: 60px;
      span.ant-steps-icon{
        color:white;
      }
      .ant-steps-item-icon {
        color:white;
        background-color: #f80e46;
    border-color: #f80e46;
      }
    }
    .antd-form-builder-question-icon{
      fill: red;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    }
    .ant-form-item-label {
      max-width: 100% !important;
      label{
        font-weight: 500;
        font-size: 16px
      }
    }
    .ant-steps-item-title::after{
      background-color:rgb(63, 63, 68) !important;
    }
    .ant-form-item-row{
      margin-bottom: 40px;
    }
  }
  #form-image{
    width: 50%;
  }
  .btn-back{
    margin-right: 10px;
  }
  .cancel-btn{
    item-align: right !important;
    width:20% !important;
    color: white !important;
    background: rgb(63, 63, 68) !important;
  }
  .btn-back:hover{
    border-color: red;
    text-decoration: none;
    color: red;
  }
  .btn-success{
    color:white;
  border-color: #f80e46 !important;
  margin-right: 10px !important;
  margin-buttom:15px;
  background-color: #f80e46 !important;
  padding: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  :hover{
    opacity:1;
  }
}
`;

export default WithDirection(PreviewModalWrapper);