import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import QualificationModalWrapper from './QualificationModal.styles';
import { Form, Input, Button, Select, Row, Col, DatePicker, Upload } from 'antd';
import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import modalActions from '@iso/redux/modal/actions';
import Loader from '@iso/components/utility/loader';
import { qualificationService } from '@iso/services';
import moment from 'moment';
import { qualificationTypes } from '@iso/constants/qualificationTypes';
import _ from 'lodash';
import styled from "styled-components";
import { dateHelper } from '@iso/lib/helpers/dateHelper';
import L from 'leaflet';
import inside from 'point-in-polygon';

const { Option } = Select;
const { closeModal } = modalActions;

const QualificationModal = (props) => {

  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [dataQualification, setDataQualification] = useState({});
  const dispatch = useDispatch();
  const modalVisibility = useSelector((state) => state.modal.modalVisibility);
  const modalData = useSelector((state) => state.modal.modalData);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector((state) => state.user.user);
  const [completion, setCompletion] = useState([]);
 
  useEffect(() => {
    setDataQualification(props.qualificationData);
  }, [props.qualificationData, props.visibleQualification]);

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
    setLoading(false);
  }

  const onStore = (values) => {
    const data = {
      "qualification":{
        ...values.qualification,
        completion: values.qualification.completion ? dateHelper.dateForAPI(values.qualification.completion) : '',
        date_expiry: values.qualification.date_expiry ? dateHelper.dateForAPI(values.qualification.date_expiry) : '',
        user_id: currentUser.id,
      }
    }

    qualificationService.storeOrUpdate(data, dataQualification.id).then(res => {
      if(res.code === '0000'){
        props.onSaved();
        props.cancelModal();
        form.resetFields();
      } else {
        setLoading(false);
      }
    });
  }
  const cancelQualificationModal = () => {
    if (props.viewSummary) {
      props.setViewSummary(false);
    }else{
    props.cancelModal();
    form.resetFields();
    }
  } 

  useEffect(() => {
      setFields([
        {
          name: ['qualification', 'type'],
          value: dataQualification.type || 'trade',
        },
        {
          name: ['qualification', 'name'],
          value: dataQualification.name || '',
        },
        {
          name: ['qualification', 'completion'],
          value: dataQualification.completion ? moment(dataQualification.completion) : '',
        },
        {
          name: ['qualification', 'institution'],
          value: dataQualification.institution || '',
        },
        {
          name: ['qualification', 'date_expiry'],
          value: dataQualification.date_expiry ? moment(dataQualification.date_expiry) : '',
        },
      ]);
    
  }, [dataQualification, props.visibleQualification]);

  const renderOptionsQualificationTypes = () => {
    let options = [];
    _.forEach(qualificationTypes, (qualification, index) => {
      options.push(
        <Option key={index} value={qualification.value}>
            <div>{qualification.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a qualification type"
        {...props.viewSummary ?{disabled:true}:{disabled:false}}
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const disabledDate = (current) => {
    return current && current < completion;
  }

  return(
    <>
    <QualificationModalWrapper 
      open={props.viewSummary ? props.viewSummary: props.visibleQualification}
      onCancel={cancelQualificationModal}
      maskClosable={true}
      centered
      title={props.title}
      
    
      footer= {[   
        <Button key="back" onClick={cancelQualificationModal} hidden ={props.viewSummary ? true:false} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary"  hidden ={props.viewSummary ? true:false} onClick={handleSubmit} loading={loading}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    
    
    >
  
      <Form
          form={form}
          layout="vertical"
          scrollToFirstError
          fields={fields}
      >
        <div className="form-body">
          <Form.Item
            name={['qualification', 'type']}
            className="isoInputWrapper"
            label="Qualification Type"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            {renderOptionsQualificationTypes()}
          </Form.Item>
          <Form.Item
            name={['qualification', 'name']}
            className="isoInputWrapper"
          
            rules={[
              {
                required: true,
                message: 'This field is required',
                whitespace: true,
              },
              {
                max:255,
                message: 'Name must be maximum 255 characters.'
              },
            ]}
          >
            <Input size="large"  {...props.viewSummary ?{readOnly:true}:{readOnly:false}} placeholder="Input qualification name"/>
          </Form.Item>
          <Form.Item
            name={['qualification', 'completion']}
            className="isoInputWrapper"
          
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <DatePicker size="large" style={{width: '100%'}}   {...props.viewSummary ?{disabled:true}:{disabled:false}} onChange={value => {setCompletion(value);}}/>
          </Form.Item>
          <Form.Item
            name={['qualification', 'institution']}
            className="isoInputWrapper"
           
            rules={[
              {
                required: true,
                message: 'This field is required',
                whitespace: true
              },
              {
                max:255,
                message: 'Institution must be maximum 255 characters.'
              },
            ]}
          >
            <Input size="large"  {...props.viewSummary ?{readOnly:true}:{readOnly:false}} placeholder="Input institution"/>
          </Form.Item>
          <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return (prevValues['qualification'] && (prevValues['qualification'].type !== currentValues['qualification'].type));
              }}
          >
          {({ getFieldValue }) => {
            if(getFieldValue('qualification')){
              if (getFieldValue('qualification').type === "first_aid"){
                return (
                  <Form.Item
                    name={['qualification', 'date_expiry']}
                    className="isoInputWrapper"
                    
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (value && value >= getFieldValue('qualification').completion) {
                            return Promise.resolve();
                          }
                          return Promise.reject('Date Expiry is not lower than Completion!');
                        },
                      }),
                    ]}
                  >
                    <DatePicker size="large"  {...props.viewSummary ?{disabled:true}:{disabled:false}} style={{width: '100%'}} disabledDate={disabledDate}/>
                  </Form.Item>
                )
              }else{
                return '';
              }
            }
          }}
          </Form.Item>
          </div>    
    </Form>
    
    </QualificationModalWrapper>
    </>
  )
}

export default QualificationModal;