import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const PersonalWrapper = styled.div`
  &.user-access-modal{
    width: 100%!important;
  }
  .ant-modal-body{
    padding: 0;
  }
  .btn-success{
    background-color: #65bb38;
    border-color: #559f2f;    
  }
  .form-title{
    padding: 16px 24px;
    border-bottom: 1px solid #eaeef1;
    h3{
      font-size: 2rem;
    }
  }
  .ant-form-item-control-input-content .inputFieldCstm{
    border: none;
    border-radius: 8px !important;
    background: #F7F7F7 !important;
    width: 100% !important;
    height: 56px !important;
  }
  .ant-form-item-control-input-content .inputFieldCstm:focus {
    border-color: red !important; 
    box-shadow :rgb(248, 14, 70)  0px 0px 0px 0.5px !important; 
  }

  .ant-checkbox-checked .ant-checkbox-inner  {
    border-color: rgb(248, 14, 70) !important;
    background-color: rgb(248, 14, 70) !important;
  }
  span.ant-checkbox-inner{
    border-color: rgb(248, 14, 70) !important;
    background-color: white !important;
    
  }
height: normal !important;
  }
  .ant-picker.ant-picker-large {
    border-radius: 8px !important;
    background: #F7F7F7 !important;
    border: none !important;
    width: 100% !important;
    height: 56px !important;
}

  .form-body{
    padding: 12px 24px 12px 24px;
    .checkbox-contact{
      margin-bottom: 10px;
    }
    .changePassword{
      padding : 10px 10px 10px 0px
      
    }
    .PassInfo{
      color: var(--Primary-Color,rgb(248, 14, 70));
font-family: Work Sans;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
    }
    .shareToCommunity{
      margin-bottom : 10px;
      color: var(--gray-700, #344054);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
    }
    .contCheckBox{
      margin-left : 8px;
      color: var(--gray-700, #344054);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 166.667% */
    }
  }
  .profile-area{
    background-color:white;
    width:50%;
  }
  
  .uploadImg{
   margin: 40px 0px 40px 20px;
width: 170px;
height: 40px;
padding: 8px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius:  8px !important;;
border-color : rgb(248, 14, 70) !important;
background: var(--Primary-Color,rgb(248, 14, 70));
color: white;
  }
  .uploadAvatar{
    margin: 40px 0px 40px 20px;
 width: 170px;
 height: 40px;
 padding: 8px;
 justify-content: center;
 align-items: center;
 gap: 10px;
 border-radius:  8px !important;;
 border-color : rgb(248, 14, 70) !important;
 background: var(--Primary-Color,rgb(248, 14, 70));
 color: white;
   }
  .ant-select-selector .workingRights.{
    border: 1px solid #E2E8F0 !important;
  }
  .DltImg{
    margin: 40px 0px 40px 20px;
 width: 170px;
 display: flex;
width: 110px;
height: 40px;
padding: 8px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 8px !important;
border: 1px solid #E2E8F0 !important;
background: var(--others-white, #FFF) !important;
   }
   .DltImg:hover{
    color:rgb(248, 14, 70) !important;
    border-color : rgb(248, 14, 70) !important;
   }
  .profile-image{
    width: 105px;
    height: 112px;
    flex-shrink: 0;
    border-radius: 112px;
background: url(<path-to-image>), lightgray 50% / cover no-repeat;
  }

  input:focus {
    box-shadow:rgb(248, 14, 70) !important; 
  }

  @media only screen and (width: 1024px) {
    .uploadAvatar{
      width:150px !important;
      margin: 60px 0px 30px 60px;
      
    }
    .uploadImg{
      width:140px !important;
      margin: 60px 0px 30px 10px;
      padding:0px;
      
    }
    .DltImg{
      margin: 60px 0px 30px 10px;
      width:120px !important;
    }
    .profile-image{
      margin-right:10px !important;

    }
  }

  @media only screen and (width: 768px) {
   
    .uploadAvatar{
      width:100px !important;
      margin: 60px 0px 30px 60px;
      
    }
    .uploadImg{
      width:140px !important;
      margin: 60px 0px 30px 10px;
      padding:0px;
      
    }
    .DltImg{
      margin: 60px 0px 30px 10px;
      width:100px !important;
    }
    .profile-image{
      margin-right:10px !important;
    
    }
  }
  @media only screen and (width: 425px) {
   
    
    .uploadAvatar{
      
      margin: 30px 0px 0px 0px;
      width: 280px;
    }
    .uploadImg{
      margin: 10px 0px 0px 0px;
      width: 280px;
      
    }
    .DltImg{
      margin: 10px 0px 0px 0px;
      width: 280px;
    }
    .profile-image{
      margin-left:60px;
    
    }
  }
  @media only screen and (width: 375px) {
    .form-title{
      padding: 16px 40px;
      border-bottom: 1px solid #eaeef1;
      h3{
        font-size: 2rem;
      }
    }
    
    .uploadAvatar{
      
      margin: 30px 0px 0px 0px;
      width: 230px;
    }
    .uploadImg{
      margin: 10px 0px 0px 0px;
      width: 230px;
      
    }
    .DltImg{
      margin: 10px 0px 0px 0px;
      width: 230px;
    }
    .profile-image{
      margin-left:40px;
    
    }
  }
  @media only screen and (width: 320px) {
    padding-top:0px;
    .form-title{
      padding: 16px 40px;
      border-bottom: 1px solid #eaeef1;
      h3{
        font-size: 1.5rem;
      }
    }
    
    .uploadAvatar{
      
      margin: 30px 0px 0px 0px;
      width: 190px;
    }
    .uploadImg{
      margin: 10px 0px 0px 0px;
      width: 190px;
      
    }
    .DltImg{
      margin: 10px 0px 0px 0px;
      width: 190px;
    }
    .profile-image{
      margin-left:15px;
    
    }
  }
 
  
`;

export default WithDirection(PersonalWrapper);