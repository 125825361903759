import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Form, Input, Button, Select, Row, Col, Upload, message,Switch,InputNumber} from 'antd';
import { CloseOutlined, PlusOutlined, UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { optiWeight } from '@iso/services';
import OptiWeightModalWrapper from './OptiWeightModal.styles';
import _ from 'lodash';
import $ from 'jquery';
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';


const { Option } = Select;
const { Dragger } = Upload;

const OptiWeightModal = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);

   const getDataOptiWeight = (property_id) => {
    if(property_id){
       optiWeight.getData(property_id).then(res => {
        if(res.code === '0000'){
          setFields([
            {
              name: ['opti_weight', 'is_active'],
              value: res.opti_weight ? res.opti_weight.is_active : 0
            }, 
            {
              name: ['opti_weight', 'api_key'],
              value: res.opti_weight ? res.opti_weight.api_key : ''
            },
            {
              name: ['opti_weight', 'client_id'],
              value: res.opti_weight  ? res.opti_weight.client_id : '1'
            },
          ]);
        }
      });
    }
  }
  useEffect(() => {
    getDataOptiWeight(props.property.id);
  }, [props.property]);


  const handleSubmit = () =>{
    setLoading(true);

    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });

  }
  const onStore = async (values) =>{
    const optiWeightData  = await optiWeight.getData(props.property.id);
    const data = {
      opti_weight: {
        ...values.opti_weight,
        is_active: values.opti_weight.is_active && values.opti_weight.is_active == true ? 1 : 0,
        property_id: props.property.id
      }
    }
    optiWeight.storeOrUpdate(data, optiWeightData.opti_weight ? optiWeightData.opti_weight.id : null).then(res => {
      if(res.code === '0000'){
        cancelModal();
        form.resetFields();
      } else {
        setLoading(false);
      }
    });
   
    
  }

  const cancelModal = () => {
    props.cancelOptiWeight();
    form.resetFields();
    setLoading(false);
  }
  return (
    <OptiWeightModalWrapper
      open={props.visibleOptiWeight}
      onCancel={cancelModal}
      title="Opti Weight"
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit} loading={loading}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
      <Form
        form={form}
        scrollToFirstError
        layout="vertical"
        fields={fields}
        >
         <div className="form-body">
            <Form.Item
              name={['opti_weight', 'api_key']}
              className="isoInputWrapper"
              label="Api Key"
            >
              <Input size="large" placeholder="Input your Api Key"/>
            </Form.Item> 
            <Form.Item
              name={['opti_weight', 'client_id']}
              className="isoInputWrapper"
              label="Client ID"
            >
              <InputNumber size="large" placeholder="Input your Client ID"/>
            </Form.Item>
            <Form.Item
                  name={['opti_weight', 'is_active']}
                  className="isoInputWrapper"
                  valuePropName="checked"
                >
              <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
            </Form.Item>
         </div>
      </Form>
    </OptiWeightModalWrapper>
  )

};

export default OptiWeightModal;