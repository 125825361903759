import styled from "styled-components";
import WithDirection from "@iso/lib/helpers/rtl";

const WeatherChartWrapper = styled.div`
  .weather-btn-link {
    margin: 10px 0;
  }
  .weather-chart-wrap {
    overflow-x: auto;
  }
  #temperature-chart {
    overflow-x: auto;
    clear: both;
  }
  .weather-btn-link .ant-btn-link {
    color: #f80e46;
    margin: 0 5px 5px 0;
  }
  .weather-btn-link .ant-btn-link:hover,
  .weather-btn-link .ant-btn-link.active {
    color: #ffffff;
    background-color: #f80e46;
  }

  .w-full {
    width: 100%;
  }

  .w-3000 {
    width: 3000px;
  }
  .w-8000 {
    width: 8000px;
  }
  .w-20000 {
    width: 20000px;
  }
  .weather-info {
    margin-top: 10px;
  }
  .weather-info li {
    float: left;
    margin-right: 10px;
  }
  @media only screen and (min-width: 768px) {
    height: 550px;
  }

  @media only screen and (max-width: 767px) {
    height: 320px;
  }
`;

export default WithDirection(WeatherChartWrapper);
