import React, { useState, useEffect } from "react";
import LayoutWrapper from "@iso/components/utility/layoutWrapper.js";
import { useSelector, useDispatch } from "react-redux";
import { taskService, propertyService } from "@iso/services";
import TaskManagePage from "./Task.styles";
import Board from "react-trello";
import modalActions from "@iso/redux/modal/actions";
import TaskModal from "./TaskModal";
import _, { toUpper, isEmpty } from "lodash";
import { Radio, Row, Col, Collapse, Switch, Button, Tabs } from "antd";
import SelectPropertyModal from "@iso/containers/Pages/Property/SelectPropertyModal";
import moment from "moment";
import NewTask from "./NewTask";
import TaskChart from "./TaskChart";
import Box from "@iso/components/utility/box";
import TaskContent from "./TabContent/TaskContent";
import CalenderContent from "./TabContent/CalenderContent";
import Progress from "./TabContent/Progress";

const { openModal } = modalActions;

const Task = () => {
  const renderTabBar = (props, DefaultTabBar) => (
    <>
      <DefaultTabBar {...props} />
    </>
  );

  const activePropertyId = useSelector(
    (state) => state.property.activePropertyId
  );
  const activeProperty = JSON.parse(localStorage.getItem("active_property"));
  // const activeProperty = localStorage.getItem("active_property");
  const activePermission = useSelector(
    (state) => state.permission.activePermission
  );
  const dispatch = useDispatch();
  const [primaryObjects, setPrimaryObjects] = useState([]);
  const [visibleSelectProperty, setVisiblitySelectProperty] = useState(false);
  const [kanbanToggle, setKanbanToggle] = useState(-1);
  const [visibleNewTaskModal, setVisibleNewTaskModal] = useState(false);
  const [isAdHoch, setIsAdHoch] = useState(0);
  const [ShowData, setShowData] = useState();
  const [data, setData] = useState({
    lanes: [
      {
        id: "backlog",
        title: "Backlog Tasks",
        cards: [],
      },
      {
        id: "todo",
        title: "To Do",
        cards: [],
      },
      {
        id: "underway",
        title: "Underway",
        cards: [],
      },
      {
        id: "completed",
        title: "Completed",
        cards: [],
      },
    ],
  });

  const tabTitleList = [
    {
      id: 1,
      label: "Task",
      children: <TaskContent data={data} setData={setData} />,
    },
    {
      id: 2,
      label: "Calendar",
      children: <CalenderContent data={data} setData={setData} />,
    },
    {
      id: 3,
      label: "Progress",
      children: <Progress data={data} setData={setData} />,
    },
  ];

  const items = tabTitleList.map((item) => {
    return {
      label: item.label,
      key: item.id,
      children: item.children,
    };
  });

  const getPrimaryObject = React.useCallback((activePropertyId) => {
    propertyService.viewProperty(activePropertyId).then((res) => {
      if (res.code === "0000") {
        setPrimaryObjects(res.primary_objects);
      }
    });
  }, []);

  useEffect(() => {
    if (activePropertyId) {
      getPrimaryObject(activePropertyId);
      propertyService.getProperties().then((res) => {
        if (res.properties.length === 0) {
          setVisiblitySelectProperty(true);
        }
      });
    }
    updateDataBoard();
  }, [activePropertyId, isAdHoch]);

  useEffect(() => {
    if (!activeProperty) {
      setVisiblitySelectProperty(true);
    }
  }, []);

  const updateDataBoard = (taskType = -1) => {
    if (activePropertyId && isAdHoch === 1) {
      taskService.getBoard(activePropertyId, taskType).then((res) => {
        if (res.code === "0000") {
          // if (isAdHoch === 0) {
          const filteredData = res.data?.lanes?.map((column) => ({
            ...column,
            cards: column.cards.filter(
              (card) => card.metadata.is_adhoch === isAdHoch
            ),
          }));
          // setShowData(filteredData)
          let lanes = res.data;
          const updatedLanes = filteredData.map((lane) => ({
            ...lane,
            cards: lane.cards.map((card) => ({
              ...card,
              description: `${card?.metadata?.images?.length} ${card?.metadata?.images?.length}`,
              tags: card?.metadata?.tags.split("|").map((item) => ({
                color: "#DB2777",
                title: item,
              })),
            })),
          }));

          setData({ lanes: updatedLanes });
        }
        // }
      });
    }
   
    if (activePropertyId && isAdHoch === 0) {
      
      taskService.getBoard(activePropertyId, taskType).then((res) => {
        if (res.code === "0000") {
          let lanes = res.data;
          lanes = lanes.lanes?.map((lane) => {
            return {
              ...lane,
              cards: !isEmpty(lane.cards)
                ? lane.cards?.map((a) => {
                  return {
                    ...a,
                    description: `${a?.metadata?.images?.length} ${a?.metadata?.images?.length}`,
                    tags: a?.metadata?.tags.split("|").map((item) => {
                      return {
                        color: "#DB2777",
                        title: item,
                      };
                    }),
                  };
                })
                : [],
            };
          });
          setData({ lanes });
        }
      });
    }
  };
  
  useEffect(() => {
    if (!activeProperty) {
      setVisiblitySelectProperty(true);
    }
  }, []);

  const cancelSelectPropertyModal = () => {
    setVisiblitySelectProperty(false);
  };

  const onShowNewTaskModal = () => {
    setVisibleNewTaskModal(true);
  };
  const handleChangeAdHoch = (checked) => {
    setIsAdHoch(checked ? 0 : 1);
    updateDataBoard(); // Pass 1 if checked, 0 if unchecked
  };

  
  return (
    <LayoutWrapper>
      <TaskManagePage>
        <Row className="" gutter={[24, 24]}>
          <Col xs={{ span: 24 }} md={{ span: 24 }}>
            <div className="toggle-row">
              <Switch
                unCheckedChildren="Show AdHoch"
                checkedChildren="Show All"
                defaultChecked={isAdHoch === 0} // Convert 1 to true, 0 to false
                onChange={handleChangeAdHoch}

                
              />
              {/* <div className="task-title-container"> */}
              {/* <h2 className="task-title">Task Boards</h2> */}
              {/* </div> */}

              <Button
                className="btn-new-task"
                onClick={() => onShowNewTaskModal()}
              >
                Add New Task
              </Button>
            </div>
            <Tabs
              defaultActiveKey="1"
              renderTabBar={renderTabBar}
              items={items}
            />
          </Col>
        </Row>
      </TaskManagePage>
      <SelectPropertyModal
        visibleSelectProperty={visibleSelectProperty}
        cancelSelectPropertyModal={cancelSelectPropertyModal}
      />
      <NewTask
        onSaved={updateDataBoard}
        primaryObjects={primaryObjects}
        kanbanToggle={kanbanToggle}
        visibleNewTaskModal={visibleNewTaskModal}
        setVisibleNewTaskModal={setVisibleNewTaskModal}
        propertyId={activePropertyId}
      />
    </LayoutWrapper>
  );
};

export default Task;
