import styled from "styled-components";
import Modal from "@iso/components/Feedback/Modal";
import WithDirection from "@iso/lib/helpers/rtl";

const AddModalWrap = styled.div`
    border-radius : 12px;
textarea#asset_comments  {
    border-radius: 8px;
    border: 1px solid var(--gray-300, #D0D5DD);
    background: var(--base-white, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
textarea#asset_actions {
    border-radius: 8px;
    border: 1px solid var(--gray-300, #D0D5DD);
    background: var(--base-white, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.ant-picker.ant-picker-large {
    border-radius: 8px;
    border: 1px solid var(--gray-300, #D0D5DD);
    background: var(--base-white, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.addHazards{
    border: 1px solid #ccc !important;

    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
    border-radius: 10px !important;
background: #3F3F44;
width: 100%;
height: 40px;
flex-shrink: 0;
}
.EditHazards{
    border: 1px solid #ccc !important;

    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
    border-radius: 10px !important;
background: #3F3F44;
width: 50%;
height: 40px;
flex-shrink: 0;
}
button.ant-btn.ant-btn-default.EditHazards{
   color : white !important
}
.attachLocation{
    border-radius: 10px  !important;
background: #8F9497;
    width: 100%;
    height: 40px;
    flex-shrink: 0;
}
.ant-timeline-item-head.ant-timeline-item-head-green {
    color: rgb(248, 14, 70);
    border-color:rgb(248, 14, 70);
}
.ant-card-bordered {
    border-radius: 10px !important;
}
.ant-select-selector{
    border-radius: 8px !important;
    border: 1px solid #ccc !important;
    background: var(--base-white, #FFF) !important;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
}
button.ant-btn.ant-btn-primary.attachLocation{
    border-radius: 10px !important;
    border: 1px solid #ccc !important;

    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
}
.ant-divider.ant-divider-horizontal {
    margin: 0px 0px 10px 0px;
}
.customDatePicker .ant-picker-footer {
    background-color: #yourDesiredColor;
  }
  
  .customDatePicker .ant-picker-ok button {
    background-color: #yourDesiredColor;
  }
//   width: 100%;
//   height: 100%;
//   .button-group {
//     width: 100%;
//     margin-bottom: 10px;
//     display: flex;
//     justify-content: flex-end;
//     align-items: flex-end;
//   }
//   .btn-success {
//     background-color: #f80e46;
//     border-color: #559f2f;
//     &:hover {
//       opacity: 0.8;
//     }
//   }
//   a {
//     color: #559f2f;
//   }
//   .ant-upload .ant-btn:focus,
//   .ant-upload .ant-btn:hover {
//     color: #559f2f;
//     border-color: #559f2f;
//   }
//   .ant-table-wrapper {
//     min-width: 100%;
//   }
//   .fAgWDa .ant-table-content {
//     overflow-x: unset;
//   }
//   .fAgWDa {
//     overflow-x: unset;
//   }
//   .dtr-data {
//     white-space: break-spaces;
//   }
//   .delete-button {
//     margin-left: 8px;
//   }
`;

export default WithDirection(AddModalWrap);
