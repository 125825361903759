import React from "react";
import { Button, Select } from "antd";
import IntlMessages from "@iso/components/utility/intlMessages";
import AssetFilterWrapper from "./AssetFilterModal.styles";
import { Checkbox } from "antd";
import { assetCategories } from '@iso/constants/assetCategories';

const CheckboxGroup = Checkbox.Group;

const AssetFilterModal = (props) => {
  const {selectedAssetCategories, visibleAssetFilterModal, setSelectedAssetCategories, cancelAssetFilterModal } = props

  const cancelModal = () => {
    cancelAssetFilterModal();
  };

  const handleChangeType = (list) => {
    setSelectedAssetCategories(list)
  };

  const handleSubmit = () => {
    cancelModal()
  }

  const handleSelectAll = (e) => {
    if(e.target.checked){
      const assetCategoryValues = assetCategories.map((assetCategory) => {
        return assetCategory.value
      })  
      setSelectedAssetCategories(assetCategoryValues)
    }else{
      setSelectedAssetCategories([])
    }
  }

  return (
    <AssetFilterWrapper
      className="primary-object-filter-modal"
      forceRender={true}
      open={visibleAssetFilterModal}
      title={
        <IntlMessages id="Asset Filter" />
      }
      onCancel={cancelModal}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
      <div className="option-container">
        <label className="title">Select Category </label>
        <div>
          <Checkbox onChange={handleSelectAll}>All</Checkbox>
        </div>
        <CheckboxGroup options={assetCategories} value={selectedAssetCategories} onChange={handleChangeType} />
      </div>
    </AssetFilterWrapper>
  );
};

export default AssetFilterModal;