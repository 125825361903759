import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const MarketplacePage = styled.div`
  width: 100%;
  height: 100%;
  .btn-success{
    background-color: #65bb38;
    border-color: #559f2f;
  }
  .button-group{
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .fAgWDa .ant-table-content{
    overflow-x: unset;
  }
  .fAgWDa{
    overflow-x: unset;
  }
  .dtr-data{
    white-space: break-spaces;
  }
  .delete-button{
    margin-left:5px;
  }

  .category-card{
    text-align: center;
    margin-bottom: 15px;
  }

  .feature-title{
    text-align: center;
    font-size: 30px;
  }
  .site-card-wrapper {
  }

  .ant-card-cover{
    height: 300px;
  }

  .ant-card-cover img{
    max-width:100%;
    max-height:100%;
    height: 100%;
  }

  .product-col{
    margin-bottom: 15px;
  }

  .product-detail-page{
    background-color: white;
    padding: 40px;
  }
  .product-detail-page button.image-gallery-thumbnail.active{
    outline : nonr;
    border:2px solid #f80e46 !important;
  }
  .site-card-border-less-wrapper {
  }
    [data-theme="dark"] .site-card-border-less-wrapper {
      background: #303030;


     
    }

  .chat-button{
    width: 100%;
    margin-top: 20px;
    border-radius: 8px !important;
  }
  .btn-success{
    background-color: #65bb38;
    border-color: #559f2f;
  }

  .price-tag{
    color: white;
    height: 50px;
    font-size: 25px;
    padding: 13px;
    font-weight: bold;
  }
  .detail-title{
    font-size: 30px;
    font-weight: bold;
  }
  .detail-description{
    margin-top: 20px;
    text-align: justify;
  }
  .product-home-price{
    font-size: 18px;
    font-weight: bold;
    color: #3f8600;
    margin-top: 10px;
  }

  .btn-see-all{
    margin: auto;
    display: block;
    font-size: 20px;
    height: 40px;
    width: 30%;
  }
  @media only screen and (max-width: 767px) {
    .btn-see-all{
      width: 100%;
    }
  }
  .ant-collapse-header{
    background-color: #80C562;
    color: white!important;
    font-weight: bold;
    font-size: 20px;
  }

  .category-container {
    overflow-x:scroll;
    overflow-y:hidden;
    height:135px;
  }

  .category-card-section {
    width: auto;
    white-space:nowrap; 
    height: 200px;
  }

  .category-card-item {
    display: inline-block;
    width: 300px;
    margin-right: 15px;
  }
`;

export default WithDirection(MarketplacePage);