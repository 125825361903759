import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const LicencesWrapper = styled.div`
.sc-dNsVcS.ddbmvf {
  padding:15px !important;
}
table.react-collapsible .react-collapsible thead > tr > th{
  color: red !important ;
}
  &.user-access-modal{
    width: 50%!important;
    // margin: 10px;
   
    .profile-area{
    background-color:white;
    width:50%;
    margin:10px;
    padding:15px !important ;
  }
 
  }
  .ant-modal-body{
    padding: 10px;
  }
  .AddLicence{
    display: flex !important;
    width: 183px !important;
    height: 40px !important;
    padding: 8px !important;
    justify-content: center;
    align-items: center;
    gap: 10px !important;
    border-radius: 8px !important;
background: var(--Primary-Color, rgb(248, 14, 70) );
  }
  .form-title{
    padding: 0px 24px 24px 0px;
    display: flex;
    align-items: center;
    margin: auto;
    // padding: 16px 24px;
    // border-bottom: 1px solid #eaeef1;
    h3{
      color:rgb(248,14,70);
      width: 100% ;
      font-size: 2rem;
    }
    h2{
      // color:rgb(248,14,70);
      width: 100% ;
      font-size: 2.5rem;
    }
  }
  .form-body{
    padding: 12px 24px 12px 24px;
    .checkbox-contact{
      margin-bottom: 10px;
    }
  }
  #containerBox h4{
    padding: 15px !important;
  }
`;

export default WithDirection(LicencesWrapper);