import styled from "styled-components";

export const HazardFormWrapper = styled.div`
  padding: 20px;
  .ant-form-item {
    margin-bottom: 0px;
  }
  .ant-form-item:not(.no-style){
    margin-bottom: 24px
  }
  .btn-success{
    color:white;
  border-color: #f80e46 !important;
  margin-right: 10px !important;
  margin-buttom:15px;
  background-color: #f80e46 !important;
  padding: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  :hover{
    opacity:1;
  }
}
`;
