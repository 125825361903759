import styled from "styled-components";
import WithDirection from "@iso/lib/helpers/rtl";

const HealthSafetyWrapper = styled.div`
  
h2 {
    font-size: 0.8em;
  }

  h1 {
    color: #f80e46;
    font-weight: bold;
  }

  .ant-col {
    padding: 0.25rem;
  }
 
  @media only screen and (max-width: 768px) {
    #breed-chart,
    #sex-chart,
    #temperature-chart,
    #raining-chart,
    #my_dataviz,
    #livestock_age_weight_chart {
      pointer-events: none;
    }
  }

  .chart-block {
    position: relative;
  }

  .sample-text {
    position: absolute;
    top: 50%;
    left: 20%;
    font-size: 1rem;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #f80e46;
  }

  .reload-button {
    color: #f80e46;
    padding: 5px 8px;
  }
  .reload-button .anticon-sync {
    line-height: 0;
  }
  .leaflet-container {
    z-index: 1;
  }
  .statistic-card {
    background: #ffffff;
    /* Primary/Light Grey */
    padding: 10px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    box-shadow: 1px 1px 10px rgba(229, 229, 229, 0.6);
    border-radius: 10px;
    height: auto;
  }
  .health-safety-search {
    margin-bottom: 15px;
    width: 50%;
    @media (max-width: 767px) {
      width: 70%;
    }
  }
//  td.ant-table-cell{
//     min-width:150px !important;
   
//   }
  .health-safety-search .ant-input-affix-wrapper:not(:last-child) {
    // border: 1px solid !important;
    border-radius: 5px;
    &:hover {
      border-color: #f80e46;
      
    }
  }
  .health-safety-search .ant-input-group-addon:last-child {
    left: 5px !important;
    background: transparent;
  }
  .health-safety-search
    .ant-input-group-addon:last-child
    .ant-input-search-button:not(.ant-btn-primary) {
    border-radius: 5px !important;
    // color: white;
    background: #f80e46;
    color: #ffffff;
    // &:hover {
    //   background: #f80e46;
    //   color: #ffffff;
    // }
  }
  .health-safety-search .ant-input {
    border: 0px !important;
  }
  #health-safety-chart,
  #health-safety-map {
    height: 500px;
  }
  // .ant-pagination-item-active a {
  //   color: #f80e46;
  // }
  .ant-table-cell a {
    color: #f80e46;
  }
  .ant-pagination-item a:hover {
    color: #f80e46;
  }
  .ant-pagination-item-active {
    border-color: #f80e46;
  }
  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: #f80e46;
  }
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    color: #f80e46;
  }
  .maps_rows{
    margin-top:10px;
    .ctm-health {
      .ant-card{
        border-radius:10px;
        .ant-card-body{
          padding:10px;
          border-radius:10px;
        }
      }
    }
    .statistic-card{
      height:100% !important;
    }
  }
  .trafficLight_custom{
    >div:nth-child(2){
      // height: calc(100% - 145px);
      >div{
        height:100%;
      }
    }
  }
  @media only screen and (max-width: 1199px){
    .maps_rows{
      margin-top:0;
      .statistic-card{
        height:500px !important;
      }
    }
    .trafficLight_custom{
      > div:nth-child(2){
        height:auto !important;
      }
    }
  }
  @media (max-width: 767px) {
    #health-safety-chart,
    #health-safety-map {
      height: 350px;
    }
  }
`;

export default WithDirection(HealthSafetyWrapper);
