import styled from "styled-components";
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const ModalHealthSafetyWrap = styled(Modal)`
  .btn-success{
    background-color: #f80e46;
    border-color: #f80e46;
    &:hover{
      opacity: 0.8;
    }
  }
`;

export default WithDirection(ModalHealthSafetyWrap);
