import styled from "styled-components";
import WithDirection from "@iso/lib/helpers/rtl";

const DeviceTableWrapper = styled.div`
  width: 100%;
  height: 100%;
  a {
    color: #f80e46;
  }
  .ant-table-wrapper {
    min-width: 100%;
  }
  button.ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25) !important;
    border-color: #d9d9d9 !important;
    cursor: not-allowed !important;
}
  th.ant-table-cell {
    color: #f80e46;
  }
  .ant-pagination-item-active {
    border-color: #f80e46;
  }
  .ant-pagination-item-active a {
    color: #f80e46;
  }
  .ant-select-device {
    font-size: 14px;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    border-radius: 5px;
  }
  .ant-select,
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-radius: 5px !important;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
    .ant-select-item-option-state {
    color: white;
  }
  .ant-device-search{
    border-radius: 5px;
    padding: 8px;
  }
  .ant-device-search:hover {
    border-color: #f80e46;
    border-radius: 5px;
    padding: 8px;
  }
  .ant-device-search .ant-input-group {
    border: 1px solid #f80e46;
  }
  .ant-device-search .ant-input {
    border: 0px !important;
  }
  .ant-btn-add-device {
    background-color: #f80e46;
    color: white;
  }
  .ant-btn-add-device:hover {
    opacity: 0.9;
  }
  .ant-popconfirm .ant-btn-primary {
    background: #f80e46;
  }
  #addDevice .ant-input {
    padding: 8px;
    width: 100%;
  }
  #addDevice .ant-form-item {
    width: 60%;
  }
  @media (max-width: 820px) {
    #addDevice .ant-form-item {
      width: 50%;
    }
  }
`;
export default WithDirection(DeviceTableWrapper);
