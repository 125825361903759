import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Form, Input, Button, Select, Row, Col, DatePicker, InputNumber } from 'antd';
import _ from 'lodash';
import { livestockService, activityService, feedingTypeService, mobService } from '@iso/services';
import { mobActivityCategories } from '@iso/constants/mobActivityCategories';
import { activityCategories } from '@iso/constants/activityCategories';
import AddHistoryModalWrapper from './AddHistoryModal.styles';
import { drugUnit } from '@iso/constants/drugUnit';
import { treatmentCategories } from '@iso/constants/treatmentCategories';
import FeedingTypeModalWrapper from './FeedingTypeModal.styles';
import { pregTesting } from '@iso/constants/pregTesting';
import { objectColors } from '@iso/constants/objectColors';
import styled from "styled-components";

const { Option } = Select;

const AddHistoryModal = (props) => {
  const [addHistoryForm] = Form.useForm();
  const [feedingTypeForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const [assets, setAssets] = useState([]); 
  const [visibleHistory, setVisibleHistory] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [liveStockActivities, setLiveStockActivities] = useState([]);
  const [feedingTypes, setFeedingTypes] = useState({});
  const [visibleFeedingType, setVisibleFeedingType] = useState(false);
  const [loadingFeedingType, setLoadingFeedingType] = useState(false);
  const [historySalePrice, setHistorySalePrice] = useState();
  const [historySaleWeight, setHistorySaleWeight] = useState();
  const [historySalePricePerWeight, setHistorySalePricePerWeight] = useState();
  const [liveStockExists, setLiveStockExists] = useState();
  const [archiveModalVisible, setArchiveModalVisible] = useState(false);

  useEffect(() => {
    if(props.propertyId){
       feedingTypeService.getList(props.propertyId).then(res => {
        if(res.code === '0000'){
          setFeedingTypes(res.feeding_types);
          setLoadingFeedingType(false);
        }
      });
    }
  }, [props.propertyId]);


  useEffect(() => {
    if(props.propertyId){
       livestockService.getList(props.propertyId).then(res => {
        if(res.code === '0000'){
          setAssets(res.assets);
          setLoading(false);
        }
      });
    }
  }, [props.propertyId, props.visibleHistory]);

  useEffect(() => {
    if(historySalePrice && historySaleWeight){
      setFields([
        {
          name: ['history', 'price_per_weight'],
          value: historySalePrice / historySaleWeight,
        },
      ]);
    }
  }, [historySalePrice, historySaleWeight]);

  useEffect(() => {
    if (
      props.dataId
      && props.model === "mobs"
      && props.type === "mob"
    ) {
      livestockService.checkExistsByMob(props.dataId).then(res => {
        if (res.code === '0000') {
          setLiveStockExists(res.data);
        }
      });
    }
  }, [props.dataId]);

  const addFeedingType = (object, e) => {
    setVisibleFeedingType(true);
  }

  const cancelModalHistory = () => {
    props.cancelModal();
    addHistoryForm.resetFields();
  }

  const cancelModalFeedingType = () => {
    setVisibleFeedingType(false);
  }

  const renderOptionsFeedingType = (feeding_type_options) => {
    let feeding_options = [];
     _.forEach(feeding_type_options, (feeding_type, index) => {
      feeding_options.push(
        <Option key={index} value={feeding_type.name}>
            <div>{feeding_type.name}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a feeding type"
        allowClear
        size="large"
      >
        {feeding_options}
      </Select>
    );
  }

  const handleFeedingTypeSubmit = (e) => {
    feedingTypeForm
      .validateFields()
      .then(feeding_type_values => {
        onStoreFeedingType(feeding_type_values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoadingFeedingType(false);
      });
  }

  const handleHistorySubmit = (e) => {
    addHistoryForm
      .validateFields()
      .then(history_values => {
        onStoreHistory(history_values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoadingHistory(false);
      });
  }

  const onStoreFeedingType = (feeding_type_values) => {
    const feeding_type_data = {
      "feeding_type":{
        ...feeding_type_values.feeding_type,
        property_id: props.propertyId
      }
    }

    feedingTypeService.storeOrUpdate(feeding_type_data).then(res => {
      if(res.code === '0000'){
        var tmp_feeding_types = _.clone(feedingTypes);
        tmp_feeding_types.push(res.feeding_type);
        setFeedingTypes(tmp_feeding_types);
        cancelModalFeedingType();
        feedingTypeForm.resetFields();
        setFields([
          {
            name: ['history', 'feeding_type'],
            value: res.feeding_type.name,
          },
        ]);
      } else {
        setLoadingFeedingType(false);
      }
    });

  }

  const onStoreHistory = (history_values) => {
    const history_data = {
      "history":{
        metadata: history_values.history,
        action:'create',
        model: props.model,
        object_id: props.dataId,
        type: props.type,
        property_id: props.propertyId
      }
    }
    
    activityService.storeOrUpdate(history_data).then(res => {
      if(res.code === '0000'){
        cancelModalHistory();
        addHistoryForm.resetFields();
        props.updateDataMobHistory(res.history.object_id, props.type);

        if(res.history.metadata.death){
          props.updateNumberAnimal(props.numberAnimals, res.history.metadata.death.number_of_animals);
          checkArchiveMob({ subAnimalNumbers: res.history.metadata.death.number_of_animals });
        }

        if(res.history.metadata.sale){
          props.updateNumberAnimal(props.numberAnimals, res.history.metadata.sale.number_of_animals);
          checkArchiveMob({ subAnimalNumbers: res.history.metadata.sale.number_of_animals });
        }
          
        if(props.updateDataMobs){
          props.updateDataMobs();
        }
      } else {
        setLoadingHistory(false);
      }
    });

  }

  useEffect(() => {
      setFields([
        {
          name: ['history', 'treatment_unit'],
          value: 'mls',
        },
        {
          name:  ['history', 'activity_category'],
          value: null,
        },
      ]);
    
  }, [props.liveStockData, liveStockActivities]);

  const renderOptionMaleAsset = (asset_data) => {
    let options = [];
    
    _.forEach(asset_data, (asset, index) => {
      if(asset.sex && asset.id !== props.dataId && asset.displayed_name){
        options.push(
          <Option key={asset.id} value={asset.displayed_name} label={asset.displayed_name}> 
            {objectColors.map((value, index) => {
            if(value.value == asset.tag_colour){
              return (
                <ColorItemRow>
                  <ColorItemPreview style={{backgroundColor: value.color}}></ColorItemPreview>
                  <div>{asset.displayed_name}</div>
                </ColorItemRow>
                )
            }
            })}
          </Option>
        );
      }
    })
    return (
      <Select
        placeholder="Select a livestock"
        allowClear
        size="large"
        showSearch
        optionFilterProp="label"
        filterOption={true}
      >
        {options}
      </Select>
    );
  }

  const renderOptionsMobActivityCategories = () => {
    let activity_options = [];
     _.forEach(mobActivityCategories, (activity, index) => {
      if (
        liveStockExists
        && (activity.value === 'sale' || activity.value === 'death')
      ) {
        return;
      }

      activity_options.push(
        <Option key={index} value={activity.value}>
            <div>{activity.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select type of history"
        allowClear
        size="large"
      >
        {activity_options}
      </Select>
    );
  }

  const renderOptionsLiveStockActivityCategories = () => {
    let activity_options = [];
     _.forEach(activityCategories, (activity, index) => {
      activity_options.push(
        <Option key={index} value={activity.value}>
            <div>{activity.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select type of history"
        allowClear
        size="large"
        listHeight="300"
      >
        {activity_options}
      </Select>
    );
  }

  const renderOptionsDrugUnit = () => {
    let unit_options = [];
     _.forEach(drugUnit, (unit, index) => {
      unit_options.push(
        <Option key={index} value={unit.value}>
            <div>{unit.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select unit"
        allowClear
        size="large"
      >
        {unit_options}
      </Select>
    );
  }

  const renderOptionsPregTesting = () => {
    let preg_options = [];
     _.forEach(pregTesting, (preg, index) => {
      preg_options.push(
        <Option key={index} value={preg.value}>
            <div>{preg.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select preg testing"
        allowClear
        size="large"
      >
        {preg_options}
      </Select>
    );
  }

  const renderOptionObject = (objects) => {
    let options = [];
    _.forEach(objects, (object, index) => {
      options.push(
        <Option key={object.id} value={object.name}>
          {object.name}
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select an object"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const onChangeSalePrice = e => {
    setHistorySalePrice(e);
  }

  const onChangeSaleWeight = e => {
    setHistorySaleWeight(e);
  }

  const cancelArchiveModal = () => {
    setArchiveModalVisible(false);

    if (props.cancelArchiveModal) {
        props.cancelArchiveModal();
    }
  }

  const handleArchiveModalSubmit = () => {
    const body = {
        mob: {
            id: props.dataId,
        }
    };

    mobService.archiveMob(body).then(res => {
        if (res.code === '0000') {
            setArchiveModalVisible(false);

            if (props.afterArchived) {
                props.afterArchived();
            }
        }
    });
  }

  const checkArchiveMob = (data) => {
    if (props.numberAnimals - data.subAnimalNumbers === 0) {
        setArchiveModalVisible(true);
    }
  }

  return (
    <>
    <AddHistoryModalWrapper
      visible={props.visibleHistory}
      onCancel={cancelModalHistory}
      centered
      maskClosable={true}
      title={ <IntlMessages id="propertyPage.mobModal.historyTab.addHistory"/> }
      footer={[        
        <Button key="back" onClick={cancelModalHistory} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary" onClick={handleHistorySubmit} loading={loadingHistory}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
    {
    
          <Form
            form={addHistoryForm}
            layout="vertical"
            scrollToFirstError
            fields={fields}
          >
          <div className="form-body">
            <Form.Item
                  name={['history', 'activity_category']}
                  className="isoInputWrapper"
                  label="Type of History"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                {(props.type === 'mob') ? renderOptionsMobActivityCategories() : renderOptionsLiveStockActivityCategories()}
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return prevValues['history'];
              }}
            >
            {({ getFieldValue }) => {
              if(getFieldValue('history')){
                if (getFieldValue('history').activity_category === "treatment"){
                  return (<div><Form.Item
                                name={['history', 'treatment_name']}
                                className="isoInputWrapper"
                                label="Treatment Name"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                              <Input size="large" placeholder="Input treatment name"/>
                          </Form.Item>
                          <Row>
                            <Col xs={{span: 24}} md={{span: 11}}>
                              <Form.Item
                                name={['history', 'treatment_quantity']}
                                className="isoInputWrapper"
                                label="Quantity"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                              <InputNumber size="large" min={1} max={1000} />
                              </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                              <Form.Item
                                name={['history', 'treatment_unit']}
                                className="isoInputWrapper"
                                label="Unit"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                              {renderOptionsDrugUnit()}
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={{span: 24}} md={{span: 11}}>
                              <Form.Item
                                name={['history', 'treatment_WHP']}
                                className="isoInputWrapper"
                                label="WHP (Number in days)"
                              >
                              <InputNumber size="large" min={1} max={1000} />
                              </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                              <Form.Item
                                name={['history', 'treatment_ESI']}
                                className="isoInputWrapper"
                                label="ESI (Number in days)"
                              >
                              <InputNumber size="large" min={1} max={1000} />
                              </Form.Item>
                            </Col>
                          </Row>
                          </div>)
                }else if(getFieldValue('history').activity_category === "feeding"){
                  return (<div>
                            <Row>
                              <Col xs={{span: 12}} md={{span: 16}}>
                                <Form.Item
                                  name={['history', 'feeding_type']}
                                  className="isoInputWrapper"
                                  label="Feeding Type"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'This field is required',
                                    },
                                  ]}
                                >
                                {renderOptionsFeedingType(feedingTypes)}
                                </Form.Item>
                              </Col>
                              <Col xs={{span: 10, offset: 2}} md={{span: 6, offset: 2}}>
                                <Button key="submit" className="btn-create-feeding-type" type="primary" onClick={addFeedingType}>
                                  {<IntlMessages id="propertyPage.modal.feedingType.addFeedingType.title" />}
                                </Button>
                              </Col>
                            </Row>
                            <Form.Item
                                name={['history', 'feeding_quantity']}
                                className="isoInputWrapper"
                                label="Quantity"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                              <InputNumber size="large" min={1} max={1000} />
                            </Form.Item>
                            <FeedingTypeModalWrapper
                              visible={visibleFeedingType}
                              onCancel={cancelModalFeedingType}
                              centered
                              maskClosable={true}
                              title={ <IntlMessages id="propertyPage.modal.feedingType.addFeedingType.title"/> }
                              footer={[        
                                <Button key="back" onClick={cancelModalFeedingType} className="cancel-button">
                                  {<IntlMessages id="propertyPage.modal.cancel" />}
                                </Button>,
                                <Button key="submit" className="btn-success" type="primary" onClick={handleFeedingTypeSubmit} loading={loadingFeedingType}>
                                  {<IntlMessages id="propertyPage.modal.save" />}
                                </Button>,
                              ]}
                            >
                            {
                            
                                  <Form
                                    form={feedingTypeForm}
                                    layout="vertical"
                                    scrollToFirstError
                                  >
                                  <div className="form-body">
                                    <Form.Item
                                      name={['feeding_type', 'name']}
                                      className="isoInputWrapper"
                                      label="Feeding Type"
                                      rules={[
                                        {
                                          required: true,
                                          message: 'This field is required',
                                        },
                                      ]}
                                    >
                                      <Input size="large" placeholder="Input feeding type"/>
                                    </Form.Item>
                                    </div>    
                              </Form>
                            }
                            </FeedingTypeModalWrapper>
                          </div>
                          )
                }else if(getFieldValue('history').activity_category === "joining"){
                  return (<div>   
                            <Form.Item
                              name={['history', 'joining_sire']}
                              className="isoInputWrapper"
                              label="Sire"
                            >
                             {renderOptionMaleAsset(assets)}
                            </Form.Item>
                        
                            <Form.Item
                              name={['history', 'joining_expected_duration']}
                              className="isoInputWrapper"
                              label="Expected Duration (Number of weeks)"
                              rules={[
                                    {
                                      required: true,
                                      message: 'This field is required',
                                    },
                              ]}
                            >
                            <InputNumber size="large" min={1} max={1000} />
                            </Form.Item>
                          </div>)
                }else if(getFieldValue('history').activity_category === "marking"){
                  return (<div>
                            <Form.Item
                                name={['history', 'marking_system_date']}
                                className="isoInputWrapper"
                                label="Date"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                             <DatePicker size="large" style={{width: '100%'}}/>
                            </Form.Item>
                          </div>)
                }else if(getFieldValue('history').activity_category === "weighing"){
                  return (<div>
                            <Form.Item
                              name={['history', 'weight']}
                              className="isoInputWrapper"
                              label="Weight (kg)"
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                },
                              ]}
                            >
                            <InputNumber size="large" min={1} max={1000} />
                          </Form.Item>
                          </div>)
                }else if(getFieldValue('history').activity_category === "preg_testing"){
                  return (<div>
                            <Form.Item
                                name={['history', 'preg_testing']}
                                className="isoInputWrapper"
                                label="Preg Testing"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                              {renderOptionsPregTesting()}
                            </Form.Item>
                          </div>)
                }else if (getFieldValue('history').activity_category === "crutching"){
                  return (<div>
                            <Form.Item
                                name={['history', 'crutching_system_date']}
                                className="isoInputWrapper"
                                label="Date"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                             <DatePicker size="large" style={{width: '100%'}}/>
                            </Form.Item>
                          </div>)
                }else if (getFieldValue('history').activity_category === "shearing"){
                  return (<div>
                            <Form.Item
                                name={['history', 'shearing_system_date']}
                                className="isoInputWrapper"
                                label="Date"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                             <DatePicker size="large" style={{width: '100%'}}/>
                            </Form.Item>
                          </div>)
                }else if (getFieldValue('history').activity_category === "death"){
                  return (<div>
                            <Form.Item
                                name={['history', 'number_of_animals']}
                                className="isoInputWrapper"
                                label="Number of Animals"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                              <InputNumber size="large" min={1} max={props.numberAnimals} />
                            </Form.Item>
                            <Form.Item
                                name={['history', 'description']}
                                className="isoInputWrapper"
                                label="Description"
                              >
                             <Input.TextArea />
                            </Form.Item>
                          </div>)
                }else if ( getFieldValue('history').activity_category === 'sale'){
                  return (<div>
                            <Form.Item
                                name={['history', 'number_of_animals']}
                                className="isoInputWrapper"
                                label="Number of Animals"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                              <InputNumber size="large" min={1} max={props.numberAnimals} />
                            </Form.Item>
                            <SalePriceInput>
                              <Form.Item
                                name={['history', 'sale_price']}
                                className="isoInputWrapper"
                                label="Sales Price per Head"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                  {
                                    type: 'number',
                                  }
                                ]}
                                style={{width: '100%'}}
                              >
                                <InputNumber size="large" placeholder="Input sale price" style={{width: '100%'}} onChange={onChangeSalePrice}/>
                              </Form.Item>
                              <SuffixAddon>$</SuffixAddon>
                            </SalePriceInput>
                            <SalePriceInput>
                              <Form.Item
                                name={['history', 'average_weight']}
                                className="isoInputWrapper"
                                label="Average Weight"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                  {
                                    type: 'number',
                                  }
                                ]}
                                style={{width: '100%'}}
                              >
                                <InputNumber size="large" placeholder="Input average weight" style={{width: '100%'}} onChange={onChangeSaleWeight}/>
                              </Form.Item>
                              <SuffixAddon>kg</SuffixAddon>
                            </SalePriceInput>
                            <Form.Item
                                name={['history', 'price_per_weight']}
                                className="isoInputWrapper"
                                label="$ per kg"
                                rules={[
                                  {
                                    type: 'number',
                                  }
                                ]}
                                style={{width: '100%'}}
                              >
                                <Input size="large" style={{width: '100%'}} disabled/>
                              </Form.Item>
                            <Form.Item
                                name={['history', 'description']}
                                className="isoInputWrapper"
                                label="Description"
                              >
                             <Input.TextArea />
                            </Form.Item>
                          </div>)
                }else if(getFieldValue('history').activity_category === "other"){
                  return (<div>
                            <Form.Item
                                name={['history', 'other_title']}
                                className="isoInputWrapper"
                                label="Title"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                              <Input size="large" placeholder="Input title"/>
                            </Form.Item>
                            <Form.Item
                                name={['history', 'other_comment']}
                                className="isoInputWrapper"
                                label="Comment"
                              >
                              <Input.TextArea />
                            </Form.Item>
                            <Form.Item
                                name={['history', 'other_location']}
                                className="isoInputWrapper"
                                label="Location"
                              >
                            {renderOptionObject(props.primaryObjects)}
                            </Form.Item>
                          </div>)
                }else {}
              }
            }}
            </Form.Item>
          </div>    
      </Form>
    }
    </AddHistoryModalWrapper>

    <AddHistoryModalWrapper
        visible={archiveModalVisible}
        centered
        onCancel={cancelArchiveModal}
        title="Archive Mob"
        footer={[
            <Button key="back" onClick={cancelArchiveModal} className="cancel-button">
              {<IntlMessages id="propertyPage.modal.cancel" />}
            </Button>,
            <Button key="submit" className="btn-success" type="primary" onClick={handleArchiveModalSubmit} >
              {<IntlMessages id="propertyPage.modal.accept" />}
            </Button>,
        ]}
    >
        <div class="form-body">{<IntlMessages id="propertyPage.modal.archive.confirmation.thisMob" />}</div>
    </AddHistoryModalWrapper>
    </>
  )
}


export default AddHistoryModal;

const ColorItemRow = styled.div`
  display: flex;
  align-items: center;
  line-height: 30px;
  div{
    margin: 4px;
  }
`;

const ColorItemPreview = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 2px;
`;

const SuffixAddon = styled.span`
  margin-top: 31px;
  margin-bottom: 5px;
  line-height: 38px;
  color: #747784!important;
  flex-shrink: 0;
  border-top-right-radius: 4px!important;
  border-bottom-right-radius: 4px!important;
  border-right-width: 1px!important;
  min-width: 36px;
  background-color: #f8f9fa;
  border: solid #d9d9d9;
  border-width: 1px 0;
  white-space: nowrap;
  text-align: center;
`;

const SalePriceInput = styled.div`
  display: flex;
  align-items: flex-start;
`;
