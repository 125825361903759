import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Menu from "@iso/components/uielements/menu";
import IntlMessages from "@iso/components/utility/intlMessages";
const SubMenu = Menu.SubMenu;

const stripTrailingSlash = (str) => {
  if (str.substr(-1) === "/") {
    return str.substr(0, str.length - 1);
  }
  return str;
};

export default React.memo(function SidebarMenu({
  singleOption,
  submenuStyle,
  submenuColor,
  stripePlan,
  ...rest
}) {
  let match = useRouteMatch();
  const activePropertySubscription = useSelector(
    (state) => state.subscription.activePropertySubscription
  );
// console.log(singleOption)
  const { key, label, leftIcon, children } = singleOption;
  const url = stripTrailingSlash(match.url);

  if (children) {
    return (
      <SubMenu
        key={key}
        title={
          <span className="isoMenuHolder" style={{display:'flex',alignItems:'center'}} >
            <i className={leftIcon} />
            <span className="nav-text">
              <IntlMessages id={label} />
            </span>
          </span>
        }
        {...rest}
      >
        {children.map((child) => {
          console.log("child =", child);
          const linkTo = child.withoutDashboard
            ? `/${child.key}`
            : `${url}/${child.key}`;
          return (
            <Menu.Item style={submenuStyle} key={child.key}>
              <Link style={submenuColor} to={linkTo}>
                <IntlMessages id={child.label} />
              </Link>
            </Menu.Item>
          );
        })}
      </SubMenu>
    );
  }
  if (key !== "health-safety" && key !== "safe-farm") {
    return (
      <Menu.Item key={key} {...rest}>
        {key == "weather" ? (
          <Link to={`${url}/${key}`} id="custom-menu-icon">
            <span className="isoMenuHolder" style={submenuColor}>
              {leftIcon}
              <span className="nav-text">
                <IntlMessages id={label} />
              </span>
            </span>
          </Link>
        ) : (
          <Link to={`${url}/${key}`} id="custom-menu-icon">
            <span className="isoMenuHolder" style={submenuColor}>
              {leftIcon}
              <span className="nav-text">
                <IntlMessages id={label} />
              </span>
            </span>
          </Link>
        )}
      </Menu.Item>
    );
  } else {
    if (process.env.REACT_APP_STRIPE_PLAN_GOLD === activePropertySubscription) {
      return (
        <Menu.Item key={key} {...rest}>
          <Link to={`${url}/${key}`} id="custom-menu-icon">
            <span className="isoMenuHolder" style={submenuColor}>
              {leftIcon}
              <span className="nav-text">
                <IntlMessages id={label} />
              </span>
            </span>
          </Link>
        </Menu.Item>
      );
    }
  }
});
