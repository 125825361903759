import axios from "axios";
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';

export const marketplaceProductService = {
  getList,
  storeOrUpdate,
  viewProduct,
  getByCategory,
  destroy
};

function getList(userId = null, categoryId = null){
  return (
    axios
      .get(
        `${apiEndpoint.marketplaceProducts}?user_id=${userId}&category_id=${categoryId}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function viewProduct(id){
  return (
    axios
      .get(
        apiEndpointFunction.marketplaceProducts(id),
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
        return {};
      })
  );
}

function storeOrUpdate(data, id = null){
  return (id ? (
    axios
      .put(
        apiEndpointFunction.marketplaceProducts(id),
        data,
        {
          headers: authHeader(),
        }
      )
  ) : (
    axios
      .post("https://airtest.airagri.tech/api/web/marketplace-products",data,
        {
          headers: authHeader()
        }
      )
  )).then(res => {
    if(res && res.status === 200){
      return res.data;
    }
    return {};
  });
}

function getByCategory(categoryId){
  return (
    axios
      .get(
        `${apiEndpoint.marketplaceProducts}/get-product-by-category?category_id=${categoryId}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function destroy(id){
  return (
    axios
      .delete(
        apiEndpointFunction.marketplaceProducts(id),
        {
          headers: authHeader()
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
      })
  );
}