import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import LicenceModalWrapper from './LicenceModal.styles';
import { Form, Input, Button, Select, Row, Col, DatePicker, Upload } from 'antd';
import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import modalActions from '@iso/redux/modal/actions';
import Loader from '@iso/components/utility/loader';
import { licenceService } from '@iso/services';
import moment from 'moment';
import { licenceTypes } from '@iso/constants/licenceTypes';
import { vehicleLicence } from '@iso/constants/vehicleLicence';
import { machineryLicence } from '@iso/constants/machineryLicence';
import _ from 'lodash';
import styled from "styled-components";
import { dateHelper } from '@iso/lib/helpers/dateHelper';
import L from 'leaflet';
import inside from 'point-in-polygon';
import $ from 'jquery';
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';
import { flatGroup } from 'd3';

const { Option } = Select;
const { closeModal } = modalActions;

const LicenceModal = (props) => {
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [dataLicence, setDataLicence] = useState({});
  const dispatch = useDispatch();
  const modalVisibility = useSelector((state) => state.modal.modalVisibility);
  const modalData = useSelector((state) => state.modal.modalData);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector((state) => state.user.user);
  const [issueDate, setIssueDate] = useState([]);
  const [licenceNames, setlicenceNames] = useState(vehicleLicence);
  const [imageId, setImageId] = useState(null);
 
  useEffect(() => {
    setDataLicence(props.licenceData);
    if(props.licenceData.licence_type === 'vehicles'){
      setlicenceNames(vehicleLicence);
    }else if(props.licenceData.licence_type === 'machinery'){
      setlicenceNames(machineryLicence);
    }else{
      setlicenceNames([]);
    }
  }, [props.licenceData, props.visibleLicence]);

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
    setLoading(false);
  }

  const onStore = (values) => {
    const data = {
      "licence":{
        ...values.licence,
        date_issue: values.licence.date_issue ? dateHelper.dateForAPI(values.licence.date_issue) : '',
        date_expiry: values.licence.date_expiry ? dateHelper.dateForAPI(values.licence.date_expiry) : '',
        user_id: currentUser.id,
        image_id: imageId
      }
    }

    licenceService.storeOrUpdate(data, dataLicence.id).then(res => {
      if(res.code === '0000'){
        props.onSaved();
        props.cancelModal();
        form.resetFields();
      } else {
        setLoading(false);
      }
    });
  }
  const cancelLicenceModal = () => {
    if (props.viewSummary) {
      props.setViewSummary(false)
    }else{
    props.cancelModal();
    form.resetFields();
    }
  } 

  useEffect(() => {
      setFields([
        {
          name: ['licence', 'licence_type'],
          value: dataLicence.licence_type || '',
        },
        {
          name: ['licence', 'licence_name'],
          value: dataLicence.licence_name || '',
        },
        {
          name: ['licence', 'licence_other_name'],
          value: dataLicence.licence_other_name || '',
        },
        {
          name: ['licence', 'licence_number'],
          value: dataLicence.licence_number || '',
        },
        {
          name: ['licence', 'date_issue'],
          value: dataLicence.date_issue ? moment(dataLicence.date_issue) : '',
        },
        {
          name: ['licence', 'date_expiry'],
          value: dataLicence.date_expiry ? moment(dataLicence.date_expiry) : '',
        },
      ]);
    
  }, [dataLicence, props.visibleLicence]);

  const handleLicenceTypeChange = (value) => {
    if(value === 'vehicles'){
      form.setFields([{
          name: ['licence', 'licence_name'],
          value: '',
      }]);
      setlicenceNames(vehicleLicence);
    }else if(value === 'machinery'){
      form.setFields([{
          name: ['licence', 'licence_name'],
          value: '',
      }]);
      setlicenceNames(machineryLicence);
    }else{
      setlicenceNames([]);
    }
  }

  const renderOptionsLicenceTypes = () => {
    let options = [];
    _.forEach(licenceTypes, (licence, index) => {
      options.push(
        <Option key={index} value={licence.value}>
            <div>{licence.label}</div>
        </Option>
      );
    })
    return (

    
        <Select
        placeholder="Select a licence type"
        allowClear
        size="large"
        {...props.viewSummary ?{disabled:true}:{disabled:false}}
        onChange={handleLicenceTypeChange}
      >
        {options}
      </Select>
     
    );
  }

  const renderOptionsLicenceNames = () => {
    let options = [];
    _.forEach(licenceNames, (licence, index) => {
      options.push(
        <Option key={index} value={licence.value}>
            <div>{licence.label}</div>
        </Option>
      );
    })
    return (
    
      
        <Select
        placeholder="Select a licence"
        allowClear
        size="large"
        {...props.viewSummary ?{disabled:true}:{disabled:false}}
        // disabled
      >
        {options}
      </Select>
     

      
    );
  }

  const disabledDate = (current) => {
    
    return current && current < issueDate;
  }

  const uploadProps = {
    name: "Image[file]",
    action: apiEndpoint.images,
    headers: authHeader(true),
    beforeUpload: (file, fileList) => {
      let $oldOne = $('.anticon-delete')[0];
      setTimeout(()=>{
        if($oldOne)
          $($oldOne).trigger('click');
      },100);
      
    },
    onChange: (response) => {
      if(response.file && response.file.status == "done"){
        setImageId(response.file.response.image.id);
      }
    },    
    listType: "picture",
    multiple: false
  }

  return(
    <>
    <LicenceModalWrapper
      open={props.viewSummary?props.viewSummary:props.visibleLicence}
      onCancel={cancelLicenceModal}
      maskClosable={true}
      centered
      title={props.title}
      footer={[        
        <Button key="back" onClick={cancelLicenceModal} 
        hidden ={props.viewSummary ? true:false}
        className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary"
        hidden ={props.viewSummary ? true:false}
        onClick={handleSubmit} loading={loading}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
  
      <Form
          form={form}
          layout="vertical"
          scrollToFirstError
          fields={fields}
      >
        <div className="form-body">
        {props.viewSummary ?
            dataLicence.images && dataLicence.images.length > 0 && <img style={{width:"100%",height:'50%'}}src={process.env.REACT_APP_API_BASE_URL + dataLicence.images[dataLicence.images.length-1].path} />
          :''}
          <Form.Item
            name={['licence', 'licence_type']}
            className="isoInputWrapper"
            label="Licence Type"
           
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            {renderOptionsLicenceTypes()}
          </Form.Item>
          <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return (prevValues['licence'] && (prevValues['licence'].licence_type !== currentValues['licence'].licence_type));
              }}
          >
          {({ getFieldValue }) => {
            if(getFieldValue('licence')){
              if (getFieldValue('licence').licence_type !== "other"){
                return (
                  <Form.Item
                    name={['licence', 'licence_name']}
                    className="isoInputWrapper"
                    label="Licence"

                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                  >
                    {renderOptionsLicenceNames()}
                  </Form.Item>
                )
              }else{
                return(
                  <Form.Item
                      name={['licence', 'licence_other_name']}
                      className="isoInputWrapper"
                      
                      
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        },
                      ]}
                    >
                      {}
                      <Input size="large"  {...props.viewSummary ?{readOnly:true}:{readOnly:false}}  placeholder="Input licence"/>
                    </Form.Item>
                )
              }
            }
          }}
          </Form.Item>
          <Form.Item
            name={['licence', 'licence_number']}
            className="isoInputWrapper"
           
            rules={[
              {
                required: true,
                message: 'This field is required',
                whitespace: true
              },
              {
                max:255,
                message: 'Licence Number must be maximum 255 characters.'
              },
            ]}
          >
            <Input size="large" {...props.viewSummary ?{readOnly:true}:{readOnly:false}} 
             onKeyDown={(e) => {
              // Allow only numbers (0-9) and specific control keys
              if (!/^\d+$/.test(e.key) && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(e.key)) {
                e.preventDefault();
              }
            }}
            placeholder="Input licence number"/>
          </Form.Item>
          <Form.Item
            name={['licence', 'date_issue']}
            className="isoInputWrapper"
          
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <DatePicker size="large" style={{width: '100%'}}  {...props.viewSummary ?{disabled:true}:{disabled:false}} onChange={value => {setIssueDate(value);}}/>
          </Form.Item>
          <Form.Item
            name={['licence', 'date_expiry']}
            className="isoInputWrapper"
            
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (value && value >= getFieldValue('licence').date_issue) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Date Expiry is not lower than Date Issue!');
                },
              }),
            ]}
          >
            <DatePicker size="large" style={{width: '100%'}} {...props.viewSummary ?{disabled:true}:{disabled:false}}  disabledDate={disabledDate}/>
          </Form.Item>
          <>
          {props.viewSummary ? '':
          <Form.Item name={['attachment']} label="Attachment">
            <Upload
              {...uploadProps}
            >
              <Button className="action-button" icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </Form.Item>}
         
          </>
          </div>    
    </Form>
    
    </LicenceModalWrapper>
    </>
  )
}

export default LicenceModal;