import React from "react";
import { Table3Wrapper } from "./Table3Wrapper.style";
import LivestockAndFeedTable from "./LivestockAndFeedTable";
import PeopleVehicleEquipmentTable from "./PeopleVehicleEquipmentTable"
import ProductionPracticesTable from './ProductionPracticesTable'
import PestsAndWeeds from './PestsAndWeeds'
import OutgoingProducts from './OutgoingProducts'
import TrainPlanRecordTable from './TrainPlanRecordTable'
import QueenslandBiosecurityTable from './QueenslandBiosecurityTable'
import DiseaseSpecificPracticesTable from './DiseaseSpecificPracticesTable'

const Table3 = (props) => {
  return (
    <Table3Wrapper>
      <LivestockAndFeedTable form={props.form}/>
      <PeopleVehicleEquipmentTable />
      <ProductionPracticesTable />
      <PestsAndWeeds />
      <OutgoingProducts />
      <TrainPlanRecordTable />
      <QueenslandBiosecurityTable form={props.form}/>
      <DiseaseSpecificPracticesTable />
    </Table3Wrapper>
  );
};

export default Table3;