import {
    BoldOutlined,
    CheckSquareOutlined,
    SwitcherOutlined,
    CaretDownOutlined,
    CheckOutlined,
    AimOutlined,
    FieldNumberOutlined,
    CalendarOutlined,
    EditOutlined,
    ShakeOutlined,
  } from "@ant-design/icons";
  import SignaturePad from '@iso/components/SignaturePad/index';
  import DragDropQuestion from "@iso/components/DragDropQuestion/DragDropQuestion";
  
  export const formInput = {
    text_box: {
      label: 'Text box',
      value: 'text_box',
      icon: <BoldOutlined />,
      widget: 'input',
    },
    checkbox: {
      label: 'Checkbox',
      value: 'checkbox',
      icon: <CheckOutlined />,
      widget: 'checkbox',
    },
    switch: {
      label: 'Switch',
      value: 'switch',
      icon: <SwitcherOutlined />,
      widget: 'switch',
    },
    select: {
      label: 'Select',
      value: 'select',
      icon: <CaretDownOutlined />,
      widget: 'select',
    },
    checkbox_group: {
      label: 'Checkbox Group',
      value: 'checkbox_group',
      icon: <CheckSquareOutlined />,
      widget: 'checkbox-group',
    },
    radio_group: {
      label: 'Radio Group',
      value: 'radio_group',
      icon: <AimOutlined />,
      widget: 'radio-group',
    },
    textarea: {
      label: 'Textarea',
      value: 'textarea',
      icon: <BoldOutlined />,
      widget: 'textarea',
    },
    number: {
      label: 'Number',
      value: 'number',
      icon: <FieldNumberOutlined />,
      widget: 'number',
    },
    date_picker: {
      label: 'Date Picker',
      value: 'date_picker',
      icon: <CalendarOutlined />,
      widget: 'date-picker',
    },
    signature: {
      label: 'Signature',
      value: 'signature',
      icon: <EditOutlined />,
      widget: SignaturePad,
    },
    // drag_drop: {
    //   label: 'Drag & Drop',
    //   value: 'drag_drop',
    //   icon: <ShakeOutlined />,
    //   widget: DragDropQuestion,
    // },
  }