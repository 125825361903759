import React from "react";
import {
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Select,
  Radio,
  Card,
} from "antd";
import _ from "lodash";
import IntlMessages from "@iso/components/utility/intlMessages";
import SignaturePad from "@iso/components/SignaturePad/index";

const { Option } = Select;

const MoreDetail = (props) => {
  const renderUsers = () => {
    let options = [];
    if (props.users && props.visitors) {
      const userList = props.users.concat(props.visitors);
      _.forEach(userList, (user, index) => {
        options.push(
          <Option key={index} value={user.id + "-" + user.user_type}>
            <div>
              {user.name} -{" "}
              {user.user_type === "visitor" ? "Visitor" : "Register User"}
            </div>
          </Option>
        );
      });
    }
    return (
      <Select placeholder="Select a user" allowClear size="large">
        {options}
      </Select>
    );
  };

  return (
    <Card>
      <h3 className="form-title">
        <IntlMessages id="More Detail" />
      </h3>
      <Form.Item
        name={["more_detail", "has_reported"]}
        label={<IntlMessages id="Has the injury been reported to the worker’s supervisor ?" />}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes"> Yes </Radio>
          <Radio value="no"> No </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name={["more_detail", "was_any_treatment"]}
        label={<IntlMessages id="Was any treatment provided ?" />}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes"> Yes </Radio>
          <Radio value="no"> No </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name={["more_detail", "did_return_to_work"]}
        label={<IntlMessages id="Did the injured worker return to work following the injury/illness ?" />}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes"> Yes </Radio>
          <Radio value="no"> No </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name={["more_detail", "user"]}
        className="isoInputWrapper"
        label={<IntlMessages id="Details of person making this entry" />}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        {renderUsers()}
      </Form.Item>
      <Row>
        <Col xs={{ span: 24 }} md={{ span: 11 }}>
          <Form.Item
            name={["more_detail", "first_name"]}
            className="isoInputWrapper"
            label={<IntlMessages id="First Name" />}
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <Input size="large" placeholder="Name" />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
          <Form.Item
            name={["more_detail", "last_name"]}
            className="isoInputWrapper"
            label={<IntlMessages id="Last Name" />}
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <Input size="large" placeholder="Name" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} md={{ span: 11 }}>
          <Form.Item
            name={["more_detail", "date_of_birth"]}
            className="isoInputWrapper"
            label={<IntlMessages id="Date Of Birth" />}
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <DatePicker size="large" format="YYYY-MM-DD" />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
          <Form.Item
            name={["more_detail", "position"]}
            className="isoInputWrapper"
            label={<IntlMessages id="Position" />}
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <Input size="large" placeholder="Position" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name={["more_detail", "department_or_team"]}
        className="isoInputWrapper"
        label={<IntlMessages id="Department Or Team" />}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <Input size="large" placeholder="Department Of Team" />
      </Form.Item>
      <Form.Item
        name={["more_detail", "signature_of_worker"]}
        label={<IntlMessages id="Signature Of Worker" />}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <SignaturePad tableName={"Signature Of Worker"}></SignaturePad>
      </Form.Item>
      <Form.Item
        name={["more_detail", "date"]}
        className="isoInputWrapper"
        label={"Date"}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <DatePicker size="large" format="YYYY-MM-DD" />
      </Form.Item>
      <Form.Item
        name={["more_detail", "did_witness_the_injury"]}
        label={<IntlMessages id="If you are not the injured worker, did you witness the injury/illness ?" />}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes"> Yes </Radio>
          <Radio value="no"> No </Radio>
        </Radio.Group>
      </Form.Item>
    </Card>
  );
};

export default MoreDetail;
