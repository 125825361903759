import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Popover,Row,Col,message } from 'antd';
import { activityPolicy } from '@iso/constants/activityPolicy';
import { hazardTypes } from '@iso/constants/hazardTypes';
import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { healthsafety } from '@iso/services';
import styled from "styled-components";
import TrafficLight from '@iso/containers/Pages/SafeFarm/TrafficLight';
import TrafficLightGroupWrap from './TrafficLightGroup.styles'; 
import Box from '@iso/components/utility/box';


const TrafficLightGroup = (props) => {
  const {propertyId} = props;

  const [inductionColor,setInductionColor] = useState();
  const [safetyWalkColor,setSafetyWalkColor] = useState();
  const [toolboxMeetingColor,setToolboxMeetingColor] = useState();
  const [visibleModalActivity,setVisibleModalActivity] = useState(false);

  useEffect(() => {
    if(propertyId){
      trafficLight(propertyId);
    }
  },[propertyId]);

  const trafficLight = (propertyId) => {
    healthsafety.trafficLight(propertyId).then(res => {
      if(res.code === '0000'){
          setToolboxMeetingColor(res.toolbox_meeting_color);
          setSafetyWalkColor(res.safety_walk_color);
          setInductionColor(res.induction_color);
        }else{
          message.error(res.message)
        }
    });
  }

  const popoverSafetyWalk =(
    <div>
      <div style={{display:'inline-flex'}}>
        <ColorItemPreview style={{backgroundColor: '#00965b'}}></ColorItemPreview> &nbsp;&nbsp; The last 30 days
      </div> <br/> 
      <div style={{display:'inline-flex'}}>
        <ColorItemPreview style={{backgroundColor: '#f6cf67'}}></ColorItemPreview> &nbsp;&nbsp; 30 - 90 days 
      </div><br/>   
      <div style={{display:'inline-flex'}}>
        <ColorItemPreview style={{backgroundColor: '#ea5252'}}></ColorItemPreview> &nbsp;&nbsp; Beyond 90 days
      </div>
    </div>
  );  
  const popoverToolBoxMeeting =(
    <div>
      <div style={{display:'inline-flex'}}>
        <ColorItemPreview style={{backgroundColor: '#00965b'}}></ColorItemPreview> &nbsp;&nbsp; The last 21 days 
      </div> <br/> 
      <div style={{display:'inline-flex'}}>
        <ColorItemPreview style={{backgroundColor: '#f6cf67'}}></ColorItemPreview> &nbsp;&nbsp; 21 - 50 days 
      </div><br/>   
      <div style={{display:'inline-flex'}}>
        <ColorItemPreview style={{backgroundColor: '#ea5252'}}></ColorItemPreview> &nbsp;&nbsp; Beyond 50 days 
      </div>
    </div>
  );  
  const popoverIndutions = (
    <div>
      <div style={{display:'inline-flex'}}>
        <ColorItemPreview style={{backgroundColor: '#00965b'}}></ColorItemPreview> &nbsp;&nbsp; The last 14 days
      </div> <br/> 
      <div style={{display:'inline-flex'}}>
        <ColorItemPreview style={{backgroundColor: '#f6cf67'}}></ColorItemPreview> &nbsp;&nbsp; 14 - 30 days 
      </div><br/>   
      <div style={{display:'inline-flex'}}>
        <ColorItemPreview style={{backgroundColor: '#ea5252'}}></ColorItemPreview> &nbsp;&nbsp; Beyond 30 days
      </div>
    </div>
  );
  return(
    <TrafficLightGroupWrap>
      <Box className="statistic-card"> 
        <Row>
          <Col xs={{span: 12}} md={{span: 12}} lg={{span: 8}}>
            <Popover content={popoverSafetyWalk} title="Info">
              <span className="traffic-light-label"> <IntlMessages id="safeFarmPage.dashboard.safetyWalk" /></span>
            </Popover>
            <TrafficLight color={safetyWalkColor} />
          </Col> 
          <Col xs={{span: 12}} md={{span: 12}} lg={{span: 8}}>
            <Popover content={popoverToolBoxMeeting} title="Info">
              <span className="traffic-light-label"><IntlMessages id="safeFarmPage.dashboard.toolboxMeeting" /></span>
            </Popover>
            <TrafficLight color={toolboxMeetingColor} />
          </Col>  
          <Col xs={{span: 12}} md={{span: 12}} lg={{span: 8}}>
            <Popover content={popoverIndutions} title="Info">
              <span className="traffic-light-label"><IntlMessages id="safeFarmPage.dashboard.inductions" /></span>
            </Popover>
            <TrafficLight color={inductionColor} />
          </Col> 
        </Row>
      </Box>
    </TrafficLightGroupWrap>
  )
}
export default TrafficLightGroup;

const ColorItemPreview = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;