import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const QualificationsWrapper = styled.div`
  &.user-access-modal{
    width: 50%!important;
   
  }
  .ant-modal-body{
    padding: 0;
  }
  .btn-success{
    background-color: #65bb38;
    border-color: #559f2f;    
  }
  .form-title{
    padding: 0px 24px 24px 0px;
    display: flex;
    align-items: center;
    margin: auto;
    // padding: 16px 24px;
    // border-bottom: 1px solid #eaeef1;
    h3{
      color:rgb(248,14,70);
      width: 100% ;
      font-size: 2rem;
    }
    h2{
      // color:rgb(248,14,70);
      width: 100% ;
      font-size: 2.5rem;
    }
  }
  .form-body{
    padding: 12px 24px 12px 24px;
    .checkbox-contact{
      margin-bottom: 10px;
    }
  }
  .profile-area{
    background-color:white;
    width:50%;
  }

  .react-collapsible tbody{
    word-break: break-word;
  }

  .react-collapsible tbody > tr > td >span{
    display: inline-block;
  }
  .AddQualification{
    display: flex !important;
    width: 183px !important;
    height: 40px !important;
    padding: 8px !important;
    justify-content: center;
    align-items: center;
    gap: 10px !important;
    border-radius: 8px !important;
background: var(--Primary-Color, rgb(248, 14, 70) );
  }
#containerBox h4{
  padding: 15px !important;
}

@media only screen and (max-width: 425px) {
 
  .form-title{
    display:block;
  }
  .form-title h2 {
    text-align:center;
  }
  .AddQualification{
    margin-left: 10px;
    margin-top: 20px;
    width: 100% !important;
  }
}


`;

export default WithDirection(QualificationsWrapper);