import styled from "styled-components";
import Modal from "@iso/components/Feedback/Modal";
import WithDirection from "@iso/lib/helpers/rtl";

const SignaturePadWrapper = styled(Modal)`
  input{
    margin-top: 8px
  }
  #signature-label{
    padding: 12px 24px 12px 24px;
  }
  #canvas-area{
    margin-top: 8px;
    border-top: 1px solid #f80e46;
    border-bottom: 1px solid #f80e46;
   
  }
  .btn-success {
    width: 50%;
    background-color:#f80e46;
    padding: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    color: rgb(255, 255, 255);
    text-align: center;
    height: auto;
    border: transparent;
    &:hover {
      opacity: 0.8;
    }
  }
  .ant-modal-body {
    padding: 0;
  }
  .form-title {
    padding: 16px 24px;
    border-bottom: 1px solid #eaeef1;
    h3 {
      font-size: 1rem;
    }
  }
  .form-body {
    .checkbox-contact {
      margin-bottom: 10px;
    }
  }
  .name-group{
    padding: 12px 24px 12px 24px;
  }
  &.mob-cannot-create .ant-btn-primary {
    display: none;
  }
  .btn-create-breed {
    background-color: #46afca;
    border-color: #46afca;
    &:hover {
      opacity: 0.8;
    }
    margin-top: 30px;
    width: 100%;
    padding: 0;
  }
  .card_content {
    display: inline;
  }

  .card_value {
    float: right;
  }


  .button-group {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .image-gallery {
    padding: 5px;
  }
  .btn-success {
    width: 50%;
    background-color: #f80e46 !important;
    padding: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    color: rgb(255, 255, 255);
    text-align: center;
    height: auto;
    border: transparent;
  }
  .preview-signature{
    margin: 20px;
  }
  .signature-action-column{
    width: 10%
  }
  .remove-signature-btn{
    background-color: #FF4d4F;
    color: white;
    border: none
  }
`;

export default WithDirection(SignaturePadWrapper);