import React  from 'react';
import Box from "@iso/components/utility/box";
import IntlMessages from "@iso/components/utility/intlMessages";
import _ from "lodash";
import Table from "@iso/components/collapsing-table/table";
import styled from "styled-components";
import { Fragment } from "react";
import moment from "moment";
import ManagePropertyPage from './Partial.styles'
const TaskBox = (props) => {
    return (
        <ManagePropertyPage>
        <Fragment>
            <Box id="containerBox">
            <div className="manage-property-header">
            <div className="mp-title">
              <h2 className="manage-property-title">Task</h2></div>
            </div>
                {props.search_result &&
                props.search_result.Task &&
                props.search_result.Task.length > 0 ? (
                    <div className="manage-table">
                    <Table
                        columns={task_columns}
                        rows={props.search_result.Task}
                        containerId="containerBox"
                        showPagination={false}
                        rowSize={99999}
                    />
                    </div>
                ) : (
                    <h1 style={{ padding: "10px 10px 20px", fontSize: '18px' }}>
                    <IntlMessages id="dashboardPage.noData" />
                  </h1>
                )}
            </Box>
        </Fragment>
        </ManagePropertyPage>
    )
}

export default TaskBox

const ColorItemRow = styled.div`
    display: flex;
    align-items: center;
    line-height: 30px;
    div {
        margin: 4px;
    }
`;

const ColorItemPreview = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 50%;
`;

const task_columns = [
    {
        label: <IntlMessages id="antTable.title.title" />,
        accessor: "title",
        minWidth: 100,
        sortable: true,
        position: 1,
        priorityLevel: 1,
    },
    {
        label: <IntlMessages id="antTable.title.details" />,
        accessor: "details",
        minWidth: 100,
        sortable: true,
        position: 2,
        priorityLevel: 2,
    },
    {
        label: <IntlMessages id="antTable.title.status" />,
        accessor: "status",
        minWidth: 100,
        sortable: true,
        position: 3,
        priorityLevel: 3,
    },
    {
        label: <IntlMessages id="antTable.title.priority" />,
        accessor: "priority",
        minWidth: 100,
        sortable: true,
        position: 3,
        priorityLevel: 3,
    },
    {
        label: <IntlMessages id="antTable.title.tags" />,
        accessor: "tags",
        minWidth: 100,
        sortable: true,
        position: 5,
        priorityLevel: 5,
    },
    {
        label: <IntlMessages id="antTable.title.due_date" />,
        accessor: "due_date",
        minWidth: 100,
        sortable: true,
        position: 6,
        priorityLevel: 6,
        CustomComponent: (cell) => {
            let mob = cell.row;
            return mob.due_date
                ? moment(mob.due_date).format("DD/MM/YYYY")
                : "";
        },
    },
];