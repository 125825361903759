import styled from "styled-components";
import { palette } from "styled-theme";
import WithDirection from "@iso/lib/helpers/rtl";
import { handleShowRandomImage } from "@iso/constants/randomBackgrounds";
import "./responsive.css";

const SignUpStyleWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  background: url(${handleShowRandomImage}) no-repeat center center;
  background-size: cover;

  .btn-success {
    background-color: #65bb38;
    border-color: #559f2f;
    &:hover {
      opacity: 0.8;
    }
  }

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    display: flex;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    z-index: 1;
    top: 0;
    left: ${(props) => (props["data-rtl"] === "rtl" ? "inherit" : "0")};
    right: ${(props) => (props["data-rtl"] === "rtl" ? "0" : "inherit")};
  }

  .isoSignUpContentWrapper {
    width: 40%;
    height: 100%;
    overflow-y: auto;
    z-index: 10;
    position: relative;
    &.w-unset {
      width: unset;
    }
  }

  .isoSignUpContent {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    // padding: 70px 50px;
    position: relative;
    background-color: #ffffff;
    justify-content: center;

    .showMsg {
      .icon-content {
        a {
          color: rgba(0, 0, 0, 0.65);
        }
      }
      .text-content {
        margin-top: 15px;
        .msg-helper {
          margin-top: 35px;
          p {
            font-size: 14px;
            color: ${palette("text", 2)};
            margin-bottom: 10px;

            &.link-resend {
              color: #000;
              cursor: pointer;
              text-decoration: underline;
              &:hover {
                color: ${palette("text", 2)};
              }
            }
          }
        }
      }
    }

    @media only screen and (max-width: 1024px) {
      .isoSignUpPage {
        background: unset;
      }

      .isoSignUpContentWrapper {
        width: 100% !important;
      }

      .signup-form-main {
        max-width: unset !important;
      }
    }

    @media only screen and (max-width: 767px) {
      width: 100%;
      // padding: 70px 20px;
    }

    .signup-form-main {
      width: 100%;
      max-width: 407px;
      margin: 0 auto;
    }

    .isoLogoWrapper {
      width: 100%;
      display: flex;
      // margin-bottom: 50px;
      margin-bottom: 30px;
      justify-content: center;
      flex-shrink: 0;

      a {
        font-size: 24px;
        font-weight: 300;
        line-height: 1;
        text-transform: uppercase;
        color: ${palette("secondary", 2)};
      }
    }

    .isoSignUpForm {
      width: 100%;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;

      .form-field > div {
        display: unset;
      }

      .ant-form-item-label
        > label.ant-form-item-required:not(
          .ant-form-item-required-mark-optional
        )::before,
      .form-field label::after {
        content: unset;
      }
      .ant-form-item-label {
        label {
          font-family: "Work Sans", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #3f3f44;
        }
      }
      .signup-label {
        font-family: "Work Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 40px;
        color: #3f3f44;
        padding-bottom: 16px;
      }

      .password-placeholder > input {
        height: 30px;
        background: transparent;
        font-size: 14px;
        padding-left: 10px;
      }
      .ant-input {
        &::placeholder {
          color: black !important;
        }
      }
      .signup-placeholder {
        font-family: "Work Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: black;
        height: 58px;
        background: #f7f7f7;
        border-radius: 8px !important;
        border: none;
        width: 100%;
        padding: 24px;
      }
      .ant-form {
        .ant-checkbox-wrapper {
          span {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #344054;
          }
        }
        .ant-btn {
          font-family: "Work Sans", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
          height: 56px;
          background-color: #535763;
          &:hover {
            background-color: #535763;
            opacity: 1;
          }
        }
        .signin-span {
          font-family: "Work Sans", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #3f3f44;
        }
        .ant-form-item:nth-child(7) {
          .ant-form-item-control-input {
            min-height: unset;
          }
        }
      }
      .isoInputWrapper {
        // margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        input {
          &::-webkit-input-placeholder {
            color: ${palette("grayscale", 0)};
          }

          &:-moz-placeholder {
            color: ${palette("grayscale", 0)};
          }

          &::-moz-placeholder {
            color: ${palette("grayscale", 0)};
          }
          &:-ms-input-placeholder {
            color: ${palette("grayscale", 0)};
          }
        }
      }

      .isoLeftRightComponent {
        input {
          width: calc(100% - 10px);

          &:first-child {
            margin-right: ${(props) =>
              props["data-rtl"] === "rtl" ? "inherit" : "20px"};
            margin-left: ${(props) =>
              props["data-rtl"] === "rtl" ? "20px" : "inherit"};
          }
        }
      }

      .isoHelperWrapper {
        // margin-top: 35px;
        flex-direction: column;
      }

      .isoForgotPass {
        font-size: 12px;
        color: ${palette("text", 2)};
        margin-bottom: 10px;

        &:hover {
          color: ${palette("primary", 0)};
        }
      }

      button {
        font-weight: 500;
        width: 100%;
        height: 42px;
        border: 0;

        &.btnFacebook {
          background-color: ${palette("color", 7)};

          &:hover {
            background-color: ${palette("color", 8)};
          }
        }

        &.btnGooglePlus {
          background-color: ${palette("color", 9)};
          margin-top: 15px;

          &:hover {
            background-color: ${palette("color", 10)};
          }
        }

        &.btnAuthZero {
          background-color: ${palette("color", 11)};
          margin-top: 15px;

          &:hover {
            background-color: ${palette("color", 12)};
          }
        }

        &.btnFirebase {
          background-color: ${palette("color", 5)};
          margin-top: 15px;

          &:hover {
            background-color: ${palette("color", 6)};
          }
        }

        &.btnAccountKit {
          ${"" /* background-color: rgb(150, 189, 235); */}
          margin-top: 15px;

          &:hover {
            ${"" /* background-color: ${palette('color', 6)}; */}
          }
        }
      }
    }
    .showMsg {
      text-align: center;
      padding: 30px;
    }
  }
`;

export default WithDirection(SignUpStyleWrapper);
