import styled from "styled-components";
import WithDirection from "@iso/lib/helpers/rtl";
import "gantt-task-react/dist/index.css";

const TaskManagePage = styled.div`
  width: 100%;
  height: 100%;
  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    position: absolute;
    top: 2%;
    left: 40%;
  }
  .react-trello-lane {
    background: #ffffff;
    /* Primary/Light Grey */

    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    /* Shadow SM */

    box-shadow: 1px 1px 10px rgba(229, 229, 229, 0.6);
    border-radius: 10px;
  }

  header span {
    color: #f80e46;
  }

  .react-trello-card {
    background: rgb(242, 246, 246);
    box-shadow: rgb(239 222 92) 3px 0px 0px inset;
    border-radius: 8px;

    header span:first-child:before {
      content: "\u2661 ";
      font-size: 15px;
    }
  }

  .smooth-dnd-draggable-wrapper article {
    /* Light Gray */

    background: #f2f6f6;
    box-shadow: inset 3px 0px 0px #efde5c;
    border-radius: 8px;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 10px 0px;
  }

  .margin-top-12 {
    margin-top: 12px;
  }

  .margin-bottom-12 {
    margin-bottom: 12px;
  }

  .ant-radio-group {
    &.btn-success {
      .ant-radio-button-wrapper {
        &:hover {
          color: #65bb38;
        }
      }
      .ant-radio-button-wrapper-checked {
        background-color: #65bb38;
        border-color: #559f2f;

        &:hover {
          color: #fff;
        }

        :not(.ant-radio-button-wrapper-disabled)::before {
          background-color: #65bb38;
        }
      }
    }
  }

  .new-task-block {
    margin-top: 10px;
    .ant-collapse-header-text {
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      font-size: 1.17em;
    }
  }

  .toggle-row {
    padding :0px 24px 30px 24px !important;
    padding: 20px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn-new-task {
      text-align: right;
      // right: 5%;
      // top: 0;
      // position: absolute;
      background-color: #f80e46;
      color: #ffffff;
      border-radius: 10px !important;
      height: 40px;
      &:hover {
        color: #f80e46;
        background-color: #ffffff;
      }
    }
    .task-title{
      color: rgb(16, 24, 40);
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    }
  }
  .task_box {
    padding: 5px 24px;
  }
`;

export default WithDirection(TaskManagePage);
