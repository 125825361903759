
import React, { useEffect, useLayoutEffect } from "react";
import LayoutWrapper from "@iso/components/utility/layoutWrapper.js";
import Box from "@iso/components/utility/box";
import PageHeader from "@iso/components/utility/pageHeader";
import IntlMessages from "@iso/components/utility/intlMessages";
import { useSelector, useDispatch } from "react-redux";
import { configs } from "@iso/components/Tables/configs";
import { Button, Divider, Dropdown, Menu, Popconfirm, Select, Pagination, Input } from "antd";

import EmployeePageWrapper from "./employee.styles";
import {
  SearchOutlined,
  CloseCircleOutlined,
  EllipsisOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import propertyActions from "@iso/redux/property/actions";
import _, { property } from "lodash";
import Table from "@iso/components/collapsing-table/table";
import { employeeService } from '@iso/services';

import { visitorService } from "../../../services/visitor.service";
import { propertyService } from "../../../services/property.service";

import "../../../components/Paginations.css"
import Loader from "../../../components/collapsing-table/components/Loader";
import ViewQualifications from "./ViewQualifications";
import ViewWorkingRights from "./ViewWorkingRights";
const { changeActiveProperty } = propertyActions;

const Employee = (props) => {
  const dispatch = useDispatch();
  const activePropertyId = useSelector(
    (state) => state.property.activePropertyId
  );
 
  const activeProperty = JSON.parse(localStorage.getItem("active_property"));
  const { Option } = Select;
  const [properties, setProperties] = React.useState([]);
  const [viewSummary, setViewSummary] = React.useState({});
  const [employee, setEmployee] = React.useState([]);
  const [visibleQualificationsModal, setVisibleQualificationsModal] = React.useState(false);
  const [visibleWorkingRights,setVisibleWorkingRights]= React.useState(false);
  const [loadingData, setLoadingData] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState();
  const [searchInput, setsearchInput] = React.useState('');
  const [visibleSelectProperty, setVisiblitySelectProperty] =
    React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
 

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const displayedVisitor = searchQuery?.slice(startIndex, endIndex);

  const handlePaginationChange = (page, size) => {
    setCurrentPage(1);
    setPageSize(size);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const menuList = (team, permission) => (
    <Menu onClick={handleMenuClick.bind(this, team)}>
      <Menu.Item key="ViewQualifications" icon={<EyeOutlined />}>
        <IntlMessages id="View Qualifications" />
      </Menu.Item>
      <Menu.Item key="ViewWorkingRights" icon={<EyeOutlined />}>
        <IntlMessages id="View Working Rights" />
      </Menu.Item>
    </Menu>
  );
  const handleMenuClick = (team, e) => {
   
    if (e.key === 'ViewQualifications') {
      console.log("Team",team);
      setVisibleQualificationsModal(true)
      setViewSummary(team)
    }
    if (e.key === 'ViewWorkingRights') {
      setVisibleWorkingRights(true)
      setViewSummary(team)
    }
  }
  const columns = [
    {
      label: "Name",
     accessor: "name" ,
      minWidth: 100,
      sortable: true,
      position: 1,
      priorityLevel: 1,
    },
    {
      label: "Email",
      accessor: "email",
      minWidth: 100,
      sortable: true,
      position: 2,
      priorityLevel: 2,
    },
    {
      label:'Contact Number',
      accessor: "mobile_phone",
      minWidth: 100,
      sortable: true,
      position: 3,
      priorityLevel: 3,
    },
    {
      label: 'Emergency Contact Name',
      accessor: "emergency_contact_name",
      minWidth: 100,
      sortable: true,
      position: 3,
      priorityLevel: 3,
    },
    {
      label: 'Emergency Contact Number',
      accessor: "emergency_contact_number",
      minWidth: 100,
      sortable: true,
      position: 5,
      priorityLevel: 5,
    },

    {
      label: "",
      accessor: "dot-menu",
      minWidth: 100,
      sortable: true,
      position: 4,
      priorityLevel: 4,
      noTitle: true,
      CustomComponent: (cell) => {
        let row = cell.row;
        // let permission = permissionService.getPermission(row.permission_id);
        return (
          <Dropdown overlay={menuList(row)} trigger={["click"]}>
            <div
              className="property-area-dot-btn"
              style={{ cursor: "pointer" }}
            >
              <EllipsisOutlined  className="dotIcon"/>
            </div>
          </Dropdown>
        );
      },
    },
  ];
  const handleOptionClick = (value) => {
    const property = properties.find(property => property.id == value);
    if (property) {
      localStorage.setItem("active_property", JSON.stringify(property));
      dispatch(changeActiveProperty(property));
    }
  };
  useEffect(() => {
    if (activePropertyId) {
      getEmployee()
    }
  },[activePropertyId])

  const getEmployee = () => {
    employeeService.getList(activePropertyId,1).then(res => {
      if (res.code === '0000') {
        setEmployee(res?.users);
        setSearchQuery(res?.users);
      }
    })
  }

  useEffect(() => {
    propertyService.getProperties().then((res) => {
      if (res.properties.length === 0) {
        setVisiblitySelectProperty(true);
      } else {
        setProperties(res.properties);
        if (res.properties.length === 1) {
          localStorage.setItem(
            "active_property",
            JSON.stringify(res.properties[0])
          );
          dispatch(changeActiveProperty(res.properties[0]));
        }
      }
    });
  }, [activePropertyId]);

  const renderOptionsProperty = () => (
    <Select
      placeholder="Select Active Property"
      value={activeProperty.name}
      size="large"
      style={{ width: 150 }}
      onChange={handleOptionClick}
    >
      {properties?.map((property, index) => (
        <Option key={index} value={property.id} className="property-option">
          {property.name}
        </Option>
      ))}
    </Select>
  );
  
  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setsearchInput(e.target.value);
    
    // Filter the employee data based on the search query
    const filteredEmployee = employee.filter((employee) => {
      return (
        employee.name && employee.name.toLowerCase().includes(searchInput.toLowerCase()) ||
        employee.email && employee.email.toLowerCase().includes(searchInput.toLowerCase()) ||
        employee.mobile_phone && employee.mobile_phone.toLowerCase().includes(searchInput.toLowerCase()) ||
        employee.emergency_contact_name && employee.emergency_contact_name.toLowerCase().includes(searchInput.toLowerCase()) ||
        employee.emergency_contact_number && employee.emergency_contact_number.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    setSearchQuery(filteredEmployee);
  };

  const handleCancelSearch = () => {
    // Clear the search input and reset the employee data
    setsearchInput('');
    setSearchQuery(employee);
    // getEmployee();
  };

  return (
    <LayoutWrapper>
      <EmployeePageWrapper>
        <div className="visiter_table_main">
          <div className="top_title_visiter">
            <PageHeader>{<IntlMessages id="Team" />}</PageHeader>
            <div className="button-group">
            <Input
                placeholder="Search…"
                suffix={!searchInput && <SearchOutlined className="site-form-item-icon" />}
                prefix={searchInput && <CloseCircleOutlined onClick={handleCancelSearch} className="site-form-item-cancel" />}
                size="large"
                className="search-bar"
                value={searchInput}
                onChange={(e) => handleSearch(e)}
               
              />
              {renderOptionsProperty()}
            </div>
          </div>
          <Box className="table_visiter" id="containerBox">
            {displayedVisitor && displayedVisitor.length ? (
              <>
                {loadingData ? (
                  <Loader/>
                )

                  :(<><Table
                
                    columns={columns}
                      rows={displayedVisitor}
                    containerId="containerBox"
                    showPagination={false}
                    rowSize={99999}
                  />
                    <div style={{ margin: '20px' }}>
                      <Pagination
                        current={currentPage}
                        total={searchQuery.length}
                        pageSize={pageSize}
                        onChange={handlePageChange}
                        showSizeChanger
                        pageSizeOptions={["10", "20", "50", "100"]}
                        onShowSizeChange={handlePaginationChange}
                      />
                    </div></>) }
              </>
            ) : (
              <h1 style={{ fontSize: '18px', padding: '15px', fontWeight: '600' }}>
                <IntlMessages id="dashboardPage.noData" />
              </h1>
            )}
          </Box>
        </div>
        <ViewQualifications visibleQualificationsModal={visibleQualificationsModal}
        viewSummary={viewSummary}
        setViewSummary= {setViewSummary}
        setVisibleQualificationsModal={setVisibleQualificationsModal}/>
        <ViewWorkingRights
        viewSummary={viewSummary}
        setViewSummary= {setViewSummary}
        setVisibleWorkingRights= {setVisibleWorkingRights}
        visibleWorkingRights={visibleWorkingRights}
        />
      </EmployeePageWrapper>

    </LayoutWrapper>
  );
};

export default Employee;
