import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const MapLocationPage = styled(Modal)`

// width : 500px !important;


  &.visitor-modal{
    width: 500px !important;
  }
  
  .ant-modal-header {
    display: flex;
}
  button.ant-btn.ant-btn-default.cancel-button{
    width: 100% !important;
  }
  .ant-btn-primary{
    background-color: #729D68 !important;
  }
  .ant-modal-body{
    padding: 0;
  }
  .btn-success{
    background-color: #f80e46 !important;
    border-color: #f80e46;
  }
  .form-title{
    padding: 16px 24px;
    border-bottom: 1px solid #eaeef1;
    h3{
      font-size: 1rem;
      color: #729D68;
    }
  }
  .form-body{
    // padding: 12px 24px 12px 24px;
    padding : 12px 24px;

    .checkbox-contact{
      margin-bottom: 10px;
    }
  }
  .form-body .leaflet-container .leaflet-google-mutant{
     visibility: visible !important;
     z-index : 1 !important;   
  }

`;

export default WithDirection(MapLocationPage);