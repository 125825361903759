import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import AssetModalWrapper from './AssetModal.styles';
import { Form, Input, Button, Select, Row, Col, DatePicker, Tabs, Card, Timeline, Popconfirm, InputNumber } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import modalActions from '@iso/redux/modal/actions';
import Loader from '@iso/components/utility/loader';
import { activityService } from '@iso/services';
import moment from 'moment';
import _ from 'lodash';
import styled from "styled-components";
import { dateHelper } from '@iso/lib/helpers/dateHelper';
import L from 'leaflet';
import { assetHistoryCategories } from '@iso/constants/assetHistoryCategories';
import { assetHistoryTypes } from '@iso/constants/assetHistoryTypes';

const { Option } = Select;
const { closeModal } = modalActions;
const { TabPane } = Tabs;

const AddAssetHistoryModal = (props) => {
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const dispatch = useDispatch();
  const modalVisibility = useSelector((state) => state.modal.modalVisibility);
  const modalData = useSelector((state) => state.modal.modalData);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [assetData, setAssetData] = useState({});
  
  const cancelModal = () => {
    props.cancelModal();
    setLoading(false);
    form.resetFields();
  }

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
  }

  const onStore = (values) => {
    const history_data = {
      "history":{
        metadata: values.history,
        action:'create',
        model: props.model,
        object_id: props.dataId,
        property_id: props.propertyId
      }
    }
    
    activityService.storeOrUpdate(history_data).then(res => {
      if(res.code === '0000'){
        cancelModal();
        form.resetFields();
        props.updateDataAssetHistory(res.history.object_id, res.history.model);
      } else {
        setLoading(false);
      }
    });
  }

  const renderOptionsAssetHistoryCategory = () => {
    let options = [];
    _.forEach(assetHistoryCategories, (asset_history, index) => {
      options.push(
        <Option key={index} value={asset_history.value}>
          <div>{asset_history.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a category"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const renderOptionsAssetHistoryType = () => {
    let options = [];
    _.forEach(assetHistoryTypes, (type, index) => {
      options.push(
        <Option key={index} value={type.label}>
          <div>{type.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a type"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const renderOptionObject = (objects) => {
    let options = [];
    _.forEach(objects, (object, index) => {
      options.push(
        <Option key={object.id} value={object.name}>
          {object.name}
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select an object"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  return(
    <>
    <AssetModalWrapper
      open={props.visibleHistory}
      onCancel={cancelModal}
      maskClosable={true}
      title= {<IntlMessages id="propertyPage.mobModal.historyTab.addHistory"/>}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit} loading={loading}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
    {
        modalLoading ?
        <Loader /> : 
          <Form
            form={form}
            layout="vertical"
            scrollToFirstError
            fields={fields}
            id="#1"
          >
          <div className="form-body">
            <Row>
              <Col xs={{span: 24}} md={{span: 11}}>
                <Form.Item
                  name={['history', 'activity_category']}
                  className="isoInputWrapper"
                  label="Category"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  {renderOptionsAssetHistoryCategory()}
                </Form.Item>
              </Col>
              <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                <Form.Item
                  name={['history', 'type']}
                  className="isoInputWrapper"
                  label="Type"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  {renderOptionsAssetHistoryType()}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{span: 24}} md={{span: 11}}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => {
                    return (prevValues['history']);
                  }}
                >
                  {({ getFieldValue }) => {
                      if(getFieldValue('history')){
                        if (getFieldValue('history').activity_category === "other"){
                          return (
                              <Form.Item
                              name={['history', 'other_category']}
                              className="isoInputWrapper"
                              label="Other Category"
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                  whitespace: true
                                },
                                {
                                  max:255,
                                  message: 'Other Category must be maximum 255 characters.'
                                },
                              ]}
                            >
                              <Input size="large" placeholder="Input other category"/>
                            </Form.Item>
                          );
                        }
                      }
                    }
                  }
                </Form.Item>
              </Col>
              <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => {
                    return (prevValues['history']);
                  }}
                >
                  {({ getFieldValue }) => {
                      if(getFieldValue('history')){
                        if (getFieldValue('history').type === "Other"){
                          return (
                              <Form.Item
                              name={['history', 'other_type']}
                              className="isoInputWrapper"
                              label="Other Type"
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                  whitespace: true
                                },
                                {
                                  max:255,
                                  message: 'Other Type Type must be maximum 255 characters.'
                                },
                              ]}
                            >
                              <Input size="large" placeholder="Input other type"/>
                            </Form.Item>
                          );
                        }
                        if(getFieldValue('history').type === "Hours"){
                          return (
                            <Form.Item
                              name={['history', 'total_hours']}
                              className="isoInputWrapper"
                              label="Total Hours"
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                },
                              ]}
                            >
                              <InputNumber style={{ width: '100%' }} size="large" min={1} max={1000} />
                            </Form.Item>
                          );
                        }
                        if(getFieldValue('history').type === "Volume"){
                          return (
                            <Form.Item
                              name={['history', 'total_volume']}
                              className="isoInputWrapper"
                              label="Total Volume"
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                },
                              ]}
                            >
                              <InputNumber style={{ width: '100%' }} size="large" min={1} max={1000} />
                            </Form.Item>
                          );
                        }
                      }
                    }
                  }
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{span: 24}} md={{span: 11}}>
                <Form.Item
                  name={['history', 'date']}
                  className="isoInputWrapper"
                  label="Date"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <DatePicker size="large" style={{width: '100%'}}/>
                </Form.Item>
              </Col>
              <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                <Form.Item
                  name={['history', 'location']}
                  className="isoInputWrapper"
                  label="Location"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  {renderOptionObject(props.primaryObjects)}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name={['history', 'description']}
              className="isoInputWrapper"
              label="Description"
            >
              <Input.TextArea />
            </Form.Item>
          </div>
      </Form>
      }     
    </AssetModalWrapper>
    </>
  )
}

export default AddAssetHistoryModal;