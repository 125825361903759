export const hazardstatus = [
    {
      label: 'Open',
      value: 'open',
    },
    {
      label: 'To be confirmed',
      value: 'To_be_confirmed',
    },
    {
      label: 'Confirmed',
      value: 'confirmed',
    },
    {
        label: 'Currently being worked on',
        value: 'Currently_being_worked_on',
      },
    {
      label: 'Resolved/Mitigated',
      value: 'Resolved/Mitigated',
    },
    {
      label: 'Need additional help',
      value: 'need_additional_help',
    },
    {
      label: 'Closed/Raised in error',
      value: 'Closed/Raised in error',
    },
    {
      label: 'Other',
      value: 'other',
    }
  ]
