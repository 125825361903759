import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Form,
  message,
  Input,
  Row,
  Col,
  DatePicker,
  Select,
  Radio,
  Tabs,
  Checkbox,
} from "antd";
import _ from "lodash";
import { NearMissFormWrapper } from "./NearMissFormWrapper.style";
import {
  formService,

  propertyService,
  
} from "@iso/services";
import { visitorFormService } from "../../../../../services/visitorform.service";
import { publicFormService } from "../../../../../services/publicform.service";
import IntlMessages from "@iso/components/utility/intlMessages";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { useSelector, useDispatch } from "react-redux";
import SignaturePad from "@iso/components/SignaturePad/index";
import { dateHelper } from "@iso/lib/helpers/dateHelper";
import moment from "moment";

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const NearMissForm = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const mapRef = useRef();
  const [currentTab, setCurrentTab] = useState("1");
  const [primaryObjects, setPrimaryObjects] = useState([]);

  const activePropertySubscription = useSelector(
    (state) => state.subscription.activePropertySubscription
  );

  const updatePropertyDetail = React.useCallback((id) => {
    propertyService.viewProperty(id).then((res) => {
      if (res.code === "0000") {
        form.setFieldValue(["near_miss", "lat"], res.property.lat);
        form.setFieldValue(["near_miss", "lng"], res.property.lng);
        setPrimaryObjects(res.primary_objects);
      }
    });
  }, []);

  const handleSubmit = async () => {
    if (!props.isAbleToSubmit) {
      return;
    }
    if (props.visitorForm) {
      const visitorFormValidation = await props.visitorForm.validateFields();
      if (visitorFormValidation.errorFields) {
        return;
      }
    }
    form
      .validateFields()
      .then((values) => {
        handleFinish(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  useEffect(() => {
    if (props.formData && props.formData.near_miss) {
      const formResult = props.formData.near_miss;
      for (let field in formResult) {
        if (["date_time", "date_reported", "date"].includes(field)) {
          form.setFieldValue(["near_miss", field], moment(formResult[field]));
        } else {
          form.setFieldValue(["near_miss", field], formResult[field]);
        }
      }
    }
  }, [props.formData]);

  const timeConfig = {
    rules: [{ required: true, message: "Please select time!" }],
  };

  const handleFinish = (values) => {
    values.near_miss.date_time = dateHelper.dateTimeForAPI(
      values.near_miss.date_time
    );
    values.near_miss.date_reported = dateHelper.dateTimeForAPI(
      values.near_miss.date_reported
    );
    values.near_miss.date = dateHelper.dateTimeForAPI(
      values.near_miss.date
    );
    const data = {
      form: {
        global_form: values,
        type: "near_miss_form",
      },
    };
    if (props.visitorForm) {
      data.visitor = props.visitorForm.getFieldsValue().visitor;
      data.form.visitor_form_token = props.visitorFormToken;
      visitorFormService.createVisitorForm(data).then(onSuccessCallback);
    } else if (props.isPublicForm) {
      data.form.form_token = props.formToken;
      publicFormService.submitPublicForm(data).then(onSuccessCallback);
    } else {
      formService
        .storeOrUpdate(data, props.formData.id)
        .then(onSuccessCallback);
    }
  };

  const onSuccessCallback = (res) => {
    if (res.code === "0000") {
      message.success("Submit success");
      props.setShowFinalScreen(true);
      setLoading(false);
    } else {
      message.error(res.message);
      setLoading(false);
    }
  };

  const handleClickMap = (e) => {
    const { lat, lng } = e.latlng;
    form.setFieldValue(["near_miss", "lat"], lat);
    form.setFieldValue(["near_miss", "lng"], lng);
  };

  useEffect(() => {
    if (props.propertyId) {
      updatePropertyDetail(props.propertyId);
    }
  }, [props.propertyId]);

  useEffect(() => {
    if (props.property) {
      if (props.property.lat && props.property.lng) {
        form.setFieldValue(["near_miss", "lat"], props.property.lat);
        form.setFieldValue(["near_miss", "lng"], props.property.lng);
      }
    }
  }, [props.property]);

  const onChange = (key) => {
    setCurrentTab(key);
  };

  const renderOptionObject = () => {
    let options = [];
    _.forEach(primaryObjects, (object, index) => {
      options.push(
        <Option key={object.id} value={object.id}>
          {object.name}
        </Option>
      );
    });
    return (
      <Select placeholder="Select an object" allowClear size="large">
        {options}
      </Select>
    );
  };

  return (
    <NearMissFormWrapper>
      <Form form={form} layout="vertical" fields={fields}>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 11 }}>
            <Form.Item
              name={["near_miss", "date_time"]}
              label={<IntlMessages id="Date and Time of Incident
              " />}
              {...timeConfig}
            >
              <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
            <Form.Item
              name={["near_miss", "witnesses"]}
              className="isoInputWrapper"
              label={<IntlMessages id="Witnesses
              " />}
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input size="large" placeholder="Witness" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => {
            return prevValues["near_miss"];
          }}
        >
          {({ getFieldValue }) => {
            return (
              <div>
                <Tabs defaultActiveKey="1" onChange={onChange}>
                  <TabPane tab="Area" key="1">
                    {currentTab == 1 && (
                      <Col xs={{ span: 24 }} md={{ span: 11 }}>
                        <Form.Item
                          name={["near_miss", "primary_object_id"]}
                          className="isoInputWrapper"
                          label="Location"
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          {renderOptionObject(props.primaryObjects)}
                        </Form.Item>
                      </Col>
                    )}
                  </TabPane>

                  <TabPane tab="Location" key="2">
                    <Map
                      ref={mapRef}
                      center={
                        getFieldValue(["near_miss", "lat"]) &&
                        getFieldValue(["near_miss", "lng"])
                          ? [
                              getFieldValue(["near_miss", "lat"]),
                              getFieldValue(["near_miss", "lng"]),
                            ]
                          : ['-37.745377', '144.970810']
                      }
                      doubleClickZoom={false}
                      onclick={handleClickMap}
                      zoom={16}
                      zoomControl={true}
                      style={{ width: "100%", height: "300px" }}
                    >
                      {activePropertySubscription ===
                      process.env.REACT_APP_STRIPE_PLAN_GOLD ? (
                        <ReactLeafletGoogleLayer
                          type={"hybrid"}
                          useGoogMapsLoader={true}
                        />
                      ) : (
                        <TileLayer
                          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                      )}
                      {getFieldValue(["near_miss", "lat"]) &&
                        getFieldValue(["near_miss", "lng"]) && (
                          <Marker
                            position={[
                              getFieldValue(["near_miss", "lat"]),
                              getFieldValue(["near_miss", "lng"]),
                            ]}
                            draggable={true}
                          >
                            <Popup
                              position={[
                                getFieldValue(["near_miss", "lat"]),
                                getFieldValue(["near_miss", "lng"]),
                              ]}
                            ></Popup>
                          </Marker>
                        )}
                    </Map>
                    <Row style={{ marginTop: "10px", marginBottom: "5px" }}>
                      <Col xs={{ span: 24 }} md={{ span: 11 }}>
                        <Form.Item
                          name={["near_miss", "lat"]}
                          className="isoInputWrapper"
                          label="Latitute"
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
                        <Form.Item
                          name={["near_miss", "lng"]}
                          label="Longitude"
                          className="isoInputWrapper"
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                  </TabPane>
                </Tabs>
              </div>
            );
          }}
        </Form.Item>
        <Form.Item
          name={["near_miss", "near_miss_type"]}
          label={<IntlMessages id="Type of Near Miss" />}
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <Checkbox.Group className="type-of-concern">
            <Row gutter={8}>
              <Col>
                <Checkbox value="safety_concern" style={{ lineHeight: "32px" }}>
                  <IntlMessages id="Safety Concern" />
                </Checkbox>
              </Col>
              <Col>
                <Checkbox value="other" style={{ lineHeight: "32px" }}>
                  <IntlMessages id="Safety Idea/Suggestion-Other" />
                </Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item
          name={["near_miss", "type_of_concern"]}
          label={<IntlMessages id="Type of Concern" />}
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <Checkbox.Group className="type-of-concern">
            <div>
              <Checkbox value="unsafe_act" style={{ lineHeight: "32px" }}>
                <IntlMessages id="Unsafe Act" />
              </Checkbox>
            </div>
            <div>
              <Checkbox
                value="unsafe_condition_of_area"
                style={{ lineHeight: "32px" }}
              >
                <IntlMessages id="Unsafe Condition of Area" />
              </Checkbox>
            </div>
            <div>
              <Checkbox
                value="unsafe_condition_of_equipment"
                style={{ lineHeight: "32px" }}
              >
                <IntlMessages id="Unsafe Condition of Equipment" />
              </Checkbox>
            </div>
            <div>
              <Checkbox
                value="unsafe_use_of_equipment"
                style={{ lineHeight: "32px" }}
              >
                <IntlMessages id="Unsafe Use of Equipment" />
              </Checkbox>
            </div>
            <div>
              <Checkbox value="other" style={{ lineHeight: "32px" }}>
                <IntlMessages id="Safety Policy Violation-Other" />
              </Checkbox>
            </div>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item
          name={["near_miss", "description"]}
          className="isoInputWrapper"
          label={<IntlMessages id="Describe the potential incident/hazard/concern and possible outcome (be detailed)" />}
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          name={["near_miss", "was_violated"]}
          className="isoInputWrapper"
          label={<IntlMessages id="Were safety procedures violated?" />}
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          name={["near_miss", "incident_site_inspection"]}
          className="isoInputWrapper"
          label={<IntlMessages id="Incident site inspection – Why was an unsafe act committed, or why was the unsafe condition" />}
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          name={["near_miss", "recommendations"]}
          className="isoInputWrapper"
          label={<IntlMessages id="Recommendations/steps to take to prevent a similar" />}
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <TextArea />
        </Form.Item>

        <Row>
          <Col xs={{ span: 24 }} md={{ span: 11 }}>
            <Form.Item
              name={["near_miss", "date_reported"]}
              label={<IntlMessages id="Date Reported" />}
              {...timeConfig}
            >
              <DatePicker format="YYYY-MM-DD" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
            <Form.Item
              name={["near_miss", "name"]}
              className="isoInputWrapper"
              label={<IntlMessages id="Name" />}
            >
              <Input size="large" placeholder="Witness" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name={["near_miss", "property_owner_signature"]}
          label={<IntlMessages id="Property Owner Signature" />}
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <SignaturePad tableName=""></SignaturePad>
        </Form.Item>
        <Form.Item
          name={["near_miss", "date"]}
          label={<IntlMessages id="Date" />}
          {...timeConfig}
        >
          <DatePicker format="YYYY-MM-DD" />
        </Form.Item>
        <Button
          key="submit"
          className="btn-success"
          type="primary"
          onClick={handleSubmit}
          loading={loading}
        >
          Submit
        </Button>
      </Form>
    </NearMissFormWrapper>
  );
};

export default NearMissForm;
