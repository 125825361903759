import React from 'react';
import { useDispatch } from 'react-redux';
import FinalScreenWrapper from './FinalScreen.styles';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom'
import authAction from '@iso/redux/auth/actions'

const { logout } = authAction;

const FinalScreen = (props) => {
  let history = useHistory()
  const dispatch = useDispatch()
  
  return (
    <FinalScreenWrapper>
      <h3 className="final-msg">You have completed the form, Thank you.</h3>
      <span className="final-desc-msg">You are now able to close the window or explore the platform by creating a free property your self</span>
      <div className="final-button-block">
        <Button type="primary" className="btn-success" size="medium" onClick={() => dispatch(logout())}>
            Close Window
        </Button>
        <Button type="primary" className="btn-success" size="medium" onClick={() => history.push("/manage-properties")}>
            Create my own property map
        </Button>
      </div>
    </FinalScreenWrapper>
  )
}

export default FinalScreen;
