import React, { useState, useEffect } from "react";
import IntlMessages from "@iso/components/utility/intlMessages";
import { Form, Input, Button, Steps } from "antd";
import PreviewModalWrapper from "./PreviewModal.styles";
import FileUploader from "@iso/components/FileUploader/FileUploader";
import { FileStorage } from "@iso/constants/UploadFile";
import { formInput } from "@iso/constants/formBuilder";
import FormBuilder from "antd-form-builder";

const { Step } = Steps;

const PreviewModal = (props) => {
  const { globalForm, formFields, visiblePreviewModal, cancellPreviewModal } = props;
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [stepsLength, setStepsLength] = useState(0);
  const [metaData, setMetaData] = useState([]);

  useEffect(() => {
    const newMetaData = [];
    const reviewFields = [];
    if (formFields) {
     
      formFields.forEach((page, pageIndex) => {
        let fields = [];
        page.formInputs &&
          page.formInputs.length &&
          page.formInputs.forEach((input) => {
            
            input.widget = formInput[input["type"]]["widget"];
            fields.push(input);
          });
        newMetaData.push({
          title: page.title,
          columns: 1,
          fields,
        });
        reviewFields.push(
          {
            key: "review" + pageIndex,
            colSpan: 2,
            render() {
              return (
                <fieldset>
                  <legend>{page.title}</legend>
                </fieldset>
              );
            },
          },
          ...fields
        );
      });
    }
    newMetaData.push({
      title: "Review",
      columns: 2,
      fields: reviewFields,
    });
    setMetaData(newMetaData);
    setStepsLength(newMetaData.length);
    // console.log("newMetaData: ", newMetaData);
  }, [formFields]);

  useEffect(() => {
    setCurrentStep(0)
  }, [visiblePreviewModal])

  const handleNext = () => {
    form.validateFields().then(() => {
      setCurrentStep(currentStep + 1);
    });
  };
  const handleBack = () => {
    form.validateFields().then(() => {
      setCurrentStep(currentStep - 1);
    });
  };

  return (
    <PreviewModalWrapper
      width={1000}
      open={visiblePreviewModal}
      onCancel={cancellPreviewModal}
      title={globalForm?.name ? globalForm?.name : "Preview"}
      footer={[
        
      ]}
    >
      <Form form={form} scrollToFirstError layout="vertical" fields={fields}>
        <div className="form-body">
          <Steps id="form-step" current={currentStep}>
            {metaData && metaData.length ? (
              metaData.map((page) => (
                <Step key={page.title} title={page.title} />
              ))
            ) : (
              <></>
            )}
          </Steps>
          <FormBuilder
            meta={
              metaData.length && metaData[currentStep]
                ? metaData[currentStep]
                : []
            }
            form={form}
          />
          <div className="form-footer" style={{ textAlign: "right" }}>
            {currentStep > 0 && (
              <Button className="btn-back" onClick={handleBack}>
                Back
              </Button>
            )}
            <Button
              type="primary"
              className="btn-success"
              onClick={
                currentStep === stepsLength - 1
                  ? () => form.submit()
                  : handleNext
              }
            >
              {currentStep === stepsLength - 1 ? "Submit" : "Next"}
            </Button>
          </div>
        </div>
      </Form>
    </PreviewModalWrapper>
  );
};
export default PreviewModal;