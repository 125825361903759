import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const WeatherForecastWrapper = styled.div`
  .weather-statistic{
    .statistic-header{
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
     
    }
    .weather-types{
      display: flex;
      overflow: auto
    }
    .weather-type-icon{
      margin-right: 5px
    }
    .weather-type-element{
      cursor: pointer;
      padding: 5px;
      display: flex;
      align-items: center;
      width: fit-content;
      margin-right: 10px;
      min-width: 140px;
      &:hover {
        color:rgb(248, 14, 70) !important;
      }
    }
    .weather-type-active{
      border-bottom: 2px solid  rgb(248, 14, 70);
      * {
        color: rgb(248, 14, 70);
      }
    }
    recharts-wrapper svg.recharts-surface{
      overflow:initial !important;
    }
    // g.raphael-group-jUlUWLSK{
    //   display:none !important;
    // }
    .timezone-by{
      display: flex;
      align-items: center;
      p{
        margin-right: 5px;
      }
    }
  }
  @media only screen and (max-width: 425px) {
   
    .statistic-header{
      display: block !important;
      justify-content: space-between;
      margin-top: 10px;
     
    }
    .statistic-header .ant-select.ant-select-single.ant-select-show-arrow{
      width: 100% !important;
      margin-top: 10px !important;
    }
    .statistic-header .ant-select.ant-select-single.ant-select-show-arrow:hover{
      border-color:red !important;
    }
  }
`;

export default WithDirection(WeatherForecastWrapper);