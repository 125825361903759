import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import AssetModalWrapper from './AssetModal.styles';
import { Form, Input, Button, Select, Row, Col, DatePicker, Tabs, Card, Timeline, Popconfirm, InputNumber, Checkbox } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import modalActions from '@iso/redux/modal/actions';
import Loader from '@iso/components/utility/loader';
import { assetService, activityService } from '@iso/services';
import moment from 'moment';
import _ from 'lodash';
import styled from "styled-components";
import { dateHelper } from '@iso/lib/helpers/dateHelper';
import L from 'leaflet';
import inside from 'point-in-polygon';
import { assetCategories } from '@iso/constants/assetCategories';
import { inventoryTypes } from '@iso/constants/inventoryTypes';
import { assetStates } from '@iso/constants/assetStates';
import { waterStorageTypes } from '@iso/constants/waterStorageTypes';
import AddAssetHistoryModal from './AddAssetHistoryModal';
import AssetHistory from './AssetHistory';
import { animalTypes } from '@iso/constants/animalTypes';
import { objectColors } from '@iso/constants/objectColors';

const { Option } = Select;
const { closeModal } = modalActions;
const { TabPane } = Tabs;

const EditAnimalModal = (props) => {
  const [form] = Form.useForm();
  const [addHistoryForm] = Form.useForm();
  const [fields, setFields] = useState([]);
  const dispatch = useDispatch();
  const modalVisibility = useSelector((state) => state.modal.modalVisibility);
  const modalData = useSelector((state) => state.modal.modalData);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [assetData, setAssetData] = useState({});
  const activePermission = useSelector((state) => state.permission.activePermission);
  const [visibleHistory, setVisibleHistory] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [assetHistory, setAssetHistory] = useState([]);
  const [animalSire, setAnimalSire] = useState({});

  const updateDataAssetHistory = React.useCallback((asset_id, modal_type) => {
      activityService.getList(asset_id, modal_type).then(res => {
        if(res.code === '0000'){
          setAssetHistory(res.activities.reverse());
          //setLoading(false);
        }
      });
  }, [assetHistory]);

  useEffect(() => {
    if(props.propertyId){
       assetService.getAnimalSire(props.propertyId).then(res => {
        if(res.code === '0000'){
          setAnimalSire(res.animal_sires);
        }
      });
    }
  }, [props.propertyId]);

  useEffect(() => {
    setAssetData(props.dataAsset);
  }, [props.dataAsset]);

  const getRandomLatLng = (coordinates) => {
    var polygon = L.polygon([
      coordinates
    ]);
    var bounds = polygon.getBounds();
    var x_max = bounds.getEast();
    var x_min = bounds.getWest();
    var y_max = bounds.getSouth();
    var y_min = bounds.getNorth();
    var lat=0,lng = 0;
    var count = 0;
    var area = polygon.getLatLngs()[0].map(function(point){
      return [point.lat, point.lng]            
    });

    while(!inside([lat, lng], area)){
      lat = y_min + (Math.random() * (y_max - y_min));
      lng = x_min + (Math.random() * (x_max - x_min));
      count+=1;
      if(count==100){
        break;
      }      
    }            

    return new L.LatLng(
          lat,
          lng
    );
  }
  
  const cancelModal = () => {
    props.cancelEditModal();
    setActiveTab("1");
    setLoading(false);
    form.resetFields();
  }

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
  }

  const onStore = (values) => {
    const selectedObject = _.find(props.primaryObjects, (object) => {
      return object.id === values.asset.primary_object_id;
    });
    
    if(selectedObject && (!assetData.id || assetData.primary_object_id != selectedObject.id)){
      var point = getRandomLatLng(selectedObject.area);
    }else{
      var point = null;
    }
    const data = {
      facility: {
        ...values.asset,
        date_of_birth: values.asset.date_of_birth ? dateHelper.dateForAPI(values.asset.date_of_birth) : '',
        sire: values.asset.sire ? 1 : 0,
        point,
        property_id: props.propertyId
      }
    }

    assetService.storeOrUpdate(data, assetData.id).then(res => {
      if(res.code === '0000'){
        props.onSaved(res.facility);
        cancelModal();
        form.resetFields();
      } else {
        setLoading(false);
      }
    });
  }

  useEffect(() => {
      updateDataAssetHistory(assetData.id, 'facilities');
      setFields([
        {
          name: ['asset', 'title'],
          value: assetData.title || '',
        },
        {
          name: ['asset', 'category'],
          value: assetData.category,
        },
        {
          name: ['asset', 'other_category'],
          value: assetData.other_category,
        },
        {
          name: ['asset', 'type'],
          value: assetData.type,
        },
        {
          name: ['asset', 'other_inventory_type'],
          value: assetData.other_inventory_type,
        },
        {
          name: ['asset', 'animal_name'],
          value: assetData.animal_name,
        },
        {
          name: ['asset', 'animal_type'],
          value: assetData.animal_type,
        },
        {
          name: ['asset', 'animal_sire_id'],
          value: assetData.animal_sire_id,
        },
        {
          name: ['asset', 'sire'],
          value: assetData.sire,
        },
        {
          name: ['asset', 'tag_number'],
          value: assetData.tag_number,
        },
        {
          name: ['asset', 'tag_colour'],
          value: assetData.tag_colour,
        },
        {
          name: ['asset', 'other_animal_type'],
          value: assetData.other_animal_type,
        },
        {
          name: ['asset', 'date_of_birth'],
          value: assetData.date_of_birth ? moment(assetData.date_of_birth) : '',
        },
        {
          name: ['asset', 'unique_features'],
          value: assetData.unique_features || '',
        },
        {
          name: ['asset', 'description'],
          value: assetData.description || '',
        },
        {
          name: ['asset', 'primary_object_id'],
          value: assetData.primary_object_id || '',
        },
      ]);
    
  }, [props.propertyId, props.visibleAssetAnimal, assetData]);

  const renderOptionsAssetCategory = () => {
    let options = [];
    _.forEach(assetCategories, (cat, index) => {
      options.push(
        <Option key={index} value={cat.value}>
          <div>{cat.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a category"
        allowClear
        size="large"
        disabled
      >
        {options}
      </Select>
    );
  }

  const renderOptionsInventoryType = () => {
    let options = [];
    _.forEach(inventoryTypes, (type, index) => {
      options.push(
        <Option key={index} value={type.value}>
          <div>{type.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a inventory type"
        allowClear
        size="large"
        disabled
      >
        {options}
      </Select>
    );
  }

 const renderOptionAnimalType = () => {
    let options = [];
    _.forEach(animalTypes, (type, index) => {
      options.push(
        <Option key={index} value={type.value}>
          {type.label}
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select an animal type"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const renderOptionsColor = () => {
    let options = [];
    _.forEach(objectColors, (color, index) => {
      options.push(
        <Option key={index} value={color.value}>
          <ColorItemRow>
            <ColorItemPreview style={{backgroundColor: color.color}}></ColorItemPreview>
            <div>{color.label}</div>
          </ColorItemRow>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a color"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }
  const renderOptionsWaterStorageTypes = () => {
    let options = [];
    _.forEach(waterStorageTypes, (water_type, index) => {
      options.push(
        <Option key={index} value={water_type.value}>
          <div>{water_type.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a water storage type"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const renderOptionObject = (objects) => {
    let options = [];
    _.forEach(objects, (object, index) => {
      options.push(
        <Option key={object.id} value={object.id}>
          {object.name}
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select an object"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const [activeTab,setActiveTab] = useState("1");
  const onChangeTab = (key) => {
    setActiveTab(key);
  }

  const addHistory = (object, e) => {
    setVisibleHistory(true);
  }

  const cancelModalHistory = () => {
    addHistoryForm.resetFields();
    setVisibleHistory(false);
    setLoadingHistory(false);
  }

  const renderOptionsSire = (animal_sire) => {
    let options = [];
    _.forEach(animal_sire, (sire, index) => {
      options.push(
        <Option key={index} value={sire.id}>
          <div>{sire.animal_name}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a sire"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  } 

  return(
    <>
    <AssetModalWrapper
      open={props.visibleAssetAnimal}
      onCancel={cancelModal}
      maskClosable={true}
      title={props.dataAsset.id ? <IntlMessages id="propertyPage.modal.asset.editAsset"/> : <IntlMessages id="propertyPage.modal.asset.addAsset"/>}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit} loading={loading}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
    {
        modalLoading ?
        <Loader /> : 
        <Tabs defaultActiveKey="1" onChange={onChangeTab} className="form-body"
        activeKey={activeTab}
        >
        <TabPane tab={<IntlMessages id="propertyPage.mobModal.detailTab" />} key="1"> 
          <Form
            form={form}
            layout="vertical"
            scrollToFirstError
            fields={fields}
            id="#1"
          >
          <div>
            <Form.Item
              name={['asset', 'title']}
              className="isoInputWrapper"
              label="Title"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                  whitespace: true,
                },
                {
                  max:255,
                  message: 'Title must be maximum 255 characters.'
                },
              ]}
            >
              <Input size="large" placeholder="Input asset title"/>
            </Form.Item>
            <Form.Item
              name={['asset', 'category']}
              className="isoInputWrapper"
              label="Category"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              {renderOptionsAssetCategory()}
            </Form.Item>
            {assetData.category === 'other' && (<Form.Item
              name={['asset', 'other_category']}
              className="isoInputWrapper"
              label="Other Category"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                  whitespace: true
                },
                {
                  max:255,
                  message: 'Other Category must be maximum 255 characters.'
                },
              ]}
            >
              <Input size="large" placeholder="Input other category"/>
            </Form.Item>)}
            {assetData.category === 'inventory' && (<Form.Item
              name={['asset', 'type']}
              className="isoInputWrapper"
              label="Inventory Type"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              {renderOptionsInventoryType()}
            </Form.Item>)}
            {assetData.category === 'inventory' && (<Form.Item
              name={['asset', 'other_inventory_type']}
              className="isoInputWrapper"
              label="Other Inventory Type"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                  whitespace: true
                },
                {
                  max:255,
                  message: 'Other Inventory Type must be maximum 255 characters.'
                },
              ]}
            >
              <Input size="large" placeholder="Input other inventory type"/>
            </Form.Item>)}
            <Form.Item
              name={['asset', 'animal_name']}
              className="isoInputWrapper"
              label={<IntlMessages id="propertyPage.modal.asset.animalName" />}
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                  whitespace: true
                },
                {
                  max:255,
                  message: 'Name must be maximum 255 characters.'
                },
              ]}
            >
              <Input size="large" placeholder="Input animal name"/>
            </Form.Item>
            <Row>
              <Col xs={{span: 24}} md={{span: 11}}>
                <Form.Item
                  name={['asset', 'animal_type']}
                  className="isoInputWrapper"
                  label={<IntlMessages id="propertyPage.modal.asset.animalType" />}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  {renderOptionAnimalType()}
                </Form.Item>
              </Col>
              <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => {
                    return (prevValues['asset'] && (prevValues['asset'].animal_type !== currentValues['asset'].animal_type));
                  }}
                >
                  {({ getFieldValue }) => {
                      if(getFieldValue('asset')){
                        if (getFieldValue('asset').animal_type === "other"){
                          return (
                            <Form.Item
                              name={['asset', 'other_animal_type']}
                              className="isoInputWrapper"
                              label={<IntlMessages id="propertyPage.modal.asset.otherAnimalType" />}
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                  whitespace: true
                                },
                                {
                                  max:255,
                                  message: 'Other Animal Type must be maximum 255 characters.'
                                },
                              ]}
                            >
                              <Input size="large" placeholder="Input other animal type"/>
                            </Form.Item>
                          );
                        }

                        if (getFieldValue('asset').category === "animal"){
                          return (
                            <Form.Item
                              name={['asset', 'animal_sire_id']}
                              className="isoInputWrapper"
                              label={<IntlMessages id="propertyPage.modal.asset.sire" />}
                            >
                              {renderOptionsSire(animalSire)}
                            </Form.Item>
                          );
                        }
                      }
                    }
                  }
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{span: 24}} md={{span: 11}}>
                <Form.Item
                  name={['asset', 'sire']}
                  className="isoInputWrapper"
                  valuePropName="checked"
                  label={<IntlMessages id="propertyPage.modal.asset.sire" />}
                >
                  <Checkbox className="checkbox-contact"></Checkbox>
                </Form.Item>
              </Col>
              <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                <Form.Item
                  name={['asset', 'date_of_birth']}
                  className="isoInputWrapper"
                  label={<IntlMessages id="propertyPage.modal.asset.dateOfBirth" />}
                >
                  <DatePicker size="large" style={{width: '100%'}} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{span: 24}} md={{span: 11}}>
                <Form.Item
                  name={['asset', 'tag_number']}
                  className="isoInputWrapper"
                  label={<IntlMessages id="propertyPage.modal.asset.tagNumber" />}
                >
                   <Input size="large" placeholder="Input tag number"/>
                </Form.Item>
              </Col>
              <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                <Form.Item
                  name={['asset', 'tag_colour']}
                  className="isoInputWrapper"
                  label={<IntlMessages id="propertyPage.modal.asset.tagColour" />}
                >
                  {renderOptionsColor()}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name={['asset', 'primary_object_id']}
              className="isoInputWrapper"
              label={<IntlMessages id="propertyPage.modal.asset.paddock" />}
            >
              {renderOptionObject(props.primaryObjects)}
            </Form.Item>
            <Form.Item
              name={['asset', 'unique_features']}
              className="isoInputWrapper"
              label={<IntlMessages id="propertyPage.modal.asset.uniqueFeature" />}
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              name={['asset', 'description']}
              className="isoInputWrapper"
              label="Description"
            >
              <Input.TextArea />
            </Form.Item>
          </div>  
      </Form>
      </TabPane>
      <TabPane tab={<IntlMessages id="propertyPage.mobModal.historyTab" />} key="2">
        <Row>
          <Col xs={{span: 7}} md={{span: 7}}>
            {(activePermission == "owner" || activePermission == "admin"|| activePermission == "modify") &&
            <Button key="submit" className="btn-create-breed" type="primary" onClick={addHistory}>
              {<IntlMessages id="propertyPage.mobModal.historyTab.addHistory" />}
            </Button>}
          </Col>
          <Col xs={{span: 15}} md={{span: 17}}>
            
          </Col>
          
        </Row>
        <br/>
        <AssetHistory assetHistory={assetHistory} updateDataAssetHistory={updateDataAssetHistory} dataId={assetData.id}/>
        <AddAssetHistoryModal        
          visibleHistory={visibleHistory} 
          cancelModal={cancelModalHistory}
          primaryObjects={props.primaryObjects}
          model='facilities'
          dataId={assetData.id}
          propertyId={props.propertyId}
          updateDataAssetHistory={updateDataAssetHistory}
        />
      </TabPane>
      </Tabs>
      }     
    </AssetModalWrapper>
    </>
  )
}

export default EditAnimalModal;

const ColorItemRow = styled.div`
  display: flex;
  align-items: center;
  line-height: 30px;
  div{
    margin: 4px;
  }
`;

const ColorItemPreview = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 2px;
`;