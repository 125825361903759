import styled from "styled-components";

export const Table3Wrapper = styled.div`
  .signature-container {
    border-bottom: 1px solid;
    padding-bottom: 5px;
    width: 500px;
  }
  .signature-label {
    text-align: center;
    font-weight: 500;
    p:first-child {
      font-style: italic;
    }
  }
  .signature-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 100px;
  }
  thead th {
    background-color: #dfeefa;
  }
  thead tr th {
    background-color: #dfeefa;
    border-top: 1px solid;
    border-left: 1px solid;
    border-right: 1px solid;
  }
`;