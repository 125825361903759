import React from "react";
import { Form, Input, Table, Checkbox } from "antd";
import BioCheckbox from "../Components/BioCheckbox";
const { TextArea } = Input;

const PeopleVehicleEquipmentTable = (props) => {
  const columns = [
    {
      title: "2",
      dataIndex: "order",
      rowScope: "row",
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 16 };
        }
        if (
          [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].includes(index)
        ) {
          return { rowSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "PEOPLE, VEHICLES AND EQUIPMENT",
      dataIndex: "people_vehicles_equipment",
      rowScope: "row",
      colSpan: 7,
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 16 };
        }
        if (
          [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].includes(index)
        ) {
          return { rowSpan: 0 };
        }
        return {};
      },
      width: 500,
    },
    {
      title: "Yes",
      colSpan: 0,
      dataIndex: "yes",
      rowScope: "row",
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 16 };
        }
        if (
          [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].includes(index)
        ) {
          return { rowSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "No",
      colSpan: 0,
      dataIndex: "no",
      rowScope: "row",
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 16 };
        }
        if (
          [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].includes(index)
        ) {
          return { rowSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "N/A",
      colSpan: 0,
      dataIndex: "na",
      rowScope: "row",
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 16 };
        }
        if (
          [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].includes(index)
        ) {
          return { rowSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "Recommended measures ",
      colSpan: 0,
      dataIndex: "recommended_measures",
      rowScope: "row",
      width: 500,
    },
    {
      title: "Actioned ?",
      colSpan: 0,
      dataIndex: "actioned",
      rowScope: "row",
    },
    {
      title: "Comments",
      colSpan: 0,
      dataIndex: "comments",
      rowScope: "row",
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 16 };
        }
        if (
          [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].includes(index)
        ) {
          return { rowSpan: 0 };
        }
        return {};
      },
      className: "comment-column",
    },
  ];

  const data = [
    {
      key: "1",
      order: "2.1",
      people_vehicles_equipment: "People, vehicles and equipment",
      yes: "Yes",
      no: "No",
      na: "N/A",
      recommended_measures: "Recommended measures ",
      actioned: "Actioned?",
      comments: "Comments",
    },
    {
      key: "2",
      order: "2.1.1",
      people_vehicles_equipment:
        "Are there strategies in place to minimise the risk of disease incursion onto the property by visitors or machinery?",
      yes: <BioCheckbox name="people_vehicles_equipment_1_yes"></BioCheckbox>,
      no: <BioCheckbox name="people_vehicles_equipment_1_no"></BioCheckbox>,
      na: <BioCheckbox name="people_vehicles_equipment_1_na"></BioCheckbox>,
      recommended_measures:
        "Where reasonable and practical, the number of entry points is reduced.",
      actioned: (
        <BioCheckbox name="people_vehicles_equipment_1_1_actioned"></BioCheckbox>
      ),
      comments: (
        <Form.Item
          name="people_vehicles_equipment_1_comments"
          className="isoInputWrapper"
        >
          <TextArea />
        </Form.Item>
      ),
    },
    {
      key: "3",
      order: "",
      people_vehicles_equipment: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "Entry signage such as farm biosecurity signs, or directions to office/house for sign-in, provided.",
      actioned: (
        <BioCheckbox name="people_vehicles_equipment_1_2_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "4",
      order: "",
      people_vehicles_equipment: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "A visitor log of people in regular contact with farm animals (contractors, shearers, vets, stock agents) is maintained.",
      actioned: (
        <BioCheckbox name="people_vehicles_equipment_1_3_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "5",
      order: "",
      people_vehicles_equipment: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "Own vehicle used to transport contractors and visitors rather than their vehicle.",
      actioned: (
        <BioCheckbox name="people_vehicles_equipment_1_4_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "6",
      order: "",
      people_vehicles_equipment: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "Farm contractors such as veterinarians, livestock agents and transport vehicles notified of permitted areas of access prior to entry.",
      actioned: (
        <BioCheckbox name="people_vehicles_equipment_1_5_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "7",
      order: "",
      people_vehicles_equipment: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "The lending of equipment is minimised, and if lent, equipment and vehicles cleaned down before use on farm.",
      actioned: (
        <BioCheckbox name="people_vehicles_equipment_1_6_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "8",
      order: "",
      people_vehicles_equipment: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "The lending of equipment is minimised, and if lent, equipment and vehicles cleaned down before use on farm.",
      actioned: (
        <BioCheckbox name="people_vehicles_equipment_1_7_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "9",
      order: "",
      people_vehicles_equipment: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "Vehicles and equipment cleaned prior to moving from a high-risk area to a low-risk area",
      actioned: (
        <BioCheckbox name="people_vehicles_equipment_1_8_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "10",
      order: "",
      people_vehicles_equipment: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "Come clean, go clean’ practices encouraged from visitors including agents and stock contractors.",
      actioned: (
        <BioCheckbox name="people_vehicles_equipment_1_9_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "11",
      order: "",
      people_vehicles_equipment: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "Clean down equipment or facilities provided for farm contractors and visitors to clean their boots and equipment",
      actioned: (
        <BioCheckbox name="people_vehicles_equipment_1_10_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "12",
      order: "",
      people_vehicles_equipment: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures: "Clean down areas marked on property map.",
      actioned: (
        <BioCheckbox name="people_vehicles_equipment_1_11_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "13",
      order: "",
      people_vehicles_equipment: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "Register of companies/organisations who have authorised access to your property (such as utility companies and mining or gas companies) is maintained, detailing any negotiated arrangements around access.",
      actioned: (
        <BioCheckbox name="people_vehicles_equipment_1_12_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "14",
      order: "",
      people_vehicles_equipment: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "Risk assessment for each entity completed, identifying the risks involved (such as the treatment of power poles or vegetation with chemicals) and the processes in place to manage the risks.",
      actioned: (
        <BioCheckbox name="people_vehicles_equipment_1_13_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "15",
      order: "",
      people_vehicles_equipment: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "Utility workers informed to make contact before accessing the property where practicable.",
      actioned: (
        <BioCheckbox name="people_vehicles_equipment_1_14_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "16",
      order: "",
      people_vehicles_equipment: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "Utility providers informed of any complications, including high biosecurity risks, to drive on designated tracks, abide by signage, avoid moving through certain areas of high risk and leave gates as they find them.",
      actioned: (
        <BioCheckbox name="people_vehicles_equipment_1_15_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "17",
      order: "",
      people_vehicles_equipment: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "Workers returning to work from overseas trips or overseas workers have clean boots and are well.",
      actioned: (
        <BioCheckbox name="people_vehicles_equipment_1_16_actioned"></BioCheckbox>
      ),
      comments: "",
    },
  ];

  return (
    <Table className="bio-table" columns={columns} dataSource={data} bordered pagination={false} />
  );
};

export default PeopleVehicleEquipmentTable;