import React, { useState, useEffect } from "react";
import { Button, Form, message } from "antd";
import _ from "lodash";
import { BioFormWrapper } from "./BioFormWrapper.style";
import Table1 from "./Table1";
import Table2 from "./Table2";
import Table3 from "./Table3/Table3";
import { formService } from "@iso/services";
import {visitorFormService} from '../../../../../services/visitorform.service'

const BioForm = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);

  const handleSubmit = async () => {
    if (!props.isAbleToSubmit) {
      return;
    }
    if(props.visitorForm){
      const visitorFormValidation = await props.visitorForm.validateFields();
      if(visitorFormValidation.errorFields){
        return;
      }
    }
    form
      .validateFields()
      .then((values) => {
        handleFinish(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  useEffect(()=>{
    if (props.formData) {
      for(let field in props.formData){
        form.setFieldValue(field, props.formData[field])
      }
    }
  }, [props.formData])
  const handleFinish = (values) => {
    const data = {
      form: {
        global_form: values,
        type: "bio_form",
      },
    };
    if(props.visitorForm){
      data.visitor = props.visitorForm.getFieldsValue().visitor;
      data.form.visitor_form_token = props.visitorFormToken;
    }
    const handleFn = props.visitorForm ? visitorFormService.createVisitorForm(data) : formService.storeOrUpdate(data, props.formData.id)
    handleFn.then((res) => {
      if (res.code === "0000") {
        message.success("Submit success");
        props.setShowFinalScreen(true);
        setLoading(false);
      } else {
        message.error(res.message);
        setLoading(false);
      }
    });
  };

  return (
    <BioFormWrapper>
      <Form form={form} layout="vertical" fields={fields}>
        <Table1 />
        <Table2 />
        <Table3 form={form}/>
        <Button
          key="submit"
          className="btn-success btn-submit-bio"
          type="primary"
          onClick={handleSubmit}
          loading={loading}
        >
          Submit
        </Button>
      </Form>
    </BioFormWrapper>
  );
};

export default BioForm;