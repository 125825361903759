import axios from "axios";
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';

export const iotDevice= {
  getList,
  storeOrUpdate,
  search,
  positionDevice,
  destroy,
  assignUserToDevice,
  setDeviceTime,
  getStopDeviceAlarmRequests
};

function getList(propertyId = null){
  return (
    axios
      .get(
        `${apiEndpoint.iotDevice}?property_id=${propertyId}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 ){
          return res.data;
        }
      })
  );
}

function search(propertyId,s){
  return (
    axios
      .get(
        `${apiEndpoint.iotDevice}/search?property_id=${propertyId}&s=${s}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
      })
  );
}
function positionDevice(propertyId){
  return (
    axios
      .get(
        `${apiEndpoint.iotDevice}/position-device?property_id=${propertyId}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
      })
  );
}


function storeOrUpdate(data, id = null){
  return (id ? (
    axios
      .put(
        apiEndpointFunction.iotDevice(id),
        data,
        {
          headers: authHeader(),
        }
      )
  ) : (
    axios
      .post(
        apiEndpoint.iotDevice,
        data,
        {
          headers: authHeader()
        }
      )
  )).then(res => {
    if(res && res.status === 200){
      return res.data;
    }
    return {};
  });
}

function destroy(id){
  return (
    axios
      .delete(
        apiEndpointFunction.iotDevice(id),
        {
          headers: authHeader()
        }
      ).then(res => {
        if (res && res.status === 200) {
          return res.data;
        }
      })
  );
}

function assignUserToDevice(deviceId, userId){
  return (
    axios
      .post(
        `${apiEndpoint.iotDevice}/${deviceId}/assign-user-to-device`,
        {
          user_id: userId
        },
        {
          headers: authHeader()
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
      })
  );
}

function setDeviceTime(deviceId, deviceTime){
  return (
    axios
      .post(
        `${apiEndpoint.iotDevice}/${deviceId}/set-device-time`,
        {
          device_time: deviceTime
        },
        {
          headers: authHeader()
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
      })
  );
}
function getStopDeviceAlarmRequests(id){
  return (
    axios
      .get(
        `${apiEndpoint.iotDevice}/${id}/get-stop-device-alarm-requests`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 ){
          return res.data;
        }
      })
  );
}