export const openWeather = [
  {
    label: 'Wind Speed',
    value: 'wind_speed',
  },
  {
    label: 'UVI',
    value: 'uvi',
  },
  {
    label: 'Rain',
    value: 'rain',
  }
]