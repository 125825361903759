import React, { useState, useEffect,useRef,useCallback } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import AssetModalWrapper from './AssetModal.styles';
import { Form, Input, Button, Select, Row, Col, DatePicker, Tabs, Card, Timeline, Popconfirm, InputNumber, Checkbox, TimePicker,Radio,Upload,Space } from 'antd';
import { activityPolicy } from '@iso/constants/activityPolicy';
import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { propertyService,healthsafety,attachment } from '@iso/services';
import _ from 'lodash';
import L from 'leaflet';  
import { dateHelper } from '@iso/lib/helpers/dateHelper';
import moment from 'moment';
import { Map, TileLayer, FeatureGroup, Polygon, Tooltip, ZoomControl,Marker,Popup } from 'react-leaflet';
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'

const { Option } = Select;

const AddActivityModal = (props) => {
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const mapRef = useRef();
  const [valueLat, setValuelat] = useState();
  const [valueLng, setValueLng] = useState();
  const [attachmentActivityId, setAttachmentActivityId] = useState([]);
  const [attendees, setAttendees] = useState([]);

  useEffect(() => {
      setFields([
        {
          name: ['asset', 'date_time'],
          value: moment(),
        },   
      
 
      ]);

  }, [props.propertyId]);
  const handleChangeTag = (input_tags) => {
    setAttendees(input_tags);
  }
  const getLatLng = (cLat, cLng) => {
    const lat = cLat;
    const lng = cLng;
    return new L.LatLng(
          lat,
          lng
    );
  }

  const uploadPolicyProps = {
    name: "Attachment[file]",
    action: apiEndpoint.attachments,
    headers: authHeader(true),
    listType: "picture",
    multiple: true,
    onChange: (response) => {
      if(response.file && response.file.status == "done"){
        setAttachmentActivityId(attachmentActivityId => [...attachmentActivityId, response.file.response.attachment.id]);
      }
    },
    onRemove: file => {
      attachment.destroy(file.uid);
    }

  };
  const handleClickMap = (e) => {
     const { lat, lng } = e.latlng;
     setValueLng(lng);
     setValuelat(lat);
  };
  const renderActivityPolicy = () => {
    let options = [];
    _.forEach(activityPolicy , (activity, index) => {
      options.push(
        <Option key={index} value={activity.value}>
          <div>{activity.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a activity"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  } 
  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
  }
  const onStore = (values) => {
    var point = null;
    if(values.asset.type === 'comment'){
      if(valueLat !== '' && valueLng !== ''){
        point = getLatLng(valueLat,valueLng);
      }
    }
    
     const dataPolicy = {
      policy: {
        ...values.asset,
        idFacilities: props.dataId,
        type: values.asset.type,
        date_time: values.asset.date_time ? dateHelper.dateTimeForAPI(values.asset.date_time) : '',
        point:point,
        meeting_attendees: attendees,
        attachment_activity_id:attachmentActivityId,
      }
    }
    healthsafety.storeOrUpdateActivityPolicies(dataPolicy).then(res => {
      if(res.code === '0000'){
        setLoading(false);
        props.cancelModalActivity();
        form.resetFields();
        props.getDataActivity(props.dataId);
        setAttendees([]);
      } else {
        setLoading(false);
      }
    });
  }


  return(
    <>
    <AssetModalWrapper
      open={props.visibleActivity}
      onCancel={props.cancelModalActivity}
      title= "Add Activity"
      footer={[
        <Button key="back" onClick={props.cancelModalActivity} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit} loading={loading}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
    <Form
        form={form}
        layout="vertical"
        scrollToFirstError
        fields={fields}
        id="#1"
      >
      <div className="form-body">
      <Row>
        <Col xs={{span: 24}} md={{span: 24}}>
            <Form.Item
              name={['asset','type']}
              className="isoInputWrapper"
              label={<IntlMessages id="propertyPage.modal.asset.activityPolicy" />}
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              {renderActivityPolicy()}
            </Form.Item>
          </Col>
      </Row>
       <Row>
          <Col xs={{span: 24}} md={{span: 24}}>
            <Form.Item
              name={['asset', 'date_time']}
              className="isoInputWrapper"
              label="Date Time"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              <DatePicker format="DD-MM-YYYY HH:mm:ss" size="large" style={{width: '100%'}} showTime/>
            </Form.Item>
          </Col>
      </Row>
      <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => {
            return ( prevValues['asset'] );
          }}
        >
        {({ getFieldValue }) => {
          if(getFieldValue('asset')){
             
            return (
              <div>
                { getFieldValue('asset').type === 'meeting' &&
                  <div>
                    <Row>
                      <Col xs={{span: 24}} md={{span: 11}}>
                        <Form.Item
                          name={['asset', 'meeting_name']}
                          className="isoInputWrapper"
                          label="Meeting Name"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                            },
                          ]}
                        >
                          <Input size="large" placeholder="Meeting Name"/>
                        </Form.Item>
                      </Col>
                      <Col xs={{span: 24}} md={{span: 11,offset:2}}>
                        <Form.Item
                          name={['asset', 'meeting_actions']}
                          className="isoInputWrapper"
                          label="Meeting Actions"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                            },
                          ]}
                        >
                          <Input size="large" placeholder="Meeting Actions"/>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={{span: 24}} md={{span: 24}}>
                        <Form.Item
                          className="isoInputWrapper"
                          label="Meeting Attendees"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                            },
                          ]}
                        >
                          <TagsInput name={['asset', 'meeting_attendees']} value={attendees} onChange={handleChangeTag} addOnBlur="true"/>

                        </Form.Item>
                      </Col>
                      <Col xs={{span: 24}} md={{span: 24}}>
                        <Form.Item
                          name={['asset', 'meeting_notes']}
                          className="isoInputWrapper"
                          label="Meeting Notes"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                            },
                          ]}
                        >
                          <Input.TextArea />
                        </Form.Item>
                      </Col>
                    </Row>

                  </div>
                }
                { (getFieldValue('asset').type === 'comment' || getFieldValue('asset').type === 'update') &&
                        <div>
                          <Row>
                           <Col xs={{span: 24}} md={{span: 24}} >
                            <Form.Item
                              name={['asset', 'comments']}
                              className="isoInputWrapper"
                              label="Comment"
                            >
                              <Input.TextArea />
                            </Form.Item>
                            </Col>
                          </Row>
                            <Row>
                              <Col xs={{span: 24}} md={{span: 24}} >
                                <Form.Item
                                  name={['asset', 'attachment']}
                                  className="isoInputWrapper"
                                  label={<IntlMessages id="propertyPage.modal.asset.attachmentPolicy" />}
                                >
                                  <Upload {...uploadPolicyProps}>
                                    <Button icon={<UploadOutlined />}>Upload</Button>
                                  </Upload>
                                
                                </Form.Item>
                              </Col>
                            </Row>

                        </div>
                      }
                      {getFieldValue('asset').type == 'comment' &&
                        <div>
                          <Map ref={mapRef} center={props.position[0] !== null ? props.position : false} doubleClickZoom={false} onclick={handleClickMap} zoom={16} zoomControl={true} style={{width:'100%', height:'300px'}}>
                             <TileLayer
                              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                              />
                              {valueLat  && <Marker position={[valueLat,valueLng]} draggable={true}>
                                  <Popup position={[valueLat,valueLng]}>
                                  </Popup>
                              </Marker>}
                          </Map>
                          <Row style={{marginTop:"10px", marginBottom:"5px"}}>
                              <Col xs={{span: 24}} md={{span: 11}} >
                                  <Input value={valueLat}/>
                              </Col>
                              <Col xs={{span: 24}} md={{span: 11, offset: 2}} >
                                  <Input value={valueLng}/>
                              </Col>
                          </Row>
                        </div>
                      }
                 
              </div>
            )
          }
        }}

      </Form.Item>
     
      </div>
      </Form>
    </AssetModalWrapper>
    </>
  )

}

export default AddActivityModal;