import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';
import satelliteModeImage from '@iso/containers/Pages/WeatherMap/Controls/icons/satellite-mode.svg';
import lightModeImage from '@iso/containers/Pages/WeatherMap/Controls/icons/light-mode.svg';
import darkModeImage from '@iso/containers/Pages/WeatherMap/Controls/icons/dark-mode.svg';
import icon2DImage from '@iso/containers/Pages/WeatherMap/Controls/icons/2D.svg';
import icon3DImage from '@iso/containers/Pages/WeatherMap/Controls/icons/3D.svg';
import precipitationIntensity from '@iso/containers/Pages/WeatherMap/Controls/icons/precipitation-intensity.svg';
import windSpeed from '@iso/containers/Pages/WeatherMap/Controls/icons/wind-speed.svg';
import wind from '@iso/containers/Pages/WeatherMap/Controls/icons/wind.svg';
import globalRada from '@iso/containers/Pages/WeatherMap/Controls/icons/global-rada.svg';
import satellite from '@iso/containers/Pages/WeatherMap/Controls/icons/satellite.svg';

const WeatherMapWrapper=styled.div`
  width: 100%;
  height: 500px;
  position: relative;
  .weather-form-area form {
    padding: 20px;
  }
  .map-container {
    
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
  canvas.mapboxgl-canvas{
    width: 100% !important;
  }
  .mapboxgl-ctrl-logo {
    display: none !important;
  }
  .mapbox-ctrl-group-bottom-right {
    display: flex;
    position: absolute;
    right: 10px;
    bottom: 10px;
    gap: 5px;
    .ant-btn {
      border-radius: 5px !important;
      border-color: #b6b6b6 !important;
      visibility: inherit !important;
    }
    .mapboxgl-ctrl-active {
      cursor: not-allowed;
      pointer-events: none;
    }
  }
  .mapbox-ctrl-group-bottom-left {
    display: flex;
    position: absolute;
    left: 10px;
    bottom: 10px;
    gap: 5px;
    .ant-btn {
      border-color: #b6b6b6 !important;
      border-radius: 5px !important;
      visibility: inherit !important;
    }
  }
  .mapboxgl-ctrl-pitchtoggle-3d {
    background-image: url(${ icon3DImage });
  }
  .mapboxgl-ctrl-pitchtoggle-2d {
    background-image: url(${ icon2DImage });
  }
  .mapboxgl-ctrl-icon {
    background-position: center;
    background-repeat: no-repeat;
  }
  .mapboxgl-ctrl-satellite-mode {
    background-image: url(${ satelliteModeImage });
    background-size: contain;
  }
  .mapboxgl-ctrl-light-mode {
    background-image: url(${ lightModeImage });
    background-size: 60% 60%;
  }
  .mapboxgl-ctrl-dark-mode {
    background-image: url(${ darkModeImage });
    background-size: 60% 60%;
  }
  .mapboxgl-ctrl-precipitation-intensity {
    background-image: url(${ precipitationIntensity });
    background-size: 60% 60%;
  }
  .mapboxgl-ctrl-wind-speed {
    background-image: url(${ windSpeed });
    background-size: 60% 60%;
  }
  .mapboxgl-ctrl-wind {
    background-image: url(${ wind });
    background-size: 60% 60%;
  }

  .mapboxgl-ctrl-active {
    &.mapboxgl-ctrl-icon {
      border: 2px solid #f80e46 !important;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(182, 182, 182, 1);
      -moz-box-shadow: 0px 0px 5px 0px rgba(182, 182, 182, 1);
      box-shadow: 0px 0px 5px 0px rgba(182, 182, 182, 1);
    }
    .ant-slider-handle {
      border-color: #f80e46;
    }
  }
  @media only screen and (max-width: 1440px) {
    height: 300px;
  }
`;

export default WithDirection( WeatherMapWrapper );
