
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Form, Input, Button, Row, Col, Select, DatePicker } from "antd";
// import { visitorService } from "@iso/services";
import { visitorService } from "../../../services/visitor.service";
import IntlMessages from "@iso/components/utility/intlMessages";
import VisitorModalWrapper from "../Visitor/VisitorModal.styles";
import { Checkbox, Radio } from "antd";
import { Map, TileLayer, FeatureGroup, Polygon, Tooltip, ZoomControl, Marker, Popup } from 'react-leaflet';
import { Form, Input, Button, Select, Row, Col, DatePicker, Card, Timeline, Upload, message } from 'antd';
import _ from "lodash";
import HazardViewModal from './AssetViewModal.styles'
import moment from "moment";


const { Option } = Select;

const AssetViewSummary = (props) => {
  
    const { editHazard } = props;
  
const cancelModal = () => {
    props.setVisibleViewSummary();
}



                
  return (

    <HazardViewModal
    className='visitor-modal'
    forceRender={true}
    open={props.visibleViewSummary}
    title={<IntlMessages id="Asset Details" />}
    onCancel={cancelModal}
    maskClosable={true}
    footer={[
      <Button key="back" onClick={cancelModal} className="cancel-button">
        {<IntlMessages id="propertyPage.modal.cancel" />}
      </Button>
      
    ]}
  >
    <div style={{
        paddingLeft:'10px',
        width: "95%", height: "400px"
      }}>
        <br />
        
        <Timeline>
            
           <Timeline.Item color="red" style={{paddingLeft:'10px'}} >
                <p>{moment(props.viewSummary.created_at).format('ll')}</p>
                <Card title={`Asset `} bordered={true}>
                <Row>
                    <Col xs={{ span: 9 }} md={{ span: 9 }}>
                      <p className="card_content">Hazard Id</p>
                    </Col>
                    <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                      <b className="card_value">{props.viewSummary.id}</b>
                    </Col>
                  </Row>
                  {props.viewSummary.type ?
                  <Row>  
                    <Col xs={{ span: 9 }} md={{ span: 9 }}>
                      <p className="card_content">Asset Type</p>
                    </Col>
                    <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                      <b className="card_value">{props.viewSummary.type}</b>
                    </Col>
                  </Row>
                  :''}
                  <Row>
                    <Col xs={{ span: 9 }} md={{ span: 9 }}>
                      <p className="card_content">Title</p>
                    </Col>
                    <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                      <b className="card_value">{props.viewSummary.title}</b>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={{ span: 9 }} md={{ span: 9 }}>
                      <p className="card_content">category</p>
                    </Col>
                    <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                      <b className="card_value">{props.viewSummary.category}</b>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={{ span: 9 }} md={{ span: 9 }}>
                      <p className="card_content">category_label</p>
                    </Col>
                    <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                      <b className="card_value">{props.viewSummary.category_label}</b>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={{ span: 9 }} md={{ span: 9 }}>
                      <p className="card_content">description</p>
                    </Col>
                    <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                      <b className="card_value">{props.viewSummary.description}</b>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={{ span: 9 }} md={{ span: 9 }}>
                      <p className="card_content">State</p>
                    </Col>
                    <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                      <b className="card_value">{props.viewSummary.state}</b>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={{ span: 9 }} md={{ span: 9 }}>
                      <p className="card_content">remaining_label</p>
                    </Col>
                    <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                      <b className="card_value">{props.viewSummary.remaining_label}</b>
                    </Col>
                  </Row>
                </Card>
              </Timeline.Item>
            
        </Timeline>

      </div>
      </HazardViewModal>
  );
};

export default AssetViewSummary;
