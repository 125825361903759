import React from 'react'
import { Space,Spin  } from 'antd'
import '../../Paginations.css'
export default function Loader() {
  return (
    <div className="loader">
    <Space size="middle">
       <Spin size="large" />
     </Space>
  </div>
  )
}
