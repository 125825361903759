import React, { useEffect, useState } from "react";
import TaskChart from "../TaskChart";
import { taskService, propertyService } from "@iso/services";
import { useSelector } from "react-redux";
import _, { isEmpty } from "lodash";
import LayoutWrapper from "@iso/components/utility/layoutWrapper.js";
import TaskManagePage from "../Task.styles";
import "./Progress.css";
import Box from "../../../../components/utility/box";

const Progress = ({ data, setData }) => {
 
  const [primaryObjects, setPrimaryObjects] = useState([]);

  const activePropertyId = useSelector(
    (state) => state.property.activePropertyId
  );

  const getPrimaryObject = React.useCallback((activePropertyId) => {
    propertyService.viewProperty(activePropertyId).then((res) => {
      if (res.code === "0000") {
        setPrimaryObjects(res.primary_objects);
      }
    });
  }, []);

  useEffect(() => {
    updateDataBoard();
  }, [activePropertyId]);

  const updateDataBoard = (taskType = -1) => {
    if (activePropertyId) {
      taskService.getBoard(activePropertyId, taskType).then((res) => {
        if (res.code === "0000") {
          let lanes = res.data;
          lanes = lanes.lanes?.map((lane) => {
            return {
              ...lane,
              cards: !isEmpty(lane.cards)
                ? lane.cards?.map((a) => {
                    return {
                      ...a,
                      description: _.isEmpty(a.description)
                        ? ""
                        : a.description,
                    };
                  })
                : [],
            };
          });
          setData({ lanes });
        }
      });
    }
  };

  return (
    <LayoutWrapper>
      <TaskManagePage>
        <Box>
          <TaskChart dataTaskType={data} />
        </Box>
      </TaskManagePage>
    </LayoutWrapper>
  );
};

export default Progress;
