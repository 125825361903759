import styled from "styled-components";
import WithDirection from "@iso/lib/helpers/rtl";
import "gantt-task-react/dist/index.css";

const TaskChartWrapper = styled.div`
  width: 100%;
  height: 100%;
  .ViewContainer {
    margin-bottom: 15px;
    button {
      border: 1px solid #f80e46;
      color: #f80e46;
      margin-right: 5px;
      cursor: pointer;
      padding: 5px;
      border-radius: 5px;
      &.active {
        color: #ffffff;
        background-color: #f80e46;
      }
    }
  }
`;

export default WithDirection(TaskChartWrapper);
