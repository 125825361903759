import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const FormPage=styled.div`

  width: 100%;
  height: 100%;
  button.ant-btn.ant-btn-default.action-button{
    border-color: red !important
  }
  .search-bar {
    background: rgba(143, 146, 161, 0.05);
    border-radius: 12px;
    border: none;
    margin-left: 2rem;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    flex-direction: row-reverse;
    max-width: 228px;
  }
  .site-form-item-icon {
    height: 19px;
    width: 19px;
    color: #8F92A1;
    font-size: 19px;
  }
  .site-form-item-cancel {
    height: 15px;
    width: 15px;
    color: #8F92A1;
    font-size: 15px;
  }
  .search-bar .ant-input {
    background: none!important;
    border: none!important;
    color: #8F92A1;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
  .ctm-form-property-select {
    div {
      border-radius: 8px !important;
    }
  }
  .react-collapsible-theme .brand-primary-light {
    color: rgb(248, 14, 70) !important;
  }
.react-collapsible tbody .actions {
  text-align: center  !important; 
}
  .btn-success{
    border-radius: 8px !important;
    border-color: #559f2f;
    margin-right: 10px;
    background-color: #f80e46;
    padding: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: normal;
    padding-left:15px;
    padding-right:15px;
    :hover{
      opacity:1;
    }
    span{
      margin-right:3px;
    }
  }
  .table_forms .react-collapsible tbody .name  {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #101828;
  }
  .button-group{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    .startEndDate{
      border:1px solid #d9d9d9 !important;
      border-radius: 8px !important;
    height: 38px;
    padding: 5px !important;
    margin-bottom: 1px !important;
    margin-left: 10px !important;
    }
    
  }
  .fAgWDa .ant-table-content{
    overflow-x: unset;
  }
  .fAgWDa{
    overflow-x: unset;
  }
  .dtr-data{
    white-space: break-spaces;
  }
  iframe {
    height: 500px;
    width: 100%;
  }
  .signature-area {
    margin-bottom: 20px;
    canvas{
      border: solid 1px black;
      max-width: 100%;
      width: 100%;
      height: 300px;
      max-height: 250px;
    }
  }
  .form_table_main{
    background: rgb(255, 255, 255);
    border: 1px solid rgb(229, 229, 229);
    box-sizing: border-box;
    box-shadow: rgba(229, 229, 229, 0.6) 1px 1px 10px;
    border-radius: 10px;
    .top_title_form{
      display: flex;
      align-items: center;
      h1{
        color: rgb(16, 24, 40);
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        ::before{
          display:none;
        }
        ::after{
          display:none;
        }
      }
      .button-group{
       
        .ant-btn{
          
          gap : 5px;
          border-radius:8px !important;
          padding-left:15px;
          padding-right:20px;
        }
        .ant-select{
          border-radius:8px !important;
          .ant-select-selector{
            border-radius:8px !important;
          }
        }
      }
      .VerticalDivider{
        border-left:4px solid rgba(0, 0, 0, 0.08);
        height:30px;
      }
      .HorizontalDeivider{
       
        min-width : 93% !important;
      }
    }
    .table_forms{
      box-shadow:none !important;
      border-radius:0 !important;
      background:unset !important;
      border:none !important;
      .react-collapsible-theme{
        .react-collapsible{
          box-shadow:none;
          border-radius:0 !important;
          tbody{
            .actions{
              text-align:right;
              .ant-btn{
                border-radius:8px !important;
              }
            }
          }
        }
      }
      h1{
        padding: 20px;
        color: rgb(16, 24, 40);
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        ::before{
          display:none;
        }
        ::after{
          display:none;
        }
      }
    }
    .manage-property-header {
     
      display: flex;
      align-items: center;
    }
  }
  .ant-modal-body{
    .button-group{
      .ant-btn{
        
      }
    }
  }
  .signature-clear-button {
    display: block;
  }
  @media only screen and (max-width: 480px) {
    .manage-property-header {
      flex-direction: column ;
      padding: 20px 5px !important;
    }
    .manage-property-header .button-group{
      justify-content:flex-start !important;
      margin: 10px 0 0 10 !important;
    }
  }
`;

export default WithDirection( FormPage );