import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Form, Input, Button, Select, Row, Col, DatePicker, InputNumber } from 'antd';
import _ from 'lodash';
import { activityService, feedingTypeService, livestockService } from '@iso/services';
import { activityCategories } from '@iso/constants/activityCategories';
import { drugUnit } from '@iso/constants/drugUnit';
import { objectColors } from '@iso/constants/objectColors';
import { pregTesting } from '@iso/constants/pregTesting';
import AddHistoryModalWrapper from '@iso/containers/Pages/PropertyMap/components/Mob/AddHistoryModal.styles';
import FeedingTypeModalWrapper from '@iso/containers/Pages/PropertyMap/components/Mob/FeedingTypeModal.styles';
import styled from "styled-components";

const { Option } = Select;

const AddHistoryModal = (props) => {
	const [addHistoryForm] = Form.useForm();
	const [feedingTypeForm] = Form.useForm();

	const [visibleHistory, setVisibleHistory] = useState(false);
	const [loadingHistory, setLoadingHistory] = useState(false);
	const [fields, setFields] = useState([]);
	const [dataLivestock, setDataLivestock] = useState({});
	const [liveStockActivities, setLiveStockActivities] = useState([]);
	const [loading, setLoading] = useState(false);
	const [feedingTypes, setFeedingTypes] = useState({});
	const [visibleFeedingType, setVisibleFeedingType] = useState(false);
	const [loadingFeedingType, setLoadingFeedingType] = useState(false);
	const [assets, setAssets] = useState([]);
	const [historySalePrice, setHistorySalePrice] = useState();
  	const [historySaleWeight, setHistorySaleWeight] = useState();
 	const [historySalePricePerWeight, setHistorySalePricePerWeight] = useState();
 	const [archiveModalVisible, setArchiveModalVisible] = useState(false);

	useEffect(() => {
	    setDataLivestock(props.liveStockData);
  	}, [props.liveStockData]);

	useEffect(() => {
	    if (dataLivestock) {
	      	updateHistoryData(dataLivestock.id, 'asset');
	    }
  	}, [dataLivestock]);

  	useEffect(() => {
	    if (props.propertyId) {
	       	livestockService.getList(props.propertyId).then(res => {
		        if(res.code === '0000'){
		          setAssets(res.assets);
		          setLoading(false);
		        }
	      	});
	    }
	}, [props.propertyId, props.visibleHistory]);

	const cancelModalHistory = () => {
	    props.cancelModal();
	    addHistoryForm.resetFields();
	}

	const handleHistorySubmit = (e) => {
	    addHistoryForm
	      	.validateFields()
	      	.then(history_values => {
	        	onStoreHistory(history_values);
	      	})
	      	.catch(info => {
	        	console.log('Validate Failed:', info);
	        	setLoadingHistory(false);
	      	});
  	}

  	const onStoreHistory = (history_values) => {
	    const history_data = {
	      	"history":{
	        	metadata: history_values.history,
	        	action:'create',
	        	model:'assets',
	        	type:'asset',
	        	object_id: dataLivestock.id,
	        	property_id: props.propertyId
	      	}
	    }
	    
	    activityService.storeOrUpdate(history_data).then(res => {
	      	if (res.code === '0000') {
	      		cancelModalHistory();
		        addHistoryForm.resetFields();

		        if (props.updateHistory) {
		        	props.updateHistory(res.history);
		        }

		        if (res.history.metadata.weighing) {
		          	var dataLivestock_clone = _.clone(dataLivestock);
		          	dataLivestock_clone.weight = res.history.metadata.weighing.weight;
		          	setDataLivestock(dataLivestock_clone);
		        }

		        if (res.history.metadata.death || res.history.metadata.sale) {
		        	setArchiveModalVisible(true);
		        }
	      	} else {
	        	setLoadingHistory(false);
	      	}
	    });
	}

	const updateHistoryData = (object_id, data_type) => {
	    if(object_id){
	       	activityService.getList(object_id, data_type).then(res => {
	        	if (res.code === '0000') {
	          		setLiveStockActivities(res.activities.reverse());
	          		setLoading(false);
	        	}
	      	});
	    }
  	}

  	const renderOptionsActivityCategories = () => {
	    let activity_options = [];
	     	_.forEach(activityCategories, (activity, index) => {
		      	activity_options.push(
		        	<Option key={index} value={activity.value}>
		            	<div>{activity.label}</div>
		        	</Option>
		      	);
		    })

	    return (
	      	<Select
	        	placeholder="Select type of history"
	        	allowClear
	        	size="large"
	        	listHeight="300"
	      	>
	        	{activity_options}
	      	</Select>
	    );
	}

	const renderOptionsDrugUnit = () => {
	    let unit_options = [];

     	_.forEach(drugUnit, (unit, index) => {
      		unit_options.push(
		        <Option key={index} value={unit.value}>
		            <div>{unit.label}</div>
		        </Option>
		      );
    	})

	    return (
	      	<Select
		        placeholder="Select unit"
		        allowClear
		        size="large"
	      	>
	        	{unit_options}
	      	</Select>
	    );
  	}

  	const renderOptionsFeedingType = (feeding_type_options) => {
	    let feeding_options = [];
	    
	    _.forEach(feeding_type_options, (feeding_type, index) => {
	      	feeding_options.push(
	        	<Option key={index} value={feeding_type.name}>
	            	<div>{feeding_type.name}</div>
	        	</Option>
	      	);
	    })
	    
	    return (
	      	<Select
		        placeholder="Select a feeding type"
		        allowClear
		        size="large"
	      	>
	        	{feeding_options}
	      	</Select>
	    );
	}

	const addFeedingType = (object, e) => {
		setVisibleFeedingType(true);
  	}

  	const cancelModalFeedingType = () => {
    	setVisibleFeedingType(false);
  	}

  	const handleFeedingTypeSubmit = (e) => {
	    feedingTypeForm
	      	.validateFields()
	      	.then(feeding_type_values => {
	        	onStoreFeedingType(feeding_type_values);
	      	})
	      	.catch(info => {
	        	console.log('Validate Failed:', info);
	        	setLoadingFeedingType(false);
	     	});
  	}

  	const onStoreFeedingType = (feeding_type_values) => {
	    const feeding_type_data = {
	      "feeding_type":{
	        ...feeding_type_values.feeding_type,
	        property_id: props.propertyId
	      }
	    }

	    feedingTypeService.storeOrUpdate(feeding_type_data).then(res => {
	      	if (res.code === '0000') {
		        var tmp_feeding_types = _.clone(feedingTypes);
		        tmp_feeding_types.push(res.feeding_type);
		        setFeedingTypes(tmp_feeding_types);
		        cancelModalFeedingType();
		        feedingTypeForm.resetFields();
		        setFields([
		          	{
		            	name: ['history', 'feeding_type'],
		            	value: res.feeding_type.name,
		          	},
		        ]);
	      	} else {
	        	setLoadingFeedingType(false);
	      	}
	    });
  	}

  	const renderOptionAssetForHistory = (asset_data) => {
	    let options = [];
	    
	    _.forEach(asset_data, (asset, index) => {
	      	if (asset.sex && asset.id !== dataLivestock.id && asset.displayed_name) {
		        options.push(
		          	<Option key={asset.id} value={asset.displayed_name} label={asset.displayed_name}>
		            	{objectColors.map((value, index) => {
			            	if (value.value == asset.tag_colour) {
				              	return (
				                	<ColorItemRow>
				                  		<ColorItemPreview style={{backgroundColor: value.color}}></ColorItemPreview>
				                  		<div>{asset.displayed_name}</div>
				                	</ColorItemRow>
				                )
			            	}
		          		})}
		          	</Option>
		        );
		    }
    	})

	    return (
	      	<Select
		        placeholder="Select a livestock"
		        allowClear
		        size="large"
		        showSearch
		        optionFilterProp="label"
		        filterOption={true}
	      	>
	        	{options}
	      	</Select>
	    );
	}

	const renderOptionsPregTesting = () => {
	    let preg_options = [];
	    
	    _.forEach(pregTesting, (preg, index) => {
	      	preg_options.push(
	        	<Option key={index} value={preg.value}>
	            	<div>{preg.label}</div>
	        	</Option>
	      	);
	    })

	    return (
	      	<Select
		        placeholder="Select preg testing"
		        allowClear
		        size="large"
	      	>
	        	{preg_options}
	      	</Select>
	    );
	}

	const renderOptionObject = (objects) => {
	    let options = [];

	    _.forEach(objects, (object, index) => {
	      	options.push(
	        	<Option key={object.id} value={object.name}>
	          		{object.name}
	        	</Option>
	      	);
	    })

	    return (
	      	<Select
	        	placeholder="Select an object"
	        	allowClear
	        	size="large"
	      	>
	        	{options}
	      	</Select>
	    );
	}

	const onChangeSalePrice = e => {
		setHistorySalePrice(e);
	}

	const onChangeSaleWeight = e => {
		setHistorySaleWeight(e);
	}

	const cancelArchiveModal = () => {
	    setArchiveModalVisible(false);

	    if (props.cancelArchiveModal) {
	        props.cancelArchiveModal();
	    }
  	}

  	const handleArchiveModalSubmit = () => {
	    const body = {
	        asset: {
	            id: dataLivestock.id,
	        }
	    };

	    livestockService.archiveAsset(body).then(res => {
	        if (res.code === '0000') {
	            setArchiveModalVisible(false);

	            if (props.afterArchived) {
	                props.afterArchived();
	            }
	        }
	    });
	}

	return (
		<>
			<AddHistoryModalWrapper
			  	open={props.visibleHistory}
			  	onCancel={cancelModalHistory}
			  	centered
			  	maskClosable={true}
			  	title={ <IntlMessages id="propertyPage.mobModal.historyTab.addHistory"/> }
			  	footer={[        
			    	<Button key="back" onClick={cancelModalHistory} className="cancel-button">
			      		{<IntlMessages id="propertyPage.modal.cancel" />}
			    	</Button>,
			    	<Button key="submit" className="btn-success" type="primary" onClick={handleHistorySubmit} loading={loadingHistory}>
			      		{<IntlMessages id="propertyPage.modal.save" />}
			    	</Button>,
			  	]}
			>
    		{
          	<Form
	            form={addHistoryForm}
	            layout="vertical"
	            scrollToFirstError
	            fields={fields}
	          >
	          <div className="form-body">
	            <Form.Item
	                  name={['history', 'activity_category']}
	                  className="isoInputWrapper"
	                  label="Type of History"
	                  rules={[
	                    {
	                      required: true,
	                      message: 'This field is required',
	                    },
	                  ]}
	                >
	                {renderOptionsActivityCategories()}
	            </Form.Item>
	            <Form.Item
	              noStyle
	              shouldUpdate={(prevValues, currentValues) => {
	                return prevValues['history'];
	              }}
	            >
	            {({ getFieldValue }) => {
	              if(getFieldValue('history')){
	                if (getFieldValue('history').activity_category === "treatment"){
	                  return (<div><Form.Item
	                                name={['history', 'treatment_name']}
	                                className="isoInputWrapper"
	                                label="Treatment Name"
	                                rules={[
	                                  {
	                                    required: true,
	                                    message: 'This field is required',
	                                  },
	                                ]}
	                              >
	                              <Input size="large" placeholder="Input treatment name"/>
	                          </Form.Item>
	                          <Row>
	                            <Col xs={{span: 24}} md={{span: 11}}>
	                              <Form.Item
	                                name={['history', 'treatment_quantity']}
	                                className="isoInputWrapper"
	                                label="Quantity"
	                                rules={[
	                                  {
	                                    required: true,
	                                    message: 'This field is required',
	                                  },
	                                ]}
	                              >
	                              <InputNumber size="large" min={1} max={1000} />
	                              </Form.Item>
	                            </Col>
	                            <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
	                              <Form.Item
	                                name={['history', 'treatment_unit']}
	                                className="isoInputWrapper"
	                                label="Unit"
	                                rules={[
	                                  {
	                                    required: true,
	                                    message: 'This field is required',
	                                  },
	                                ]}
	                              >
	                              {renderOptionsDrugUnit()}
	                              </Form.Item>
	                            </Col>
	                          </Row>
	                          <Row>
	                            <Col xs={{span: 24}} md={{span: 11}}>
	                              <Form.Item
	                                name={['history', 'treatment_WHP']}
	                                className="isoInputWrapper"
	                                label="WHP (Number in days)"
	                              >
	                              <InputNumber size="large" min={1} max={1000} />
	                              </Form.Item>
	                            </Col>
	                            <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
	                              <Form.Item
	                                name={['history', 'treatment_ESI']}
	                                className="isoInputWrapper"
	                                label="ESI (Number in days)"
	                              >
	                              <InputNumber size="large" min={1} max={1000} />
	                              </Form.Item>
	                            </Col>
	                          </Row>
	                          </div>)
	                }else if(getFieldValue('history').activity_category === "feeding"){
	                  return (<div>
	                            <Row>
	                              <Col xs={{span: 12}} md={{span: 16}}>
	                                <Form.Item
	                                  name={['history', 'feeding_type']}
	                                  className="isoInputWrapper"
	                                  label="Feeding Type"
	                                  rules={[
	                                    {
	                                      required: true,
	                                      message: 'This field is required',
	                                    },
	                                  ]}
	                                >
	                                {renderOptionsFeedingType(feedingTypes)}
	                                </Form.Item>
	                              </Col>
	                              <Col xs={{span: 10, offset: 2}} md={{span: 6, offset: 2}}>
	                                <Button key="submit" className="btn-create-feeding-type" type="primary" onClick={addFeedingType}>
	                                  {<IntlMessages id="propertyPage.modal.feedingType.addFeedingType.title" />}
	                                </Button>
	                              </Col>
	                            </Row>
	                            <Form.Item
	                                name={['history', 'feeding_quantity']}
	                                className="isoInputWrapper"
	                                label="Quantity"
	                                rules={[
	                                  {
	                                    required: true,
	                                    message: 'This field is required',
	                                  },
	                                ]}
	                              >
	                              <InputNumber size="large" min={1} max={1000} />
	                            </Form.Item>
	                            <FeedingTypeModalWrapper
	                              open={visibleFeedingType}
	                              onCancel={cancelModalFeedingType}
	                              centered
	                              maskClosable={true}
	                              title={ <IntlMessages id="propertyPage.modal.feedingType.addFeedingType.title"/> }
	                              footer={[        
	                                <Button key="back" onClick={cancelModalFeedingType} className="cancel-button">
	                                  {<IntlMessages id="propertyPage.modal.cancel" />}
	                                </Button>,
	                                <Button key="submit" className="btn-success" type="primary" onClick={handleFeedingTypeSubmit} loading={loadingFeedingType}>
	                                  {<IntlMessages id="propertyPage.modal.save" />}
	                                </Button>,
	                              ]}
	                            >
	                            {
	                            
	                                  <Form
	                                    form={feedingTypeForm}
	                                    layout="vertical"
	                                    scrollToFirstError
	                                  >
	                                  <div className="form-body">
	                                    <Form.Item
	                                      name={['feeding_type', 'name']}
	                                      className="isoInputWrapper"
	                                      label="Feeding Type"
	                                      rules={[
	                                        {
	                                          required: true,
	                                          message: 'This field is required',
	                                        },
	                                      ]}
	                                    >
	                                      <Input size="large" placeholder="Input feeding type"/>
	                                    </Form.Item>
	                                    </div>    
	                              </Form>
	                            }
	                            </FeedingTypeModalWrapper>
	                          </div>
	                          )
	                }else if(getFieldValue('history').activity_category === "joining"){
	                  return (<div>   
	                            <Form.Item
	                              name={['history', 'joining_siren']}
	                              className="isoInputWrapper"
	                              label="Sire"
	                            >
	                             {renderOptionAssetForHistory(assets)}
	                            </Form.Item>
	                        
	                            <Form.Item
	                              name={['history', 'joining_expected_duration']}
	                              className="isoInputWrapper"
	                              label="Expected Duration (Number of weeks)"
	                              rules={[
	                                    {
	                                      required: true,
	                                      message: 'This field is required',
	                                    },
	                              ]}
	                            >
	                            <InputNumber size="large" min={1} max={1000} />
	                            </Form.Item>
	                          </div>)
	                }else if(getFieldValue('history').activity_category === "marking"){
	                  return (<div>
	                            <Form.Item
	                                name={['history', 'marking_system_date']}
	                                className="isoInputWrapper"
	                                label="System Date"
	                                rules={[
	                                  {
	                                    required: true,
	                                    message: 'This field is required',
	                                  },
	                                ]}
	                              >
	                             <DatePicker size="large" style={{width: '100%'}}/>
	                            </Form.Item>
	                          </div>)
	                }else if(getFieldValue('history').activity_category === "weighing"){
	                  return (<div>
	                            <Form.Item
	                              name={['history', 'weight']}
	                              className="isoInputWrapper"
	                              label="Weight (kg)"
	                              rules={[
	                                {
	                                  required: true,
	                                  message: 'This field is required',
	                                },
	                              ]}
	                            >
	                            <InputNumber size="large" min={1} max={1000} />
	                          </Form.Item>
	                          </div>)
	                }else if(getFieldValue('history').activity_category === "preg_testing"){
	                  return (<div>
	                            <Form.Item
	                                name={['history', 'preg_testing']}
	                                className="isoInputWrapper"
	                                label="Preg Testing"
	                                rules={[
	                                  {
	                                    required: true,
	                                    message: 'This field is required',
	                                  },
	                                ]}
	                              >
	                              {renderOptionsPregTesting()}
	                            </Form.Item>
                              {
                                (getFieldValue('history').preg_testing === 'pregnant' || 
                                getFieldValue('history').preg_testing === 'multiple')
                                &&
                                <Form.Item
                                  name={['history', 'justation']}
                                  className="isoInputWrapper"
                                  label="Gestation (Number of weeks)"
                                  rules={[
                                        {
                                          required: true,
                                          message: 'This field is required',
                                        },
                                  ]}
                                >
                                <InputNumber size="large" min={1} addonAfter="weeks"/>
                              </Form.Item>
                              }
                          
	                          </div>)
	                }else if (getFieldValue('history').activity_category === "crutching"){
	                  return (<div>
	                            <Form.Item
	                                name={['history', 'crutching_system_date']}
	                                className="isoInputWrapper"
	                                label="System Date"
	                                rules={[
	                                  {
	                                    required: true,
	                                    message: 'This field is required',
	                                  },
	                                ]}
	                              >
	                             <DatePicker size="large" style={{width: '100%'}}/>
	                            </Form.Item>
	                          </div>)
	                }else if (getFieldValue('history').activity_category === "death"){
	                  return (<div>
	                            <Form.Item
	                                name={['history', 'cause_of_death']}
	                                className="isoInputWrapper"
	                                label="Cause of death"
	                                rules={[
	                                  {
	                                    required: true,
	                                    message: 'This field is required',
	                                  },
	                                ]}
	                              >
	                              <Input.TextArea />
	                            </Form.Item>
	                            <Form.Item
	                                name={['history', 'death_location']}
	                                className="isoInputWrapper"
	                                label="Location"
	                                rules={[
	                                  {
	                                    required: true,
	                                    message: 'This field is required',
	                                  },
	                                ]}
	                              >
	                             {renderOptionObject(props.primaryObjects)}
	                            </Form.Item>
	                          </div>)
	                } else if ( getFieldValue('history').activity_category === 'sale'){
                  return (<div>
                            <SalePriceInput>
                              <Form.Item
                                name={['history', 'sale_price']}
                                className="isoInputWrapper"
                                label="Sales Price"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                  {
                                    type: 'number',
                                  }
                                ]}
                                style={{width: '100%'}}
                              >
                                <InputNumber size="large" placeholder="Input sale price" style={{width: '100%'}} onChange={onChangeSalePrice}/>
                              </Form.Item>
                              <SuffixAddon>$</SuffixAddon>
                            </SalePriceInput>
                            <SalePriceInput>
                              <Form.Item
                                name={['history', 'weight']}
                                className="isoInputWrapper"
                                label="Weight"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                  {
                                    type: 'number',
                                  }
                                ]}
                                style={{width: '100%'}}
                              >
                                <InputNumber size="large" placeholder="Input weight" style={{width: '100%'}} onChange={onChangeSaleWeight}/>
                              </Form.Item>
                              <SuffixAddon>kg</SuffixAddon>
                            </SalePriceInput>
                            <Form.Item
                                name={['history', 'description']}
                                className="isoInputWrapper"
                                label="Description"
                              >
                             <Input.TextArea />
                            </Form.Item>
                          </div>)
                }else if(getFieldValue('history').activity_category === "other"){
	                  return (<div>
	                            <Form.Item
	                                name={['history', 'other_title']}
	                                className="isoInputWrapper"
	                                label="Title"
	                                rules={[
	                                  {
	                                    required: true,
	                                    message: 'This field is required',
	                                  },
	                                ]}
	                              >
	                              <Input size="large" placeholder="Input title"/>
	                            </Form.Item>
	                            <Form.Item
	                                name={['history', 'other_comment']}
	                                className="isoInputWrapper"
	                                label="Comment"
	                              >
	                              <Input.TextArea />
	                            </Form.Item>
	                            <Form.Item
	                                name={['history', 'other_location']}
	                                className="isoInputWrapper"
	                                label="Location"
	                              >
	                             {renderOptionObject(props.primaryObjects)}
	                            </Form.Item>
	                          </div>)
	                } else {}
	              }
	            }}
	            </Form.Item>
	          </div>    
	      </Form>
    }
    </AddHistoryModalWrapper>

    <AddHistoryModalWrapper
        open={archiveModalVisible}
        centered
        onCancel={cancelArchiveModal}
        title="Archive Livestock"
        footer={[
            <Button key="back" onClick={cancelArchiveModal} className="cancel-button">
              {<IntlMessages id="propertyPage.modal.cancel" />}
            </Button>,
            <Button key="submit" className="btn-success" type="primary" onClick={handleArchiveModalSubmit} >
              {<IntlMessages id="propertyPage.modal.accept" />}
            </Button>,
        ]}
    >
        <div class="form-body">{<IntlMessages id="propertyPage.modal.archive.confirmation.thisLivestock" />}</div>
    </AddHistoryModalWrapper>
		</>
	)
}

export default AddHistoryModal;

const ColorItemRow = styled.div`
  display: flex;
  align-items: center;
  line-height: 30px;
  div{
    margin: 4px;
  }
`;

const ColorItemPreview = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 2px;
`;

const SuffixAddon = styled.span`
  margin-top: 31px;
  margin-bottom: 5px;
  line-height: 38px;
  color: #747784!important;
  flex-shrink: 0;
  border-top-right-radius: 4px!important;
  border-bottom-right-radius: 4px!important;
  border-right-width: 1px!important;
  min-width: 36px;
  background-color: #f8f9fa;
  border: solid #d9d9d9;
  border-width: 1px 0;
  white-space: nowrap;
  text-align: center;
`;

const SalePriceInput = styled.div`
  display: flex;
  align-items: flex-start;
`;