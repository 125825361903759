import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const CheckinFormWrapper = styled.div`
padding: 25px;
.btn-success{
  background-color: #f80e46;
  border-color: #f80e46;
  margin-top: 20px
}
.form-title{
  padding: 16px 24px;
  border-bottom: 1px solid #eaeef1;
  h3{
    font-size: 1rem;
  }
}
.form-body{
  padding: 12px 24px 12px 24px;
  .checkbox-contact{
    margin-bottom: 10px;
  }
}`;

export default WithDirection(CheckinFormWrapper);