import React from "react";
import { Form, Input, Table, Checkbox } from "antd";
import BioCheckbox from "../Components/BioCheckbox";

const { TextArea } = Input;

const TrainPlanRecordTable = (props) => {
  const columns = [
    {
      title: "6",
      dataIndex: "order",
      rowScope: "row",
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 3 };
        }
        if ([7, 9].includes(index)) {
          return { rowSpan: 2, className: index === 9 ? 'border-bottom' : ''  };
        }
        if ([2, 3, 8, 10].includes(index)) {
          return { rowSpan: 0};
        }
        return {};
      },
    },
    {
      title: "TRAIN – PLAN - RECORD",
      dataIndex: "train_plan_record",
      rowScope: "row",
      colSpan: 7,
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 3 };
        }
        if ([7, 9].includes(index)) {
          return { rowSpan: 2 , className: index === 9 ? 'border-bottom' : '' };
        }
        if ([2, 3, 8, 10].includes(index)) {
          return { rowSpan: 0 };
        }
        return {};
      },
      width: 500,
    },
    {
      title: "Yes",
      colSpan: 0,
      dataIndex: "yes",
      rowScope: "row",
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 3 };
        }
        if ([7, 9].includes(index)) {
          return { rowSpan: 2 , className: index === 9 ? 'border-bottom' : '' };
        }
        if ([2, 3, 8, 10].includes(index)) {
          return { rowSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "No",
      colSpan: 0,
      dataIndex: "no",
      rowScope: "row",
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 3 };
        }
        if ([7, 9].includes(index)) {
          return { rowSpan: 2 , className: index === 9 ? 'border-bottom' : '' };
        }
        if ([2, 3, 8, 10].includes(index)) {
          return { rowSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "N/A",
      colSpan: 0,
      dataIndex: "na",
      rowScope: "row",
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 3 };
        }
        if ([7, 9].includes(index)) {
          return { rowSpan: 2 , className: index === 9 ? 'border-bottom' : '' };
        }
        if ([2, 3, 8, 10].includes(index)) {
          return { rowSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "Recommended measures ",
      colSpan: 0,
      dataIndex: "recommended_measures",
      rowScope: "row",
      width: 500,
      onCell: (_, index) => {
        if (index === 10) {
          return { className: 'border-bottom' };
        }
        return {};
      },
    },
    {
      title: "Actioned ?",
      colSpan: 0,
      dataIndex: "actioned",
      rowScope: "row",
      onCell: (_, index) => {
        if (index === 10) {
          return { className: 'border-bottom' };
        }
        return {};
      },
    },
    {
      title: "Comments",
      colSpan: 0,
      dataIndex: "comments",
      rowScope: "row",
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 3 };
        }
        if ([7, 9].includes(index)) {
          return { rowSpan: 2 , className: index === 9 ? 'border-bottom' : '' };
        }
        if ([2, 3, 8, 10].includes(index)) {
          return { rowSpan: 0 };
        }
        return {};
      },
      className: "comment-column",
    },
  ];

  const data = [
    {
      key: "1",
      order: "6.1",
      train_plan_record: "Training",
      yes: "Yes",
      no: "No",
      na: "N/A",
      recommended_measures: "Recommended measures ",
      actioned: "Actioned?",
      comments: "Comments",
    },
    {
      key: "2",
      order: "6.1.1",
      train_plan_record:
        "Do all personnel responsible for management and husbandry understand their role in the implementation of biosecurity practices on-farm, and know how to identify sick and injured livestock?",
      yes: <BioCheckbox name="training_1_yes"></BioCheckbox>,
      no: <BioCheckbox name="training_1_no"></BioCheckbox>,
      na: <BioCheckbox name="training_1_na"></BioCheckbox>,
      recommended_measures:
        "Personnel training and instruction on animal health and welfare, including disease reporting, conducted.",
      actioned: <BioCheckbox name="training_1_1_actioned"></BioCheckbox>,
      comments: (
        <Form.Item name="trainging_1_comments" className="isoInputWrapper">
          <TextArea />
        </Form.Item>
      ),
    },
    {
      key: "3",
      order: "",
      train_plan_record: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "Emergency contact lists displayed in noticeable places on farm and all staff know where they are.",
      actioned: <BioCheckbox name="training_1_2_actioned"></BioCheckbox>,
      comments: "",
    },
    {
      key: "4",
      order: "",
      train_plan_record: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "Personnel have completed the LPA Learning modules.",
      actioned: <BioCheckbox name="training_1_3_actioned"></BioCheckbox>,
      comments: "",
    },
    {
      key: "5",
      order: "6.1.2",
      train_plan_record:
        "Do all personnel responsible for management and husbandry know where to find contact details for the local vet(s) and government animal health officer(s), and what to do in the event of a suspected emergency animal disease?",
      yes: <BioCheckbox name="training_2_yes"></BioCheckbox>,
      no: <BioCheckbox name="training_2_no"></BioCheckbox>,
      na: <BioCheckbox name="training_2_na"></BioCheckbox>,
      recommended_measures:
        "EAD Watch Hotline (1800 675 888) is displayed in a common and visible location.",
      actioned: <BioCheckbox name="training_2_1_actioned"></BioCheckbox>,
      comments: (
        <Form.Item name="trainging_2_comments" className="isoInputWrapper">
          <TextArea />
        </Form.Item>
      ),
    },
    {
      key: "6",
      order: "6.2",
      train_plan_record: "Documentation and record keeping",
      yes: "Yes",
      no: "No",
      na: "N/A",
      recommended_measures: "Recommended measures ",
      actioned: "Actioned?",
      comments: "Comments",
    },
    {
      key: "7",
      order: "6.2.1",
      train_plan_record:
        "Do you record animal health activities and treatments to maintain herd/flock health history and provide accurate NVDs/eNVDs and AHDs when selling livestock?",
      yes: (
        <BioCheckbox name="documentation_record_keeping_1_yes"></BioCheckbox>
      ),
      no: <BioCheckbox name="documentation_record_keeping_1_no"></BioCheckbox>,
      na: <BioCheckbox name="documentation_record_keeping_1_na"></BioCheckbox>,
      recommended_measures: "Livestock treatments accurately recorded.",
      actioned: (
        <BioCheckbox name="documentation_record_keeping_1_1_actioned"></BioCheckbox>
      ),
      comments: (
        <Form.Item
          name="documentation_record_keeping_1_comments"
          className="isoInputWrapper"
        >
          <TextArea />
        </Form.Item>
      ),
    },
    {
      key: "8",
      order: "6.2.2",
      train_plan_record:
        "Are all vulnerable personnel working on the property vaccinated for identified risk diseases such as Q Fever and tetanus and, where appropriate, have stock been vaccinated to prevent animal-to-human transmissible diseases such as leptospirosis?",
      yes: (
        <BioCheckbox name="documentation_record_keeping_2_yes"></BioCheckbox>
      ),
      no: <BioCheckbox name="documentation_record_keeping_2_no"></BioCheckbox>,
      na: <BioCheckbox name="documentation_record_keeping_2_na"></BioCheckbox>,
      recommended_measures: "Vaccination records from staff requested/on file",
      actioned: (
        <BioCheckbox name="documentation_record_keeping_2_1_actioned"></BioCheckbox>
      ),
      comments: (
        <Form.Item
          name="documentation_record_keeping_2_comments"
          className="isoInputWrapper"
        >
          <TextArea />
        </Form.Item>
      ),
    },
    {
      key: "9",
      order: "",
      train_plan_record: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "Vaccination programs on property implemented if necessary and records maintained.",
      actioned: (
        <BioCheckbox name="documentation_record_keeping_2_2_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "10",
      order: "6.2.3",
      train_plan_record:
        "Do you review your farm biosecurity management plan annually?",
      yes: (
        <BioCheckbox name="documentation_record_keeping_3_yes"></BioCheckbox>
      ),
      no: <BioCheckbox name="documentation_record_keeping_3_no"></BioCheckbox>,
      na: <BioCheckbox name="documentation_record_keeping_3_na"></BioCheckbox>,
      recommended_measures:
        "Biosecurity activities to be undertaken over the next 12 months identified and documented.",
      actioned: (
        <BioCheckbox name="documentation_record_keeping_3_1_actioned"></BioCheckbox>
      ),
      comments: (
        <Form.Item
          name="documentation_record_keeping_3_comments"
          className="isoInputWrapper"
        >
          <TextArea />
        </Form.Item>
      ),
    },
    {
      key: "11",
      order: "",
      train_plan_record: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "Regular property inspections for actual or potential biosecurity issues undertaken.",
      actioned: (
        <BioCheckbox name="documentation_record_keeping_3_2_actioned"></BioCheckbox>
      ),
      comments: "",
    },
  ];

  return (
    <Table className="bio-table" columns={columns} dataSource={data} bordered pagination={false} />
  );
};

export default TrainPlanRecordTable;