import styled from "styled-components";
import Modal from "@iso/components/Feedback/Modal";
import WithDirection from "@iso/lib/helpers/rtl";

const SelectPropertyModalWrapper = styled(Modal)`
  &.user-access-modal {
    width: 640px !important;
  }
  .ant-modal-body {
    padding: 0;
  }
  .btn-success {
    background-color: #3f3f44;
    border-color: #3f3f44;
    gap: 20px;
    display: flex;
    align-items: center;
    margin: 0 auto;
  }
  .form-title {
    padding: 16px 24px;
    border-bottom: 1px solid #eaeef1;
    // h3 {
    //   font-size: 1rem;
    // }
  }
  .form-body {
    text-align : center;
    padding: 12px 24px 12px 24px;
    .checkbox-contact {
      margin-bottom: 10px;
    }
    .dltInfo {
      font-size: 2rem;
    }
  }
`;

export default WithDirection(SelectPropertyModalWrapper);
