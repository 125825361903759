import React from "react";

const Assets = () => {
	return (
		<div className="sidebar-icon">

			<svg id="Layer_1"
				className="sidebar-icons"
				data-name="Layer 1"
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 512 512"
			>

				{/* <image style="overflow:visible;" width="67" height="67" xlink:href="safety_assets.png"  transform="matrix(1 0 0 1 -499.6471 149)">
</image> */}
				<g className="sidebar-icons">
					<polygon points="-415.6,164.8 -421.4,173.7 -421.4,201.1 -416.9,208.9 -366.2,208.9 -361.2,203.2 -361.2,173.7 
		-366.1,164.8 "/>
					<line x1="-361.2" y1="173.7" x2="-421.4" y2="173.7" />
					<path d="M-410,182L-410,182c-2.1,0-3.7-1.7-3.7-3.7v-4.6h7.4v4.6C-406.3,180.3-407.9,182-410,182z"

					/>
					<path d="M-372.4,182L-372.4,182c-2.1,0-3.7-1.7-3.7-3.7v-4.6h7.4v4.6C-368.7,180.3-370.3,182-372.4,182z"

					/>
					<path d="M-373.5,160.2L-373.5,160.2c1.7,0,3.1,1.4,3.1,3.1v1.5h-6.1v-1.5C-376.6,161.6-375.2,160.2-373.5,160.2z"
					/>
					<path d="M-409.3,160.2L-409.3,160.2c1.7,0,3.1,1.4,3.1,3.1v1.5h-6.1v-1.5C-412.4,161.6-411,160.2-409.3,160.2z"
					/>
					<path d="M-380,164.8h-22.4v-3.4c0-3.4,2.8-6.2,6.2-6.2h10c3.4,0,6.2,2.8,6.2,6.2V164.8z"

					/>
					<circle cx="-390.9" cy="190.2" r="8.8" />
					<line x1="-391.1" y1="185.4" x2="-391.1" y2="196.1" />
					<line x1="-386.5" y1="193.4" x2="-395.8" y2="188" />
					<line x1="-386.4" y1="188.2" x2="-395.9" y2="193.2" />
					<line x1="-414.4" y1="204.7" x2="-404.2" y2="204.7" />
				</g>
				<g>
					<g className="sidebar-icons">
						<path d="M441.7,459.1H52.9l-36.8-63.9V185l46.7-72.5h380.7l40.2,72.8V412L441.7,459.1z M61.6,444.1H435l33.9-37.8
			V189.2l-34-61.6H71.1l-39.9,61.9v201.8L61.6,444.1z"

						/>
					</g>
					<g className="sidebar-icons">
						<rect x="23.7" y="179.7" width="452.7" height="15" />
					</g>
					<g className="sidebar-icons">
						<path d="M109.5,257.1c-19.6,0-35.5-15.9-35.5-35.5v-41.9h71v41.9C145,241.2,129.1,257.1,109.5,257.1z M89.1,194.7
			v26.9c0,11.3,9.2,20.4,20.4,20.4s20.4-9.2,20.4-20.4v-26.9H89.1z"

						/>
					</g >
					<g className="sidebar-icons">
						<path d="M392.1,257.1c-19.6,0-35.5-15.9-35.5-35.5v-41.9h71v41.9C427.6,241.2,411.7,257.1,392.1,257.1z M371.7,194.7
			v26.9c0,11.3,9.2,20.4,20.4,20.4s20.4-9.2,20.4-20.4v-26.9H371.7z"  />
					</g>
					<g className="sidebar-icons">
						<path d="M414,127.5h-61.2V109c0-16.9,13.7-30.6,30.6-30.6c16.9,0,30.6,13.7,30.6,30.6V127.5z M367.9,112.5H399V109
			c0-8.6-7-15.5-15.6-15.5s-15.5,7-15.5,15.5V112.5z" />
					</g>
					<g className="sidebar-icons">
						<path d="M145,127.5H83.9V109c0-16.9,13.7-30.6,30.6-30.6c16.9,0,30.6,13.7,30.6,30.6V127.5z M98.9,112.5H130V109
			c0-8.6-7-15.5-15.6-15.5s-15.5,7-15.5,15.5V112.5z"  />
					</g>
					<g className="sidebar-icons">
						<path d="M342.4,127.5h-183V94.8c0-29.7,24.2-53.9,53.9-53.9h75.1c29.7,0,53.9,24.2,53.9,53.9V127.5z M174.4,112.5
			h152.9V94.8c0-21.4-17.5-38.9-38.9-38.9h-75.1c-21.4,0-38.9,17.5-38.9,38.9V112.5z" />
					</g>
					<g className="sidebar-icons">
						<path d="M252.7,384.8c-40.7,0-73.8-33.1-73.8-73.8s33.1-73.8,73.8-73.8s73.8,33.1,73.8,73.8S293.4,384.8,252.7,384.8z
			 M252.7,252.2c-32.4,0-58.8,26.4-58.8,58.8c0,32.4,26.4,58.8,58.8,58.8c32.4,0,58.8-26.4,58.8-58.8
			C311.5,278.6,285.1,252.2,252.7,252.2z"  />
					</g>
					<g className="sidebar-icons">
						<rect x="243.6" y="274.9" width="15" height="80.6" />
					</g>
					<g className="sidebar-icons">

						<rect x="243.6" y="274.9" transform="matrix(0.4997 -0.8662 0.8662 0.4997 -147.3486 375.164)" width="15" height="80.6" />
					</g>
					<g className="sidebar-icons">

						<rect x="210.8" y="307.6" transform="matrix(0.8842 -0.467 0.467 0.8842 -118.1141 153.7574)" width="80.6" height="15" />
					</g>
					<g className="sidebar-icons">
						<rect x="76.3" y="412.7" width="76.5" height="15" />
					</g>
				</g>
			</svg>

		</div>
	);
};
export default Assets;
