export const hazardTypes = [
  {
    label: 'Safety hazards',
    value: 'safety_hazards',
  },
  {
    label: 'Biological hazards',
    value: 'biological_hazards',
  },
  {
    label: 'Physical hazards',
    value: 'physical_hazards',
  },
  {
    label: 'Ergonomic hazards',
    value: 'ergonomic_hazards',
  },
  {
    label: 'Chemical hazards',
    value: 'chemical_hazards',
  },
  {
    label: 'Work organization hazards',
    value: 'work_organization_hazards',
  },
  {
    label: 'Environmental hazards',
    value: 'environmental_hazards',
  },
  {
    label: 'Other',
    value: 'other',
  }
]