import React from "react";

const Notification = (props) => {
  const { toggleNoti, setToggleNoti } = props;

  return (
    <div onClick={() => setToggleNoti(!toggleNoti)}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 6.44V9.77"
          stroke="#8F92A1" style={{strokeWidth:'1.5',strokeMiterlimit:'10',strokeLinecap:'round'}}
        />
        <path
          d="M12.02 2C8.33999 2 5.35999 4.98 5.35999 8.66V10.76C5.35999 11.44 5.07999 12.46 4.72999 13.04L3.45999 15.16C2.67999 16.47 3.21999 17.93 4.65999 18.41C9.43999 20 14.61 20 19.39 18.41C20.74 17.96 21.32 16.38 20.59 15.16L19.32 13.04C18.97 12.46 18.69 11.43 18.69 10.76V8.66C18.68 5 15.68 2 12.02 2Z"
          stroke="#8F92A1"
          style={{strokeWidth:'1.5',strokeMiterlimit:'10',strokeLinecap:'round'}}
        />
        <path
          d="M15.33 18.82C15.33 20.65 13.83 22.15 12 22.15C11.09 22.15 10.25 21.77 9.65001 21.17C9.05001 20.57 8.67001 19.73 8.67001 18.82"
          stroke="#8F92A1" style={{strokeWidth:'1.5',strokeMiterlimit:'10',strokeLinecap:'round'}}
        />
      </svg>
    </div>
  );
};
export default Notification;
