import React from "react";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import IntlMessages from "@iso/components/utility/intlMessages";
import authAction from "@iso/redux/auth/actions";
import appAction from "@iso/redux/app/actions";
import userAction from "@iso/redux/user/actions";
import SignInStyleWrapper from "./SignIn.styles";
import { Form, Input, Button } from "antd";
import { userService } from "@iso/services";
import Alert from "@iso/components/Feedback/Alert";
import logo from "@iso/assets/images/logo.png";
import queryString from "query-string";
import "./responsive.css";

const { login } = authAction;
const { clearMenu } = appAction;
const { store } = userAction;

export default function SignIn() {
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.Auth.idToken);
  const activePropertyId = useSelector(
    (state) => state.property.activePropertyId
  );
  const formToken = queryString.parse(window.location.search).form_token || "";
  const sharingToken =
    queryString.parse(window.location.search).sharing_token || "";

  const [form] = Form.useForm();
  const [errMsg, setErrMsg] = React.useState("");

  const onFinish = (values) => {
    userService.login(values).then((res) => {
      if (res.code === "0000") {
        dispatch(login(res.access_token));
        dispatch(store());
        dispatch(clearMenu());
        if (res.is_system_admin == 1) {
          history.push("/admin-dashboard");
        } else {
          if (formToken && formToken != "") {
            history.push("/form-submission?token=" + formToken);
          } else if (sharingToken && sharingToken != "") {
            history.push("/share-map?token=" + sharingToken);
          } else {
            if (activePropertyId) {
              history.push("/dashboard");
            } else {
              history.push("/manage-properties");
            }
          }
        }
      } else {
        setErrMsg(res.message);
      }
    });
  };

  const [redirectToReferrer, setRedirectToReferrer] = React.useState(false);
  React.useEffect(() => {
    if (isLoggedIn) {
      setRedirectToReferrer(true);
    }
  }, [isLoggedIn]);

  let { from } = location.state || { from: { pathname: "/dashboard" } };

  const marginBot = {
    marginBottom: "10px",
  };

  if (redirectToReferrer) {
    return <Redirect to={from} />;
  }
  return (
    <SignInStyleWrapper className="isoSignInPage">
      <div className="isoLoginContentWrapper">
        <div className="isoLoginContent">
          <div className="signin-form-main">
            <div className="isoLogoWrapper">
              <Link to="/dashboard">
                <img src={logo} height="100" />
              </Link>
            </div>
            {sharingToken && sharingToken != "" && (
              <p className="sharing-msg">
                <IntlMessages id="page.signInInviteMessage" />
              </p>
            )}
            {formToken && formToken != "" && (
              <p className="sharing-msg">
                <IntlMessages id="page.signInInviteFormMessage" />
              </p>
            )}
            {errMsg && (
              <Alert message={errMsg} type="error" style={marginBot} />
            )}
            <div className="isoSignInForm">
              <span className="signin-heading-hi">
                Hi,
                <span></span><span className="signin-heading">Welcome Back!</span>
              </span>
              <Form
                form={form}
                name="register"
                onFinish={onFinish}
                scrollToFirstError
                className="login-main-form"
              >
                <Form.Item
                  name="email"
                
                  className="isoInputWrapper form-field"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid Email!",
                    },
                    {
                      required: true,
                      message: "Please input your Email!",
                    },
                  ]}
                >
                  {/* <label className="signin-inputlabel" for="html">
                    Your email or phone number
                  </label> */}
                  <Input
                    size="large"
                    className="signin-placeholder"
                    placeholder="youremail@gmail.com"
                  />
                </Form.Item>
                <Link to="/forgotpassword">
                  <span className="forgotpass">Forgot Password?</span>
                </Link>
                <Form.Item
                  name="password"
                  className="isoInputWrapper form-field"
                 
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password!",
                    },
                    {
                      min: 6,
                      message: "Password must be minimum 6 characters.",
                    },
                  ]}
                >
                  {/* <div className="signin-label-div">
                    <label className="signin-inputlabel" for="html"></label>
                  </div> */}
                  <Input.Password
                    className="signin-placeholder password-placeholder"
                    type="password"
                    size="large"
                    placeholder="Please Enter Your Password"
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="signin-btn"
                  >
                    {/* <IntlMessages id="page.signInButton" /> */}
                    Login
                  </Button>
                </Form.Item>
              </Form>

              <div
                // className="isoCenterComponent isoHelperWrapper"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                
                <span className="signin-span" style={{ color: "black" }}>
                  Don't have an account?
                </span>
                <Link className="signup-btn" to="/signup">
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SignInStyleWrapper>
  );
}
