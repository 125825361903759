import React, { useState, useEffect, useCallback } from "react";
import { Form, Input, Button, Select, Dropdown, Menu, Popconfirm } from "antd";
import IntlMessages from "@iso/components/utility/intlMessages";
import PropertyModalWrapper from '../Property/PropertyModal.styles'
import Box from '@iso/components/utility/box';
import {
    PlusOutlined, EllipsisOutlined, EditOutlined, EyeOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { useSelector } from "react-redux";
import _ from "lodash";
import Loader from "@iso/components/utility/loader";
// import PermissionSelect from "./PermissionSelect";
import Table from "@iso/components/collapsing-table/table";

import { qualificationService } from '@iso/services';
import moment from "moment";
import QualificationModal from "../Profile/QualificationModal";
const { Option } = Select;

const ViewQualifications = (props) => {
 
    const [qualificationTrade, setQualificationTrade] = useState([]);
    const [qualificationCourse, setQualificationCourse] = useState([]);
    const [qualificationFirstAid, setQualificationFirstAid] = useState([]);

    const activePropertyId = useSelector(
        (state) => state.property.activePropertyId
    );

  
   
    const permissions = useSelector((state) => state.permission.permissions);
      const[title,setTitle] =React.useState('')
    const [visibleQualification, setVisibilityQualification] = React.useState(false);
    const [visibleModal,setVisibleModal] = React.useState(false)
    const [viewSummary,setViewSummary]= React.useState(); 
    const cancelModalQualification = () => {
        if (props.visibleQualificationsModal) {
            setQualificationCourse();
            setQualificationFirstAid();
            setQualificationTrade();
            props.setViewSummary('')
            props.setVisibleQualificationsModal(false);
        }

    };

useEffect(() =>{
    updateQualificationData(props?.viewSummary?.id);
},[props?.viewSummary])

    const updateQualificationData = (id) => {
        if (id) {
          qualificationService.getList(id, 'trade').then(res => {
            if (res.code === '0000') {
              setQualificationTrade(res.qualifications);
            }
          });
          qualificationService.getList(id, 'course').then(res => {
            if (res.code === '0000') {
              setQualificationCourse(res.qualifications);
            }
          });
          qualificationService.getList(id, 'first_aid').then(res => {
            if (res.code === '0000') {
              setQualificationFirstAid(res.qualifications);
            }
          });
        }
    }
  
    const menuList = (qualification, permission) => (
        <Menu onClick={handleMenuClick.bind(this, qualification)}>
          <Menu.Item key="editDetails" icon={<EditOutlined />}>
            <IntlMessages id="propertyPage.managePage.editDetails" />
          </Menu.Item>
          
          <p style={{ cursor: "pointer" }} className="deleteButton">
            <Popconfirm
              placement="bottomRight"
              title="You are about to delete individual record"
              onConfirm={() => {
                confirmDelete(qualification);
              }}
              okText="Yes"
              cancelText="No"
              className="delete-button"
            >
              <a style={{ color: "rgba(0, 0, 0, 0.65)", marginLeft: "11px" }}>
                {<DeleteOutlined />}&nbsp;&nbsp;
                <IntlMessages id="Remove" />
              </a>
            </Popconfirm>
          </p>
        </Menu>
      );
      const confirmDelete = (qualification) => {
        if (qualification.id) {
          qualificationService.destroy(qualification.id).then((res) => {
            if (res.code === "0000") {
              updateQualificationData(props?.viewSummary?.id);
            }
          });
        }
      };
      const handleMenuClick = (qualification, e) => {
    
        
        if (e.key === "viewDetails") {
    
        //   setViewSummary(true);
        //   setQualificationData(qualification);
        //   setTitle('Qualifications')
        }
        if (e.key === "editDetails") {
          setViewSummary(qualification)
        setVisibleModal(true);
        setTitle("Qualifications")
      
    
        }
      }
      const onSaved = () => {
        updateQualificationData(props?.viewSummary?.id);
      }
      const onCancel = () =>{
        setVisibleModal(false)
        setViewSummary('');
      }
      const columns = [
        {
          label: <IntlMessages id="profilePage.qualificationsTab.table.name" />,
          accessor: 'name',
          minWidth: 100,
          sortable: false,
          position: 1,
          priorityLevel: 1
        },
        {
          label: <IntlMessages id="profilePage.qualificationsTab.table.completion" />,
          accessor: 'completion',
          minWidth: 100,
          sortable: false,
          position: 2,
          priorityLevel: 2,
          CustomComponent: cell => {
            let qualification = cell.row
            return (qualification.completion) ? moment(qualification.completion).format('L') : ''
          }
        },
        {
          label: <IntlMessages id="profilePage.qualificationsTab.table.institution" />,
          accessor: 'institution',
          minWidth: 100,
          sortable: false,
          position: 3,
          priorityLevel: 3
        },
        {
          label: "",
          accessor: "dot-menu",
          minWidth: 100,
          sortable: true,
          position: 4,
          priorityLevel: 4,
          noTitle: true,
          CustomComponent: (cell) => {
            let qualification = cell.row
            // let permission = permissionService.getPermission(row.permission_id);
            return (
              <Dropdown overlay={menuList(qualification)} trigger={["click"]}>
                <div
                  className="property-area-dot-btn"
                  style={{ cursor: "pointer" }}
                >
                  <EllipsisOutlined  className="dotIcon"/>
                </div>
              </Dropdown>
            );
          },
        },
    
      ];
  

    return (
        <PropertyModalWrapper
            className="user-access-modal"
            forceRender={true}
            open={props.visibleQualificationsModal}
            title={<IntlMessages id="View Qualifications" />}
            onCancel={cancelModalQualification}
            footer={[
                <Button key="back" onClick={cancelModalQualification} className="cancel-button">
                    {<IntlMessages id="propertyPage.modal.cancel" />}
                </Button>,
            ]}
        >
            <div className="form-title">
                <h3>{<IntlMessages id="profilePage.qualificationsTab.type.trade" />}</h3>
            </div>
            <Box id="containerBox">
              
                {
                    qualificationTrade?.length > 0? (
                        <Table
                            columns={columns}
                            rows={qualificationTrade}
                            containerId="containerBox"
                            showPagination={false}
                            rowSize={99999}
                        />
                    ) :
                        <h1 style={{ fontSize: '16px', padding: '15px', fontWeight: '600' }}>
                            <IntlMessages id="dashboardPage.noData" />
                        </h1>}
                {/* )} */}
            </Box>

            <div className="form-title">
                <h3>Courses</h3>
            </div>

           
            {qualificationCourse?.length > 0 ? (
                    <Table
                        columns={columns}
                        rows={qualificationCourse}
                        containerId="containerBox"
                        showPagination={false}
                        rowSize={99999}
                    />
                ) :
                    <h1 style={{ fontSize: '18px', padding: '15px', fontWeight: '600' }}>
                        <IntlMessages id="dashboardPage.noData" />
                    </h1>}
            {/* )} */}

            <div className="form-title">
                <h3>First Aid</h3>
            </div>

            {
        
                qualificationFirstAid?.length > 0 ? (
                    <Table
                        columns={columns}
                        rows={qualificationFirstAid}
                        containerId="containerBox"
                        showPagination={false}
                        rowSize={99999}
                    />
                ) :
                    <h1 style={{ fontSize: '18px', padding: '15px', fontWeight: '600' }}>
                        <IntlMessages id="dashboardPage.noData" />
                    </h1>

            // )
            }

      {visibleModal?
      <QualificationModal
          visibleQualification={visibleModal} 
          qualificationData={viewSummary}
          cancelModal={onCancel}
          onSaved={onSaved}
          setViewSummary={setViewSummary}
          title={title}
         />:''}
   
        </PropertyModalWrapper>
    );
};

export default ViewQualifications;
