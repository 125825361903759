import React, { useState, useEffect } from "react";
import { weatherDurationTomorrowIo } from "@iso/constants/weatherDurationTomorrowIo";
import { Select, Switch } from "antd";
import { ReactComponent as PrecipTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/precip-tomorrowio.svg";
import { ReactComponent as TemperatureTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/temperature-tomorrowio.svg";
import { ReactComponent as FeelsLikeTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/feels-like-tomorrowio.svg";
import { ReactComponent as HumidityTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/humidity-tomorrowio.svg";
import { ReactComponent as WindSpeedTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/wind-speed-tomorrowio.svg";
import { ReactComponent as WindGustTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/wind-gust-tomorrowio.svg";
import { ReactComponent as WindDirectionTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/wind-direction-tomorrowio.svg";
import { ReactComponent as PressureTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/pressure-tomorrowio.svg";
import { ReactComponent as RainAccTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/rain-acc-tomorrowio.svg";
import { ReactComponent as VisibilityTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/visibility-tomorrowio.svg";
import { ReactComponent as CloudCoverTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/cloud-cover-tomorrowio.svg";
import { ReactComponent as CloudBaseTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/cloud-base-tomorrowio.svg";
import { ReactComponent as CloudCeilingTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/cloud-ceiling-tomorrowio.svg";
import _ from "lodash";
import { weatherDataService } from "@iso/services";
import WeatherForecastWrapper from "./WeatherForecast.styles";
import { useSelector } from 'react-redux';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const { Option } = Select;

const WeatherForecast = (props) => {
  const activePropertyId = useSelector((state) => state.property.activePropertyId);

  const [weatherTypes, setWeatherTypes] = useState({
    precipitationIntensity: {
      key: "precipitationIntensity",
      label: "Precip. Intensity",
      value: "",
      unit: "mm/hr",
      icon: PrecipTomorrowio,
    },
    temperature: {
      key: "temperature",
      label: "Temperature",
      value: "",
      unit: "°C",
      icon: TemperatureTomorrowio,
    },
    temperatureApparent: {
      key: "temperatureApparent",
      label: "Feels Like",
      value: "",
      unit: "°C",
      icon: FeelsLikeTomorrowio,
    },
    humidity: {
      key: "humidity",
      label: "Humidity",
      value: "",
      unit: "%",
      icon: HumidityTomorrowio,
    },
    windSpeed: {
      key: "windSpeed",
      label: "Wind Speed",
      value: "",
      unit: "knots",
      icon: WindSpeedTomorrowio,
    },
    windGust: {
      key: "windGust",
      label: "Wind Gust",
      value: "",
      unit: "knots",
      icon: WindGustTomorrowio,
    },
    windDirection: {
      key: "windDirection",
      label: "Wind Direction",
      value: "",
      unit: "",
      icon: WindDirectionTomorrowio,
    },
    pressureSeaLevel: {
      key: "pressureSeaLevel",
      label: "Pressure",
      value: "",
      unit: "",
      icon: PressureTomorrowio,
    },
    rainAccumulation: {
      key: "rainAccumulation",
      label: "Rain Acc",
      value: "",
      unit: "mm",
      icon: RainAccTomorrowio,
    },
    visibility: {
      key: "visibility",
      label: "Visibility",
      value: "",
      unit: "km",
      icon: VisibilityTomorrowio,
    },
    cloudCover: {
      key: "cloudCover",
      label: "Cloud Cover",
      value: "",
      unit: "%",
      icon: CloudCoverTomorrowio,
    },
    cloudBase: {
      key: "cloudBase",
      label: "Cloud Base",
      value: "",
      unit: "ft",
      icon: CloudBaseTomorrowio,
    },
    cloudCeiling: {
      key: "cloudCeiling",
      label: "Cloud Ceiling",
      value: "",
      unit: "ft",
      icon: CloudCeilingTomorrowio,
    },
  });
  const [activeType, setActiveType] = useState("precipitationIntensity");
  const [dataset, setDataset] = useState([]);
  const [selectedDuration, setSelectedDuration] = useState("today");
  const [tomorrowioData, setTomorrowioData] = useState({});
  const [isPropertyTimezone, setIsPropertyTimezone] = useState(true);
  const [timezone, setTimezone] = useState('');
  const renderOptionsWeatherDuration = () => {
    let options = [];
    _.forEach(weatherDurationTomorrowIo, (duration, index) => {
      options.push(
        <Option key={index} value={duration.value} className="property-option">
          {duration.label}
        </Option>
      );
    });

    return (
      <Select
        placeholder="Select Duration"
        size="medium"
        style={{ width: 150 }}
        onChange={(value) => setSelectedDuration(value)}
        defaultValue={selectedDuration}
      >
        {options}
      </Select>
    );
  };

  // const updateWeatherData = () => {
  //   let data = {
  //     duration_type: selectedDuration,
  //     timezone_type: isPropertyTimezone ? 'property' : 'local',
  //     local_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  //   };
  //   weatherDataService.getTomorrowioWeatherData(data, activePropertyId).then((res) => {
  //     let data = res?.weather_data?.data?.timelines;
  //     if (res.code === "0000" && data) {
  //       data = data[0].intervals;
  //       const weatherData = [];
  //       data.forEach((forecastData) => {
  //         _.map(forecastData.values, (value, key) => {
  //           if (weatherData[key]) {
  //             weatherData[key].push({
  //               time: forecastData.startTime,
  //               value: value,
  //             });
  //           } else {
  //             weatherData[key] = [
  //               {
  //                 time: forecastData.startTime,
  //                 value: value,
  //               },
  //             ];
  //           }
  //         });
  //       });
  //       setTomorrowioData(weatherData);
  //       if (weatherData[activeType]) {
  //         setDataset(weatherData[activeType]);
  //       } else {
  //         setDataset([]);
  //       }
  //     } else {
  //       setTomorrowioData([]);
  //     }
  //     setTimezone(res?.timezone ? res.timezone : '');
  //   });
  // };
  const getCurrentWeather = () => {
    let data = {
      duration_type: 'current_time',
      timezone_type: isPropertyTimezone ? 'property' : 'local',
      local_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
    weatherDataService.getTomorrowioWeatherData(data, activePropertyId).then((res) => {
      if (res && res.weather_data && res.weather_data.data && res.weather_data.data.timelines) {
        let newWeatherTypes = { ...weatherTypes };
        let currentWeather = res.weather_data.data.timelines[0].intervals[0].values;
        _.map(currentWeather, (value, key) => {
          if (newWeatherTypes[key]) {
            newWeatherTypes[key].value = value;
          } else {
            newWeatherTypes[key].value = "";
          }
        });
        setWeatherTypes(newWeatherTypes);
      } else {
        // Handle the case where the response structure is not as expected
      }
      setTimezone(res?.timezone ? res.timezone : '');
    });
  };
  
  const updateWeatherData = () => {
    let data = {
      duration_type: selectedDuration,
      timezone_type: isPropertyTimezone ? 'property' : 'local',
      local_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
    weatherDataService.getTomorrowioWeatherData(data, activePropertyId).then((res) => {
      if (res && res.weather_data && res.weather_data.data && res.weather_data.data.timelines) {
        let data = res.weather_data.data.timelines[0].intervals;
        const weatherData = [];
        data.forEach((forecastData) => {
          _.map(forecastData.values, (value, key) => {
            if (weatherData[key]) {
              weatherData[key].push({
                time: forecastData.startTime,
                value: value,
              });
            } else {
              weatherData[key] = [
                {
                  time: forecastData.startTime,
                  value: value,
                },
              ];
            }
          });
        });
        setTomorrowioData(weatherData);
        if (weatherData[activeType]) {
          setDataset(weatherData[activeType]);
        } else {
          setDataset([]);
        }
      } else {
        // Handle the case where the response structure is not as expected
      }
      setTimezone(res?.timezone ? res.timezone : '');
    });
  };
  
  // const getCurrentWeather = () => {
  //   let data = {
  //     duration_type: 'current_time',
  //     timezone_type: isPropertyTimezone ? 'property' : 'local',
  //     local_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  //   };
  //   weatherDataService.getTomorrowioWeatherData(data, activePropertyId).then((res) => {
  //     let data = res?.weather_data?.data?.timelines;
  //     if (res.code === "0000" && data !=='') {
  //       let newWeatherTypes = { ...weatherTypes };
  //       let currentWeather = data[0].intervals[0].values;
  //       _.map(currentWeather, (value, key) => {
  //         if (newWeatherTypes[key]) {
  //           newWeatherTypes[key].value = value;
  //         } else {
  //           newWeatherTypes[key].value = "";
  //         }
  //       });
  //       setWeatherTypes(newWeatherTypes);
  //     }
  //     setTimezone(res?.timezone ? res.timezone : '');
  //   });
  // };

  useEffect(() => {
    if (props.currentPosition) {
      getCurrentWeather();
      updateWeatherData();
    }
  }, [props.currentPosition]);

  useEffect(() => {
    if (props.currentPosition) {
      updateWeatherData();
    }
  }, [selectedDuration]);

  useEffect(() => {
    if (props.currentPosition) {
      getCurrentWeather();
      updateWeatherData();
    }
  }, [isPropertyTimezone]);

  useEffect(() => {
    if (tomorrowioData[activeType]) {
      setDataset(tomorrowioData[activeType]);
    } else {
      setDataset([]);
    }
  }, [activeType]);

  return (
    <WeatherForecastWrapper>
      <div className="weather-statistic">
        <div className="statistic-header">
          <div className="timezone-by">
            <p>Timezone {timezone ? `(${timezone})` : ''} : </p>
            <Switch checkedChildren="Property" unCheckedChildren="Local" checked={isPropertyTimezone} onChange={(value) => setIsPropertyTimezone(value)}/>
          </div>
          {renderOptionsWeatherDuration()}
        </div>
        <div className="weather-types">
          {_.map(weatherTypes, (type) => {
            return (
              <div
                className={`weather-type-element ${
                  activeType === type.key ? "weather-type-active" : ""
                }`}
                onClick={() => setActiveType(type.key)}
              >
                <div className="weather-type-icon">
                  <type.icon
                    width={24}
                    height={24}
                    fill={`${activeType === type.key ? "rgb(248, 14, 70) " : ""}`}
                  ></type.icon>
                </div>
                <div>
                  <p>{type.label}</p>
                  <p>
                    {type.value} <span>{type.unit}</span>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={dataset}
          margin={{
            top: 20,
            right: 30,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          {/* <XAxis dataKey="time"  style={{overflow:'initial'}} /> */}
          <XAxis
          style={{overflow:'initial'}}
  dataKey="time"
  tickFormatter={(time) => {
    if (selectedDuration === 'today' || selectedDuration === 'yesterday' || selectedDuration === 'tomorrow') {
      // Display only the time for "Today," "Yesterday," and "Tomorrow"
      const date = new Date(time);
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else if (selectedDuration === 'this_week' || selectedDuration === 'next_week' || selectedDuration === 'last_week') {
      // Display only the date for "This Week" and "Next Week"
      const date = new Date(time);
      return date.toLocaleDateString();
    } else {
      // Default behavior - display both date and time
      const dateTime = new Date(time);
      return dateTime.toLocaleString();
    }
  }}
  
/>


          <YAxis unit={" " + weatherTypes[activeType]["unit"]} />
          <Tooltip />
          <Legend />
          <Line
            name={weatherTypes[activeType]["label"]}
            type="monotone"
            dataKey="value"
            stroke="rgb(248, 14, 70)"
            activeDot={{ r: 8 }}
          />
        
        </LineChart>
      </ResponsiveContainer>
    </WeatherForecastWrapper>
  );
};

export default WeatherForecast;
