import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Row, Col, Select, DatePicker } from "antd";

import { visitorService } from "../../../services/visitor.service";
import IntlMessages from "@iso/components/utility/intlMessages";
import VisitorModalWrapper from "./VisitorModal.styles";

import _ from "lodash";
import Alert from "@iso/components/Feedback/Alert";

import moment from "moment";
import { number } from "prop-types";

const { Option } = Select;

const VisitorModal = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const currentUser = useSelector((state) => state.user.user);
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    setFields([
      {
        name: "name",
        value: props.editedVisitor.name,
      },
      {
        name: "email",
        value: props.editedVisitor.email,
      },
      {
        name: "birthday",
        value: props.editedVisitor.birthday
          ? moment(props.editedVisitor.birthday)
          : "",
      },
      {
        name: "mobile_number",
        value: props.editedVisitor.mobile_number,
      },
      {
        name: "emergency_contact",
        value: props.editedVisitor.emergency_contact,
      },
      {
        name: "emergency_mobile_number",
        value: props.editedVisitor.emergency_mobile_number,
      },
      {
        name: "property_id",
        value: props.editedVisitor.property_id ? props.editedVisitor.property_id : props.propertyId,
      },
    ]);
  }, [props]);

  const cancelModal = () => {
    props.cancelModal();
    resetForm();
  };

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(async (values) => {
        values.property_id = props.editedVisitor.property_id ? props.editedVisitor.property_id : props.propertyId
        onStore(values);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const onStore = (data) => {
    visitorService.storeOrUpdate(data, props.editedVisitor.id).then(res => {
      if (res.code === '0000') {
        props.updateVisitorData();
        props.cancelModal();
        resetForm();
      }
    });
  };

  const resetForm = () => {
    setTimeout(() => {
      form.resetFields();
      setLoading(false);
      setErrMsg("");
    }, 500);
  };

  return (
    <VisitorModalWrapper
      className='visitor-modal'
      forceRender={true}
      open={props.visibleVisitorModal}
      title={<IntlMessages id="visitorPage.modal.visitorDetail" />}
      onCancel={cancelModal}
      maskClosable={true}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button
          key="submit"
          className="btn-success"
          type="primary"
          loading={loading}
          onClick={handleSubmit}
        >
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="addVisitor"
        layout="vertical"
        scrollToFirstError
        fields={fields}
      >
        {errMsg && (
          <Alert
            message={errMsg}
            type="error"
            style={{ margin: "15px 15px 0 15px" }}
          />
        )}
        <div className="form-body">
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 11 }}>
              <Form.Item
                name={["name"]}
                className="isoInputWrapper"
                // label={<IntlMessages id="formPage.modal.vistor.name" />}
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                  {
                    type: "name",
                    message: "The input is not valid Name!",
                  },
                ]}
              >
                <Input size="large" placeholder="Input your name" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
              <Form.Item
                name={["email"]}
              
                className="isoInputWrapper"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid Email!",
                  },
                  {
                    required: true,
                    message: "Please input your Email!",
                  },
                ]}
              >
                <Input size="large" placeholder="Email" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 11 }}>
              <Form.Item
                name={["birthday"]}
                className="isoInputWrapper"
                
               
              >
                <DatePicker size="large" style={{ width: "100%" }}/>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
              <Form.Item
                name={["emergency_contact"]}
                className="isoInputWrapper"
                rules={[
                  {
                    pattern: /^\d{10,12}$/, // Adjust this pattern as per your phone number format
                    message: 'Phone number must be 10 to 12 digits!',
                  },
                ]}
                
              >
                <Input
              onKeyDown={(e) => {
                // Allow only numbers (0-9) and specific control keys
                if (!/^\d+$/.test(e.key) && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(e.key)) {
                  e.preventDefault();
                }
              }}
                  size="large"
                  placeholder="Input your emergency contact"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 11 }}>
              <Form.Item
                name={["emergency_mobile_number"]}
                className="isoInputWrapper"
                rules={[
                  
                  {
                    pattern: /^\d{10,12}$/, // Adjust this pattern as per your phone number format
                    message: 'Phone number must be 10 to 12 digits!',
                  },
                ]}
               
              >
                <Input
                onKeyDown={(e) => {
                  // Allow only numbers (0-9) and specific control keys
                  if (!/^\d+$/.test(e.key) && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                  size="large"
                  placeholder="Input your emergency mobile number"
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
              <Form.Item
                name={["mobile_number"]}
                className="isoInputWrapper"
                rules={[
                 
                  {
                    pattern: /^\d{10,12}$/, // Adjust this pattern as per your phone number format
                    message: 'Phone number must be 10 to 12 digits!',
                  },
                ]}
                onKeyDown={(e) => {
                  // Allow only numbers (0-9) and specific control keys
                  if (!/^\d+$/.test(e.key) && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
              >
                <Input size="large" placeholder="Input your mobile number" />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </VisitorModalWrapper>
  );
};

export default VisitorModal;
