import React, { useState, useEffect,useRef } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import AssetModalWrapper from './AssetModal.styles';
import { Form, Input, Button, Select, Row, Col, DatePicker, Tabs, Card, Timeline, Popconfirm, InputNumber, Checkbox, TimePicker, Upload } from 'antd';
import { CloseOutlined,UploadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import modalActions from '@iso/redux/modal/actions';
import Loader from '@iso/components/utility/loader';
import { assetService } from '@iso/services';
import moment from 'moment';
import _ from 'lodash';
import styled from "styled-components";
import { dateHelper } from '@iso/lib/helpers/dateHelper';
import L from 'leaflet';
import inside from 'point-in-polygon';
import { assetCategories } from '@iso/constants/assetCategories';
import { inventoryTypes } from '@iso/constants/inventoryTypes';
import { assetStates } from '@iso/constants/assetStates';
import { containerUnits } from '@iso/constants/containerUnits';
import { treatmentRates } from '@iso/constants/treatmentRates';
import { fuelTypes } from '@iso/constants/fuelTypes';
import { fuelContainerUnits } from '@iso/constants/fuelContainerUnits';
import { storageTypes } from '@iso/constants/storageTypes';
import { feedUnits } from '@iso/constants/feedUnits';
import { waterStorageTypes } from '@iso/constants/waterStorageTypes';
import { aircraftTypes } from '@iso/constants/aircraftTypes';
import { aircraftUnits } from '@iso/constants/aircraftUnits';
import { animalTypes } from '@iso/constants/animalTypes';
import { objectColors } from '@iso/constants/objectColors';
import { weatherTypes } from '@iso/constants/weatherTypes';
import { levelTypes } from '@iso/constants/levelTypes';
import { Map, TileLayer, FeatureGroup, Polygon, Tooltip, ZoomControl,Marker,Popup } from 'react-leaflet';
import { propertyService,healthsafety,attachment } from '@iso/services';
import { healthSafetyTypes } from '@iso/constants/healthSafetyTypes';
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'

const { Option } = Select;
const { closeModal } = modalActions;
const { TabPane } = Tabs;

const AssetModal = (props) => {
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const dispatch = useDispatch();
  const modalVisibility = useSelector((state) => state.modal.modalVisibility);
  const modalData = useSelector((state) => state.modal.modalData);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [dataAsset, setDataAsset] = useState({});
  const [currentTab, setCurrentTab] = useState('1');
  const [valueLat, setValuelat] = useState();
  const [valueLng, setValueLng] = useState();
  const mapRef = useRef();
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const [position, setPosition] = useState([null, null]);
  const dateFormat = 'DD/MM/YYYY';
  const [attachmentId, setAttachmentId] = useState([]);
  const [tags, setTags] = useState([]);
  const currentUser = useSelector((state) => state.user.user);

  const uploadProps = {
    name: "Attachment[file]",
    action: apiEndpoint.attachments,
    headers: authHeader(true),
    listType: "picture",
    multiple: true,

    onChange: (response) => {
      if(response.file && response.file.status == "done"){
        // response.file.response.attachment.id
        setAttachmentId(attachmentId => [...attachmentId, response.file.response.attachment.id]);
      }
    },
    onRemove: file => {
      attachment.destroy(file.uid);
    }

  };

  const updatePropertyDetail = React.useCallback((id) => {
    propertyService.viewProperty(id).then(res => {
      if(res.code === '0000'){
        setPosition([res.property.lat, res.property.lng]);
      }
    })
  }, []);
  useEffect(() => {
    if(activePropertyId){
      updatePropertyDetail(activePropertyId);
    }
  },[activePropertyId]); 

   const onChange = (key) => {
  
    setCurrentTab(key);
  };  

  const handleChangeTag = (input_tags) => {
    setTags(input_tags);
  };

  const handleClickMap = (e) => {
     const { lat, lng } = e.latlng;
      form.setFieldsValue({
        latitude: lat,
        longitude: lng,
      });
     setValueLng(lng);
     setValuelat(lat);

  };
  const getRandomLatLng = (coordinates) => {
    var polygon = L.polygon([
      coordinates
    ]);
    var bounds = polygon.getBounds();
    var x_max = bounds.getEast();
    var x_min = bounds.getWest();
    var y_max = bounds.getSouth();
    var y_min = bounds.getNorth();
    var lat=0,lng = 0;
    var count = 0;
    var area = polygon.getLatLngs()[0].map(function(point){
      return [point.lat, point.lng]            
    });

    while(!inside([lat, lng], area)){
      lat = y_min + (Math.random() * (y_max - y_min));
      lng = x_min + (Math.random() * (x_max - x_min));
      count+=1;
      if(count==100){
        break;
      }      
    }            

    return new L.LatLng(
          lat,
          lng
    );
  }

  const getLatLng = (cLat, cLng) => {
    const lat = cLat;
    const lng = cLng;
    return new L.LatLng(
          lat,
          lng
    );
  }

  const cancelModal = () => {
    // dispatch(closeModal());
    // //setActiveTab("1");
    // if(modalData.layer){
    //   modalData.featureGroup.leafletElement.removeLayer(modalData.layer);
    // }
    props.cancelAssetModal();
    setLoading(false);
    form.resetFields();
  }

  useEffect(() => {
    if(props.dataObject){
      setFields([
        {
          name: ['asset', 'primary_object_id'],
          value: props.dataObject ? props.dataObject.id : '',
        },
        {
          name: ['asset', 'last_checked_date'],
          value: moment(),
        },   
        {
          name: ['asset', 'creation_date'],
          value: moment(),
        },        
        {
          name: ['asset', 'expiry_date'],
          value: moment(),
        },     
        {
          name: ['asset', 'created_by'],
          value: currentUser ? currentUser.name : '',
        }
      ])
    }

  }, [props.propertyId, props.visibleAssetModal])

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(values => {
        onStore(values);
        setAttachmentId('');
        setValueLng('');
        setValuelat('');
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
  }

  const onStore = (values) => {
    const selectedObject = _.find(props.primaryObjects, (object) => {
      return object.id === values.asset.primary_object_id;
    });
    var point = null;
   
    

    if(values.asset.category === 'health_and_safety'){
      if(values.asset.type === 'evacuation_point' || values.asset.type === 'fire_extinguisher' || values.asset.type === 'first_aid_kit'){
        if(valueLat !== '' && valueLng !== ''){
          point = getLatLng(valueLat,valueLng);
        }
      }
    }else{
      if(currentTab == 1){
        if(!dataAsset.id || dataAsset.primary_object_id != selectedObject.id){
          point = getRandomLatLng(selectedObject.area);
        }
      }else{
        if(valueLat !== '' && valueLng !== ''){
          point = getLatLng(valueLat,valueLng);
        }
      }
    }



    const data = {
      facility: {
        ...values.asset,
        date_of_purchase: values.asset.date_of_purchase ? dateHelper.dateForAPI(values.asset.date_of_purchase) : '',
        manufacture_date: values.asset.manufacture_date ? dateHelper.dateForAPI(values.asset.manufacture_date) : '',
        last_checked_date: values.asset.last_checked_date ? dateHelper.dateForAPI(values.asset.last_checked_date) : '',
        creation_date: values.asset.creation_date ? dateHelper.dateForAPI(values.asset.creation_date) : '',
        expiry_date: values.asset.expiry_date ? dateHelper.dateForAPI(values.asset.expiry_date) : '',
        date_of_birth: values.asset.date_of_birth ? dateHelper.dateForAPI(values.asset.date_of_birth) : '',
        weather_date: values.asset.weather_date ? dateHelper.dateForAPI(values.asset.weather_date) : '',
        weather_time: values.asset.weather_time ? dateHelper.getTime(values.asset.weather_time) : '',
        chemical_last_used: values.asset.chemical_last_used ? dateHelper.dateForAPI(values.asset.chemical_last_used) : '',
        sire: values.asset.sire ? 1 : 0,
        point,
        tags:tags,
        attachment_id : attachmentId,
        property_id: props.propertyId
      }
    }

    assetService.storeOrUpdate(data, dataAsset.id).then(res => {
      if(res.code === '0000'){
        props.onSaved(res.facility);
        props.updatePropertyDetail(props.propertyId);
        form.resetFields();
        cancelModal();
        setLoading();
      } else {
        setLoading(false);
      }
    });
  }

  const renderOptionsAssetCategory = () => {
    let options = [];
    _.forEach(assetCategories, (cat, index) => {
      options.push(
        <Option key={index} value={cat.value}>
          <div>{cat.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a category"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }
  const renderOptionsLevelTypes = () => {
    let options = [];
    _.forEach(levelTypes, (type, index) => {
      options.push(
        <Option key={index} value={type.value}>
          <div>{type.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a level"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  } 

  const renderOptionsInventoryType = () => {
    let options = [];
    _.forEach(inventoryTypes, (type, index) => {
      options.push(
        <Option key={index} value={type.value}>
          <div>{type.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a inventory type"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const renderOptionsAssetState = () => {
    let options = [];
    _.forEach(assetStates, (state, index) => {
      options.push(
        <Option key={index} value={state.value}>
          <ColorItemRow>
            <ColorItemPreview style={{backgroundColor: state.color}}></ColorItemPreview>
            <div>{state.label}</div>
          </ColorItemRow>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a state"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const renderOptionsContainerUnit = () => {
    let options = [];
    _.forEach(containerUnits, (unit, index) => {
      options.push(
        <Option key={index} value={unit.value}>
          <div>{unit.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a container unit"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const renderOptionsTreatmentRate = () => {
    let options = [];
    _.forEach(treatmentRates, (rate, index) => {
      options.push(
        <Option key={index} value={rate.value}>
          <div>{rate.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a rate"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const renderOptionsFuelType = () => {
    let options = [];
    _.forEach(fuelTypes, (fuel, index) => {
      options.push(
        <Option key={index} value={fuel.value}>
          <div>{fuel.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a fuel type"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  } 

  const renderOptionsFuelContainerUnit = () => {
    let options = [];
    _.forEach(fuelContainerUnits, (fuel_unit, index) => {
      options.push(
        <Option key={index} value={fuel_unit.value}>
          <div>{fuel_unit.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a fuel unit"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  } 

  const renderOptionsStorageTypes = () => {
    let options = [];
    _.forEach(storageTypes, (storage, index) => {
      options.push(
        <Option key={index} value={storage.value}>
          <div>{storage.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a storage type"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }
  const renderOptionsFeedUnits = () => {
    let options = [];
    _.forEach(feedUnits, (unit, index) => {
      options.push(
        <Option key={index} value={unit.value}>
          <div>{unit.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a feed unit"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  } 

  const renderOptionsWaterStorageTypes = () => {
    let options = [];
    _.forEach(waterStorageTypes, (water_type, index) => {
      options.push(
        <Option key={index} value={water_type.value}>
          <div>{water_type.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a water storage type"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const renderOptionsAircraftType = () => {
    let options = [];
    _.forEach(aircraftTypes, (aircraft_type, index) => {
      options.push(
        <Option key={index} value={aircraft_type.value}>
          <div>{aircraft_type.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a aircraft type"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }
  const renderOptionsAircraftUnit = () => {
    let options = [];
    _.forEach(aircraftUnits, (aircraft_unit, index) => {
      options.push(
        <Option key={index} value={aircraft_unit.value}>
          <div>{aircraft_unit.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a aircraft unit"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const renderOptionObject = (objects) => {
    let options = [];
    _.forEach(objects, (object, index) => {
      options.push(
        <Option key={object.id} value={object.id}>
          {object.name}
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select an object"
        allowClear
        size="large"
        disabled={props.dataObject ? true : false}
      >
        {options}
      </Select>
    );
  }

  const renderOptionAnimalType = () => {
    let options = [];
    _.forEach(animalTypes, (type, index) => {
      options.push(
        <Option key={index} value={type.value}>
          {type.label}
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select an animal type"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const renderOptionsColor = () => {
    let options = [];
    _.forEach(objectColors, (color, index) => {
      options.push(
        <Option key={index} value={color.value}>
          <ColorItemRow>
            <ColorItemPreview style={{backgroundColor: color.color}}></ColorItemPreview>
            <div>{color.label}</div>
          </ColorItemRow>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a color"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const renderOptionsWeatherTypes = () => {
    let options = [];
    _.forEach(weatherTypes, (type, index) => {
      options.push(
        <Option key={index} value={type.value}>
          <div>{type.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a weather type"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  } 

  const renderOptionObjectLat = (objects) => {
    let options = [];
    _.forEach(objects, (object, index) => {
      options.push(
        <Option key={object.id} value={object[0]}>
          {object[0]}
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select an object"
        allowClear
        size="large"
        disabled={true}
      >
        {options}
      </Select>
    );
  } 
  const renderOptionObjectLng = (objects) => {
    let options = [];
    _.forEach(objects, (object, index) => {
      options.push(
        <Option key={object.id} value={object[1]}>
          {object[1]}
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select an object"
        allowClear
        size="large"
        disabled={true}
      >
        {options}
      </Select>
    );
  }
   const renderOptionsHealthSafetyTypes = () => {
    let options = [];
    _.forEach(healthSafetyTypes, (type, index) => {
      options.push(
        <Option key={index} value={type.value}>
          <div>{type.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a Health Safety Types"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  return(
    <>
    <AssetModalWrapper
      visible={props.visibleAssetModal}
      onCancel={cancelModal}
      maskClosable={true}
      title={dataAsset.id ? <IntlMessages id="propertyPage.modal.asset.editAsset"/> : <IntlMessages id="propertyPage.modal.asset.addAsset"/>}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit} loading={loading}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
    {
        modalLoading ?
        <Loader /> : 
          <Form
            form={form}
            layout="vertical"
            scrollToFirstError
            fields={fields}
            id="#1"
          >
          <div className="form-body">
            <Form.Item
              name={['asset', 'title']}
              className="isoInputWrapper"
              label="Title"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                  whitespace: true,
                },
                {
                  max:255,
                  message: 'Title must be maximum 255 characters.'
                },
              ]}
            >
              <Input size="large" placeholder="Input asset title"/>
            </Form.Item>
            <Form.Item
              name={['asset', 'category']}
              className="isoInputWrapper"
              label="Category"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              {renderOptionsAssetCategory()}
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return (prevValues['asset']);
              }}
            >
              {({ getFieldValue }) => {
                  if(getFieldValue('asset')){
                    if (getFieldValue('asset').category === "inventory"){
                      return (
                        <div>
                          <Form.Item
                            name={['asset', 'type']}
                            className="isoInputWrapper"
                            label="Inventory Type"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            {renderOptionsInventoryType()}
                          </Form.Item>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => {
                              return (prevValues['asset'] && (prevValues['asset'].type !== currentValues['asset'].type));
                            }}
                          >
                            {({ getFieldValue }) => {
                                if(getFieldValue('asset')){
                                  if (getFieldValue('asset').type === "other"){
                                    return (
                                      <Form.Item
                                        name={['asset', 'other_inventory_type']}
                                        className="isoInputWrapper"
                                        label="Other Inventory Type"
                                        rules={[
                                          {
                                            required: true,
                                            message: 'This field is required',
                                            whitespace: true
                                          },
                                          {
                                            max:255,
                                            message: 'Other Inventory Type must be maximum 255 characters.'
                                          },
                                        ]}
                                      >
                                        <Input size="large" placeholder="Input other inventory type"/>
                                      </Form.Item>
                                    );
                                  }
                                  else if(getFieldValue('asset').type === "animal_treatment" || getFieldValue('asset').type === "fertiliser" 
                                    || getFieldValue('asset').type === "vaccine"){
                                    return (
                                      <div>
                                        <Row>
                                          <Col xs={{span: 24}} md={{span: 8}}>
                                            <Form.Item
                                              name={['asset', 'container_unit']}
                                              className="isoInputWrapper"
                                              label="Container Unit"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'This field is required',
                                                },
                                              ]}
                                            >
                                              {renderOptionsContainerUnit()}
                                            </Form.Item>
                                          </Col>
                                          <Col xs={{span: 24}} md={{span: 7, offset: 1}}>
                                            <Form.Item
                                              name={['asset', 'container_size']}
                                              className="isoInputWrapper"
                                              label="Container Size"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'This field is required',
                                                },
                                              ]}
                                            >
                                              <InputNumber style={{ width: '100%' }} size="large" min={1} max={1000} />
                                            </Form.Item>
                                          </Col>
                                          <Col xs={{span: 24}} md={{span: 7, offset: 1}}>
                                            <Form.Item
                                              name={['asset', 'containers']}
                                              className="isoInputWrapper"
                                              label="Containers"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'This field is required',
                                                },
                                              ]}
                                            >
                                              <InputNumber style={{ width: '100%' }} size="large" min={1} max={1000} />
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col xs={{span: 24}} md={{span: 8}}>
                                            <Form.Item
                                              name={['asset', 'remaining']}
                                              className="isoInputWrapper"
                                              label="Remaining"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'This field is required',
                                                },
                                              ]}
                                            >
                                              <InputNumber style={{ width: '100%' }} size="large" min={1} max={1000} />
                                            </Form.Item>
                                          </Col>
                                          <Col xs={{span: 24}} md={{span: 7, offset: 1}}>
                                            <Form.Item
                                              name={['asset', 'withholding']}
                                              className="isoInputWrapper"
                                              label="Withholding"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'This field is required',
                                                },
                                              ]}
                                            >
                                              <InputNumber style={{ width: '100%' }} size="large" min={1} max={1000} />
                                            </Form.Item>
                                          </Col>
                                          <Col xs={{span: 24}} md={{span: 7, offset: 1}}>
                                            <Form.Item
                                              name={['asset', 'ESI']}
                                              className="isoInputWrapper"
                                              label="ESI"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'This field is required',
                                                },
                                              ]}
                                            >
                                              <InputNumber style={{ width: '100%' }} size="large" min={1} max={1000} />
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col xs={{span: 24}} md={{span: 11}}>
                                            <Form.Item
                                              name={['asset', 'dosage']}
                                              className="isoInputWrapper"
                                              label="Dosage"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'This field is required',
                                                },
                                              ]}
                                            >
                                              <InputNumber style={{ width: '100%' }} size="large" min={0} max={1000} />
                                            </Form.Item>
                                          </Col>
                                          <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                                            <Form.Item
                                              name={['asset', 'rate']}
                                              className="isoInputWrapper"
                                              label="Rate"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'This field is required',
                                                },
                                              ]}
                                            >
                                              {renderOptionsTreatmentRate()}
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                        <Form.Item
                                          noStyle
                                          shouldUpdate={(prevValues, currentValues) => {
                                            return (prevValues['asset'] && (prevValues['asset'].rate !== currentValues['asset'].rate));
                                          }}
                                        >
                                          {({ getFieldValue }) => {
                                              if(getFieldValue('asset')){
                                                if (getFieldValue('asset').rate === "other"){
                                                  return (
                                                    <Form.Item
                                                      name={['asset', 'other_rate']}
                                                      className="isoInputWrapper"
                                                      label="Other Rate"
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message: 'This field is required',
                                                          whitespace: true
                                                        },
                                                        {
                                                          max:255,
                                                          message: 'Other Rate must be maximum 255 characters.'
                                                        },
                                                      ]}
                                                    >
                                                      <Input size="large" placeholder="Input other rate"/>
                                                    </Form.Item>
                                                  );
                                                }
                                              }
                                            }
                                          }
                                        </Form.Item>
                                        <Form.Item
                                          name={['asset', 'expiry_date']}
                                          className="isoInputWrapper"
                                          label="Expiry Date"
                                          rules={[
                                            {
                                              required: true,
                                              message: 'This field is required',
                                            },
                                          ]}
                                        >
                                          <DatePicker size="large" style={{width: '100%'}} format={dateFormat} />
                                        </Form.Item>
                                      </div>
                                    );
                                  }
                                  else if (getFieldValue('asset').type === "fuel" || getFieldValue('asset').type === "chemical"){
                                    return (
                                      <div>
                                      <Row>
                                        <Col xs={{span: 24}} md={{span: getFieldValue('asset').type === 'fuel' ? 11 : 8}}>
                                        {getFieldValue('asset').type === 'fuel' ? 
                                          <Form.Item
                                            name={['asset', 'fuel_type']}
                                            className="isoInputWrapper"
                                            label="Fuel Type"
                                            rules={[
                                              {
                                                required: true,
                                                message: 'This field is required',
                                              },
                                            ]}
                                          >
                                            {renderOptionsFuelType()}
                                          </Form.Item> :
                                          <Form.Item
                                              name={['asset', 'chemical_batch_number']}
                                              className="isoInputWrapper"
                                              label="Batch Number"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'This field is required',
                                                },
                                              ]}
                                            >
                                              <InputNumber style={{ width: '100%' }} size="large" min={0} max={1000} />
                                          </Form.Item>
                                        }
                                        </Col>
                                        <Col xs={{span: 24}} md={{span: getFieldValue('asset').type === 'fuel' ? 11 : 7, offset: getFieldValue('asset').type === 'fuel' ? 2 : 1}}>
                                          <Form.Item
                                            name={['asset', 'container_unit']}
                                            className="isoInputWrapper"
                                            label="Container Unit"
                                            rules={[
                                              {
                                                required: true,
                                                message: 'This field is required',
                                              },
                                            ]}
                                          >
                                            {renderOptionsFuelContainerUnit()}
                                          </Form.Item>
                                        </Col>
                                        {getFieldValue('asset').type === 'chemical' ? 
                                        <Col xs={{span: 24}} md={{span: 7, offset: 1}}>
                                          <Form.Item
                                            name={['asset', 'containers']}
                                            className="isoInputWrapper"
                                            label="Containers"
                                            rules={[
                                              {
                                                required: true,
                                                message: 'This field is required',
                                              },
                                            ]}
                                          >
                                            <InputNumber style={{ width: '100%' }} size="large" min={1} max={1000} />
                                          </Form.Item>
                                        </Col> : ''}
                                      </Row>
                                      <Row>
                                        <Col xs={{span: 24}} md={{span: 11}}>
                                          <Form.Item
                                              noStyle
                                              shouldUpdate={(prevValues, currentValues) => {
                                                return (prevValues['asset'] && (prevValues['asset'].fuel_type !== currentValues['asset'].fuel_type));
                                              }}
                                            >
                                              {({ getFieldValue }) => {
                                                  if(getFieldValue('asset')){
                                                    if (getFieldValue('asset').fuel_type === "other"){
                                                      return (
                                                        <Form.Item
                                                          name={['asset', 'other_fuel_type']}
                                                          className="isoInputWrapper"
                                                          label="Other Fuel Type"
                                                          rules={[
                                                            {
                                                              required: true,
                                                              message: 'This field is required',
                                                              whitespace: true
                                                            },
                                                            {
                                                              max:255,
                                                              message: 'Other Fuel Type must be maximum 255 characters.'
                                                            },
                                                          ]}
                                                        >
                                                          <Input size="large" placeholder="Input other fuel type"/>
                                                        </Form.Item>
                                                      );
                                                    }
                                                  }
                                                }
                                              }
                                          </Form.Item>
                                        </Col>
                                        <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                                          <Form.Item
                                            noStyle
                                            shouldUpdate={(prevValues, currentValues) => {
                                              return (prevValues['asset'] && (prevValues['asset'].container_unit !== currentValues['asset'].container_unit));
                                            }}
                                          >
                                            {({ getFieldValue }) => {
                                                if(getFieldValue('asset')){
                                                  if (getFieldValue('asset').container_unit === "other"){
                                                    return (
                                                      <Form.Item
                                                        name={['asset', 'other_container_unit']}
                                                        className="isoInputWrapper"
                                                        label="Other Container Unit"
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message: 'This field is required',
                                                            whitespace: true
                                                          },
                                                          {
                                                            max:255,
                                                            message: 'Other Container Unit Type must be maximum 255 characters.'
                                                          },
                                                        ]}
                                                      >
                                                        <Input size="large" placeholder="Input other container unit"/>
                                                      </Form.Item>
                                                    );
                                                  }
                                                }
                                              }
                                            }
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                      <Row>
                                          <Col xs={{span: 24}} md={{span: 8}}>
                                            <Form.Item
                                              name={['asset', 'container_size']}
                                              className="isoInputWrapper"
                                              label="Container Size"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'This field is required',
                                                },
                                              ]}
                                            >
                                              <InputNumber style={{ width: '100%' }} size="large" min={1} max={1000} />
                                            </Form.Item>
                                          </Col>
                                          <Col xs={{span: 24}} md={{span: 7, offset: 1}}>
                                            <Form.Item
                                              name={['asset', 'remaining']}
                                              className="isoInputWrapper"
                                              label="Remaining"
                                              rules={[
                                                {
                                                  required: getFieldValue('asset').type === 'fuel' ? true : false,
                                                  message: 'This field is required',
                                                },
                                              ]}
                                            >
                                              <InputNumber style={{ width: '100%' }} size="large" min={1} max={1000} />
                                            </Form.Item>
                                          </Col>
                                          <Col xs={{span: 24}} md={{span: 7, offset: 1}}>
                                            <Form.Item
                                              name={['asset', 'expiry_date']}
                                              className="isoInputWrapper"
                                              label="Expiry Date"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'This field is required',
                                                },
                                              ]}
                                            >
                                              <DatePicker size="large" style={{width: '100%'}} format={dateFormat}/>
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                        {getFieldValue('asset').type === 'chemical' ? 
                                        <div><Row>
                                          <Col xs={{span: 24}} md={{span: 11}}>
                                            <Form.Item
                                              name={['asset', 'chemical_last_used']}
                                              className="isoInputWrapper"
                                              label="Last Used"
                                            >
                                              <DatePicker size="large" style={{width: '100%'}} format={dateFormat}/>
                                            </Form.Item>
                                          </Col>
                                          <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                                            <Form.Item
                                              name={['asset', 'rate']}
                                              className="isoInputWrapper"
                                              label="Usage Rate"
                                            >
                                              {renderOptionsTreatmentRate()}
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                        <Form.Item
                                          noStyle
                                          shouldUpdate={(prevValues, currentValues) => {
                                            return (prevValues['asset'] && (prevValues['asset'].rate !== currentValues['asset'].rate));
                                          }}
                                        >
                                          {({ getFieldValue }) => {
                                              if(getFieldValue('asset')){
                                                if (getFieldValue('asset').rate === "other"){
                                                  return (
                                                    <Form.Item
                                                      name={['asset', 'other_rate']}
                                                      className="isoInputWrapper"
                                                      label="Other Rate"
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message: 'This field is required',
                                                          whitespace: true
                                                        },
                                                        {
                                                          max:255,
                                                          message: 'Other Rate must be maximum 255 characters.'
                                                        },
                                                      ]}
                                                    >
                                                      <Input size="large" placeholder="Input other rate"/>
                                                    </Form.Item>
                                                  );
                                                }
                                              }
                                            }
                                          }
                                        </Form.Item></div> : ''}
                                      </div>
                                    );
                                  }
                                  else if (getFieldValue('asset').type === "stock_feed")
                                  {
                                    return (
                                      <div>
                                        <Form.Item
                                          name={['asset', 'feed_type']}
                                          className="isoInputWrapper"
                                          label="Feed Type"
                                          rules={[
                                            {
                                              required: true,
                                              message: 'This field is required',
                                              whitespace: true,
                                            },
                                            {
                                              max:255,
                                              message: 'Feed Type must be maximum 255 characters.'
                                            },
                                          ]}
                                        >
                                          <Input size="large" placeholder="Input feed type"/>
                                        </Form.Item>
                                        <Row>
                                          <Col xs={{span: 24}} md={{span: 11}}>
                                            <Form.Item
                                              name={['asset', 'storage_type']}
                                              className="isoInputWrapper"
                                              label="Storage Type"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'This field is required',
                                                },
                                              ]}
                                            >
                                              {renderOptionsStorageTypes()}
                                            </Form.Item>
                                          </Col>
                                          <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                                            <Form.Item
                                              name={['asset', 'storage_location']}
                                              className="isoInputWrapper"
                                              label="Storage Location"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'This field is required',
                                                },
                                              ]}
                                            >
                                              {renderOptionObject(props.primaryObjects)}
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                        <Form.Item
                                            noStyle
                                            shouldUpdate={(prevValues, currentValues) => {
                                              return (prevValues['asset'] && (prevValues['asset'].storage_type !== currentValues['asset'].storage_type));
                                            }}
                                          >
                                            {({ getFieldValue }) => {
                                                if(getFieldValue('asset')){
                                                  if (getFieldValue('asset').storage_type === "other"){
                                                    return (
                                                      <Form.Item
                                                        name={['asset', 'other_storage_type']}
                                                        className="isoInputWrapper"
                                                        label="Other Storage Type"
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message: 'This field is required',
                                                            whitespace: true
                                                          },
                                                          {
                                                            max:255,
                                                            message: 'Other Storage Type must be maximum 255 characters.'
                                                          },
                                                        ]}
                                                      >
                                                        <Input size="large" placeholder="Input other storage type"/>
                                                      </Form.Item>
                                                    );
                                                  }
                                                }
                                              }
                                            }
                                          </Form.Item>
                                          <Row>
                                          <Col xs={{span: 24}} md={{span: 11}}>
                                            <Form.Item
                                              name={['asset', 'feed_unit']}
                                              className="isoInputWrapper"
                                              label="Feed Unit"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'This field is required',
                                                },
                                              ]}
                                            >
                                              {renderOptionsFeedUnits()}
                                            </Form.Item>
                                          </Col>
                                          <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                                            <Form.Item
                                              name={['asset', 'volume']}
                                              className="isoInputWrapper"
                                              label="Volume"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'This field is required',
                                                },
                                              ]}
                                            >
                                              <InputNumber style={{ width: '100%' }} size="large" min={1} max={1000} />
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                        <Form.Item
                                            noStyle
                                            shouldUpdate={(prevValues, currentValues) => {
                                              return (prevValues['asset'] && (prevValues['asset'].feed_unit !== currentValues['asset'].feed_unit));
                                            }}
                                          >
                                            {({ getFieldValue }) => {
                                                if(getFieldValue('asset')){
                                                  if (getFieldValue('asset').feed_unit === "other"){
                                                    return (
                                                      <Form.Item
                                                        name={['asset', 'other_feed_unit']}
                                                        className="isoInputWrapper"
                                                        label="Other Feed Unit"
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message: 'This field is required',
                                                            whitespace: true
                                                          },
                                                          {
                                                            max:255,
                                                            message: 'Other Feed Unit must be maximum 255 characters.'
                                                          },
                                                        ]}
                                                      >
                                                        <Input size="large" placeholder="Input other feed unit"/>
                                                      </Form.Item>
                                                    );
                                                  }else if (getFieldValue('asset').feed_unit === "bale"){
                                                    return (
                                                      <Form.Item
                                                        name={['asset', 'bale_weight']}
                                                        className="isoInputWrapper"
                                                        label="Bale Weight"
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message: 'This field is required',
                                                          },
                                                        ]}
                                                      >
                                                        <InputNumber style={{ width: '100%' }} size="large" min={1} max={1000} />
                                                      </Form.Item>
                                                    );
                                                  }
                                                }
                                              }
                                            }
                                          </Form.Item>
                                          <Form.Item
                                            name={['asset', 'current_volume']}
                                            className="isoInputWrapper"
                                            label="Current Volume"
                                            rules={[
                                              {
                                                required: true,
                                                message: 'This field is required',
                                              },
                                            ]}
                                          >
                                            <InputNumber style={{ width: '100%' }} size="large" min={1} max={1000} />
                                          </Form.Item>
                                      </div>
                                    );
                                  }
                                  else if (getFieldValue('asset').type === "water")
                                  {
                                    return (
                                      <div>
                                        <Row>
                                          <Col xs={{span: 24}} md={{span: 11}}>
                                            <Form.Item
                                              name={['asset', 'storage_type']}
                                              className="isoInputWrapper"
                                              label="Storage Type"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'This field is required',
                                                },
                                              ]}
                                            >
                                              {renderOptionsWaterStorageTypes()}
                                            </Form.Item>
                                          </Col>
                                          <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                                            <Form.Item
                                              name={['asset', 'storage_location']}
                                              className="isoInputWrapper"
                                              label="Storage Location"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'This field is required',
                                                },
                                              ]}
                                            >
                                              {renderOptionObject(props.primaryObjects)}
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                        <Form.Item
                                            noStyle
                                            shouldUpdate={(prevValues, currentValues) => {
                                              return (prevValues['asset'] && (prevValues['asset'].storage_type !== currentValues['asset'].storage_type));
                                            }}
                                          >
                                            {({ getFieldValue }) => {
                                                if(getFieldValue('asset')){
                                                  if (getFieldValue('asset').storage_type === "other"){
                                                    return (
                                                      <Form.Item
                                                        name={['asset', 'other_storage_type']}
                                                        className="isoInputWrapper"
                                                        label="Other Storage Type"
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message: 'This field is required',
                                                            whitespace: true
                                                          },
                                                          {
                                                            max:255,
                                                            message: 'Other Storage Type must be maximum 255 characters.'
                                                          },
                                                        ]}
                                                      >
                                                        <Input size="large" placeholder="Input other storage type"/>
                                                      </Form.Item>
                                                    );
                                                  }
                                                }
                                              }
                                            }
                                          </Form.Item>
                                          <Row>
                                          <Col xs={{span: 24}} md={{span: 11}}>
                                            <Form.Item
                                              name={['asset', 'volume']}
                                              className="isoInputWrapper"
                                              label="Volume"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'This field is required',
                                                },
                                              ]}
                                            >
                                              <InputNumber style={{ width: '100%' }} size="large" min={1} max={1000} />
                                            </Form.Item>
                                          </Col>
                                          <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                                            <Form.Item
                                            name={['asset', 'current_volume']}
                                            className="isoInputWrapper"
                                            label="Current Volume"
                                            rules={[
                                              {
                                                required: true,
                                                message: 'This field is required',
                                              },
                                            ]}
                                            >
                                              <InputNumber style={{ width: '100%' }} size="large" min={1} max={1000} />
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                      </div>
                                    );
                                  }
                                  else if(getFieldValue('asset').type === "aircraft")
                                  {
                                    return (
                                      <div>
                                        <Row>
                                          <Col xs={{span: 24}} md={{span: 11}}>
                                            <Form.Item
                                              name={['asset', 'aircraft_type']}
                                              className="isoInputWrapper"
                                              label="Aircraft Type"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'This field is required',
                                                },
                                              ]}
                                            >
                                              {renderOptionsAircraftType()}
                                            </Form.Item>
                                          </Col>
                                          <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                                            <Form.Item
                                              name={['asset', 'storage_location']}
                                              className="isoInputWrapper"
                                              label="Storage Location"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'This field is required',
                                                },
                                              ]}
                                            >
                                              {renderOptionObject(props.primaryObjects)}
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                        <Form.Item
                                            noStyle
                                            shouldUpdate={(prevValues, currentValues) => {
                                              return (prevValues['asset'] && (prevValues['asset'].aircraft_type !== currentValues['asset'].aircraft_type));
                                            }}
                                          >
                                            {({ getFieldValue }) => {
                                                if(getFieldValue('asset')){
                                                  if (getFieldValue('asset').aircraft_type === "other"){
                                                    return (
                                                      <Form.Item
                                                        name={['asset', 'other_aircraft_type']}
                                                        className="isoInputWrapper"
                                                        label="Other Aircraft Type"
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message: 'This field is required',
                                                            whitespace: true
                                                          },
                                                          {
                                                            max:255,
                                                            message: 'Other Aircraft Type must be maximum 255 characters.'
                                                          },
                                                        ]}
                                                      >
                                                        <Input size="large" placeholder="Input other aircraft type"/>
                                                      </Form.Item>
                                                    );
                                                  }
                                                }
                                              }
                                            }
                                        </Form.Item>
                                        <Row>
                                          <Col xs={{span: 24}} md={{span: 11}}>
                                            <Form.Item
                                              name={['asset', 'aircraft_model']}
                                              className="isoInputWrapper"
                                              label="Aircraft Model"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'This field is required',
                                                },
                                              ]}
                                            >
                                              <InputNumber style={{ width: '100%' }} size="large" min={1} max={1000} />
                                            </Form.Item>
                                          </Col>
                                          <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                                            <Form.Item
                                              name={['asset', 'container_unit']}
                                              className="isoInputWrapper"
                                              label="Unit of measurement"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'This field is required',
                                                },
                                              ]}
                                            >
                                              {renderOptionsAircraftUnit()}
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                        <Form.Item
                                            noStyle
                                            shouldUpdate={(prevValues, currentValues) => {
                                              return (prevValues['asset'] && (prevValues['asset'].container_unit !== currentValues['asset'].container_unit));
                                            }}
                                          >
                                            {({ getFieldValue }) => {
                                                if(getFieldValue('asset')){
                                                  if (getFieldValue('asset').container_unit === "other"){
                                                    return (
                                                      <Form.Item
                                                        name={['asset', 'other_container_unit']}
                                                        className="isoInputWrapper"
                                                        label="Other Unit Of Measurement"
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message: 'This field is required',
                                                            whitespace: true
                                                          },
                                                          {
                                                            max:255,
                                                            message: 'Other Unit Of Measurement must be maximum 255 characters.'
                                                          },
                                                        ]}
                                                      >
                                                        <Input size="large" placeholder="Input other unit of measurement"/>
                                                      </Form.Item>
                                                    );
                                                  }
                                                }
                                              }
                                            }
                                        </Form.Item>
                                        <Row>
                                          <Col xs={{span: 24}} md={{span: 11}}>
                                            <Form.Item
                                              name={['asset', 'current_fuel_level']}
                                              className="isoInputWrapper"
                                              label="Current Fuel Level"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'This field is required',
                                                },
                                              ]}
                                            >
                                              <InputNumber style={{ width: '100%' }} size="large" min={1} max={1000} />
                                            </Form.Item>
                                          </Col>
                                          <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                                            <Form.Item
                                              name={['asset', 'volume']}
                                              className="isoInputWrapper"
                                              label="Fuel Capacity"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'This field is required',
                                                },
                                              ]}
                                            >
                                              <InputNumber style={{ width: '100%' }} size="large" min={1} max={1000} />
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                      </div>
                                    );
                                  }
                                }
                              }
                            }
                          </Form.Item>
                        </div>
                      );
                    }else if (getFieldValue('asset').category === "other"){
                      return (
                        <div>
                          <Form.Item
                            name={['asset', 'other_category']}
                            className="isoInputWrapper"
                            label="Other Category"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                                whitespace: true
                              },
                              {
                                max:255,
                                message: 'Other Category must be maximum 255 characters.'
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Input other category"/>
                          </Form.Item>
                        </div>
                      );
                    }else if (getFieldValue('asset').category === 'animal'){
                      return (
                        <div>
                          <Form.Item
                            name={['asset', 'animal_name']}
                            className="isoInputWrapper"
                            label={<IntlMessages id="propertyPage.modal.asset.animalName" />}
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                                whitespace: true
                              },
                              {
                                max:255,
                                message: 'Name must be maximum 255 characters.'
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Input animal name"/>
                          </Form.Item>
                          <Row>
                            <Col xs={{span: 24}} md={{span: 11}}>
                              <Form.Item
                                name={['asset', 'animal_type']}
                                className="isoInputWrapper"
                                label={<IntlMessages id="propertyPage.modal.asset.animalType" />}
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                                {renderOptionAnimalType()}
                              </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                              <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, currentValues) => {
                                  return (prevValues['asset'] && (prevValues['asset'].animal_type !== currentValues['asset'].animal_type));
                                }}
                              >
                                {({ getFieldValue }) => {
                                    if(getFieldValue('asset')){
                                      if (getFieldValue('asset').animal_type === "other"){
                                        return (
                                          <Form.Item
                                            name={['asset', 'other_animal_type']}
                                            className="isoInputWrapper"
                                            label={<IntlMessages id="propertyPage.modal.asset.otherAnimalType" />}
                                            rules={[
                                              {
                                                required: true,
                                                message: 'This field is required',
                                                whitespace: true
                                              },
                                              {
                                                max:255,
                                                message: 'Other Animal Type must be maximum 255 characters.'
                                              },
                                            ]}
                                          >
                                            <Input size="large" placeholder="Input other animal type"/>
                                          </Form.Item>
                                        );
                                      }
                                    }
                                  }
                                }
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={{span: 24}} md={{span: 11}}>
                              <Form.Item
                                name={['asset', 'sire']}
                                className="isoInputWrapper"
                                valuePropName="checked"
                                label={<IntlMessages id="propertyPage.modal.asset.sire" />}
                              >
                                <Checkbox className="checkbox-contact"></Checkbox>
                              </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                              <Form.Item
                                name={['asset', 'date_of_birth']}
                                className="isoInputWrapper"
                                label={<IntlMessages id="propertyPage.modal.asset.dateOfBirth" />}
                              >
                                <DatePicker size="large" style={{width: '100%'}} format={dateFormat}/>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={{span: 24}} md={{span: 11}}>
                              <Form.Item
                                name={['asset', 'tag_number']}
                                className="isoInputWrapper"
                                label={<IntlMessages id="propertyPage.modal.asset.tagNumber" />}
                              >
                                 <Input 
                                  onKeyDown={(e) => {
                                    // Allow only numbers (0-9) and specific control keys
                                    if (!/^\d+$/.test(e.key) && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                 size="large" placeholder="Input tag number"/>
                              </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                              <Form.Item
                                name={['asset', 'tag_colour']}
                                className="isoInputWrapper"
                                label={<IntlMessages id="propertyPage.modal.asset.tagColour" />}
                              >
                                {renderOptionsColor()}
                              </Form.Item>
                            </Col>
                          </Row>
                          <Form.Item
                            name={['asset', 'primary_object_id']}
                            className="isoInputWrapper"
                            label={<IntlMessages id="propertyPage.modal.asset.paddock" />}
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            {renderOptionObject(props.primaryObjects)}
                          </Form.Item>
                          <Form.Item
                            name={['asset', 'unique_features']}
                            className="isoInputWrapper"
                            label={<IntlMessages id="propertyPage.modal.asset.uniqueFeature" />}
                          >
                            <Input.TextArea />
                          </Form.Item>
                        </div>
                      )
                    }else if (getFieldValue('asset').category === 'weather'){
                      return (
                        <div>
                          <Row>
                            <Col xs={{span: 24}} md={{span: 11}}>
                              <Form.Item
                                name={['asset', 'weather_type']}
                                className="isoInputWrapper"
                                label={<IntlMessages id="propertyPage.modal.asset.weatherType" />}
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                                {renderOptionsWeatherTypes()}
                              </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                                <Form.Item
                                  name={['asset', 'primary_object_id']}
                                  className="isoInputWrapper"
                                  label={<IntlMessages id="propertyPage.modal.asset.paddock" />}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'This field is required',
                                    },
                                  ]}
                                >
                                  {renderOptionObject(props.primaryObjects)}
                          </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={{span: 24}} md={{span: 11}}>
                              <Form.Item
                                name={['asset', 'weather_date']}
                                className="isoInputWrapper"
                                label={<IntlMessages id="propertyPage.modal.asset.date" />}
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                                <DatePicker size="large" style={{width: '100%'}} format={dateFormat}/>
                              </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                              <Form.Item
                                name={['asset', 'weather_time']}
                                className="isoInputWrapper"
                                label={<IntlMessages id="propertyPage.modal.asset.time" />}
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                                <TimePicker size="large" style={{width: '100%'}} />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Form.Item
                            name={['asset', 'weather_comment']}
                            className="isoInputWrapper"
                            label={<IntlMessages id="propertyPage.modal.asset.weatherComment" />}
                          >
                            <Input.TextArea />
                          </Form.Item>
                        </div>
                      )
                    }
                  }
                }
              }
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return (prevValues['asset']);
              }}
            >
              {({ getFieldValue }) => {
                  if(getFieldValue('asset')){
                    if (getFieldValue('asset').category !== "animal" && getFieldValue('asset').category !== "weather"){
                      return (
                        <div>
                          <Row>
                            {getFieldValue('asset').category !== "health_and_safety" &&
                            <Col xs={{span: 24}} md={{span: 11}}>
                              <Form.Item
                                name={['asset', 'state']}
                                className="isoInputWrapper"
                                label={getFieldValue('asset').category === 'water' ? "Level":"State"}
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                               {getFieldValue('asset').category !== 'water' ? renderOptionsAssetState() : renderOptionsLevelTypes()}
                              </Form.Item>
                            </Col>
                            }
                            {getFieldValue('asset').category !== "health_and_safety" && getFieldValue('asset').category !== 'water' &&
                            <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                              <Form.Item
                                name={['asset', 'date_of_purchase']}
                                className="isoInputWrapper"
                                label="Date Of Purchase"
                                rules={[
                                  {
                                    required: getFieldValue('asset').type === 'chemical' ? false : true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                                <DatePicker size="large" style={{width: '100%'}} format={dateFormat}/>
                              </Form.Item>
                            </Col>
                          }
                          </Row>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => {
                              return (prevValues['asset'] && (prevValues['asset'].state !== currentValues['asset'].state));
                            }}
                          >
                            {({ getFieldValue }) => {
                                if(getFieldValue('asset')){
                                  if (getFieldValue('asset').state === "other"){
                                    return (
                                      <Form.Item
                                        name={['asset', 'other_state']}
                                        className="isoInputWrapper"
                                        label="Other State"
                                        rules={[
                                          {
                                            required: true,
                                            message: 'This field is required',
                                          },
                                        ]}
                                      >
                                        <Input size="large" placeholder="Input other state"/>
                                      </Form.Item>
                                    );
                                  }
                                }
                              }
                            }
                          </Form.Item>
                          <Row>
                            {getFieldValue('asset').category !== "health_and_safety" && getFieldValue('asset').category !== 'water' &&
                            <Col xs={{span: 24}} md={{span: 11}}>
                              <Form.Item
                                name={['asset', 'manufacture_date']}
                                className="isoInputWrapper"
                                label="Manufacture Date"
                                rules={[
                                  {
                                    required: getFieldValue('asset').type === 'chemical' ? false : true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                                <DatePicker size="large" style={{width: '100%'}} format={dateFormat}/>
                              </Form.Item>
                            </Col>
                            }
                            {getFieldValue('asset').category !== "water" && getFieldValue('asset').category !== "health_and_safety"  &&
                              <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                                <Form.Item
                                  name={['asset', 'primary_object_id']}
                                  className="isoInputWrapper"
                                  label="Location"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'This field is required',
                                    },
                                  ]}
                                >
                                  {renderOptionObject(props.primaryObjects)}
                                </Form.Item>
                              </Col>
                            }
                          </Row>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => {
                               return (prevValues['asset'] && (prevValues['asset'].state !== currentValues['asset'].state));
                            }}
                          >
                            {({ getFieldValue }) => {
                                if(getFieldValue('asset')){
                                  if (getFieldValue('asset').category === "health_and_safety"){
                                    return (
                                      <div>
                                          <Row>
                                            <Col xs={{span: 24}} md={{span: 24}}>
                                              <Form.Item
                                                name={['asset', 'type']}
                                                className="isoInputWrapper"
                                                label={<IntlMessages id="propertyPage.modal.asset.healthSafetyTypes" />}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'This field is required',
                                                  },
                                                ]}
                                              >
                                                {renderOptionsHealthSafetyTypes()}
                                              </Form.Item>
                                            </Col>
                                            
                                          </Row>

                                          <Form.Item
                                              noStyle
                                              shouldUpdate={(prevValues, currentValues) => {
                                                 return (prevValues['asset'] && (prevValues['asset'].type !== currentValues['asset'].type));
                                              }}
                                            >
                                            {({ getFieldValue }) => {
                                              if(getFieldValue('asset') && getFieldValue('asset').type){
                                              
                                                  return (
                                                    <div>
                                                      <Row>
                                                      {getFieldValue('asset').type !== "fire_extinguisher" &&
                                                      <Col xs={{span: 24}} md={{span: 11}}>
                                                        <Form.Item
                                                          name={['asset', 'last_checked_date']}
                                                          className="isoInputWrapper"
                                                          label="Last Checked Date"
                                                          rules={[
                                                            {
                                                              required: true,
                                                              message: 'This field is required',
                                                            },
                                                          ]}
                                                        >
                                                          <DatePicker size="large" style={{width: '100%'}} format={dateFormat}/>
                                                        </Form.Item>
                                                      </Col>
                                                      }
                                                      { getFieldValue('asset').type !== "first_aid_kit" &&
                                                        getFieldValue('asset').type !== "fire_extinguisher" && 
                                                        <Col xs={{span: 24}} md={{span: 11,offset:2}}>
                                                          <Form.Item
                                                            name={['asset', 'creation_date']}
                                                            className="isoInputWrapper"
                                                            label="Creation Date"
                                                            rules={[
                                                              {
                                                                required: true,
                                                                message: 'This field is required',
                                                              },
                                                            ]}
                                                            >
                                                              <DatePicker size="large" style={{width: '100%'}} format={dateFormat}/>
                                                            </Form.Item>
                                                          </Col> 
                                                          } 
                                                        </Row>
                                                        <Row>
                                                         <Col xs={{span: 24}} md={{span: 11}}>
                                                          <Form.Item
                                                            name={['asset', 'expiry_date']}
                                                            className="isoInputWrapper"
                                                            label="Expiry Date"
                                                            rules={[
                                                              {
                                                                required: true,
                                                                message: 'This field is required',
                                                              },
                                                            ]}
                                                          >
                                                            <DatePicker size="large" style={{width: '100%'}} format={dateFormat}/>
                                                          </Form.Item>
                                                          </Col>
                                                          {
                                                            (getFieldValue('asset').type === "fire_extinguisher" || 
                                                            getFieldValue('asset').type === "first_aid_kit" ) &&
                                                            <Col xs={{span: 24}} md={{span: 11,offset:2}}>
                                                              <Form.Item
                                                                name={['asset', 'manufacture_date']}
                                                                className="isoInputWrapper"
                                                                label="Manufacture Date"
                                                                rules={[
                                                                  {
                                                                    required: getFieldValue('asset').type === 'chemical' ? false : true,
                                                                    message: 'This field is required',
                                                                  },
                                                                ]}
                                                              >
                                                                <DatePicker size="large" style={{width: '100%'}} format={dateFormat}/>
                                                              </Form.Item>
                                                            </Col>
                                                            }
                                                            {
                                                              (getFieldValue('asset').type === "policy" ||
                                                              getFieldValue('asset').type === "induction"  ||
                                                              getFieldValue('asset').type === "procedure" ) &&
                                                              
                                                                <Col xs={{span: 24}} md={{span: 11,offset:2}}>
                                                                  <Form.Item
                                                                    className="isoInputWrapper"
                                                                    label="Tags"
                                                                  >
                                                                    <TagsInput name={['asset', 'tags']} value={tags} onChange={handleChangeTag} addOnBlur="true"/>
                                                                  </Form.Item>
                                                                </Col>
                                                             
                                                            }
                                                        </Row>  
                                                        
                                                        {
                                                          getFieldValue('asset').type !== "policy" && 
                                                          getFieldValue('asset').type !== "induction"  &&
                                                          getFieldValue('asset').type !== "procedure"  ?
                                                        <Row style={{marginBottom:'10px',marginTop:'5px'}}>
                                                          <Col xs={{span: 24}} md={{span: 24}} style={{marginBottom:'10px'}}>
                                                            <Map ref={mapRef} center={position[0] !== null ? position : false} doubleClickZoom={false} onclick={handleClickMap} zoom={16} zoomControl={true} style={{width:'100%', height:'300px'}}>
                                                             <TileLayer
                                                              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                              />
                                                              {valueLat  && <Marker position={[valueLat,valueLng]} draggable={true}>
                                                                  <Popup position={[valueLat,valueLng]}>
                                                                  </Popup>
                                                              </Marker>}
                                                            </Map>
                                                          </Col>
                                                        
                                                          <Col xs={{span: 24}} md={{span: 11}}> 
                                                            <Form.Item
                                                              className="isoInputWrapper"
                                                              name='latitude'
                                                              label="Latitude"
                                                              value={valueLat}
                                                              rules={[
                                                                {
                                                                  required: true,
                                                                  message: 'This field is required',
                                                                },
                                                              ]}
                                                            >                          
                                                            <Input value={valueLat}/>
                                                            </Form.Item>
                                                          </Col>
                                                          <Col xs={{span: 24}} md={{span: 11, offset: 2}} >
                                                            <Form.Item
                                                              className="isoInputWrapper"
                                                              name='longitude'
                                                              label="Longitude"
                                                              value={valueLng}
                                                              rules={[
                                                                  {
                                                                    required: true,
                                                                    message: 'This field is required',
                                                                  },
                                                                ]}
                                                            >
                                                            <Input value={valueLng}/>
                                                            </Form.Item>
                                                          </Col> 
                                                        </Row>
                                                        :
                                                        <Row>
                                                        {getFieldValue('asset').category !== "health_and_safety" &&
                                                          <Col xs={{span: 24}} md={{span: 11}}>
                                                            <Form.Item
                                                              name={['asset', 'primary_object_id']}
                                                              className="isoInputWrapper"
                                                              label="Location"
                                                              rules={[
                                                                {
                                                                  required: true,
                                                                  message: 'This field is required',
                                                                },
                                                              ]}
                                                            >
                                                              {renderOptionObject(props.primaryObjects)}
                                                            </Form.Item>
                                                          </Col>
                                                        }
                                                          <Col xs={{span: 24}} md={{span: 11}}>
                                                           <Form.Item
                                                              name={['asset', 'created_by']}
                                                              className="isoInputWrapper"
                                                              label="Created By"
                                                              rules={[
                                                                {
                                                                  required: true,
                                                                  message: 'This field is required',
                                                                },
                                                              ]}
                                                            >
                                                              <Input size="large" placeholder="Created By"/>
                                                            </Form.Item>
                                                          </Col>
                                                        </Row>
                                                      }
                                                    </div>

                                                  )
                                               
                                            } 
                                          }
                                          }
                                          </Form.Item>

                                          <Row>
                                            <Col xs={{span: 24}} md={{span: 24}} >
                                              <Form.Item
                                                name={['asset', 'attachment']}
                                                className="isoInputWrapper"
                                                label={<IntlMessages id="propertyPage.modal.asset.attachment" />}
                                              >
                                                <Upload {...uploadProps} maxCount={1}>
                                                  <Button icon={<UploadOutlined />}>Upload</Button>
                                                </Upload>
                                              
                                              </Form.Item>
                                            </Col>
                                          </Row>
                                    </div>
                                  )
                                }
                               }
                              } 
                            }
                          </Form.Item>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => {
                                return (prevValues['asset'] && (prevValues['asset'].state !== currentValues['asset'].state));
                            }}
                          >
                          {({ getFieldValue }) => {
                              if(getFieldValue('asset')){
                                if (getFieldValue('asset').category === "water"){
                                  return (
                                    <div>
                                      <Row>
                                        <Col xs={{span: 24}} md={{span: 11}}>
                                            <Form.Item
                                              name={['asset', 'creation_date']}
                                              className="isoInputWrapper"
                                              label="Creation Date"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'This field is required',
                                                },
                                              ]}
                                            >
                                              <DatePicker size="large" style={{width: '100%'}} format={dateFormat}/>
                                            </Form.Item>
                                          </Col>        
                                          <Col xs={{span: 24}} md={{span: 11,offset: 2}}>
                                            <Form.Item
                                              name={['asset', 'last_checked_date']}
                                              className="isoInputWrapper"
                                              label="Last Checked Date"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'This field is required',
                                                },
                                              ]}
                                            >
                                              <DatePicker size="large" style={{width: '100%'}} format={dateFormat}/>
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                        <Tabs defaultActiveKey="1" onChange={onChange}>
                                           
                                            <TabPane tab="Area" key="1">
                                              { currentTab == 1 && <Col xs={{span: 24}} md={{span: 11}}>
                                                <Form.Item
                                                 
                                                  name={['asset', 'primary_object_id']}
                                                  className="isoInputWrapper"
                                                  label="Location"
                                                  
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: 'This field is required',
                                                    },
                                                  ]}
                                                >
                                                  {renderOptionObject(props.primaryObjects)}
                                                </Form.Item>
                                              </Col>}
                                            </TabPane>

                                            <TabPane tab="Location" key="2">
                                              
                                              <Map ref={mapRef} center={position[0] !== null ? position : false} doubleClickZoom={false} onclick={handleClickMap} zoom={16} zoomControl={true} style={{width:'100%', height:'300px'}}>
                                                 <TileLayer
                                                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                  />
                                                  {valueLat  && <Marker position={[valueLat,valueLng]} draggable={true}>
                                                      <Popup position={[valueLat,valueLng]}>
                                                      </Popup>
                                                  </Marker>}
                                              </Map>
                                                <Row style={{marginTop:"10px", marginBottom:"5px"}}>
                                                  <Col xs={{span: 24}} md={{span: 11}} >
                                
                                                      <Input value={valueLat}/>
                                                  </Col>
                                                  <Col xs={{span: 24}} md={{span: 11, offset: 2}} >
                                                      <Input value={valueLng}/>
                                                  </Col>
                                              </Row>
                                            </TabPane>
                                            
                                       </Tabs>
                                    </div>
                                  )

                                }
                              }
                            }
                          }
                          </Form.Item>
                      
                        </div>
                      )
                    }
                  }
                }
              }
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return (prevValues['asset']);
              }}
            >
              {({ getFieldValue }) => {
                  if(getFieldValue('asset')){
                    if (getFieldValue('asset').category !== "weather"){
                      return (
                        <Form.Item
                          name={['asset', 'description']}
                          className="isoInputWrapper"
                          label="Description"
                        >
                          <Input.TextArea />
                        </Form.Item>
                      )
                    }
                  }
                }
              }
            </Form.Item>
          </div>  
      </Form>
      }     
    </AssetModalWrapper>
    </>
  )
}

export default AssetModal;

const ColorItemRow = styled.div`
  display: flex;
  align-items: center;
  line-height: 30px;
  div{
    margin: 4px;
  }
`;

const ColorItemPreview = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 2px;
`;