import React, { useState, useEffect } from 'react';
import { Marker } from 'react-leaflet';
import {  IconObservation  } from '../../Icons/IconObservation';
import _ from 'lodash';
import ObservationModal from './ObservationModal';
import { observationService } from '@iso/services';
import btnSaveAction from '@iso/redux/btnSave/actions';
import { useDispatch, useSelector } from 'react-redux';
import modalActions from '@iso/redux/modal/actions';

const { show } = btnSaveAction;
const { openModal } = modalActions;

const Observation = (props) => {
  const [observations, setObservations] = useState({});
  const [dataObservation, setDataObservation] = useState({});
  const [primaryObjects, setPrimaryObjects] = useState([]);
  const objectRef = React.useRef([]);
  const dispatch = useDispatch();
  const saveSuccess = useSelector((state) => state.btnSave.saveSuccess);
  const cancelSave = useSelector((state) => state.btnSave.cancelSave);
  const dataTransfer = useSelector((state) => state.btnSave.data);
  const modalData = useSelector((state) => state.modal.modalData);

  const updateDataObservations = React.useCallback((propertyId) => {
    observationService.getList(propertyId).then(res => {
      if(res.code === '0000'){
        setObservations(res.observations);
      }
    })
  }, [props.primaryObjects]);

  useEffect(() => {
    setPrimaryObjects(props.primaryObjects);
  }, [props.primaryObjects]);

  useEffect(() => {
    if(props.propertyId){
      updateDataObservations(props.propertyId);
    }
  }, [updateDataObservations, props.propertyId]);

  const saveLocation = React.useCallback((observation, dataTransfer) => {
    var layer = objectRef.current[observation.id].leafletElement;

    var point = dataTransfer.points;

    const data = {
      observation:{
        ...observation,
        point
      }
    }
    observationService.storeOrUpdateObservation(data, observation.id).then(res => {
      if(res.code === '0000'){
        updateDataObservations(res.observation.property_id)
        layer.editing.disable();
      }
    });

  }, [updateDataObservations]);

  const cancelSaveArea = React.useCallback((propertyId, dataObjectId) => {
    var layer = objectRef.current[dataObjectId].leafletElement;
    layer.editing.disable();
  }, []);

  useEffect(() => {
    if(saveSuccess && dataTransfer.type === 'observation' && dataObservation){
      saveLocation(dataObservation, dataTransfer);
    } else if (cancelSave && dataTransfer.type === 'observation'){
      cancelSaveArea(props.propertyId, dataObservation.id);
      updateDataObservations(props.propertyId);
    }
  }, [saveSuccess, saveLocation, dataTransfer, props.propertyId, cancelSave, dataObservation, cancelSaveArea])

  const onSaved = (savedObservation) => {
    let observationTmp = _.clone(observations);
    const observationIndex = _.findIndex(observationTmp, (observation) => {
      return observation.id === savedObservation.id
    });
    if(observationIndex === -1){
      observationTmp.push(savedObservation);
    } else {
      observationTmp[observationIndex] = savedObservation;
    }
    setObservations(observationTmp);
    props.updatePropertyDetail(props.propertyId);
  }
  
  const handleShowObservations = (data) => {
    var html = [];
    if(data.length > 0){
      _.forEach(data, function(observation){
        html.push(
          <Marker 
            key={observation.id} 
            position={[observation.lat, observation.lng]} 
            icon={IconObservation()}
            onClick={handleClickObservation.bind(this, observation)}
            ref={el => (objectRef.current[observation.id] = el)}
          />
        );
      });
    }
    return html;
  }

  const handleClickObservation = (object, e) => {
    //Not trigger anything if on deleted state
    if(!props.controlDraw.current.leafletElement._toolbars.edit._modes.remove || !props.controlDraw.current.leafletElement._toolbars.edit._modes.remove.handler._enabled){
      const modalData = {
        object,
        type: 'observation'
      }
      dispatch(openModal(modalData));
    }
  }

  const editLocation = () => {
    const data = {
      objectRef,
      dataId: modalData.object.id,
      type: 'observation'
    }
    dispatch(show(data));
    setDataObservation(modalData.object);
    objectRef.current[modalData.object.id].leafletElement.editing.enable();
  }

  return (
    <>
      {handleShowObservations(observations)}
      <ObservationModal
        propertyId={props.propertyId}
        primaryObjects={primaryObjects}
        onSaved={onSaved}
        editLocation={editLocation}
      />
    </>
  );
}

export default Observation;
