import React from "react";

const Propertymapicon = () => {
  return (
    <div className="sidebar-icon">
      <svg className="sidebar-icon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 13.43C13.7231 13.43 15.12 12.0331 15.12 10.31C15.12 8.58687 13.7231 7.19 12 7.19C10.2769 7.19 8.88 8.58687 8.88 10.31C8.88 12.0331 10.2769 13.43 12 13.43Z"
        //  stroke='rgb(143, 148, 151)'
        style={{strokeWidth:'1.5', strokeLinecap:'round',strokeLinejoin:'round'}}   

        />
        <path
          d="M3.62001 8.49C5.59001 -0.169998 18.42 -0.159997 20.38 8.5C21.53 13.58 18.37 17.88 15.6 20.54C13.59 22.48 10.41 22.48 8.39001 20.54C5.63001 17.88 2.47001 13.57 3.62001 8.49Z"
          style={{strokeWidth:'1.5', strokeLinecap:'round',strokeLinejoin:'round'}}
        />
      </svg>
    </div>
  );
};
export default Propertymapicon;
