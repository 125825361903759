import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import Box from "@iso/components/utility/box";
import IntlMessages from "@iso/components/utility/intlMessages";
import { Button, Dropdown, Menu, Popconfirm, Popover } from "antd";
import { DownOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import modalActions from "@iso/redux/modal/actions";
import { assetService, propertyService } from "@iso/services";
import _ from "lodash";
import moment from "moment";
import Table from "@iso/components/collapsing-table/table";
import styled from "styled-components";
import { assetStates } from "@iso/constants/assetStates";
import AssetModal from "@iso/containers/Pages/PropertyMap/components/Asset/AssetModal";
import EditAircraftModal from '@iso/containers/Pages/PropertyMap/components/Asset/EditAircraftModal';
import EditFeedModal from '@iso/containers/Pages/PropertyMap/components/Asset/EditFeedModal';
import EditFuelModal from '@iso/containers/Pages/PropertyMap/components/Asset/EditFuelModal';
import EditGeneralAssetModal from '@iso/containers/Pages/PropertyMap/components/Asset/EditGeneralAssetModal';
import EditTreatmentModal from '@iso/containers/Pages/PropertyMap/components/Asset/EditTreatmentModal';
import EditWaterModal from '@iso/containers/Pages/PropertyMap/components/Asset/EditWaterModal';
import EditAnimalModal from '@iso/containers/Pages/PropertyMap/components/Asset/EditAnimalModal';
import EditWeatherModal from '@iso/containers/Pages/PropertyMap/components/Asset/EditWeatherModal';
import ManagePropertyPage from './Partial.styles'
import {
    EllipsisOutlined,
  
  } from "@ant-design/icons";
const { openModal } = modalActions;

const AssetBox = (props) => {
    const [assets, setAssets] = useState([]);
    const dispatch = useDispatch();
    const activePropertyId = useSelector(
        (state) => state.property.activePropertyId
    );
    const activeProperty = localStorage.getItem("active_property");
    const activePermission = useSelector(
        (state) => state.permission.activePermission
    );

    const [liveStockData, setLiveStockData] = useState({});
    const [visibleEditLivestock, setVisibleEditLivestock] = useState(false);
    const [primaryObjects, setPrimaryObjects] = useState([]);
    const [visibleAssetTreatment, setVisibilityAssetTreatment] = React.useState(
        false
    );
    const [visibleAssetFuel, setVisibilityAssetFuel] = React.useState(false);
    const [visibleAssetFeed, setVisibilityAssetFeed] = React.useState(false);
    const [visibleAssetWater, setVisibilityAssetWater] = React.useState(false);
    const [visibleAssetAircraft, setVisibilityAssetAircraft] = React.useState(
        false
    );
    const [visibleAssetOther, setVisibilityAssetOther] = React.useState(false);
    const [dataAsset, setDataAsset] = useState({});
    const [visibleSelectProperty, setVisiblitySelectProperty] = React.useState(
        false
    );
    const [visibleAssetAnimal, setVisibilityAssetAnimal] = React.useState(
        false
    );
    const [visibleAssetWeather, setVisibilityAssetWeather] = React.useState(
        false
    );

    const updatePropertyDetail = React.useCallback((id) => {
        propertyService.viewProperty(id).then((res) => {
            if (res.code === "0000") {
                setPrimaryObjects(res.primary_objects);
            }
        });
    }, []);

    useEffect(() => {
        if (activePropertyId) {
            updatePropertyDetail(activePropertyId);
        }
    }, [activePropertyId, updatePropertyDetail]);

    useEffect(() => {
        updateAssetData();
    }, [activePropertyId]);

    const updateAssetData = () => {
        if (activePropertyId) {
            assetService.getList(activePropertyId).then((res) => {
                if (res.code === "0000") {
                    setAssets(res.facilities);
                }
            });
        }
    };

    useEffect(() => {
        if (!activeProperty) {
            setVisiblitySelectProperty(true);
        }
    }, []);

    useEffect(() => {
        propertyService.getProperties().then((res) => {
            if (res.properties.length === 0) {
                setVisiblitySelectProperty(true);
            }
        });
    }, [activePropertyId]);

    const onSaved = (savedAsset) => {
        let tmpAssets = _.clone(assets);
        const Index = _.findIndex(tmpAssets, (asset) => {
            return asset.id === savedAsset.id;
        });

        if (Index === -1) {
            tmpAssets.push(savedAsset);
        } else {
            tmpAssets[Index] = savedAsset;
        }
        setAssets(tmpAssets);
        updatePropertyDetail(activePropertyId);
    };

    const menu = (asset) => (
        <Menu onClick={handleMenuClick.bind(this, asset)}>
            <Menu.Item key="editDetails" icon={<EditOutlined />}>
                <IntlMessages id="propertyPage.managePage.editDetails" />
            </Menu.Item>
            {(activePermission == "owner" || activePermission == "admin") && (
                <p style={{ cursor: "pointer" }} className="deleteButton">
                    <Popconfirm
                        placement="bottomRight"
                        title="You are about to delete individual record"
                        onConfirm={() => {
                            confirmDelete(asset);
                        }}
                        okText="Yes"
                        cancelText="No"
                        className="delete-button"
                    >
                        <a
                            style={{
                                color: "rgba(0, 0, 0, 0.65)",
                                marginLeft: "11px",
                            }}
                        >
                            {<DeleteOutlined />}&nbsp;&nbsp;
                            <IntlMessages id="propertyPage.managePage.delete" />
                        </a>
                    </Popconfirm>
                </p>
            )}
        </Menu>
    );
    const handleMenuClick = (asset, e) => {
        if (e.key === "editDetails") {
            setDataAsset(asset);
            if (
                asset.type === "animal_treatment" ||
                asset.type === "fertiliser" ||
                asset.type === "vaccine"
            ) {
                setVisibilityAssetTreatment(true);
            }

            if (asset.type === "fuel" || asset.type === "chemical") {
                setVisibilityAssetFuel(true);
            }

            if (asset.type === "stock_feed") {
                setVisibilityAssetFeed(true);
            }

            if (asset.type === "water") {
                setVisibilityAssetWater(true);
            }

            if (asset.type === "aircraft") {
                setVisibilityAssetAircraft(true);
            }

            if (asset.type === "other") {
                setVisibilityAssetOther(true);
            }

            if (asset.category === "animal") {
                setVisibilityAssetAnimal(true);
            }

            if (asset.category === "weather") {
                setVisibilityAssetWeather(true);
            }

            if (
                asset.category !== "inventory" &&
                asset.category !== "animal" &&
                asset.category !== "weather"
            ) {
                setVisibilityAssetOther(true);
            }
        }
    };

    const confirmDelete = (del_asset) => {
        if (del_asset.id) {
            assetService.destroy(del_asset.id).then((res) => {
                if (res.code === "0000") {
                    updateAssetData();
                }
            });
        }
    };

    const columns = [
        {
            label: <IntlMessages id="assetPage.table.name" />,
            accessor: "title",
            minWidth: 100,
            sortable: true,
            position: 1,
            priorityLevel: 1,
        },
        {
            label: <IntlMessages id="assetPage.table.category" />,
            accessor: "category_label",
            minWidth: 100,
            sortable: true,
            position: 2,
            priorityLevel: 2,
        },
        {
            label: <IntlMessages id="assetPage.table.assetType" />,
            accessor: "type_label",
            minWidth: 100,
            sortable: true,
            position: 3,
            priorityLevel: 3,
        },{
            label: <IntlMessages id="assetPage.table.stateOrLevel" />,
            accessor: "state_label",
            minWidth: 100,
            sortable: true,
            position: 4,
            priorityLevel: 4,
            CustomComponent: (cell) => {
              let asset = cell.row;
              if (asset.category != "water") {
                return assetStates.map((value, index) => {
                  const opacity = 0.30;
                  const rgbValues = value.color.match(/\d+/g);
                  const colorWithOpacity = `rgba(${rgbValues[0]}, ${rgbValues[1]}, ${rgbValues[2]}, ${opacity})`;
                  if (value.value == asset.state) {
                    return (
                      <div className="" style={{ display: "flex", backgroundColor: colorWithOpacity, padding: "5px 10px", borderRadius: '10px', gap: "6px", maxWidth: "130px", alignItems: "center" }}>
                        <ColorItemPreview
                          style={{ backgroundColor: value.color }}
                        ></ColorItemPreview>
                        &nbsp;&nbsp;
                        <div>{value.label}</div>
                      </div>
                    );
                  }
                });
              } else {
                return <div>{asset.state}</div>;
              }
            },
          },
        
        {
            label: <IntlMessages id="assetPage.table.location" />,
            accessor: "primary_object",
            minWidth: 200,
            sortable: true,
            position: 5,
            priorityLevel: 5,
        },
        {
            label: <IntlMessages id="assetPage.table.lastUsed" />,
            accessor: "last_activity_date",
            minWidth: 200,
            sortable: true,
            position: 6,
            priorityLevel: 6,
            CustomComponent: (cell) => {
                let asset = cell.row;
                return asset.last_activity_date
                    ? moment(asset.last_activity_date).format("DD/MM/YYYY")
                    : "N/A";
            },
        },
        {
            label: <IntlMessages id="assetPage.table.remaining" />,
            accessor: "remaining_label",
            minWidth: 100,
            sortable: true,
            position: 7,
            priorityLevel: 7,
        },

        {
            label: "Action",
            accessor: "dot-menu",
            minWidth: 100,
            sortable: true,
            position: 8,
            priorityLevel: 8,
            noTitle: true,
            CustomComponent: (cell, i) => {
              let row = cell.row;
      
              return (
                <div className="dot-btn manage-property-dot-menulist">
                  <Popover
                    placement="bottomLeft"
                    className="mp-popover"
                    content={() => menu(row)}
                    trigger="click"
                  >
                    <div
                      className="manage-property-dot-btn actionstyle"
                      style={{ cursor: "pointer", fontSize: 'large', fontWeight: 'bold', color: '#3f3f44' }}
                    >
                      <EllipsisOutlined style={{ rotate: '90deg' }} />
                    </div>
                  </Popover>
                </div>
              );
            },
          },
    ];

    const cancelEditTreatmentModal = () => {
        setVisibilityAssetTreatment(false);
      }
    
      const cancelEditFuelModal = () => {
        setVisibilityAssetFuel(false);
      }
    
      const cancelEditFeedModal = () => {
        setVisibilityAssetFeed(false);
      }
    
      const cancelEditWaterModal = () => {
        setVisibilityAssetWater(false);
      }
    
      const cancelEditAircraftModal = () => {
        setVisibilityAssetAircraft(false);
      }
    
      const cancelEditOtherModal = () => {
        setVisibilityAssetOther(false);
      }
    
      const cancelEditAnimalModal = () => {
        setVisibilityAssetAnimal(false);
      }
    
      const cancelEditWeatherModal = () => {
        setVisibilityAssetWeather(false);
      }

    return (
        <ManagePropertyPage>
        <Fragment>
            <Box id="containerBox">
            <div className="manage-property-header">
            <div className="mp-title">
              <h2 className="manage-property-title">Asset</h2></div>
            </div>

                {props.search_result &&
                props.search_result.Facility &&
                props.search_result.Facility.length > 0 ? (
                    <div className="manage-table">
                    <Table
                        columns={columns}
                        rows={props.search_result.Facility}
                        containerId="containerBox"
                        showPagination={false}
                        rowSize={99999}
                    /></div>
                ) : (
                    <h1 style={{ padding: "10px 10px 20px", fontSize: '18px' }}>
                    <IntlMessages id="dashboardPage.noData" />
                  </h1>
                )}
            </Box>
            <EditTreatmentModal
                updateDataAssets={updateAssetData}
                propertyId={activePropertyId}
                primaryObjects={primaryObjects}
                onSaved={onSaved}
                visibleAssetTreatment={visibleAssetTreatment}
                dataAsset={dataAsset}
                cancelEditModal={cancelEditTreatmentModal}
            />
            <EditFuelModal        
        updateDataAssets={updateAssetData}
        propertyId={activePropertyId}
        primaryObjects={primaryObjects}
        onSaved={onSaved}
        visibleAssetFuel={visibleAssetFuel}
        dataAsset={dataAsset}
        cancelEditModal={cancelEditFuelModal}
      />
      <EditFeedModal        
        updateDataAssets={updateAssetData}
        propertyId={activePropertyId}
        primaryObjects={primaryObjects}
        onSaved={onSaved}
        visibleAssetFeed={visibleAssetFeed}
        dataAsset={dataAsset}
        cancelEditModal={cancelEditFeedModal}
      />
      <EditWaterModal        
        updateDataAssets={updateAssetData}
        propertyId={activePropertyId}
        primaryObjects={primaryObjects}
        onSaved={onSaved}
        visibleAssetWater={visibleAssetWater}
        dataAsset={dataAsset}
        cancelEditModal={cancelEditWaterModal}
      />
      <EditAircraftModal        
        updateDataAssets={updateAssetData}
        propertyId={activePropertyId}
        primaryObjects={primaryObjects}
        onSaved={onSaved}
        visibleAssetAircraft={visibleAssetAircraft}
        dataAsset={dataAsset}
        cancelEditModal={cancelEditAircraftModal}
      />
      <EditGeneralAssetModal        
        updateDataAssets={updateAssetData}
        propertyId={activePropertyId}
        primaryObjects={primaryObjects}
        onSaved={onSaved}
        visibleAssetOther={visibleAssetOther}
        dataAsset={dataAsset}
        cancelEditModal={cancelEditOtherModal}
      />
      <EditAnimalModal        
        updateDataAssets={updateAssetData}
        propertyId={activePropertyId}
        primaryObjects={primaryObjects}
        onSaved={onSaved}
        visibleAssetAnimal={visibleAssetAnimal}
        dataAsset={dataAsset}
        cancelEditModal={cancelEditAnimalModal}
      />  
      <EditWeatherModal        
        updateDataAssets={updateAssetData}
        propertyId={activePropertyId}
        primaryObjects={primaryObjects}
        onSaved={onSaved}
        visibleAssetWeather={visibleAssetWeather}
        dataAsset={dataAsset}
        cancelEditModal={cancelEditWeatherModal}
      />
        </Fragment>
        </ManagePropertyPage>
    );
};

export default AssetBox;

const ColorItemRow = styled.div`
    display: flex;
    align-items: center;
    line-height: 30px;
    div {
        margin: 4px;
    }
`;

const ColorItemPreview = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 50%;
`;
