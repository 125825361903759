import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Form, Input, Button, InputNumber, Select, Popconfirm, Row, Col, Tabs, Upload, Switch,Space,Collapse,DatePicker,Tooltip,Popover} from 'antd';
import { UploadOutlined,InfoCircleOutlined } from '@ant-design/icons';
import _ from 'lodash';
import styled from "styled-components";
import { calculatorPaddock } from '@iso/services';
import modalActions from '@iso/redux/modal/actions';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '@iso/components/utility/loader';
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';
import PlanWrapModal from './PlanWrapModal.styles';
import Table7 from './Table7';
import Table8 from './Table8';
import Table9 from './Table9';
import { pastureQuality } from '@iso/constants/pastureQuality';
import { simplePasture } from '@iso/constants/simplePasture';
import { stockType } from '@iso/constants/stockType';
import { averageWeightCows } from '@iso/constants/averageWeightCows';
import { averageWeightWeaner } from '@iso/constants/averageWeightWeaner';
import { averageWeightEwes } from '@iso/constants/averageWeightEwes';
import { averageWeightLambs } from '@iso/constants/averageWeightLambs';
import { physiologicalStateCows } from '@iso/constants/physiologicalStateCows';
import { physiologicalStateEwes } from '@iso/constants/physiologicalStateEwes';
import { dataIntake } from '@iso/constants/dataIntake';
import moment from 'moment';
import { dateHelper } from '@iso/lib/helpers/dateHelper';

const { Option } = Select;
const { closeModal } = modalActions;
const { Panel } = Collapse;

const AddPlanModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [planForm] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [simplePastureBudgets, setSimplePastureBudgets] = useState('');
  const [estimatedFeed, setEstimatedFeed] = useState(0);

  const dateFormat = 'DD/MM/YYYY';
  const table7 = <Table7 />;
  const table8 = <Table8 />;
  const table9 = <Table9 />;
  
  const tooltipPastureQuality = <span>When stock graze pastures, losses occur through trampling and fouling and is commonly 15—40% of consumption. Use low wastage values for shorter pastures, rotational grazing and dry paddocks and higher values if rank pasture, set stocked and wet paddocks.</span>;

  useEffect(() => {
    setFields([
        {
          name: ['paddock', 'date'],
          value: moment()
        },
        {
          name: ['paddock', 'size'],
          value: props.dataObject.acreage_by_hectare ? props.dataObject.acreage_by_hectare : ''
        },   
    ]);
  }, [props.dataObject]);


  const setFieldStock = (stock,valueEstimated,valueMaintenance) =>{
    setFields([
        {
          name: [stock, 'estimated_pasture_intake'],
          value: valueEstimated
        }, 
        {
          name: [stock, 'maintenance_energy'],
          value: valueMaintenance
        },
    ]);
  }

  
  const renderStockType = (stock) => {
    let options = [];
    _.forEach(stockType, (item, index) => {
      options.push(
        <Option key={index} value={item.value} stock={stock} stockType="stockType">
            <div>{item.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder=""
        allowClear
        size="large"
        
      >
        {options}
      </Select>
    );
  }

  const renderTwinsSingles = () => {
    return (
      <Select
        placeholder=""
        allowClear
        size="large"
      >
        <Option key='1' value='twins'>
            <div>Twins</div>
        </Option>  
        <Option key='2' value='singles'>
            <div>Singles</div>
        </Option>
      </Select>
    );
  }  
  const renderFoo = () => {
    return (
      <Select
        placeholder=""
        allowClear
        size="large"
      >
        <Option key='1' value={500}>
            <div>500</div>
        </Option>  
        <Option key='2' value={1000}>
            <div>1000</div>
        </Option> 
        <Option key='3' value={1500}>
            <div>1500</div>
        </Option> 
        <Option key='4' value={2000}>
            <div>2000</div>
        </Option>
      </Select>
    );
  }
  const rendeRaverageWeightCows = () => {
    let options = [];
    _.forEach(averageWeightCows, (item, index) => {
      options.push(
        <Option key={index} value={item.value}>
            <div>{item.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder=""
        allowClear
        size="large"

      >
        {options}
      </Select>
    );
  }  
  const renderAverageWeightWeaner = () => {
    let options = [];
    _.forEach(averageWeightWeaner, (item, index) => {
      options.push(
        <Option key={index} value={item.value}>
            <div>{item.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder=""
        allowClear
        size="large"

      >
        {options}
      </Select>
    );
  }  
  const renderAverageWeightEwes = () => {
    let options = [];
    _.forEach(averageWeightEwes, (item, index) => {
      options.push(
        <Option key={index} value={item.value}>
            <div>{item.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder=""
        allowClear
        size="large"

      >
        {options}
      </Select>
    );
  }  
  const renderAverageWeightLambs = () => {
    let options = [];
    _.forEach(averageWeightLambs, (item, index) => {
      options.push(
        <Option key={index} value={item.value}>
            <div>{item.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder=""
        allowClear
        size="large"

      >
        {options}
      </Select>
    );
  }  
  const renderPhysiologicalStateCows = () => {
    let options = [];
    _.forEach(physiologicalStateCows, (item, index) => {
      options.push(
        <Option key={index} value={item.value}>
            <div>{item.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder=""
        allowClear
        size="large"

      >
        {options}
      </Select>
    );
  }  
  const renderPhysiologicalStateEwes = () => {
    let options = [];
    _.forEach(physiologicalStateEwes, (item, index) => {
      options.push(
        <Option key={index} value={item.value}>
            <div>{item.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder=""
        allowClear
        size="large"

      >
        {options}
      </Select>
    );
  }
  const renderPastureQuality = () => {
    let options = [];
    _.forEach(pastureQuality, (item, index) => {
      options.push(
        <Option key={index} value={item.value} pastureQuality='pastureQuality'>
            <div>{item.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a pasture quality"
        allowClear
        size="large"
        
      >
        {options}
      </Select>
    );
  }  
  const renderSimplePasture = () => {
    let options = [];
    _.forEach(simplePasture, (item, index) => {
      options.push(
        <Option key={index} value={item.value}>
            <div>{item.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Simple pasture budgets"
        allowClear
        size="large"
        onChange={onChangeSimplePasture}
      >
        {options}
      </Select>
    );
  }
  const onChangeSimplePasture = (value) =>{
    setSimplePastureBudgets(value);
  }
  const handleCalculator = (e) =>{

    planForm
      .validateFields()
      .then(values => {
        // console.log(values);
        let paddockSize = values.paddock.size ? values.paddock.size : 0;
        let paddockFoo = values.paddock.foo ? values.paddock.foo : 0;
        let consumptionWastageFactor = values.paddock.consumption_wastage_factor ? values.paddock.consumption_wastage_factor : 0;
        let pastureGrowth  = values.paddock.pasture_growth ? values.paddock.pasture_growth : 0;
        let desiredFoo  = values.paddock.desired_foo ? values.paddock.desired_foo : 0;

        let simplePastureType = values.paddock.simple_pasture_budgets;
        let stockAnimalsIntake = 0; 
       
        let intake = 0;
        let energy = 0;

        for(let i = 1; i < 4; i++){
          if(typeof values['stock'+i]  !== 'undefined'){
            let stockTypeVal = values['stock'+i]['stock_type'];
            let fooVal = values['paddock']['foo'];
            let pastureQualityVal = values['paddock']['pasture_quality'];
            let averageWeightVal = values['stock'+i]['average_weight'];
            let physiologicalStateVal = values['stock'+i]['physiological_state'];
            let numberAanimals = values['stock'+i]['number_animals_grazing'];
            let twin_singles_not_app = values['stock'+i]['twin_singles_not_app'];
            _.forEach(dataIntake, (item, index) => {
              if(values['stock'+i]['stock_type'] === 'cows'){
                intake = item[stockTypeVal][fooVal] ? item[stockTypeVal][fooVal][pastureQualityVal][averageWeightVal][physiologicalStateVal]['intake'] : 0;
                energy = item[stockTypeVal][fooVal] ? item[stockTypeVal][fooVal][pastureQualityVal][averageWeightVal][physiologicalStateVal]['energy'] : 0;
                // console.log(item[stockTypeVal][fooVal][pastureQualityVal][averageWeightVal][physiologicalStateVal]);
              }else if(values['stock'+i]['stock_type'] === 'weanerCattle'){
                intake = item[stockTypeVal][fooVal] ? item[stockTypeVal][fooVal][pastureQualityVal][averageWeightVal]['intake'] : 0;
                energy = item[stockTypeVal][fooVal] ? item[stockTypeVal][fooVal][pastureQualityVal][averageWeightVal]['energy'] : 0;
              }else if(values['stock'+i]['stock_type'] === 'ewesWethers'){
                if(physiologicalStateVal !== 'dry'){
                  intake = item[stockTypeVal][fooVal] ? item[stockTypeVal][fooVal][pastureQualityVal][averageWeightVal][physiologicalStateVal][twin_singles_not_app]['intake'] : 0;
                  energy = item[stockTypeVal][fooVal] ? item[stockTypeVal][fooVal][pastureQualityVal][averageWeightVal][physiologicalStateVal][twin_singles_not_app]['energy'] : 0;
                }else{
                  intake = item[stockTypeVal][fooVal] ? item[stockTypeVal][fooVal][pastureQualityVal][averageWeightVal][physiologicalStateVal]['intake']: 0;
                  energy = item[stockTypeVal][fooVal] ? item[stockTypeVal][fooVal][pastureQualityVal][averageWeightVal][physiologicalStateVal]['energy']: 0;
                }
                
              }else if(values['stock'+i]['stock_type'] === 'lambs'){
                intake = item[stockTypeVal][fooVal] ? item[stockTypeVal][fooVal][pastureQualityVal][averageWeightVal]['intake'] : 0;
                energy = item[stockTypeVal][fooVal] ? item[stockTypeVal][fooVal][pastureQualityVal][averageWeightVal]['energy'] : 0;
              }

            });
            stockAnimalsIntake = stockAnimalsIntake + (numberAanimals * intake);
            setFieldStock('stock'+i,intake,energy);
          }

        }
        // formVal = planForm.getFieldsValue();
        if(simplePastureType === "howLongWill"){

            let n =  stockAnimalsIntake * ( 1 + consumptionWastageFactor/100 ) / paddockSize;
            let m = paddockFoo - desiredFoo;
            let o = pastureGrowth - n;
            let numberDaysWillLast = m / o;

            setFieldsResultHowLongWill(m.toFixed(1),n.toFixed(1),o.toFixed(1),numberDaysWillLast.toFixed(1));

          }else if(simplePastureType === "howMuchFeed"){

            let l = stockAnimalsIntake * ( 1 + consumptionWastageFactor/100 ) / paddockSize;
            let m = pastureGrowth - l;
            let n = values.result.number_days_paddock ? values.result.number_days_paddock : 0;
            let total = paddockFoo + ( m * n );
            
            setFieldsResultHowMuchFeed(l.toFixed(1),m.toFixed(1),total.toFixed(1));

          }else if(simplePastureType === "howManyHectares"){
            
            let f = values.paddock.number_days_paddock ? values.paddock.number_days_paddock : 0;
            let m = stockAnimalsIntake * ( 1 + consumptionWastageFactor/100 ) * f;
            let n = (pastureGrowth * f ) - (desiredFoo - paddockFoo);
            let total = m/pastureGrowth + (desiredFoo - paddockFoo);
         
            setFieldsResultHowManyHectares(m.toFixed(1),n.toFixed(1),total.toFixed(1));
          }
      
        
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });

  }
  const setFieldsResultHowManyHectares = (m,n,total) => {
    
    setFields([
        {
          name: ['result', 'total_pasture_intake_waste'],
          value: m
        },  
        {
          name: ['result', 'total_growth_minus_foo'],
          value: n
        },  
         {
          name: ['result', 'total_area'],
          value: total
        },
      
    ]);
  }  
  const setFieldsResultHowMuchFeed = (l,m,total) => {
    
    setFields([
        {
          name: ['result', 'daily_intake'],
          value: l
        },  
        {
          name: ['result', 'growth_minus_intake'],
          value: m
        },  
         {
          name: ['result', 'estimated_foo'],
          value: total
        },
      
    ]);
  }
  const setFieldsResultHowLongWill = (m,n,o,numberDaysWillLast) => {
    setFields([
        {
          name: ['result', 'foo'],
          value: m
        },  
        {
          name: ['result', 'daily_intake'],
          value: n
        },  
        {
          name: ['result', 'change_foo'],
          value: o
        }, 
         {
          name: ['result', 'number_days_will_last'],
          value: numberDaysWillLast
        },
      
    ]);
  }
  const handleSubmitPlan = (e) =>{
    setLoading(true);
    planForm
      .validateFields()
      .then(values => {
        onStorePlan(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      }); 
  }
  const onStorePlan = (values) => {
    
    const data = {
      ...values,
      paddock:{
        ...values.paddock,
        date: values.paddock.date ? dateHelper.dateForAPI(values.paddock.date) : '',
        primary_object_id: props.dataId,

      }
    }
    calculatorPaddock.storeOrUpdate(data).then(res => {
      if(res.code === '0000'){
        props.getListPaddock();
        setLoading();
        planForm.resetFields();
        props.cancelPlanModal();
        setFields([
          {
            name: ['paddock', 'date'],
            value: moment()
          },
          {
            name: ['paddock', 'size'],
            value: props.dataObject.acreage_by_hectare ? props.dataObject.acreage_by_hectare : ''
          },   
        ]);
      }else{
        setLoading(false);
      }
    });
  }

  const onChangeNumberOfDay = (value) =>{
    
    if(value !== null){
      setEstimatedFeed(value);
    }else{
      setEstimatedFeed(0);
    }
    
   }
  const renderStockPaddock = () => {
    let stock = [];

    for (let i = 1; i < 4; i++) {

      stock.push(
        <Collapse defaultActiveKey={['1']} key={i}>
          <Panel header={"Stock"+i} key={i}>
            <div>
              <Row>
                <Col xs={{span: 24}} md={{span: 24}}>
                  <Form.Item
                    name={['stock'+i,'stock_type']}
                    className="isoInputWrapper"
                    label="Stock type"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                  >
                    {renderStockType('stock'+i)}
                  </Form.Item> 
                </Col>
              </Row>    
             
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                    return (currentValues['stock'+i]);
                }}
              >
              {({ getFieldValue }) => {
                if(getFieldValue('stock'+i)){
                  if (getFieldValue('stock'+i).stock_type === "cows" ){
                    return (
                      <div>
                         <Row>
                            <Col xs={{span: 24}} md={{span: 24}}>
                            <Form.Item
                              name={['stock'+i, 'average_weight']}
                              className="isoInputWrapper"
                              label="Average weight"
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                },
                              ]}
                            >
                              {rendeRaverageWeightCows()}
                            </Form.Item> 
                           </Col>
                        </Row> 
                        <Row>
                          <Col xs={{span: 24}} md={{span: 24}}>
                            <Form.Item
                              name={['stock'+i, 'physiological_state' ]}
                              className="isoInputWrapper"
                              label="Physiological state"
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                },
                              ]}
                            >
                             {renderPhysiologicalStateCows()}
                            </Form.Item> 
                          </Col>
                        </Row> 
                      </div>
                    )
                  }else if(getFieldValue('stock'+i).stock_type === "weanerCattle" ){
                    return(
                      <div>
                         <Row>
                            <Col xs={{span: 24}} md={{span: 24}}>
                            <Form.Item
                              name={['stock'+i, 'average_weight']}
                              className="isoInputWrapper"
                              label="Average weight"
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                },
                              ]}
                            >
                              {renderAverageWeightWeaner()}
                            </Form.Item> 
                           </Col>
                        </Row> 
                      </div>
                    )

                  }else if(getFieldValue('stock'+i).stock_type === "lambs" ){
                    return(
                      <div>
                         <Row>
                            <Col xs={{span: 24}} md={{span: 24}}>
                            <Form.Item
                              name={['stock'+i, 'average_weight']}
                              className="isoInputWrapper"
                              label="Average weight"
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                },
                              ]}
                            >
                              {renderAverageWeightLambs()}
                            </Form.Item> 
                           </Col>
                        </Row> 
                      </div>
                    )

                  }else if(getFieldValue('stock'+i).stock_type === "ewesWethers" ){
                    return(
                      <div>
                         <Row>
                            <Col xs={{span: 24}} md={{span: 24}}>
                            <Form.Item
                              name={['stock'+i, 'average_weight']}
                              className="isoInputWrapper"
                              label="Average weight"
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                },
                              ]}
                            >
                              {renderAverageWeightEwes()}
                            </Form.Item> 
                           </Col>
                        </Row> 
                        <Row>
                          <Col xs={{span: 24}} md={{span: 24}}>
                            <Form.Item
                              name={['stock'+i, 'physiological_state' ]}
                              className="isoInputWrapper"
                              label="Physiological state"
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                },
                              ]}
                            >
                             {renderPhysiologicalStateEwes()}
                            </Form.Item> 
                          </Col>
                        </Row> 
                        <Row>
                          <Col xs={{span: 24}} md={{span: 24}}>
                            <Form.Item
                              name={['stock'+i, 'twin_singles_not_app']}
                              className="isoInputWrapper"
                              label="Twins / singles / not applicable "
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                },
                              ]}
                            >
                              {renderTwinsSingles()}
                            </Form.Item> 
                          </Col>
                        </Row>     
                        
                      </div>
                    )
                  }

                 
                 }
                } 
              }
              </Form.Item>
                            
                       
                     
              <Row>
                <Col xs={{span: 24}} md={{span: 24}}>
                  <Form.Item
                    name={['stock'+i, 'number_animals_grazing']}
                    className="isoInputWrapper"
                    label="Number of animals grazing"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                  >
                    <InputNumber size="large" style={{width:'100%'}} />
                  </Form.Item> 
                </Col>
              </Row>              
              <Row>
                <Col xs={{span: 24}} md={{span: 24}}>
                  <Form.Item
                    name={['stock'+i, 'estimated_pasture_intake']}
                    className="isoInputWrapper"
                    label="Estimated pasture intake (kg DM/Hd/day)"
                    defaultValue="1"
                    
                  >
                    <InputNumber size="large" style={{width:'100%'}} disabled={true} />
                  </Form.Item> 
                </Col>
              </Row>              
              <Row>
                <Col xs={{span: 24}} md={{span: 24}}>
                  <Form.Item
                    name={['stock'+i, 'maintenance_energy']}
                    className="isoInputWrapper"
                    label="Maintenance energy required (MJ ME/Hd/day) "

                  >
                    <InputNumber size="large" value='0' style={{width:'100%'}} disabled={true}/>
                  </Form.Item> 
                </Col>
              </Row>
            </div>
          </Panel>
        </Collapse>
      )
    }
    return(
      <>{stock}</>
    )

  }


  return(
    <PlanWrapModal
      open={props.visiblePlanModal}
      onCancel={props.cancelPlanModal}
      maskClosable={true}
      title="Add Plan"
      footer={[
        <Button key="back" onClick={props.cancelPlanModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary" onClick={handleSubmitPlan} loading={loading}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
        <Form
          form={planForm}
            name="addPlan"
            layout="vertical"
            scrollToFirstError
            fields={fields}
          >
          <div className="form-body">
          <Row>
              <Col xs={{span: 24}} md={{span: 24}}>
                  <Form.Item
                    name={['paddock', 'simple_pasture_budgets']}
                    className="isoInputWrapper"
                    label="Simple pasture budgets"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                  >
                    {renderSimplePasture()}
                  </Form.Item> 
              </Col>
            </Row>   
            <Row>
              <Col xs={{span: 24}} md={{span: 24}}>
                  <Form.Item
                    name={['paddock', 'name']}
                    className="isoInputWrapper"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                  >
                  <Input size="large" placeholder="Name"/>
                  </Form.Item> 
              </Col>
            </Row>    
            <Row>
              <Col xs={{span: 24}} md={{span: 24}}>
                  <Form.Item
                    name={['paddock', 'date']}
                    className="isoInputWrapper"
                    label="Date"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                  >
                    <DatePicker size="large" style={{width: '100%'}} format={dateFormat}/>
                  </Form.Item> 
              </Col>
            </Row>
            <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
                return (currentValues['paddock']);
            }}
            >
            {({ getFieldValue }) => {
              if(getFieldValue('paddock')){
                if (getFieldValue('paddock').simple_pasture_budgets !== "howManyHectares" ){
                  return (      
                    <Row>
                      <Col xs={{span: 24}} md={{span: 24}}>
                        <AddonInput>
                          <Form.Item
                            name={['paddock', 'size']}
                            className="isoInputWrapper"
                            label="Paddock Size"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            <InputNumber size="large" style={{width:'100%'}} />
                          </Form.Item> 
                          <SuffixAddon>ha</SuffixAddon>
                        </AddonInput>
                      </Col>
                    </Row>
                  )
                  }
                }
              }
            } 
            </Form.Item>             
            <Row>
              <Col xs={{span: 24}} md={{span: 24}}>
                <AddonInput>
                  <Form.Item
                    name={['paddock', 'foo']}
                    className="isoInputWrapper"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                    label={<div>Current feed on offer (FOO) <Popover placement="topLeft" 
                    content={table8}> <InfoCircleOutlined /></Popover></div>}
                  >
                    {renderFoo()}
                  </Form.Item> 
                  <SuffixAddon>Kg DM/ha</SuffixAddon>
                </AddonInput>
              </Col>
            </Row>       
            <Row>
              <Col xs={{span: 24}} md={{span: 24}}>
                  <Form.Item
                    name={['paddock', 'pasture_quality']}
                    className="isoInputWrapper"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                    label={<div>Pasture quality <Tooltip placement="topLeft" title={tooltipPastureQuality}><InfoCircleOutlined /></Tooltip></div>}
                  >
                  {renderPastureQuality()}
                  </Form.Item> 
              </Col>
            </Row>       
            <Row>
              <Col xs={{span: 24}} md={{span: 24}}>
                <AddonInput>
                  <Form.Item
                    name={['paddock', 'consumption_wastage_factor']}
                    className="isoInputWrapper"
                    label="Consumption wastage factor"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                  >
                    <InputNumber size="large" style={{width:'100%'}} />
                  </Form.Item> 
                  <SuffixAddon>%</SuffixAddon>
                </AddonInput>
              </Col>
            </Row>       
            <Row>
              <Col xs={{span: 24}} md={{span: 24}}>
                <AddonInput>
                  <Form.Item
                    name={['paddock', 'pasture_growth']}
                    className="isoInputWrapper"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                    label={<div>Pasture growth <Popover placement="topLeft" 
                    content={table9}> <InfoCircleOutlined /></Popover></div>}
             
                  >
                    <InputNumber size="large" style={{width:'100%'}} />
                  </Form.Item> 
                  <SuffixAddon>Kg DM/ha/day</SuffixAddon>
                </AddonInput>
              </Col>
            </Row>       
            <Row>
              <Col xs={{span: 24}} md={{span: 24}}>
                <AddonInput>
                  <Form.Item
                    name={['paddock', 'desired_foo']}
                    className="isoInputWrapper"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                    label={<div>Desired FOO when animals removed <Popover placement="topLeft" 
                    content={table7}> <InfoCircleOutlined /></Popover></div>}
                  >
                    <InputNumber size="large" style={{width:'100%'}} />
                  </Form.Item> 
                  <SuffixAddon>Kg DM/ha</SuffixAddon>
                </AddonInput>
              </Col>
            </Row>
            <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
                return (currentValues['paddock']);
            }}
            >
            {({ getFieldValue }) => {
            if(getFieldValue('paddock')){
              if (getFieldValue('paddock').simple_pasture_budgets === "howManyHectares" ){
                return (
                
                    <Form.Item
                      name={['paddock', 'number_days_paddock']}
                      className="isoInputWrapper"
                      label="Number of days animals are in the paddock"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                        },
                      ]}
                     
                    >
                      <InputNumber size="large" style={{width:'100%'}} />
                    </Form.Item> 
                )
                }
               }
              } 
            }
            </Form.Item>
            {renderStockPaddock()}

             <br />
            <Row>
              <Button key="submit" className="btn-success" type="primary" onClick={handleCalculator}>
                Calculator
              </Button>
            </Row>
            <br />
            <h4>Results</h4>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                  return (currentValues['paddock']);
              }}
            >
            {({ getFieldValue }) => {
              if(getFieldValue('paddock')){
                if (getFieldValue('paddock').simple_pasture_budgets === "howLongWill" ){
                  return (
                    <div>
                      <Row>
                        <Col xs={{span: 24}} md={{span: 24}}>
                            <Form.Item
                              name={['result', 'foo']}
                              className="isoInputWrapper"
                              label="Feed on offer (Kg DM/ha)"
                            >
                              <InputNumber size="large" style={{width:'100%'}} disabled={true} />
                            </Form.Item> 
                        </Col>           
                        <Col xs={{span: 24}} md={{span: 24}}>
                            <Form.Item
                              name={['result', 'daily_intake']}
                              className="isoInputWrapper"
                              label="Daily intake per ha + wastage (Kg DM/ha/day)"
                            >
                              <InputNumber size="large" style={{width:'100%'}} disabled={true} />
                            </Form.Item> 
                        </Col>           
                        <Col xs={{span: 24}} md={{span: 24}}>
                            <Form.Item
                              name={['result', 'change_foo']}
                              className="isoInputWrapper"
                              label="Change in feed on offer (Kg DM/ha/day)"
                            >
                              <InputNumber size="large" style={{width:'100%'}} disabled={true} />
                            </Form.Item> 
                        </Col> 
                        <Col xs={{span: 24}} md={{span: 24}}>
                            <Form.Item
                              name={['result', 'number_days_will_last']}
                              className="isoInputWrapper"
                              label="Number of days paddock will last"
                            >
                              <InputNumber size="large" style={{width:'100%'}} disabled={true} />
                            </Form.Item> 
                        </Col>
                      </Row>
                  </div>
              )
              }else if(getFieldValue('paddock').simple_pasture_budgets ===  'howMuchFeed'){
                return(
                  <div>
                    <Row>
                        <Col xs={{span: 24}} md={{span: 24}}>
                            <Form.Item
                              name={['result', 'daily_intake']}
                              className="isoInputWrapper"
                              label="Daily intake per ha + wastage (Kg DM/ha/day)"
                            >
                              <InputNumber size="large" style={{width:'100%'}} disabled={true} />
                            </Form.Item> 
                        </Col>           
                        <Col xs={{span: 24}} md={{span: 24}}>
                            <Form.Item
                              name={['result', 'growth_minus_intake']}
                              className="isoInputWrapper"
                              label=" Growth minus intake (KG DM/ha/day)"
                            >
                              <InputNumber size="large" style={{width:'100%'}} disabled={true} />
                            </Form.Item> 
                        </Col> 
                        <Col xs={{span: 24}} md={{span: 24}}>
                            <Form.Item
                              name={['result', 'number_days_paddock']}
                              className="isoInputWrapper"
                              label="Number of days in this paddock"
                            >
                              <InputNumber size="large" style={{width:'100%'}} onChange={onChangeNumberOfDay} />
                            </Form.Item> 
                        </Col>         
                        <Col xs={{span: 24}} md={{span: 24}}>
                            <Form.Item
                              name={['result', 'estimated_foo']}
                              className="isoInputWrapper"
                              label={"Estimated feed on offer in "+estimatedFeed+" days (Kg/ha)"}
                            >
                              <InputNumber size="large" style={{width:'100%'}} disabled={true} />
                            </Form.Item> 
                        </Col>
                      </Row>
                  </div>
                )
              }else if(getFieldValue('paddock').simple_pasture_budgets ===  'howManyHead'){
                return(
                  <div>
                    <Row>
                        <Col xs={{span: 24}} md={{span: 24}}>
                            <Form.Item
                              name={['result', 'pasture_intake_waste']}
                              className="isoInputWrapper"
                              label="Pasture intake + waste (Kg DM/hd/day)"
                            >
                              <InputNumber size="large" style={{width:'100%'}} disabled={true} />
                            </Form.Item> 
                        </Col>           
                        <Col xs={{span: 24}} md={{span: 24}}>
                            <Form.Item
                              name={['result', 'number_days_paddock']}
                              className="isoInputWrapper"
                              label="Number of days in this paddock"
                            >
                              <InputNumber size="large" style={{width:'100%'}} />
                            </Form.Item> 
                        </Col>         
                        <Col xs={{span: 24}} md={{span: 24}}>
                            <Form.Item
                              name={['result', 'number_head']}
                              className="isoInputWrapper"
                              label="Number of head you can run in this paddock"
                            >
                              <InputNumber size="large" style={{width:'100%'}} disabled={true} />
                            </Form.Item> 
                        </Col>
                      </Row>
                  </div>
                )
              }else if(getFieldValue('paddock').simple_pasture_budgets ===  'howManyHectares'){
                return(
                  <div>
                    <Row>
                        <Col xs={{span: 24}} md={{span: 24}}>
                            <Form.Item
                              name={['result', 'total_pasture_intake_waste']}
                              className="isoInputWrapper"
                              label="Total pasture intake + wastage (Kg DM/ha/day)"
                            >
                              <InputNumber size="large" style={{width:'100%'}} disabled={true} />
                            </Form.Item> 
                        </Col>           
                        <Col xs={{span: 24}} md={{span: 24}}>
                            <Form.Item
                              name={['result', 'total_growth_minus_foo']}
                              className="isoInputWrapper"
                              label=" Total growth minus change in FOO (KG DM/ha)"
                            >
                              <InputNumber size="large" style={{width:'100%'}} disabled={true} />
                            </Form.Item> 
                        </Col>         
                        <Col xs={{span: 24}} md={{span: 24}}>
                            <Form.Item
                              name={['result', 'total_area']}
                              className="isoInputWrapper"
                              label="Total area required (hectares)"
                            >
                              <InputNumber size="large" style={{width:'100%'}} disabled={true} />
                            </Form.Item> 
                        </Col>
                      </Row>
                  </div>
                )
              }
             }
            } 
          }
  
          </Form.Item>
          </div>
        </Form>

    </PlanWrapModal>
  )
}

export default AddPlanModal;
const SuffixAddon = styled.span`
  margin-top: 31px;
  margin-bottom: 5px;
  line-height: 38px;
  color: #747784!important;
  flex-shrink: 0;
  border-top-right-radius: 4px!important;
  border-bottom-right-radius: 4px!important;
  border-right-width: 1px!important;
  min-width: 50px;
  background-color: #f8f9fa;
  border: solid #d9d9d9;
  border-width: 1px 0;
  white-space: nowrap;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
`;
const AddonInput = styled.div`
  display: flex;
  align-items: flex-start;
  .ant-row{
    width:100%;
  }
`;
