import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Row, Col, Radio, Checkbox, DatePicker, Menu, Popconfirm, Dropdown } from 'antd';
import {
  PlusOutlined, EllipsisOutlined, EditOutlined, EyeOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { qualificationService } from '@iso/services';
import userActions from '@iso/redux/user/actions';
import IntlMessages from '@iso/components/utility/intlMessages';
import QualificationsWrapper from './Qualifications.styles';
import Table from '@iso/components/collapsing-table/table';
import Box from '@iso/components/utility/box';
import QualificationModal from './QualificationModal';
import _ from 'lodash';
import moment from 'moment';
import LicenceViewModal from './LicenceViewModal';
const { store } = userActions;

const Qualifications = (props) => {
  const [qualificationTrade, setQualificationTrade] = useState([]);
  const [qualificationCourse, setQualificationCourse] = useState([]);
  const [qualificationFirstAid, setQualificationFirstAid] = useState([]);
  const [qualificationData, setQualificationData] = useState({});
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const [fields, setFields] = useState([]);
  const currentUser = useSelector((state) => state.user.user);
  const [visibleQualification, setVisibilityQualification] = React.useState(false);
  const [editQualification, setEditQualification] = useState(false);
  const [title, setTitle] = useState("Add Qualifications")
  const [viewSummary, setViewSummary] = useState(false);
  const user_id = props.userId || '';
  const form_id = props.formId || '';
  const isDisableForm = user_id && form_id ? true : false
  useEffect(() => {
    updateQualificationData();
  }, [currentUser.id, user_id, form_id,]);
  
  const menuList = (qualification, permission) => (
    <Menu onClick={handleMenuClick.bind(this, qualification)}>
      <Menu.Item key="editDetails" icon={<EditOutlined />}>
        <IntlMessages id="propertyPage.managePage.editDetails" />
      </Menu.Item>
      <Menu.Item key="viewDetails" icon={<EyeOutlined />}>
        <IntlMessages id="View Details" />
      </Menu.Item>
      <p style={{ cursor: "pointer" }} className="deleteButton">
        <Popconfirm
          placement="bottomRight"
          title="You are about to delete individual record"
          onConfirm={() => {
            confirmDelete(qualification);
          }}
          okText="Yes"
          cancelText="No"
          className="delete-button"
        >
          <a style={{ color: "rgba(0, 0, 0, 0.65)", marginLeft: "11px" }}>
            {<DeleteOutlined />}&nbsp;&nbsp;
            <IntlMessages id="Remove" />
          </a>
        </Popconfirm>
      </p>
    </Menu>
  );
  const confirmDelete = (qualification) => {
    if (qualification.id) {
      qualificationService.destroy(qualification.id).then((res) => {
        if (res.code === "0000") {
          updateQualificationData();
        }
      });
    }
  };
  const handleMenuClick = (qualification, e) => {


    if (e.key === "viewDetails") {

      setViewSummary(true);
      setQualificationData(qualification);
      setTitle('Qualifications')
    }
    if (e.key === "editDetails") {
      showEditModal(qualification)

    }
  }
  const updateQualificationData = () => {
    if(user_id !== undefined && user_id !== "" && form_id !== undefined && form_id !== ""){
      qualificationService.getCheckinFormUserQualification(form_id, user_id, 'trade').then(res => {
        if(res.code === '0000'){
          setQualificationTrade(res.qualifications);
        }
      });
       qualificationService.getCheckinFormUserQualification(form_id, user_id, 'course').then(res => {
        if(res.code === '0000'){
          setQualificationCourse(res.qualifications);
        }
      });
       qualificationService.getCheckinFormUserQualification(form_id, user_id, 'first_aid').then(res => {
        if(res.code === '0000'){
          setQualificationFirstAid(res.qualifications);
        }
      });
    }else if (currentUser) {
      qualificationService.getList(currentUser.id, 'trade').then(res => {
        if (res.code === '0000') {
          setQualificationTrade(res.qualifications);
        }
      });
      qualificationService.getList(currentUser.id, 'course').then(res => {
        if (res.code === '0000') {
          setQualificationCourse(res.qualifications);
        }
      });
      qualificationService.getList(currentUser.id, 'first_aid').then(res => {
        if (res.code === '0000') {
          setQualificationFirstAid(res.qualifications);
        }
      });
    }
  }

  const showModal = () => {
    setVisibilityQualification(true);
  }

  const showEditModal = (qualification) => {
    setEditQualification(true);
    setQualificationData(qualification);
    setVisibilityQualification(true);
    setTitle("Edit Qualifications")
  }

  const cancelModal = () => {
    setVisibilityQualification(false);
    setEditQualification(false);
    setQualificationData({});
  }

  const onSaved = () => {
    updateQualificationData();
  }

  const columns = [
    {
      label: <IntlMessages id="profilePage.qualificationsTab.table.name" />,
      accessor: 'name',
      minWidth: 100,
      sortable: false,
      position: 1,
      priorityLevel: 1
    },
    {
      label: <IntlMessages id="profilePage.qualificationsTab.table.completion" />,
      accessor: 'completion',
      minWidth: 100,
      sortable: false,
      position: 2,
      priorityLevel: 2,
      CustomComponent: cell => {
        let qualification = cell.row
        return (qualification.completion) ? moment(qualification.completion).format('L') : ''
      }
    },
    {
      label: <IntlMessages id="profilePage.qualificationsTab.table.institution" />,
      accessor: 'institution',
      minWidth: 100,
      sortable: false,
      position: 3,
      priorityLevel: 3
    },
    {
      label: "",
      accessor: "dot-menu",
      minWidth: 100,
      sortable: true,
      position: 4,
      priorityLevel: 4,
      noTitle: true,
      CustomComponent: (cell) => {
        let qualification = cell.row
        // let permission = permissionService.getPermission(row.permission_id);
        return (
          <Dropdown overlay={menuList(qualification)} trigger={["click"]}>
            <div
              className="property-area-dot-btn"
              style={{ cursor: "pointer" }}
            >
              <EllipsisOutlined className="dotIcon"/>
            </div>
          </Dropdown>
        );
      },
    },

  ];
  const columns_first_aid = [
    {
      label: <IntlMessages id="profilePage.qualificationsTab.table.name" />,
      accessor: 'name',
      minWidth: 100,
      sortable: false,
      position: 1,
      priorityLevel: 1
    },
    {
      label: <IntlMessages id="profilePage.qualificationsTab.table.completion" />,
      accessor: 'completion',
      minWidth: 100,
      sortable: false,
      position: 2,
      priorityLevel: 2,
      CustomComponent: cell => {
        let qualification = cell.row
        return (qualification.completion) ? moment(qualification.completion).format('L') : ''
      }
    },
    {
      label: <IntlMessages id="profilePage.qualificationsTab.table.institution" />,
      accessor: 'institution',
      minWidth: 100,
      sortable: false,
      position: 3,
      priorityLevel: 3
    },
    {
      label: <IntlMessages id="profilePage.qualificationsTab.table.dateExpiry" />,
      accessor: 'date_expiry',
      minWidth: 200,
      sortable: false,
      position: 4,
      priorityLevel: 4,
      CustomComponent: cell => {
        let qualification = cell.row
        return (qualification.date_expiry) ? moment(qualification.date_expiry).format('L') : ''
      }
    },
    {
      label: "",
      accessor: "dot-menu",
      minWidth: 100,
      sortable: true,
      position: 6,
      priorityLevel: 6,
      noTitle: true,
      CustomComponent: (cell) => {
        let qualification = cell.row
        // let permission = permissionService.getPermission(row.permission_id);
        return (
          <Dropdown overlay={menuList(qualification)} trigger={["click"]}>
            <div
              className="property-area-dot-btn"
              style={{ cursor: "pointer" }}
            >
              <EllipsisOutlined className="dotIcon"/>
            </div>
          </Dropdown>
        );
      },
    },

  ];

  return (
    <>
      <QualificationsWrapper
        style={{ padding: '15px' }}
       
      >
        <div className="form-title" >
          <h2>{<IntlMessages id="profilePage.managePage.qualificationsTab" />}</h2>
          <Button icon={<PlusOutlined />} type="primary" className="AddQualification" onClick={showModal}>
            <IntlMessages id="profilePage.licencesTab.addQualification" />
          </Button>

        </div>
        <div className="form-title">
          <h3>{<IntlMessages id="profilePage.qualificationsTab.type.trade" />}</h3>
        </div>
        <Box id="containerBox">
          {qualificationTrade.length > 0 ?
            <Table
              columns={columns}
              rows={qualificationTrade}
              containerId="containerBox"
              showPagination={false}
              rowSize={99999}
            /> : <h4 ><IntlMessages id="dashboardPage.noData" /></h4>
          }
        </Box>
        <div className="form-title">
          <h3>{<IntlMessages id="profilePage.qualificationsTab.type.courses" />}</h3>
        </div>
        <Box id="containerBox">
          {qualificationCourse.length > 0 ?
            <Table
              columns={columns}
              rows={qualificationCourse}
              containerId="containerBox"
              showPagination={false}
              rowSize={99999}
            /> : <h4><IntlMessages id="dashboardPage.noData" /></h4>
          }
        </Box>
        <div className="form-title">
          <h3>{<IntlMessages id="profilePage.qualificationsTab.type.first_aid" />}</h3>
        </div>
        <Box id="containerBox">
          {qualificationFirstAid.length > 0 ?
            <Table
              columns={columns_first_aid}
              rows={qualificationFirstAid}
              containerId="containerBox"
              showPagination={false}
              rowSize={99999}
            /> : <h4><IntlMessages id="dashboardPage.noData" /></h4>
          }
        </Box>
        <QualificationModal
          visibleQualification={visibleQualification} cancelModal={cancelModal} onSaved={onSaved}
          editQualification={editQualification} qualificationData={qualificationData}
          viewSummary={viewSummary}
          setViewSummary={setViewSummary}
          title={title}
        />
      
      </QualificationsWrapper>

    </>
  );
}

export default Qualifications;