import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Layout, Input } from "antd";
import appActions from "@iso/redux/app/actions";
import TopbarUser from "./TopbarUser";
import TopbarWrapper from "./Topbar.styles";
import TopbarSearch from "./TopbarSearch";
import IntlMessages from "@iso/components/utility/intlMessages";
import { SearchOutlined ,CloseCircleOutlined} from "@ant-design/icons";
import { profileService, pushnotification } from "@iso/services";
import Notification from "./Notification";
import Setting from "./Setting";
import Hamburger from "./Hamburger";
import ListNotification from "./ListNotification";
// import { useHistory } from "react-router";
import { useHistory } from 'react-router-dom';
import SharingMapModal from "./SharingMapModal";
import SARModal from "./SARModal";


import FormsModal from "./FormsModal";

const { Header } = Layout;
const { toggleCollapsed, toggleChangeText } = appActions;
const { Search } = Input;
const URL =
  process.env.REACT_APP_WEB_SOCKET_PROTOCOL +
  process.env.REACT_APP_WEB_SOCKET_URL;

export default function Topbar(props) {
  const history = useHistory();
  //const [isToggle, setIsToggle] = React.useState(false);
  const [, setSelectedItem] = React.useState("");

  const customizedTheme = useSelector(
    (state) => state.ThemeSwitcher.topbarTheme
  );
  const textTopbar = useSelector((state) => state.App.textTopBar);
  const { collapsed, openDrawer } = useSelector((state) => state.App);
  const [profileData, setProfileData] = React.useState({});
  const [modalSAROpen, setModalSAROpen] = React.useState(false);
  const currentUser = useSelector((state) => state.user.user);
  const [isShowSharingMapModal, setIsShowSharingMapModal] = useState(false);
  const [searchInput,setsearchInput]= React.useState('');
  const [openFormModal,setopenFormModal] = React.useState(false);
  
const { changeCurrent} = appActions;
  const activePropertyId = useSelector(
    (state) => state.property.activePropertyId
  );
  const notificationData = useSelector(
    (state) => state.notification.notificationData
  );
  const [countNotification, setCountNotification] = useState(0);
  const [dataNotification, setDataNotification] = useState([]);
  const [ws, setWs] = useState();
  const dispatch = useDispatch();
  const [toggleNoti, setToggleNoti] = useState(false);
  const handleToggle = React.useCallback(() => {
    dispatch(toggleCollapsed());
    dispatch(toggleChangeText());
  }, [dispatch]);

  useEffect(() => {
    setWs(new WebSocket(URL));
  }, []);

  const isCollapsed = collapsed && !openDrawer;
  const styling = {
    background: customizedTheme.backgroundColor,
    position: "fixed",
    height: 70,
  };
  const [readyState, setReadySate] = useState();

  useEffect(() => {
    if (activePropertyId && currentUser) {
      updateProfileData();
      getDataNotification();
    }
  }, [currentUser, activePropertyId]);

  const updateProfileData = () => {
    profileService.getProfile().then((res) => {
      if (res.code === "0000") {
        setProfileData(res.user);
      }
    });
  };

  const handleSearch = (e) => {
    if (e.key == "Enter") {
      history.push("/search?s=" + searchInput);
    }
  };
const handleCancelSearch = () => {

  setsearchInput('')
  history.goBack();
  
    dispatch(changeCurrent(['dashboard']));
}
  const isMap = window.location.pathname.split("/").slice(-1)[0] == "map";

  useEffect(() => {
    if (Object.keys(notificationData).length > 0) {
      const data = {
        notification: {
          property_id: activePropertyId,
          user_id: currentUser.id,
          type: notificationData.type,
          data: notificationData.data,
        },
      };
      if (notificationData.id) {
        pushNotification(data, notificationData.id);
      } else {
        pushNotification(data, "");
      }
    }
  }, [notificationData]);

  const getDataNotification = () => {
    pushnotification.getData(activePropertyId, currentUser?.id).then((res) => {
      if (res.code === "0000") {
        setDataNotification(res.notification);
        setCountNotification(res.notification.length);
      }
    });
  };

  const pushNotification = (notificationData, id) => {
    pushnotification.storeOrUpdate(notificationData, id).then((res) => {
      if (res.code === "0000") {
        webSocket();
        setDataNotification(res.notification);
      }
    });
  };

  // websocket
  const webSocket = () => {
    // const data = {
    //   "notification":{
    //     "property_id": activePropertyId,
    //     "user_id": currentUser.id,
    //   }
    // }

    // ws.send(JSON.stringify(data));
    ws.send("new notification");
  };

  useEffect(() => {
    if (ws) {
      ws.onopen = function (e) {
       
      };

      ws.onmessage = (e) => {
        if (e.data) {
          getDataNotification();
        }
      };

      return () => {
        ws.onclose = () => {
        
          setWs(new WebSocket(URL));
        };
      };
    }
  }, [ws, dataNotification]);

  const showSharingMapModal = () => {
    setIsShowSharingMapModal(true);
  };

  const cancelSharingMapModal = () => {
    setIsShowSharingMapModal(false);
  };
  const handleOpenFormModal = () =>{
    setopenFormModal(true)
  }

  return (
    <>
    <TopbarWrapper>
      <Header
        style={styling}
        className={
          isCollapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"
        }
      >
        <div className="isoLeft">
          <button
            className={
              isCollapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"
            }
            style={{ color: customizedTheme.textColor }}
            onClick={handleToggle}
          />
           <Input
            placeholder="Search…"
            suffix={!searchInput &&<SearchOutlined className="site-form-item-icon"  />}
            prefix={searchInput && <CloseCircleOutlined onClick={handleCancelSearch} className="site-form-item-cancel" />}
            size="large"
            className="search-bar"
            value={searchInput}
          onChange={(e) => setsearchInput(e.target.value)}
            onKeyDown={handleSearch}
          />
        </div>

        <ul className="isoRight">
          <li onClick={() => setModalSAROpen(true)}>
          <img src={process.env.PUBLIC_URL + "./images/icons/Setting.svg"} className="arrowImg" alt="sdfsdf"></img>
          </li>
          <li className="head-bar-notificication">
            <Notification
              setToggleNoti={setToggleNoti}
              toggleNoti={toggleNoti}
            />
            <span className="count-notification">{countNotification}</span>
            {toggleNoti && (
              <ListNotification
                getDataNotification={getDataNotification}
                dataNotification={dataNotification}
                currentUser={currentUser}
              />
            )}
          </li>
          <li >
          <img src={process.env.PUBLIC_URL + "./images/icons/form.png"} height ='20px'
          width='20px' alt="form"
          onClick={handleOpenFormModal}
          ></img>
          </li>
          {/* <li onClick={showSharingMapModal}>
            <Setting />
          </li> */}
          {/* <li onClick={() => setModalSAROpen(true)}>
          <img src={process.env.PUBLIC_URL + "./images/icons/Setting.svg"} className="arrowImg" alt="sdfsdf"></img>
        
          </li> */}
          <li className="isoUserName">
            {profileData.name ? profileData.name : ""}
          </li>
          <li onClick={() => setSelectedItem("user")} className="isoUser">
            <TopbarUser />
          </li>
          {/* <li>
            <Hamburger />
          </li> */}
        </ul>
      </Header>
        <SARModal
        modalSAROpen={modalSAROpen}
        setModalSAROpen={setModalSAROpen}
        />
      <SharingMapModal
        isShowSharingMapModal={isShowSharingMapModal}
        cancelSharingMapModal={cancelSharingMapModal}
      />
      {openFormModal ? 
  <FormsModal  openFormModal= {openFormModal}
  setopenFormModal = {setopenFormModal}
  />:''}
    </TopbarWrapper>


  </>
  );
}
