import React,{useEffect,useState} from 'react';
import { Col, Row, Slider,Popover,Button } from 'antd';

const WindSpeedControl = (props) => {
  const {mapgl,visibleWindSpeed,setVisibleWindSpeed,rasterOpacityWindSpeed,setRasterOpacityWindSpeed} = props;

  useEffect(() => {
    if(mapgl){
      console.log('control-visibleWindSpeed',visibleWindSpeed);
      mapgl.setLayoutProperty('wind-speed','visibility', visibleWindSpeed ? 'visible' : 'none');
      mapgl.setPaintProperty(
        'wind-speed',
        'raster-opacity',
          parseInt(rasterOpacityWindSpeed, 10) / 100
      );
    }
  },[visibleWindSpeed,rasterOpacityWindSpeed]);

  const content = (
    <Row>
      {visibleWindSpeed &&
      <Col span={21}>
        <Slider
          min={0}
          max={100}
          onChange={(value) =>setRasterOpacityWindSpeed(value)}
          value={ rasterOpacityWindSpeed ? rasterOpacityWindSpeed : 0}
        />
      </Col>
      }
      {visibleWindSpeed &&
        <Col span={3}> {rasterOpacityWindSpeed} </Col>
      }
    </Row>
  );

  return (
     <Popover 
      content={content} 
      title="Wind Speed" 
      className={visibleWindSpeed ? 
      "mapboxgl-ctrl-active" :
      ""}>
      <Button 
        className="mapboxgl-ctrl-icon mapboxgl-ctrl-wind-speed"
        onClick={() => setVisibleWindSpeed(setVisibleWindSpeed => !setVisibleWindSpeed)}>
      </Button>
    </Popover>

  )
}
export default WindSpeedControl;