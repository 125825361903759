
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Form, Input, Button, Row, Col, Select, DatePicker } from "antd";
// import { visitorService } from "@iso/services";
import { visitorService } from "../../../services/visitor.service";
import IntlMessages from "@iso/components/utility/intlMessages";
import VisitorModalWrapper from "../Visitor/VisitorModal.styles";
import { Checkbox, Radio } from "antd";
import { Map, TileLayer, FeatureGroup, Polygon, Tooltip, ZoomControl, Marker, Popup } from 'react-leaflet';
import { Form, Input, Button, Select, Row, Col, DatePicker, Card, Timeline, Upload, message } from 'antd';
import _ from "lodash";
import HazardViewModal from '../Hazards/HazardViewModal.styles'
import moment from "moment";


const { Option } = Select;

const VisitorViewSummary = (props) => {
    const { editHazard } = props;

 
const cancelModal = () => {
    props.setVisibleViewSummaryForm(false);
}



                
  return (

    <HazardViewModal
    className='visitor-modal'
    forceRender={true}
    open={props.visibleViewSummaryForm}
    title={<IntlMessages id="Hazard Details" />}
    onCancel={cancelModal}
    maskClosable={true}
    footer={[
      <Button key="back" onClick={cancelModal} className="cancel-button">
        {<IntlMessages id="propertyPage.modal.cancel" />}
      </Button>,
      
    ]}
  >
    <div style={{
        paddingLeft:'10px',
        width: "95%", height: "400px"
      }}>
        <br />
        
        <Timeline>
            
           <Timeline.Item color="red" style={{paddingLeft:'10px'}} >
                <p>{moment(props.viewSummary.created_at).format('ll')}</p>
                <Card title={`Hazard`} bordered={true}>
                <Row>
                    <Col xs={{ span: 9 }} md={{ span: 9 }}>
                      <p className="card_content">Hazard Id</p>
                    </Col>
                    <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                      <b className="card_value">{props.viewSummary.email}</b>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={{ span: 9 }} md={{ span: 9 }}>
                      <p className="card_content">Emergency Mobile Number</p>
                    </Col>
                    <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                      <b className="card_value">{props.viewSummary.emergency_mobile_number}</b>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={{ span: 9 }} md={{ span: 9 }}>
                      <p className="card_content">Mobile Number</p>
                    </Col>
                    <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                      <b className="card_value">{props.viewSummary.mobile_number}</b>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={{ span: 9 }} md={{ span: 9 }}>
                      <p className="card_content">Birthday</p>
                    </Col>
                    <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                      <b className="card_value">{moment(props.viewSummary.birthday).format('ll')}</b>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col xs={{ span: 9 }} md={{ span: 9 }}>
                      <p className="card_content">actions</p>
                    </Col>
                    <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                      <b className="card_value">{}</b>
                    </Col>
                  </Row> */}
                </Card>
              </Timeline.Item>
            
        </Timeline>

      </div>
      </HazardViewModal>
  );
};

export default VisitorViewSummary;
