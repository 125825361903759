import React, { useState, useEffect } from 'react';
import FormModalWrapper from './FormModal.styles';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Form, Input, Button, Select, Row, Col } from 'antd';
import _ from 'lodash';
import { surveyQuestionService } from '@iso/services';
import { surveyQuestionTypes } from '@iso/constants/surveyQuestionTypes';

const { Option } = Select;

const QuestionModal = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const [dataQuestion, setDataQuestion] = useState({});
  
  useEffect(() => {

    if(props.questionData)
    {
      setDataQuestion(props.questionData);
      setFields([
        {
          name: ['survey_question', 'title'],
          value: dataQuestion.title,
        },
        {
          name: ['survey_question', 'question_type'],
          value: dataQuestion.question_type,
        },
      ]);
    }
    
  }, [props.visibilityQuestionModal, dataQuestion,props.questionData]);

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
  }

  const onStore = (values) => {
    const data = {
      "survey_question":{
        ...values.survey_question,
        form_id: props.formId
      }
    }

    surveyQuestionService.storeOrUpdate(data, dataQuestion.id).then(res => {
      if(res.code === '0000'){
        cancelModal();
        props.updateQuestionData();

      } else {
        setLoading(false);
      }
    });
  }

  const cancelModal = () => {
    props.cancelQuestionModal();
    form.resetFields();
    setLoading(false);
  }

  const renderOptionsSurveyQuestion = () => {
    let options = [];
    _.forEach(surveyQuestionTypes, (type, index) => {
      options.push(
        <Option key={index} value={type.value}>
            <div>{type.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a question type"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  return (
     <FormModalWrapper
      open={props.visibilityQuestionModal}
      onCancel={cancelModal}
      centered
      title={ <IntlMessages id="formPage.modal.addQuestionModal.title"/> }
      footer={[        
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit} loading={loading}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
    {
    
          <Form
            form={form}
            layout="vertical"
            scrollToFirstError
            fields={fields}
          >
          <div className="form-body">
            <Form.Item
              name={['survey_question', 'title']}
              className="isoInputWrapper"
              label="Title"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              name={['survey_question', 'question_type']}
              className="isoInputWrapper"
              label="Question Type"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              {renderOptionsSurveyQuestion()}
            </Form.Item>
          </div>    
      </Form>
    }
    </FormModalWrapper>
  )
}

export default QuestionModal;