import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import modalActions from '@iso/redux/modal/actions';
import AddLayerModalWrapper from './AddLayerModal.styles';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Button, Form, Input } from 'antd';
import { layerService } from '@iso/services';


const { closeModal } = modalActions;

const AddLayerModal = (props) => {
    const dispatch = useDispatch();
    const modalVisibility = useSelector((state) => state.modal.modalVisibility);
    const modalData = useSelector((state) => state.modal.modalData);
    const [layerForm] = Form.useForm();
    const [fields, setFields] = useState([]);
    const [loadingLayer, setLoadingLayer] = useState(false);

    const cancelModal = () => {
        layerForm.resetFields();
        dispatch(closeModal());
    }

    const handleAddLayerSubmit = () => {
        layerForm
            .validateFields()
            .then(layerValues => {
                setLoadingLayer(true);
                onStoreLayer(layerValues);
            })
            .catch(info => {
                console.log('Validate Failed:', info);
                setLoadingLayer(false);
            });
    }

    const onStoreLayer = (values) => {
        const layer_data = {
          "layer":{
            ...values.layer,
            property_id: props.propertyId
          }
        }
    
        layerService.storeOrUpdate(layer_data).then(res => {
          if(res.code === '0000'){
            var tmp_layers = _.clone(props.listLayer);
            tmp_layers.push(res.layer);
            props.setListLayer(tmp_layers);
            cancelModal();
            layerForm.resetFields();
          }
          setLoadingLayer(false);
        });
    
      }

    useEffect(() => {
        if(modalData.type === 'addLayer'){

        }
    }, [modalData]);

    return (
        <AddLayerModalWrapper
            open={modalVisibility && modalData.type === 'addLayer'}
            onCancel={cancelModal}
            maskClosable={true}
            centered
            // title={<IntlMessages id="propertyPage.modal.layer.addlayer.title" />}
            title={"Add Layer"}
            footer={[
                <Button key="back" onClick={cancelModal} className="cancel-button">
                    {<IntlMessages id="propertyPage.modal.cancel" />}
                </Button>,
                <Button key="submit" onClick={handleAddLayerSubmit} loading={loadingLayer} className="btn-success" type="primary">
                    {<IntlMessages id="propertyPage.modal.save" />}
                </Button>,
            ]}
        >
            <Form
                form={layerForm}
                layout="vertical"
                scrollToFirstError
                fields={fields}
            >
                <div className="form-body">
                    <Form.Item
                        name={['layer', 'layer_name']}
                        className="isoInputWrapper"
                        label="Name"
                        rules={[
                            {
                                required: true,
                                message: 'This field is required',
                                whitespace: true
                            },
                        ]}
                    >
                        <Input size="large" placeholder="Input layer name" />
                    </Form.Item>
                </div>
            </Form>
        </AddLayerModalWrapper>
    )
}

export default AddLayerModal;