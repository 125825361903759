import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';
const getHiddenPrimaryObjectClass = (ids) => {

  const classArr = ids.map((id) => {
    return `.primary-objects.type_id_${id}, .primary-objects-tooltip.type_id_${id}`
  });
  return classArr.join(',');
}

const getHiddenAssetClass = (categories) => {
 
  const classArr = categories.map((category) => {
    return `.custom-icon-marker.asset.asset_category_${category}`
  });
  return classArr.join(',');
}


const PropertyMapPage = styled.div`

.mapcollapsedwidth{
  width: 93.5vw !important;
}
${props => props.hidden_primary_type_ids && props.hidden_primary_type_ids.length ? `
${getHiddenPrimaryObjectClass(props.hidden_primary_type_ids)} {
  display: none; 
}
` : '' }

&.not-show-asset .custom-icon-marker.asset {
  display: none; 
}

${props => props.hidden_asset_categories && props.hidden_asset_categories.length ? `
${getHiddenAssetClass(props.hidden_asset_categories)} {
  display: none !important; 
}
` : '' }

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .leaflet-container{
    height: 100%;
    width:100%;

  }
  button.ant-btn.ant-btn-default.ant-dropdown-trigger.action-button {
    border-color: #f80e46 !important;
}

  .leaflet-container.leaflet-touch.leaflet-fade-anim.leaflet-grab.leaflet-touch-drag.leaflet-touch-zoom{
    border-radius:16px !important;
  }
  .btn-success{
    background-color: #65bb38;
    border-color: #559f2f;
    &:hover{
      opacity: 0.8;
    }
  }
  .button-group{
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .isoComponentTitle{
    margin-bottom: 15px;
  }
  .isoLeftRightComponent{
    padding: 8px 24px;
    background: #fff;
    border-bottom: 1px solid #eaeef1;
  }
  path.primary-objects{
    stroke-width: 2;
    &:hover{
      fill-opacity: 0.4;
      stroke: #fff;
    }
  }
  .primary-objects-tooltip{
    background: transparent;
    color: white;
    &.dark {
      color: #000000!important;
    }
    font-weight: 600;
    border: none;
    box-shadow: none;
  }
 
  .custom-icon-marker{

    width: 44px;
    height: 60px;
  }
  a[title="Clear all layers"]{
    display: none;
  }
 
  &.not-show-area .primary-objects {

    display: none; 
  }
  &.not-show-task .custom-icon-marker.task-marker {
    display: none; 
  }

  &.not-show-mob .custom-icon-marker.mob {
    display: none; 
  }
  &.not-show-asset .custom-icon-marker.asset {
    display: none; 
  }
  .leaflet-draw-toolbar.leaflet-bar.leaflet-draw-toolbar-top{
    bottom:-11.5px;
    position:relative;
  }
  @media (max-width: 768px){
    .leaflet-draw-toolbar.leaflet-bar.leaflet-draw-toolbar-top{
      bottom:-15.5px;
      position:relative;
    }
  }
  .leaflet-draw-edit-edit{
    border-top-left-radius: unset!important;
    border-top-right-radius: unset!important;
  }
  .not-show-area-label.primary-objects-tooltip {
    display: none; 
  }
 
  .leaflet-draw-draw-polygon{
    border-bottom-left-radius: unset!important;
    border-bottom-right-radius: unset!important;
  }

  .numberCircle {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 5px;
    background: #fff;
    border: 2px solid #666;
    color: #666;
    text-align: center;
    font: 10px Arial,sans-serif;
    margin-top: -75px;
    margin-left: 25px;
    z-index: 9;
    position: absolute;
  }
  .reload-button{
    color: #729D68;
    padding: 5px 8px;
  }
  .reload-button .anticon-sync{
    line-height: 0;
  }
  @media only screen and (width: 1440px) {
    .propertyMap {
      height: 64vh !important;
    }
    .weather-map-style > div {
      height: 64vh !important;
    }
   .mapcollapsedwidth {
      width: 92vw !important;
    }
  }
  @media only screen and (width: 1024px) {
    .propertyMap {
      height: 64vh !important;
    }
    .weather-map-style > div {
      height: 64vh !important;
    }
   .mapcollapsedwidth {
      width: 88vw !important;
    }
  }
  @media only screen and (width: 768px) {
    .propertyMap {
      height: 64vh !important;
    }
    .weather-map-style > div {
      height: 64vh !important;
    }
   .mapcollapsedwidth {
      width: 85vw !important;
    }
  }
  @media only screen and (max-width: 425px) {
    .propertyMap {
      height: 64vh !important;
    }
    .weather-map-style > div {
      height: 64vh !important;
    }
   .mapcollapsedwidth {
      width: 88vw !important;
    }
  }
`;

export default WithDirection(PropertyMapPage);