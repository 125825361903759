import React, { useState, useEffect } from "react";
import LayoutWrapper from "@iso/components/utility/layoutWrapper.js";
import PageHeader from "@iso/components/utility/pageHeader";
import Box from "@iso/components/utility/box";
import AssetPage from "./Asset.styles";
import IntlMessages from "@iso/components/utility/intlMessages";
import { configs } from "@iso/components/Tables/configs";
import { Button, Dropdown, Menu, Popconfirm, Input, Pagination } from "antd";
import {
  PlusOutlined,
  DownOutlined,
  EditOutlined,
  SearchOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  EyeOutlined,
  PaperClipOutlined
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import modalActions from "@iso/redux/modal/actions";
import AssetModal from "@iso/containers/Pages/PropertyMap/components/Asset/AssetModal";
import EditAircraftModal from "@iso/containers/Pages/PropertyMap/components/Asset/EditAircraftModal";
import EditFeedModal from "@iso/containers/Pages/PropertyMap/components/Asset/EditFeedModal";
import EditFuelModal from "@iso/containers/Pages/PropertyMap/components/Asset/EditFuelModal";
import EditGeneralAssetModal from "@iso/containers/Pages/PropertyMap/components/Asset/EditGeneralAssetModal";
import EditTreatmentModal from "@iso/containers/Pages/PropertyMap/components/Asset/EditTreatmentModal";
import EditWaterModal from "@iso/containers/Pages/PropertyMap/components/Asset/EditWaterModal";
import EditAnimalModal from "@iso/containers/Pages/PropertyMap/components/Asset/EditAnimalModal";
import EditWeatherModal from "@iso/containers/Pages/PropertyMap/components/Asset/EditWeatherModal";
import { assetService, propertyService } from "@iso/services";
import _ from "lodash";
import moment from "moment";
import Table from "@iso/components/collapsing-table/table";
import styled from "styled-components";
import { assetStates } from "@iso/constants/assetStates";
import SelectPropertyModal from "@iso/containers/Pages/Property/SelectPropertyModal";
import { levelTypes } from "@iso/constants/levelTypes";

import AssetViewSummary from "./AssetViewSummary";
import Loader from "../../../components/collapsing-table/components/Loader";
const { openModal } = modalActions;

const Asset = (props) => {
  const [assets, setAssets] = useState([]);
  const dispatch = useDispatch();
  const activePropertyId = useSelector(
    (state) => state.property.activePropertyId
  );

  // const activeProperty = localStorage.getItem("active_property");
  const activeProperty = JSON.parse(localStorage.getItem("active_property"));

  const activePermission = useSelector(
    (state) => state.permission.activePermission
  );
  const [primaryObjects, setPrimaryObjects] = useState([]);
  const [visibleViewSummary, setVisibleViewSummary] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [visibleAssetTreatment, setVisibilityAssetTreatment] =
    React.useState(false);
  const [visibleAssetFuel, setVisibilityAssetFuel] = React.useState(false);
  const [visibleAssetFeed, setVisibilityAssetFeed] = React.useState(false);
  const [visibleAssetWater, setVisibilityAssetWater] = React.useState(false);
  const [visibleAssetAircraft, setVisibilityAssetAircraft] =
    React.useState(false);
  const [visibleAssetOther, setVisibilityAssetOther] = React.useState(false);
  const [dataAsset, setDataAsset] = useState({});
  const [visibleSelectProperty, setVisiblitySelectProperty] =
    React.useState(false);
  const [searchInput, setsearchInput] = React.useState('');
  const [visibleAssetAnimal, setVisibilityAssetAnimal] = React.useState(false);
  const [visibleAssetWeather, setVisibilityAssetWeather] =
    React.useState(false);
  const [viewSummary, setViewSummary] = React.useState({})
  const [searchQuery, setSearchQuery] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const displayedVisitor = searchQuery?.slice(startIndex, endIndex);
   
  const handlePageSizeChange = (current, size) => {
    setCurrentPage(1);
    setPageSize(size);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const updatePropertyDetail = React.useCallback((id) => {
    setLoadingData(true);
    propertyService.viewProperty(id).then((res) => {
      if (res.code === "0000") {
        setPrimaryObjects(res.primary_objects);
        setLoadingData(false)
          }
    });
  }, []);

  useEffect(() => {
    if (activePropertyId) {
      updatePropertyDetail(activePropertyId);
    }
  }, [activePropertyId]);

  useEffect(() => {
    localStorage.setItem("assetLength", JSON.stringify(assets.length));
    if (props?.handleSafetyAssets) {
      // props.handlOpenmodal(showModal)
      props?.handleSafetyAssets(assets.length);
    }
  }, [assets]);

  useEffect(() => {
    updateAssetData();
  }, [activePropertyId]);

  const updateAssetData = () => {
    setLoadingData(true);
    if (activePropertyId) {
      assetService.getList(activePropertyId).then((res) => {
        if (res.code === "0000") {
          setAssets(res.facilities);
          setSearchQuery(res.facilities);
          setTimeout(() =>{
            setLoadingData(false);
          },3000)
         
        }
      });
    }
  };

  useEffect(() => {
    if (!activeProperty) {
      setVisiblitySelectProperty(true);
    }
  }, []);

  useEffect(() => {
    propertyService.getProperties().then((res) => {
      if (res.properties.length === 0) {
        setVisiblitySelectProperty(true);
      }
    });
  }, [activePropertyId]);

  const handleCancelSearch = () => {
    setsearchInput('')
    setSearchQuery(assets);
  };
  const handleSearch = (e) => {
    setsearchInput(e.target.value)
    // if (e.key === "Enter") {
      const filteredAssets = assets.filter((asset) => {
        const searchTerm = searchInput.toLowerCase();
        return (
          asset.title.toLowerCase().includes(searchTerm) ||
          asset.category_label.toLowerCase().includes(searchTerm) ||
          asset.type_label.toLowerCase().includes(searchTerm) ||
          asset.state_label.toLowerCase().includes(searchTerm) ||
          (asset.primary_object &&
            asset.primary_object.toString().toLowerCase().includes(searchTerm)) ||
          (asset.last_activity_date &&
            moment(asset.last_activity_date)
              .format("DD/MM/YYYY")
              .includes(searchTerm)) ||
          asset.remaining_label.toLowerCase().includes(searchTerm)
        );
      });
      setSearchQuery(filteredAssets);
    // }
  };


  const menu = (asset) => (

    <Menu onClick={handleMenuClick.bind(this, asset)}>
      <Menu.Item key="editDetails" icon={<EditOutlined />}>
        <IntlMessages id="propertyPage.managePage.editDetails" />
      </Menu.Item>

      <Menu.Item key="viewSummary" icon={<EyeOutlined />}>
        <IntlMessages id="View Summary" />
      </Menu.Item>

      <p style={{ cursor: "pointer" }} className="deleteButton">
        <Popconfirm
          placement="bottomRight"
          title="You are about to delete individual record"
          onConfirm={() => {
            confirmDelete(asset);
          }}
          okText="Yes"
          cancelText="No"
          className="delete-button"
        >
          <a style={{ color: "rgba(0, 0, 0, 0.65)", marginLeft: "11px" }}>
            {<DeleteOutlined />}&nbsp;&nbsp;
            <IntlMessages id="propertyPage.managePage.delete" />
          </a>
        </Popconfirm>
      </p>
    </Menu>
  );
  const handleMenuClick = (asset, e) => {
    if (e.key === "editDetails") {
      setDataAsset(asset);
      if (
        asset.type === "animal_treatment" ||
        asset.type === "fertiliser" ||
        asset.type === "vaccine"
      ) {
        setVisibilityAssetTreatment(true);
      }

      if (asset.type === "fuel" || asset.type === "chemical") {
        setVisibilityAssetFuel(true);
      }

      if (asset.type === "stock_feed") {
        setVisibilityAssetFeed(true);
      }

      if (asset.type === "water") {
        setVisibilityAssetWater(true);
      }

      if (asset.type === "aircraft") {
        setVisibilityAssetAircraft(true);
      }

      if (asset.type === "other") {
        setVisibilityAssetOther(true);
      }

      if (asset.category === "animal") {
        setVisibilityAssetAnimal(true);
      }

      if (asset.category === "weather") {
        setVisibilityAssetWeather(true);
      }

      if (
        asset.category !== "inventory" &&
        asset.category !== "animal" &&
        asset.category !== "weather"
      ) {
        setVisibilityAssetOther(true);
      }
    }
    if (e.key === 'viewSummary') {
      setVisibleViewSummary(true)
      setViewSummary(asset)
    }
  };

  const confirmDelete = (del_asset) => {
    if (del_asset.id) {

      assetService.destroy(del_asset.id).then((res) => {
        if (res.code === "0000") {
          updateAssetData();
        }
      });
    }
  };

  const cancelSelectPropertyModal = () => {
    setVisiblitySelectProperty(false);
  };

  const columns = [
    {
      label: <IntlMessages id="assetPage.table.name" />,
      accessor: "title",
      minWidth: 100,
      sortable: true,
      position: 1,
      priorityLevel: 1,
      CustomComponent: (cell) => {
        let asset = cell.row;

        const hasAttachment = asset.attachment; // Replace with your attachment presence condition
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
          
            <div style={{ marginLeft: hasAttachment ? 10 : 0 }}>{asset.title}</div>
            {hasAttachment.length >0 && <PaperClipOutlined style={{color:'rgb(248, 14, 70)'}}/>} {/* Add the PaperclipIcon conditionally */}
          </div>
        );
      },
    },
    {
      label: <IntlMessages id="assetPage.table.category" />,
      accessor: "category_label",
      minWidth: 100,
      sortable: true,
      position: 2,
      priorityLevel: 2,
    },
    {
      label: <IntlMessages id="assetPage.table.assetType" />,
      accessor: "type_label",
      minWidth: 100,
      sortable: true,
      position: 3,
      priorityLevel: 3,
    },
    {
      label: <IntlMessages id="assetPage.table.stateOrLevel" />,
      accessor: "state_label",
      minWidth: 100,
      sortable: true,
      position: 4,
      priorityLevel: 4,
      CustomComponent: (cell) => {
        let asset = cell.row;
        if (asset.category != "water") {
          return assetStates.map((value, index) => {
            const opacity = 0.30;
            const rgbValues = value.color.match(/\d+/g);
            const colorWithOpacity = `rgba(${rgbValues[0]}, ${rgbValues[1]}, ${rgbValues[2]}, ${opacity})`;
            if (value.value == asset.state) {
              return (
                <div className="" style={{ display: "flex", backgroundColor: colorWithOpacity, padding: "5px 10px", borderRadius: '10px', gap: "6px", maxWidth: "130px", alignItems: "center" }}>
                  <ColorItemPreview
                    style={{ backgroundColor: value.color }}
                  ></ColorItemPreview>
                  &nbsp;&nbsp;
                  <div>{value.label}</div>
                </div>
              );
            }
          });
        } else {
          return <div>{asset.state}</div>;
        }
      },
    },

    {
      label: <IntlMessages id="assetPage.table.location" />,
      accessor: "primary_object",
      minWidth: 200,
      sortable: true,
      position: 5,
      priorityLevel: 5,
    },
    {
      label: <IntlMessages id="assetPage.table.lastUsed" />,
      accessor: "last_activity_date",
      minWidth: 200,
      sortable: true,
      position: 6,
      priorityLevel: 6,
      CustomComponent: (cell) => {
        let asset = cell.row;
        return asset.last_activity_date
          ? moment(asset.last_activity_date).format("DD/MM/YYYY")
          : "N/A";
      },
    },
    {
      label: <IntlMessages id="assetPage.table.remaining" />,
      accessor: "remaining_label",
      minWidth: 100,
      sortable: true,
      position: 7,
      priorityLevel: 7,
    },

    {
      label: "",
      accessor: "dot-menu",
      minWidth: 100,
      sortable: true,
      position: 4,
      priorityLevel: 4,
      noTitle: true,
      CustomComponent: (cell) => {
        let row = cell.row;
        // let permission = permissionService.getPermission(row.permission_id);
        return (
          <Dropdown overlay={menu(row)} trigger={["click"]}>
            <div
              className="property-area-dot-btn"
              style={{ cursor: "pointer" }}
            >
              <EllipsisOutlined  className="dotIcon"/>
            </div>
          </Dropdown>
        );
      },
    },
  ];

  const onSaved = (savedAsset) => {
    let tmpAssets = _.clone(assets);
    const Index = _.findIndex(tmpAssets, (asset) => {
      return asset.id === savedAsset.id;
    });

    if (Index === -1) {
      tmpAssets.push(savedAsset);
    } else {
      tmpAssets[Index] = savedAsset;
    }
    setAssets(tmpAssets);
    setSearchQuery(tmpAssets)
    updatePropertyDetail(activePropertyId);
  };

  const showModal = () => {
    const modalData = {
      type: "asset",
      canCreate: true,
    };
    dispatch(openModal(modalData));
  };

  const cancelEditTreatmentModal = () => {
    setVisibilityAssetTreatment(false);
  };

  const cancelEditFuelModal = () => {
    setVisibilityAssetFuel(false);
  };

  const cancelEditFeedModal = () => {
    setVisibilityAssetFeed(false);
  };

  const cancelEditWaterModal = () => {
    setVisibilityAssetWater(false);
  };

  const cancelEditAircraftModal = () => {
    setVisibilityAssetAircraft(false);
  };

  const cancelEditOtherModal = () => {
    setVisibilityAssetOther(false);
  };

  const cancelEditAnimalModal = () => {
    setVisibilityAssetAnimal(false);
  };

  const cancelEditWeatherModal = () => {
    setVisibilityAssetWeather(false);
  };

  return (
    <LayoutWrapper>
      <AssetPage>
        <div className="visiter_table_main">
          <div className="top_title_visiter">
            {/* <Divider type= 'vertical' orientation='right' className="VerticalDivider"/> */}
            <PageHeader>{<IntlMessages id="Safety Asset" />}</PageHeader>

            <div className="button-group">
              <Input
                placeholder="Search…"
                suffix={!searchInput && <SearchOutlined className="site-form-item-icon" />}
                prefix={searchInput && <CloseCircleOutlined onClick={handleCancelSearch} className="site-form-item-cancel" />}
                size="large"
                className="search-bar"
                value={searchInput}
                // onChange={(e) => setsearchInput(e.target.value)}
                // onKeyDown={handleSearch}
                onChange={(e) => handleSearch(e)}
              />
              {/* {renderOptionsProperty()} */}
              {(activePermission == "owner" || activePermission == "admin" &&   activeProperty?.subscription_label !== process.env.REACT_APP_STRIPE_PLAN_GOLD  ) && (
                <Button
                  icon={<PlusOutlined />}
                  type="primary"
                  className="btn-success"
                  onClick={showModal}
                  size="large"
                  style={{
                    background: "rgb(248, 14, 70)",
                    borderRadius: "12px !important",
                  }}
                >
                  <IntlMessages id="assetPage.managePage.addAsset" />
                </Button>
              )}

            </div>
          </div>
          <Box className="table_visiter" id="containerBox">

            {displayedVisitor && displayedVisitor.length > 0 ? (
              <>
                {loadingData ? (
                 <Loader/>
                ) :
                  (<><Table
                    columns={columns}
                    rows={displayedVisitor}
                    containerId="containerBox"
                    showPagination={false}
                    rowSize={99999}
                  />
                    <div style={{ margin: '20px' }}>
                      <Pagination
                        current={currentPage}
                        total={searchQuery.length}
                        pageSize={pageSize}
                        onChange={handlePageChange}
                        showSizeChanger
                        pageSizeOptions={["10", "20", "50", "100"]}
                        onShowSizeChange={handlePageSizeChange}
                      /></div></>
                  )}
              </>
            ) : (
              <h1 style={{ fontSize: '18px', padding: '15px', fontWeight: '600' }}>
                <IntlMessages id="dashboardPage.noData" />
              </h1>
            )}
          </Box>
        </div>
        <AssetModal
          updateDataAssets={updateAssetData}
          propertyId={activePropertyId}
          primaryObjects={primaryObjects}
          onSaved={onSaved}
        />
        <EditTreatmentModal
          updateDataAssets={updateAssetData}
          propertyId={activePropertyId}
          primaryObjects={primaryObjects}
          onSaved={onSaved}
          visibleAssetTreatment={visibleAssetTreatment}
          dataAsset={dataAsset}
          cancelEditModal={cancelEditTreatmentModal}
        />
        <EditFuelModal
          updateDataAssets={updateAssetData}
          propertyId={activePropertyId}
          primaryObjects={primaryObjects}
          onSaved={onSaved}
          visibleAssetFuel={visibleAssetFuel}
          dataAsset={dataAsset}
          cancelEditModal={cancelEditFuelModal}
        />
        <EditFeedModal
          updateDataAssets={updateAssetData}
          propertyId={activePropertyId}
          primaryObjects={primaryObjects}
          onSaved={onSaved}
          visibleAssetFeed={visibleAssetFeed}
          dataAsset={dataAsset}
          cancelEditModal={cancelEditFeedModal}
        />
        <EditWaterModal
          updateDataAssets={updateAssetData}
          propertyId={activePropertyId}
          primaryObjects={primaryObjects}
          onSaved={onSaved}
          visibleAssetWater={visibleAssetWater}
          dataAsset={dataAsset}
          cancelEditModal={cancelEditWaterModal}
        />
        <EditAircraftModal
          updateDataAssets={updateAssetData}
          propertyId={activePropertyId}
          primaryObjects={primaryObjects}
          onSaved={onSaved}
          visibleAssetAircraft={visibleAssetAircraft}
          dataAsset={dataAsset}
          cancelEditModal={cancelEditAircraftModal}
        />
        <EditGeneralAssetModal
          updateDataAssets={updateAssetData}
          propertyId={activePropertyId}
          primaryObjects={primaryObjects}
          onSaved={onSaved}
          visibleAssetOther={visibleAssetOther}
          dataAsset={dataAsset}
          cancelEditModal={cancelEditOtherModal}
        />
        <EditAnimalModal
          updateDataAssets={updateAssetData}
          propertyId={activePropertyId}
          primaryObjects={primaryObjects}
          onSaved={onSaved}
          visibleAssetAnimal={visibleAssetAnimal}
          dataAsset={dataAsset}
          cancelEditModal={cancelEditAnimalModal}
        />
        <EditWeatherModal
          updateDataAssets={updateAssetData}
          propertyId={activePropertyId}
          primaryObjects={primaryObjects}
          onSaved={onSaved}
          visibleAssetWeather={visibleAssetWeather}
          dataAsset={dataAsset}
          cancelEditModal={cancelEditWeatherModal}
        />

        <AssetViewSummary
          visibleViewSummary={visibleViewSummary}
          setVisibleViewSummary={setVisibleViewSummary}
          viewSummary={viewSummary}

        />
      </AssetPage>

     
    </LayoutWrapper>
  );
};

export default Asset;

const ColorItemRow = styled.div`
  display: flex;
  align-items: center;
  line-height: 30px;
  div {
    margin: 4px;
  }
`;

const ColorItemPreview = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;
