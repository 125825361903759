import styled from "styled-components";
import Modal from "@iso/components/Feedback/Modal";
import WithDirection from "@iso/lib/helpers/rtl";

const AuthenNlisModalWrapper = styled(Modal)`
  &.auth-nlis-modal {
    width: 400px !important;
    margin-top: 200px;
  }
  &..ant-modal-footer{
    display:block;
  }
  
  
  .ant-modal-body {
    padding: 0;
  }
  .btn-success {
    background-color: #65bb38;
    border-color: #559f2f;
    width:100% !important;
  }
  .form-title {
    padding: 16px 24px;
    border-bottom: 1px solid #eaeef1;
    h3 {
      font-size: 1rem;
      color: #f80e46;
    }
  }
  .form-body {
    padding: 12px 24px 12px 24px;
    .checkbox-contact {
      margin-bottom: 10px;
    }
    .nlis-auth-desc {
      margin-bottom: 10px;
    }
  }
`;

export default WithDirection(AuthenNlisModalWrapper);
