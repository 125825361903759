import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import LicenceModalWrapper from './LicenceModal.styles';
import { Form, Input, Button, Select, Row, Col, DatePicker, Upload } from 'antd';
import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import modalActions from '@iso/redux/modal/actions';
import Loader from '@iso/components/utility/loader';
import { workingRightService } from '@iso/services';
import moment from 'moment';
import { licenceTypes } from '@iso/constants/licenceTypes';
import { vehicleLicence } from '@iso/constants/vehicleLicence';
import { machineryLicence } from '@iso/constants/machineryLicence';
import _ from 'lodash';
import styled from "styled-components";
import { dateHelper } from '@iso/lib/helpers/dateHelper';
import L from 'leaflet';
import inside from 'point-in-polygon';
import $ from 'jquery';
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';
import {WorkingRight} from '@iso/constants/WorkingRight'
const { Option } = Select;
const { closeModal } = modalActions;

const WorkingRightModal = (props) => {
  
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [dataLicence, setDataLicence] = useState({});
  const dispatch = useDispatch();
  const modalVisibility = useSelector((state) => state.modal.modalVisibility);
  const modalData = useSelector((state) => state.modal.modalData);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector((state) => state.user.user);
  const [issueDate, setIssueDate] = useState([]);
  const [licenceNames, setlicenceNames] = useState(vehicleLicence);
  const [imageId, setImageId] = useState(null);
  const [workingRightData,setWorkingRightData] = useState();
 
  useEffect(() => {
    setDataLicence(props.licenceData);
    if(props.licenceData.licence_type === 'vehicles'){
      setlicenceNames(vehicleLicence);
    }else if(props.licenceData.licence_type === 'machinery'){
      setlicenceNames(machineryLicence);
    }else{
      setlicenceNames([]);
    }
  }, [props.licenceData, props.visibleLicence]);

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
    setLoading(false);
  }
  

  
  
  const onStore = (values) => {
    
    const data = {
      "working_right":{
        ...values.workingRight,
        type :workingRightData ? workingRightData:'',
        image_id: imageId?imageId:'',
        user_id: currentUser.id,
      
      }
    }

    workingRightService.storeOrUpdate(data, dataLicence.id).then(res => {
      if(res.code === '0000'){
        props.onSaved();
        props.cancelModal();
        form.resetFields();
        setImageId(null)
      } else {
        setLoading(false);
      }
    });
  }
  const cancelLicenceModal = () => {
    if (props.viewSummary) {
      props.setViewSummary(false);
      props.setLicenceData({})
      form.resetFields();
    }else{
    props.cancelModal();
    form.resetFields();
  }} 

  useEffect(() => {
      setFields([
        {
          name: ['workingRight', 'type'],
          value: dataLicence.type || '',
        },
        {
          name: ['workingRight', 'name'],
          value: dataLicence.name || '',
        },
       
      ]);
    
  }, [dataLicence, props.visibleLicence]);

  
  const handleWorkingRight =(value) =>{
    if (value === 0) {
      setWorkingRightData("Working Visa");
    }else{
      setWorkingRightData("Citizen");
    }
  }
  const renderOptionWorkingRight = (WorkingRight) => {
    let options = [];
    _.forEach(WorkingRight, (type, index) => {
      options.push(
        <Option key={index} value={type.value}>
          {type.label}
        </Option>
      );
    });
    return <Select  {...props.viewSummary ?{disabled:true}:{disabled:false}}  placeholder="Select" value={WorkingRight.label} onChange={handleWorkingRight} size="large">{options}</Select>;
  };
  const uploadProps = {
    name: "Image[file]",
    action: apiEndpoint.images,
    headers: authHeader(true),
    beforeUpload: (file, fileList) => {
      let $oldOne = $('.anticon-delete')[0];
      setTimeout(()=>{
        if($oldOne)
          $($oldOne).trigger('click');
      },100);
      
    },
    onChange: (response) => {
      if(response.file && response.file.status == "done"){
        setImageId(response.file.response.image.id);
      }
    },    
    listType: "picture",
    multiple: false
  }

  return(
    <>
    <LicenceModalWrapper
      open={props.viewSummary ?props.viewSummary:props.visibleLicence}
      onCancel={cancelLicenceModal}
      maskClosable={true}
      centered
      title={props.title}
      footer={[        
        <Button
        hidden ={props.viewSummary ? true:false}
        key="back" onClick={cancelLicenceModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button 
        hidden ={props.viewSummary ? true:false}
        key="submit" className="btn-success" type="primary" onClick={handleSubmit} loading={loading}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
  
      <Form
          form={form}
          layout="vertical"
          scrollToFirstError
          fields={fields}
      >
        <div className="form-body">
        {/* {
            dataLicence.images && dataLicence.images.length > 0 && <img style={{width:"100%",marginBottom:'20px'}}src={process.env.REACT_APP_API_BASE_URL + dataLicence.images[dataLicence.images.length-1].path} />
           
          } */}
          
          <Form.Item
            name={['workingRight', 'type']}
            className="isoInputWrapper"
            label="Working Right Type"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
              {renderOptionWorkingRight(WorkingRight)}
          </Form.Item>
     
          <Form.Item
            name={['workingRight', 'name']}
            className="isoInputWrapper"
           
            rules={[
              {
                required: true,
                message: 'This field is required',
                whitespace: true
              },
              {
                max:255,
                message: 'Licence Number must be maximum 255 characters.'
              },
            ]}
          >
            <Input size="large"  {...props.viewSummary ?{readOnly:true}:{readOnly:false}} placeholder="Name"/>
          </Form.Item>
         
          <>
          {props.viewSummary ? '':
          <Form.Item name={['attachment']} label="Attachment" size="large">
            <Upload
              {...uploadProps}
            >
              <Button className="action-button" icon={<UploadOutlined   size="large"/>}>Upload</Button>
            </Upload>
          </Form.Item>}
        
         
         
          </>
          </div>    
    </Form>
     
    </LicenceModalWrapper>
    </>
  )
}

export default WorkingRightModal;