import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form, message, Steps, Card } from "antd";
import { formService } from "@iso/services";
import { visitorFormService } from "../../../services/visitorform.service";
import FormBuilder from "antd-form-builder";
import GlobalFormWrapper from "./GlobalForm.style";
import { formInput } from "@iso/constants/formBuilder";

const { Step } = Steps;

const GlobalForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [globalForm] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [stepsLength, setStepsLength] = useState(0);
  const forceUpdate = FormBuilder.useForceUpdate();

  const [form] = Form.useForm();
  const [metaData, setMetaData] = useState([]);

  useEffect(() => {
    const newMetaData = [];
    const reviewFields = [];

    if (props.formData?.category_global_form_data?.meta_fields) {
      const formFields = props.formData?.category_global_form_data?.meta_fields;
      formFields.forEach((page, pageIndex) => {
        let fields = [];
        page.formInputs &&
          page.formInputs.length &&
          page.formInputs.forEach((input) => {
            input.widget = formInput[input["type"]]["widget"];
            fields.push(input);
          });
        newMetaData.push({
          title: page.title,
          columns: 1,
          fields,
        });
        reviewFields.push(
          {
            key: "review" + pageIndex,
            colSpan: 2,
            render() {
              return (
                <fieldset>
                  <legend>{page.title}</legend>
                </fieldset>
              );
            },
          },
          ...fields
        );
      });
    }
    newMetaData.push({
      title: "Review",
      columns: 2,
      fields: reviewFields,
    });
    setMetaData(newMetaData);
    setStepsLength(newMetaData.length);
  }, [props.formData]);

  const handleFinish = async (values) => {
    const data = {
      form: {
        global_form: values,
        type: "system_forms",
      },
    };

    if (props.visitorForm) {
      const visitorFormValidation = await props.visitorForm.validateFields();
      if (visitorFormValidation.errorFields) {
        return;
      }
      data.visitor = props.visitorForm.getFieldsValue().visitor;
      data.form.visitor_form_token = props.visitorFormToken;
    }
    const handleFn = props.visitorForm
      ? visitorFormService.createVisitorForm(data)
      : formService.storeOrUpdate(data, props.formData.id);
    handleFn.then((res) => {
      if (res.code === "0000") {
        message.success("Submit success");
        props.setShowFinalScreen(true);
        setLoading(false);
      } else {
        message.error(res.message);
        setLoading(false);
      }
    });
  };

  const handleNext = () => {
    globalForm.validateFields().then(() => {
      setCurrentStep(currentStep + 1);
    });
  };
  const handleBack = () => {
    globalForm.validateFields().then(() => {
      setCurrentStep(currentStep - 1);
    });
  };

  return (
    <GlobalFormWrapper>
      <Row>
        <Col xs={{ span: 24 }} md={{ span: 24 }}>
          <Form
            form={globalForm}
            onValuesChange={forceUpdate}
            layout="vertical"
            className="preview-form-area advanced-form"
            onFinish={handleFinish}
          >
            <div className="form-body">
              <h1>{props.formData?.category_global_form_data.name}</h1>
              <p>{props.formData?.category_global_form_data.description}</p>
              <hr />
              <Steps id="form-step" current={currentStep}>
                {metaData && metaData.length ? (
                  metaData.map((page) => (
                    <Step key={page.title} title={page.title} />
                  ))
                ) : (
                  <></>
                )}
              </Steps>
              <Card className="form-content">
              <FormBuilder
                meta={
                  metaData.length && metaData[currentStep]
                    ? metaData[currentStep]
                    : []
                }
                form={form}
              />
              </Card>
              <div className="form-footer" style={{ textAlign: "right" }}>
                {currentStep > 0 && (
                  <Button className="btn-back" onClick={handleBack}>
                    Back
                  </Button>
                )}
                <Button
                  type="primary"
                  loading={loading}
                  onClick={
                    currentStep === stepsLength - 1
                      ? () => globalForm.submit()
                      : handleNext
                  }
                >
                  {currentStep === stepsLength - 1 ? "Submit" : "Next"}
                </Button>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    </GlobalFormWrapper>
  );
};

export default GlobalForm;