import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const AssetModalWrapper = styled(Modal)`
  .btn-success{
    background-color: #65bb38;
    border-color: #559f2f;
    &:hover{
      opacity: 0.8;
    }
  }
  .ant-modal-body{
    padding: 0;
  }
  .form-title{
    padding: 16px 24px;
    border-bottom: 1px solid #eaeef1;
    h3{
      font-size: 1rem;
    }
  }
  .form-body{
    padding: 12px 24px 12px 24px;
    .checkbox-contact{
      margin-bottom: 10px;
    }
  }
  a,.ant-upload .ant-btn:hover,.ant-upload .ant-btn:focus{
    border-color: #559f2f;
    color:#559f2f
  }
  &.mob-cannot-create .ant-btn-primary{
    display: none;
  }
  .btn-create-breed{
    background-color: #46AFCA;
    border-color: #46AFCA;
    &:hover{
      opacity: 0.8;
    }
    margin-top:30px;
    width: 100%;
    padding: 0;
  }
  .card_content{
    display:inline;
  }

  .card_value{
    float:right;
  }
  @media only screen and (width: 425px) {
    .top_title_visiter{
      display: block !important;
    }
    .visiter_table_main .top_title_visiter .button-group .search-bar{
      margin:0px ;
    }
    .visiter_table_main .top_title_visiter h1{
      padding:0px !important;
      margin:0px 0px 10px 0px !important;
    }
    .visiter_table_main .top_title_visiter .button-group{
      display: block !important;
      justify-content:flex-start !important;
     
    }
    .visiter_table_main .button-group .search-bar{
      margin:0px 0px 10px 0px ;
      max-width:100%;
    

    }
    .visiter_table_main .top_title_visiter .button-group button{
      margin-left: 10px;
    width: 120px;
    }
  }
`;

export default WithDirection(AssetModalWrapper);