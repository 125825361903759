import React, {useEffect } from 'react';
import { Map, TileLayer, FeatureGroup, Polygon, Tooltip, ZoomControl,Marker,Popup } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import _ from 'lodash';
import DeviceMapWrapper from './DeviceMap.styles'; 
import DeviceMapMarker from './DeviceMapMarker';


const DeviceMap = (props) => {
  // const {centerPoint, getPositionDevice, positionDevice, propertyId, loading, setLoading, showMarkerAlert, setShowMarkerAlert,mapRef, propertyPosition } = props;
  const {centerPoint, getPositionDevice, positionDevice, propertyId, loading, setLoading, showMarkerAlert, setShowMarkerAlert,mapRef, propertyPosition } = props;
  return (
    <DeviceMapWrapper>
     <Map ref={mapRef} center={ (centerPoint && centerPoint !== null) ? centerPoint : propertyPosition} doubleClickZoom={false} zoom={16} zoomControl={true} style={{width:'100%', height:400}}>
        <ReactLeafletGoogleLayer type={'hybrid'} useGoogMapsLoader={true}/>
        <DeviceMapMarker 
          propertyId={propertyId} 
          getPositionDevice={getPositionDevice} 
          setLoading={setLoading} 
          loading={loading} 
          positionDevice={positionDevice}
          showMarkerAlert={showMarkerAlert}
          setShowMarkerAlert={setShowMarkerAlert}
        />
      </Map>
    </DeviceMapWrapper>
  );
}

export default DeviceMap;
