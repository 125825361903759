import React from "react";
import { Form, Input, Table, Checkbox } from "antd";
import { DiseaseSpecificPracticesWrapper } from "./DiseaseSpecificPracticesWrapper.style";
// import BioCheckbox from "../Components/BioCheckbox";
import BioCheckbox from "../Components/BioCheckbox";
import SignaturePad from '@iso/components/SignaturePad/index';

const { TextArea } = Input;

const DiseaseSpecificPracticesTable = (props) => {
  const columns = [
    {
      title: "8",
      dataIndex: "order",
      rowScope: "row",
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 3 };
        }
        if ([4, 6, 8, 10, 12, 14].includes(index)) {
          return { rowSpan: 2 , className: index === 14 ? 'border-bottom' : '' };
        }
        if ([2, 3, 5, 7, 9, 11, 13, 15].includes(index)) {
          return { rowSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "JOHNE’S DISEASE SPECIFIC PRACTICES FOR CATTLE",
      dataIndex: "disease_specific_practices",
      rowScope: "row",
      colSpan: 7,
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 3 };
        }
        if ([4, 6, 8, 10, 12, 14].includes(index)) {
          return { rowSpan: 2, className: index === 14 ? 'border-bottom' : ''  };
        }
        if ([2, 3, 5, 7, 9, 11, 13, 15].includes(index)) {
          return { rowSpan: 0 };
        }
        return {};
      },
      width: 500,
    },
    {
      title: "Yes",
      colSpan: 0,
      dataIndex: "yes",
      rowScope: "row",
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 3 };
        }
        if ([4, 6, 8, 10, 12, 14].includes(index)) {
          return { rowSpan: 2 , className: index === 14 ? 'border-bottom' : '' };
        }
        if ([2, 3, 5, 7, 9, 11, 13, 15].includes(index)) {
          return { rowSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "No",
      colSpan: 0,
      dataIndex: "no",
      rowScope: "row",
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 3 };
        }
        if ([4, 6, 8, 10, 12, 14].includes(index)) {
          return { rowSpan: 2 , className: index === 14 ? 'border-bottom' : '' };
        }
        if ([2, 3, 5, 7, 9, 11, 13, 15].includes(index)) {
          return { rowSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "N/A",
      colSpan: 0,
      dataIndex: "na",
      rowScope: "row",
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 3 };
        }
        if ([4, 6, 8, 10, 12, 14].includes(index)) {
          return { rowSpan: 2, className: index === 14 ? 'border-bottom' : ''  };
        }
        if ([2, 3, 5, 7, 9, 11, 13, 15].includes(index)) {
          return { rowSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "Recommended measures ",
      colSpan: 0,
      dataIndex: "recommended_measures",
      rowScope: "row",
      width: 500,
      onCell: (_, index) => {
        if (index === 15) {
          return { className: 'border-bottom' };
        }
        return {};
      },
    },
    {
      title: "Actioned ?",
      colSpan: 0,
      dataIndex: "actioned",
      rowScope: "row",
      onCell: (_, index) => {
        if (index === 15) {
          return { className: 'border-bottom' };
        }
        return {};
      },
    },
    {
      title: "Comments",
      colSpan: 0,
      dataIndex: "comments",
      rowScope: "row",
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 3 };
        }
        if ([4, 6, 8, 10, 12, 14].includes(index)) {
          return { rowSpan: 2 , className: index === 14 ? 'border-bottom' : '' };
        }
        if ([2, 3, 5, 7, 9, 11, 13, 15].includes(index)) {
          return { rowSpan: 0 };
        }
        return {};
      },
      className: "comment-column",
    },
  ];

  const data = [
    {
      key: "1",
      order: "8",
      disease_specific_practices: "JOHNE’S DISEASE SPECIFIC PRACTICES",
      yes: "Yes",
      no: "No",
      na: "N/A",
      recommended_measures: "Recommended measures ",
      actioned: "Actioned?",
      comments: "Comments",
    },
    {
      key: "2",
      order: "8.1.1",
      disease_specific_practices:
        "Do you know the Johne’s Disease (JD) status and level of risk of the livestock being introduced?",
      yes: <BioCheckbox name="disease_specific_practices_1_yes"></BioCheckbox>,
      no: <BioCheckbox name="disease_specific_practices_1_no"></BioCheckbox>,
      na: <BioCheckbox name="disease_specific_practices_1_na"></BioCheckbox>,
      recommended_measures: "Relevant questions asked on the JD checklist.",
      actioned: (
        <BioCheckbox name="disease_specific_practices_1_1_actioned"></BioCheckbox>
      ),
      comments: (
        <Form.Item
          name="disease_specific_practices_1_comments"
          className="isoInputWrapper"
        >
          <TextArea />
        </Form.Item>
      ),
    },
    {
      key: "3",
      order: "",
      disease_specific_practices: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "Cattle Health Declarations requested from sellers and retained for seven years.",
      actioned: (
        <BioCheckbox name="disease_specific_practices_1_2_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "4",
      order: "",
      disease_specific_practices: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "JD status of introductions and risk management practices recorded.",
      actioned: (
        <BioCheckbox name="disease_specific_practices_1_3_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "5",
      order: "8.1.2",
      disease_specific_practices:
        "Are all suspect clinical cases investigated and notified to state department as required?",
      yes: <BioCheckbox name="disease_specific_practices_2_yes"></BioCheckbox>,
      no: <BioCheckbox name="disease_specific_practices_2_no"></BioCheckbox>,
      na: <BioCheckbox name="disease_specific_practices_2_na"></BioCheckbox>,
      recommended_measures:
        "Veterinary investigation of suspect cases conducted.",
      actioned: (
        <BioCheckbox name="disease_specific_practices_2_1_actioned"></BioCheckbox>
      ),
      comments: (
        <Form.Item
          name="disease_specific_practices_2_comments"
          className="isoInputWrapper"
        >
          <TextArea />
        </Form.Item>
      ),
    },
    {
      key: "6",
      order: "",
      disease_specific_practices: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures: "Clinical cases reported as per state legislation.",
      actioned: (
        <BioCheckbox name="disease_specific_practices_2_2_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "7",
      order: "8.1.3",
      disease_specific_practices:
        "If there is JD on the property, is the potential exposure minimised to limit the spread of infection (or risk of infection) through the culling of infected livestock, grazing management and vaccination, as appropriate?",
      yes: <BioCheckbox name="disease_specific_practices_3_yes"></BioCheckbox>,
      no: <BioCheckbox name="disease_specific_practices_3_no"></BioCheckbox>,
      na: <BioCheckbox name="disease_specific_practices_3_na"></BioCheckbox>,
      recommended_measures:
        "If JD on property, work with veterinarian to prioritise high-risk animals for culling including clinical cases, suspect clinical cases, test-positive animals, animals originated from high-risk sources, etc.",
      actioned: (
        <BioCheckbox name="disease_specific_practices_3_1_actioned"></BioCheckbox>
      ),
      comments: (
        <Form.Item
          name="disease_specific_practices_3_comments"
          className="isoInputWrapper"
        >
          <TextArea />
        </Form.Item>
      ),
    },
    {
      key: "8",
      order: "",
      disease_specific_practices: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "Young animals not grazed in high-risk areas e.g. adjacent to highrisk neighbours with infected livestock, land grazed by clinical or suspect cases.",
      actioned: (
        <BioCheckbox name="disease_specific_practices_3_2_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "9",
      order: "8.1.4",
      disease_specific_practices:
        "If there are other JD susceptible ruminants on the property (e.g. sheep, goats or alpaca), do you prevent them from co-grazing with cattle, and/or have practices (e.g. testing and vaccination) in place to minimise JD risk?",
      yes: <BioCheckbox name="disease_specific_practices_4_yes"></BioCheckbox>,
      no: <BioCheckbox name="disease_specific_practices_4_no"></BioCheckbox>,
      na: <BioCheckbox name="disease_specific_practices_4_na"></BioCheckbox>,
      recommended_measures:
        "JD status of other species on the property determined and, if suspected infected or of unknown JD status, co-grazing prevented if possible.",
      actioned: (
        <BioCheckbox name="disease_specific_practices_4_1_actioned"></BioCheckbox>
      ),
      comments: (
        <Form.Item
          name="disease_specific_practices_4_comments"
          className="isoInputWrapper"
        >
          <TextArea />
        </Form.Item>
      ),
    },
    {
      key: "10",
      order: "",
      disease_specific_practices: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures: "Sheep and goats vaccinated as appropriate.",
      actioned: (
        <BioCheckbox name="disease_specific_practices_4_2_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "11",
      order: "8.1.5",
      disease_specific_practices:
        "If JD infection is detected, are risks within the herd assessed and people who have previously received cattle as coming from a low-risk herd been notified to enable them to manage their revised risk?",
      yes: <BioCheckbox name="disease_specific_practices_5_yes"></BioCheckbox>,
      no: <BioCheckbox name="disease_specific_practices_5_no"></BioCheckbox>,
      na: <BioCheckbox name="disease_specific_practices_5_na"></BioCheckbox>,
      recommended_measures: "Herd health monitored.",
      actioned: (
        <BioCheckbox name="disease_specific_practices_5_1_actioned"></BioCheckbox>
      ),
      comments: (
        <Form.Item
          name="disease_specific_practices_5_comments"
          className="isoInputWrapper"
        >
          <TextArea />
        </Form.Item>
      ),
    },
    {
      key: "12",
      order: "",
      disease_specific_practices: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "People who have received animals notified of higher risk than first thought/advised.",
      actioned: (
        <BioCheckbox name="disease_specific_practices_5_2_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "13",
      order: "8.1.6",
      disease_specific_practices:
        "Only for J-BAS 7 and 8 – Has there been veterinary oversight in the development of this biosecurity plan?",
      yes: <BioCheckbox name="disease_specific_practices_6_yes"></BioCheckbox>,
      no: <BioCheckbox name="disease_specific_practices_6_no"></BioCheckbox>,
      na: <BioCheckbox name="disease_specific_practices_6_na"></BioCheckbox>,
      recommended_measures:
        "Veterinarian oversight into biosecurity plan and testing provided.",
      actioned: (
        <BioCheckbox name="disease_specific_practices_6_1_actioned"></BioCheckbox>
      ),
      comments: (
        <Form.Item
          name="disease_specific_practices_6_comments"
          className="isoInputWrapper"
        >
          <TextArea />
        </Form.Item>
      ),
    },
    {
      key: "14",
      order: "",
      disease_specific_practices: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures: "Vet has signed below. ",
      actioned: (
        <BioCheckbox name="disease_specific_practices_6_2_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "15",
      order: "8.1.7",
      disease_specific_practices:
        "Only for J-BAS 7 and 8 – Has a triennial Check Test been completed with negative results (or Sample Test if progressing to a higher J-BAS level)?",
      yes: <BioCheckbox name="disease_specific_practices_7_yes"></BioCheckbox>,
      no: <BioCheckbox name="disease_specific_practices_7_no"></BioCheckbox>,
      na: <BioCheckbox name="disease_specific_practices_7_na"></BioCheckbox>,
      recommended_measures: "Triennial Check Test completed. ",
      actioned: (
        <BioCheckbox name="disease_specific_practices_7_1_actioned"></BioCheckbox>
      ),
      comments: (
        <Form.Item
          name="disease_specific_practices_7_comments"
          className="isoInputWrapper"
        >
          <TextArea />
        </Form.Item>
      ),
    },
    {
      key: "16",
      order: "",
      disease_specific_practices: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "Laboratory results recorded and property managed based on outcomes.",
      actioned: (
        <BioCheckbox name="disease_specific_practices_7_2_actioned"></BioCheckbox>
      ),
      comments: "",
    },
  ];

  return (
    <DiseaseSpecificPracticesWrapper>
      <div className="header-table">
        <p>
          COMPLETING THIS SECTION IS OPTIONAL.
          <span>
            *You can complete and save property risk assessments and biosecurity
            plans online in your LPA account.
          </span>
        </p>
        <ul>
          <li>
            This section is for producers running CATTLE who want to attain a
            Johne’s Beef Assurance Score (J-BAS)
          </li>
          <li>
            A veterinarian’s signature is only required if this section is
            completed AND only if a J-BAS 7 or 8 score is desirable
          </li>
          <li>
            A veterinarian’s signature is voluntary for J-BAS score 6 and below
          </li>
          <li>
            A veterinarian’s signature is NOT required for any other sections of
            the LPA on-farm biosecurity management plan template
          </li>
        </ul>
      </div>
      <Table columns={columns} dataSource={data} bordered pagination={false} className="bio-table" />
      
      <Form.Item name="vet_signatures">
         <SignaturePad tableName={'Vet Name'}></SignaturePad>
      </Form.Item>
    </DiseaseSpecificPracticesWrapper>
  );
};

export default DiseaseSpecificPracticesTable;