
import React, { useEffect, useLayoutEffect } from "react";
import LayoutWrapper from "@iso/components/utility/layoutWrapper.js";
import Box from "@iso/components/utility/box";
import PageHeader from "@iso/components/utility/pageHeader";
import ViewSurveyResultModal from "@iso/containers/Pages/Form/ViewSurveyResultModal";
import IntlMessages from "@iso/components/utility/intlMessages";
import { useSelector, useDispatch } from "react-redux";
import { configs } from "@iso/components/Tables/configs";
import { Button, Divider, Dropdown, Menu, Select } from "antd";
import VisitorPageWrapper from "../../Visitor/Visitor.style";
import ManagePropertyPage from './Partial.styles'
import {
  PlusOutlined,
  DownOutlined,
  EditOutlined,
  CheckCircleOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  ArrowUpOutlined,
  EyeOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import propertyActions from "@iso/redux/property/actions";
import _, { property } from "lodash";
import Table from "@iso/components/collapsing-table/table";
// import {
//   propertyService,
//   permissionService,
//   formService,
//   visitorService,
// } from "@iso/services";
import { subscriptionPlan } from "@iso/constants/subscriptionPlan";
import { formTypes } from "@iso/constants/formTypes";
import VisitorModal from "../../Visitor/VisitorModal";
// import { visitorService } from "../../../../services/visitor.service";
import { visitorService } from "../../../../services/visitor.service";
import { propertyService } from "../../../../services/property.service";

const { changeActiveProperty } = propertyActions;

const VisitorBox = (props) => {
  const dispatch = useDispatch();
  const activePropertyId = useSelector(
    (state) => state.property.activePropertyId
  );
  const activePropertySubscription = useSelector(
    (state) => state.property.activePropertySubscription
  );
  const activePermission = useSelector(
    (state) => state.permission.activePermission
  );
  const [visibleVisitorModal, setVisibleVisitorModal] = React.useState(false);
  const [visibleVisitorFromResultModal, setVisibleVisitorFromResultModal] =
    React.useState(false);
  // const activeProperty=localStorage.getItem( "active_property" );
  const activeProperty = JSON.parse(localStorage.getItem("active_property"));
  const [property, setProperty] = React.useState({});
  const { Option } = Select;
  const [properties, setProperties] = React.useState([]);
  const [isSaved, setIsSaved] = React.useState(false);
  const [visitors, setVisitors] = React.useState([]);
  const [visitorData, setVisitorData] = React.useState({});
  const [visitorForm, setVisitorForm] = React.useState({});
  const [visitorsLng, setVisitorsLng] = React.useState();
 

  const [visibleSelectProperty, setVisiblitySelectProperty] =
    React.useState(false);

  useEffect(() => {
    if (activePropertyId) {
      visitorService.getList(activePropertyId).then((res) => {
        if (res.code === "0000") {
          setVisitors(res.visitors);
        }
      });
    }
  }, [activePropertyId, isSaved, activePropertySubscription]);


  useEffect(() => {
    if (props?.handleVisitorData) {
      props?.handleVisitorData(visitors.length);
    }
  }, [visitors]);

  const updateVisitorData = () => {
    if (activePropertyId) {
      visitorService.getList(activePropertyId).then((res) => {
        if (res.code === "0000") {
          setVisitors(res.visitors);

        }
      });
    }
  };

  useEffect(() => {
    propertyService.getProperties().then((res) => {
      if (res.properties.length === 0) {
        setVisiblitySelectProperty(true);
      } else {
        setProperties(res.properties);
        if (res.properties.length === 1) {
          localStorage.setItem(
            "active_property",
            JSON.stringify(res.properties[0])
          );
          dispatch(changeActiveProperty(res.properties[0]));
        }
      }
    });
  }, [activePropertyId, isSaved, activePropertySubscription]);

  const showModal = () => {
    setVisibleVisitorModal(true);
  };

  const cancelModal = () => {
    setVisibleVisitorModal(false);
    setVisitorData({});
  };

  const openEditModal = (visitor) => {
    setVisitorData(visitor);
    setVisibleVisitorModal(true);
  };

  const openFormResult = (visitorForm) => {
    setVisitorForm(visitorForm);
    setVisibleVisitorFromResultModal(true);
  };

  const cancelViewSurveyResult = () => {
    setVisitorForm({});
    setVisibleVisitorFromResultModal(false);
  };

  const columns = [
    {
      label: <IntlMessages id="visitorPage.table.title.name" />,
      accessor: "name",
      minWidth: 100,
      sortable: true,
      position: 1,
      priorityLevel: 1,
    },
    {
      label: <IntlMessages id="visitorPage.table.title.email" />,
      accessor: "email",
      minWidth: 100,
      sortable: true,
      position: 2,
      priorityLevel: 2,
    },
    {
      label: <IntlMessages id="Mobile" />,
      accessor: "mobile_number",
      minWidth: 100,
      sortable: true,
      position: 3,
      priorityLevel: 3,
    },
    {
      label: <IntlMessages id="Emergency Contact" />,
      accessor: "emergency_mobile_number",
      minWidth: 100,
      sortable: true,
      position: 3,
      priorityLevel: 3,
    },
    {
      label: <IntlMessages id="Arrival" />,
      accessor: "created_at",
      minWidth: 100,
      sortable: true,
      position: 5,
      priorityLevel: 5,
    },
    {
      label: <IntlMessages id="Departure" />,
      accessor: "Department",
      minWidth: 100,
      sortable: true,
      position: 6,
      priorityLevel: 6,
    },
    {
      label: <IntlMessages id="Induction in last 180 days" />,
      accessor: "Induction",
      minWidth: 100,
      sortable: true,
      position: 7,
      priorityLevel: 7,
    },
    // {
    //   label: <IntlMessages id="visitorPage.table.title.signature" />,
    //   accessor: "signature",
    //   minWidth: 100,
    //   sortable: true,
    //   position: 8,
    //   priorityLevel: 8,
    //   noTitle: true,
    //   CustomComponent: (cell) => {
    //     let visitor = cell.row;
    //     if (visitor?.visitor_form?.form?.type === 'pdf_upload') {
    //       return (
    //         <><img className="signature-image" src={process.env.REACT_APP_API_BASE_URL + visitor?.visitor_form?.signature_path} /></>
    //       )
    //     }
    //     return <></>;
    //   },
    // },
    {
      label: "Action",
      accessor: "actions",
      minWidth: 100,
      sortable: true,
      position: 8,
      priorityLevel: 8,
      noTitle: true,
      CustomComponent: (cell) => {
        let visitor = cell.row;

        return (
          <div>
            <Button
              className="action-button"
              onClick={() => {
                openEditModal(visitor);
              }}
            >
              <IntlMessages id="visitorPage.table.button.edit" />
            </Button>
            {visitor.visitor_form &&
              ["self_create", "system_forms", "bio_form"].includes(
                visitor?.visitor_form?.form?.type
              ) && (
                <Button
                  className="action-button"
                  onClick={() => {
                    openFormResult(visitor.visitor_form);
                  }}
                >
                  <IntlMessages id="visitorPage.table.button.viewFormResult" />
                </Button>
              )}
          </div>
        );
      },
    },
  ];

  const handleOptionClick = (value) => {
    _.forEach(properties, (property) => {
      if (property.id == value) {
        localStorage.setItem("active_property", JSON.stringify(property));
        dispatch(changeActiveProperty(property));
      }
    });
  };

  const renderOptionsProperty = () => {
    let options = [];
    _.forEach(properties, (property, index) => {
      options.push(
        <Option key={index} value={property.id} className="property-option">
          {property.name}
        </Option>
      );
    });

    return (
      <Select
        placeholder="Select Active Property"
        value={activeProperty.name}
        size="large"
        style={{ width: 150 }}
        onChange={handleOptionClick}
      >
        {options}
      </Select>
    );
  };

  return (
//   <ManagePropertyPage>
//        <Box id="containerBox">
//                  <div className="manage-property-header">
//             <div className="mp-title">
//               <h2 className="manage-property-title">Visitors</h2></div>
//             </div>

//                 {props.search_result &&
//                 props.search_result.visitors &&
//                 props.search_result.visitors.length > 0 ? (
//                     <div className="manage-table">
//                     <Table
//                         columns={columns}
//                         rows={props.search_result.visitors}
//                         containerId="containerBox"
//                         showPagination={false}
//                         rowSize={99999}
//                     />
//                     </div>
//                 ) : (
//                     <h1 style={{ padding: "10px 10px 20px", fontSize: '18px' }}>
//                     <IntlMessages id="dashboardPage.noData" />
//                   </h1>
//                 )}
//             </Box>
//         <VisitorModal
//           visibleVisitorModal={visibleVisitorModal}
//           cancelModal={cancelModal}
//           propertyId={activePropertyId}
//           editedVisitor={visitorData}
//           updateVisitorData={updateVisitorData}
//         />
//         <ViewSurveyResultModal
//           visibleViewSurveyResult={visibleVisitorFromResultModal}
//           cancelViewSurveyResult={cancelViewSurveyResult}
//           surveyData={visitorForm}
//           type={visitorForm?.form?.type}
//         />
//      </ManagePropertyPage>
<LayoutWrapper>
<VisitorPageWrapper>
  <div className="visiter_table_main">
    <div className="top_title_visiter">
      {/* <Divider type= 'vertical' orientation='right' className="VerticalDivider"/> */}
      <PageHeader>{<IntlMessages id="visitorPage.header" />}</PageHeader>
      {/* <Divider className="HorizontalDeivider"/> */}
    {/* </div> */}
    <div className="button-group">
    {renderOptionsProperty()}
      {(activePermission == "owner" || activePermission == "admin") && (
        <Button
          icon={<PlusOutlined />}
          type="primary"
          className="btn-success"
          onClick={showModal}
          size="large"
          style={{
            background: "rgb(248, 14, 70)",
            borderRadius: "12px !important",
          }}
        >
          <IntlMessages id="visitorPage.addVisitor" />
        </Button>
      )}
    
    </div>
    </div>
    <Box className="table_visiter" id="containerBox">


        
      {
      props.search_result &&
      props.search_result.visitors &&
      props.search_result.visitors.length > 0 ?(
        <Table
          columns={columns}
          rows={props.search_result.visitors}
          containerId="containerBox"
          showPagination={false}
          rowSize={99999}
        />
      ) : (
        <h1 style={{ fontSize: '18px', padding: '15px', fontWeight: '600' }}>
          <IntlMessages id="dashboardPage.noData" />
        </h1>
      )}
    </Box>
  </div>
  <VisitorModal
    visibleVisitorModal={visibleVisitorModal}
    cancelModal={cancelModal}
    propertyId={activePropertyId}
    editedVisitor={visitorData}
    updateVisitorData={updateVisitorData}
  />
  <ViewSurveyResultModal
    visibleViewSurveyResult={visibleVisitorFromResultModal}
    cancelViewSurveyResult={cancelViewSurveyResult}
    surveyData={visitorForm}
    type={visitorForm?.form?.type}
  />
</VisitorPageWrapper>
</LayoutWrapper>
  );
};

export default VisitorBox;
