import React, { useState, useEffect } from "react";
import MetalHealthCheckingWrapper from "./MentalHealthChecking.style";
import $ from "jquery";
import { profileService } from "@iso/services";

const MentalHealthChecking = (props) => {
  const [ellipsisToken, setEllipsisToken] = useState();

  useEffect(() => {
    profileService.getEllipsisToken().then((res) => {
      if (res.code === "0000") {
        setEllipsisToken(res.token);
      }
    });
  }, []);
  
  // useEffect(() => {
  //   if (ellipsisToken) {
  //     if (window.EHWEB && typeof window.EHWEB.initSession === "function") {
  //     window.EHWEB.initSession({
  //       // for prod, 'https://eh-voice-widget.ellipsishealth.net'
  //       url: process.env.REACT_APP_ELLIPSIS_URL,
  //       containerId: "eh-session-container",
  //       jwt: ellipsisToken, // JWT token to be created by the client
  //       // queryParams are optional and are only needed if additional
  //       // information needs to be passed.
  //       queryParams: {
  //         lang: process.env.REACT_APP_ELLIPSIS_LANG,
  //         partnerDivisionId: process.env.REACT_APP_ELLIPSIS_PARTNER_ID,
  //       },
  //       onStart: () => {
  //         console.log("ON START");
  //         // This event means the widget is loaded and ready for use.
  //       },
  //       onResult: (message) => {
  //         console.log("ON RESULT");
  //         // Process the result json object. Please refer to
  //         // Appendix 'A' for more information.
  //         // display scores
  //         $("#eh-session-result").html(
  //           JSON.stringify(message.data.score.measures, null, 2)
  //         );
  //         // hide the div once the session is complete
  //         $("#eh-session-container").hide();
  //       },
  //       onError: (message) => {
  //         // Process the error json object. Please refer to
  //         // Appendix 'A' for more information.
  //         console.log("ON ERROR");
  //         console.error(message);
  //       },
  //     });
  //   }else{
  //     console.error("window.EHWEB or window.EHWEB.initSession is not defined.");
  //   }
  //   }
  // }, [ellipsisToken]);
  return (
    <MetalHealthCheckingWrapper>
      <div>
        <div id="eh-session-container"></div>
        <div id="eh-session-result"></div>
      </div>
    </MetalHealthCheckingWrapper>
  );
};

export default MentalHealthChecking;
