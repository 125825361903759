import React, { useState, useEffect } from "react";
import IntlMessages from "@iso/components/utility/intlMessages";
import ObservationModalWrapper from "./ObservationModal.styles";
import {
  Form,
  Input,
  Button,
  Upload,
  message
} from "antd";
import _ from "lodash";
import { observationService } from "@iso/services";
import { useSelector, useDispatch } from "react-redux";
import modalActions from "@iso/redux/modal/actions";
import "react-tagsinput/react-tagsinput.css";
import { UploadOutlined } from "@ant-design/icons";
import $ from "jquery";
import { apiEndpoint } from "@iso/lib/helpers/endpoint";
import { authHeader } from "@iso/lib/helpers/authHeader";

const { closeModal } = modalActions;

const ObservationModal = (props) => {
  const activePermission = useSelector(
    (state) => state.permission.activePermission
  );

  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const modalVisibility = useSelector((state) => state.modal.modalVisibility);
  const modalData = useSelector((state) => state.modal.modalData);
  const dispatch = useDispatch();
  const [dataObservation, setDataObservation] = useState({});
  const [imageId, setImageId] = useState(null);

  useEffect(() => {
    if (modalData.type === "observation") {
      if (JSON.stringify(modalData.object) !== "{}") {
        if (modalData.object) {
          setDataObservation(modalData.object);
        }
        setFields([
          {
            name: ["observation", "title"],
            value: modalData.object.title || "",
          },
          {
            name: ["observation", "comment"],
            value: modalData.object.comment || "",
          },
          {
            name: ["observation", "primary_object_id"],
            value: modalData.object.primary_object_id || 0,
          },
        ]);
      } else {
        setFields([
          {
            name: ["observation", "title"],
            value: "",
          },
          {
            name: ["observation", "comment"],
            value: "",
          },
          {
            name: ["observation", "primary_object_id"],
            value: props.dataObject ? props.dataObject.id : 0,
          },
        ]);
      }
    }
    setImageId(null);
  }, [props.propertyId, modalVisibility, modalData]);

  const cancelModal = () => {
    dispatch(closeModal());
    if (modalData.layer) {
      modalData.featureGroup.leafletElement.removeLayer(modalData.layer);
    }
    setLoading(false);
    form.resetFields();
  };

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        onStore(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
        setLoading(false);
      });
  };

  const onStore = (values) => {
    const data = {
      observation: {
        ...values.observation,
        point: dataObservation.point,
        property_id: props.propertyId,
        image_id: imageId,
      },
    };
    observationService.storeOrUpdateObservation(data, dataObservation.id).then((res) => {
      if (res.code === "0000") {
        props.onSaved(res.observation)
        cancelModal();
        form.resetFields();
      } else {
        setLoading(false);
      }
    });
  };

  const handleChangeTag = (input_tags) => {
    setTags(input_tags);
  };

  useEffect(() => {
    handleChangeTag(tags);
  }, [tags]);

  const uploadProps = {
    name: "Image[file]",
    action: apiEndpoint.images,
    headers: authHeader(true),
    beforeUpload: (file, fileList) => {
      let $oldOne = $(".anticon-delete")[0];
      setTimeout(() => {
        if ($oldOne) $($oldOne).trigger("click");
      }, 100);
    },
    onChange: (response) => {
      if (response.file && response.file.status == "done") {
        let res = response.file.response
        if(res.code === '0000'){
          setImageId(res.image.id);
        }else{
          message.error(res.message)
        }
      }
    },
    listType: "picture",
    multiple: false,
    data:{
      type: 'observations',
      storage: 's3'
    }
  };

  const renderImage = () => {
    if(dataObservation.images && dataObservation.images.length > 0){
      let media = dataObservation.images[dataObservation.images.length - 1];
      if(media.file_type === 'video'){
        return (
          <video width="320" height="240" controls>
            <source src={media.full_path} type="video/mp4"/>
            Your browser does not support the video tag.
          </video>
        )
      }else{
        return (
          <img
              style={{ width: "50%" }}
              src={media.full_path}
          />
        )
      }
    }
  }

  const editLocation = () => {
    props.editLocation();
    cancelModal();
  }

  return (
    <ObservationModalWrapper
      className="user-access-modal"
      forceRender={true}
      visible={modalVisibility && modalData.type === "observation"}
      title={
        dataObservation.id ? (
          <IntlMessages id="propertyMapPage.editObservation" />
        ) : (
          <IntlMessages id="propertyMapPage.addObservation" />
        )
      }
      onCancel={cancelModal}
      maskClosable={true}
      footer={[
        (activePermission == "owner" || activePermission == "admin"|| activePermission == "modify") && dataObservation.id && <Button key="editLocation" onClick={editLocation} style={{float: 'left'}} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.move" />}
        </Button>,
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        (activePermission == "owner" ||
          activePermission == "admin" ||
          activePermission == "modify") && (
          <Button
            key="submit"
            className="btn-success"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            {<IntlMessages id="propertyPage.modal.save" />}
          </Button>
        ),
      ]}
    >
      {
        <Form
          form={form}
          name="addEditTask"
          layout="vertical"
          scrollToFirstError
          fields={fields}
        >
          <div className="form-body">
            <Form.Item
              name={["observation", "title"]}
              className="isoInputWrapper"
              label="Title"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
                {
                  whitespace: true,
                  message: "Please input your Title",
                },
              ]}
            >
              <Input size="large" placeholder="Input observation title" />
            </Form.Item>
            <Form.Item name={["observation", "comment"]} label="Comment">
              <Input.TextArea />
            </Form.Item>

            <Form.Item name={["attachment"]} label="Attachment">
              <Upload {...uploadProps}>
                <Button className="action-button" icon={<UploadOutlined />}>
                  Upload
                </Button>
              </Upload>
            </Form.Item>
            {renderImage()}
          </div>
        </Form>
      }
    </ObservationModalWrapper>
  );
};

export default ObservationModal;
