
import React,{useEffect,useState,useRef,useCallback} from 'react';
import { Form, Input, Button, Select, Row, Col, DatePicker, Upload, message } from 'antd';
import ModalActivityWrap from './ModalActivity.styles';
import IntlMessages from '@iso/components/utility/intlMessages';
import { activityPolicy } from '@iso/constants/activityPolicy';
import { hazardTypes } from '@iso/constants/hazardTypes';
import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { propertyService,healthsafety,attachment,assetService } from '@iso/services';
import _ from 'lodash';
import L from 'leaflet';  
import { useSelector, useDispatch } from 'react-redux';
import { dateHelper } from '@iso/lib/helpers/dateHelper';
import moment from 'moment';
import { Map, TileLayer, FeatureGroup, Polygon, Tooltip, ZoomControl,Marker,Popup } from 'react-leaflet';
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import appActions from '@iso/redux/app/actions';
import notificationActions from '@iso/redux/notification/actions';

const { changeCurrent} = appActions;
const { notificationData } = notificationActions;

const { Option } = Select;

const ModalActivity = (props) => {
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const mapRef = useRef();
  const [valueLat, setValueLat] = useState();
  const [valueLng, setValueLng] = useState();
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const currentUser = useSelector((state) => state.user.user);

  const [attachmentActivityId, setAttachmentActivityId] = useState([]);
  const [attendees, setAttendees] = useState([]);
  const [showHazardOther, setShowHazardOther] = useState(false);
  const [position,setPosition] = useState([null, null]);
  const [assetData, setAssetData] = useState([]);
  const dispatch = useDispatch();

  const {activityActive,propertyId} = props;


useEffect(() => {
    setFields([
      {
        name: ['asset', 'date_time'],
        value: moment(),
      },
      {
        name: ['asset','type'],
        value: activityActive,
      }   
  
    ]);
    if(propertyId){
      updatePropertyDetail(propertyId);
      getDataAsset(propertyId);
    }
    
  }, [propertyId,activityActive]);
  
  const updatePropertyDetail = React.useCallback((id) => {
    propertyService.viewProperty(id).then(res => {
      if(res.code === '0000'){
        setPosition([res.property.lat, res.property.lng]);
      }
    })
  }, []);

  const getDataAsset = (propertyId) => {
     healthsafety.getHealthAndSafetyAssets(propertyId).then(res => {
      if(res.code === '0000'){
        setAssetData(res.assets);
      }else{
        message.error(res.message)
      }
    });
  }

  const handleChangeTag = (input_tags) => {
    setAttendees(input_tags);
  }

  const handleClickMap = (e) => {
     const { lat, lng } = e.latlng;
     setValueLng(lng);
     setValueLat(lat);
  };
  const uploadPolicyProps = {
    name: "Attachment[file]",
    action: apiEndpoint.attachments,
    headers: authHeader(true),
    listType: "picture",
    multiple: true,
    onChange: (response) => {
      if(response.file && response.file.status == "done"){
        setAttachmentActivityId(attachmentActivityId => [...attachmentActivityId, response.file.response.attachment.id]);
      }
    },
    onRemove: file => {
      attachment.destroy(file.uid);
    }

  };

  const getLatLng = (cLat, cLng) => {
    const lat = cLat;
    const lng = cLng;
    return new L.LatLng(
          lat,
          lng
    );
  }
  const renderActivityPolicy = () => {
    let options = [];
    _.forEach(activityPolicy , (activity, index) => {
      options.push(
        <Option key={index} value={activity.value}>
          <div>{activity.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a activity"
        allowClear
        size="large"
        disabled ={!activityActive ?false:true}
        
      >
        {options}
      </Select>
    );
  }  
  const handleChangeHazardType = (value) => {
    if(value === 'other'){
      setShowHazardOther(true);
    }else{
      setShowHazardOther(false);
    }
  }

  const renderHazardTypes = () => {
    let options = [];
    _.forEach(hazardTypes , (activity, index) => {
      options.push(
        <Option key={index} value={activity.value}>
          <div>{activity.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a activity"
        allowClear
        onChange={handleChangeHazardType}
        size="large"
      >
        {options}
      </Select>
    );
  } 
  const renderOptionsAssets = (assets) => {
    let options = [];
    _.forEach(assets, (asset, index) => {
      
      options.push(
        <Option key={index} value={asset.id}>
          <div>{asset.title}</div>
        </Option>
      ); 
     
    })
    return (
      <Select
        placeholder="Select Assets"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  } 
  const handleSubmit = (e) => {
    setLoading(true);
    
    form
      .validateFields()
      .then(values => {
        onStore(values);
        

      })
      .catch(info => {
       
        setLoading(false);
      });
  }
  const onStore = (values) => {
    var point = null;
    if(valueLat && valueLng){
      point = getLatLng(valueLat,valueLng);
    }
    
    const dataPolicy = {
      policy: {
        ...values.asset,
        idFacilities: values.asset.asset_id,
        type: values.asset.type,
        date_time: values.asset.date_time ? dateHelper.dateTimeForAPI(values.asset.date_time) : '',
        point:point,
        attendees: attendees,
        attachment_activity_id:attachmentActivityId,
      }
    }
   
    healthsafety.storeOrUpdateActivityPolicies(dataPolicy).then(res => {
      if(res.code === '0000'){
        setLoading(false);
        form.resetFields();
        // props.getDataPolicy();
        props.search(activePropertyId, '');
        props.cancelModalActivity();
        setAttendees([]);
        setValueLat();
        setValueLng();
        setAttachmentActivityId([]);
        if(currentUser){
          // const inputString = "clfm477d100hx08jn5ogmdq6v";
          // const numericString = currentUser.id.replace(/\D/g, ""); // Remove non-numeric characters
          // const CurrentId = parseInt(numericString);
           const notiData = {
            "type": "Activity",
            "data":{
              "title": "New Activity",
              "user_id_send":currentUser.id,
              "user_name_send":currentUser.name,
              "body" : "added Activity "+values.asset.type.replace(/_/g, ' ').replace(/(?: |\b)(\w)/g, l => l.toUpperCase()),
            }
          }
          dispatch(notificationData(notiData));
        }else{
          console.log('Error User');
        }
       

      } else {
        setLoading(false);
      }
    });
  }

  return (

    <ModalActivityWrap
      open={props.visibleModalActivity}
      title="Safety Activity"
      onCancel={props.cancelModalActivity}
      footer={[
      <Button key="back" onClick={props.cancelModalActivity} className="cancel-button">
        {<IntlMessages id="propertyPage.modal.cancel" />}
      </Button>,
      <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit} loading={loading}>
        {<IntlMessages id="propertyPage.modal.save" />}
      </Button>]}
    >
     <Form
        form={form}
        layout="vertical"
        scrollToFirstError
        fields={fields}
        id="#1"
      >
      <div className="form-body">
      <Row>
        <Col xs={{span: 24}} md={{span: 24}}>
            <Form.Item
              name={['asset','type']}
              className="isoInputWrapper"
              label={<IntlMessages id="propertyPage.modal.asset.activityPolicy" />}
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              {renderActivityPolicy()}
            </Form.Item>
          </Col>
      </Row>      
      <Row>
        <Col xs={{span: 24}} md={{span: 24}}>
            <Form.Item
              name={['asset','asset_id']}
              className="isoInputWrapper"
              label={<IntlMessages id="healthSafety.history.assetName" />}
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              {renderOptionsAssets(assetData)}
            </Form.Item>
          </Col>
      </Row>
       <Row>
          <Col xs={{span: 24}} md={{span: 24}}>
            <Form.Item
              name={['asset', 'date_time']}
              className="isoInputWrapper"
              label={<IntlMessages id="healthSafety.history.dateTime" />}
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              <DatePicker format="DD-MM-YYYY HH:mm:ss" size="large" style={{width: '100%'}} showTime/>
            </Form.Item>
          </Col>
      </Row>
      <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => {
            return ( prevValues['asset'] );
          }}
        >
        {({ getFieldValue }) => {
          if(getFieldValue('asset')){
             
            return (
              <div>
              {getFieldValue('asset').type == 'hazard' &&
                  <Row>
                    <Col xs={{span: 24}} md={{span: 24}}>
                        <Form.Item
                          name={['asset','hazard_type']}
                          className="isoInputWrapper"
                          label={<IntlMessages id="healthSafety.history.hazardType" />}
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                            },
                          ]}
                        >
                          {renderHazardTypes()}
                        </Form.Item>
                      </Col>
                  </Row>
                }
                {showHazardOther &&
                  <Row>
                    <Col xs={{span: 24}} md={{span: 24}}>
                      <Form.Item
                        name={['asset', 'hazard_type_other']}
                        className="isoInputWrapper"
                        label={<IntlMessages id="healthSafety.history.hazardTypeOther" />}
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Hazard type"/>
                      </Form.Item>
                    </Col>
                  </Row>
                }
                { getFieldValue('asset').type === 'meeting' &&
                  <div>
                    <Row>
                      <Col xs={{span: 24}} md={{span: 24}}>
                        <Form.Item
                          name={['asset', 'meeting_name']}
                          className="isoInputWrapper"
                          label={<IntlMessages id="healthSafety.history.meetingName" />}
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                            },
                          ]}
                        >
                          <Input size="large" placeholder="Meeting Name"/>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                }
                {(getFieldValue('asset').type == 'safety_walk' || 
                  getFieldValue('asset').type == 'hazard' || 
                  getFieldValue('asset').type == 'meeting'||
                  getFieldValue('asset').type == 'induction'||
                  getFieldValue('asset').type === 'toolbox_meeting'
                  ) &&
                  <Row>
                  <Col xs={{span: 24}} md={{span: 24}}>
                      <Form.Item
                        name={['asset', 'actions']}
                        className="isoInputWrapper"
                        label={<IntlMessages id="healthSafety.history.actions" />}
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                          },
                        ]}
                      >
                        <Input.TextArea />
                      </Form.Item>
                    </Col>
                  </Row>
                }
                {getFieldValue('asset').type == 'induction' &&
                    <Row>
                      <Col xs={{span: 24}} md={{span: 24}}>
                        <Form.Item
                          name={['asset', 'person_running_induction']}
                          className="isoInputWrapper"
                          label={<IntlMessages id="healthSafety.history.personRunningInduction" />}
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                            },
                          ]}
                        >
                          <Input size="large" placeholder="Person running Induction"/>
                        </Form.Item>
                      </Col>
                    </Row>
                }
                {(
                  getFieldValue('asset').type === 'meeting' || 
                  getFieldValue('asset').type === 'safety_walk' || 
                  getFieldValue('asset').type === 'induction'||
                  getFieldValue('asset').type === 'toolbox_meeting'
                  ) &&
                  <div>
                  <Row>
                    <Col xs={{span: 24}} md={{span: 24}}>
                      <Form.Item
                        className="isoInputWrapper"
                        label={<IntlMessages id="healthSafety.history.attendees" />}
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                          },
                        ]}
                      >
                        <TagsInput name={['asset', 'attendees']} value={attendees} onChange={handleChangeTag} addOnBlur="true"/>
                      </Form.Item>
                    </Col>
                  </Row>
                  </div>
                }
                { (getFieldValue('asset').type === 'comment' || 
                  getFieldValue('asset').type === 'update' ||
                  getFieldValue('asset').type == 'hazard' ||
                  getFieldValue('asset').type == 'induction' ||
                  getFieldValue('asset').type === 'toolbox_meeting'
                  ) &&
                    <Row>
                     <Col xs={{span: 24}} md={{span: 24}} >
                      <Form.Item
                        name={['asset', 'comments']}
                        className="isoInputWrapper"
                        label={<IntlMessages id="healthSafety.history.comment" />}
                      >
                        <Input.TextArea />
                      </Form.Item>
                      </Col>
                    </Row>           
            
                  }
                  {(getFieldValue('asset').type === 'comment' || 
                    getFieldValue('asset').type === 'update' ||
                    getFieldValue('asset').type == 'safety_walk'||
                    getFieldValue('asset').type === 'toolbox_meeting'
                  ) &&
                  
                      <Row>
                        <Col xs={{span: 24}} md={{span: 24}} >
                          <Form.Item
                            name={['asset', 'attachment']}
                            className="isoInputWrapper"
                            label={<IntlMessages id="propertyPage.modal.asset.attachmentPolicy" />}
                          >
                            <Upload {...uploadPolicyProps}>
                              <Button icon={<UploadOutlined />}>Upload</Button>
                            </Upload>
                          
                          </Form.Item>
                        </Col>
                      </Row>
                    }

                    {(getFieldValue('asset').type == 'safety_walk' ) &&
                      <Row>
                        <Col xs={{span: 24}} md={{span: 24}}>
                          <Form.Item
                            name={['asset', 'reporter']}
                            className="isoInputWrapper"
                            label={<IntlMessages id="healthSafety.history.reporter" />}
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Reporter"/>
                          </Form.Item>
                        </Col>
                      </Row>  
                    }
                    {(getFieldValue('asset').type == 'safety_walk' ||  getFieldValue('asset').type === 'meeting' ) &&
                      <Row>
                       <Col xs={{span: 24}} md={{span: 24}} >
                            <Form.Item
                              name={['asset', 'notes']}
                              className="isoInputWrapper"
                              label={<IntlMessages id="healthSafety.history.notes" />}
                            >
                              <Input.TextArea />
                            </Form.Item>
                          </Col>
                      </Row>
                    }

                    {(getFieldValue('asset').type == 'comment' || 
                      getFieldValue('asset').type == 'hazard' )&&
                      <div>
                        <Map ref={mapRef} center={position[0] !== null ? position : false} doubleClickZoom={false} onclick={handleClickMap} zoom={16} zoomControl={true} style={{width:'100%', height:'300px'}}>
                           <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {valueLat  && <Marker position={[valueLat,valueLng]} draggable={true}>
                                <Popup position={[valueLat,valueLng]}>
                                </Popup>
                            </Marker>}
                        </Map>
                        <Row style={{marginTop:"10px", marginBottom:"5px"}}>
                            <Col xs={{span: 24}} md={{span: 11}} >
                                <Input value={valueLat}/>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 11, offset: 2}} >
                                <Input value={valueLng}/>
                            </Col>
                        </Row>
                      </div>
                    }
              </div>
            )
          }
        }}

      </Form.Item>
        
      </div>
      </Form>

    </ModalActivityWrap>
  );
}

export default ModalActivity;
