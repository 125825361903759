import styled from "styled-components";

export const NearMissFormWrapper = styled.div`
  padding: 20px;
  .ant-form-item {
    margin-bottom: 0px;
  }
  .ant-form-item:not(.no-style){
    margin-bottom: 24px
  }
  .type-of-concern{
    display: block;
    span {
      font-size: 14px !important;
      font-weight: 400 !important;
      color: black !important;
  
    } 
  }
  span.ant-checkbox-inner {
    border: 1px solid #d9d9d9 !important;
    &:hover {
      border: 1px solid #f80e46 !important;
    }
}

.ant-checkbox-checked .ant-checkbox-inner  {
  border-color: rgb(248, 14, 70) !important;
  background-color: rgb(248, 14, 70) !important;
}

.btn-success{
background-color: #f80e46;
border-color: #f80e46;
padding: 8px;
color: white;
font-size: 12px;
height: auto;
border-radius: 8px;
}
`;
