import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import IntlMessages from "@iso/components/utility/intlMessages";
import SignUpStyleWrapper from "./SignUp.styles";
import { userService } from "@iso/services";
import { Form, Input, Button, Checkbox } from "antd";
import queryString from "query-string";
import { SendOutlined } from "@ant-design/icons";
import Alert from "@iso/components/Feedback/Alert";
import logo from "@iso/assets/images/logo.png";
// import ReCAPTCHA from "react-google-recaptcha";
import ReCAPTCHA from "react-google-recaptcha-enterprise";

const SignUp = (props) => {
  const [captchaToken, setCaptchaToken] = useState("");
  const [form] = Form.useForm();
  const [showMsg, setShowMsg] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState("abc11@yopmail.com");
  const [msgAlert, setMsgAlert] = React.useState("");
  const token = queryString.parse(window.location.search).token || "";
  const formToken = queryString.parse(window.location.search).form_token || "";
  const email = queryString.parse(window.location.search).email || "";
  let history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const sharingToken =
    queryString.parse(window.location.search).sharing_token || "";
  const [fields, setFields] = useState([]);

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  useEffect(() => {
    setFields([
      {
        name: "email",
        value: decodeURIComponent(email),
      },
      {
        name: "is_read_agree_terms_conditions",
        value: true,
      },
      {
        name: "is_read_agree_terms_conditions",
        value: true,
      },
    ]);
  }, []);

  const onFinish = (values) => {
   
    setLoading(true);
    const data = {
      user: values,
      token,
      form_token: formToken,
      sharing_token: sharingToken,
      request_from: "safefarm",
    };
    setUserEmail(values.email);
    userService.registers(data).then((res) => {
      if (res.code === "0000") {
        if (token) {
          history.push("/dashboard");
        } else {
          setLoading(false);
          setShowMsg(true);
        }
      }
    });
  };

  const resendEmail = () => {
    userService.resendEmailConfirm(userEmail, "safefarm").then((res) => {
      if (res.code === "0000") {
        setMsgAlert("Your request has been sent");
      }
    });
    setTimeout(() => {
      setMsgAlert("");
    }, 3000);
  };

  return (
    <SignUpStyleWrapper className="isoSignUpPage">
      <div className={`isoSignUpContentWrapper ${showMsg ? `w-unset` : ``}`}>
        <div className="isoSignUpContent">
          {showMsg ? (
            <div className="showMsg">
              <div className="icon-content">
                <Link to="/signin">
                  {/* <img src={icon} /> */}
                  <SendOutlined style={{ fontSize: "40px" }} />
                </Link>
              </div>
              <div className="text-content">
                <h2>We've sent an email to {userEmail}</h2>
                <h2>
                  Click the confirmation link in that email to begin using
                  Safefarm.
                </h2>
                <div className="msg-helper">
                  {msgAlert && (
                    <Alert
                      message={msgAlert}
                      type="success"
                      style={{ marginBottom: "10px" }}
                    />
                  )}
                  <p>if you did not receive the email,</p>
                  <p className="link-resend" onClick={resendEmail}>
                    Resend another email
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="signup-form-main">
              <div className="isoLogoWrapper">
                <Link to="/dashboard">
                  <img src={logo} height="100" />
                </Link>
              </div>
              <div className="isoSignUpForm">
                <Form
                  form={form}
                  name="register"
                  onFinish={onFinish}
                  scrollToFirstError
                  fields={fields}
                >
                  <Form.Item
                    name="name"
                    className="isoInputWrapper form-field"
                  
                    rules={[
                      {
                        required: true,
                        message: "Please input your Name!",
                      },
                      {
                        whitespace: true,
                        message: "No blank space",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="name"
                      className="signup-placeholder"
                    />
                  </Form.Item>

                  <Form.Item
                    name="email"
                   
                    className="isoInputWrapper form-field"
                    autocomplete="off"
                    style={email ? { pointerEvents: "none" } : {}}
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid Email!",
                      },
                      {
                        required: true,
                        message: "Please input your Email!",
                      },
                      () => ({
                        validator(rule, value) {
                          if (value) return userService.checkEmailExists(value);
                          else return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      size="large"
                      autoComplete="off"
                      placeholder="youremal@gmail.com"
                      className="signup-placeholder"
                    />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    className="isoInputWrapper form-field"
                  
                    rules={[
                      {
                        required: true,
                        message: "Please input your Password!",
                      },
                      {
                        min: 6,
                        message: "Password must be minimum 6 characters.",
                      },
                    ]}
                  >
                    <Input.Password
                      type="password"
                      size="large"
                      placeholder="Enter your password"
                      className="signup-placeholder password-placeholder"
                      style={{ padding: "15px" }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="mobile_phone"
                    className="isoInputWrapper form-field"
                    
                    rules={[
                      {
                        required: true,
                        message: "Please input your Mobile Number!",
                      },
                      {
                        min: 10,
                        max: 10,
                        message: "Mobile Number must be 10 characters.",
                      },
                      {
                        type: "tel",
                        message: "Mobile Number must be in Number",
                      },
                    ]}
                  >
                    <Input
                     onKeyDown={(e) => {
                      // Allow only numbers (0-9) and specific control keys
                      if (!/^\d+$/.test(e.key) && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                      size="large"
                      placeholder="Mobile Number"
                      className="signup-placeholder"
                    />
                  </Form.Item>

                  <Form.Item
                    name="is_read_agree_terms_conditions"
                    className="isoInputWrapper tc"
                    valuePropName="checked"
                    style={{ marginBottom: "12px" }}
                    rules={[
                      {
                        required: true,
                        message: "Please accept the terms & conditions",
                      },
                      () => ({
                        validator(rule, value) {
                          if (value) return Promise.resolve();
                          else
                            return Promise.reject(
                              "Please accept the terms & conditions"
                            );
                        },
                      }),
                    ]}
                  >
                    <Checkbox>
                      I have read and agree to the
                      <Link
                        to="https://www.airagri.com.au/termsconditions/"
                        title="terms and condtions"
                        style={{ color: "#F80E46", paddingLeft: "8px" }}
                      >
                        terms and condtions
                      </Link>
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="is_partners_specific"
                    className="isoInputWrapper"
                    valuePropName="checked"
                  >
                    <Checkbox>
                      I wish to opt in to receive offers from our partners /
                      specific to farming
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="recaptcha"
                    className="isoInputWrapper"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please verify reCaptcha",
                    //   },
                    // ]}
                  >
                    <ReCAPTCHA
                      sitekey="6Lcf5pYmAAAAAHVhwKYsa8j5gG-LPgBQZ16Rwmh4"
                      onChange={handleCaptchaChange}
                      size="invisible"
                    />
                  </Form.Item>

                  <Form.Item style={{ marginBottom: "16px" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="btn-success"
                      style={{ backgroundColor: "#535763" }}
                      loading={loading}
                    >
                      <IntlMessages id="page.signUpButton" />
                    </Button>
                  </Form.Item>

                  <div className="isoInputWrapper isoCenterComponent isoHelperWrapper">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        className="signin-span"
                        style={{ color: "#3F3F44", fontWeight: 500 }}
                      >
                        Already have an account?
                      </span>
                      <Link
                        className="signup-btn"
                        to="/signin"
                        style={{
                          paddingLeft: "9px",
                          color: "#F80E46",
                          fontWeight: 500,
                          textDecoration: "underline",
                        }}
                      >
                        Sign In
                      </Link>
                    </div>
                    
                  </div>
                </Form>
              </div>
            </div>
          )}
        </div>
      </div>
    </SignUpStyleWrapper>
  );
};

export default SignUp;
