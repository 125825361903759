import React from "react";
import { Link } from "react-router-dom";
import IntlMessages from "@iso/components/utility/intlMessages";
import ForgotPassword from "./ForgotPassword.styles";
import Alert from "@iso/components/Feedback/Alert";
import { Form, Input, Button } from "antd";
import { userService } from "@iso/services";
import logo from "@iso/assets/images/logo.png";

export default function () {
  const [msgAlert, setMsgAlert] = React.useState("");
  const [typeAlert, setTypeAlert] = React.useState("");
  const [form] = Form.useForm();
  const marginBot = {
    marginBottom: "10px",
  };

  const onFinish = (values) => {
    userService.forgotPassword(values.email, "safefarm").then((res) => {
      if (res.code === "0000") {
        setTypeAlert("success");
        setMsgAlert("Your request has been sent");
      } else {
        setTypeAlert("error");
        setMsgAlert(res.message);
      }
      form.resetFields();
    });
    const forgotPwd = setTimeout(() => {
      setMsgAlert("");
    }, 5000);
    return () => {
      clearTimeout(forgotPwd);
    };
  };

  return (
    <ForgotPassword className="isoForgotPassPage">
      <div className="isoFormContentWrapper">
        <div className="isoFormContent">
          <div className="isoLogoWrapper">
            <Link to="/dashboard">
              <img src={logo} height="100" />
            </Link>
          </div>

          <div className="isoFormHeadText">
            <h3 style={{ fontSize: "18px" }}>
              <IntlMessages id="page.forgotPasswordSubTitle" />
            </h3>
            <p style={{ textAlign: "right", paddingTop: "5px" }}>
              <IntlMessages id="page.forgotPasswordDescription" />
            </p>
          </div>
          {msgAlert && (
            <Alert message={msgAlert} type={typeAlert} style={marginBot} />
          )}
          <div className="isoForgotPassForm">
            <Form
              form={form}
              name="register"
              onFinish={onFinish}
              scrollToFirstError
            >
              <Form.Item
                name="email"
                className="isoInputWrapper"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid Email!",
                  },
                  {
                    required: true,
                    message: "Please input your Email!",
                  },
                ]}
              >
                <Input size="large" placeholder="Email" />
              </Form.Item>

              <div className="isoInputWrapper">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-success"
                  style={{ background: "rgb(83, 87, 99)", border: "unset" }}
                >
                  <IntlMessages id="page.resetPassSubTitle" />
                </Button>
              </div>
            </Form>
            <div className="isoCenterComponent isoHelperWrapper">
              <Link to="/signin" className="isoForgotPass">
                <IntlMessages id="page.resendEmailBackToSignIn" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </ForgotPassword>
  );
}
