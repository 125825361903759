import React,{useEffect,useState} from 'react';
import ListNotificationWrapper from './ListNotification.styles';
import _ from 'lodash';
import { Row,Col,Button } from 'antd';
import { pushnotification } from '@iso/services';
import moment from 'moment';

const ListNotification = (props) => {
  const {dataNotification,getDataNotification, currentUser} = props;
  const [showNoNotifications, setShowNoNotifications] = useState(true);
  const onMarkRead = (id) => {
    const data = {
      "user_id": currentUser.id,
      "notification_id": id
    }
    pushnotification.markRead(data).then(res =>{
      if(res.code === "0000"){
        getDataNotification();
      }
    });
  }
  useEffect(() => {
    // Hide the "No Notifications" message after 3 seconds
    const timeout = setTimeout(() => {
      setShowNoNotifications(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  
  const renderListNotification = () => {
    return(
      <div className="notification-data-wrap"> 
        {
          (dataNotification.length > 0) ?
            dataNotification.map((data, index) => {
              return (
                <div className="notification-data-item">
                  <Row>
                    <Col span={24}> <h3>{data.property_name} </h3></Col>
                    <Col span={17}> <h4>{data.type}</h4></Col>
                    <Col span={7}><Button onClick={() => onMarkRead(data.id)}>Mark Read</Button></Col>
                  </Row>
                  <div className="notification-body"><b>{data.data.user_name_send}</b> <span>{data.data.body}</span></div>
                  <div className="notification-date"><i>{moment(data.created_at).format('lll')}</i></div>
                </div>
              )
            })
          :
          
          (
            showNoNotifications && <span>No Notifications</span>
          )}
        
      </div>
    )
   
  }

  return (
    <ListNotificationWrapper> 
      {renderListNotification()}
    </ListNotificationWrapper>
  );

}
export default ListNotification;