import React, { useEffect, useState } from "react";
import SARModalWrapper from "./SARModal.styles";
import IntlMessages from "@iso/components/utility/intlMessages";
import Box from "@iso/components/utility/box";
import {
  Button,
  Form,
  Input,
  DatePicker,
  TimePicker,
  Select,
  Collapse,
  Table,
} from "antd";
import { sarService } from "@iso/services";
import moment from "moment";

const SARModal = ({ modalSAROpen, setModalSAROpen }) => {
  const [getSARUsers, setGetSARUsers] = useState([]);
  const [getSAR, setGetSAR] = useState([]);
  const [loading, setLoading] = useState(false);
  const [terminateLoading, setTerminateLoading] = useState(false);
  const [paginationSize, setPaginationSize] = useState(5);
  const [openAccordion, setOpenAccordion] = useState([]);
  const [terminateId, setTerminateId] = useState();

  const [form] = Form.useForm();
  const { Panel } = Collapse;

  const handleTerminated = (id) => {
    setTerminateLoading(true);
    sarService.updateSAR(id).then((res) => {
      if (res.code === "0000") {
        sarService.getAllSAR().then((res) => {
          if (res.code === "0000") {
            setGetSAR(res.sar);
          }
        });
      }
      setTerminateLoading(false);
    });
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (item, { action, id }) => (
        <>
          {action ? (
            <Button
              type="primary"
              className="btn-danger"
              loading={terminateLoading && terminateId === id}
              onClick={() => {
                setTerminateId(id);
                handleTerminated(id);
              }}
            >
              Terminate
            </Button>
          ) : (
            ""
          )}
        </>
      ),
    },
  ];

  const resetForm = () => {
    setTimeout(() => {
      form.resetFields();
      // handleAddress();
      // setLoading(false);
      // setContactChecked(false);
      // setErrMsg("");
    }, 500);
  };

  const cancelModal = () => {
    setModalSAROpen(false);
    resetForm();
  };

  const handleSubmit = (e) => {
    form.validateFields().then(async (values) => {
      setLoading(true);
      const dataFormated = new Date(values.SARDate).toDateString();
      const timeFormated = new Date(values.SARTime).toLocaleTimeString();
      const property = localStorage.getItem("active_property");
      const payload = {
        name: values.SARName,
        assigned_to: values.UserName,
        date_time: new Date(`${dataFormated} ${timeFormated}`).toUTCString(),
        property_id: JSON.parse(property).id,
      };
      if (property) {
        sarService.addSar(payload).then((res) => {
          if (res.code === "0000") {
            setOpenAccordion([]);
            form.resetFields();
            sarService.getAllSAR().then((res) => {
              if (res.code === "0000") {
                setGetSAR(res.sar);
              }
            });
          }
          setLoading(false);
        });
      }
    });
  };

  useEffect(() => {
    const property = localStorage.getItem("active_property");
    if (property) {
      sarService.getAllSARUser(JSON.parse(property)?.id).then((res) => {
        if (res.code === "0000") {
          const selectData = res.users.map((item) => {
            return { value: item.id, label: item.name };
          });
          setGetSARUsers(selectData);
        }
      });
    }

    sarService.getAllSAR().then((res) => {
      if (res.code === "0000") {
        setGetSAR(res.sar);
      }
    });
  }, []);

  return (

    <SARModalWrapper
      forceRender={true}
      open={modalSAROpen}
      title={<IntlMessages id="topbar.SAR" />}
      onCancel={cancelModal}
      maskClosable={true}
      footer={[]}
    >
      <Collapse
        activeKey={openAccordion}
        onChange={(e) => setOpenAccordion(e)}
      >
        <Panel header="Add SAR" key="Add SAR">
          <Form
            initialValues={{ SARDate: moment(), SARTime: moment() }}
            form={form}
            name="SAR"
            layout="vertical"
            scrollToFirstError
          >
            <div className="form-body">
              <Form.Item
                name="SARName"
                className="isoInputWrapper"
                label="SAR Name"
                rules={[
                  {
                    required: true,
                    message: "Please input your SAR Name",
                  },
                  {
                    max: 255,
                    message: "SAR Name must be maximum 255 characters",
                  },
                ]}
              >
                <Input
                  className="pac-target-input"
                  size="large"
                  placeholder="Input your SAR Name"
                />
              </Form.Item>
              <Form.Item
                name="UserName"
                className="isoInputWrapper"
                label="User Name"
                rules={[
                  {
                    required: true,
                    message: "Please input your User Name",
                  },
                ]}
              >
                <Select
                  className="pac-target-input"
                  size="large"
                  options={getSARUsers}
                />
              </Form.Item>
              <Form.Item
                name="SARDate"
                className="isoInputWrapper"
                label="SAR Date"
                rules={[
                  {
                    required: true,
                    message: "Please input your SAR Date",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  className="pac-target-input"
                  size="large"
                />
              </Form.Item>
              <Form.Item
                name="SARTime"
                label="SAR Time"
                rules={[
                  {
                    required: true,
                    message: "Please input your SAR Time",
                  },
                ]}
              >
                <TimePicker
                  className="pac-target-input"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </div>
            <div className="footer-btn">
              <Button
                key="back"
                onClick={cancelModal}

                className="btn-cancel"
              >
                {<IntlMessages id="topbar.cancel" />}
              </Button>

              <Button
                key="submit"
                className="btn-success"
                style={{
                  background: "rgb(248, 14, 70)",
                  borderColor: 'rgb(248, 14, 70)',
                  borderRadius: "12px !important",
                }}
                type="primary"
                loading={loading}
                onClick={handleSubmit}
              >
                {<IntlMessages id="topbar.save" />}
              </Button>
            </div>
          </Form>
        </Panel>
      </Collapse>
      {/* <Box> */}
      <Table
        dataSource={getSAR.map((item) => {
          return {
            key: item.id,  // Add key prop here
            id: item.id,
            name: item.name,
            date: item.date_time,
            action: item.terminated === 0 ? true : false,
          };
        })}
        columns={columns}
        pagination={{
          pageSize: paginationSize,
          pageSizeOptions: [5, 10, 15, 20, 25],
          showSizeChanger: true, // Remove the double quotes around true
        }}
        onChange={(e) => setPaginationSize(e.pageSize)}
      />

      {/* </Box> */}
    </SARModalWrapper>

  );
};

export default SARModal;
