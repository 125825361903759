import React,{ useState, useEffect } from 'react';
import { Provider } from 'react-redux';
// import GlobalStyles from '@iso/assets/styles/globalStyle';
import GlobalStyles  from '../src/assets/styles/globalStyle'
import { store } from './redux/store';
import Boot from './redux/boot';
import Main from './Layout/Main';
import AppProvider from './AppProvider';
import { BrowserRouter } from "react-router-dom";
// import { fetchToken, onMessageListener } from './firebase';



const App = () => {

  // const [notification, setNotification] = useState('');

  // fetchToken();

  // useEffect(() => {
  //   console.log(notification);
  // }, [notification]);

  // onMessageListener().then(payload => {
  //   setNotification(payload);
  // }).catch(err => console.log('failed: ', err));


  return (
  <Provider store={store}>
      <BrowserRouter>
        <AppProvider>
          <GlobalStyles />
          <Main />
        </AppProvider>
      </BrowserRouter>
  </Provider>
  )
};
Boot()
  .then(() => App())
  .catch(error => console.error(error));

export default App;


