import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Form, Input, Button, Select, Row, Col, DatePicker, Divider, Card, Timeline, Upload, message } from 'antd';
import Box from '@iso/components/utility/box';
// import HazardsModalWrap from './ModalActivity.styles';
import IntlMessages from '@iso/components/utility/intlMessages';
import { activityPolicy } from '@iso/constants/activityPolicy';
import { hazardTypes } from '@iso/constants/hazardTypes';
import { hazardstatus } from '../../../constants/hazardstatus';
import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { propertyService, healthsafety, attachment, assetService } from '@iso/services';
import _ from 'lodash';
import L from 'leaflet';
import { useSelector, useDispatch } from 'react-redux';
import { dateHelper } from '@iso/lib/helpers/dateHelper';
import moment from 'moment';
import { Map, TileLayer, FeatureGroup, Polygon, Tooltip, ZoomControl, Marker, Popup } from 'react-leaflet';
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import appActions from '@iso/redux/app/actions';
import notificationActions from '@iso/redux/notification/actions';
import MapLocationModal from '../HealthSafety/MapLocationModal';
// import './ModalActivity.styles.css'
import '../HealthSafety/ModalActivity.styles.css';
// import HealthSafetyWrapper from './Dashboard.styles';
import AddModalWrap from '../HealthSafety/ModalAddWrap.styles';
import VisitorModal from '../Visitor/VisitorModal'
import FormModalWrapper from '../Form/FormModal.styles';
const { changeCurrent } = appActions;

const { notificationData } = notificationActions;

const { Option } = Select;

const HazardsModal = (props) => {
  const mapRef = useRef();
  const [editData, setEditData] = useState([]);
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);

  const [valueLat, setValueLat] = useState();
  const [valueLng, setValueLng] = useState();
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const currentUser = useSelector((state) => state.user.user);
  const [visibleModalActivity, setVisibleModalActivity] = useState(false);
  const [attachmentActivityId, setAttachmentActivityId] = useState([]);
  const [attendees, setAttendees] = useState([]);
  const [showHazardOther, setShowHazardOther] = useState(false);
  const [position, setPosition] = useState(null);
  const [assetData, setAssetData] = useState([]);
  const [visibleAttachLocation, setVisibleAttachLocation] = useState(false);
  const dispatch = useDispatch();
  const [userHistory, setUserHistory] = useState([]);
  const [status,setStatus] = useState('')
  const { activityActive, propertyId, editHazard, editId } = props;
  const user = JSON.parse(localStorage.getItem("user"));


  useEffect(() => {
    if (props.propertyId) {
      propertyService.getListUsersOfProperty(props.propertyId).then(res => {
        if (res.code === '0000') {
          setUserHistory(res.users);
        }
      });
    }
    // setEditData(props.editHazard ? props.editHazard : '')
  }, [props.propertyId]);

  const updatePropertyDetail = React.useCallback((id) => {
    propertyService.viewProperty(id).then(res => {
      if (res.code === '0000') {
        setPosition([res.property.lat, res.property.lng]);
      }
    })
  }, []);



  useEffect(() => {
    setFields([

      {
        name: ['asset', 'type'],
        value: activityActive,
      },
      {
        name: ['asset', 'hazard_type'],
        value: props.editHazard ? props.editHazard.hazard_type : '',
      },


      {
        name: ['asset', 'asset_id'],
        value: props.editHazard ? props.editHazard.asset_id : '',
      },

      {
        name: ['asset', 'date_time'],
        value: editHazard?.date_time
          ? moment(editHazard.date_time)
          : "",
      },
      {
        name: ['asset', 'actions'],
        value: props.editHazard ? props.editHazard.actions : '',
      },
      {
        name: ['asset', 'comments'],
        value: props.editHazard ? props.editHazard.comments : '',
      },
      {
        name: ['asset', 'status'],
        value: props.editHazard ? props.editHazard.status : '',
      },
    ])


    if (propertyId) {
      updatePropertyDetail(propertyId);
      getDataAsset(propertyId);
    }
    if (props.editHazard) {
      setValueLat(props.editHazard.point ? props.editHazard.point.lat : '');
      setValueLng(props.editHazard.point ? props.editHazard.point.lng : '');
    }
  }, [props, propertyId, activityActive, updatePropertyDetail]);





  // const updatePropertyDetail = (id) =>{
  //   propertyService.viewProperty(id).then(res => {

  //     if (res.code === '0000') {
  //       setPosition([res.property.lat, res.property.lng]);
  //     }
  //   })
  // }

  const getDataAsset = (propertyId) => {

    healthsafety.getHealthAndSafetyAssets(propertyId).then(res => {
      if (res.code === '0000') {

        setAssetData(res.assets);
      } else {
        message.error(res.message)
      }
    });
  }

  const handleChangeTag = (input_tags) => {
    setAttendees(input_tags);
  }



  const getUpdatedList = (propertyId) => {

    if (propertyId) {
      healthsafety.getActivityPolicyByProperty(propertyId).then(res => {
        if (res.code === '0000') {
          setUserHistory(res.users);
        }
      });
    }
  };

  const getDataPolicy = () => {
    if (activePropertyId) {
      healthsafety.getDataTypePolicy(activePropertyId).then(res => {
        if (res.code === '0000') {
          // setAssets(res.assets);
        }
      });
    }
  }

  const handleClickMap = (e) => {
    const { lat, lng } = e.latlng;
    setValueLng(lng);
    setValueLat(lat);
  };
  const uploadPolicyProps = {
    name: "Attachment[file]",
    action: apiEndpoint.attachments,
    headers: authHeader(true),
    listType: "picture",
    multiple: true,
    onChange: (response) => {
      if (response.file && response.file.status == "done") {
        setAttachmentActivityId(attachmentActivityId => [...attachmentActivityId, response.file.response.attachment.id]);
      }
    },
    onRemove: file => {
      attachment.destroy(file.uid);
    }

  };

  const getLatLng = (cLat, cLng) => {
    const lat = cLat;
    const lng = cLng;
    return new L.LatLng(
      lat,
      lng
    );
  }
  const renderActivityPolicy = () => {
    let options = [];
    _.forEach(activityPolicy, (activity, index) => {
      options.push(
        <Option key={index} value={activity.value}>
          <div>{activity.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a activity"
        allowClear
        size="large"
        disabled
      >
        {options}
      </Select>
    );
  }
  const handleChangeHazardType = (value) => {
    if (value === 'other') {
      setShowHazardOther(true);
    } else {
      setShowHazardOther(false);
    }
  }

  const renderHazardTypes = () => {
    let options = [];
    _.forEach(hazardTypes, (activity, index) => {
      options.push(
        <Option key={index} value={activity.value}>
          <div>{activity.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a activity"
        allowClear
        onChange={handleChangeHazardType}
        size="large"
      >
        {options}
      </Select>
    );
  }
  const renderOptionsAssets = (assets) => {
    let options = [];
    _.forEach(assets, (asset, index) => {

      options.push(
        <Option key={index} value={asset.id}>
          <div>{asset.title}</div>
        </Option>
      );

    })
    return (
      <Select
        placeholder="Select Assets"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }
  const handleStatus = (value) => {
    const selectedStatus = hazardstatus.find((status) => status.value === value);
    setStatus(selectedStatus ? selectedStatus.label : null);
  };

  const renderAssetStatus = () => {
    const options = hazardstatus.map((status, index) => (
      <Select.Option key={index} value={status.value}>
        {status.label}
      </Select.Option>
    ));

    return (
      <Select
        placeholder="Select status"
        value={status}
        allowClear
        onChange={(value) => handleStatus(value)}
        size="large"
      >
        {options}
      </Select>
    );
  };


  const handleSubmit = (e) => {

    setLoading(true);

    form
      .validateFields()

      .then(async (values) => {
        // values.id = editId ? editId:propertyId
        onStore(values);
      })
      .catch(info => {

        setLoading(false);
      });
  }
  const onStore = (values) => {
    
    var point = null;
    if (valueLat && valueLng) {
      point = getLatLng(valueLat, valueLng);
    }
    let hazarsdata = 'hazard';

    const dataPolicy = {
      policy: {
        ...values.asset,
        idFacilities: values.asset.asset_id,
        type: values.asset.type,
        // type: activityActive !== 'hazard' ? values.asset.type : hazarsdata,
        date_time: values.asset.date_time ? dateHelper.dateTimeForAPI(values.asset.date_time) : '',
        point: point,
        attendees: attendees,
        attachment_activity_id: attachmentActivityId,
      }
    }

    healthsafety.storeOrUpdateActivityPolicies(dataPolicy, editHazard ? editHazard.id : '').then(res => {

      if (res.code === '0000') {
        getDataAsset();
        setLoading(false);
        // if (!props.visibleModalActivity && !props.visibleHazardModal) {
        //   props.getProperties();
        //   props.cancelModalActivity()
        // }else{

        //  props.visibleHazardModal ? props.visibleHazardModal : props.setVisibleModalActivity(false);

        // }
        if (props.visibleModalActivity) {
          props.setVisibleModalActivity(false)
        } else if (props.visibleHazardModal) {
          props.setVisibleHazardModal(false)
        } else {
          props.getProperties();
          props.cancelModalActivity()
        }
        form.resetFields();


        setAttendees([]);
        setValueLat();
        setValueLng();
        setAttachmentActivityId([]);

        if (currentUser) {
          const notiData = {
            "type": "Activity",
            "data": {
              "title": "New Activity",
              "user_id_send": currentUser.id,
              "user_name_send": currentUser.name,
              "body": values.asset.type ? values.asset.type.replace(/_/g, ' ').replace(/(?: |\b)(\w)/g, l => l.toUpperCase()) : '',
              // .replace(/_/g, ' ').replace(/(?: |\b)(\w)/g, l => l.toUpperCase()),
            }
          }
          dispatch(notificationData(notiData));
        } else {

        }
      } else {
        setLoading(false);
      }
    });
  }
  const showModal = () => {
    // setVisibleAttachLocation(true);
    setVisibleModalActivity(true);

  }
  const handleCloseAttachLocation = () => {
    // setVisibleAttachLocation(false);
    setVisibleModalActivity(false)
  }

  const cancelModal = () => {
    // form.resetFields();

    if (props.visibleModalActivity) {
      props.setVisibleModalActivity(false);
    } else if (props.visibleHazardModal) {
      props.setVisibleHazardModal(false);
    } else {
      props.cancelModalActivity();
    }





  }

  return (
    <>

      {props.visibleModalActivity || props.visibleHazardModal ?
        <FormModalWrapper
          className="modal-title-style"
          forceRender={true}
          maskClosable={true}
          open={props.visibleModalActivity || props.visibleHazardModal}
          title="NEW HAZARD"
          onCancel={cancelModal}

          footer={[
            <Button key="back" onClick={cancelModal} className="cancel-button">
              {<IntlMessages id="propertyPage.modal.cancel" />}
            </Button>,
            <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit} loading={loading}>
              {<IntlMessages id="propertyPage.modal.save" />}
            </Button>]}
        >
          <AddModalWrap>
            <Box  style={{
              background: "#ffffff",
              /* Primary/Light Grey */
          
              border:" 1px solid #e5e5e5",
              boxSizing: "border-box",
              boxShadow: "1px 1px 10px rgba(229, 229, 229, 0.6)",
              borderRadius:'20px',
            }}>
              <Form
                


                form={form}
                layout="vertical"
                scrollToFirstError
                fields={fields}

                id="#1"
              >

                <div className="ctmForm">
                  <Form.Item
                    noStyle

                  >

                    <div>
                      <Row>
                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                          <Form.Item
                            name={['asset', 'type']}
                            className="isoInputWrapper"
                            label={<IntlMessages id="propertyPage.modal.asset.activityPolicy" />}
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            {renderActivityPolicy()}
                          </Form.Item>
                        </Col>
                      </Row>
                     
                      <Row>
                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                          <Form.Item
                            name={['asset', 'hazard_type']}
                            className="isoInputWrapper"
                            label={<IntlMessages id="Type" />}
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            {renderHazardTypes()}
                          </Form.Item>
                        </Col>
                      </Row>
                      {/* } */}

                      {showHazardOther &&
                        <Row>
                          <Col xs={{ span: 24 }} md={{ span: 24 }}>
                            <Form.Item
                              name={['asset', 'hazard_type_other']}
                              className="isoInputWrapper"
                              label={<IntlMessages id="healthSafety.history.hazardTypeOther" />}
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                },
                              ]}
                            >
                              <Input size="large" placeholder="Hazard type" />
                            </Form.Item>
                          </Col>
                        </Row>
                      }

                      <Row>
                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                          <Form.Item
                            name={['asset', 'asset_id']}
                            className="isoInputWrapper"
                            label={<IntlMessages id="healthSafety.history.assetName" />}
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            {renderOptionsAssets(assetData)}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                          <Form.Item
                            name={['asset', 'status']}
                            className="isoInputWrapper"
                            label={<IntlMessages id="propertyPage.modal.asset.activityPolicy" />}
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            {renderAssetStatus(hazardstatus)}
                          </Form.Item>
                        </Col>
                      </Row>
                      {/* {(getFieldValue('asset').type == 'safety_walk' ||
                      getFieldValue('asset').type == 'hazard' ||
                      getFieldValue('asset').type == 'meeting' ||
                      getFieldValue('asset').type == 'induction' ||
                      getFieldValue('asset').type === 'toolbox_meeting'
                    ) && */}
                      <Row>
                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                          <Form.Item
                            name={['asset', 'actions']}
                            className="isoInputWrapper"
                            label={<IntlMessages id="healthSafety.history.actions" />}
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            <Input.TextArea />
                          </Form.Item>
                        </Col>
                      </Row>
                      {/* } */}
                      <Row>
                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                          <Form.Item
                            name={['asset', 'date_time']}
                            className="isoInputWrapper"
                            label={<IntlMessages id="Date Found" />}
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            <DatePicker format="DD-MM-YYYY HH:mm:ss" size="large" style={{ width: '100%' }} showTime className="customDatePicker" />
                          </Form.Item>
                        </Col>
                      </Row>


                      <Row>
                        <Col xs={{ span: 24 }} md={{ span: 24 }} >
                          <Form.Item
                            name={['asset', 'comments']}
                            className="isoInputWrapper"
                            label={<IntlMessages id="healthSafety.history.comment" />}
                          >
                            <Input.TextArea />
                          </Form.Item>
                        </Col>
                      </Row>

                      {/* } */}


                      <Row>
                        <Col xs={{ span: 24 }} md={{ span: 24 }} >


                          <Form.Item className="isoInputWrapper">

                            <Button key="submit" type="primary" onClick={showModal} className='attachLocation' >
                              {<IntlMessages id="Attach Location" />}
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>




                    </div>

                  </Form.Item>

                </div>
              </Form>
            </Box>

          </AddModalWrap>

        </FormModalWrapper> :
        <AddModalWrap>
          <Box style={{
              background: "#ffffff",
              /* Primary/Light Grey */
          
              border:" 1px solid #e5e5e5",
              boxSizing: "border-box",
              boxShadow: "1px 1px 10px rgba(229, 229, 229, 0.6)",
              borderRadius:'20px',
            }}>
            <Form
              style={{ borderRadius: '8px' }}


              form={form}
              layout="vertical"
              scrollToFirstError
              fields={fields}

              id="#1"
            >

              <div className="hazardHeader">
                <h2 className="headerTitle">{props.editId}</h2>
              </div>

              <div className="ctmForm">
                <Form.Item
                  noStyle

                >
<div>
                    <Row>
                      <Col xs={{ span: 24 }} md={{ span: 24 }}>
                        <Form.Item
                          name={['asset', 'type']}
                          className="isoInputWrapper"
                          label={<IntlMessages id="propertyPage.modal.asset.activityPolicy" />}
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                            },
                          ]}
                        >
                          {renderActivityPolicy()}
                        </Form.Item>
                      </Col>
                    </Row>
                    {/* {getFieldValue('asset').type == 'hazard' && */}
                    <Row>
                      <Col xs={{ span: 24 }} md={{ span: 24 }}>
                        <Form.Item
                          name={['asset', 'hazard_type']}
                          className="isoInputWrapper"
                          label={<IntlMessages id="Type" />}
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                            },
                          ]}
                        >
                          {renderHazardTypes()}
                        </Form.Item>
                      </Col>
                    </Row>
                    {/* } */}

                    {showHazardOther &&
                      <Row>
                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                          <Form.Item
                            name={['asset', 'hazard_type_other']}
                            className="isoInputWrapper"
                            label={<IntlMessages id="healthSafety.history.hazardTypeOther" />}
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Hazard type" />
                          </Form.Item>
                        </Col>
                      </Row>
                    }

                    <Row>
                      <Col xs={{ span: 24 }} md={{ span: 24 }}>
                        <Form.Item
                          name={['asset', 'asset_id']}
                          className="isoInputWrapper"
                          label={<IntlMessages id="healthSafety.history.assetName" />}
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                            },
                          ]}
                        >
                          {renderOptionsAssets(assetData)}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                          <Form.Item
                            name={['asset', 'status']}
                            className="isoInputWrapper"
                            label={<IntlMessages id="Status" />}
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            {renderAssetStatus(hazardstatus)}
                          </Form.Item>
                        </Col>
                      </Row>
                    <Row>
                      <Col xs={{ span: 24 }} md={{ span: 24 }}>
                        <Form.Item
                          name={['asset', 'actions']}
                          className="isoInputWrapper"
                          label={<IntlMessages id="healthSafety.history.actions" />}
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                            },
                          ]}
                        >
                          <Input.TextArea />
                        </Form.Item>
                      </Col>
                    </Row>
                    {/* } */}
                    <Row>
                      <Col xs={{ span: 24 }} md={{ span: 24 }}>
                        <Form.Item
                          name={['asset', 'date_time']}
                          className="isoInputWrapper"
                          label={<IntlMessages id="Date Found" />}
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                            },
                          ]}
                        >
                          <DatePicker format="DD-MM-YYYY HH:mm:ss" size="large" style={{ width: '100%' }} showTime />
                        </Form.Item>
                      </Col>
                    </Row>



                    {/* {(getFieldValue('asset').type === 'comment' ||
                      getFieldValue('asset').type === 'update' ||
                      getFieldValue('asset').type == 'hazard' ||
                      getFieldValue('asset').type == 'induction' ||
                      getFieldValue('asset').type === 'toolbox_meeting'
                    ) && */}
                    <Row>
                      <Col xs={{ span: 24 }} md={{ span: 24 }} >
                        <Form.Item
                          name={['asset', 'comments']}
                          className="isoInputWrapper"
                          label={<IntlMessages id="healthSafety.history.comment" />}
                        >
                          <Input.TextArea />
                        </Form.Item>
                      </Col>
                    </Row>

                    {/* } */}


                    <Row>
                      <Col xs={{ span: 24 }} md={{ span: 24 }} >


                        <Form.Item className="isoInputWrapper">

                          <Button key="submit" type="primary" onClick={showModal} className='attachLocation' >
                            {<IntlMessages id="Attach Location" />}
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>


                    <Divider />
                    <Row>
                      <Col xs={{ span: 24 }} md={{ span: 24 }} >

                        {editId == 'NEW HAZARD' ?
                          <Button key="submit" className="addHazards" type="primary" onClick={handleSubmit} loading={loading}>
                            <IntlMessages id={editId == 'NEW HAZARD' ? "Add Hazard" : 'Save'} />
                          </Button> :
                          <>
                            <Button key="back" onClick={props.cancelModalActivity} className="EditHazards">
                              {<IntlMessages id="propertyPage.modal.cancel" />}
                            </Button>
                            <Button key="submit" className="EditHazards" type="primary" onClick={handleSubmit} loading={loading}>
                              {<IntlMessages id="propertyPage.modal.save" />}
                            </Button></>}

                      </Col>
                    </Row>
                  
                  </div>

                </Form.Item>

              </div>
            </Form>
          </Box>

        </AddModalWrap>}

      <MapLocationModal
        visibleVisitorModal={visibleModalActivity}
        cancelModal={handleCloseAttachLocation}
        mapRef={mapRef}
        position={position}
        setPosition={setPosition}
        handleClickMap={handleClickMap}
        valueLat={valueLat}
        setValueLat={setValueLat}
        setValueLng={setValueLng}
        valueLng={valueLng}
      >

      </MapLocationModal>
    </>
  );
}

export default HazardsModal;