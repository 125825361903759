import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@iso/components/utility/box";
import ButtonGroupWrapper from "./ButtonGroup.styles";
import ModalActivity from "./ModalActivity";
import { Row, Col, Button, Modal, message } from "antd";
import { healthsafety, assetService } from "@iso/services";
import _ from "lodash";
import IntlMessages from "@iso/components/utility/intlMessages";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import appActions from "@iso/redux/app/actions";
import { propertyService } from "@iso/services";
import styled from "styled-components";
import HazardsModal from "../Hazards/HazardsModal";
import FormModal from "../Form/FormModal";

const { changeCurrent } = appActions;

const ButtonGroup = (props) => {
  const { propertyId, showModalHealthSafety } = props;
  
  const currentUser = useSelector((state) => state.user.user);
  const [type, setType] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fields, setFields] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [visibleHazardModal, setVisibleHazardModal] = useState(false);
  const [visibleModalActivity, setVisibleModalActivity] = useState(false);
  const [ visibleFormModal, setVisiblityFormModal ]=React.useState( false );
  const [activityActive, setActivityActive] = useState();

  const handleRedirectPage = (e, path) => {
    history.push(e);
    // history.push("/property-map");
    dispatch(changeCurrent([path]));
  };
  const cancelModalActivity = () => {
    setVisibleModalActivity(false);
  };
  const showModalActivity = (value) => {
    setVisibleModalActivity(true);
    setActivityActive();
    if (value) {
      setActivityActive(value);
    }
  };
  const showHazardmodal = () => {
    setVisibleHazardModal(true);
  };
  const handleOpenModal = () => {
    setVisiblityFormModal(true);
  };
  const cancelModal = () => {
    setVisiblityFormModal(false);
    // setFormData({});
  };
  return (
    <ButtonGroupWrapper>
      <Box className="statistic-card">
        <Row gutter={[10, 15]}>
          <Col
            xs={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            onClick={() => showModalActivity("safety_walk")}
          >
            <Button block className="ant-btn--sf-dashboard">
              <span>
                <IntlMessages id="safeFarmPage.dashboard.safetyWalk" />
              </span>
            </Button>
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <Button
              className="ant-btn--sf-dashboard"
              block
              // onClick={() => handleRedirectPage('hazards','hazards')}
              onClick={() => showHazardmodal()}
            >
              <span>
                <IntlMessages id="safeFarmPage.dashboard.hazardRisk" />
              </span>
            </Button>
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <Button
              className="ant-btn--sf-dashboard"
              block
              onClick={() => showModalActivity()}
            >
              <span>
                <IntlMessages id="dashboard.historyChart.title" />
              </span>
            </Button>
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <Button
              className="ant-btn--sf-dashboard"
              block
              onClick={() => showModalHealthSafety()}
            >
              <span>
                <IntlMessages id="healthSafety.addHealthSafety" />{" "}
              </span>
            </Button>
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <Button
              className="ant-btn--sf-dashboard"
              block
              onClick={handleOpenModal}
              // onClick={() => handleRedirectPage('manage-forms','')}
            >
              <span>
                <IntlMessages id="safeFarmPage.dashboard.forms" />
              </span>
            </Button>
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <Button
              className="ant-btn--sf-dashboard"
              block
              onClick={() => handleRedirectPage("property-map", "property-map")}
            >
              <span>
                <IntlMessages id="sidebar.propertyMap" />
              </span>
            </Button>
          </Col>
        </Row>
      </Box>

      <ModalActivity
        cancelModalActivity={cancelModalActivity}
        visibleModalActivity={visibleModalActivity}
        propertyId={propertyId}
        activityActive={activityActive}
        getDataPolicy = {props.getDataPolicy}
        search={props.search}
      />
     <FormModal
     cancelModal ={cancelModal}
     visibleFormModal ={visibleFormModal}
     propertyId  ={propertyId}
     fromProperty={true}
     />

      {visibleHazardModal ? (
        <HazardsModal
          visibleHazardModal={visibleHazardModal}
          setVisibleHazardModal={setVisibleHazardModal}
          propertyId={propertyId}
          activityActive="hazard"
        />
      ) : (
        ""
      )}
    </ButtonGroupWrapper>
  );
};

export default ButtonGroup;
