
import React,{ useEffect, useState, useRef } from 'react';
import LayoutWrapper from '@iso/components/utility/layoutWrapper.js';
import PageHeader from '@iso/components/utility/pageHeader';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Button, Alert, Checkbox, Row, Col, Form, message } from 'antd';
import FormPage from './Form.styles';
import queryString from 'query-string';
import { userFormService, formService } from '@iso/services';
import _ from 'lodash';
import * as Survey from "survey-react";
import "survey-react/survey.css";
import "survey-react/modern.css";
import SignatureCanvas from 'react-signature-canvas';
import FinalScreen from './FinalScreen'
import FormBuilder from 'antd-form-builder';
import GlobalForm from './GlobalForm'
import BioForm from '@iso/containers/Pages/AdminPages/FormBuilder/BioForm/BioForm';
import CheckinForm from '@iso/containers/Pages/AdminPages/FormBuilder/CheckinForm/CheckinForm';

Survey.StylesManager.applyTheme("modern");

const FormSubmission = () => {
	const padRef = useRef({});
	const token = queryString.parse(window.location.search).token || '';
	const [form, setForm] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');
	const [surveyContent, setSurveyContent] = useState({});
  	const [questionList, setQuestionList] = useState([]);
  	const [loading, setLoading] = useState(false);
  	const [pdfForm] = Form.useForm();
  const [showFinalScreen, setShowFinalScreen] = useState(false)
  const formRef = React.createRef()
  const [globalForm] = Form.useForm()

  	const checkFormSubmission = (token) => {
  		userFormService.checkFormSubmission(token).then(res => {
            if(res.code === '0000')
            {
            	setForm(res.form);
              	let questions = [];
	          _.forEach(res.form.surveyQuestions, (question, index) => {
	            switch(question.question_type){
	              case 'yes/no':
	                var data = {
	                  name: "page_" + question.id,
	                  questions: [
	                    {
	                      type: "radiogroup",
	                      choices: ["Yes", "No"],
	                      isRequired: true,
	                      name: (question.id).toString(),
	                      title: question.title
	                    }
	                  ]
	                };
	                break;
	              case 'scale':
	                var data = {
	                  name: "page_" + question.id,
	                  questions: [
	                    {
	                      type: "radiogroup",
	                      choices: ["Strongly Disagree", "Disagree", "Neutral", "Agree", "Strongly Agree"],
	                      isRequired: true,
	                      name: (question.id).toString(),
	                      title: question.title
	                    }
	                  ]
	                };
	                break;
	              default:
	                var data = {
	                  name: "page_" + question.id,
	                  questions: [
	                    {
	                      type: "comment",
	                      isRequired: true,
	                      name: (question.id).toString(),
	                      title: question.title
	                    }
	                  ]
	                };
	                break;
	            }

	            questions.push(data);
	          });
	          	setQuestionList(questions);
            }else{
                setErrorMsg(res.message)
            }
        })
  	}

	useEffect(() => {
        checkFormSubmission(token);
    }, []);

    useEffect(() => {
	    if(questionList && form)
	    {
	      var surveyJSON = {
	        title: form.survey_title,
	        description: form.survey_description,
	        completedHtml: "<h3>Thank you for completing the survey!</h3>",
	        showCompletedPage: false,
	        pages: questionList
	    }

      	setSurveyContent(surveyJSON);
    } 
  }, [questionList, form])

	const submitSurveyData = (survey) => {
	    const data = {
	      form: {
	        survey: survey.data,
	        type: 'self_create',
	        signature_path: padRef.current && Object.keys(padRef.current).length === 0 && padRef.current.constructor === Object ? '' : padRef.current.toDataURL('image/png')
	      }
	    }

	    formService.storeOrUpdate(data, form.id).then(res => {
	      if(res.code === '0000'){
	        message.success('Submit success')
	        setShowFinalScreen(true)
	      }else{
	        message.error(res.message)
	      }
	    });
	}

	const handleSubmit = (e) => {
	    setLoading(true);

	    pdfForm
	      .validateFields()
	      .then(values => {
	        setLoading(true);
	        onStore(values);
	      })
	      .catch(info => {
	        console.log('Validate Failed:', info);
	        setLoading(false);
	      });
	  }

	  const onStore = (values) => {
	    const data = {
	      form: {
	      	...values.form,
	        type: 'pdf_upload',
	        signature_path: padRef.current && Object.keys(padRef.current).length === 0 && padRef.current.constructor === Object ? '' : padRef.current.toDataURL('image/png')
	      }
	    }

	    formService.storeOrUpdate(data, form.id).then(res => {
	      if(res.code === '0000'){
	        message.success('Submit success')
	      	checkFormSubmission(token)
	        pdfForm.resetFields();
	        setLoading(false)
	        setShowFinalScreen(true)
	      }else{
	        message.error(res.message)
	        setLoading(false)
	      }
	    });
	  }

	const handleClear = () => {
	    padRef.current.clear();
	}

	const handleSubmitGlobalForm = () => {
        setLoading(true);
        globalForm
          .validateFields()
          .then(values => {
            handleFinish(values);
          })
          .catch(info => {
            console.log('Validate Failed:', info);
            setLoading(false);
        });
    }

    const handleFinish = (values) => {
      const data = {
	      form: {
	        global_form: values,
	        type: 'system_forms'
	      }
	    }

	    formService.storeOrUpdate(data, form.id).then(res => {
	      if(res.code === '0000'){
	        message.success('Submit success')
	        setShowFinalScreen(true)
	      }else{
	        message.error(res.message)
	      }
	    });
    }
 
  return (
    <LayoutWrapper>
      <FormPage>
        {errorMsg && errorMsg !== '' && !showFinalScreen &&
            <Alert message={errorMsg} type="error" />
        }
        {
        	form && form.type == 'self_create' && !showFinalScreen && <Survey.Survey json={surveyContent} onComplete={submitSurveyData} />
        }
        { showFinalScreen && <FinalScreen /> }
        {
        	form && form.type == 'pdf_upload' && !showFinalScreen &&
        	<Row>
        		<Col xs={{span: 24}} md={{span: 12}}>
        			<iframe src={(form.files && form.files.length) ? process.env.REACT_APP_API_BASE_URL + form.files[form.files.length-1].path : ''} />
        		</Col>
        		<Col xs={{span: 24}} md={{span: 11, offset: 1}}>
        			<Form
				        layout="vertical"
				        scrollToFirstError
				        className="form-body"
				        form={pdfForm}
				    >
				    	<Form.Item
			                name={['form', 'is_agree']}
			                className="isoInputWrapper"
			                valuePropName="checked"
			            >
			                <Checkbox>{<IntlMessages id="formPage.modal.agreeConfirm" />}</Checkbox>
			                
			            </Form.Item>
			            <h3>{<IntlMessages id="formPage.modal.signature" />}</h3>
			        	<div className="signature-area">
			                <SignatureCanvas ref={padRef} />
			                <button onClick={handleClear} className="signature-clear-button">Clear</button>
			            </div>
			            <div className="isoInputWrapper">
				            <Button key="submit" className="btn-success" type="primary" loading={loading} onClick={handleSubmit}>
					          {<IntlMessages id="propertyPage.modal.save" />}
					        </Button>
					    </div>
		           	</Form>
        		</Col>
        	</Row>
        }
        {
        	form && form.type == 'system_forms' && !showFinalScreen &&
        	<GlobalForm formData={form} setShowFinalScreen={setShowFinalScreen}/>
        }
		{
        	form && form.type == 'bio_form' && !showFinalScreen &&
        	<BioForm formData={form} setShowFinalScreen={setShowFinalScreen} isAbleToSubmit={true}/>
        }
		{
        	form && form.type == 'checkin_form' && !showFinalScreen &&
        	<CheckinForm formData={form} setShowFinalScreen={setShowFinalScreen} isAbleToSubmit={true}/>
        }
      </FormPage>
    </LayoutWrapper>
  );
}

export default FormSubmission;