import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import AreaSummaryModalWrapper from './AreaSummaryModal.styles';
import { Form, Button, Input, ButtonRow, Col, Row, Statistic, Card } from 'antd';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@iso/components/utility/box';
import Table from '@iso/components/collapsing-table/table';
import { PlusOutlined } from '@ant-design/icons';
import Mobs from './Mobs';
import AssetModal from './AssetModal';
import TaskModal from './TaskModal';
import modalActions from '@iso/redux/modal/actions';
import AddAreaHistoryModal from './AddAreaHistoryModal';
import { activityService } from '@iso/services';
import moment from 'moment';
import LayoutWrapper from '@iso/components/utility/layoutWrapper.js';
import PrimaryObjectModal from '@iso/containers/Pages/PropertyMap/components/PrimaryObject/PrimaryObjectModal';

const { openModal } = modalActions;

const AreaSummaryModal = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const activePermission = useSelector((state) => state.permission.activePermission);
  const [livestockTotal, setLivestockTotal] = useState(props.dataObject.total_assets);
  const [assetTotal, setAssetTotal] = useState(props.dataObject.total_facilities);
  const [taskTotal, setTaskTotal] = useState(props.dataObject.total_tasks);
  const [visibleHistory, setVisibleHistory] = useState(false);
  const [areaHistoryData, setAreaHistoryData] = useState([]);
  const [visibleTaskModal, setVisibleTaskModal] = useState(false);
  const [visibleAssetModal, setVisibleAssetModal] = useState(false);

  const updateAreaHistoryData = () => {
    if(props.dataObject.id){
       activityService.getList(props.dataObject.id, 'area').then(res => {
        if(res.code === '0000'){
          setAreaHistoryData(res.activities.reverse());
        }
      });
    }
  }
  const showEditAreaModal = () => {
    const modalData = {
          object: props.dataObject,
          type: 'area',
          hideEditArea: true
    }
    dispatch(openModal(modalData));
  } 

  useEffect(() => {
    updateAreaHistoryData();
  }, [props.dataObject, props.visibleSummary])

  useEffect(() => {
    setLivestockTotal(props.dataObject.total_assets);
    setAssetTotal(props.dataObject.total_facilities);
    setTaskTotal(props.dataObject.total_tasks);
  }, [props.dataObject.total_assets, props.dataObject.total_facilities, props.dataObject.total_tasks, props.visibleSummary]);

  const cancelModal = () => {
    props.cancelSummaryModal();
  }

  const updateLivestockTotal = (livestocksDeleted) => {
    var new_total = livestockTotal - livestocksDeleted;
    setLivestockTotal(new_total);
  }

  const updatePropertyDetail = (propertyId) => {
    props.updatePropertyDetail(propertyId);
  }

  const onSaved = (savedAsset) => {
    var new_asset_total = parseInt(assetTotal) + 1;
    setAssetTotal(new_asset_total);
  }

  const showAssetModal = () => {
    // const modalData = {
    //   type: 'asset',
    //   canCreate: true
    // }
    // dispatch(openModal(modalData));
    setVisibleAssetModal(true);
  }

  const cancelAssetModal = () => {
    setVisibleAssetModal(false);
  }

  const showTaskModal = () => {
    // const modalData = {
    //   type: 'task',
    //   canCreate: true,
    //   object: {}
    // }
    // dispatch(openModal(modalData));
    setVisibleTaskModal(true);
  }

  const cancelTaskModal = () => {
    setVisibleTaskModal(false);
  }

  const onSavedTask = (savedTask) => {
    var new_task_total = parseInt(taskTotal) + 1;
    setTaskTotal(new_task_total);
  }

  const showHistoryModal = () => {
    setVisibleHistory(true);
  }

  const cancelModalHistory = () => {
    setVisibleHistory(false);
  }

   const columns = [
    {
      label: <IntlMessages id="areaSummary.modal.activity.createdAt" />,
      accessor: 'created_at',
      minWidth: 300,
      sortable: true,
      position: 1,
      priorityLevel: 1,
      CustomComponent: cell => {
        let activity = cell.row
        return (activity.created_at) ? moment(activity.created_at).format('L') : '';
      }
    },
    {
      label: <IntlMessages id="areaSummary.modal.activity.category" />,
      accessor: 'metadata',
      minWidth: 300,
      sortable: true,
      position: 2,
      priorityLevel: 2,
      CustomComponent: cell => {
        let activity = cell.row
        return activity.metadata.activity_category ? (activity.metadata.activity_category).replace('_', ' ') : 'N/A';
      }
    },
    {
      label: <IntlMessages id="areaSummary.modal.activity.name" />,
      accessor: 'metadata',
      minWidth: 300,
      sortable: true,
      position: 3,
      priorityLevel: 3,
      CustomComponent: cell => {
        let activity = cell.row
        return activity.metadata.name ? activity.metadata.name : 'N/A'; 
      }
    },
    {
      label: <IntlMessages id="areaSummary.modal.activity.quantity" />,
      accessor: 'metadata',
      minWidth: 300,
      sortable: true,
      position: 4,
      priorityLevel: 4,
      CustomComponent: cell => {
        let activity = cell.row
        return (activity.metadata.quantity) ? (activity.metadata.quantity) : 'N/A';
      }
    },
    {
      label: <IntlMessages id="areaSummary.modal.activity.application" />,
      accessor: 'metadata',
      minWidth: 300,
      sortable: true,
      position: 5,
      priorityLevel: 5,
      CustomComponent: cell => {
        let activity = cell.row
        return (activity.metadata.application) ? (activity.metadata.application) : 'N/A'
      }
    },
    {
      label: <IntlMessages id="areaSummary.modal.activity.seed" />,
      accessor: 'metadata',
      minWidth: 300,
      sortable: true,
      position: 6,
      priorityLevel: 6,
      CustomComponent: cell => {
        let activity = cell.row
        return (activity.metadata.seed) ? (activity.metadata.seed) : 'N/A'
      }
    },
    {
      label: <IntlMessages id="areaSummary.modal.activity.volume" />,
      accessor: 'metadata',
      minWidth: 300,
      sortable: true,
      position: 7,
      priorityLevel: 7,
      CustomComponent: cell => {
        let activity = cell.row
        return  (activity.metadata.volume) ? (activity.metadata.volume) : 'N/A';
      }
    },
    {
      label: <IntlMessages id="areaSummary.modal.activity.description" />,
      accessor: 'metadata',
      minWidth: 300,
      sortable: true,
      position: 8,
      priorityLevel: 8,
      CustomComponent: cell => {
        let activity = cell.row
        return (activity.metadata.description) ? (activity.metadata.description) : 'N/A'
      }
    },
  ];

  return (
    <>
    <AreaSummaryModalWrapper
      className="user-access-modal"
      forceRender={true}
      visible={props.visibleSummary}
      title={props.dataObject.name}
      onCancel={cancelModal}
      maskClosable={true}
      footer={[
        <Button key="edit" onClick={showEditAreaModal} type="primary" className="btn-success" >
          <IntlMessages id="propertyPage.managePage.editDetails" />
        </Button>,
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.close" />}
        </Button>,

      ]}
    >
    <div className="form-body">
      <Row >
        <Col xs={{span: 12}} md={{span: 6}}>
          <Card>
            <Statistic
              title={props.dataObject.acreage_unit ? props.dataObject.acreage_unit : 'Acreage'}
              value={props.dataObject.acreage}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col xs={{span: 12}} md={{span: 6}}>
          <Card>
            <Statistic
              title={props.dataObject.acreage_unit === 'Hectare' ? 'Acreage' : 'Hectares'}
              value={props.dataObject.acreage_unit === 'Hectare' ? props.dataObject.acreage_by_acres : props.dataObject.acreage_by_hectare }
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col xs={{span: 12}} md={{span: 6}}>
          <Card>
            <Statistic
              title={props.dataObject.total_mobs > 0 ? <IntlMessages id="areaSummary.modal.inUse" /> : <IntlMessages id="areaSummary.modal.restDays" />}
              value={props.dataObject.total_mobs > 0 ? props.dataObject.in_use_days : props.dataObject.rest_days}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col xs={{span: 12}} md={{span: 6}}>
          <Card>
            <Statistic
              title={<IntlMessages id="areaSummary.modal.livestockCard" />}
              value={livestockTotal}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
      </Row>
      <br/>
      <Row>
        <Col xs={{span: 24}} md={{span: 8}} className="btn-summary-action">
          <Button icon={<PlusOutlined />} type="primary" className="btn-success" onClick={showHistoryModal}>
            <IntlMessages id="areaSummary.modal.newActivity" />
          </Button>
        </Col>
        <Col xs={{span: 24}} md={{span: 7, offset: 1}} className="btn-summary-action">
          <Button icon={<PlusOutlined />} type="primary" className="btn-success" onClick={showTaskModal}>
            <IntlMessages id="areaSummary.modal.newTask" />
          </Button>
        </Col>
        <Col xs={{span: 24}} md={{span: 7, offset: 1}} className="btn-summary-action">
          <Button icon={<PlusOutlined />} type="primary" className="btn-success" onClick={showAssetModal}>
            <IntlMessages id="areaSummary.modal.newAsset" />
          </Button>
        </Col>
      </Row>
      <br/>
      <h3><IntlMessages id="areaSummary.modal.block.activity" /></h3>
      <Box id="historyContainerBox">
      {
        areaHistoryData.length > 0 &&
          <Table 
            columns={columns} 
            rows={areaHistoryData}
            containerId="historyContainerBox"
            showPagination={false}
            rowSize={3}
          />        
        }
      </Box>
      <br/>
      <h3><IntlMessages id="areaSummary.modal.block.mobs" /></h3>
      <Mobs objectId={props.dataObject.id} updateLivestockTotal={updateLivestockTotal} visibleSummary={props.visibleSummary} updatePropertyDetail={updatePropertyDetail}/>
    </div>
    <AssetModal
        visibleAssetModal={visibleAssetModal}
        cancelAssetModal={cancelAssetModal}        
        propertyId={props.propertyId}
        primaryObjects={props.primaryObjects}
        onSaved={onSaved}
        dataObject={props.dataObject}
        currentPos={props.currentPos}
        updatePropertyDetail={updatePropertyDetail}
      />

   
      <TaskModal visibleTaskModal={visibleTaskModal} cancelTaskModal={cancelTaskModal} propertyId={props.propertyId} dataObject={props.dataObject} 
      primaryObjects={props.primaryObjects} onSaved={onSavedTask} updatePropertyDetail={updatePropertyDetail}/>
    
    <PrimaryObjectModal 
      propertyId={props.propertyId}
      primaryObjects={props.primaryObjects}
      updatePropertyDetail={updatePropertyDetail}
      edit={true}
      onEdited={props.onEdited}
      propertyAcreageUnit={props.propertyAcreageUnit}
    />

    <AddAreaHistoryModal        
        visibleHistory={visibleHistory} 
        model="areas" type="area"
        primaryObjects={props.primaryObjects} 
        cancelModal={cancelModalHistory} 
        propertyId={props.propertyId}
        dataId={props.dataObject.id}
        updateAreaHistoryData={updateAreaHistoryData} 
    />
    </AreaSummaryModalWrapper>
    </>
  );
}

export default AreaSummaryModal;