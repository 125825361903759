import styled from "styled-components";
import Modal from "@iso/components/Feedback/Modal";
import WithDirection from "@iso/lib/helpers/rtl";

const PropertyAreaPage=styled.div`
  width: 100%;
  height: 100%;
  .property-area-dot-btn span {
    rotate: 90deg;
  }
  svg.brand-primary-light {
    color: red !important;
}

.search-bar {
  background: rgba(143, 146, 161, 0.05);
  border-radius: 12px;
  border: none;
  margin-left: 2rem;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  flex-direction: row-reverse;
  max-width: 228px;
}
.search-bar .ant-input {
  background: none!important;
  border: none!important;
  color: #8F92A1;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
  .property-area-add-btn {
    background-color:rgb(248, 14, 70) !important;
    height: 40px;
    border-radius: 8px !important
  }
  .propertyarea-export-btn {
    background-color: #3d4337 !important;
    border-color: #3d4337 !important;
    height: 40px;
    border-radius: 8px !important
  }
  .propertyarea-import-btn {
    background-color: #8d918c  !important;
    border-color: #8d918c !important;
    height: 40px;
    border-radius: 8px !important;
  }
  .area_top {
    padding: 20px 24px;
    display: flex;
    align-items: center;
    margin: auto;
    > .mp-title {
      display : flex;
      width: 100%;
      .area-property-title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: #101828;
      }
    }
    .button-group {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      .btn-success {
        border-radius: 8px !important;
        height: 40px;
        span {
          margin-right: 4px;
          margin-left: 4px !important;
        }
      }
    }
  }
 
  .btn-success {
    &:hover {
      opacity: 0.8;
    }
  }
  .ant-table-wrapper {
    min-width: 100%;
  }
  .fAgWDa .ant-table-content {
    overflow-x: unset;
  }
  .fAgWDa {
    overflow-x: unset;
  }
  .dtr-data {
    white-space: break-spaces;
  }
  .delete-button {
    margin-left: 8px;
  }
  .dataTables_filter {
    margin-bottom: 10px;
  }
  .dataTables_filter .input-sm {
    margin-left: 10px;
  }
  .dataTables_filter .input-sm {
    margin-left: 10px;
    text-align: center;
    outline-offset: unset;
    border-radius: 8px;
  }
  .ant-pagination-item-active {
    border-color: #f80e46;
    a {
      color: white !important;
    }
  }
  .ant-pagination-item:focus,
  .ant-pagination-item:hover {
    border-color: #f80e46;
    a {
      color: #f80e46;
    }
  }
  .ant-spin-dot-item {
    background-color: #f80e46;
  }
  .ant-pagination-item-link:hover {
    border-color: #f80e46;
    color: #f80e46;
  }
  .react-collapsible-theme .react-collapsible-search input {
    text-align: center;
    border: 0;
    border: 5px #c9c9ce solid;
    border-radius: 6px;
    width: 100%;
    height: 2rem;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
  }
  .search-icon {
    top: 5px !important;
    left: 15px !important;
    width: 20px;
  }

  .clear-icon {
    position: relative;
    top: 5px !important;
    right: 2px !important;
  }

  .search-icon svg {
    color: rgba(0, 0, 0, 0.65);
  }

  .action-button.view-history-button,
  .action-button {
    margin-bottom: 5px;
    margin-top: 5px;
  }

  @media (max-width: 768px) {
    .react-collapsible-theme .react-collapsible-search .search-input {
      width: 100%;
    }
  }
  .area-manage-table {
    table {
      thead {
        tr {
          .column-name {
            span {
              display: none;
              color: #000000;
            }
          }
        }
      }
      tbody {
        .name {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #101828;
        }
        td {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #475467;
        }
        .view_history {
          button {
            background: #ecdd5d !important;
            border-color: #ecdd5d !important;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 24px;
            color: #1e1e1e;
            border-radius: 10px !important;
            box-shadow: none;
            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
    
  }
  @media only screen and (width: 480px) {
    .area_top {
      
      flex-direction: column !important;
      padding: 20px 15px !important;
    }
    .area_top .button-group{
      display: block !important;
      justify-content:flex-start !important;
      margin: 10px 0px 10px 0 !important;
    }
    .propertyarea-import-btn{
      padding  : 10px !important;
      font-size : 12px !important;
      width: 175px !important ;
    }
    .propertyarea-export-btn{
      padding  : 10px !important;
      font-size : 12px !important;
      width: 175px !important ;
    }
    .property-area-add-btn{
      padding  : 10px !important;
      font-size : 12px !important;
      width: 175px !important ;
    }
  }
  @media only screen and (width: 768px) {
    .area_top {
      
      flex-direction: column !important;
      padding: 20px 15px !important;
    }
    .area_top .button-group{
     
      justify-content:flex-start !important;
      margin: 10px 0 0 0 !important;
    }
    .propertyarea-import-btn{
      padding  : 10px !important;
      font-size : 12px !important;
      min-width: 125px !important ;
    }
    .propertyarea-export-btn{
      padding  : 10px !important;
      font-size : 12px !important;
      min-width: 125px !important ;
    }
    .property-area-add-btn{
      padding  : 10px !important;
      font-size : 12px !important;
      min-width: 125px !important ;
    }
  }
  @media only screen and (width: 425px) {
    .area_top {
      
      flex-direction: column !important;
      padding: 20px 15px !important;
    }
    .area_top .button-group{
      display: block !important;
      justify-content:flex-start !important;
      margin: 10px 0px 10px 0 !important;
    }
    .propertyarea-import-btn{
      padding  : 10px !important;
      font-size : 12px !important;
      width: 145px !important ;
    }
    .propertyarea-export-btn{
      padding  : 10px !important;
      font-size : 12px !important;
      width: 145px !important ;
    }
    .property-area-add-btn{
      padding  : 10px !important;
      font-size : 12px !important;
      width: 145px !important ;
    }
  }
  @media only screen and (width: 375px) {
    .area_top {
      
      flex-direction: column !important;
      padding: 10px 15px !important;
    }
    .area_top .button-group{
      display: block !important;
      justify-content:flex-start !important;
      margin: 10px 0px 10px 0px !important;
    }
    .propertyarea-import-btn{
      padding  : 10px !important;
      font-size : 12px !important;
      width: 100% !important ;
    }
    .propertyarea-export-btn{
      padding  : 10px !important;
      font-size : 12px !important;
      width: 100% !important ;
    }
    .property-area-add-btn{
      padding  : 10px !important;
      font-size : 12px !important;
      width: 100% !important ;
    }
  }
  @media only screen and (width: 320px) {
    .area_top {
      
      flex-direction: column !important;
      padding: 10px 15px !important;
    }
    h2.area-property-title{
      text-align:center !important;
    }
    .area_top .button-group{
      display: block !important;
      justify-content:flex-start !important;
      margin: 10px 0px 0px 0px !important;
    }
    .propertyarea-import-btn{
      padding  : 10px !important;
      font-size : 12px !important;
      width: 100% !important ;
    }
    .propertyarea-export-btn{
      padding  : 10px !important;
      font-size : 12px !important;
      width: 100% !important ;
    }
    .property-area-add-btn{
      padding  : 10px !important;
      font-size : 12px !important;
      width: 100% !important ;
    }
  }
  
`;

export default WithDirection( PropertyAreaPage );
