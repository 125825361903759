import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const VisitorModaWlrapper = styled(Modal)`

  &.visitor-modal{
    width: 1200px !important;
    padding-buttom: 10px !important; 
    transform-origin: 1423px -64px !important;
  }
  .ant-btn-primary{
    background-color: #729D68!important;
  }
  .ant-modal-body{
    padding: 1rem;
  }
  .btn-success{
    background-color: #f80e46 !important;
    border-color: #f80e46;
  }
  .form-title{
    padding: 16px 24px;
    border-bottom: 1px solid #eaeef1;
    h3{
      font-size: 1rem;
      color: #729D68;
    }
  }
  .form-body{
    padding: 12px 24px 12px 24px;
    .checkbox-contact{
      margin-bottom: 10px;
    }
  }
`;

export default WithDirection(VisitorModaWlrapper);