import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Row, Col, Radio, Checkbox, DatePicker, Alert, Upload, TimePicker, Select, InputNumber, message } from 'antd';
import { weatherDataService } from '@iso/services';
import userActions from '@iso/redux/user/actions';
import IntlMessages from '@iso/components/utility/intlMessages';
import WeatherFormWrapper from './WeatherForm.styles';
import moment from 'moment';
import { dateHelper } from '@iso/lib/helpers/dateHelper';
import { UploadOutlined } from '@ant-design/icons';
import { weatherTabTypes } from '@iso/constants/weatherTabTypes';
import { rainfallUnits } from '@iso/constants/rainfallUnits';
import { weatherDuration } from '@iso/constants/weatherDuration';
import FormModalWrapper from '../Form/FormModal.styles';
import styled from "styled-components";
import _ from 'lodash';

const { store }=userActions;
const { Option }=Select;

const WeatherForm=( props ) =>
{
  const dispatch=useDispatch();
  const [ form ]=Form.useForm();
  const [ sharedForm ]=Form.useForm();
  const [ loading, setLoading ]=useState( false );
  const [ alertSharing, setAlertSharing ]=useState( false );
  const [ fields, setFields ]=useState( [] );
  const currentUser=useSelector( ( state ) => state.user.user );
  const [ chartConfig, setChartConfig ]=useState( {} );

  const handleSubmit=() =>
  {
    setLoading( true );
    form
      .validateFields()
      .then( values =>
      {
        onStore( values );
      } )
      .catch( info =>
      {
        console.log( 'Validate Failed:', info );
        setLoading( false );
      } );

  }

  useEffect( () =>
  {
    setFields( [
      {
        name: [ 'weather_data', 'weather_date' ],
        value: moment()
      },
      {
        name: [ 'weather_data', 'weather_time' ],
        value: moment()
      },
      {
        name: [ 'weather_data', 'rain_unit' ],
        value: 'millimeter'
      },
      {
        name: [ 'weather_data', 'duration' ],
        value: 24
      }
    ] );
  }, [] );

  const onStore=async ( values ) =>
  {
    const data={
      weather_data: {
        ...values.weather_data,
        weather_date: values.weather_data.weather_date? dateHelper.dateForAPI( values.weather_data.weather_date ):'',
        weather_time: values.weather_data.weather_time? dateHelper.getTime( values.weather_data.weather_time ):'',
        rain: values.weather_data.rain_unit==='inches'? ( 25.4*( values.weather_data.rain ) ):values.weather_data.rain,
        property_id: props.propertyId
      }
    }

    weatherDataService.storeOrUpdate( data ).then( res =>
    {
      if ( res.code==='0000' )
      {
        //props.onSaved(res.weather_data);

        form.resetFields();
        if (props.visibleWeatherObswrvation) {
          props.setVisibleWeatherObservation(false);
        }
        setFields( [
          {
            name: [ 'weather_data', 'weather_date' ],
            value: moment()
          },
          {
            name: [ 'weather_data', 'weather_time' ],
            value: moment()
          },
          {
            name: [ 'weather_data', 'rain_unit' ],
            value: 'millimeter'
          },
          {
            name: [ 'weather_data', 'duration' ],
            value: 24
          }
        ] );
        if (props.updateWeatherChart && props.updateWeatherData) {
          
        
        props.updateWeatherChart( res.weather_data.primary_object_id );
        props.updateWeatherData();
        }
       
        setTimeout( () =>
        {
          setLoading( false );
          message.success( 'Save Successfully!' );
        }, 1000 );
      } else
      {
        setTimeout( () =>
        {
          setLoading( false );
          message.error( 'Save Fail!' );
        }, 1000 );
      }
    } );
  }

  const renderOptionsWeatherTabTypes=() =>
  {
    let options=[];
    _.forEach( weatherTabTypes, ( type, index ) =>
    {
      options.push(
        <Option key={index} value={type.value}>
          <div>{type.label}</div>
        </Option>
      );
    } )
    return (
      <Select
        placeholder="Select a weather type"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const renderOptionsRainfallUnit=() =>
  {
    let options=[];
    _.forEach( rainfallUnits, ( unit, index ) =>
    {
      options.push(
        <Option key={index} value={unit.value}>
          <div>{unit.label}</div>
        </Option>
      );
    } )
    return (
      <Select
        placeholder="Select rainfall unit"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const renderOptionsWeatherDuration=() =>
  {
    let options=[];
    _.forEach( weatherDuration, ( duration, index ) =>
    {
      options.push(
        <Option key={index} value={duration.value}>
          <div>{duration.label}</div>
        </Option>
      );
    } )
    return (
      <Select
        placeholder="Select weather duration"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const renderOptionObject=( objects ) =>
  {
    let options=[];
    _.forEach( objects, ( object, index ) =>
    {
      options.push(
        <Option key={object.id} value={object.id}>
          {object.name}
        </Option>
      );
    } )
    return (
      <Select
        placeholder="Select an object"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

const cancelModal = () =>{
 props.setVisibleWeatherObservation(false); 
}
  return (
    <>
{props.visibleWeatherObswrvation ?
<FormModalWrapper
    className="modal-title-style"
        forceRender={true}
      maskClosable={true}
      open={props.visibleWeatherObswrvation}
      title= "Add Weather Observation"
      onCancel={cancelModal}

      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button
          key="submit"
          className="btn-success"
          type="primary"
          loading={loading}
          onClick={handleSubmit}
        >
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
      {/* <WeatherFormWrapper
        className="weather-form-area"
      > */}
        <Form
          form={form}
          layout="vertical"
          scrollToFirstError
          fields={fields}
        >
          <div className="form-body">
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 11 }}>
                <Form.Item
                  name={[ 'weather_data', 'primary_object_id' ]}
                  className="isoInputWrapper custom-weather-form"
                  label={<IntlMessages id="weatherPage.weatherForm.location" />}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  {renderOptionObject( props.primaryObjects )}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
                <TemperatureInput>
                  <Form.Item
                    name={[ 'weather_data', 'duration' ]}
                    className="isoInputWrapper custom-weather-form"
                    label={<IntlMessages id="weatherPage.weatherForm.duration" />}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                  >
                    <InputNumber style={{ width: '100%' }} size="large" min={0} max={1000} step={1} addonAfter="h" suffix="h" />
                  </Form.Item>
                  {/* <SuffixAddon>h</SuffixAddon> */}
                </TemperatureInput>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 11 }}>
                <Form.Item
                  name={[ 'weather_data', 'weather_date' ]}
                  className="isoInputWrapper"
                  label={<IntlMessages id="propertyPage.modal.asset.date" />}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <DatePicker size="large" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
                <Form.Item
                  name={[ 'weather_data', 'weather_time' ]}
                  className="isoInputWrapper"
                  label={<IntlMessages id="propertyPage.modal.asset.time" />}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <TimePicker size="large" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 11 }}>
                <TemperatureInput>
                  <Form.Item
                    name={[ 'weather_data', 'temperature' ]}
                    className="isoInputWrapper"
                    label={<IntlMessages id="weatherPage.weatherForm.weatherData.temperature" />}
                    style={{ width: "100%" }}
                  >
                    <InputNumber style={{ width: '100%' }} size="large" min={0} max={1000} addonAfter="&#8451;" />
                  </Form.Item>
                  {/* <SuffixAddon>&#8451;</SuffixAddon> */}
                </TemperatureInput>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
                <Row>
                  <Col xs={{ span: 11 }} md={{ span: 13 }}>
                    <Form.Item
                      name={[ 'weather_data', 'rain' ]}
                      className="isoInputWrapper"
                      label={<IntlMessages id="weatherPage.weatherForm.weatherData.rain" />}
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                        },
                      ]}
                    >
                      <InputNumber style={{ width: '100%' }} size="large" min={0} max={1000} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 11, offset: 2 }} md={{ span: 9, offset: 2 }}>
                    <Form.Item
                      name={[ 'weather_data', 'rain_unit' ]}
                      className="isoInputWrapper"
                      label={<IntlMessages id="weatherPage.weatherForm.weatherData.rainUnit" />}
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                        },
                      ]}
                    >
                      {renderOptionsRainfallUnit()}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Form.Item
              name={[ 'weather_data', 'comment' ]}
              className="isoInputWrapper"
              label={<IntlMessages id="propertyPage.modal.asset.weatherComment" />}
            >
              <Input.TextArea />
            </Form.Item>
            {/* <Row style={{ marginTop: '15px' }}>
              <Col xs={{ span: 11 }} md={{ span: 11 }}>
              </Col>
              <Col xs={{ span: 11, offset: 2 }} md={{ span: 11, offset: 2 }}>
                <Button key="submit" className="btn-success" type="primary" style={{ float: 'right' }} onClick={handleSubmit} loading={loading}>
                  {<IntlMessages id="propertyPage.modal.save" />}
                </Button>
              </Col>
            </Row> */}
          </div>
        </Form>
      {/* </WeatherFormWrapper> */}
      </FormModalWrapper>
      :

 
      <WeatherFormWrapper
        className="weather-form-area"
      >
        <Form
          form={form}
          layout="vertical"
          scrollToFirstError
          fields={fields}
        >
          <div className="form-title">
            <h1 className='h3title'>{<IntlMessages id="weatherPage.weatherForm.title" />}</h1>
          </div>
          <div className="form-body">
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 11 }}>
                <Form.Item
                  name={[ 'weather_data', 'primary_object_id' ]}
                  className="isoInputWrapper custom-weather-form"
                  label={<IntlMessages id="weatherPage.weatherForm.location" />}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  {renderOptionObject( props.primaryObjects )}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
                <TemperatureInput>
                  <Form.Item
                    name={[ 'weather_data', 'duration' ]}
                    className="isoInputWrapper"
                    label={<IntlMessages id="weatherPage.weatherForm.duration" />}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                  >
                    <InputNumber style={{ width: '100%' }} size="large" min={0} max={1000} step={1} addonAfter="h" suffix="h" />
                  </Form.Item>
                  {/* <SuffixAddon>h</SuffixAddon> */}
                </TemperatureInput>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 11 }}>
                <Form.Item
                  name={[ 'weather_data', 'weather_date' ]}
                  className="isoInputWrapper"
                  label={<IntlMessages id="propertyPage.modal.asset.date" />}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <DatePicker size="large" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
                <Form.Item
                  name={[ 'weather_data', 'weather_time' ]}
                  className="isoInputWrapper"
                  label={<IntlMessages id="propertyPage.modal.asset.time" />}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <TimePicker size="large" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 11 }}>
                <TemperatureInput>
                  <Form.Item
                    name={[ 'weather_data', 'temperature' ]}
                    className="isoInputWrapper"
                    label={<IntlMessages id="weatherPage.weatherForm.weatherData.temperature" />}
                    style={{ width: "100%" }}
                  >
                    <InputNumber style={{ width: '100%' }} size="large" min={0} max={1000} addonAfter="&#8451;" />
                  </Form.Item>
                  {/* <SuffixAddon>&#8451;</SuffixAddon> */}
                </TemperatureInput>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
                <Row>
                  <Col xs={{ span: 11 }} md={{ span: 13 }}>
                    <Form.Item
                      name={[ 'weather_data', 'rain' ]}
                      className="isoInputWrapper"
                      label={<IntlMessages id="weatherPage.weatherForm.weatherData.rain" />}
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                        },
                      ]}
                    >
                      <InputNumber style={{ width: '100%' }} size="large" min={0} max={1000} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 11, offset: 2 }} md={{ span: 9, offset: 2 }}>
                    <Form.Item
                      name={[ 'weather_data', 'rain_unit' ]}
                      className="isoInputWrapper"
                      label={<IntlMessages id="weatherPage.weatherForm.weatherData.rainUnit" />}
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                        },
                      ]}
                    >
                      {renderOptionsRainfallUnit()}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Form.Item
              name={[ 'weather_data', 'comment' ]}
              className="isoInputWrapper"
              label={<IntlMessages id="propertyPage.modal.asset.weatherComment" />}
            >
              <Input.TextArea />
            </Form.Item>
            <Row style={{ marginTop: '15px' }}>
              <Col xs={{ span: 11 }} md={{ span: 11 }}>
              </Col>
              <Col xs={{ span: 11, offset: 2 }} md={{ span: 11, offset: 2 }}>
                <Button key="submit" className="btn-success" type="primary" style={{ float: 'right' }} onClick={handleSubmit} loading={loading}>
                  {<IntlMessages id="propertyPage.modal.save" />}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </WeatherFormWrapper> 
}
    </>
  );
}

export default WeatherForm;

const SuffixAddon=styled.span`
  margin-top: 22px;
  margin-bottom: 5px;
  line-height: 38px;
  color: #747784!important;
  flex-shrink: 0;
  border-top-right-radius: 4px!important;
  border-bottom-right-radius: 4px!important;
  border-right-width: 1px!important;
  min-width: 36px;
  border: solid #d9d9d9;
  border-width: 1px 0;
  white-space: nowrap;
  text-align: center;
`;

const TemperatureInput=styled.div`
  display: flex;
  align-items: flex-start;
`;