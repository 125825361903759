import React, { useState, useEffect } from 'react';
import Box from '@iso/components/utility/box';
import { useHistory } from 'react-router-dom';
import PageHeader from '@iso/components/utility/pageHeader';
import IntlMessages from '@iso/components/utility/intlMessages';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Button, Row, Col, message, Card } from 'antd';
import _ from 'lodash';
import HealthSafetyWrapper from './Dashboard.styles';
import ModalHealthSafety from './ModalHealthSafety';
import MapHealthSafety from './MapHealthSafety';
import DashboardSafeFarm from '@iso/containers/Pages/SafeFarm/Dashboard';
import AssetChart from './AssetChart';
import Activity from './Activity';
import TrafficLightGroup from './TrafficLightGroup';
import ButtonGroup from './ButtonGroup';
import CollapseHealthSafety from './CollapseHealthSafety';
import { propertyService } from '@iso/services';
import { healthsafety, assetService } from '@iso/services';
import moment from 'moment'
import WeatherMap from '../WeatherMap/WeatherMap';

const { Search } = Input;


const Dashboard = () => {
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const [primaryObjects, setPrimaryObjects] = useState([]);
  const [position, setPosition] = useState([null, null]);
  const [propertyId, setPropertyId] = useState(null);
  const [healthAndSafetyPolicies, setHealthAndSafetyPolicies] = useState([]);
  // const [historyData, setHistoryData] = useState([]);
  const [assets, setAssets] = useState([]);

  const [activityActive, setActivityActive] = useState();
  const [healthAndSafetyAssets, setHealthAndSafetyAssets] = useState([]);
  const [visibleModalHealthSafety, setVisibleModalHealthSafety] = useState(false);

  const cancelModalHealthSafety = () => {
    setVisibleModalHealthSafety(false);

  }
  const showModalHealthSafety = () => {
    setVisibleModalHealthSafety(true);
  }

  const updatePropertyDetail = React.useCallback((id) => {
    propertyService.viewProperty(id).then(res => {
      if (res.code === '0000') {
        setPrimaryObjects(res.primary_objects);
        setPosition([res.property.lat, res.property.lng]);
      }
    })
  }, []);

const getDataPolicy = () => {
  if (activePropertyId) {
    healthsafety.getDataTypePolicy(activePropertyId).then(res => {
      if (res.code === '0000') {
       
      }
    });
  }
}
  const getDataAsset = () => {
    if (activePropertyId) {
      healthsafety.getHealthAndSafetyAssets(activePropertyId).then(res => {
        if (res.code === '0000') {
          setAssets(res.assets);
        }
      });
    }
  }

  const search = (propertyId, s) => {
    healthsafety.search(propertyId, s).then(res => {
      if (res.code === '0000') {
        
        setHealthAndSafetyAssets(res.health_safety_assets);
        setHealthAndSafetyPolicies(res.health_safety_policies);

      }
    })
  }


  useEffect(() => {
    if (activePropertyId) {
      setPropertyId(activePropertyId);
      updatePropertyDetail(activePropertyId);
      getDataAsset();
      getDataPolicy();
      search(activePropertyId, '');
    }
  }, [activePropertyId]);

  const onSaved = (savedAsset) => {
    updatePropertyDetail(propertyId);

  }

  const onSearch = (value) => {
    search(activePropertyId, value);
  }

  return (
    <HealthSafetyWrapper>
      <Row gutter={15}>
        <Col xl={12} md={24} sm={24} xs={24} className="trafficLight_custom">
          <ButtonGroup propertyId={propertyId}  showModalHealthSafety={showModalHealthSafety} cancelModalHealthSafety ={cancelModalHealthSafety} getDataPolicy ={getDataPolicy} search= {search}/>
          <TrafficLightGroup propertyId={propertyId} />
        </Col>
        <Col xl={12} md={24} sm={24} xs={24}>
          <Box className="statistic-card">
            <Search
              placeholder="input search text"
              allowClear
              onSearch={onSearch}
              size="large"
              className="health-safety-search"
            />

            <CollapseHealthSafety healthAndSafetyPolicies={healthAndSafetyPolicies} healthAndSafetyAssets={healthAndSafetyAssets} getDataPolicy ={getDataPolicy}  />
          </Box>
        </Col>
        </Row>
        <Row gutter={15} className='maps_rows'>
        <Col xl={12} md={24} sm={24} xs={24}>
          <Box id='health-safety-map' className="statistic-card">
            <MapHealthSafety
              onSaved={onSaved}
              propertyId={propertyId}
              position={position}
              updatePropertyDetail={updatePropertyDetail}
              primaryObjects={primaryObjects} />
          </Box>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          xl={12}
          className="weather-map-style ctm-health"
        >
          <Card bordered={false} style={{ width: "100%" }}>
            <WeatherMap />
          </Card>
        </Col>
      </Row>

      <ModalHealthSafety
        cancelModalHealthSafety={cancelModalHealthSafety}
        visibleModalHealthSafety={visibleModalHealthSafety}
        onSaved={onSaved}
        propertyId={propertyId}
        position={position}
        getDataAsset={getDataAsset}
        search ={search}
      />

    </HealthSafetyWrapper>

  )
}
export default Dashboard;
