import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Form, InputNumber, Button, Row, Col} from 'antd';
import { iotDevice } from '@iso/services';
import DeviceTimeModalWrapper from './DeviceTimeModal.styles';

const DeviceTimeModal = (props) => {
  const {propertyId, assignedIotDevice, visible, onSaved,cancelModal} = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    if(assignedIotDevice){
     setFields([
        {
          name: 'device_time',
          value: assignedIotDevice.device_time ? assignedIotDevice.device_time : ''
        }, 
      ]);
    }
    
  }, [assignedIotDevice]);


  const cancelDeviceTimeModal = () => {
    cancelModal();
    resetForm();
  }

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(async (values) => {
        iotDevice.setDeviceTime(assignedIotDevice.id, values.device_time).then(()=>{
          cancelDeviceTimeModal();
          setLoading(false);
          onSaved();
        })
      })
      .catch(info => {
        setLoading(false);
      });
  }

  const resetForm = () => {
    setTimeout(() => {
      form.resetFields();
      setLoading(false);
    }, 500);
  }

  return (
    <DeviceTimeModalWrapper
    open={visible}
      onCancel={cancelDeviceTimeModal}
      title="IOT Device"
      footer={[
        <Button key="back" onClick={cancelDeviceTimeModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit} loading={loading}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
      <Form
        form={form}
        scrollToFirstError
        layout="vertical"
        fields={fields}
        >
         <div className="form-body">
            <Form.Item
              name="device_time"
              className="isoInputWrapper"
              label="Times"
            >
              <InputNumber addonAfter="Minutes"  size="large"/>
            </Form.Item>
         </div>
      </Form>
    </DeviceTimeModalWrapper>
  )

};

export default DeviceTimeModal;