import React , { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, Select } from 'antd';
import IntlMessages from '@iso/components/utility/intlMessages';
import AuthenNlisModalWrapper from './AuthenNlisModal.styles';
import Alert from '@iso/components/Feedback/Alert';
import { propertyService } from '@iso/services';

const AuthenNlisModal = (props) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState([]);
    const [errMsg, setErrMsg] = useState("");

    const closeModalAuth = () => {
        props.closeModalAuth();
        resetForm();
    }

    const resetForm = () => {
        setTimeout(() => {
            form.resetFields();
            
            setLoading(false);
            setErrMsg("");
        }, 500);
    }

    const handleSubmit = (e) => {
        // props.closeModalAuth();
        setLoading(true);
        form
            .validateFields()
            .then(async (values) => {
                console.log("values", values);

                let data = Object.assign({}, values);
                if (data) {
                    data.pic = "QKZZ1111";
                    onStore(data);
                } else {
                    console.log(123);
                    setLoading(false);
                }
                
            })
            .catch(info => {
                console.log('Validate Failed:', info);
                setLoading(false);
            });
    }

    const onStore = (data) => {
        propertyService.storeOrUpdateNlisAccount(data)
        .then(res => {
            if(res.code === '0000'){
                props.closeModalAuth();
                resetForm();
            }
        })
        .catch(err => {
            console.log(123123);
        });
    }

    return (
        <AuthenNlisModalWrapper
            className="auth-nlis-modal"
            forceRender={true}
            open={props.showModalAuth}
            title={<IntlMessages id="propertyPage.modal.nlisAuth.title" />}
            onCancel={closeModalAuth}
            maskClosable={true}
            footer={[
                <Button key="submit" className="btn-success" type="primary" loading={loading} onClick={handleSubmit}>
                  {<IntlMessages id="propertyPage.modal.nlisAuth.submit" />}
                </Button>,
            ]}
        >
            <Form
                form={form}
                name="addProperty"
                layout="vertical"
                scrollToFirstError
                initialValues={{
                    use_my_account: false,
                }}
                fields={fields}
            >
                {
                    errMsg &&
                    <Alert
                        message={errMsg}
                        type="error"
                        style={{margin: '15px 15px 0 15px'}}
                    />
                }
                <div className="form-body">
                    <div className="nlis-auth-desc">
                        {<IntlMessages id="propertyPage.modal.nlisAuth.description" />}
                    </div>
                    <Form.Item
                        name="username"
                        className="isoInputWrapper"
                        label="Username"
                        rules={[
                            {
                                required: true,
                                message: 'Please input NLIS username',

                            },
                            {
                                max:255,
                                message: 'Username must be maximum 255 characters.'
                            },
                            {
                                whitespace: true,
                                message: 'Please input your username',
                            },
                        ]}
                    >
                        <Input size="large" placeholder="Input your username"/>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        className="isoInputWrapper"
                        label="Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input NLIS password',
                            },
                            {
                                min: 6,
                                message: 'Password must be minimum 6 characters.'
                            }
                        ]}
                    >
                        <Input type="password" className="pac-target-input" size="large" placeholder="Input your password" />
                    </Form.Item>
                </div>
            </Form>
        </AuthenNlisModalWrapper>
    );
}

export default AuthenNlisModal;