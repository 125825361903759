import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import MobModalWrapper from './MobModal.styles';
import BreedModalWrapper from './BreedModal.styles';
import { Form, Input, Button, Select, Row, Col, DatePicker, Tabs, Card, Timeline, Popconfirm, InputNumber, Upload, Typography, Divider, message } from 'antd';
import { CloseOutlined, PlusOutlined, UploadOutlined, EditOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import modalActions from '@iso/redux/modal/actions';
import Loader from '@iso/components/utility/loader';
import { mobService, breedService, activityService, livestockService,primaryObjectService } from '@iso/services';
import moment from 'moment';
import { objectColors } from '@iso/constants/objectColors';
import _ from 'lodash';
import styled from "styled-components";
import { dateHelper } from '@iso/lib/helpers/dateHelper';
import L from 'leaflet';
import LiveStock from './LiveStock';
import inside from 'point-in-polygon';
import AddHistoryModal from './AddHistoryModal';
import SplitModal from './SplitModal';
import { mobTypes } from '@iso/constants/mobTypes';
import $ from 'jquery';
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';
import EditHistoryModal from './EditHistoryModal';
import * as turf from '@turf/turf';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { closeModal } = modalActions;
const { TabPane } = Tabs;
const { Text } = Typography;

const MobModal = (props) => {
  const [form] = Form.useForm();
  const [breedForm] = Form.useForm();
  const [fields, setFields] = useState([]);
  const dispatch = useDispatch();
  const modalVisibility = useSelector((state) => state.modal.modalVisibility);
  const activePropertySubscription = useSelector((state) => state.subscription.activePropertySubscription);
  const modalData = useSelector((state) => state.modal.modalData);
  const [loading, setLoading] = useState(false);
  const [loadingBreed, setLoadingBreed] = useState(false);
  const [modalLoading, setModalLoading] = useState(true);
  const [dataMob, setDataMob] = useState({});
  const [visibleBreed, setVisibleBreed] = useState(false);
  const [breeds, setBreeds] = useState({});
  const [visibleHistory, setVisibleHistory] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [activityHistory, setActivityHistory] = useState([]);
  const activePermission = useSelector((state) => state.permission.activePermission);
  const [disableNumberAnimal, setDisableNumberAnimal] = useState(false);
  const [imageId, setImageId] = useState(null);
  const [visibleSplitModal, setVisibleSplitModal] = useState(false);
  const [visibleEditHistoryModal, setVisibleEditHistoryModal] = useState(false);
  const [historyData, setHistoryData] = useState({});
  const [valueCheckboxMob, setValueCheckboxMob] = useState([]);
  const [areaObject,setAreaObject] = useState([]);
  // const [arrayPoints, setArrPoints] = useState([]);

  const [messageApi, contextHolder] = message.useMessage();

  const updateDataMobHistory = React.useCallback((mob_id, modal_type) => {
      activityService.getList(mob_id, modal_type).then(res => {
        if(res.code === '0000'){
          setActivityHistory(res.activities.reverse());
          setLoading(false);
        }
      });
  }, [activityHistory]);

  const updateMobData = () => {
    if(modalData.object.id){
       livestockService.getByMob(modalData.object.id).then(res => {
        if(res.code === '0000'){
          setFields([
            {
              name: ['mob', 'tag_number_range'],
              value: res.assets.length,
            },
          ]);
          setDisableNumberAnimal(true);
          if(props.updateMobData){
            props.updateMobData();
          }
          if(props.updateDataMobs){
            props.updateDataMobs(props.propertyId);
          }
        }
      });
    }
  }

  const updateDataMobs = () => {
    props.updateDataMobs(props.propertyId);
  }

  const updateNumberAnimal = (original_number, death_number) => {
    setFields([
      {
        name: ['mob', 'tag_number_range'],
        value: original_number - death_number,
      },
    ]);
  }

  const getRandomLatLng = (coordinates) => {
    var polygon = L.polygon([
      coordinates
    ]);
    var bounds = polygon.getBounds();
    var x_max = bounds.getEast();
    var x_min = bounds.getWest();
    var y_max = bounds.getSouth();
    var y_min = bounds.getNorth();
    var lat=0,lng = 0;
    var count = 0;
    var area = polygon.getLatLngs()[0].map(function(point){
      return [point.lat, point.lng]            
    });

    while(!inside([lat, lng], area)){
      lat = y_min + (Math.random() * (y_max - y_min));
      lng = x_min + (Math.random() * (x_max - x_min));
      count+=1;
      if(count==100){
        break;
      }      
    }            

    return new L.LatLng(
          lat,
          lng
    );
  }
   

  useEffect(() => {
    if(props.propertyId){
       breedService.getList(props.propertyId).then(res => {
        if(res.code === '0000'){
          setBreeds(res.breeds);
          setModalLoading(false);
        }
      });
    }
  }, [props.propertyId]);
  
  const cancelModal = () => {
    dispatch(closeModal());
    setActiveTab("1");
    setValueCheckboxMob([]);
    if(modalData.layer){
      modalData.featureGroup.leafletElement.removeLayer(modalData.layer);
    }
    setLoading(false);
    form.resetFields();
    setDisableNumberAnimal(false);
  }

  const handleBreedSubmit = (e) => {
    breedForm
      .validateFields()
      .then(breed_values => {
        onStoreBreed(breed_values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoadingBreed(false);
      });
  }

  const addBreed = (object, e) => {
    setVisibleBreed(true);
  }

  const addHistory = (object, e) => {
    setVisibleHistory(true);
  }

  const cancelModalBreed = () => {
    setVisibleBreed(false);
  }

  const cancelModalHistory = () => {
    //addHistoryForm.resetFields();
    setVisibleHistory(false);
    setLoadingHistory(false);
  }

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
  }
  const listMobs = (mobs) => {
    let arrayPoint = [];
     _.forEach(mobs, (mob) => {
      if(mob.latitude && mob.longitude){
        arrayPoint.push(
          {
            "lat": mob.latitude,
            "lng": mob.longitude
          }
        );
      }
       
    });
     return arrayPoint;
  }

  const onStore = async (values) => {
    const selectedObject = _.find(props.primaryObjects, (object) => {
      return object.id === values.mob.primary_object_id;
    });
    let point = null;
    let arrayPoints = [];
    const res = await mobService.getByObject(selectedObject.id)
    if(res?.mobs){
      arrayPoints = listMobs(res.mobs);
    }
    if(!dataMob.id || dataMob.primary_object_id != selectedObject.id){
      point = props.getLatLngMarkerArea(1,selectedObject,arrayPoints)[0];
      if(point == null && arrayPoints.length > 0){
        messageWarning();
        cancelModal();
        form.resetFields();
        setDisableNumberAnimal(false);
        setLoading(false);
        return;
      }
    }

  
    
    const data = {
      mob: {
        ...values.mob,
        date_of_birth: values.mob.date_of_birth ? dateHelper.dateForAPI(values.mob.date_of_birth[0]) : '',
        date_of_birth_end: values.mob.date_of_birth ? dateHelper.dateForAPI(values.mob.date_of_birth[1]) : '',
        point,
        property_id: props.propertyId,
        image_id: imageId,
      }
    }

    mobService.storeOrUpdate(data, dataMob.id).then(res => {
      if(res.code === '0000'){
        props.onSaved(res.mob);
        cancelModal();
        form.resetFields();
        setDisableNumberAnimal(false);
      } else {
        setLoading(false);
      }
    });
    
   
  }

  

  const onStoreBreed = (breed_values) => {
    const breed_data = {
      "breed":{
        ...breed_values.breed,
        property_id: props.propertyId
      }
    }

    breedService.storeOrUpdate(breed_data).then(res => {
      if(res.code === '0000'){
        var tmp_breeds = _.clone(breeds);
        tmp_breeds.push(res.breed);
        setBreeds(tmp_breeds);
        cancelModalBreed();
        breedForm.resetFields();
        setFields([
          {
            name: ['mob', 'breed'],
            value: res.breed.slug,
          },
        ]);
      } else {
        setLoadingBreed(false);
      }
    });

  }

  useEffect(() => {
    if(modalData.type === 'mob'){
      if(modalData.object){
        setDataMob(modalData.object);
        updateDataMobHistory(modalData.object.id, modalData.type);
        setFields([
          {
            name: ['mob', 'name'],
            value: dataMob.name || '',
          },
          {
            name: ['mob', 'type'],
            value: dataMob.type || '',
          },
          {
            name: ['mob', 'other_type'],
            value: dataMob.other_type || '',
          },
          {
            name: ['mob', 'breed'],
            value: dataMob.breed || '',
          },
          {
            name: ['mob', 'tag_colour'],
            value: dataMob.tag_colour || 'blue',
          },
          {
            name: ['mob', 'tag_number_range'],
            value: dataMob.tag_number_range || '',
          },
          {
            name: ['mob', 'date_of_birth'],
            value: dataMob.date_of_birth && dataMob.date_of_birth_end ? [moment(dataMob.date_of_birth), moment(dataMob.date_of_birth_end)] : '',
          },
          {
            name: ['mob', 'description'],
            value: dataMob.description || '',
          },
          {
            name: ['mob', 'primary_object_id'],
            value: dataMob.primary_object_id || '',
          },
          {
            name: ['mob', 'meta_data'],
            value: dataMob.meta_data || '',
          },
        ]);
        if(modalData.object.asset_total && modalData.object.asset_total != 0){
          setDisableNumberAnimal(true);
        }
      }
    }
  }, [props.propertyId, modalVisibility, modalData, dataMob]);

  const renderOptionsColor = () => {
    let options = [];
    _.forEach(objectColors, (color, index) => {
      options.push(
        <Option key={index} value={color.value}>
          <ColorItemRow>
            <ColorItemPreview style={{backgroundColor: color.color}}></ColorItemPreview>
            <div>{color.label}</div>
          </ColorItemRow>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a color"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const renderOptionsMobType = () => {
    let options = [];
    _.forEach(mobTypes, (type, index) => {
      options.push(
        <Option key={index} value={type.value}>
            <div>{type.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a type"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const renderOptionsBreed = (mob_breed_options) => {
    let breed_options = [];
     _.forEach(mob_breed_options, (breed_opt, index) => {
      breed_options.push(
        <Option key={index} value={breed_opt.slug}>
            <div>{breed_opt.name}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a breed"
        allowClear
        size="large"
      >
        {breed_options}
      </Select>
    );
  }

  const editLocation = () => {
    cancelModal();
    props.editLocation();
  }

  const renderOptionObject = (objects) => {
    let options = [];
    _.forEach(objects, (object, index) => {
      options.push(
        <Option key={object.id} value={object.id}>
          {object.name}
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select an object"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const [activeTab,setActiveTab] = useState("1");

  const onChangeTab = (key) => {
    setActiveTab(key);
  }

  const renderDeleteHistoryButton = (history) => {
    return (
        (activePermission == "owner" || activePermission == "admin"|| activePermission == "modify") && <Popconfirm
          placement="bottomRight"
          title="You are about to delete individual record"
          onConfirm={() => {
            confirmDelete(history);
          }}
          okText="Yes"
          cancelText="No"
          className="delete-button"
        >
        <a style={{color:'red', marginRight: '20px', float:'right'}}>
          {<CloseOutlined />}
        </a>
        </Popconfirm>
      )
  }

  const renderEditHistoryButton = (history) => {
    return (
      (activePermission == "owner" || activePermission == "admin"|| activePermission == "modify") &&
      <a style={{color:'#65BB38', marginRight: '20px', float:'right'}} onClick={() => showEditHistoryModal(history)}>
        {<EditOutlined />}
      </a>
    )
  }

  const showEditHistoryModal = (history_data) => {
    setHistoryData(history_data);
    setVisibleEditHistoryModal(true);
  }

  const cancelEditHistoryModal = () => {
    setHistoryData({});
    setVisibleEditHistoryModal(false);
  }

  const confirmDelete = (del_history) => {
    if(del_history.id){
       activityService.destroy(del_history.id).then(res => {
        if(res.code === '0000'){
          updateDataMobHistory(dataMob.id, modalData.type);    
          props.updateDataMobs();    
        }
      });
    }
  }

  const uploadProps = {
    name: "Image[file]",
    action: apiEndpoint.images,
    headers: authHeader(true),
    beforeUpload: (file, fileList) => {
      let $oldOne = $('.anticon-delete')[0];
      setTimeout(()=>{
        if($oldOne)
          $($oldOne).trigger('click');
      },100);
    },
    onChange: (response) => {
      if(response.file && response.file.status == "done"){
        setImageId(response.file.response.image.id);
      }
    },    
    listType: "picture",
    multiple: false
  }

  const splitMob = () => {
    setVisibleSplitModal(true);
  }

  const cancelSplitModal = () => {
    setVisibleSplitModal(false);
    setValueCheckboxMob([]);
  }

  const snakeCaseToCapitialize = (value) => {
    return value.replaceAll('_', ' ').replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
  }

  const afterMobArchived = () => {
    dispatch(closeModal());

    if (props.updateDataMobs) {
      props.updateDataMobs();
    }
  }
  const messageWarning = () => {
    messageApi.open({
      type: 'warning',
      content: 'The mob area is not enough',
    });
  };
  return(
    <>
    {contextHolder}
    <MobModalWrapper
      visible={modalVisibility && modalData.type === 'mob'}
      onCancel={cancelModal}
      maskClosable={true}
      title={dataMob.id ? <IntlMessages id="propertyPage.modal.mob.editMob"/> : <IntlMessages id="propertyPage.modal.mob.addMob"/>}
      footer={[
        (activePermission == "owner" || activePermission == "admin"|| activePermission == "modify") && dataMob.id 
        && <Button key="split" onClick={splitMob} style={{float: 'left'}} className="action-button" 
        disabled={( (dataMob.tag_number_range > 1 && dataMob.asset_total == 0) || valueCheckboxMob.length > 0) ? false : true}>
          {<IntlMessages id="propertyPage.modal.split" />}
        </Button>,
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        (activePermission == "owner" || activePermission == "admin"|| activePermission == "modify") && <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit} loading={loading}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
       <Tabs defaultActiveKey="1" className="form-body"
        onChange={onChangeTab}
        activeKey={activeTab}

       >
            <TabPane tab={<IntlMessages id="propertyPage.mobModal.detailTab" />} key="1">
             {
                  modalLoading ?
                  <Loader /> : 
                    <Form
                      form={form}
                      layout="vertical"
                      scrollToFirstError
                      fields={fields}
                      id="#1"
                    >
                    <div>
                      <Form.Item
                        name={['mob', 'name']}
                        className="isoInputWrapper"
                        label="Name"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                          },
                          {
                            whitespace: true,
                            message: 'Please input your name',
                          }
                        ]}
                      >
                        <Input size="large" placeholder="Input mob name"/>
                      </Form.Item>
                      <Form.Item
                        name={['mob', 'type']}
                        className="isoInputWrapper"
                        label={<IntlMessages id="propertyMapPage.modal.mob.animalField" />}
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                          },
                        ]}
                      >
                        {renderOptionsMobType()}
                      </Form.Item>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => {
                          return (prevValues['mob']);
                        }}
                      >
                      {({ getFieldValue }) => {
                          if(getFieldValue('mob')){
                            if (getFieldValue('mob').type === "other"){
                              return (
                                <Form.Item
                                  name={['mob', 'other_type']}
                                  className="isoInputWrapper"
                                  label={<IntlMessages id="propertyMapPage.modal.mob.otherAnimalField" />}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'This field is required',
                                      whitespace: true
                                    },
                                    {
                                      max:255,
                                      message: 'Other Animal must be maximum 255 characters.'
                                    },
                                  ]}
                                >
                                  <Input size="large" placeholder="Input other animal"/>
                                </Form.Item>
                              )
                            }
                          }
                        }
                      }
                      </Form.Item>
                      <Form.Item
                        name={['mob', 'date_of_birth']}
                        className="isoInputWrapper"
                        label="Date Of Birth"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                          },
                        ]}
                      >
                        <RangePicker size="large" style={{width: '100%'}} />
                      </Form.Item>
                      <Row>
                        <Col xs={{span: 15}} md={{span: 17}}>
                          <Form.Item
                            name={['mob', 'breed']}
                            className="isoInputWrapper"
                            label="Breed"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                          {renderOptionsBreed(breeds)}
                          </Form.Item>
                        </Col>
                        <Col xs={{span: 7, offset: 2}} md={{span: 5, offset: 2}}>
                          <Button key="submit" className="btn-create-breed" type="primary" onClick={addBreed}>
                            {<IntlMessages id="propertyPage.modal.breed.addBreed" />}
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={{span: 24}} md={{span: 11}}>
                          <Form.Item
                            name={['mob', 'tag_colour']}
                            className="isoInputWrapper"
                            label="Tag Colour"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            {renderOptionsColor()}
                          </Form.Item>
                        </Col>
                        <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                          <Form.Item
                            name={['mob', 'tag_number_range']}
                            className="isoInputWrapper"
                            label={<IntlMessages id="propertyPage.modal.mob.numberAnimal" />}
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            <InputNumber size="large" min={1} max={9999} style={{width: '100%'}} disabled={disableNumberAnimal} />
                          </Form.Item>
                        </Col>
                      </Row>
                      
                      <Form.Item
                        name={['mob', 'primary_object_id']}
                        className="isoInputWrapper"
                        label={<IntlMessages id="propertyPage.modal.mob.paddock" />}
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                          },
                        ]}
                      >
                        {renderOptionObject(props.primaryObjects)}
                      </Form.Item>
                      
                      <Form.Item
                        name={['mob', 'description']}
                        className="isoInputWrapper"
                        label="Description"
                      >
                        <Input.TextArea />
                      </Form.Item>
                      {activePropertySubscription === process.env.REACT_APP_STRIPE_PLAN_GOLD && 
                        <><Form.Item name={['attachment']} label="Attachment">
                          <Upload
                            {...uploadProps}
                          >
                            <Button className="action-button" icon={<UploadOutlined />}>Upload</Button>
                          </Upload>
                        </Form.Item>
                        {
                          dataMob.images && dataMob.images.length > 0 && <img style={{width:"50%"}}src={process.env.REACT_APP_API_BASE_URL + dataMob.images[dataMob.images.length-1].path} />
                        }
                        </>
                      }
                      {(dataMob.tag_number_range > 1 && dataMob.asset_total == 0) && 
                        <>
                        <Divider dashed orientation="left" orientationMargin="left">Attributes</Divider>
                        <Form.List name={['mob', 'meta_data']} label="Attributes">
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map(({ key, name, fieldKey, ...restField }) => (
                                <>
                                  <Row>
                                    <Col xs={{span: 10}} md={{span: 10}}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'name']}
                                        fieldKey={[fieldKey, 'name']}
                                        label="Name"
                                        rules={[{ required: true, message: 'This field is required' }]}
                                      >
                                        <Input size="large" placeholder="Name" />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={{span: 10, offset: 1}} md={{span: 10, offset: 1}}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'value']}
                                        label="Value"
                                        fieldKey={[fieldKey, 'value']}
                                        rules={[{ required: true, message: 'This field is required' }]}
                                      >
                                        <InputNumber placeholder="Value" size="large" min={1} style={{width: '100%'}} />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={{span: 2, offset: 1}} md={{span: 2, offset: 1}} style={{display: 'flex', alignItems: 'center'}}>
                                      <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Col>
                                  </Row>
                                </>
                              ))}
                              <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  Add Attribute
                                </Button>
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                        </>
                      }
                    </div>  
                </Form>
              }
            </TabPane>
            {dataMob.id && <TabPane tab={<IntlMessages id="propertyPage.mobModal.livestockTab" />} key="2">
              {activeTab == "2" && <LiveStock mobId={dataMob.id} updateMobData={updateMobData} valueCheckboxMob={valueCheckboxMob} setValueCheckboxMob={setValueCheckboxMob}/>}
            </TabPane>}
            {dataMob.id && <TabPane tab={<IntlMessages id="propertyPage.mobModal.historyTab" />} key="3">
              {activeTab == "3" && (
                  <div>
                      <Row>
                        <Col xs={{span: 7}} md={{span: 7}}>
                          {(activePermission == "owner" || activePermission == "admin"|| activePermission == "modify") && <Button key="submit" className="btn-create-breed" type="primary" onClick={addHistory}>
                            {<IntlMessages id="propertyPage.mobModal.historyTab.addHistory" />}
                          </Button>}
                        </Col>
                        <Col xs={{span: 15}} md={{span: 17}}>
                          
                        </Col>
                      </Row>
                       <div style={{ overflow: "scroll", width: "100%", height: "600px" }}>
                <br/>
                <Timeline>
                  {(activityHistory) ? (activityHistory.map((value, index) => {
                      if(value.metadata.value && value.metadata.value.weight){
                         return  (<Timeline.Item color="green" key={index}>
                                    <p>{moment(value.created_at).format('ll')}</p>
                                    <Card
                                      title="Weight"
                                      bordered={true}
                                      extra={ value.metadata.original_mob ? (<Text type="secondary">{value.metadata.original_mob.name}</Text>) : ''}
                                    >
                                      <p className="card_content">Weight</p> 
                                      <b className="card_value">{value.metadata.value.weight} kg</b>
                                    </Card>
                                  </Timeline.Item>)
                      }

                      if(value.metadata.moving){
                         return (<Timeline.Item color="green" key={index}>
                                    {renderDeleteHistoryButton(value)}
                                    {renderEditHistoryButton(value)}
                                    <p>
                                    { ( (typeof value.metadata.moving.created_at !== 'undefined') && value.metadata.moving.created_at !== '' ) ? moment(value.metadata.moving.created_at).format('ll') : moment(value.created_at).format('ll')}
                                    { ( (typeof value.metadata.moving.time !== 'undefined') && value.metadata.moving.time !== '' ) ? ' ,'+value.metadata.moving.time : ''}
                                    </p>
                                    <Card
                                      title="Moving"
                                      bordered={true}
                                      extra={ value.metadata.original_mob ? (<Text type="secondary">{value.metadata.original_mob.name}</Text>) : ''}
                                    >
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Historical Area</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.moving.source}</b>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">New Area</p> 
                                        </Col>
                                          
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                        <b className="card_value">{value.metadata.moving.destination}</b>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Duration in Area</p> 
                                        </Col>
                                          
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                        <b className="card_value">{value.metadata.moving.duration}</b>
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Timeline.Item>)
                      }

                      if(value.metadata.treatment){
                        return (<Timeline.Item color="green" key={index}>
                                    {renderDeleteHistoryButton(value)}
                                    {renderEditHistoryButton(value)}
                                    <p>{moment(value.created_at).format('ll')}</p>
                                    <Card
                                      title="Treatment"
                                      bordered={true}
                                      extra={ value.metadata.original_mob ? (<Text type="secondary">{value.metadata.original_mob.name}</Text>) : ''}
                                    >
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Treatment name</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.treatment.treatment_name}</b>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Quantity</p> 
                                        </Col>
                                          
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                        <b className="card_value">{value.metadata.treatment.treatment_quantity}</b>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Unit</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.treatment.treatment_unit}</b>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">WHP</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.treatment.treatment_WHP}</b>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">ESI</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.treatment.treatment_ESI}</b>
                                        </Col>
                                      </Row>
                          
                                    </Card>
                                  </Timeline.Item>)
                      }

                      if(value.metadata.feeding){
                        return (<Timeline.Item color="green" key={index}>
                                    {renderDeleteHistoryButton(value)}
                                    {renderEditHistoryButton(value)}
                                    <p>{moment(value.created_at).format('ll')}</p>
                                    <Card
                                      title="Feeding"
                                      bordered={true}
                                      extra={ value.metadata.original_mob ? (<Text type="secondary">{value.metadata.original_mob.name}</Text>) : ''}
                                    >
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Feed Type</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.feeding.feeding_type}</b>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Quantity</p> 
                                        </Col>
                                          
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                        <b className="card_value">{value.metadata.feeding.feeding_quantity}</b>
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Timeline.Item>)
                      }

                      if(value.metadata.joining){
                        return (<Timeline.Item color="green" key={index}>
                                    {renderDeleteHistoryButton(value)}
                                    {renderEditHistoryButton(value)}
                                    <p>{moment(value.created_at).format('ll')}</p>
                                    <Card
                                      title="Joining"
                                      bordered={true}
                                      extra={ value.metadata.original_mob ? (<Text type="secondary">{value.metadata.original_mob.name}</Text>) : ''}
                                    >
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Sire</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.joining.joining_siren}</b>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Expected Duration</p> 
                                        </Col>
                                          
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                        <b className="card_value">{value.metadata.joining.joining_expected_duration}</b>
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Timeline.Item>)
                      }

                      if(value.metadata.marking){
                        return (<Timeline.Item color="green" key={index}>
                                    {renderDeleteHistoryButton(value)}
                                    {renderEditHistoryButton(value)}
                                    <p>{moment(value.created_at).format('ll')}</p>
                                    <Card
                                      title="Marking"
                                      bordered={true}
                                      extra={ value.metadata.original_mob ? (<Text type="secondary">{value.metadata.original_mob.name}</Text>) : ''}
                                    >
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">System Date</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{moment(value.metadata.marking.marking_system_date).format('ll')}</b>
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Timeline.Item>)
                      }

                      if(value.metadata.weighing){
                        return (<Timeline.Item color="green" key={index}>
                                    {renderDeleteHistoryButton(value)}
                                    {renderEditHistoryButton(value)}
                                    <p>{moment(value.created_at).format('ll')}</p>
                                    <Card
                                      title="Weighing"
                                      bordered={true}
                                      extra={ value.metadata.original_mob ? (<Text type="secondary">{value.metadata.original_mob.name}</Text>) : ''}
                                    >
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Weight</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.weighing.weight} kg</b>
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Timeline.Item>)
                      }

                      if(value.metadata.preg_testing){
                        return (<Timeline.Item color="green" key={index}>
                                    {renderDeleteHistoryButton(value)}
                                    {renderEditHistoryButton(value)}
                                    <p>{moment(value.created_at).format('ll')}</p>
                                    <Card
                                      title="Preg Testing"
                                      bordered={true}
                                      extra={ value.metadata.original_mob ? (<Text type="secondary">{value.metadata.original_mob.name}</Text>) : ''}
                                    >
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Preg Testing</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.preg_testing.preg_testing}</b>
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Timeline.Item>)
                      }

                      if(value.metadata.crutching){
                        return (<Timeline.Item color="green" key={index}>
                                    {renderDeleteHistoryButton(value)}
                                    {renderEditHistoryButton(value)}
                                    <p>{moment(value.created_at).format('ll')}</p>
                                    <Card
                                      title="Crutching"
                                      bordered={true}
                                      extra={ value.metadata.original_mob ? (<Text type="secondary">{value.metadata.original_mob.name}</Text>) : ''}
                                    >
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">System Date</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{moment(value.metadata.crutching.crutching_system_date).format('ll')}</b>
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Timeline.Item>)
                      }

                      if(value.metadata.shearing){
                        return (<Timeline.Item color="green" key={index}>
                                    {renderDeleteHistoryButton(value)}
                                    {renderEditHistoryButton(value)}
                                    <p>{moment(value.created_at).format('ll')}</p>
                                    <Card
                                      title="Shearing"
                                      bordered={true}
                                      extra={ value.metadata.original_mob ? (<Text type="secondary">{value.metadata.original_mob.name}</Text>) : ''}
                                    >
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Date</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{moment(value.metadata.shearing.shearing_system_date).format('ll')}</b>
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Timeline.Item>)
                      }

                      if(value.metadata.death){
                        return (<Timeline.Item color="green" key={index}>
                                    {renderDeleteHistoryButton(value)}
                                    {renderEditHistoryButton(value)}
                                    <p>{moment(value.created_at).format('ll')}</p>
                                    <Card
                                      title="Death"
                                      bordered={true}
                                      extra={ value.metadata.original_mob ? (<Text type="secondary">{value.metadata.original_mob.name}</Text>) : ''}
                                    >
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Number Of Animals</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.death.number_of_animals}</b>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Description</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.death.description}</b>
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Timeline.Item>)
                      }

                      if(value.metadata.sale){
                        return (<Timeline.Item color="green" key={index}>
                                    {renderDeleteHistoryButton(value)}
                                    {renderEditHistoryButton(value)}
                                    <p>{moment(value.created_at).format('ll')}</p>
                                    <Card
                                      title="Sale"
                                      bordered={true}
                                      extra={ value.metadata.original_mob ? (<Text type="secondary">{value.metadata.original_mob.name}</Text>) : ''}
                                    >
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Number Of Animals</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.sale.number_of_animals}</b>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Sale Price</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.sale.sale_price}</b>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Average Weight</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.sale.average_weight}</b>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">$ per kg</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.sale.price_per_weight}</b>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Description</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.sale.description}</b>
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Timeline.Item>)
                      }

                      if(value.metadata.other){
                        return (<Timeline.Item color="green" key={index}>
                                    {renderDeleteHistoryButton(value)}
                                    {renderEditHistoryButton(value)}
                                    <p>{moment(value.created_at).format('ll')}</p>
                                    <Card
                                      title="Other"
                                      bordered={true}
                                      extra={ value.metadata.original_mob ? (<Text type="secondary">{value.metadata.original_mob.name}</Text>) : ''}
                                    >
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Title</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.other.other_title}</b>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Comment</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.other.other_comment}</b>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Location</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.other.other_location}</b>
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Timeline.Item>)
                      }

                      if (value.metadata.update_detail) {
                        return (<Timeline.Item color="green" key={index}>
                          <p>{moment(value.created_at).format('ll')}</p>

                          <Card
                            title="Update Detail"
                            bordered={true}
                            extra={ value.metadata.original_mob ? (<Text type="secondary">{value.metadata.original_mob.name}</Text>) : ''}
                          >
                            {
                              Object.keys(value.metadata.update_detail).map((key) => {
                                return (<Row>
                                  <Col xs={{span: 11}} md={{span: 11}}>
                                    <p className="card_content">{ snakeCaseToCapitialize(key) }</p> 
                                  </Col>
                                   
                                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                   <b className="card_value">{ value.metadata.update_detail[key] }</b>
                                  </Col>
                                </Row>)
                              })
                            }
                          </Card>
                        </Timeline.Item>)
                      }

                      if (value.metadata.split) {
                        return (<Timeline.Item color="green" key={index}>
                          <p>{moment(value.created_at).format('ll')}</p>
                          
                          <Card
                            title="Split"
                            bordered={true}
                            extra={ value.metadata.original_mob ? (<Text type="secondary">{value.metadata.original_mob.name}</Text>) : ''}
                          >
                            <Row>
                              <Col xs={{span: 11}} md={{span: 11}}>
                                <p className="card_content">Original Mob</p> 
                              </Col>
                               
                              <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                               <b className="card_value">{ value.metadata.split.original_mob }</b>
                              </Col>
                            </Row>
                          </Card>
                        </Timeline.Item>)
                      }

                      return false
                      
                  })) : ''}
                </Timeline>
              </div>
                      <AddHistoryModal        
                        visibleHistory={visibleHistory}
                        updateDataMobHistory={updateDataMobHistory}
                        model="mobs"
                        type="mob"
                        dataId={dataMob.id} 
                        primaryObjects={props.primaryObjects}
                        cancelModal={cancelModalHistory}
                        propertyId={props.propertyId}
                        mobHistory={true}
                        numberAnimals={dataMob.tag_number_range}
                        updateNumberAnimal={updateNumberAnimal}
                        updateDataMobs={updateDataMobs}
                        afterArchived={afterMobArchived}
                      />

                      <EditHistoryModal visibleEditHistoryModal={visibleEditHistoryModal} cancelEditHistoryModal={cancelEditHistoryModal}
                        historyData={historyData} updateDataHistory={updateDataMobHistory} propertyId={props.propertyId} dataType={modalData.type} model="mobs"
                      />
                  </div>
              )}
            </TabPane>}
        </Tabs>
        <SplitModal 
          visibleSplitModal={visibleSplitModal} 
          cancelSplitModal={cancelSplitModal} 
          dataMob={dataMob} 
          primaryObjects={props.primaryObjects} 
          breeds={breeds} 
          messageWarning={messageWarning}
          propertyId={props.propertyId} 
          numberAnimals={dataMob.tag_number_range} 
          updateDataMobs={updateDataMobs}
          valueCheckboxMob={valueCheckboxMob}
          cancelModal={cancelModal}
          getLatLngMarkerArea={props.getLatLngMarkerArea}
        />     

    </MobModalWrapper>
    <BreedModalWrapper
      visible={visibleBreed}
      onCancel={cancelModalBreed}
      maskClosable={true}
      centered
      title={ <IntlMessages id="propertyPage.modal.breed.addBreed.title"/> }
      footer={[        
        <Button key="back" onClick={cancelModalBreed} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary" onClick={handleBreedSubmit} loading={loadingBreed}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
    {
      modalLoading ?
      <Loader /> : 
          <Form
            form={breedForm}
            layout="vertical"
            scrollToFirstError
          >
          <div className="form-body">
            <Form.Item
              name={['breed', 'name']}
              className="isoInputWrapper"
              label="Breed"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              <Input size="large" placeholder="Input breed"/>
            </Form.Item>
            </div>    
      </Form>
    }
    </BreedModalWrapper>
    </>
  )
}

export default MobModal;

const ColorItemRow = styled.div`
  display: flex;
  align-items: center;
  line-height: 30px;
  div{
    margin: 4px;
  }
`;

const ColorItemPreview = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 2px;
`;