import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const PrimaryObjectFilterWrapper = styled(Modal)`
.btn-success{
  background-color: #f80e46 !important;
  border-color: ##f80e46 !important;
  &:hover{
    opacity: 0.8;
  }
}
  .ant-checkbox-group-item{
    display: flex;
  }
  .option-container{
    label.title{
      font-weight: bold;
      margin-bottom: 8px;
      display: block
    }
    margin-bottom: 18px;
  }
  .ant-modal-body{
    padding: 20px ;

  }
  
  .form-title{
    padding: 16px 24px;
    border-bottom: 1px solid #eaeef1;
    h3{
      font-size: 1rem;
      color: #729D68;
    }
  }
  .form-body{
    padding: 12px 24px 12px 24px;
    .checkbox-contact{
      margin-bottom: 10px;
    }
  }
`;

export default WithDirection(PrimaryObjectFilterWrapper);