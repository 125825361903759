import styled from "styled-components";
import WithDirection from "@iso/lib/helpers/rtl";

const WeatherWrapper=styled.div`
  position: relative;
  h2 {
    font-size: 0.8em;
  }
  .temperature_weather{
    .sc-bfhvDw{
      @media only screen and (max-width: 767px) {
				overflow-x:auto;
        height:auto;
			}
      span{
				@media only screen and (max-width: 767px) {
					width:auto !important;
					height:auto !important;
				}
			}
    }
  }
  h1 {
    color: #f80e46;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .loading-weather-chart {
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    color: #719e67;
    z-index: 1;
  }
  .ant-col {
    padding: 0.25rem;
  }
  #weather-chat-div .ant-select {
    margin-right: 5px;
  }
  .statistic-card {
    padding: 20px;
    background: #ffffff;
    /* Primary/Light Grey */

    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    box-shadow: 1px 1px 10px rgba(229, 229, 229, 0.6);
    border-radius: 10px;
    .sc-cMa-dbN{
      height: auto;
    }
    .h1Title{
      font-size: 2rem;
    }
    @media only screen and(max-width:767px){
      .sc-lmUcrn{
        height: auto !important;
      }
    }
   
  }
`;

export default WithDirection( WeatherWrapper );

