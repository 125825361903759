import React, { useCallback, useEffect, useState } from "react";
import { Layout } from "antd";
import { options } from "./options";
import Scrollbars from "@iso/components/utility/customScrollBar";
import Menu from "@iso/components/uielements/menu";
import appActions from "@iso/redux/app/actions";
import Logo from "@iso/components/utility/logo";
import SidebarWrapper from "./Sidebar.styles";
import SidebarMenu from "./SidebarMenu";
import siteConfig from "@iso/config/site.config";
import { propertyService, subscriptionService } from "@iso/services";
import { useSelector, useDispatch } from "react-redux";
// import collepseIcon from "src/assets/images/collepse-icon.png";
import collepseIcon from "@iso/assets/images/collepse-icon.png";

const { Sider } = Layout;
const { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed } =
  appActions;

export default function Sidebar() {
  const dispatch = useDispatch();
  const [stripePlan, setStripePlan] = useState(null);

  const { view, openKeys, collapsed, openDrawer, current, height } =
    useSelector((state) => state.App);
  const customizedTheme = useSelector(
    (state) => state.ThemeSwitcher.sidebarTheme
  );
  const properties = useSelector((state) => state.property.properties);
  const activeId = useSelector((state) => state.property.activePropertyId);
  const currentUser = useSelector((state) => state.user.user);

  const activePropertyId = useSelector(
    (state) => state.property.activePropertyId
  );

  function handleClick(e) {
    dispatch(changeCurrent([e.key]));
    if (view === "MobileView") {
      setTimeout(() => {
        dispatch(toggleCollapsed());
        // dispatch(toggleOpenDrawer());
      }, 100);

      // clearTimeout(timer);
    }
  }
  function onOpenChange(newOpenKeys) {
    const latestOpenKey = newOpenKeys.find(
      (key) => !(openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = openKeys.find(
      (key) => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = getAncestorKeys(latestCloseKey);
    }
    dispatch(changeOpenKeys(nextOpenKeys));
  }
  const getAncestorKeys = (key) => {
    const map = {
      sub3: ["sub2"],
    };
    return map[key] || [];
  };

  const isCollapsed = collapsed && !openDrawer;
  const mode = isCollapsed === true ? "vertical" : "inline";
  const onMouseEnter = (event) => {
    if (collapsed && openDrawer === false) {
      dispatch(toggleOpenDrawer());
    }
    return;
  };
  const onMouseLeave = () => {
    if (collapsed && openDrawer === true) {
      dispatch(toggleOpenDrawer());
    }
    return;
  };
  const styling = {
    backgroundColor: customizedTheme.backgroundColor,
  };
  const submenuStyle = {
    alignItems: "center",
    display: "flex",
    backgroundColor: "rgba(0,0,0,0.3)",
    color: 'rgb(143, 148, 151) !important',
  };
  const submenuColor = {
  
    color: customizedTheme.textColor,
  };

  const { toggleCollapsed, toggleChangeText } = appActions;
  const [collapseIcon, setCollapseIcon] = useState(false);
  const [isSectionVisible, setIsSectionVisible] = useState(true);
  const handleToggle = React.useCallback(() => {
    dispatch(toggleCollapsed());
    dispatch(toggleChangeText());
    setCollapseIcon(true);
    // if (collapseIcon === true) {
    //   setCollapseIcon(false)
    // }else{
    //   setCollapseIcon(true)
    // }
    setIsSectionVisible(!isSectionVisible);
  }, [dispatch,isSectionVisible]);


  const handleMailto = () => {
    const mailtoLink = 'mailto:support@airagri.com.au';
    window.location.href = mailtoLink;
  }
  // const { collapsed, openDrawer } = useSelector((state) => state.App);

  // const isCollapsed = collapsed && !openDrawer;

  return (
    <SidebarWrapper className="sidebar-wp" style={{ backgroundColor: "#fff" }}>
      <Sider
        trigger={null}
        collapsible={true}
        collapsed={isCollapsed}
        width={240}
        className="isomorphicSidebar"
        style={styling}
      >
        <Logo collapsed={isCollapsed} />
        <Scrollbars style={{ height: height - 70 }}>

          <Menu
            onClick={handleClick}
            theme="dark"
            className="isoDashboardMenu"
            mode={mode}
            openKeys={isCollapsed ? [] : openKeys}
            selectedKeys={current}
            onOpenChange={onOpenChange}
          >
            {options(properties, activeId, currentUser, currentUser.is_system_admin).map(
              (singleOption) => (
                <SidebarMenu
                  key={singleOption.key}
                  submenuStyle={submenuStyle}
                  // submenuColor={submenuColor}
                  singleOption={singleOption}
                />
              )
            )}
          </Menu>
         
          {/* {isSectionVisible && (
            <div>
              <div className="mailmainBox"><img src={process.env.PUBLIC_URL + "./images/icons/Icon.svg"} className="arrowImg" alt="sdfsdf"></img></div>
              <div className="subDivBox">
                <div className="infoBox">Want to explore about our PLD</div>
                <button className="infoBtn" onClick={handleMailto}>Book a Call</button>
              </div>
            </div>
          )} */}
        </Scrollbars>
        <div
          className={
            collapseIcon
              ? "triggerBtn menuCollapsed"
              : "triggerBtn menuOpen"
          }
          style={{ cursor: "pointer" }}
          onClick={handleToggle}
        >
          <img
            className="des-icon-menu"
            src={collepseIcon}
            style={{ marginRight: "20px" }}
            alt="collepse-icon"
          />
        </div>

      </Sider>
    </SidebarWrapper>
  );
}
