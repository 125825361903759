import styled from "styled-components";
import WithDirection from "@iso/lib/helpers/rtl";

const HazardsPage = styled.div`
  width: 64%;
  
  height: 100%;
  margin-left : 10px;
  background-color: white;
  border-radius : 8px;
  svg.brand-primary-light {
    color: rgb(248, 14, 70) !important;
}
a.ant-picker-now-btn{
  color: #f80e46 !important;
}
button.ant-btn.ant-btn-primary.ant-btn-sm {
  background-color: #f80e46 !important;
}
.manage-table .react-collapsible tbody .hazard_type {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #101828;
}
.property-area-dot-btn span {
  rotate: 90deg;
}
  .dot-btn {
    span {
      rotate: 90deg;
      cursor: pointer;
    }
  }
  .ant-popover-inner {
    border-radius: 12px !important;
  }
  .btn-success {
    background-color: #f80e46;
    border-color: #f80e46;
  }
  .manage-property-title {
    color: #101828;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }
  .manage-property-table {
    border: 1px solid #eaecf0;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 12px;
    background: white;
    .ant-pagination {
      padding: 24px;
    }
    .add-property-btn {
      background: #f80e46;
      border-radius: 8px !important;
      color: #fff;
    }
    .m-p-main {
      padding: 24px;
      display: flex;
      justify-content: space-between;
    }
    .dropdown {
      border: 1px solid #d0d5dd !important;
    }
  }
  .action-btn {
    font-size: 12px;
    line-height: 24px;
    background: #535763;
    border-radius: 10px !important;
    border-color: transparent !important;
    color: white;
  }
  .button-group {
    width: 100%;
    
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .fAgWDa .ant-table-content {
    overflow-x: unset;
  }
  .fAgWDa {
    overflow-x: unset;
  }
  .dtr-data {
    white-space: break-spaces;
  }
  .delete-button {
    margin-left: 5px;
  }
  @media only screen and (max-width: 991px) {
    width: 100%;
    height: 100%;
    margin-left : 0px !important;
    margin-top : 30px !important;
  }
  @media only screen and (width: 1024px) {
    width: 63%;
    padding-left : 0px !important;
   
  }
  @media only screen and (width: 320) {
    width: 100%;
    margin-left : 0px !important;
    border-radius: 8px !important;
  }
  .search-bar {
    background: rgba(143, 146, 161, 0.05);
    border-radius: 12px;
    border: none;
    height:40px;
    margin-left: 2rem;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    flex-direction: row-reverse;
    max-width: 228px;
  }
  .search-bar .ant-input {
    background: none!important;
    border: none!important;
    color: #8F92A1;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
  
  .manage-table {
    position : relative !important;
  }

  .manage-table .loader {
    margin-top:50px !important;
  }

`;

export default WithDirection(HazardsPage);
