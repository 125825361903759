import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const HazardViewModal = styled(Modal)`


border-radius: 15px 15px 0px 0px;
  
  &.visitor-modal{
    width: 700px !important;
  }
  button.ant-btn.ant-btn-default.cancel-button{
    width: 100% !important;
  }
  .ant-btn-primary{
    background-color: #729D68!important;
  }
  .ant-modal-body{
    padding: 0;
  }
  .btn-success{
    background-color: #f80e46 !important;
    border-color: #f80e46;
  }
  .form-title{
    padding: 16px 24px;
    border-bottom: 1px solid #eaeef1;
    h3{
      font-size: 1rem;
      color: #729D68;
    }
  }
  .form-body{
    padding: 12px 24px 12px 24px;
    .checkbox-contact{
      margin-bottom: 10px;
    }
  }
  .dot-btn {
    span {
      rotate: 90deg;
      cursor: pointer;
    }
  }
  // .ant-popover-inner {
  //   border-radius: 12px !important;
  // }
  .btn-success {
    background-color: #f80e46;
    border-color: #f80e46;
  }
  .manage-property-title {
    color: #101828;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }
  .manage-property-table {
    border: 1px solid #eaecf0;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 12px;
    background: white;
    .ant-pagination {
      padding: 24px;
    }
    .add-property-btn {
      background: #f80e46;
      border-radius: 8px !important;
      color: #fff;
    }
    .m-p-main {
      padding: 24px;
      display: flex;
      justify-content: space-between;
    }
    .dropdown {
      border: 1px solid #d0d5dd !important;
    }
  }
  .action-btn {
    font-size: 12px;
    line-height: 24px;
    background: #535763;
    border-radius: 10px !important;
    border-color: transparent !important;
    color: white;
  }
  .button-group {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  
  .fAgWDa .ant-table-content {
    overflow-x: unset;
  }
  .fAgWDa {
    overflow-x: unset;
  }
  .dtr-data {
    white-space: break-spaces;
  }
  .delete-button {
    margin-left: 5px;
  }
`;

export default WithDirection(HazardViewModal);