import styled from "styled-components";
import { palette } from "styled-theme";
import WithDirection from "@iso/lib/helpers/rtl";
import { handleShowRandomImage } from "@iso/constants/randomBackgrounds";

const SignInStyleWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  background: url(${handleShowRandomImage}) no-repeat center center;
  background-size: cover;

  .welcomeClass{
    background-color: #65bb38;
    border-color: #559f2f;
  }
  .btn-success {
    background-color: #65bb38;
    border-color: #559f2f;
    &:hover {
      opacity: 0.8;
    }
  }

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    display: flex;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    z-index: 1;
    top: 0;
    left: ${(props) => (props["data-rtl"] === "rtl" ? "inherit" : "0")};
    right: ${(props) => (props["data-rtl"] === "rtl" ? "0" : "inherit")};
  }

  .isoLoginContentWrapper {
    width: 40%;
    height: 100%;
    overflow-y: auto;
    z-index: 10;
    position: relative;
  }

  .isoLoginContent {
    min-height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 70px 50px;
    position: relative;
    background-color: #ffffff;

    .signin-form-main {
      width: 100%;
      max-width: 470px;
      margin: 0 auto;
    }

    @media only screen and (max-width: 767px) {
      width: 100%;
      padding: 70px 20px;
    }

    .isoLogoWrapper {
      width: 100%;
      display: flex;
      margin-bottom: 50px;
      justify-content: center;
      flex-shrink: 0;

      a {
        font-size: 24px;
        font-weight: 300;
        line-height: 1;
        text-transform: uppercase;
        color: ${palette("secondary", 2)};
      }
    }

    .isoSignInForm {
      width: 100%;
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      .ant-form-item-label > label{
        color: rgb(63, 63, 68) ;
      }
      .ant-form-item-label
        > label.ant-form-item-required:not(
          .ant-form-item-required-mark-optional
        )::before,
      .form-field label::after {
        content: unset;
      }
      .ant-form-item-label {
        font-family: "Work Sans", sans-serif;
       
       
        label {
          font-family: "Work Sans", sans-serif;
        }
      }
      .form-field > div {
        display: unset;
      }

      .isoInputWrapper {
        margin-bottom: 15px;

        &:last-of-type {
          margin-bottom: 0;
        }

        input {
          &::-webkit-input-placeholder {
            color: ${palette("grayscale", 0)};
          }

          &:-moz-placeholder {
            color: ${palette("grayscale", 0)};
          }

          &::-moz-placeholder {
            color: ${palette("grayscale", 0)};
          }
          &:-ms-input-placeholder {
            color: ${palette("grayscale", 0)};
          }
        }
      }

      .isoHelperText {
        font-size: 12px;
        font-weight: 400;
        line-height: 1.2;
        color: ${palette("grayscale", 1)};
        padding-left: ${(props) =>
          props["data-rtl"] === "rtl" ? "inherit" : "13px"};
        padding-right: ${(props) =>
          props["data-rtl"] === "rtl" ? "13px" : "inherit"};
        margin: 15px 0;
        position: relative;
        display: flex;
        align-items: center;

        &:before {
          content: "*";
          color: ${palette("error", 0)};
          padding-right: 3px;
          font-size: 14px;
          line-height: 1;
          position: absolute;
          top: 2px;
          left: ${(props) => (props["data-rtl"] === "rtl" ? "inherit" : "0")};
          right: ${(props) => (props["data-rtl"] === "rtl" ? "0" : "inherit")};
        }
      }

      .isoHelperWrapper {
        margin-top: 35px;
        flex-direction: column;
      }

      .isoOtherLogin {
        padding-top: 40px;
        margin-top: 35px;
        border-top: 1px dashed ${palette("grayscale", 2)};

        > a {
          display: flex;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        button {
          width: 100%;
          height: 42px;
          border: 0;
          font-weight: 500;

          &.btnFacebook {
            background-color: #3b5998;

            &:hover {
              background-color: darken(#3b5998, 5%);
            }
          }

          &.btnGooglePlus {
            background-color: #dd4b39;
            margin-top: 15px;

            &:hover {
              background-color: darken(#dd4b39, 5%);
            }
          }

          &.btnAuthZero {
            background-color: #e14615;
            margin-top: 15px;

            &:hover {
              background-color: darken(#e14615, 5%);
            }
          }

          &.btnFirebase {
            background-color: ${palette("color", 5)};
            margin-top: 15px;

            &:hover {
              background-color: ${palette("color", 6)};
            }
          }

          &.btnAccountKit {
            ${"" /* background-color: rgb(150, 189, 235); */}
            margin-top: 15px;

            &:hover {
              ${"" /* background-color: ${palette('color', 6)}; */}
            }
          }
        }
      }

      .isoForgotPass {
        font-size: 12px;
        color: ${palette("text", 3)};
        margin-bottom: 10px;
        text-decoration: none;

        &:hover {
          color: ${palette("primary", 0)};
        }
      }

      button {
        width: 100%;
        height: 42px;
        border: 0;
        font-weight: 500;
      }
    }
  }
  .sharing-msg {
    font-size: 13px;
    color: ${palette("text", 3)};
    margin-bottom: 10px;
    text-decoration: none;
    text-align: center;
  }

  .signin-inputlabel {
    // font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 40px;
    color: #3f3f44;
  }
  .ant-form-item-label > label{
    color: rgb(63, 63, 68) ;
    font-weight: 600
  }

  .password-placeholder > input {
    background-color: transparent;
    font-size: 14px;
  }

  .signin-placeholder {
    // font-family: "Work Sans";
    
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    height: 58px;
    background: #f7f7f7;
    border-radius: 8px;
    border: none;
  }
  .ant-input {
      &::placeholder {
        color: black !important;
      }
    }

  .signin-btn {
    background: #535763;
    border-radius: 8px !important;
    font-family: "Work Sans", sans-serif;
    span {
      font-family: "Work Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
    }
  }

  .signin-heading {
    font-family: "Work Sans", sans-serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 72px;
    color: #3f3f44;
  }

  .signin-heading-hi {
    font-family: "Work Sans", sans-serif !important;
    color: #3f3f44;
    font-style: normal;
    font-weight: 100 !important;
    font-size: 48px;
    line-height: 72px;
    display: flex;
    margin: 0 0 38px 0;
  }

  .signin-span {
    font-family: "Work Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #3f3f44;
  }

  .signup-btn {
    font-family: "Work Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #f80e46;
    padding-left: 9px;
  }

  .forgotpass {
    font-family: "Work Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #3f3f44;
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: -25px;
    padding-top: 15px;
  }

  .signin-label-div {
    display: flex;
    justify-content: space-between;
  }
`;

export default WithDirection(SignInStyleWrapper);
