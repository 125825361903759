import Dashboard from "@iso/containers/Pages/Dashboard/Dashboard";
import ManageProperty from "@iso/containers/Pages/Property/ManageProperty/ManageProperty";
import PropertyMap from "@iso/containers/Pages/PropertyMap/PropertyMap";
import Task from "@iso/containers/Pages/Task/Task";
import PropertyArea from "@iso/containers/Pages/PropertyArea/PropertyArea";
import Weather from "@iso/containers/Pages/Weather/Weather";
import Hazards from "@iso/containers/Pages/Hazards/Hazards";
import Visitor from "@iso/containers/Pages/Visitor/Visitor";
import Employee from "@iso/containers/Pages/Employee/Employee";
import Asset from '@iso/containers/Pages/Asset/Asset';
import Search from "@iso/containers/Pages/Search/Search";
import Profile from "@iso/containers/Pages/Profile/Profile";
import Property from "../containers/Pages/Property/Property";
import Form from "@iso/containers/Pages/Form/Form";
import HealthSafety from "@iso/containers/Pages/HealthSafety/Dashboard";
import SafeFarm from "@iso/containers/Pages/SafeFarm/SafeFarm";
import ManageUserProduct from '@iso/containers/Pages/ManageUserProduct/ManageUserProduct'
import ProductDetail from "../containers/Pages/Marketplace/ProductDetail";


export default {
  Dashboard: {
    component: Dashboard,
    path: "/dashboard",
  },
  Property: {
    component: Property,
    path: "/manage-properties",
  },
  PropertyMap: {
    component: PropertyMap,
    path: "/property-map",
  },
  PropertyArea: {
    component: PropertyArea,
    path: "/property-area",
  },
  Hazards: {
    component: Hazards,
    path: "/hazards",
  },
  Visitor: {
    component: Visitor,
    path: '/visitor'
  },

  Employee: {
    component: Employee,
    path: "/employee",
  },
  Task: {
    component: Task,
    path: "/tasks",
  },
  Weather: {
    component: Weather,
    path: "/weather",
  },

  Profile: {
    component: Profile,
    path: "/profile",
  },

  Form: {
    component: Form,
    path: "/manage-forms",
  },
  HealthSafety: {
    component: HealthSafety,
    path: "/health-safety",
  },
  SafeFarm: {
    component: SafeFarm,
    path: "/safe-farm",
  },
  Asset: {
    component: Asset,
    path: '/manage-assets'
  },
  Search: {
		component: Search,
		path: '/search'
	},
  ManageProductCategory: {
    component: ManageUserProduct,
    path: '/manage-your-product'
  },
  ProductDetail: {
    component: ProductDetail,
    path: '/product-detail'
  },

};
