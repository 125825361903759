import React, { useState, useEffect } from 'react';
import { Marker } from 'react-leaflet';
import AssetModal from './AssetModal';
import EditTreatmentModal from './EditTreatmentModal';
import EditFuelModal from './EditFuelModal';
import EditFeedModal from './EditFeedModal';
import EditWaterModal from './EditWaterModal';
import EditAircraftModal from './EditAircraftModal';
import EditGeneralAssetModal from './EditGeneralAssetModal';
import EditAnimalModal from './EditAnimalModal';
import EditWeatherModal from './EditWeatherModal';
import { assetService, primaryObjectService } from '@iso/services';
import { useSelector, useDispatch } from 'react-redux';
import modalActions from '@iso/redux/modal/actions';
import btnSaveAction from '@iso/redux/btnSave/actions';
import {  IconAsset  } from '../../Icons/IconAsset';
import _ from 'lodash';

const { show } = btnSaveAction;

const { openModal } = modalActions;

const Asset = (props) => {
  const [assets, setAssets] = useState({});
  const objectRef = React.useRef([]);
  const dispatch = useDispatch();

  const [dataAsset, setDataAsset] = useState({});
  const [primaryObjects, setPrimaryObjects] = useState([]);
  const saveSuccess = useSelector((state) => state.btnSave.saveSuccess);
  const dataBtnSave = useSelector((state) => state.btnSave.data);
  const cancelSave = useSelector((state) => state.btnSave.cancelSave);
  const dataTransfer = useSelector((state) => state.btnSave.data);
  const modalData = useSelector((state) => state.modal.modalData);
  const [visibleAssetTreatment, setVisibilityAssetTreatment] = React.useState(false);
  const [visibleAssetFuel, setVisibilityAssetFuel] = React.useState(false);
  const [visibleAssetFeed, setVisibilityAssetFeed] = React.useState(false);
  const [visibleAssetWater, setVisibilityAssetWater] = React.useState(false);
  const [visibleAssetAircraft, setVisibilityAssetAircraft] = React.useState(false);
  const [visibleAssetOther, setVisibilityAssetOther] = React.useState(false);
  const [visibleAssetAnimal, setVisibilityAssetAnimal] = React.useState(false);
  const [visibleAssetWeather, setVisibilityAssetWeather] = React.useState(false);

  const updateDataAssets = React.useCallback((propertyId) => {
    assetService.getList(propertyId).then(res => {
      if(res.code === '0000'){
        setAssets(res.facilities);
      }
    })
  }, [props.primaryObjects]);

  useEffect(() => {
    if(saveSuccess && dataBtnSave.type === 'area'){
      const primaryObjectId = dataBtnSave.dataId;
      setTimeout(() => {
        updateDataAssets(props.propertyId)
      }, 500);
    }
  }, [saveSuccess, dataBtnSave]);

  useEffect(() => {
    setPrimaryObjects(props.primaryObjects);
  }, [props.primaryObjects]);

  useEffect(() => {
    if(props.propertyId){
      updateDataAssets(props.propertyId);
    }
  }, [updateDataAssets, props.propertyId]);

  const saveLocation = React.useCallback((asset, dataTransfer) => {
    var layer = objectRef.current[asset.id].leafletElement;

    var point = dataTransfer.points;

    const data = {
      facility:{
        ...asset,
        point
      }
    }
    assetService.storeOrUpdate(data, asset.id).then(res => {
      if(res.code === '0000'){
        updateDataAssets(res.facility.property_id)
        layer.editing.disable();
      }
    });

  }, []);

  const cancelSaveArea = React.useCallback((propertyId, dataObjectId) => {
    var layer = objectRef.current[dataObjectId].leafletElement;
    layer.editing.disable();
  }, []);

  useEffect(() => {
    if(saveSuccess && dataTransfer.type === 'asset' && dataAsset){
      saveLocation(dataAsset, dataTransfer);
    } else if (cancelSave && dataTransfer.type === 'asset'){
      cancelSaveArea(props.propertyId, dataAsset.id);
    }
  }, [saveSuccess, saveLocation, dataTransfer, props.propertyId, cancelSave, dataAsset])


  const handleShowAssets = (data) => {
    var html = [];
    if(data.length > 0){
      _.forEach(data, function(asset){
        if(asset.lat && asset.lng){
          html.push(
            <Marker 
              key={asset.id} 
              position={[asset.lat, asset.lng]}
              icon={IconAsset(asset.category, asset.tag_colour, asset.animal_type, asset.type)}
              onClick={handleClickAsset.bind(this, asset)}
              onDragEnd={handleEditAsset.bind(this, asset)}
              onRemove={handleRemoveAsset.bind(this, asset)}
              ref={el => (objectRef.current[asset.id] = el)}            
            />
          );
        }
      });
    }
    return html;
  }
 
  const handleEditAsset = (object, e) => {
    let LatLng = e.target.getLatLng();
    object.lat = LatLng.lat;
    object.lng = LatLng.lng;
    props.handeSetUpdatedAssets(object, LatLng);
  }
  
  const handleClickAsset = (object, e) => {
    if(!props.controlDraw.current.leafletElement._toolbars.edit._modes.remove 
      || !props.controlDraw.current.leafletElement._toolbars.edit._modes.remove.handler._enabled){
      setDataAsset(object);
      if(object.type === 'animal_treatment' || object.type === 'fertiliser' || object.type === 'vaccine' ){
        setVisibilityAssetTreatment(true);
      }

      if(object.type === 'fuel' || object.type === 'chemical'){
        setVisibilityAssetFuel(true);
      }

      if(object.type === 'stock_feed'){
        setVisibilityAssetFeed(true);
      }

      if(object.type === 'water'){
        setVisibilityAssetWater(true);
      }

      if(object.type === 'aircraft'){
        setVisibilityAssetAircraft(true);
      }

      if(object.type === 'other'){
        setVisibilityAssetOther(true);
      }

      if(object.category === 'animal'){
        setVisibilityAssetAnimal(true);
      }

      if(object.category === 'weather'){
        setVisibilityAssetWeather(true);
      }

      if(object.category !== 'inventory' && object.category !== 'animal' && object.category != 'weather'){
        setVisibilityAssetOther(true);
      }

    }
  }

  const handleRemoveAsset = (object, e) => {
    props.handleSetDeletedAssets(object.id);    
  }

  const onSaved = (savedAsset) => {
    let assetTmps = _.clone(assets);
    const assetIndex = _.findIndex(assetTmps, (asset) => {
      return asset.id === savedAsset.id
    });
    if(assetIndex === -1){
      assetTmps.push(savedAsset);
    } else {
      assetTmps[assetIndex] = savedAsset;
    }
    setAssets(assetTmps);
    props.updatePropertyDetail(props.propertyId);
  }

  const cancelEditTreatmentModal = () => {
    setVisibilityAssetTreatment(false);
  }

  const cancelEditFuelModal = () => {
    setVisibilityAssetFuel(false);
  }

  const cancelEditFeedModal = () => {
    setVisibilityAssetFeed(false);
  }

  const cancelEditWaterModal = () => {
    setVisibilityAssetWater(false);
  }

  const cancelEditAircraftModal = () => {
    setVisibilityAssetAircraft(false);
  }

  const cancelEditOtherModal = () => {
    setVisibilityAssetOther(false);
  }

  const cancelEditAnimalModal = () => {
    setVisibilityAssetAnimal(false);
  }

  const cancelEditWeatherModal = () => {
    setVisibilityAssetWeather(false);
  }


  return (
    <>
      {handleShowAssets(assets)}
      <AssetModal        
        updateDataAssets={updateDataAssets}
        propertyId={props.propertyId}
        primaryObjects={primaryObjects}
        onSaved={onSaved}
      />
      <EditTreatmentModal        
        updateDataAssets={updateDataAssets}
        propertyId={props.propertyId}
        primaryObjects={primaryObjects}
        onSaved={onSaved}
        visibleAssetTreatment={visibleAssetTreatment}
        dataAsset={dataAsset}
        cancelEditModal={cancelEditTreatmentModal}
      />
      <EditFuelModal        
        updateDataAssets={updateDataAssets}
        propertyId={props.propertyId}
        primaryObjects={primaryObjects}
        onSaved={onSaved}
        visibleAssetFuel={visibleAssetFuel}
        dataAsset={dataAsset}
        cancelEditModal={cancelEditFuelModal}
      />
      <EditFeedModal        
        updateDataAssets={updateDataAssets}
        propertyId={props.propertyId}
        primaryObjects={primaryObjects}
        onSaved={onSaved}
        visibleAssetFeed={visibleAssetFeed}
        dataAsset={dataAsset}
        cancelEditModal={cancelEditFeedModal}
      />
      <EditWaterModal        
        updateDataAssets={updateDataAssets}
        propertyId={props.propertyId}
        primaryObjects={primaryObjects}
        onSaved={onSaved}
        visibleAssetWater={visibleAssetWater}
        dataAsset={dataAsset}
        cancelEditModal={cancelEditWaterModal}
      />
      <EditAircraftModal        
        updateDataAssets={updateDataAssets}
        propertyId={props.propertyId}
        primaryObjects={primaryObjects}
        onSaved={onSaved}
        visibleAssetAircraft={visibleAssetAircraft}
        dataAsset={dataAsset}
        cancelEditModal={cancelEditAircraftModal}
      />
      <EditGeneralAssetModal        
        updateDataAssets={updateDataAssets}
        propertyId={props.propertyId}
        primaryObjects={primaryObjects}
        onSaved={onSaved}
        visibleAssetOther={visibleAssetOther}
        dataAsset={dataAsset}
        cancelEditModal={cancelEditOtherModal}
      />

      <EditAnimalModal        
        updateDataAssets={updateDataAssets}
        propertyId={props.propertyId}
        primaryObjects={primaryObjects}
        onSaved={onSaved}
        visibleAssetAnimal={visibleAssetAnimal}
        dataAsset={dataAsset}
        cancelEditModal={cancelEditAnimalModal}
      />

      <EditWeatherModal   
        updateDataAssets={updateDataAssets}
        propertyId={props.propertyId}
        primaryObjects={primaryObjects}
        onSaved={onSaved}
        visibleAssetWeather={visibleAssetWeather}
        dataAsset={dataAsset}
        cancelEditModal={cancelEditWeatherModal}
      />

    </>
  )
}

export default Asset;