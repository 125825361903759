import React, {useState,useEffect } from 'react';
import { Table,Button,Popover } from 'antd';
import { alertDeviceService } from '@iso/services';
import AlertDeviceWrapper from './AlertDevice.styles'; 
import IntlMessages from '@iso/components/utility/intlMessages';
import { SyncOutlined,EnvironmentOutlined } from '@ant-design/icons';
import DeviceStatusLight from './DeviceStatusLight';
import StopDeviceAlarmRequestModal from './StopDeviceAlarmRequestModal';
import styled from "styled-components";
const AlertDevice = (props) => {

  const {propertyId, setCenterPoint, setPositionDevice, setShowMarkerAlert} = props;
  
  const [dataAlert, setDataAlert] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedIotDevice, setSelectedIotDevice] = useState();
  const [isVisibleStopAlarmRequestModal, setIsVisibleStopAlarmRequestModal] = useState(false);

  const popoverDeviceStatus =(
    <div>
      <div style={{display:'inline-flex'}}>
        <ColorItemPreview style={{backgroundColor: '#00965b'}}></ColorItemPreview> &nbsp;&nbsp; Cancelled
      </div> <br/>
      <div style={{display:'inline-flex'}}>
        <ColorItemPreview style={{backgroundColor: '#f6cf67'}}></ColorItemPreview> &nbsp;&nbsp; Just cancelled in last 60 min 
      </div><br/>   
      <div style={{display:'inline-flex'}}>
        <ColorItemPreview style={{backgroundColor: '#ea5252'}}></ColorItemPreview> &nbsp;&nbsp; Active
      </div>
    </div>
  );  

  const handleShowStopAlarmRequests = (iotDevice) => {
    setIsVisibleStopAlarmRequestModal(true);
    setSelectedIotDevice(iotDevice);
  }

   useEffect(() => {
    if(propertyId){
     getListAlert(propertyId);
    }
  },[propertyId]);

  const getListAlert = (propertyId) => {
    alertDeviceService.get(propertyId).then(res => {
      if(res.code === "0000"){
        setDataAlert(res.alert_devices);
        setLoading(false);
      }
    });

  }
console.log("dataAlert",dataAlert);
  const columnsTable = [
    {
      title: <IntlMessages id="safeFarmPage.deviceName" />,
      dataIndex: 'device_name',
      key: 'device_name',
      fixed: 'left',
      sorter: (a, b) => a.device_name.length - b.device_name.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: <IntlMessages id="safeFarmPage.type" />,
      dataIndex: 'type',
      key: 'type'
    },   
    {
      title: <IntlMessages id="uiElements.badge.status" />,
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => {
        const stopRequests = record?.iot_device?.stop_device_alarm_requests
        let light = 'red' 
        if(stopRequests && stopRequests.length){
          const firstStopRequest = stopRequests[0]

          let currentTime = new Date();
          let firstStopRequestAt = new Date(firstStopRequest.created_at);
          let diffMinutes = Math.floor((currentTime - firstStopRequestAt) / (1000 * 60))
          if(diffMinutes <= 60){
            light= "yellow" 
          }else{
            light = "green"
          }
        }
        return (
          <Popover content={popoverDeviceStatus} title="Status">
            <div>
              <DeviceStatusLight color={light} onClick={()=>handleShowStopAlarmRequests(record.iot_device)}>
              </DeviceStatusLight>
            </div>
            
          </Popover>
        )
      },
    },    
    { title: <IntlMessages id="safeFarmPage.dateTimeAustralia" />,
      dataIndex: 'date_time',
      key: 'date_time',
    },
    {
      title: 'Location',
      key: 'location',
      render: (_, record) => (
        <Button className="ant-btn-location" onClick={() => getLocation(record)}>
         <EnvironmentOutlined /> 
        </Button>
      ),
    },  
  ];
  
  const reloadDataAlert = () => {
    setLoading(true);
    if(propertyId){
      getListAlert(propertyId);
    }
  }
  const getLocation = (data) => {
    setCenterPoint([data.lat,data.lng]);
    setPositionDevice(data);
    setShowMarkerAlert(true);
  }

  const cancelStopAlarmModal = () => {
    setIsVisibleStopAlarmRequestModal(false)
    setSelectedIotDevice(null)
  }

  return (
    <AlertDeviceWrapper>
      <Table dataSource={dataAlert} columns={columnsTable} size="small" pagination={{pageSize: 12}}  />  
      <Button className="reload-button-alert" onClick={reloadDataAlert} >
         { loading ? <SyncOutlined spin /> : <SyncOutlined />}
      </Button>
      <StopDeviceAlarmRequestModal selectedIotDevice={selectedIotDevice} visible={isVisibleStopAlarmRequestModal} cancelModal={cancelStopAlarmModal}/>
    </AlertDeviceWrapper>
  );
}

export default AlertDevice;

const ColorItemPreview = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;