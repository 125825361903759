import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const VisitorPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  span.anticon.anticon-ellipsis {
    rotate: 90deg;
}
  svg.brand-primary-light {
    color: rgb(248, 14, 70) !important;
}

.search-bar {
  background: rgba(143, 146, 161, 0.05);
  border-radius: 12px;
  border: none;
  margin-left: 2rem;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  flex-direction: row-reverse;
  max-width: 228px;
}
.search-bar .ant-input {
  background: none!important;
  border: none!important;
  color: #8F92A1;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
  .btn-success{
    border-color: #559f2f;
    margin-right: 10px;
    background-color: #f80e46;
    padding: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: normal;
    padding-left:15px;
    padding-right:15px;
    :hover{
      opacity:1;
    }
    span{
      margin-right:8px;
    }
  }

  .button-group{
    gap: 12px;
  
    width: 100%;
   
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .fAgWDa .ant-table-content{
    overflow-x: unset;
  }
  .fAgWDa{
    overflow-x: unset;
  }
  .dtr-data{
    white-space: break-spaces;
  }
  iframe {
    height: 500px;
    width: 100%;
  }
  .signature-area {
    margin-bottom: 20px;
    canvas{
      border: solid 1px black;
      max-width: 100%;
      width: 100%;
      height: 300px;
      max-height: 250px;
    }
  }

  .signature-clear-button {
    display: block;
  }
  .action-button{
    margin-right: 10px;
  }
  .signature-image{
    max-width: 200px
  }
  td.signature{
    text-align: center
  }
  .visiter_table_main{
    background: rgb(255, 255, 255);
    border: 1px solid rgb(229, 229, 229);
    box-sizing: border-box;
    box-shadow: rgba(229, 229, 229, 0.6) 1px 1px 10px;
    border-radius: 10px;
    .top_title_visiter{
     padding:20px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      margin: auto;
      h1{
        margin-left : 10px !important;
         padding-top:10px;
         
          color: rgb(16, 24, 40);
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
        :after{
          display:none
        }
        :before{
          display:none
        }
      }
      .button-group{
        button{
          border-radius:8px !important;
         
        }
        .ant-select{
          border-radius:8px !important;
          .ant-select-selector{
            border-radius:8px !important;
            border: 1px solid #d0d5dd !important;

          }
        }
      }
      .VerticalDivider{
        border-left:4px solid rgba(0, 0, 0, 0.08);
        height:35px;
      }
      .HorizontalDeivider{
        // margin-left : 20px;
        min-width : 93.5% !important;
      }
    }
    .table_visiter .react-collapsible tbody .name {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #101828;
    }
    .react-collapsible-theme .brand-primary-light {
      color: rgb(248, 14, 70) !important;
    }
    .table_visiter{
      box-shadow: none !important;
      border-radius: 0 !important;
      .react-collapsible-theme{
        .react-collapsible{
          box-shadow: none !important;
          border-radius: 0 !important;
          border:unset;
          tbody{
            .actions{
              text-align:right;
              .ant-btn{
                border-radius:8px !important;
              }
            }
          }
        }
      }

    }
  }
  @media only screen and (width: 425px) {
    .visiter_table_main .top_title_visiter{
      
      flex-direction: column !important;
    
    }
    .visiter_table_main .top_title_visiter h1{
      padding:0px !important;
      margin:0px 0px 10px 0px !important;
    }
    .visiter_table_main .button-group{
      display: block !important;
      justify-content:flex-start !important;
     
    }
    
    

    // }
    .visiter_table_main .top_title_visiter .button-group button{
      margin-left: 10px;
    width: 120px;
    }
  }
  @media only screen and (width: 320px) {
    .visiter_table_main .top_title_visiter{
      
      flex-direction: column !important;
    
    }
    .visiter_table_main .top_title_visiter h1{
      padding:0px !important;
      margin:0px 0px 10px 0px !important;
    }
    .visiter_table_main .button-group{
      display: block !important;
      justify-content:flex-start !important;
     
    }
    
    .visiter_table_main .top_title_visiter .button-group .ant-select {
      width:100% !important;
    }
    .visiter_table_main .top_title_visiter .button-group button{
      margin-top:10px;
    width: 100%;
    }
  }
  @media only screen and (width: 375px) {
    .visiter_table_main .top_title_visiter{
      
      flex-direction: column !important;
    
    }
    .visiter_table_main .top_title_visiter h1{
      padding:0px !important;
      margin:0px 0px 10px 0px !important;
    }
    .visiter_table_main .button-group{
      display: block !important;
      justify-content:flex-start !important;
     
    }
    
    .visiter_table_main .top_title_visiter .button-group .ant-select {
      width:100% !important;
    }
    .visiter_table_main .top_title_visiter .button-group button{
      margin-top:10px;
    width: 100%;
    }
  }
`;

export default WithDirection(VisitorPageWrapper);