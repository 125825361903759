import styled from "styled-components";
import WithDirection from "@iso/lib/helpers/rtl";

const GlobalFormWrapper = styled.div`
  #form-step {
    margin: 60px 0px;
  }
  .form-footer {
    margin-top: 60px;
  }
  .btn-back {
    margin-right: 10px;
  }
  .form-type-switch {
    margin: 20px 0px;
  }
  .advanced-form {
    .ant-form-item-label {
      label {
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
  .form-body {
    .ant-form-item-row {
      margin-bottom: 40px;
    }
  }
`;

export default WithDirection(GlobalFormWrapper);