import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import Box from "@iso/components/utility/box";
import IntlMessages from "@iso/components/utility/intlMessages";
import { Button, Menu, Dropdown } from "antd";
import {
    EditOutlined,
    DownOutlined,
    EyeOutlined,
    PlusCircleOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import modalActions from "@iso/redux/modal/actions";
import {
    propertyService,
    primaryObjectService,
    activityService,
} from "@iso/services";
import _ from "lodash";
import { objectColors } from "@iso/constants/objectColors";
import moment from "moment";
import Table from "@iso/components/collapsing-table/table";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import PrimaryObjectModal from "@iso/containers/Pages/PropertyMap/components/PrimaryObject/PrimaryObjectModal";
import ManagePropertyPage from './Partial.styles'
const { openModal } = modalActions;

const PropertyAreaBox = (props) => {
    let history = useHistory();
    const [primaryObjects, setPrimaryObjects] = useState([]);
    const dispatch = useDispatch();
    const activePropertyId = useSelector(
        (state) => state.property.activePropertyId
    );
    const activePropertySubscription = useSelector(
        (state) => state.subscription.activePropertySubscription
    );
    const activeProperty = localStorage.getItem("active_property");
    const activePermission = useSelector(
        (state) => state.permission.activePermission
    );
    const [visibleSelectProperty, setVisiblitySelectProperty] = React.useState(
        false
    );
    const [visibleSummary, setVisibleSummary] = useState(false);
    const [dataObject, setDataObject] = useState({});
    const [visibleHistory, setVisibleHistory] = useState(false);
    const [objectsForExport, setObjectsForExport] = useState([]);
    const [propertyAcreageUnit, setPropertyAcreageUnit] = useState("");

    const updatePropertyDetail = () => {
        if (activePropertyId) {
            propertyService.viewProperty(activePropertyId).then((res) => {
                if (res.code === "0000") {
                    setPrimaryObjects(res.primary_objects);
                }
            });
        }
    };

    const onEdited = (editedObject) => {
        const objectTmp = _.map(primaryObjects, (object) => {
            if (object.id === editedObject.id) {
                return editedObject;
            }
            return object;
        });
        setPrimaryObjects(objectTmp);
    };

    useEffect(() => {
        updatePrimaryObjectData();
    }, [activePropertyId]);

    useEffect(() => {
        if (!activeProperty) {
            setVisiblitySelectProperty(true);
        }
    }, []);

    useEffect(() => {
        propertyService.getProperties().then((res) => {
            if (res.properties.length === 0) {
                setVisiblitySelectProperty(true);
            }
        });
    }, [activePropertyId]);

    const updatePrimaryObjectData = () => {
        if (activePropertyId) {
            primaryObjectService.getList(activePropertyId).then((res) => {
                if (res.code === "0000") {
                    setPrimaryObjects(res.objects);
                    setObjectsForExport(res.objects_for_export);
                    setPropertyAcreageUnit(res.property.acreage_unit);
                }
            });
        }
    };

    const showEditAreaModal = (primary) => {
        const modalData = {
            object: primary,
            type: "area",
            hideEditArea: true,
        };
        dispatch(openModal(modalData));
    };

    const menu = (primary) => (
        <Menu onClick={handleMenuClick.bind(this, primary)}>
            <Menu.Item key="editDetails" icon={<EditOutlined />}>
                <IntlMessages id="propertyPage.managePage.editDetails" />
            </Menu.Item>
            {(activePermission == "owner" ||
                activePermission == "admin" ||
                activePermission == "modify") && (
                <Menu.Item key="addHistory" icon={<PlusCircleOutlined />}>
                    <IntlMessages id="propertyPage.mobModal.historyTab.addHistory" />
                </Menu.Item>
            )}
            <Menu.Item key="viewSummary" icon={<EyeOutlined />}>
                <IntlMessages id="propertyPage.modal.viewSummary" />
            </Menu.Item>
        </Menu>
    );

    const handleMenuClick = (primary, e) => {
        if (e.key === "editDetails") {
            showEditAreaModal(primary);
        }

        if (e.key === "addHistory") {
            setDataObject(primary);
            setVisibleHistory(true);
        }

        if (e.key === "viewSummary") {
            setDataObject(primary);
            showSummaryModal();
        }
    };

    const columns = [
        {
            label: <IntlMessages id="propertyAreaPage.table.name" />,
            accessor: "name",
            minWidth: 100,
            sortable: true,
            position: 1,
            priorityLevel: 1,
        },
        {
            label: <IntlMessages id="propertyAreaPage.table.type" />,
            accessor: "type",
            minWidth: 100,
            sortable: true,
            position: 2,
            priorityLevel: 2,
        },
        {
            label: <IntlMessages id="propertyAreaPage.table.cropType" />,
            accessor: "crop_label",
            minWidth: 100,
            sortable: true,
            position: 3,
            priorityLevel: 3,
        },
        {
            label: <IntlMessages id="propertyAreaPage.table.areaColour" />,
            accessor: "color_label",
            minWidth: 100,
            sortable: true,
            position: 4,
            priorityLevel: 4,
            CustomComponent: (cell) => {
                let primary = cell.row;
                return objectColors.map((value, index) => {
                    if (value.value == primary.color) {
                        return (
                            <div style={{ display: "inline-flex" }}>
                                <ColorItemPreview
                                    style={{ backgroundColor: value.color }}
                                ></ColorItemPreview>
                                &nbsp;&nbsp;
                                <div>{value.label}</div>
                            </div>
                        );
                    }
                });
            },
        },
        {
            label: <IntlMessages id="propertyAreaPage.table.acerage" />,
            accessor: "acreage",
            minWidth: 200,
            sortable: true,
            position: 5,
            priorityLevel: 5,
        },
        {
            label: <IntlMessages id="propertyAreaPage.table.creationDate" />,
            accessor: "created_at",
            minWidth: 200,
            sortable: true,
            position: 6,
            priorityLevel: 6,
            CustomComponent: (cell) => {
                let primary = cell.row;
                return moment(primary.created_at).format("DD/MM/YYYY");
            },
        },
        {
            label: <IntlMessages id="propertyAreaPage.table.lastUpdated" />,
            accessor: "updated_at",
            minWidth: 200,
            sortable: true,
            position: 6,
            priorityLevel: 6,
            CustomComponent: (cell) => {
                let primary = cell.row;
                return moment(primary.updated_at).format("DD/MM/YYYY");
            },
        },
        {
            label: "",
            accessor: "view_history",
            minWidth: 100,
            sortable: true,
            position: 12,
            priorityLevel: 12,
            noTitle: true,
            CustomComponent: (cell) => {
                let primary = cell.row;
                return (
                    <Dropdown overlay={menu(primary)} trigger={["click"]}>
                        <Button className="btn-success ant-btn-primary">
                            <IntlMessages id="antTable.title.actions" />{" "}
                            <DownOutlined />
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    const showSummaryModal = () => {
        setVisibleSummary(true);
    };

    return (
        <ManagePropertyPage>
        <Fragment>
             
            <Box id="containerBox">
            <div className="manage-property-header">
            <div className="mp-title">
              <h2 className="manage-property-title">Property Area</h2></div>
            </div>
               
                {props.search_result &&
                props.search_result.PrimaryObject &&
                props.search_result.PrimaryObject.length > 0 ? (
                    <div className="manage-table">
                    <Table
                        columns={columns}
                        rows={props.search_result.PrimaryObject}
                        containerId="containerBox"
                        showPagination={false}
                        rowSize={99999}
                    />
                    </div>
                ) : (
                    <h1 style={{ padding: "10px 10px 20px", fontSize: '18px' }}>
                    <IntlMessages id="dashboardPage.noData" />
                  </h1>
                )}
            </Box>
            <PrimaryObjectModal
                propertyId={activePropertyId}
                updatePropertyDetail={updatePropertyDetail}
                onEdited={onEdited}
                primaryObjects={primaryObjects}
                edit={true}
                propertyAcreageUnit={propertyAcreageUnit}
            />
        </Fragment>
        </ManagePropertyPage>
    );
};

export default PropertyAreaBox;

const ColorItemRow = styled.div`
    display: flex;
    align-items: center;
    line-height: 30px;
    div {
        margin: 4px;
    }
`;

const ColorItemPreview = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 50%;
`;
