import React, { useEffect } from "react";
import HazardsPage from "./Hazards.style";
import AddHazardsPage from "./AddHazardModal.styles";
import LayoutWrapper from "@iso/components/utility/layoutWrapper.js";
import _, { property } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { propertyService, permissionService } from "@iso/services";
// import { useHistory, useLocation } from "react-router-dom";
import propertyActions from "@iso/redux/property/actions";
import Table from "@iso/components/collapsing-table/table";
import {
  Button,
  Dropdown,
  Menu,
  Popover,
  Popconfirm,
  Pagination,
  Select,Input,
  message,
} from "antd";
import Box from "@iso/components/utility/box";
import { healthsafety, assetService } from "@iso/services";
import IntlMessages from "@iso/components/utility/intlMessages";
import {
  SearchOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
  EditOutlined,
  EyeOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import ModalActivity from "../HealthSafety/ModalActivity";
import AddHazardModalStyles from "./AddHazardModal.styles";
import ModalWrapper from "../../../components/ModalWrapper";
import HazardViewSummary from "./HazardViewSummary";
import HazardsModal from "./HazardsModal";
import "../../../components/Paginations.css"
import Loader from "../../../components/collapsing-table/components/Loader";
const { changeActiveProperty } = propertyActions;
const Hazards = (props) => {
  const activePropertyId = useSelector(
    (state) => state.property.activePropertyId
  );
  const activePropertySubscription = useSelector(
    (state) => state.property.activePropertySubscription
  );
  const activePermission = useSelector(
    (state) => state.permission.activePermission
  );

  const [properties, setProperties] = React.useState([]);
  const [isSaved, setIsSaved] = React.useState(false);
  const [hazards, setHazards] = React.useState([]);
  const [editHazard, setEditHazard] = React.useState({});
  const [activityActive, setActivityActive] = React.useState("hazard");
  const [viewSummary, setViewSummary] = React.useState(false);
  const [visibleModalActivity, setVisibleModalActivity] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [editId, setEditId] = React.useState("NEW HAZARD");
  const [loadingData, setLoadingData] = React.useState(true);
  const [searchQuery, setSearchQuery] = React.useState();
  const [searchInput, setsearchInput] = React.useState('');
  const [visibleSelectProperty, setVisiblitySelectProperty] =
    React.useState(false);
  const activeProperty = JSON.parse(localStorage.getItem("active_property"));
  const { Option } = Select;
  const dispatch = useDispatch();
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (current, size) => {
    setCurrentPage(1); 
    setPageSize(size);
  };
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const displayedHazards = searchQuery?.slice(startIndex, endIndex);

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setsearchInput(e.target.value);
    
    // Filter the employee data based on the search query
    const filteredEmployee =hazards.filter((hazards) => {
      return (
        hazards.hazard_type && hazards.hazard_type.toLowerCase().includes(searchInput.toLowerCase()) ||
        hazards.comments && hazards.comments.toLowerCase().includes(searchInput.toLowerCase()) ||
        hazards.status && hazards.status.toLowerCase().includes(searchInput.toLowerCase()) 
      );
    });
    setSearchQuery(filteredEmployee);
  };

  const handleCancelSearch = () => {
    // Clear the search input and reset the employee data
    setsearchInput('');
    setSearchQuery(hazards);
    // getEmployee();
  };
  useEffect(() => {
    if (activePropertyId) {
      healthsafety.getActivityPolicyByProperty(activePropertyId).then((res) => {
        if (res.code === "0000") {
          const hazardData = res.activities.filter(
            (item) => item.type === "hazard"
          );
          const tasksArr = [];
            
          hazardData.forEach((e) => {
            return tasksArr.push({
              id: e.id,
              assetName: e.assetName,
              date_time: e.metadata.date_time,
              asset_id: e.metadata.asset_id,
              actions: e.metadata.actions,
              hazard_type: e.metadata.hazard_type,
              comments: e.metadata.comments,
              point: e.metadata.point,
            });
          });
          setHazards(tasksArr);
          setSearchQuery(tasksArr)
          setLoadingData(false);
        } else {
          setLoadingData(false);
          message.error(res.message);
        }
      });
    }
  }, [activePropertyId, isSaved, activePropertySubscription]);
  useEffect(() => {
    if (props?.handleHazards) {
      props?.handleHazards(hazards.length);
    }
  }, [hazards]);

  const getProperties = () => {
  
    if (activePropertyId) {
      setLoadingData(true)
      healthsafety.getActivityPolicyByProperty(activePropertyId).then((res) => {
        if (res.code === "0000") {
          const tasksArr = [];
          const hazardData = res.activities.filter(
            (item) => item.type === "hazard"
          );
          hazardData.forEach((e) => {
            return tasksArr.push({
              id: e.id,
              assetName: e.assetName,
              date_time: e.metadata.date_time,
              asset_id: e.metadata.asset_id,
              actions: e.metadata.actions,
              hazard_type: e.metadata.hazard_type,
              comments: e.metadata.comments,
              point: e.metadata.point,
              status:e.metadata.status
            });
          });
        
          setHazards(tasksArr);
          setSearchQuery(tasksArr)
          setEditHazard({});
          setEditId("NEW HAZARD");
          setLoadingData(false);
        } else {
          setLoadingData(false);
          message.error(res.message);
        }
      });
    }
  };
  useEffect(() => {
    getProperties();
  }, [activePropertyId]);

  const menuList = (property, permission) => (
    <Menu onClick={handleMenuClick.bind(this, property)}>
      <Menu.Item key="editDetails" icon={<EditOutlined />}>
        <IntlMessages id="propertyPage.managePage.editDetails" />
      </Menu.Item>

      <Menu.Item key="viewSummary" icon={<EyeOutlined />}>
        <IntlMessages id="view Summary" />
      </Menu.Item>

      <p style={{ cursor: "pointer" }} className="deleteButton">
        <Popconfirm
          placement="bottomRight"
          title="You are about to delete individual record"
          onConfirm={() => {
            confirmDelete(property);
          }}
          okText="Yes"
          cancelText="No"
          className="delete-button"
        >
          <a style={{ color: "rgba(0, 0, 0, 0.65)", marginLeft: "11px" }}>
            {<DeleteOutlined />}&nbsp;&nbsp;
            <IntlMessages id="propertyPage.managePage.delete" />
          </a>
        </Popconfirm>
      </p>
    </Menu>
  );

  const confirmDelete = (property) => {
    if (property.id) {
      healthsafety.destroyActivity(property.id).then((res) => {
        if (res.code === "0000") {
          getProperties();
        }
      });
    }
  };
  const handleMenuClick = (property, e) => {
    if (e.key === "editStatus") {
      localStorage.setItem("active_property", JSON.stringify(property));
      dispatch(changeActiveProperty(property));
      propertyService.active(property.id).then((res) => {
        if (res.code == "0000") {
        }
      });
    }
    if (e.key === "viewSummary") {
      setViewSummary(true);
      setEditHazard(property);
    }

    if (e.key === "editDetails") {
      // setVisibleModalActivity(true);
      setEditHazard(property);
      setEditId("EDIT HAZARD");
      setActivityActive("hazard");
    }
  };
  const columns = [
    {
      label: <IntlMessages id="Type" />,
      accessor: "hazard_type", // Update this line
      minWidth: 100,
      sortable: true,
      position: 1,
      priorityLevel: 1,
    },
    {
      label: <IntlMessages id="Comments" />,
      accessor: "comments",
      minWidth: 100,
      sortable: true,
      position: 2,
      priorityLevel: 2,
    },
    {
      label: <IntlMessages id="Status" />,
      accessor: "status",
      minWidth: 100,
      sortable: true,
      position: 2,
      priorityLevel: 2,
    },

    {
      label: "",
      accessor: "dot-menu",
      minWidth: 100,
      sortable: true,
      position: 4,
      priorityLevel: 4,
      noTitle: true,
      CustomComponent: (cell) => {
        let row = cell.row;
        let permission = permissionService.getPermission(row.permission_id);
        return (
          <Dropdown overlay={menuList(row, permission)} trigger={["click"]}>
            <div
              className="property-area-dot-btn"
              style={{ cursor: "pointer" }}
            >
              <EllipsisOutlined className="dotIcon"/>
            </div>
          </Dropdown>
        );
      },
    },
  ];

  useEffect(() => {
    propertyService.getProperties().then((res) => {
      if (res.properties.length === 0) {
        if (!visibleSelectProperty) {
          setVisiblitySelectProperty(true);
        }
      } else {
        setProperties(res.properties);
        if (res.properties.length === 1) {
          localStorage.setItem(
            "active_property",
            JSON.stringify(res.properties[0])
          );
          dispatch(changeActiveProperty(res.properties[0]));
        }
      }
    });
  }, [activePropertyId, isSaved, activePropertySubscription]);

  useEffect(() => {
    if (!activeProperty && !visibleSelectProperty) {
      setVisiblitySelectProperty(true);
    }
  }, []);
  const handleOptionClick = (value) => {
    _.forEach(properties, (property) => {
      if (property.id == value) {
        localStorage.setItem("active_property", JSON.stringify(property));
        dispatch(changeActiveProperty(property));
      }
    });
  };
  const showModal = () => {
    setVisibleModalActivity(true);
    setActivityActive("hazard");
    // setVisiblity(true);
  };
  const cancelModalActivity = () => {
    setVisibleModalActivity(false);
    setEditHazard({});
    setEditId("NEW HAZARD");
  };

  const cancelModalViewSummary = () => {
    setViewSummary(false);
    setEditHazard({});
  };
  const renderOptionsProperty = () => {
    let options = [];
    _.forEach(properties, (property, index) => {
      options.push(
        <Option key={index} value={property.id} className="property-option">
          {property.name}
        </Option>
      );
    });

    return (
      <Select
        placeholder="Select Active Property"
        value={activeProperty?.name}
        size="large"
        style={{ width: 150 }}
        onChange={handleOptionClick}
      >
        {options}
      </Select>
    );
  };

  return (
    <LayoutWrapper>
      <AddHazardsPage>
        <HazardsModal
          getProperties={getProperties}
          setEditHazard={setEditHazard}
          editId={editId}
          cancelModalActivity={cancelModalActivity}
          visibleModalActivity={visibleModalActivity}
          propertyId={activePropertyId}
          activityActive={activityActive}
          editHazard={editHazard}
        />
      </AddHazardsPage>
      <HazardsPage>
        <Box id="containerBox">
          <div className="manage-property-header">
            <div className="mp-title">
              <h2 className="manage-property-title">Hazards</h2>
            </div>
            <div className="button-group" style={{ gap: "12px" }}>
            <Input
                placeholder="Search…"
                suffix={!searchInput && <SearchOutlined className="site-form-item-icon" />}
                prefix={searchInput && <CloseCircleOutlined onClick={handleCancelSearch} className="site-form-item-cancel" />}
                size="large"
                className="search-bar"
                value={searchInput}
                onChange={(e) => handleSearch(e)}
               
              />
              {renderOptionsProperty()}
              {/* <Button
                icon={<PlusOutlined />}
                type="primary"
                className=""
                // onClick={showModal}
                size="large"
                style={{
                  background: "rgb(248, 14, 70)",
                  borderRadius: "12px !important",
                }}
              >
                <IntlMessages id="Add Asset" />
              </Button> */}
            
            </div>
          </div>

          {properties.length > 0 ? (
            <>
            
            <div className="manage-table">
              {loadingData ?( <Loader/> ):
             (<> <Table
                columns={columns}
                rows={displayedHazards}
                // pagination={true}
                rowKey="id"
                containerId="containerBox"
                rowSize={99999}
              />
          
              <div style={{margin:'20px'}}>
              <Pagination
                current={currentPage}
                total={searchQuery?.length}
                pageSize={pageSize}
                onChange={handlePageChange}
                showSizeChanger
                pageSizeOptions={["10", "20", "50", "100"]}
                onShowSizeChange={handlePageSizeChange}
              /></div></>)}
            </div>
            </>
          ) : (
            <h1 style={{ padding: "10px 10px 20px", fontSize: "18px" }}>
              <IntlMessages id="dashboardPage.noData" />
            </h1>
          )}
        </Box>

        <HazardViewSummary
          viewSummary={viewSummary}
          editHazard={editHazard}
          cancelModalViewSummary={cancelModalViewSummary}
        />
      </HazardsPage>
    </LayoutWrapper>
  );
};

export default Hazards;
