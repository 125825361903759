// Note: If you update Stripe Plan, please update the backend code as well.
// Sorry for the inconvenience, we will optimize this in the future if possible.

export const subscriptionPlan = [
  {
    label: 'Bronze - Free',
    value: process.env.REACT_APP_STRIPE_PLAN_BRONZE,
  },
  {
    label: 'Silver - $4.95 pm',
    value: process.env.REACT_APP_STRIPE_PLAN_SILVER,
  },
  {
    label: 'Gold - $39.95 pm',
    value: process.env.REACT_APP_STRIPE_PLAN_GOLD,
  }
]
