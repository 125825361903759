import React, { useEffect, useState } from "react";
import VerifyElapsedWrapper from "./VerifyElapsedPage.styles";
import { sarService } from "@iso/services";

const VerifyElapsedPage = () => {
  const [successMsg, setSuccessMsg] = useState("Loading...");
  const url = window.location.search.split("&");
  const token = url?.[0].split("=").at(-1);
  const user = url?.at(-1).split("=").at(-1);

  useEffect(() => {
    if (user !== token) {
      sarService.terminateSARAlarm({ user, token }).then((res) => {
        setSuccessMsg(res.message);
      });
    } else {
      sarService.complateSAR(token).then((res) => {
        setSuccessMsg(res.message);
      });
    }
  }, []);

  return (
    <VerifyElapsedWrapper>
      <div className="SAR-contain">
        <p>{successMsg}</p>
      </div>
    </VerifyElapsedWrapper>
  );
};

export default VerifyElapsedPage;
