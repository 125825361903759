import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const UserWeatherChartWrapper = styled.div`
 	
	  @media only screen and (min-width: 768px) {
    	height: 550px;
  	}
  	@media only screen and (max-width: 767px) {
    	height: 320px;
 	}
	 @media only screen and (max-width: 425px) {
					
		height : 50vh !important;
		width: 100% !important;
	}
	}
	.ctm-userweatherchart{
		#weather_tab_data{
			@media only screen and (max-width: 767px) {
				overflow-x:auto;
			}
			@media only screen and (max-width: 768px) {
				height:100vh !important;
					width: 100% !important;
			}
			span{
				@media only screen and (max-width: 767px) {
					
					height:100vh !important;
				}
				@media only screen and (max-width: 768px) {
					
					height:100vh !important;
				}
				@media only screen and (max-width: 1440px) {
					
					height:100vh !important;
				}
				@media only screen and (max-width: 768px) {
					
					height:100vh !important;
				}
				@media only screen and (max-width: 1024px) {
					
					height:100vh !important;
				}
				@media only screen and (max-width: 425px) {
					
					height:100vh !important;
				}
				@media only screen and (max-width: 320px) {
					
					height:100vh !important;
				}
				
			}
		}
	}
`;

export default WithDirection(UserWeatherChartWrapper);