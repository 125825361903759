import React, { useState, useEffect } from 'react';
import WeatherChartWrapper from './WeatherChart.styles';
import _ from 'lodash';
import ReactFC from "react-froalacharts";
import FroalaCharts from "froalacharts";
import FroalaTheme from "froalacharts/themes/froalacharts.theme.froala";
import moment from 'moment';
import { Button,Select } from 'antd';

ReactFC.fcRoot(FroalaCharts, FroalaTheme);

const WeatherChart = (props) => {
  const {chartid, data,weatherApiType,setWeatherApiType,dateChart,setDateChart,typeChartR,nameChartR,daysWeather,widthClass,hoursWeather,nextDay } = props;
  var dataStore = new FroalaCharts.DataStore();
  const [labelChart, setLabelChart] = useState([]);
  const [dataRain, setDataRain] = useState([]);
  const [dataUVI, setDataUVI] = useState([]);
  const [dataWindSpeed, setDataWindSpeed] = useState([]);
  const [dateAllChart,setDateAllChart] = useState([]);
  const [dataDayOpenWeather, setDataDayOpenWeather] = useState();
  const [dataDayJanesWeather, setDataDayJanesWeather] = useState();
  const [dataChart, setDataChart] = useState([]);
  const dataChartR = [],
        dataTemp = [],
        dataDeltaT = [],
        dataDewPoint = [];

  const getDataJanesWeather = (data) => {
    _.forEach(data, function(value,i) {
    
      if(value.apparentTemperature){
        let date = moment(value.valid,"YYYY-MM-DD");
        if(nextDay > 0 ){
          let next = moment().add(nextDay,'days').format('YYYY-MM-DD');
          if(date.format("YYYY-MM-DD") == next){
            setLabelChart(oldArray => [...oldArray,{'label': moment(value.valid).utc().format("DD/MM/YYYY HH:mm")}] );
            dataTemp.push({'value':parseFloat(value.apparentTemperature).toFixed(0)});
            dataDeltaT.push({'value': value.deltaT ? parseFloat(value.deltaT).toFixed(0) : 0 });
            dataDewPoint.push({'value': value.dewPoint ? parseFloat(value.dewPoint).toFixed(0) : 0 });
            if(typeChartR === 'wind_speed'){
              dataChartR.push({'value': value.windSpeed ?  parseFloat(value.windSpeed).toFixed(0) : 0 });
            }else{
              dataChartR.push({'value': value.rainfall ?  parseFloat(value.rainfall).toFixed(1) : 0 });
            }
          }
        }else{
         
          if( date <= daysWeather && ( i % hoursWeather == 0 )){
            setLabelChart(oldArray => [...oldArray,{'label': moment(value.valid).utc().format("DD/MM/YYYY HH:mm")}] );
            dataTemp.push({'value':parseFloat(value.apparentTemperature).toFixed(0)});
            dataDeltaT.push({'value': value.deltaT ? parseFloat(value.deltaT).toFixed(0) : 0 });
            dataDewPoint.push({'value': value.dewPoint ? parseFloat(value.dewPoint).toFixed(0) : 0 });
            if(typeChartR === 'wind_speed'){
              dataChartR.push({'value': value.windSpeed ?  parseFloat(value.windSpeed).toFixed(0) : 0 });
            }else{
              dataChartR.push({'value': value.rainfall ?  parseFloat(value.rainfall).toFixed(1) : 0 });
            }
          }
        }
      
      }
    });
    setDataChart([
        {
          "seriesname": "Temperature",
          "renderas": "line",
          "plottooltext": "Temperature in $label : <b>$dataValue</b>",
          "data": dataTemp

        },
        {
          "seriesname": "Dew Point",
          "showvalues": "0",
          "plottooltext": "Dew Point in $label : <b>$dataValue</b>",
          "data": dataDewPoint

        },
        
        {
          "seriesname": nameChartR,
          "parentyaxis": "S",
          "renderas": "area",
          "showvalues": "0",
          "plottooltext": nameChartR+" in $label: <b>$value</b>",
          "data": dataChartR
        },
        {
          "seriesname": "DeltaT",
          "showvalues": "0",
          "renderas": "line",
          "plottooltext": "DeltaT in $label : <b>$dataValue</b>",
          "data": dataDeltaT

        }
    ]);
  }

  const getDataHourlyOpenWeather = (data) => {
      
     _.forEach(data, function(value, i) {
      let date = moment.unix(value.dt).format("DD-MM-YYYY");
      date = moment(date,"DD-MM-YYYY");
      if(nextDay > 0 ){
        let next = moment().add(nextDay, 'days').format("YYYY-MM-DD");
        if(date.format("YYYY-MM-DD") == next){
          setLabelChart(oldArray => [...oldArray,{'label':moment.unix(value.dt).format("DD/MM/YYYY HH:mm")}] );
          dataTemp.push({'value':value.temp ? parseFloat((value.temp)  - 273.15).toFixed(0) : 0});
          dataDewPoint.push({'value':value.dew_point ? parseFloat((value.dew_point)  - 273.15).toFixed(0) : 0});
          if(typeChartR === 'wind_speed'){
            dataChartR.push({'value': value.wind_speed ? parseFloat(value.wind_speed).toFixed(0) : 0 });
          }else if(typeChartR === 'uvi'){
            dataChartR.push({'value': value.uvi ? value.uvi : 0 });
          }else{
            dataChartR.push({'value': value.rain ? value.rain : 0 });
          }
        }
      }else{
        if(date <= daysWeather && ( i % hoursWeather == 0 )){
          setLabelChart(oldArray => [...oldArray,{'label':moment.unix(value.dt).format("DD/MM/YYYY HH:mm")}] );
          dataTemp.push({'value':value.temp ? parseFloat((value.temp)  - 273.15).toFixed(0) : 0});
          dataDewPoint.push({'value':value.dew_point ? parseFloat((value.dew_point)  - 273.15).toFixed(0) : 0});
          if(typeChartR === 'wind_speed'){
            dataChartR.push({'value': value.wind_speed ? parseFloat(value.wind_speed).toFixed(0) : 0 });
          }else if(typeChartR === 'uvi'){
            dataChartR.push({'value': value.uvi ? value.uvi : 0 });
          }else{
            dataChartR.push({'value': value.rain ? value.rain : 0 });
          }
        }
      }
    });

    setDataChart([
      {
        "seriesname": "Temperature",
        "renderas": "line",
        "plottooltext": "Temperature in $label : <b>$dataValue</b>",
        "data": dataTemp
      },
      {
        "seriesname": "Dew Point",
        "showvalues": "0",
        "plottooltext": "Dew Point in $label : <b>$dataValue</b>",
        "data": dataDewPoint

      },
      {
        "seriesname": nameChartR,
        "parentyaxis": "S",
        "renderas": "area",
        "showvalues": "0",
        "plottooltext": nameChartR+" in $label: <b>$value</b>",
        "data": dataChartR
      } 
    ]);
    
  }

  useEffect(() => {
    setLabelChart([]);
    setDataChart([]);
    if(data.weather_data){
      if(weatherApiType === "open_weather"){
        getDataHourlyOpenWeather(data.weather_data.hourly);
      }else{
        getDataJanesWeather(data.weather_data.values);
      }
    }
  }, [data,dateChart,weatherApiType,typeChartR,daysWeather,hoursWeather,nextDay]);
  
  const dataSource = {
    "chart": {
      "caption": "",
      "drawcrossline": "1",
      "yaxisname": "Temperature °C",
      "syaxisname": nameChartR,
      "showvalues": "1",
      "showanchors": "1",
      "plothighlighteffect": "fadeout",
      "theme": "froala",
      "paletteColors" : "#ffc107, #F48484, #007bff, #AA5656"
    },
    "categories": [
      {
        "category": labelChart
      }
    ],
    "dataset": dataChart
  };

  const chartConfig = {
    id: "chart_weather",
    type: 'combination',
    renderAt: 'ft',
    width: '100%',
    height: '75%',
    dataSource: dataSource
  };
    
  return (
    <WeatherChartWrapper>
      <div className="weather-chart-wrap">
        <div id={chartid} className={widthClass}>
           <ReactFC {...chartConfig} />
        </div>
      </div>
    </WeatherChartWrapper>
  )
}

export default WeatherChart;