import React, { useState, useEffect, useRef } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import AssetModalWrapper from './AssetModal.styles';
import { Form, Input, Button, Select, Row, Col, DatePicker, Tabs, Card, Timeline, Popconfirm, InputNumber,Upload,Space,Modal } from 'antd';
import { CloseOutlined,UploadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import modalActions from '@iso/redux/modal/actions';
import Loader from '@iso/components/utility/loader';
import { assetService, activityService } from '@iso/services';
import moment from 'moment';
import _ from 'lodash';
import styled from "styled-components";
import { dateHelper } from '@iso/lib/helpers/dateHelper';
import L from 'leaflet';
import inside from 'point-in-polygon';
import { assetCategories } from '@iso/constants/assetCategories';
import { inventoryTypes } from '@iso/constants/inventoryTypes';
import { assetStates } from '@iso/constants/assetStates';
import { waterStorageTypes } from '@iso/constants/waterStorageTypes';
import AddAssetHistoryModal from './AddAssetHistoryModal';
import ListAcivity from './ListAcivity';
import AddActivityModal from './AddActivityModal';
import AssetHistory from './AssetHistory';
import { Map, TileLayer, FeatureGroup, Polygon, Tooltip, ZoomControl,Marker,Popup } from 'react-leaflet';
import { propertyService,healthsafety,attachment } from '@iso/services';
import { levelTypes } from '@iso/constants/levelTypes';
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';
import { healthSafetyTypes } from '@iso/constants/healthSafetyTypes';
import { activityPolicy } from '@iso/constants/activityPolicy';

import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'

const { Option } = Select;
const { closeModal } = modalActions;
const { TabPane } = Tabs;

const EditGeneralAssetModal = (props) => {
  const [form] = Form.useForm();
  const [addHistoryForm] = Form.useForm();
  const [fields, setFields] = useState([]);
  const dispatch = useDispatch();
  const modalVisibility = useSelector((state) => state.modal.modalVisibility);
  const modalData = useSelector((state) => state.modal.modalData);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [assetData, setAssetData] = useState({});
  const activePermission = useSelector((state) => state.permission.activePermission);
  const [visibleHistory, setVisibleHistory] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false); 
  const [visibleActivity, setVisibleActivity] = useState(false);
  const [assetHistory, setAssetHistory] = useState([]);
  const [currentTab, setCurrentTab] = useState('1');
  const [valueLat, setValueLat] = useState();
  const [valueLng, setValueLng] = useState(); 
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const dateFormat = 'DD/MM/YYYY';
  const [attachmentId, setAttachmentId] = useState([]);
  const [attachmentUid, setAttachmentUid] = useState(null);
  const [attachmentUrl, setAttachmentUrl] = useState(null);
  const [attachmentFileList, setAttachmentFileList] = useState([]);
  const [position, setPosition] = useState([null, null]);
  const [dataActivity, setDataActivity] = useState([]);
  const [tags, setTags] = useState([]);

  const updateDataAssetHistory = React.useCallback((asset_id, modal_type) => {
      activityService.getList(asset_id, modal_type).then(res => {
        if(res.code === '0000'){
          setAssetHistory(res.activities.reverse());
          //setLoading(false);
        }
      });
  }, [assetHistory]); 


  const updatePropertyDetail = React.useCallback((id) => {
    propertyService.viewProperty(id).then(res => {
      if(res.code === '0000'){
        setPosition([res.property.lat, res.property.lng]);
      }
    })
  }, []);

  const getDataActivity = (id) =>{
    healthsafety.getDataActivity(id).then(res => {
        if(res.code === '0000'){
          setDataActivity(res.activity_policy);
        }
      });
  }
  useEffect(() => {
    if(activePropertyId){
      updatePropertyDetail(activePropertyId);
    }
  },[activePropertyId]);

  const mapRef = useRef();
  const handleChangeTag = (input_tags) => {
    setTags(input_tags);
  }
  useEffect(() => {
    setAssetData(props.dataAsset);
    setValueLat(props.dataAsset.lat);
    setValueLng(props.dataAsset.lng);
    getDataActivity(props.dataAsset.id);

    var arrAtt = [];
    if(props.dataAsset.attachment?.length > 0){
      _.forEach(props.dataAsset.attachment, (attachment, index) => {
        arrAtt.push(
          { 
            uid : attachment.id,
            name: attachment.url,
            status: 'done',
            url: process.env.REACT_APP_API_BASE_URL+attachment.url,
          }
        )
      });
      setAttachmentFileList(arrAtt);
    }



    form.setFieldsValue({
      latitude: props.dataAsset.lat,
      longitude: props.dataAsset.lng,
    });
  }, [props.dataAsset]);

  const uploadProps = {
    name: "Attachment[file]",
    action: apiEndpoint.attachments,
    headers: authHeader(true),
    listType: "picture",
    defaultFileList: [...attachmentFileList],
    multiple: true,
    onChange: (response) => {
      setAttachmentFileList(response.fileList);
      if(response.file && response.file.status == "done"){
        setAttachmentId(attachmentId => [...attachmentId, response.file.response.attachment.id]);

      }
    },
    onRemove: file => {
      attachment.destroy(file.uid);
    }
  };
 
  const onChange = (key) => {
    // console.log('tab checked', key);
    setCurrentTab(key);
  };  
  const handleClickMap = (e) => {
     const { lat, lng } = e.latlng;
     setValueLng(lng);
     setValueLat(lat);
     form.setFieldsValue({
        latitude: lat,
        longitude: lng,
    });
   
  };
  const getLatLng = (cLat, cLng) => {
    const lat = cLat;
    const lng = cLng;
    return new L.LatLng(
          lat,
          lng
    );
  }
  const getRandomLatLng = (coordinates) => {
    var polygon = L.polygon([
      coordinates
    ]);
    var bounds = polygon.getBounds();
    var x_max = bounds.getEast();
    var x_min = bounds.getWest();
    var y_max = bounds.getSouth();
    var y_min = bounds.getNorth();
    var lat=0,lng = 0;
    var count = 0;
    var area = polygon.getLatLngs()[0].map(function(point){
      return [point.lat, point.lng]            
    });

    while(!inside([lat, lng], area)){
      lat = y_min + (Math.random() * (y_max - y_min));
      lng = x_min + (Math.random() * (x_max - x_min));
      count+=1;
      if(count==100){
        break;
      }      
    }            

    return new L.LatLng(
          lat,
          lng
    );
  }
  
  const cancelModal = () => {
    props.cancelEditModal();
    setActiveTab("1");
    setLoading(false);
    form.resetFields();

  }

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
  }

  const onStore = (values) => {
    const selectedObject = _.find(props.primaryObjects, (object) => {
      return object.id === values.asset.primary_object_id;
    });
    var point = null;
    var pointComment = null;
    
    if(values.asset.category === 'health_and_safety'){
      if(values.asset.type === 'evacuation_point' || values.asset.type === 'fire_extinguisher' || values.asset.type === 'first_aid_kit'){
        if(valueLat !== '' && valueLng !== ''){
          point = getLatLng(valueLat,valueLng);
        }
      }
    }else{
      if(currentTab == 1){
        if(!assetData.id || assetData.primary_object_id != selectedObject.id){
          var point = getRandomLatLng(selectedObject.area);
        }
      }else{
        if(valueLat !== '' && valueLng !== ''){
          point = getLatLng(valueLat,valueLng);
        }
      }
    }
  
    const data = {
      facility: {
        ...values.asset,
        date_of_purchase: values.asset.date_of_purchase ? dateHelper.dateForAPI(values.asset.date_of_purchase) : '',
        manufacture_date: values.asset.manufacture_date ? dateHelper.dateForAPI(values.asset.manufacture_date) : '',
        expiry_date: values.asset.expiry_date ? dateHelper.dateForAPI(values.asset.expiry_date) : '',
        last_checked_date: values.asset.last_checked_date ? dateHelper.dateForAPI(values.asset.last_checked_date) : '',
        creation_date: values.asset.creation_date ? dateHelper.dateForAPI(values.asset.creation_date) : '',
        point,
        tags: tags,
        attachment_id : attachmentId,
        property_id: props.propertyId
      }
    }

    assetService.storeOrUpdate(data, assetData.id).then(res => {
      if(res.code === '0000'){
    
        props.onSaved(res.facility);
        setLoading();
        cancelModal();
        setTags([]);
        form.resetFields();
      } else {
        setLoading(false);
      }
    });
  }


  useEffect(() => {
      
      updateDataAssetHistory(assetData.id, 'facilities');
      if (assetData.tags) {
        setTags(assetData.tags.split('|'));
      }else{
        setTags([]);
      }
      setFields([
        {
          name: ['asset', 'title'],
          value: assetData.title || '',
        },
        {
          name: ['asset', 'category'],
          value: assetData.category,
        },
        {
          name: ['asset', 'other_category'],
          value: assetData.other_category,
        },
        {
          name: ['asset', 'type'],
          value: assetData.type,
        },
        {
          name: ['asset', 'other_inventory_type'],
          value: assetData.other_inventory_type,
        },
        {
          name: ['asset', 'state'],
          value: assetData.state,
        },
        {
          name: ['asset', 'other_state'],
          value: assetData.other_state,
        },
        {
          name: ['asset', 'date_of_purchase'],
          value: assetData.date_of_purchase ? moment(assetData.date_of_purchase) : '',
        },
        {
          name: ['asset', 'manufacture_date'],
          value: assetData.manufacture_date ? moment(assetData.manufacture_date) : '',
        },
        {
          name: ['asset', 'description'],
          value: assetData.description || '',
        },
        {
          name: ['asset', 'expiry_date'],
          value: assetData.expiry_date ? moment(assetData.expiry_date) : '',
        },        
        {
          name: ['asset', 'last_checked_date'],
          value: assetData.last_checked_date ? moment(assetData.last_checked_date):  '',
        },        
        {
          name: ['asset', 'creation_date'],
          value: assetData.creation_date ? moment(assetData.creation_date) :  '',
        },    
        {
          name: ['asset', 'created_by'],
          value: assetData.created_by ? assetData.created_by : '',
        },   
        {
          name: ['asset', 'tag_number'],
          value: assetData.tag_number ?  assetData.tag_number : '',
        },
     
      ]);
    
  }, [props.propertyId, props.visibleAssetOther, assetData]);

  const renderOptionsAssetCategory = () => {
    let options = [];
    _.forEach(assetCategories, (cat, index) => {
      options.push(
        <Option key={index} value={cat.value}>
          <div>{cat.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a category"
        allowClear
        size="large"
        disabled
      >
        {options}
      </Select>
    );
  }
  const renderOptionsLevelTypes = () => {
    let options = [];
    _.forEach(levelTypes, (type, index) => {
      options.push(
        <Option key={index} value={type.value}>
          <div>{type.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a level"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  } 

  const renderOptionsInventoryType = () => {
    let options = [];
    _.forEach(inventoryTypes, (type, index) => {
      options.push(
        <Option key={index} value={type.value}>
          <div>{type.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a inventory type"
        allowClear
        size="large"
        disabled
      >
        {options}
      </Select>
    );
  }

  const renderOptionsAssetState = () => {
    let options = [];
    _.forEach(assetStates, (state, index) => {
      options.push(
        <Option key={index} value={state.value}>
          <ColorItemRow>
            <ColorItemPreview style={{backgroundColor: state.color}}></ColorItemPreview>
            <div>{state.label}</div>
          </ColorItemRow>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a state"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }
  const renderOptionsHealthSafetyTypes = () => {
    let options = [];
    _.forEach(healthSafetyTypes, (type, index) => {
      options.push(
        <Option key={index} value={type.value}>
          <div>{type.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a Health Safety Types"
        allowClear
        size="large"
        disabled
      >
        {options}
      </Select>
    );
  }
    const renderActivityPolicy = () => {
    let options = [];
    _.forEach(activityPolicy , (activity, index) => {
      options.push(
        <Option key={index} value={activity.value}>
          <div>{activity.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a activity"
        allowClear
        disabled
        size="large"
      >
        {options}
      </Select>
    );
  } 
  const renderOptionsWaterStorageTypes = () => {
    let options = [];
    _.forEach(waterStorageTypes, (water_type, index) => {
      options.push(
        <Option key={index} value={water_type.value}>
          <div>{water_type.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a water storage type"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const renderOptionObject = (objects) => {
    let options = [];
    _.forEach(objects, (object, index) => {
      options.push(
        <Option key={object.id} value={object.id}>
          {object.name}
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select an object"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }


  const [activeTab,setActiveTab] = useState("1");
  const onChangeTab = (key) => {
    setActiveTab(key);
  }

  const addHistory = (object, e) => {
    setVisibleHistory(true);
  }  
  const addActivy = (object, e) => {
    setVisibleActivity(true);
  }

  const cancelModalHistory = () => {
    addHistoryForm.resetFields();
    setVisibleHistory(false);
    setLoadingHistory(false);
  } 
  const cancelModalActivity = () => {
    setVisibleActivity(false);
  }

  return(
    <>
    <AssetModalWrapper
      visible={props.visibleAssetOther}
      onCancel={cancelModal}
      maskClosable={true}
      title={props.dataAsset.id ? <IntlMessages id="propertyPage.modal.asset.editAsset"/> : <IntlMessages id="propertyPage.modal.asset.addAsset"/>}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit} loading={loading}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
    {
        modalLoading ?
        <Loader /> : 
        <Tabs defaultActiveKey="1" onChange={onChangeTab} className="form-body"
        activeKey={activeTab}
        >
        <TabPane tab={<IntlMessages id="propertyPage.mobModal.detailTab" />} key="1"> 
          <Form
            form={form}
            layout="vertical"
            scrollToFirstError
            fields={fields}
            id="#1"
          >
          <div>
            <Form.Item
              name={['asset', 'title']}
              className="isoInputWrapper"
              label="Title"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                  whitespace: true,
                },
                {
                  max:255,
                  message: 'Title must be maximum 255 characters.'
                },
              ]}
            >
              <Input size="large" placeholder="Input asset title"/>
            </Form.Item>
            <Form.Item
              name={['asset', 'category']}
              className="isoInputWrapper"
              label="Category"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              {renderOptionsAssetCategory()}
            </Form.Item>
            {assetData.category === 'other' && (<Form.Item
              name={['asset', 'other_category']}
              className="isoInputWrapper"
              label="Other Category"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                  whitespace: true
                },
                {
                  max:255,
                  message: 'Other Category must be maximum 255 characters.'
                },
              ]}
            >
              <Input size="large" placeholder="Input other category"/>
            </Form.Item>)}
            {assetData.category === 'inventory' && (<Form.Item
              name={['asset', 'type']}
              className="isoInputWrapper"
              label="Inventory Type"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              {renderOptionsInventoryType()}
            </Form.Item>)}
            {assetData.category === 'inventory' && (<Form.Item
              name={['asset', 'other_inventory_type']}
              className="isoInputWrapper"
              label="Other Inventory Type"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                  whitespace: true
                },
                {
                  max:255,
                  message: 'Other Inventory Type must be maximum 255 characters.'
                },
              ]}
            >
              <Input size="large" placeholder="Input other inventory type"/>
            </Form.Item>)}
            <Row>
              {assetData.category !== "health_and_safety" &&
              <Col xs={{span: 24}} md={{span: 11}}>
                <Form.Item
                  name={['asset', 'state']}
                  className="isoInputWrapper"
                  label={assetData.category === 'water' ? "Level":"State"}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                {assetData.category !== 'water' ? renderOptionsAssetState() : renderOptionsLevelTypes()}

                </Form.Item>
              </Col>
              }
              { props.dataAsset.category !== "health_and_safety" &&
              <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                <Form.Item
                  name={['asset', 'date_of_purchase']}
                  className="isoInputWrapper"
                  label="Date Of Purchase"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <DatePicker size="large" style={{width: '100%'}} format={dateFormat}/>
                </Form.Item>
              </Col>
            }
            </Row>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return (prevValues['asset'] && (prevValues['asset'].state !== currentValues['asset'].state));
              }}
            >
              {({ getFieldValue }) => {
                  if(getFieldValue('asset')){
                    if (getFieldValue('asset').state === "other"){
                      return (
                          <Form.Item
                          name={['asset', 'other_state']}
                          className="isoInputWrapper"
                          label="Other State"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                            },
                          ]}
                        >
                          <Input size="large" placeholder="Input other state"/>
                        </Form.Item>
                      );
                    }
                  }else{
                    if (assetData.state === "other"){
                      return (
                          <Form.Item
                          name={['asset', 'other_state']}
                          className="isoInputWrapper"
                          label="Other State"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                            },
                          ]}
                        >
                          <Input size="large" placeholder="Input other state"/>
                        </Form.Item>
                      );
                    }
                  }
                }
              }
            </Form.Item>
            <Row>
              { props.dataAsset.category !== "health_and_safety" &&
              <Col xs={{span: 24}} md={{span: 11}}>
                <Form.Item
                  name={['asset', 'manufacture_date']}
                  className="isoInputWrapper"
                  label="Manufacture Date"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <DatePicker size="large" style={{width: '100%'}} format={dateFormat}/>
                </Form.Item>
              </Col>
              }
              {props.dataAsset.category !== "water" && 
              props.dataAsset.category !== "health_and_safety" && 
              <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                <Form.Item
                  name={['asset', 'primary_object_id']}
                  className="isoInputWrapper"
                  label="Location"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  {renderOptionObject(props.primaryObjects)}
                </Form.Item>
              </Col>
            }
            </Row>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                  return (prevValues['asset'] && (prevValues['asset'].state !== currentValues['asset'].state));
              }}
            >
            {({ getFieldValue }) => {
                if(getFieldValue('asset')){
                  if (getFieldValue('asset').category === "water"){
                    return (
                      <div>
                        <Row>
                          <Col xs={{span: 24}} md={{span: 11}}>
                              <Form.Item
                                name={['asset', 'creation_date']}
                                className="isoInputWrapper"
                                label="Creation Date"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                                <DatePicker size="large" style={{width: '100%'}} format={dateFormat}/>
                              </Form.Item>
                            </Col>        
                            <Col xs={{span: 24}} md={{span: 11,offset: 2}}>
                              <Form.Item
                                name={['asset', 'last_checked_date']}
                                className="isoInputWrapper"
                                label="Last Checked Date"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                                <DatePicker size="large" style={{width: '100%'}} format={dateFormat}/>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Tabs defaultActiveKey="1" onChange={onChange}>
                             
                              <TabPane tab="Area" key="1">
                                { currentTab == 1 && <Col xs={{span: 24}} md={{span: 11}}>
                                  <Form.Item
                                   
                                    name={['asset', 'primary_object_id']}
                                    className="isoInputWrapper"
                                    label="Location"
                                    
                                    rules={[
                                      {
                                        required: true,
                                        message: 'This field is required',
                                      },
                                    ]}
                                  >
                                    {renderOptionObject(props.primaryObjects)}
                                  </Form.Item>
                                </Col>}
                              </TabPane>

                              <TabPane tab="Location" key="2">
                                
                                <Map ref={mapRef} center={valueLng !== null ? [valueLat,valueLng] : position} doubleClickZoom={false} onclick={handleClickMap} zoom={16} zoomControl={true} style={{width:'100%', height:'300px'}}>
                                   <TileLayer
                                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    {valueLat  && <Marker position={[valueLat,valueLng]} draggable={true}>
                                        <Popup position={[valueLat,valueLng]}>
                                        </Popup>
                                    </Marker>}
                                </Map>
                                    <Row style={{marginTop:"10px", marginBottom:"5px"}}>
                                    <Col xs={{span: 24}} md={{span: 11}} >
                                             
                                        <Input value={valueLat}/>
                                    </Col>
                                    <Col xs={{span: 24}} md={{span: 11, offset: 2}} >
                                        <Input value={valueLng}/>
                                    </Col>
                                </Row>
                              </TabPane>
                              
                         </Tabs>
                      </div>
                    )

                  }
                }
              }
            }
            </Form.Item>
            <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => {
                     return (prevValues['asset'] && (prevValues['asset'].state !== currentValues['asset'].state));
                  }}
                >
                  {({ getFieldValue }) => {
                      if(getFieldValue('asset')){
                        if (getFieldValue('asset').category === "health_and_safety"){
                          return (
                            <div>
                                <Row>
                                  <Col xs={{span: 24}} md={{span: 24}}>
                                    <Form.Item
                                      name={['asset', 'type']}
                                      className="isoInputWrapper"
                                      label={<IntlMessages id="propertyPage.modal.asset.healthSafetyTypes" />}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'This field is required',
                                        },
                                      ]}
                                    >
                                      {renderOptionsHealthSafetyTypes()}
                                    </Form.Item>
                                  </Col>
                                  
                                </Row>

                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) => {
                                       return (prevValues['asset'] && (prevValues['asset'].type !== currentValues['asset'].type));
                                    }}
                                  >
                                  {({ getFieldValue }) => {
                                    if(getFieldValue('asset') && getFieldValue('asset').type){
                                    
                                        return (
                                          <div>
                                          
                                            <Row>
                                            {getFieldValue('asset').type !== "fire_extinguisher" &&
                                            <Col xs={{span: 24}} md={{span: 11}}>
                                              <Form.Item
                                                name={['asset', 'last_checked_date']}
                                                className="isoInputWrapper"
                                                label="Last Checked Date"
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'This field is required',
                                                  },
                                                ]}
                                              >
                                                <DatePicker size="large" style={{width: '100%'}} format={dateFormat}/>
                                              </Form.Item>
                                            </Col>
                                            }
                                            { getFieldValue('asset').type !== "first_aid_kit" &&
                                              getFieldValue('asset').type !== "fire_extinguisher" && 
                                              <Col xs={{span: 24}} md={{span: 11,offset:2}}>
                                                <Form.Item
                                                  name={['asset', 'creation_date']}
                                                  className="isoInputWrapper"
                                                  label="Creation Date"
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: 'This field is required',
                                                    },
                                                  ]}
                                                  >
                                                    <DatePicker size="large" style={{width: '100%'}} format={dateFormat}/>
                                                  </Form.Item>
                                                </Col> 
                                                } 
                                              </Row>
                                              <Row>
                                               <Col xs={{span: 24}} md={{span: 11}}>
                                                <Form.Item
                                                  name={['asset', 'expiry_date']}
                                                  className="isoInputWrapper"
                                                  label="Expiry Date"
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: 'This field is required',
                                                    },
                                                  ]}
                                                >
                                                  <DatePicker size="large" style={{width: '100%'}} format={dateFormat}/>
                                                </Form.Item>
                                                </Col>
                                                {
                                                  (getFieldValue('asset').type === "fire_extinguisher" || 
                                                  getFieldValue('asset').type === "first_aid_kit" ) &&
                                                  <Col xs={{span: 24}} md={{span: 11,offset:2}}>
                                                    <Form.Item
                                                      name={['asset', 'manufacture_date']}
                                                      className="isoInputWrapper"
                                                      label="Manufacture Date"
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message: 'This field is required',
                                                        },
                                                      ]}
                                                    >
                                                      <DatePicker size="large" style={{width: '100%'}} format={dateFormat}/>
                                                    </Form.Item>
                                                  </Col>
                                                  }
                                                  {
                                                    (getFieldValue('asset').type === "policy" ||
                                                    getFieldValue('asset').type === "induction"  ||
                                                    getFieldValue('asset').type === "procedure" ) &&
                                                    
                                                      <Col xs={{span: 24}} md={{span: 11,offset:2}}>
                                                        <Form.Item
                                                          className="isoInputWrapper"
                                                          label="Tag"
                                                        >
                                                          <TagsInput name={['asset', 'tags']} value={tags} onChange={handleChangeTag} addOnBlur="true"/>

                                                        </Form.Item>
                                                      </Col>
                                                   
                                                  }
                                              </Row>  
                                              
                                              {
                                                getFieldValue('asset').type !== "policy" && 
                                                getFieldValue('asset').type !== "induction"  &&
                                                getFieldValue('asset').type !== "procedure"  ?
                                              <Row style={{marginBottom:'10px',marginTop:'5px'}}>
                                                <Col xs={{span: 24}} md={{span: 24}} style={{marginBottom:'10px'}}>
                                                  <Map ref={mapRef} center={valueLng !== null ? [valueLat,valueLng] : position} doubleClickZoom={false} onclick={handleClickMap} zoom={16} zoomControl={true} style={{width:'100%', height:'300px'}}>
                                                   <TileLayer
                                                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                    />
                                                    {valueLat  && <Marker position={[valueLat,valueLng]} draggable={true}>
                                                        <Popup position={[valueLat,valueLng]}>
                                                        </Popup>
                                                    </Marker>}
                                                  </Map>
                                                </Col>
                                              
                                                <Col xs={{span: 24}} md={{span: 11}}>  
                                                  <Form.Item
                                                    className="isoInputWrapper"
                                                    name='latitude'
                                                    label="Latitude"
                                                    value={valueLat}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: 'This field is required',
                                                      },
                                                    ]}
                                                  >                          
                                                  <Input value={valueLat}/>
                                                  </Form.Item>
                                                </Col>
                                                <Col xs={{span: 24}} md={{span: 11, offset: 2}} >
                                                 <Form.Item
                                                    className="isoInputWrapper"
                                                    name='longitude'
                                                    label="Longitude"
                                                    value={valueLng}
                                                    rules={[
                                                        {
                                                          required: true,
                                                          message: 'This field is required',
                                                        },
                                                      ]}
                                                  >
                                                  <Input value={valueLng}/>
                                                  </Form.Item>
                                                </Col> 
                                              </Row>
                                              :
                                              <Row>
                                              {getFieldValue('asset').category !== "health_and_safety" &&
                                                <Col xs={{span: 24}} md={{span: 11}}>
                                                <Form.Item
                                                  shouldUpdate={(prevValues, currentValues) => {
                                                    return (prevValues['asset'] && (prevValues['asset'].state !== currentValues['asset'].state));
                                                  }}
                                                  name={['asset', 'primary_object_id']}
                                                  className="isoInputWrapper"
                                                  label="Location"
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: 'This field is required',
                                                    },
                                                  ]}
                                                >
                                                  {renderOptionObject(props.primaryObjects)}
                                                </Form.Item>
                                              </Col>
                                              }
                                              <Col xs={{span: 24}} md={{span: 11}}>
                                                   <Form.Item
                                                      name={['asset', 'created_by']}
                                                      className="isoInputWrapper"
                                                      label="Created By"
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message: 'This field is required',
                                                        },
                                                      ]}
                                                    >
                                                      <Input size="large" placeholder="Created By"/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            }
                                            
                                          </div>

                                        )
                                     
                                  } 
                                }
                                }
                                </Form.Item>

                                <Row>
                                  <Col xs={{span: 24}} md={{span: 24}} >
                                    <Form.Item
                                      name={['asset', 'attachment']}
                                      className="isoInputWrapper"
                                      label={<IntlMessages id="propertyPage.modal.asset.attachment" />}
                                    >
                                      <Upload {...uploadProps} fileList={attachmentFileList}>
                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                      </Upload>
                                    
                                    </Form.Item>
                                  </Col>
                                </Row>
                          </div>
                        )
                      }
                     }
                    } 
                  }
                </Form.Item>
            <Form.Item
              name={['asset', 'description']}
              className="isoInputWrapper"
              label="Description"
            >
              <Input.TextArea />
            </Form.Item>
          </div>  
      </Form>
      </TabPane>
      { assetData.category === 'health_and_safety' &&
      <TabPane tab="Activity" key="2">
        <Row>
          <Col xs={{span: 7}} md={{span: 7}}>
             <Button key="submit" className="btn-create-breed" type="primary" onClick={addActivy}>
              Add Activity
            </Button>
          </Col>
          <Col xs={{span: 15}} md={{span: 17}}>
            
          </Col>
        </Row>
        <br/>
        <ListAcivity  dataId={assetData.id} propertyId={props.propertyId} dataActivity={dataActivity} getDataActivity={getDataActivity}/>
        <AddActivityModal 
          visibleActivity={visibleActivity} 
          dataId={assetData.id}
          propertyId={props.propertyId}
          cancelModalActivity={cancelModalActivity}
          position={position}
          getDataActivity={getDataActivity}
          onSaved={props.onSaved}
        />
      </TabPane>
      }
      <TabPane tab={<IntlMessages id="propertyPage.mobModal.historyTab" />} key="3">
        <Row>
          <Col xs={{span: 7}} md={{span: 7}}>
            {(activePermission == "owner" || activePermission == "admin"|| activePermission == "modify") &&
            <Button key="submit" className="btn-create-breed" type="primary" onClick={addHistory}>
              {<IntlMessages id="propertyPage.mobModal.historyTab.addHistory" />}
            </Button>}
          </Col>
          <Col xs={{span: 15}} md={{span: 17}}>
            
          </Col>
          
        </Row>
        <br/>
        <AssetHistory assetHistory={assetHistory} updateDataAssetHistory={updateDataAssetHistory} dataId={assetData.id}/>
        <AddAssetHistoryModal
          getDataActivity={getDataActivity}     
          visibleHistory={visibleHistory} 
          cancelModal={cancelModalHistory}
          primaryObjects={props.primaryObjects}
          model='facilities'
          dataId={assetData.id}
          propertyId={props.propertyId}
          updateDataAssetHistory={updateDataAssetHistory}
        />
      </TabPane>
      
      </Tabs>
      }     
    </AssetModalWrapper>
    </>
  )
}

export default EditGeneralAssetModal;

const ColorItemRow = styled.div`
  display: flex;
  align-items: center;
  line-height: 30px;
  div{
    margin: 4px;
  }
`;

const ColorItemPreview = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 2px;
`;