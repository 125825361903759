import _ from "lodash";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

let endpoints = {
  registers: "/api/web/registrations",
  resendEmailConfirmation: "/api/web/registrations/resend-email-confirmation",
  forgotPassword: "/api/web/forgot-passwords",
  resetPassword: "/api/web/reset-passwords",
  users: "/api/web/users",
  sessions: "/api/web/sessions",
  checkEmailExists: "/api/web/users/check-email-exists",
  checkEmailExistsInProperty: "/api/web/users/check-email-exists-in-property",
  verifyEmail: "/api/web/verifications",
  properties: "/api/web/properties",
  profiles: "/api/web/profiles",
  permissions: "/api/web/permissions",
  getUserPermission: "/api/web/permissions/get-user-permission",
  getUserOfProperty: "/api/web/property/get-user-of-property",
  primaryObjects: "/api/web/primary-objects",
  tasks: "/api/web/tasks",
  mobs: "/api/web/mobs",
  breeds: "/api/web/breeds",
  assets: "/api/web/assets",
  archiveAsset: "/api/web/assets/archive-asset",
  activities: "/api/web/activity-histories",
  feedingTypes: "/api/web/feeding-types",
  dashboard: "/api/web/dashboards",
  getActivityByProperty:
    "/api/web/activity-histories/get-livestock-history-by-property",
  getDataForBubbleChart: "/api/web/assets/get-data-for-bubble-chart",
  crops: "/api/web/crops",
  licences: "/api/web/licences",
 
  primaryObjectTypes: "/api/web/primary-object-types",
  qualifications: "/api/web/qualifications",

  emergencies: "/api/web/emergencies",
 
  workingRight :"/api/web/working-rights",
  facilities: "/api/web/facilities",
  subscriptions: "/api/web/subscriptions",
  applicationHistories: "/api/web/application-histories",
  images: "/api/web/images",
  weatherData: "/api/web/weather-datas",
  getDataForWeatherChart: "/api/web/weather-datas/get-data-for-weather-chart",
  getAnimalSire: "/api/web/facilities/get-animal-sire",
  getCategoryChart: "/api/web/facilities/get-category-chart",
  getDataTypePolicy: "/api/web/facilities/get-data-type-policy",
  forms: "/api/web/forms",
  getUserByProperty: "/api/web/users/get-user-by-property",
  splitMob: "/api/web/mobs/split-mob",
  userForm: "/api/web/user-forms",
  getHistoryByProperty: "/api/web/activity-histories/get-history-by-property",
  marketplaceProducts: "/api/web/marketplace-products",
  marketplaceCategories: "/api/web/marketplace-categories",
  getProductByCategory: "/api/web/marketplace-products/get-product-by-category",
  marketplaceCustomers: "/api/web/marketplace-customers",
  getAllProperties: "/api/web/properties/get-all-properties",
  getAllAssetsByProperty: "/api/web/assets/get-all-assets-by-property",
  getAllUsers: "/api/web/users/get-all-users",
  importFiles: "/api/web/import-files",
  mergeMob: "/api/web/mobs/merge-mob",
  archiveMob: "/api/web/mobs/archive-mob",
  getUserByRadius: "/api/web/users/get-user-by-radius",
  surveyQuestions: "/api/web/survey-questions",
  search: "/api/web/searches",
  surveyPending: "/api/web/users/get-user-survey-pending",
  nlis: "/api/web/nlis",
  layers: "/api/web/layers",
  layerObjects: "/api/web/layer-objects",
  attachments: "/api/web/attachments",
  activityPolicies: "/api/web/activity-policies",
  calculatorPaddock: "/api/web/calculator-paddocks",
  optiWeight: "/api/web/opti-weights",
  kmz: "/api/web/kmzs",
  globalForms: "/api/web/global-forms",
  iotDevice: "/api/web/iot-devices",
  categoryGlobalForm: "/api/web/category-global-forms",
  alertDevice: "/api/web/alert-devices",
  healthSafety: "/api/web/health-safeties",
  pushnotification: "/api/web/push-notifications",
  addSAR: "/api/web/sars",
  getAllSARUser: "/api/web/users/get-all-users-for-sar",
  getAllSAR: "/api/web/sars",
  complateElapsed: "/api/web/sars/complate-elapsed",
  terminateAlarm: "/api/web/sars/terminate-alarm",
  visitor: '/api/web/visitors',
  observations: '/api/web/observations',
  visitorForm: '/api/web/visitor-forms',
};

let API_ENDPOINTS = {};

_.forEach(
  endpoints,
  (value, key) => (API_ENDPOINTS[key] = `${API_BASE_URL}${value}`)
);
export const apiEndpoint = API_ENDPOINTS;

let API_ENDPOINTS_FUNCTION = {
  properties: (id) => {
    return API_BASE_URL + `${endpoints.properties}/${id}`;
  },
  primaryObjects: (id) => {
    return API_BASE_URL + `${endpoints.primaryObjects}/${id}`;
  },
  tasks: (id) => {
    return API_BASE_URL + `${endpoints.tasks}/${id}`;
  },
  mobs: (id) => {
    return API_BASE_URL + `${endpoints.mobs}/${id}`;
  },
  breeds: (id) => {
    return API_BASE_URL + `${endpoints.breeds}/${id}`;
  },
  profiles: (id) => {
    return API_BASE_URL + `${endpoints.profiles}/${id}`;
  },
  assets: (id) => {
    return API_BASE_URL + `${endpoints.assets}/${id}`;
  },
  activities: (id) => {
    return API_BASE_URL + `${endpoints.activities}/${id}`;
  },
  feedingTypes: (id) => {
    return API_BASE_URL + `${endpoints.feedingTypes}/${id}`;
  },
  crops: (id) => {
    return API_BASE_URL + `${endpoints.crops}/${id}`;
  },
  licences: (id) => {
    return API_BASE_URL + `${endpoints.licences}/${id}`;
  },
  

  primaryObjectTypes: (id) => {
    return API_BASE_URL + `${endpoints.primaryObjectTypes}/${id}`;
  },
  qualifications: (id) => {
    return API_BASE_URL + `${endpoints.qualifications}/${id}`;
  },
  
  
  workingRight: (id) => {
    return API_BASE_URL + `${endpoints.workingRight}/${id}`;
  },
  emergencies: (id) => {
    return API_BASE_URL + `${endpoints.emergencies}/${id}`;
  },
  
  
  facilities: (id) => {
    return API_BASE_URL + `${endpoints.facilities}/${id}`;
  },
  permissions: (id) => {
    return API_BASE_URL + `${endpoints.permissions}/${id}`;
  },
  subscriptions: (id) => {
    return API_BASE_URL + `${endpoints.subscriptions}/${id}`;
  },
  applicationHistories: (id) => {
    return API_BASE_URL + `${endpoints.applicationHistories}/${id}`;
  },
  images: (id) => {
    return API_BASE_URL + `${endpoints.images}/${id}`;
  },
  weatherData: (id) => {
    return API_BASE_URL + `${endpoints.weatherData}/${id}`;
  },
  forms: (id) => {
    return API_BASE_URL + `${endpoints.forms}/${id}`;
  },
  userForm: (id) => {
    return API_BASE_URL + `${endpoints.userForm}/${id}`;
  },
  marketplaceProducts: (id) => {
    return API_BASE_URL + `${endpoints.marketplaceProducts}/${id}`;
  },
  marketplaceCategories: (id) => {
    return API_BASE_URL + `${endpoints.marketplaceCategories}/${id}`;
  },
  marketplaceCustomers: (id) => {
    return API_BASE_URL + `${endpoints.marketplaceCustomers}/${id}`;
  },
  surveyQuestions: (id) => {
    return API_BASE_URL + `${endpoints.surveyQuestions}/${id}`;
  },
  attachments: (id) => {
    return API_BASE_URL + `${endpoints.attachments}/${id}`;
  },
  calculatorPaddock: (id) => {
    return API_BASE_URL + `${endpoints.calculatorPaddock}/${id}`;
  },
  activityPolicies: (id) => {
    return API_BASE_URL + `${endpoints.activityPolicies}/${id}`;
  },
  layers: (id) => {
    return API_BASE_URL + `${endpoints.layers}/${id}`;
  },
  layerObjects: (id) => {
    return API_BASE_URL + `${endpoints.layerObjects}/${id}`;
  },
  optiWeight: (id) => {
    return API_BASE_URL + `${endpoints.optiWeight}/${id}`;
  },
  kmz: (id) => {
    return API_BASE_URL + `${endpoints.kmz}/${id}`;
  },
  iotDevice: (id) => {
    return API_BASE_URL + `${endpoints.iotDevice}/${id}`;
  },
  globalForms: (id) => {
    return API_BASE_URL + `${endpoints.globalForms}/${id}`;
  },
  categoryGlobalForm: (id) => {
    return API_BASE_URL + `${endpoints.categoryGlobalForm}/${id}`;
  },
  pushnotification: (id) => {
    return API_BASE_URL + `${endpoints.pushnotification}/${id}`;
  },
  terminateSAR: (id) => {
    return API_BASE_URL + `${endpoints.getAllSAR}/${id}`;
  },
  getSARUser: (id) => {
    return API_BASE_URL + `${endpoints.getAllSARUser}/${id}`;
  },
  visitors: (id) => {
    return API_BASE_URL + `${endpoints.visitor}/${id}`;
  },
};
export const apiEndpointFunction = API_ENDPOINTS_FUNCTION;
