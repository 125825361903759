import React, {useState, useEffect } from 'react';
import DeviceTableWrapper from './DeviceTable.styles'; 
import { Row, Col, Button, Input, Space, Table, Select, Dropdown, Menu, Popconfirm, Form, Alert } from 'antd';
import { iotDevice } from '@iso/services';
import _ from 'lodash';
import IntlMessages from '@iso/components/utility/intlMessages';
import { PlusOutlined, DownOutlined, EditOutlined, PlusCircleOutlined, DeleteOutlined, UserOutlined, SettingOutlined, SoundOutlined } from '@ant-design/icons';
import AssignModal from './AssignModal';
import DeviceTimeModal from './DeviceTimeModal';
import StopDeviceAlarmRequestModal from './StopDeviceAlarmRequestModal';

const { Option } = Select;

const DeviceTable = (props) => {
  const [dataDevice, setDataDevice] = useState([]);
  const [dataDeviceByProperty, setDataDeviceByProperty] = useState([]);
  const [deviceId,setDeviceId] = useState();
  const [loading, setLoading] = useState(false);
  const propertyId = props.propertyId;
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState();
  const [isSaveAssign, setIsSaveAssign] = React.useState(false);
  const [assignedDevice,setAssignedDevice] = useState();
  const [isShowAssignModal, setIsShowAssignModal] = useState(false);
  const [isShowDeviceTimeModal, setIsShowDeviceTimeModal] = useState(false);
  const [isVisibleStopAlarmRequestModal, setIsVisibleStopAlarmRequestModal] = useState(false);
  const [selectedIotDevice, setSelectedIotDevice] = useState();

  useEffect(() => {
    if(propertyId){
      getDeviceByProperty();
      getDevice();
    }
  },[propertyId, isSaveAssign]);  

  const getDevice = () => { 
    iotDevice.getList().then(res => {
      if(res.code === '0000'){
        setDataDevice(res.devices);
      }
    });
  };

  const getDeviceByProperty = () => {  
    iotDevice.getList(propertyId).then(res => {
      if(res.code === '0000'){
        setDataDeviceByProperty(res.devices);
      }else{
        setDataDeviceByProperty([]);
      } 

    });
  };

  const searchDevice = (s) =>{
     iotDevice.search(propertyId,s).then(res => {
      if(res.code === '0000'){
        setDataDeviceByProperty(res.devices);
      } 
    });
  }

  const handleChangeDevice = (value) => {
    setDeviceId(value);
  }; 

  const onAddDevice = (name) => {
     setLoading(true);
     setShowAlert(false);
    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
     
  }
  const onStore = (value) =>{
    const data = {
      'name' : value.name,
      'property_id': propertyId,
    }
    iotDevice.storeOrUpdate(data).then(res => {
      if(res.code === '0000'){
        getDeviceByProperty();
        getDevice();
        setDeviceId();
        props.getPositionDevice();
        setFields([
          {
            name:'name',
            value: '',
          },
        ]);
        setLoading(false);
        setMessage(res.message);
      }else{
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
        setMessage(res.message);
        setLoading(false);
      }
    });   
  }
  const onSearchDevice = (e) => {
    if(e.target.value.length > 0){
      searchDevice(e.target.value);
    }else{
      getDeviceByProperty();
    }
  }

  const renderDeviceSelect = () => {
    let options = [];
    let deviceName = [];
    _.forEach(dataDevice, (device, index) => {
      options.push(
        <Option label={device.name} value={device.device_id}>
          <div className="demo-option-label-item">
            {device.name}
          </div>
        </Option>
      );
      deviceName.push(device.name);

    })
    return (
      <Select
        mode="multiple"
        className= "ant-select-device"
        placeholder="Select Devices"
        optionLabelProp="label"
        onChange={handleChangeDevice}
        value={deviceId}
        size="large"

        style={{width:'100%'}}
        allowClear
      >
        {options}
      </Select>
    );
  };
  
  const menu = (iotDevice) => (
    <div>
      <Menu>
        <Menu.Item key="removeDevice">
          <Popconfirm
            className="ant-popconfirm"
            placement="bottomRight"
            title="You are about to remove individual record"
            onConfirm={() => {
              confirmRemove(iotDevice.id);
            }}
            okText="Yes"
            cancelText="No"
            classname="delete-button"
          >
          <a>
            {<DeleteOutlined />}&nbsp;&nbsp;
            <IntlMessages id="safeFarmPage.table.remove" />
          </a>
          </Popconfirm>
        </Menu.Item>
        <Menu.Item key="assignUser" icon={<UserOutlined />} onClick={(e)=>handleAssignUser(iotDevice)}>
          <IntlMessages id="safeFarmPage.table.assign" />
        </Menu.Item>
        <Menu.Item key="deviceTime" icon={<SettingOutlined />} onClick={(e)=>handleSetDeviceTime(iotDevice)}>
          <IntlMessages id="safeFarmPage.table.deviceTime" />
        </Menu.Item>
        <Menu.Item key="showStopAlarmRequest" icon={<SoundOutlined />} onClick={(e)=>handleShowStopAlarmRequests(iotDevice)}>
          <IntlMessages id="Stop Alarm Requests" />
        </Menu.Item>
    </Menu>
    </div>
  );

  const handleShowStopAlarmRequests = (iotDevice) => {
    setIsVisibleStopAlarmRequestModal(true);
    setSelectedIotDevice(iotDevice);
  }

  const confirmRemove = (id) => {
    iotDevice.destroy(id).then(res => {
      if(res.code === '0000'){
        getDeviceByProperty();
        getDevice();
        props.getPositionDevice();
      }
      
    });
  }

  const columnsTable = [
    {
      title: <IntlMessages id="safeFarmPage.table.name" />,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Unique_ID',
      dataIndex: 'unique_id',
      key: 'unique_id',
    },    
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Dropdown overlay={menu(record)} trigger={['click']}>
          <Button className="btn-success ant-btn-primary">
           <span><IntlMessages id="antTable.title.actions" /></span> <DownOutlined />
          </Button>
        </Dropdown>
      ),
    },   
  ];
  
  const cancelModal = () => {
    setIsShowAssignModal(false);
    setAssignedDevice({});
  }

  const cancelDeviceTimelModal = () => {
    setIsShowDeviceTimeModal(false);
    setAssignedDevice({});
  }

  const onSaveAssign = () => {
    setIsSaveAssign(!isSaveAssign);
  }

  const handleAssignUser = (device) => {
    setAssignedDevice(device)
    setIsShowAssignModal(true)
  }

  const handleSetDeviceTime = (device) => {
    setAssignedDevice(device)
    setIsShowDeviceTimeModal(true)
  }

  const cancelStopAlarmModal = () => {
    setIsVisibleStopAlarmRequestModal(false)
    setSelectedIotDevice(null)
  }
  
  return (
    <DeviceTableWrapper>
      <Row gutter={[10,15]}>
      <Col xs={{span: 24}} md={{span: 24}}>
        {showAlert &&
          <Alert
            message="Error"
            description={message}
            type="error"
            closable
          />
        }
        </Col>
        <Col xs={{span: 24}} md={{span: 9}}>
          <Input
            className="ant-device-search"
            placeholder="Input Search Name"
            allowClear
            onChange={onSearchDevice}
          />
        </Col>
        <Col xs={{span: 24}} md={{span: 15}}>
        <Form
          form={form}
          name="addDevice"
          layout="inline"
          scrollToFirstError
          fields={fields}
        >
        <Form.Item
            name="name"
            className="isoInputWrapper"
            rules={[
              {
                required: true,
                message: 'Please input your device name',

              },
              {
                whitespace:true,
                message: 'Please input your name',
              },
            ]}
          >
            <Input placeholder="Device Name" />
          </Form.Item>
          <Button onClick={onAddDevice} loading={loading} className="ant-btn-add-device" icon={<PlusOutlined />}>
            <IntlMessages id="propertyMapPage.add" />
          </Button>
        </Form>
        </Col>

        <Col xs={{span: 24}} md={{span: 24}}>
          <Table dataSource={dataDeviceByProperty} columns={columnsTable} size="small" pagination={{pageSize: 12}}  />  
        </Col>
      </Row>

      <AssignModal propertyId={propertyId} assignedIotDevice={assignedDevice} visible={isShowAssignModal} cancelModal={cancelModal} onSaved={onSaveAssign}/>
      <DeviceTimeModal propertyId={propertyId} assignedIotDevice={assignedDevice} visible={isShowDeviceTimeModal} cancelModal={cancelDeviceTimelModal} onSaved={onSaveAssign}/>
      <StopDeviceAlarmRequestModal selectedIotDevice={selectedIotDevice} visible={isVisibleStopAlarmRequestModal} cancelModal={cancelStopAlarmModal}/>
    </DeviceTableWrapper>
  );
}

export default DeviceTable;
