import React , { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Row, Col, Radio, Checkbox, DatePicker, Alert, Upload, TimePicker, Select, InputNumber } from 'antd';
import { weatherDataService } from '@iso/services';
import userActions from '@iso/redux/user/actions';
import IntlMessages from '@iso/components/utility/intlMessages';
import EditWeatherDataModalWrapper from './EditWeatherDataModal.styles';
import moment from 'moment';
import { dateHelper } from '@iso/lib/helpers/dateHelper';
import { UploadOutlined } from '@ant-design/icons';
import { weatherTabTypes } from '@iso/constants/weatherTabTypes';
import styled from "styled-components";
import { weatherDuration } from '@iso/constants/weatherDuration';
import _ from 'lodash';

const { store } = userActions;
const { Option } = Select;

const EditWeatherDataModal = (props) => {
  const activePermission = useSelector((state) => state.permission.activePermission);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [sharedForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const currentUser = useSelector((state) => state.user.user);

  // const cancelModal = () => {
  //   props.cancelModal();
  //   setCheckingPassword(true);
  //   form.resetFields();
  // }

  const handleSubmit = () => {
    setLoading(true);
    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
  }

  useEffect(() => {
      setFields([
        {
          name: ['weather_data', 'primary_object_id'],
          value: props.particularWeatherData.primary_object_id,
        },
        {
          name: ['weather_data', 'duration'],
          value: props.particularWeatherData.duration,
        },
        {
          name: ['weather_data', 'weather_date'],
          value: props.particularWeatherData.weather_date ? moment(props.particularWeatherData.weather_date) : '',
        },
        {
          name: ['weather_data', 'weather_time'],
          value: props.particularWeatherData.weather_time ? moment(props.particularWeatherData.weather_time, 'HH:mm:ss') : '',
        },
        {
          name: ['weather_data', 'temperature'],
          value: props.particularWeatherData.temperature,
        },
        {
          name: ['weather_data', 'rain'],
          value: props.particularWeatherData.rain,
        },
        {
          name: ['weather_data', 'comment'],
          value: props.particularWeatherData.comment,
        },
      ]);
  }, [props.particularWeatherData, props.visibleEditWeatherData]);

  const onStore = async (values) => {
    const data = {
      weather_data: {
        ...values.weather_data,
        weather_date: values.weather_data.weather_date ? dateHelper.dateForAPI(values.weather_data.weather_date) : '',
        weather_time: values.weather_data.weather_time ? dateHelper.getTime(values.weather_data.weather_time) : '',
        property_id: props.propertyId
      }
    }

    weatherDataService.storeOrUpdate(data, props.particularWeatherData.id).then(res => {
      if(res.code === '0000'){
        //props.onSaved(res.weather_data);
        form.resetFields();
        cancelModal();
        props.updateWeatherData();
      } else {
        setLoading(false);
      }
    });
  }

  const renderOptionObject = (objects) => {
    let options = [];
    _.forEach(objects, (object, index) => {
      options.push(
        <Option key={object.id} value={object.id}>
          {object.name}
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select an object"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const renderOptionsWeatherDuration = () => {
    let options = [];
    _.forEach(weatherDuration, (duration, index) => {
      options.push(
        <Option key={index} value={duration.value}>
          <div>{duration.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select weather duration"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  } 

  const cancelModal = () => {
    props.cancelModal();
    setLoading(false);
  }

  return (
    <>
      <EditWeatherDataModalWrapper
        className="user-access-modal"
        open={props.visibleEditWeatherData}
        title={ <IntlMessages id="weatherPage.editModal.title" /> }
        onCancel={cancelModal}
        maskClosable={true}
        footer={[
          <Button key="back" onClick={cancelModal} className="cancel-button">
            {<IntlMessages id="propertyPage.modal.cancel" />}
          </Button>,
          (activePermission == "owner" || activePermission == "admin"|| activePermission == "modify") && <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit} loading={loading}>
            {<IntlMessages id="propertyPage.modal.save" />}
          </Button>,
        ]}
      >
      {
      
        <Form
          form={form}
          layout="vertical"
          scrollToFirstError
          fields={fields}
        >
          <div className="form-body">
            <Row>
              <Col xs={{span: 24}} md={{span: 11}}>
                <Form.Item
                  name={['weather_data', 'primary_object_id']}
                  className="isoInputWrapper"
                  label={<IntlMessages id="weatherPage.weatherForm.location" />}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  {renderOptionObject(props.primaryObjects)}
                </Form.Item>
              </Col>
              <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                <TemperatureInput>
                  <Form.Item
                    name={['weather_data', 'duration']}
                    className="isoInputWrapper"
                    label={<IntlMessages id="weatherPage.weatherForm.duration" />}
                    style={{width:"100%"}}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                  >
                    <InputNumber style={{ width: '100%' }} size="large" min={0} max={1000} step={1} />
                  </Form.Item>
                  <SuffixAddon>h</SuffixAddon>
                </TemperatureInput>
              </Col>
            </Row>
          <Row>
            <Col xs={{span: 24}} md={{span: 11}}>
              <Form.Item
                name={['weather_data', 'weather_date']}
                className="isoInputWrapper"
                label={<IntlMessages id="propertyPage.modal.asset.date" />}
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
              >
                <DatePicker size="large" style={{width: '100%'}} />
              </Form.Item>
            </Col>
            <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
              <Form.Item
                name={['weather_data', 'weather_time']}
                className="isoInputWrapper"
                label={<IntlMessages id="propertyPage.modal.asset.time" />}
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
              >
                <TimePicker size="large" style={{width: '100%'}} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={{span: 24}} md={{span: 11}}>
              <TemperatureInput>
                <Form.Item
                  name={['weather_data', 'temperature']}
                  className="isoInputWrapper"
                  label={<IntlMessages id="weatherPage.weatherForm.weatherData.temperature" />}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <InputNumber style={{ width: '100%' }} size="large" min={0} max={1000} />
                </Form.Item>
                <SuffixAddon>&#8451;</SuffixAddon>
              </TemperatureInput>
            </Col>
            <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
              <Form.Item
                name={['weather_data', 'rain']}
                className="isoInputWrapper"
                label={<IntlMessages id="weatherPage.weatherForm.weatherData.rain" />}
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
              >
                <InputNumber style={{ width: '100%' }} size="large" min={0} max={1000} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name={['weather_data', 'comment']}
            className="isoInputWrapper"
            label={<IntlMessages id="propertyPage.modal.asset.weatherComment" />}
          >
            <Input.TextArea />
          </Form.Item>
          </div>
        </Form>
      }
    </EditWeatherDataModalWrapper>
    </>
  );
}

export default EditWeatherDataModal;

const SuffixAddon = styled.span`
  margin-top: 31px;
  margin-bottom: 5px;
  line-height: 38px;
  color: #747784!important;
  flex-shrink: 0;
  border-top-right-radius: 4px!important;
  border-bottom-right-radius: 4px!important;
  border-right-width: 1px!important;
  min-width: 36px;
  background-color: #f8f9fa;
  border: solid #d9d9d9;
  border-width: 1px 0;
  white-space: nowrap;
  text-align: center;
`;

const TemperatureInput = styled.div`
  display: flex;
  align-items: flex-start;
`;