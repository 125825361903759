import React, { useState, useEffect } from "react";
import IntlMessages from "@iso/components/utility/intlMessages";
import {
  Form,
  Input,
  Button,
  Select,
  Row,
  Col,
  Upload,
  message,
  Modal,
} from "antd";
import styled from "styled-components";
import { StarOutlined, UploadOutlined } from "@ant-design/icons";
import { kmzService } from "@iso/services";
import { authHeader } from "@iso/lib/helpers/authHeader";
import { apiEndpoint, apiEndpointFunction } from "@iso/lib/helpers/endpoint";

const KmzModal = (props) => {
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [idKmz, setIdKmz] = useState();
  const handleSubmit = () => {
    const data = {
      property_id: props.propertyId,
    };
    setLoading(true);
    kmzService.handleKmz(data, idKmz).then((res) => {
      if (res.code === "0000") {
        props.getDataPaging();
        props.cancelModalKmz();
        setLoading();
      }
    });
  };

  const uploadKmz = {
    name: "Kmz[file]",
    action: apiEndpoint.kmz,
    headers: authHeader(true),
    listType: "file",
    multiple: false,
    maxCount: 1,
    onChange: (response) => {
      if (response.file && response.file.status == "done") {
        setIdKmz(response.file.response.kmz.id);
      }
    },
  };
  

  return (
    <KmzModalWrap
      open={props.visibleKmz}
      title="Import File KMZ"
      onCancel={props.cancelModalKmz}
      footer={[
        <Button
          key="back"
          onClick={props.cancelModalKmz}
          className="cancel-button"
        >
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button
          key="submit"
          className="btn-success"
          type="primary"
          onClick={handleSubmit}
          loading={loading}
        >
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
      <Upload {...uploadKmz}>
        <Button icon={<UploadOutlined />}>Upload</Button>
      </Upload>
    </KmzModalWrap>
  );
};

export default KmzModal;

const KmzModalWrap = styled(Modal)`
  .btn-success {
    background: #f80e46;
    &:hover {
      border-color: #f80e46;
    }
  }
  .ant-upload-select-file {
    .ant-btn {
      &:hover {
        border: 1px solid #f80e46;
        color: #f80e46;
      }
    }
  }
`;
