import React, { useState, useEffect, useRef } from "react";
import { Button, Form, message, Input, DatePicker, Select, Steps } from "antd";
import { IllnessFormWrapper } from "./IllnessFormWrapper.style";
import {
  formService,
  
  propertyService,


} from "@iso/services";
import { visitorFormService } from "../../../../../services/visitorform.service";
import { visitorService } from "../../../../../services/visitor.service";
import { publicFormService } from "../../../../../services/publicform.service";
import { useSelector } from "react-redux";
import { dateHelper } from "@iso/lib/helpers/dateHelper";
import moment from "moment";
import WorkerDetail from "./Components/WorkerDetail";
import IllnessDetail from "./Components/IllnessDetail";
import MoreDetail from "./Components/MoreDetail";
import ForManager from "./Components/ForManager";

const { Step } = Steps;

const steps = [
  {
    title: "Worker’s details",
    name: "worker_detail",
  },
  {
    title: "Injury or illness details",
    name: "illness_detail",
  },
  {
    title: "More Detail",
    name: "more_detail",
  },
  {
    title: "For manager/supervisor",
    name: "for_manager",
  },
];

const IllnessForm = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const [users, setUsers] = useState([]);
  const [visitors, setVisitors] = useState([]);
  const [current, setCurrent] = useState(0);

  const next = () => {
   
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleSubmit = async () => {
    if (!props.isAbleToSubmit) {
      return;
    }
    if (props.visitorForm) {
      const visitorFormValidation = await props.visitorForm.validateFields();
      if (visitorFormValidation.errorFields) {
        return;
      }
    }
    form
      .validateFields()
      .then((values) => {
        handleFinish(values);
      })
      .catch((info) => {
        if (info["errorFields"] && info["errorFields"].length > 0) {
          const errorField = info["errorFields"][0];
          const stepIndex = steps.findIndex(
            (step) => step.name === errorField.name[0]
          );
          setCurrent(stepIndex);
        }
        console.log("Validate Failed:", info);
      });
  };

  useEffect(() => {
    if (props.formData) {
      const formResult = props.formData;
      for (let field in formResult) {
        for (let subField in formResult[field]) {
          if (
            [
              "date_of_birth",
              "date_of_illness",
              "date",
              "time_of_illness",
            ].includes(subField)
          ) {
            form.setFieldValue(
              [field, subField],
              moment(formResult[field][subField])
            );
          } else {
            form.setFieldValue([field, subField], formResult[field][subField]);
          }
        }
      }
    }
  }, [props.formData]);

  const handleFinish = (values) => {
    values.worker_detail.date_of_birth = dateHelper.dateTimeForAPI(
      values.worker_detail.date_of_birth
    );
    values.illness_detail.date_of_illness = dateHelper.dateTimeForAPI(
      values.illness_detail.date_of_illness
    );
    values.illness_detail.time_of_illness = dateHelper.dateTimeForAPI(
      values.illness_detail.time_of_illness
    );
    values.more_detail.date_of_birth = dateHelper.dateTimeForAPI(
      values.more_detail.date_of_birth
    );
    values.more_detail.date = dateHelper.dateTimeForAPI(
      values.more_detail.date
    );
    values.for_manager.date = dateHelper.dateTimeForAPI(
      values.for_manager.date
    );
    const data = {
      form: {
        global_form: values,
        type: "illness_form",
      },
    };
    if (props.visitorForm) {
      data.visitor = props.visitorForm.getFieldsValue().visitor;
      data.form.visitor_form_token = props.visitorFormToken;
      visitorFormService.createVisitorForm(data).then(onSuccessCallback);
    } else if (props.isPublicForm) {
      data.form.form_token = props.formToken;
      publicFormService.submitPublicForm(data).then(onSuccessCallback);
    } else {
      formService
        .storeOrUpdate(data, props.formData.id)
        .then(onSuccessCallback);
    }
  };

  const onSuccessCallback = (res) => {
    if (res.code === "0000") {
      message.success("Submit success");
      props.setShowFinalScreen(true);
      setLoading(false);
    } else {
      message.error(res.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props.propertyId) {
      propertyService.getListUsersOfProperty(props.propertyId).then((res) => {
        if (res.users) {
          const userList = res.users.map((user) => {
            return {
              ...user,
              user_type: "register_user",
            };
          });
          setUsers(userList);
        }
      });
      visitorService.getList(props.propertyId).then((res) => {
        if (res.visitors) {
          const visitorList = res.visitors.map((visitor) => {
            return {
              ...visitor,
              user_type: "visitor",
            };
          });
          setVisitors(visitorList);
        }
      });
    }
  }, [props.propertyId]);

  const handleFormChange = (changedValues, allValues) => {
    if (changedValues?.worker_detail?.user) {
      const userInfo = changedValues.worker_detail.user.split("-");
      if (userInfo[1] === "visitor") {
        const selectedVisitor = visitors.filter(
          (visitor) => visitor.id == userInfo[0]
        );
        if (selectedVisitor.length > 0) {
          form.setFieldValue(
            ["worker_detail", "first_name"],
            selectedVisitor[0].name
          );
          form.setFieldValue(
            ["worker_detail", "date_of_birth"],
            selectedVisitor[0].birthday
              ? moment(selectedVisitor[0].birthday)
              : null
          );
        }
      } else {
        const selectedUser = users.filter((user) => user.id == userInfo[0]);
        if (selectedUser.length > 0) {
          form.setFieldValue(
            ["worker_detail", "first_name"],
            selectedUser[0].name
          );
          form.setFieldValue(
            ["worker_detail", "last_name"],
            selectedUser[0].surname
          );
          form.setFieldValue(
            ["worker_detail", "date_of_birth"],
            selectedUser[0].date_of_birth
              ? moment(selectedUser[0].date_of_birth)
              : null
          );
          form.setFieldValue(
            ["worker_detail", "address"],
            selectedUser[0].address
          );
        }
      }
    }

    if (changedValues?.more_detail?.user) {
      const userInfo = changedValues.more_detail.user.split("-");
      if (userInfo[1] === "visitor") {
        const selectedVisitor = visitors.filter(
          (visitor) => visitor.id == userInfo[0]
        );
        if (selectedVisitor.length > 0) {
          form.setFieldValue(
            ["more_detail", "first_name"],
            selectedVisitor[0].name
          );
          form.setFieldValue(
            ["more_detail", "date_of_birth"],
            selectedVisitor[0].birthday
              ? moment(selectedVisitor[0].birthday)
              : null
          );
        }
      } else {
        const selectedUser = users.filter((user) => user.id == userInfo[0]);
        if (selectedUser.length > 0) {
          form.setFieldValue(
            ["more_detail", "first_name"],
            selectedUser[0].name
          );
          form.setFieldValue(
            ["more_detail", "last_name"],
            selectedUser[0].surname
          );
          form.setFieldValue(
            ["more_detail", "date_of_birth"],
            selectedUser[0].date_of_birth
              ? moment(selectedUser[0].date_of_birth)
              : null
          );
          form.setFieldValue(
            ["more_detail", "address"],
            selectedUser[0].address
          );
        }
      }
    }
  };

  return (
    <IllnessFormWrapper>
      <Steps className="illness-steps" current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <Form
        form={form}
        layout="vertical"
        fields={fields}
        onValuesChange={handleFormChange}
      >
        <div className={current == 0 ? "active-step" : "form-step"}>
          <WorkerDetail
            form={form}
            propertyId={props.propertyId}
            users={users}
            visitors={visitors}
          />
        </div>
        <div className={current == 1 ? "active-step" : "form-step"}>
          <IllnessDetail form={form} propertyId={props.propertyId} />
        </div>
        <div className={current == 2 ? "active-step" : "form-step"}>
          <MoreDetail
            form={form}
            propertyId={props.propertyId}
            users={users}
            visitors={visitors}
          />
        </div>
        <div className={current == 3 ? "active-step" : "form-step"}>
          <ForManager form={form} propertyId={props.propertyId} />
        </div>
        <div style={{ marginTop: 24 }}>
          {current < steps.length - 1 && (
            <Button  className="btn-success" type="primary" onClick={() => next()}>
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button
              key="submit"
              className="btn-success"
              type="primary"
              onClick={handleSubmit}
              loading={loading}
            >
              Submit
            </Button>
          )}
          {current > 0 && (
            <Button className="btn-Previous" style={{ margin: "0 8px" }}  onClick={() => prev()}>
              Previous
            </Button>
          )}
        </div>
      </Form>
    </IllnessFormWrapper>
  );
};

export default IllnessForm;
