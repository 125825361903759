import React, { useState, useEffect, useCallback } from "react";
import { Form, Input, Button, Select, Dropdown, Menu, Popconfirm, Pagination } from "antd";
import IntlMessages from "@iso/components/utility/intlMessages";
import PropertyModalWrapper from '../Property/PropertyModal.styles';
import Box from '@iso/components/utility/box';
import {
  EllipsisOutlined,
  PlusOutlined,
  EditOutlined,
  EyeOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import { propertyService, userService, permissionService } from "@iso/services";
import { useSelector } from "react-redux";
import _ from "lodash";
import Loader from "@iso/components/utility/loader";
// import PermissionSelect from "./PermissionSelect";
import Table from "@iso/components/collapsing-table/table";
import {workingRightService, licenceService } from '@iso/services';
import moment from "moment";
import WorkingRightModal from "../Profile/WorkingRightModal";

const { Option } = Select;

const ViewWorkingRights = (props) => {

    const activePropertyId = useSelector(
        (state) => state.property.activePropertyId
    );
    const [workingRightData,setWorkingRightData] = useState([]);
 const[visibleModal,setVisibleModal]= React.useState();
 const [visibleLicence, setVisibilityLicence] = React.useState(false);
 const [editLicence, setEditLicence] = useState(false);
 const [viewSummary, setViewSummary] = useState(false);
 const [licenceData, setLicenceData] = useState({});
 const [title,setTitle]= useState();
    const [modalLoading, setModalLoading] = useState(true);
    const [users, setUsers] = useState([]);
   
    const [form] = Form.useForm();
 
    const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const displayedVisitor = workingRightData?.slice(startIndex, endIndex);
  const handlePageSizeChange = (current, size) => {
    setCurrentPage(1);
    setPageSize(size);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
   
  const onSaved = () => {
    viewWorkingRightsData(props?.viewSummary?.id,activePropertyId);
  }
    const cancelModal = () => {
        if (props.setVisibleWorkingRights) {
            props.setVisibleWorkingRights(false);
            props.setViewSummary();
        }

    };    
    useEffect(() =>{
      viewWorkingRightsData(props?.viewSummary?.id,activePropertyId);
  },[props?.viewSummary,activePropertyId])
  
  const viewWorkingRightsData = (id,activePropertyId) => {
    if (id && activePropertyId) {
      
      workingRightService.getList(id,activePropertyId).then(res => {
        if (res.code === '0000') {

          setWorkingRightData(res.working_rights);
        }
      });

    }
  }
      const menuList = (workingRights, permission) => (
        <Menu onClick={handleMenuClick.bind(this, workingRights)}>
          <Menu.Item key="editDetails" icon={<EditOutlined />}>
            <IntlMessages id="propertyPage.managePage.editDetails" />
          </Menu.Item>
          <p style={{ cursor: "pointer" }} className="deleteButton">
           <Popconfirm
             placement="bottomRight"
             title="You are about to delete individual record"
             onConfirm={() => {
               confirmDelete(workingRights);
             }}
             okText="Yes"
             cancelText="No"
             className="delete-button"
           >
             <a style={{ color: "rgba(0, 0, 0, 0.65)", marginLeft: "11px" }}>
               {<DeleteOutlined />}&nbsp;&nbsp;
               <IntlMessages id="propertyPage.managePage.delete" />
             </a>
           </Popconfirm>
         </p>
        </Menu>
      );
      const confirmDelete = (workingRights) => {
      
        if (workingRights.id) {
          workingRightService.destroy(workingRights.id).then((res) => {
            if (res.code === "0000") {
            
              viewWorkingRightsData();
            }
          });
        }
      };
      const handleMenuClick = (workingRights, e) => {
    
    
        if (e.key === "viewDetails") {
    
          // setViewSummary(true);
          // setLicenceData(workingRights);
          // setTitle('Working Right')
        }
        if (e.key === "editDetails") {
          setEditLicence(true);
          setLicenceData(workingRights);
          setVisibilityLicence(true);
          setTitle("Edit Working Right")
    
        }
      }
      const cancelWorkingRightsModal = () => {
        setVisibilityLicence(false);
        setEditLicence(false);
        setLicenceData({});
      }
    const columns = [
      {
        label: <IntlMessages id="Image" />,
        accessor: 'images',
        minWidth: 100,
        sortable: false,
        position: 1,
        priorityLevel: 1,
        CustomComponent: (cell) => {
          let working = cell.row;
          const lastImage = working.images[working.images.length - 1];
          if (working.images && working.images.length > 0) {
            return <img src={lastImage.full_path} alt={`Image for ${working.type_label}`} height='31px' width='46px' />;
          } else {
            return 'No Image';
          }
        }
      },
      {
        label: <IntlMessages id="Type" />,
        accessor: 'type',
        minWidth: 100,
        sortable: false,
        position: 2,
        priorityLevel: 2,
        CustomComponent: cell => {
          let working = cell.row
          return (working.type) ? working.type : working.type_label;
        }
      },
      {
        label: <IntlMessages id="Name" />,
        accessor: 'name',
        minWidth: 100,
        sortable: false,
        position: 3,
        priorityLevel: 3
      },
      {
        label: <IntlMessages id="Createdv At" />,
        accessor: 'created_at',
        minWidth: 200,
        sortable: false,
        position: 4,
        priorityLevel: 4,
        CustomComponent: cell => {
          let working = cell.row
          return (working.created_at) ? moment(working.created_at).format('L') : ''
        }
      },
      {
        label: <IntlMessages id="Last Edited At" />,
        accessor: 'updated_at',
        minWidth: 200,
        sortable: false,
        position: 5,
        priorityLevel: 5,
        CustomComponent: cell => {
          let working = cell.row
          return (working.updated_at) ? moment(working.updated_at).format('L') : ''
        }
      },
     
      {
        label: "",
        accessor: "dot-menu",
        minWidth: 100,
        sortable: true,
        position: 6,
        priorityLevel: 6,
        noTitle: true,
        CustomComponent: (cell) => {
          let workingRights = cell.row
          // let permission = permissionService.getPermission(row.permission_id);
          return (
            <Dropdown overlay={menuList(workingRights)} trigger={["click"]}>
              <div
                className="property-area-dot-btn"
                style={{ cursor: "pointer" }}
              >
                <EllipsisOutlined  className="dotIcon"/>
              </div>
            </Dropdown>
          );
        },
      },
    ];
   

    return (
        <PropertyModalWrapper
            className="user-access-modal"
            forceRender={true}
            open={props.visibleWorkingRights}
            title={<IntlMessages id="View Working Rights" />}
            onCancel={cancelModal}
            footer={[
                <Button key="back" onClick={cancelModal} className="cancel-button" >
                    {<IntlMessages id="propertyPage.modal.cancel" />}
                </Button>,
                // <Button
                //     key="submit"
                //     className="btn-success"
                //     type="primary"
                //     loading={loading}
                //     onClick={handleSubmit}
                // >
                //     {<IntlMessages id="propertyPage.modal.save" />}
                // </Button>,
            ]}
        >
            <div className="form-title">
                <h3>{<IntlMessages id="Working Rights" />}</h3>
            </div>

             { displayedVisitor?.length > 0 ? (
                    <>
                    <Table
                        columns={columns}
                        rows={displayedVisitor}
                        containerId="containerBox"
                        showPagination={false}
                        rowSize={99999}
                    />
                   
                    <div style={{ margin: '20px' }}>
                    <Pagination
                      current={currentPage}
                      total={workingRightData.length}
                      pageSize={pageSize}
                      onChange={handlePageChange}
                      showSizeChanger
                      pageSizeOptions={["10", "20", "50", "100"]}
                      onShowSizeChange={handlePageSizeChange}
                    /></div>
                    </>
                ) :
                    <h1 style={{ fontSize: '18px', padding: '15px', fontWeight: '600' }}>
                        <IntlMessages id="dashboardPage.noData" />
                    </h1>
            }

            <WorkingRightModal
             visibleLicence={visibleLicence} cancelModal={cancelWorkingRightsModal} onSaved={onSaved}
             editLicence={editLicence} licenceData={licenceData}
             viewSummary={viewSummary} setLicenceData = {setLicenceData}
             setViewSummary={setViewSummary}

             title = {title}
            />

           
        </PropertyModalWrapper>
    );
};

export default ViewWorkingRights;
