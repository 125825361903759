
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Form, Input, Button, Row, Col, Select, DatePicker } from "antd";
// import { visitorService } from "@iso/services";
import { visitorService } from "../../../services/visitor.service";
import IntlMessages from "@iso/components/utility/intlMessages";
import VisitorModalWrapper from "../Visitor/VisitorModal.styles";
import { Checkbox, Radio } from "antd";
import { Map, TileLayer, FeatureGroup, Polygon, Tooltip, ZoomControl, Marker, Popup } from 'react-leaflet';
import { Form, Input, Button, Select, Row, Col, DatePicker, Card, Timeline, Upload, message } from 'antd';
import _ from "lodash";
import Alert from "@iso/components/Feedback/Alert";
import { loadScript } from "@iso/lib/helpers/googleApiHelper";
import { acreageUnit } from "@iso/constants/acreageUnit";
import moment from "moment";
import MapLocationPage from './MapLocationModal.styles'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
const { Option } = Select;

const MapLocationModal = (props) => {
  const mapRef = useRef();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const currentUser = useSelector((state) => state.user.user);
  const [errMsg, setErrMsg] = useState("");

  const cancelModal = () => {
    // props.setValueLat('');
    // props.setValueLng('');
    props.cancelModal();
    resetForm();
  };

  const handleSubmit = (e) => {
    setLoading(true);

    if (props.setValueLat && props.valueLng) {
      props.setValueLat(props.valueLat);
      props.setValueLng(props.valueLng);
     
    }
    setLoading(false);
    cancelModal();
  }
  const resetForm = () => {
    setTimeout(() => {
      form.resetFields();
      setLoading(false);
      setErrMsg("");
    }, 500);
  };

  return (
    <MapLocationPage
      className='visitor-modal'
      forceRender={true}
      open={props.visibleVisitorModal}
      title="Add Location"
      onCancel={cancelModal}
      maskClosable={true}
      footer={[
        <Button key="back" onClick={handleSubmit} className="cancel-button">
          {<IntlMessages id="Add" />}
        </Button>
      ]}
    >
      <Form 
        form={form}
        name="addLocation"
        layout="vertical"
        scrollToFirstError
        fields={fields}
      >
        {/* {errMsg && (
          <Alert
            message={errMsg}
            type="error"
            style={{ margin: "15px 15px 0 15px" }}
          />
        )} */}
       <div className="form-body">
                    {/* <Row> */}
                     
                     <Map ref={mapRef} center={ (props.position && props.position !== null) ? props.position : [-37.16274, 146.08866]} 
                     onclick={props.handleClickMap} doubleClickZoom={false} zoom={16} zoomControl={true} style={{width:'100%', height:'300px'}}>  
                         {/* <TileLayer
                             attribution='&amp;copy <a href="http:osm.org/copyright">OpenStreetMap</a> contributors'
                             url="https:{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                         /> */}
                         <ReactLeafletGoogleLayer type={'hybrid'} useGoogMapsLoader={false} />
                         {props.valueLat && <Marker position={[props.valueLat, props.valueLng]} draggable={true}>
                             <Popup position={[props.valueLat, props.valueLng]}>
                             </Popup> 
                         </Marker>}
                     </Map>
                     {/* </Col> */}
                     {/* </Row> */}
                     <Row style={{ marginTop: "10px", marginBottom: "5px" }}>
                         <Col xs={{ span: 24 }} md={{ span: 11 }} >
                             <Input value={props.valueLat} />
                         </Col>
                         <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }} >
                             <Input value={props.valueLng} />
                         </Col>
                     </Row>
                 </div>

      </Form>
    </MapLocationPage>
  );
};

export default MapLocationModal;
