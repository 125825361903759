import { propertyService } from '@iso/services';

import permissionActions from '@iso/redux/permission/actions';
import subscriptionActions from '@iso/redux/subscription/actions';
import picActions from '@iso/redux/property_pic/actions';
import trialSatelliteExpiredActions from '@iso/redux/property_trial_satellite/actions';

const { changeActivePermission } = permissionActions;
const { changeActivePropertySubscription } = subscriptionActions;
const { changeActivePropertyPIC } = picActions;
const { changeActiveTrialSatelliteExpired } = trialSatelliteExpiredActions;

const actions = {
  STORE_PROPERTIES: 'STORE_PROPERTIES',
  CHANGE_ACTIVITY: 'CHANGE_ACTIVITY',
  
  changeActiveProperty: (activeProperty) => {
    return dispatch => {
      dispatch(changeActive(activeProperty.id));
      dispatch(changeActivePermission(activeProperty.id));
      dispatch(changeActivePropertySubscription(activeProperty.subscription_label));
      dispatch(changeActivePropertyPIC(activeProperty.PIC));
      dispatch(changeActiveTrialSatelliteExpired(activeProperty.trial_satellite_expired));
    }
  }, 
};

function storeProperties(properties) {
  return {
    type: actions.STORE_PROPERTIES,
    properties,
  }
}

function changeActive(activePropertyId){
  return {
    type: actions.CHANGE_ACTIVITY,
    activePropertyId
  }
}

export default actions;
