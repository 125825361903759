import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Row, Col, Radio, Checkbox, DatePicker, Menu, Dropdown,Popconfirm } from 'antd';

import { profileService, livestockService, licenceService } from '@iso/services';
import userActions from '@iso/redux/user/actions';
import IntlMessages from '@iso/components/utility/intlMessages';
import LicencesWrapper from './Licences.styles';
import Table from '@iso/components/collapsing-table/table';
import Box from '@iso/components/utility/box';
import LicenceModal from './LicenceModal';
import _ from 'lodash';
import moment from 'moment';
import {
  EllipsisOutlined,
  PlusOutlined,
  EditOutlined,
  EyeOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import LicenceViewModal from './LicenceViewModal';
const { store } = userActions;

const Licences = (props) => {
  const [licenceVehicles, setLicenceVihicles] = useState([]);
  const [licenceMachinery, setLicenceMachinery] = useState([]);
  const [licenceOther, setLicenceOther] = useState([]);
  const [licenceData, setLicenceData] = useState({});
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const [checkingPassword, setCheckingPassword] = useState(true);
  const [fields, setFields] = useState([]);
  const currentUser = useSelector((state) => state.user.user);
  const [visibleLicence, setVisibilityLicence] = React.useState(false);
  const [editLicence, setEditLicence] = useState(false);
  const [viewSummary, setViewSummary] = useState(false);
const [title,setTitle] = useState("Add Licence")
const user_id = props.userId || '';
const form_id = props.formId || '';
const isDisableForm = user_id && form_id ? true : false
  useEffect(() => {
    updateLicenceData();
  }, [currentUser.id, user_id, form_id,]);

  const updateLicenceData = () => {
    if(user_id !== undefined && user_id !== "" && form_id !== undefined && form_id !== ""){
      licenceService.getCheckinFormUserLicense(form_id, user_id, 'vehicles').then(res => {
        if(res.code === '0000'){
          setLicenceVihicles(res.licences);
        }
      });
       licenceService.getCheckinFormUserLicense(form_id, user_id, 'machinery').then(res => {
        if(res.code === '0000'){
          setLicenceMachinery(res.licences);
        }
      });
       licenceService.getCheckinFormUserLicense(form_id, user_id, 'other').then(res => {
        if(res.code === '0000'){
          setLicenceOther(res.licences);
        }
      });
    }else if (currentUser) {
      licenceService.getList(currentUser.id, 'vehicles').then(res => {
        if (res.code === '0000') {
          setLicenceVihicles(res.licences);
        }
      });
      licenceService.getList(currentUser.id, 'machinery').then(res => {
        if (res.code === '0000') {
          setLicenceMachinery(res.licences);
        }
      });
      licenceService.getList(currentUser.id, 'other').then(res => {
        if (res.code === '0000') {
          setLicenceOther(res.licences);
        }
      });
    }
  }

  const showModal = () => {
    setVisibilityLicence(true);
  }

  const showEditModal = (licence) => {
    setEditLicence(true);
    setLicenceData(licence);
    setVisibilityLicence(true);
  }

  const cancelModal = () => {
    setVisibilityLicence(false);
    setEditLicence(false);
    setLicenceData({});
  }

  const onSaved = () => {
    updateLicenceData();
  }
  const menuList = (licence, permission) => (
    <Menu onClick={handleMenuClick.bind(this, licence)}>
      <Menu.Item key="editDetails" icon={<EditOutlined />}>
        <IntlMessages id="propertyPage.managePage.editDetails" />
      </Menu.Item>
      <Menu.Item key="viewDetails" icon={<EyeOutlined />}>
        <IntlMessages id="View Details" />
      </Menu.Item>
      {/* <Menu.Item key="Remove" icon={<DeleteOutlined />}>
        <IntlMessages id="Remove" />
      </Menu.Item> */}

      <p style={{ cursor: "pointer" }} className="deleteButton">
       <Popconfirm
         placement="bottomRight"
         title="You are about to delete individual record"
         onConfirm={() => {
           confirmDelete(licence);
         }}
         okText="Yes"
         cancelText="No"
         className="delete-button"
       >
         <a style={{ color: "rgba(0, 0, 0, 0.85)", marginLeft: "11px" }}>
           {<DeleteOutlined />}&nbsp;&nbsp;
           <IntlMessages id="Remove" />
         </a>
       </Popconfirm>
     </p>
    </Menu>
  );
  const confirmDelete = (licence) => {
    if (licence.id) {
      licenceService.destroy(licence.id).then((res) => {
        if (res.code === "0000") {
          updateLicenceData();
        }
      });
    }
  };
  const handleMenuClick = (licence, e) => {


    if (e.key === "viewDetails") {

      setViewSummary(true);
      setLicenceData(licence);
      setTitle('Licence')
    }
    if (e.key === "editDetails") {
      setEditLicence(true);
      setLicenceData(licence);
      setVisibilityLicence(true);
      setTitle("Edit Licence")

    }
  }
  const columns = [
    {
      label: <IntlMessages id="image" />,
      accessor: 'images',
      minWidth: 100,
      sortable: false,
      position: 1,
      priorityLevel: 1,
      CustomComponent: (cell) => {
        let licence = cell.row;
        if (licence.images && licence.images.length > 0) {
          return <img src={licence.images[licence.images.length - 1].full_path} alt={`Image for ${licence.licence_name_label}`} height='31px' width='46px' />;
        } else {
          return 'No Image';
        }
      }
    },
    {
      label: <IntlMessages id="profilePage.licencesTab.table.licenceName" />,
      accessor: 'licence_name_label',
      minWidth: 100,
      sortable: false,
      position: 2,
      priorityLevel: 2,
      CustomComponent: cell => {
        let licence = cell.row
        return (licence.licence_name) ? licence.licence_name_label : licence.licence_other_name;
      }
    },
    {
      label: <IntlMessages id="profilePage.licencesTab.table.licenceNumber" />,
      accessor: 'licence_number',
      minWidth: 100,
      sortable: false,
      position: 3,
      priorityLevel: 3
    },
    {
      label: <IntlMessages id="profilePage.licencesTab.table.dateIssue" />,
      accessor: 'date_issue',
      minWidth: 200,
      sortable: false,
      position: 4,
      priorityLevel: 4,
      CustomComponent: cell => {
        let licence = cell.row
        return (licence.date_issue) ? moment(licence.date_issue).format('L') : ''
      }
    },
    {
      label: <IntlMessages id="profilePage.licencesTab.table.dateExpiry" />,
      accessor: 'date_expiry',
      minWidth: 200,
      sortable: false,
      position: 5,
      priorityLevel: 5,
      CustomComponent: cell => {
        let licence = cell.row
        return (licence.date_expiry) ? moment(licence.date_expiry).format('L') : ''
      }
    },
   
    {
      label: "",
      accessor: "dot-menu",
      minWidth: 100,
      sortable: true,
      position: 6,
      priorityLevel: 6,
      noTitle: true,
      CustomComponent: (cell) => {
        let licence = cell.row
        // let permission = permissionService.getPermission(row.permission_id);
        return (
          <Dropdown overlay={menuList(licence)} trigger={["click"]}>
            <div
              className="property-area-dot-btn"
              style={{ cursor: "pointer" }}
            >
              <EllipsisOutlined className="dotIcon"/>
            </div>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <LicencesWrapper
        style={{ padding: '15px' }}
      //  className="profile-area"
      >
        <div className="form-title" >
          <h2>{<IntlMessages id="profilePage.managePage.licencesTab" />}</h2>
          <Button icon={<PlusOutlined />} type="primary" className="AddLicence" onClick={showModal}>
            <IntlMessages id="profilePage.licencesTab.addLicence" />
          </Button>

        </div>
        <div className="form-title">
          <h3>{<IntlMessages id="profilePage.licencesTab.type.vehicles" />}</h3>
        </div>
        <Box id="containerBox" style={{ border: 'none' }}>
          {licenceVehicles.length > 0 ?
            <Table
              columns={columns}
              rows={licenceVehicles}
              containerId="containerBox"
              showPagination={false}
              rowSize={99999}
            /> : <h4><IntlMessages id="dashboardPage.noData" /></h4>
          }
        </Box>
        <div className="form-title">
          <h3>{<IntlMessages id="profilePage.licencesTab.type.machinery" />}</h3>
        </div>
        <Box id="containerBox">
          {licenceMachinery.length > 0 ?
            <Table
              columns={columns}
              rows={licenceMachinery}
              containerId="containerBox"
              showPagination={false}
              rowSize={99999}
            /> : <h4><IntlMessages id="dashboardPage.noData" /></h4>
          }
        </Box>
        <div className="form-title">
          <h3>{<IntlMessages id="profilePage.licencesTab.type.other" />}</h3>
        </div>
        <Box id="containerBox">
          {licenceOther.length > 0 ?
            <Table
              columns={columns}
              rows={licenceOther}
              containerId="containerBox"
              showPagination={false}
              rowSize={99999}
            /> : <h4><IntlMessages id="dashboardPage.noData" /></h4>
          }
        </Box>
        <LicenceModal
          visibleLicence={visibleLicence} cancelModal={cancelModal} onSaved={onSaved}
          editLicence={editLicence} licenceData={licenceData}
          viewSummary={viewSummary}
          setViewSummary={setViewSummary}
          title = {title}
        />
        {/* <LicenceViewModal
          viewSummary={viewSummary}
          setViewSummary={setViewSummary}
          licenceData={licenceData}
          title = 'Licence Details'
        /> */}
      </LicencesWrapper>

    </>
  );
}

export default Licences;