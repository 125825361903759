import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Form, Button, Table, Dropdown } from 'antd';
import { iotDevice } from '@iso/services';
import StopDeviceAlarmRequestModalWrapper from './StopDeviceAlarmRequestModal.styles';

const StopDeviceAlarmRequestModal = (props) => {
  const {selectedIotDevice, visible, cancelModal} = props;
  const [data, setData] = useState([]);

  const columnsTable = [
    {
      title: <IntlMessages id="User" />,
      dataIndex: 'user',
      key: 'user',
      fixed: 'left',
      render: (_, record) => (
        <span>{record?.user?.email}</span>
      ),
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
    },   
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
    },   
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 150
    }, 
  ];
  
  useEffect(()=>{
    if(selectedIotDevice){
      iotDevice.getStopDeviceAlarmRequests(selectedIotDevice.id).then(res => {
        if(res.code === '0000'){
          setData(res.data)
        }
      })
    }
  }, [selectedIotDevice])

  return (
    <StopDeviceAlarmRequestModalWrapper
      width={1000}
      visible={visible}
      onCancel={cancelModal}
      title="Stop Alarm Requests"
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
      ]}
    >
      <Table dataSource={data} columns={columnsTable} size="small" pagination={false}  />  
    </StopDeviceAlarmRequestModalWrapper>
  )

};

export default StopDeviceAlarmRequestModal;