import React from "react";

const HealthSafety = () => {
  return (

<div className="sidebar-icon">
<svg id="Layer_1" data-name="Layer 1"  className="sidebar-icon" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 512 512">
  
  <path   className="sidebar-icon" class="cls-1" fill="rgb(143, 148, 151) " d="M448,102.4H358.4V89.6a64,64,0,0,0-64-64H217.6a64,64,0,0,0-64,64v12.8H64a64,64,0,0,0-64,64v256a64,64,0,0,0,64,64H448a64,64,0,0,0,64-64v-256A64,64,0,0,0,448,102.4ZM179.2,89.6a38.44,38.44,0,0,1,38.4-38.4h76.8a38.44,38.44,0,0,1,38.4,38.4v12.8H179.2ZM64,128H448a38.44,38.44,0,0,1,38.4,38.4v38.4H435.2v-6.4A19.2,19.2,0,0,0,416,179.2H390.4a19.2,19.2,0,0,0-19.2,19.2v6.4H140.8v-6.4a19.2,19.2,0,0,0-19.2-19.2H96a19.2,19.2,0,0,0-19.2,19.2v6.4H25.6V166.4A38.44,38.44,0,0,1,64,128Zm345.6,76.8v25.6H396.8V204.8Zm-294.4,0v25.6H102.4V204.8ZM448,460.8H64a38.44,38.44,0,0,1-38.4-38.4v-192H76.8v6.4A19.2,19.2,0,0,0,96,256h25.6a19.2,19.2,0,0,0,19.2-19.2v-6.4H371.2v6.4A19.2,19.2,0,0,0,390.4,256H416a19.2,19.2,0,0,0,19.2-19.2v-6.4h51.2v192A38.44,38.44,0,0,1,448,460.8ZM313.6,307.2H294.4V288a19.2,19.2,0,0,0-19.2-19.2H236.8A19.2,19.2,0,0,0,217.6,288v19.2H198.4a19.2,19.2,0,0,0-19.2,19.2v38.4A19.2,19.2,0,0,0,198.4,384h19.2v19.2a19.2,19.2,0,0,0,19.2,19.2h38.4a19.2,19.2,0,0,0,19.2-19.2V384h19.2a19.2,19.2,0,0,0,19.2-19.2V326.4A19.2,19.2,0,0,0,313.6,307.2Zm-6.4,51.2H268.8v38.4H243.2V358.4H204.8V332.8h38.4V294.4h25.6v38.4h38.4Z"/></svg>
</div>
  );
};
export default HealthSafety;
