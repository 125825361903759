import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Row, Col, Radio, Checkbox, DatePicker, Menu, Dropdown, Popconfirm } from 'antd';
import EmergencyWrapper from './Emergency.styles';
import { workingRightService, licenceService } from '@iso/services';
import userActions from '@iso/redux/user/actions';
import IntlMessages from '@iso/components/utility/intlMessages';
import LicencesWrapper from './Licences.styles';
import Table from '@iso/components/collapsing-table/table';
import Box from '@iso/components/utility/box';
import LicenceModal from './LicenceModal';
import _ from 'lodash';
import moment from 'moment';
import {
  EllipsisOutlined,
  PlusOutlined,
  EditOutlined,
  EyeOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import LicenceViewModal from './LicenceViewModal';
import WorkingRightModal from './WorkingRightModal';
const { store } = userActions;

const WorkingRight = (props) => {
  const [licenceVehicles, setLicenceVihicles] = useState([]);
  const [licenceMachinery, setLicenceMachinery] = useState([]);
  const [licenceOther, setLicenceOther] = useState([]);
  const [licenceData, setLicenceData] = useState({});
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const [checkingPassword, setCheckingPassword] = useState(true);
  const [fields, setFields] = useState([]);
  const currentUser = useSelector((state) => state.user.user);
  const [visibleLicence, setVisibilityLicence] = React.useState(false);
  const [editLicence, setEditLicence] = useState(false);
  const [viewSummary, setViewSummary] = useState(false);
  const [title, setTitle] = useState("Add Working Right")
  const [workingRightData, setWorkingRightData] = useState([]);
  
  const user_id = props.userId || "";
  const form_id = props.formId || "";
  const isDisableForm = user_id && form_id ? true : false;

console.log("id",activePropertyId);
  useEffect(() => {
    updateLicenceData();
  }, [currentUser.id, user_id, form_id,activePropertyId]);

  // const updateLicenceData = () => {
  //   if (currentUser) {
  //     workingRightService.getList(currentUser.id,activePropertyId).then(res => {
  //       if (res.code === '0000') {

  //         setWorkingRightData(res.working_rights);
  //       }
  //     });

  //   }
  // }
  const updateLicenceData = () => {
    if (user_id !== undefined && user_id !== "" && form_id !== undefined && form_id !== "") {
      workingRightService
      .getCheckinFormUserWorkingRight(user_id, form_id)
      .then((res) => {
          if (res.code === "0000") {
              setWorkingRightData(res.working_rights);
          } else {
              console.error("API Error:", res);
          }
      })
      .catch((error) => {
          console.error("API Request Error:", error);
      });
  
    } else {
      workingRightService.getList().then((res) => {
        if (res.code === "0000") {
          setWorkingRightData(res.working_rights);
        }
      });
    }
  };
  const showModal = () => {
    setVisibilityLicence(true);
  }

  const showEditModal = (licence) => {
    setEditLicence(true);
    setLicenceData(licence);
    setVisibilityLicence(true);
  }

  const cancelModal = () => {
    setVisibilityLicence(false);
    setEditLicence(false);
    setLicenceData({});
  }

  const onSaved = () => {
    updateLicenceData();
  }
  const menuList = (workingRights, permission) => (
    <Menu onClick={handleMenuClick.bind(this, workingRights)}>
      <Menu.Item key="editDetails" icon={<EditOutlined />}>
        <IntlMessages id="propertyPage.managePage.editDetails" />
      </Menu.Item>
      <Menu.Item key="viewDetails" icon={<EyeOutlined />}>
        <IntlMessages id="View Details" />
      </Menu.Item>
      {/* <Menu.Item key="Remove" icon={<DeleteOutlined />}>
        <IntlMessages id="Remove" />
      </Menu.Item> */}

      <p style={{ cursor: "pointer" }} className="deleteButton">
        <Popconfirm
          placement="bottomRight"
          title="You are about to delete individual record"
          onConfirm={() => {
            confirmDelete(workingRights);
          }}
          okText="Yes"
          cancelText="No"
          className="delete-button"
        >
          <a style={{ color: "rgba(0, 0, 0, 0.65)", marginLeft: "11px" }}>
            {<DeleteOutlined />}&nbsp;&nbsp;
            <IntlMessages id="propertyPage.managePage.delete" />
          </a>
        </Popconfirm>
      </p>
    </Menu>
  );
  const confirmDelete = (workingRights) => {

    if (workingRights.id) {
      workingRightService.destroy(workingRights.id).then((res) => {
        if (res.code === "0000") {

          updateLicenceData();
        }
      });
    }
  };
  const handleMenuClick = (workingRights, e) => {


    if (e.key === "viewDetails") {

      setViewSummary(true);
      setLicenceData(workingRights);
      setTitle('Working Right')
    }
    if (e.key === "editDetails") {
      setEditLicence(true);
      setLicenceData(workingRights);
      setVisibilityLicence(true);
      setTitle("Edit Working Right")

    }
  }
  const columns = [
    {
      label: <IntlMessages id="Image" />,
      accessor: 'images',
      minWidth: 100,
      sortable: false,
      position: 1,
      priorityLevel: 1,
      CustomComponent: (cell) => {
        let working = cell.row;
        const lastImage = working.images[working.images.length - 1];
        if (working.images && working.images.length > 0) {
          return <img src={lastImage.full_path} alt={`Image for ${working.type_label}`} height='31px' width='46px' />;
        } else {
          return 'No Image';
        }
      }
    },
    {
      label: <IntlMessages id="Type" />,
      accessor: 'type',
      minWidth: 100,
      sortable: false,
      position: 2,
      priorityLevel: 2,
      CustomComponent: cell => {
        let working = cell.row
        return (working.type) ? working.type : working.type_label;
      }
    },
    {
      label: <IntlMessages id="Name" />,
      accessor: 'name',
      minWidth: 100,
      sortable: false,
      position: 3,
      priorityLevel: 3
    },
    {
      label: <IntlMessages id="Createdv At" />,
      accessor: 'created_at',
      minWidth: 200,
      sortable: false,
      position: 4,
      priorityLevel: 4,
      CustomComponent: cell => {
        let working = cell.row
        return (working.created_at) ? moment(working.created_at).format('L') : ''
      }
    },
    {
      label: <IntlMessages id="Last Edited At" />,
      accessor: 'updated_at',
      minWidth: 200,
      sortable: false,
      position: 5,
      priorityLevel: 5,
      CustomComponent: cell => {
        let working = cell.row
        return (working.updated_at) ? moment(working.updated_at).format('L') : ''
      }
    },

    {
      label: "",
      accessor: "dot-menu",
      minWidth: 100,
      sortable: true,
      position: 6,
      priorityLevel: 6,
      noTitle: true,
      CustomComponent: (cell) => {
        let workingRights = cell.row
        // let permission = permissionService.getPermission(row.permission_id);
        return (
          <Dropdown overlay={menuList(workingRights)} trigger={["click"]}>
            <div
              className="property-area-dot-btn"
              style={{ cursor: "pointer" }}
            >
              <EllipsisOutlined className="dotIcon"/>
            </div>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <EmergencyWrapper
        style={{ padding: '15px' }}
      //  className="profile-area"
      >
        <div className="form-title" >
          <h2>{<IntlMessages id="Working Right" />}</h2>
          {!isDisableForm && (
            <Button
              icon={<PlusOutlined />}
              type="primary"
            className='AddEmergency'
             
              onClick={showModal}
            >
              <IntlMessages id="Add Working Right" />
            </Button>
          )}
         

        </div>

        <Box id="containerBox" style={{ border: 'none' }}>
          {workingRightData.length > 0 ?
            <Table
              columns={columns}
              rows={workingRightData}
              containerId="containerBox"
              showPagination={false}
              rowSize={99999}
            /> : <h4><IntlMessages id="dashboardPage.noData" /></h4>
          }
        </Box>


        <WorkingRightModal

          visibleLicence={visibleLicence} cancelModal={cancelModal} onSaved={onSaved}
          editLicence={editLicence} licenceData={licenceData}
          viewSummary={viewSummary} setLicenceData={setLicenceData}
          setViewSummary={setViewSummary}
          title={title}
        />
      </EmergencyWrapper>

    </>
  );
}

export default WorkingRight;