import L from 'leaflet';
import Observation from './AssetIcons/airagri_observation.png';

export const IconObservation = () => {
  var icon = new L.Icon({
    iconUrl: Observation,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [44, 60],
    iconAnchor: [22, 60],
    className: 'custom-icon-marker task-marker'
  });

  return icon;
};