import styled from "styled-components";
import Modal from "@iso/components/Feedback/Modal";
import WithDirection from "@iso/lib/helpers/rtl";

const PrimaryObjectModalWrapper = styled(Modal)`
  .delete-button {
    width: 50%;
    background-color: rgb(63, 63, 68);
    padding: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    color: rgb(255, 255, 255);
    text-align: center;
    height: auto;
    border: transparent;
  }
  .btn-success {
    background-color: #65bb38;
    border-color: #559f2f;
    &:hover {
      opacity: 0.8;
    }
  }
  .ant-modal-body {
    padding: 0;
  }
  .form-title {
    padding: 16px 24px;
    border-bottom: 1px solid #eaeef1;
    h3 {
      font-size: 1rem;
    }
  }
  .form-body {
    padding: 12px 24px 12px 24px;
    .checkbox-contact {
      margin-bottom: 10px;
    }
    // .PicInput{
    //   display: flex;
    //   padding: 8px 12px;
    //   align-items: center;
    //   gap: 8px;
    //   flex: 1 0 0;
    //   border-radius: 8px 0px 0px 8px;
    //   border-top: 1px solid #D0D5DD;
    //   border-bottom: 1px solid #D0D5DD;
    //   border-left: 1px solid #D0D5DD;
    //   background: var(--base-white, #FFF);
    // }
    // .yarnbtn{
    //   display: flex;
    //   // margin-right: 5px
    //   padding: 10px 16px;
    //   justify-content: center;
    //   align-items: center;
    //   // gap: 8px;
    //   border-radius: 0px 8px 8px 0px;
    //   border: 1px solid #D0D5DD;
    //   background: #EDEDED;
    // }
  }
  .btn-create-crop {
    background-color: #46afca;
    border-color: #46afca;
    &:hover {
      opacity: 0.8;
    }
    margin-top: 30px;
    width: 100%;
    padding: 0;
  }
`;

export default WithDirection(PrimaryObjectModalWrapper);
