import styled from "styled-components";

export const IllnessFormWrapper = styled.div`
  padding: 20px;
  .ant-form-item {
    margin-bottom: 0px;
  }
  .ant-form-item:not(.no-style){
    margin-bottom: 24px
  }
  .btn-success{
    border-color: #f80e46 !important;
    background: #f80e46 !important;
  }
  .btn-Previous{
    border-color: #f80e46 !important;
    color: #f80e46 !important;
  }
  .form-title{
    font-size: 1.6rem;
    color: #f80e46;
    margin-bottom: 10px;
  }
  span.ant-steps-icon{
    color:white !important;
  }
  .witness-space .ant-space-item:nth-child(1), .witness-space .ant-space-item:nth-child(2){
    width: 50%;
  }
  .ant-steps-item-icon {
    border-color: white !important;
    background: #f80e46 !important;
}
  .illness-steps{
    margin-bottom: 30px
  }
  .form-step{
    display: none;
  }
  .form-body .leaflet-container .leaflet-google-mutant leaflet-top leaflet-left{
    visibility: visible !important;
    z-index : 1 !important;   
 }
`;
