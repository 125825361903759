import React from "react";
import { Link } from "react-router-dom";
import siteConfig from "@iso/config/site.config";
import logo from "@iso/assets/images/logo.png";
import logo_icon from "@iso/assets/images/logo_icon.png";
import closeIcon from "@iso/assets/images/close-circle.svg";
import appActions from "@iso/redux/app/actions";
import { useDispatch } from "react-redux";
import safeFarmLogo from "@iso/assets/images/safeFarmLogo.png";
export default ({ collapsed }) => {
  const dispatch = useDispatch();
  const [collapseIcon, setCollapseIcon] = React.useState(false);

  const { toggleChangeText, toggleCollapsed } = appActions;

  const handleToggle = React.useCallback(() => {
    dispatch(toggleCollapsed());
    dispatch(toggleChangeText());
    setCollapseIcon(true);
  }, [dispatch]);
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            
              <Link to="/dashboard">
                <img src={logo_icon} style={{width: '100%', height: '50px', objectFit: 'contain'}} />
              </Link>
            
          </h3>
        </div>
      ) : (
        <div>
          <h3>
            <Link to="/dashboard">
              <img src={safeFarmLogo} style={{ height: '65px',width: '100%', objectFit: 'contain'}} />
            </Link>
          </h3>
          <div
            className={
              collapseIcon ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"
            }
            style={{ cursor: "pointer" }}
            onClick={handleToggle}
          >
            <img
              className="mob-icon-menu"
              src={closeIcon}
              style={{ marginRight: "20px" }}
              alt="collepse-icon"
            />
          </div>
        </div>
      )}
    </div>
  );
};
