import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const SharingMapModalWrapper = styled(Modal)`
  .form-qr-area {
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
    flex-direction: column;
    align-items: center;
    .ant-typography {
      margin-top: 20px;
      padding: 5px;
      border: 1px solid #e9e9e9 ;
      border-radius: 5px;
      svg {
        fill: #f80e46;
      }
    }
  }
`;

export default WithDirection(SharingMapModalWrapper);