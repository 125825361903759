import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import ProductModalWrapper from './ProductModal.styles';
import { Form, Input, Button, Select, Row, Col, DatePicker, InputNumber, Upload, Card, message } from 'antd';
import { CloseOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { marketplaceProductService, marketplaceCategoryService } from '@iso/services';
import moment from 'moment';
import _ from 'lodash';
import $ from 'jquery';
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';

const { Option } = Select;

const ProductModal = (props) => {
  const currentUser = useSelector((state) => state.user.user);
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const activePermission = useSelector((state) => state.permission.activePermission);
  const [imageId, setImageId] = useState([]);
  const [productDetail, setProductDetail] = useState({});

  const cancelModal = () => {
    props.cancelProductModal();
    form.resetFields();
    setLoading(false);
  }

  const renderOptionProductCategory = (categories) => {
    let options = [];
    _.forEach(categories, (category, index) => {
      options.push(
        <Option key={category.id} value={category.id}>
          {category.name}
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a category"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  useEffect(() => {
    if(props.productData)
    {
      setProductDetail(props.productData);

      setFields([
          {
            name: ['marketplace_product', 'title'],
            value: productDetail.title || '',
          },
          {
            name: ['marketplace_product', 'category_id'],
            value: productDetail.category_id || '',
          },
          {
            name: ['marketplace_product', 'price'],
            value: productDetail.price || '',
          },
          {
            name: ['marketplace_product', 'object_id'],
            value: productDetail.object_id || '',
          },
          {
            name: ['marketplace_product', 'external_link'],
            value: productDetail.external_link || '',
          },
          {
            name: ['marketplace_product', 'description'],
            value: productDetail.description || '',
          }
      ]);

      // let image_gallery = [];
      // _.forEach(props.productData.images, (option, index) => {
      //   image_gallery.push(
      //     {
      //       uid: option.id,
      //       status: 'done',
      //       url: process.env.REACT_APP_API_BASE_URL + option.path,
      //     }
      //   );
      // })

      // setImageList(image_gallery);
    }
  }, [props.visibleProductModal, props.productData, productDetail])

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
  }

  const onStore = (values) => {
    const data = {
      marketplace_product: {
        ...values.marketplace_product,
        image_id: imageId
      }
    }

    marketplaceProductService.storeOrUpdate(data, productDetail.id).then(res => {
      if(res.code === '0000'){
        cancelModal();
        props.getProducts();
        form.resetFields();
      } else {
        setLoading(false);
      }
    });
  }

  const uploadProps = {
    name: "Image[file]",
    action: apiEndpoint.images,
    headers: authHeader(true),
    beforeUpload: (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
      }

      return isJpgOrPng && isLt2M;
    },
    onChange: (response) => {
      if(response.file && response.file.status == "done"){
        let images = imageId;
        images.push(response.file.response.image.id);
        setImageId(images);

        //setImageId(response.file.response.image.id);
      }
    },    
    listType: "picture-card",
  }

  return(
    <ProductModalWrapper
      open={props.visibleProductModal}
      onCancel={cancelModal}
      maskClosable={true}
      title={productDetail && productDetail.id ? <IntlMessages id="marketplacePage.managePage.editProduct"/> : <IntlMessages id="marketplacePage.managePage.addProduct"/>}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        (activePermission == "owner" || activePermission == "admin"|| activePermission == "modify" || currentUser.is_system_admin == 1) 
        && <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit} loading={loading}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        scrollToFirstError
        fields={fields}
      >
        <div className="form-body">
          <Form.Item
            name={['marketplace_product', 'title']}
            className="isoInputWrapper"
            label={<IntlMessages id="marketplacePage.productModal.title"/>}
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <Input size="large" placeholder="Input title"/>
          </Form.Item>
          <Row>
            <Col xs={{span: 24}} md={{span: 11}}>
              <Form.Item
                name={['marketplace_product', 'category_id']}
                className="isoInputWrapper"
                label={<IntlMessages id="marketplacePage.productModal.category" />}
                // rules={[
                //   {
                //     required: true,
                //     message: 'This field is required',
                //   },
                // ]}
              >
              {renderOptionProductCategory(props.productCategories)}
              </Form.Item>
            </Col>
            <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
              <Form.Item
                name={['marketplace_product', 'price']}
                className="isoInputWrapper"
                label={<IntlMessages id="marketplacePage.productModal.price" />}
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
              >
                <InputNumber size="large" min={1} max={99999999} style={{width: '100%'}} step={0.1}/>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name={['marketplace_product', 'object_id']}
            className="isoInputWrapper"
            label={<IntlMessages id="marketplacePage.productModal.object"/>}
          >
            <InputNumber size="large" />
          </Form.Item>
          <Form.Item
            name={['marketplace_product', 'external_link']}
            className="isoInputWrapper"
            label={<IntlMessages id="marketplacePage.productModal.externalLink"/>}
          >
            <Input size="large" placeholder="Input title"/>
          </Form.Item>
          <Form.Item
            name={['marketplace_product', 'description']}
            className="isoInputWrapper"
            label={<IntlMessages id="marketplacePage.productModal.description"/>}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item name={['attachment']} label={<IntlMessages id="marketplacePage.productModal.imageGallery"/>}>
            <Upload
              {...uploadProps}
            >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
            </Upload>
          </Form.Item>
          <Row>
          {(productDetail && productDetail.images && productDetail.images.length > 0) ? (productDetail.images.map((value, index) => {
                    return (
                     <img className="image-gallery" style={{width:"100px", height:"100px"}} src={process.env.REACT_APP_API_BASE_URL + value.path} />
                    )
                  }  
                )
              ) : ''
            }
          </Row>
        </div>    
      </Form>
    </ProductModalWrapper>
  )
}

export default ProductModal;