
import React from "react";
import IntlMessages from "@iso/components/utility/intlMessages";
import { Form, Input, Button, Select, Row, Col, DatePicker, Card, Timeline, Upload, message } from 'antd';
import _ from "lodash";
import HazardViewModal from '../Hazards/HazardViewModal.styles'
import moment from "moment";


const { Option } = Select;

const LicenceViewModal = (props) => {
  const cancelModal = () => {
    props.setViewSummary(false);
  }
  return (

    <HazardViewModal
      className='visitor-modal'
      forceRender={true}
      open={props.viewSummary}
      title={props.title}
      onCancel={cancelModal}
      maskClosable={true}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="Close" />}
        </Button>,

      ]}
    >
      <div style={{
        paddingLeft: '10px',
        width: "95%", height: "400px"
      }}>
        <br />

        <Timeline>

          <Timeline.Item color="red" style={{ paddingLeft: '10px' }} >
            {props.qualificationData ? <p>{moment(props.qualificationData.created_at).format('ll')}</p> : ''}
            {props.licenceData ? <p>{moment(props.licenceData.created_at).format('ll')}</p> : ''}
            {props.emergencyData ?<p>{moment(props.emergencyData.created_at).format('ll')}</p>:''}
            {props.licenceData ?
              <Card title=
                {props.licenceData ?? props.licenceData ? moment(props.licenceData.licence_type_label).format('ll') : ''}
                bordered={true}>
                <Row>
                  <Col xs={{ span: 9 }} md={{ span: 9 }}>
                    <p className="card_content">User Id</p>
                  </Col>
                  <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                    <b className="card_value">{props.licenceData ? props.licenceData.user_id : ''} </b>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{ span: 9 }} md={{ span: 9 }}>
                    <p className="card_content">Date Issue</p>
                  </Col>
                  <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                    <b className="card_value">{props.licenceData ? moment(props.licenceData.date_issue).format('ll') : ''} </b>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{ span: 9 }} md={{ span: 9 }}>
                    <p className="card_content">Date Expiry</p>
                  </Col>
                  <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                    <b className="card_value">{props.licenceData ? moment(props.licenceData.date_expiry).format('ll') : ''}</b>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{ span: 9 }} md={{ span: 9 }}>
                    <p className="card_content">Licence Name</p>
                  </Col>
                  <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                    <b className="card_value">{props.licenceData ? props.licenceData.licence_name : ''}</b>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{ span: 9 }} md={{ span: 9 }}>
                    <p className="card_content">Updated At</p>
                  </Col>
                  <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                    <b className="card_value">{props.licenceData ? props.licenceData.updated_at : ''}</b>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{ span: 9 }} md={{ span: 9 }}>
                    <p className="card_content">Licence Type Label</p>
                  </Col>
                  <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                    <b className="card_value">{props.licenceData ? props.licenceData.licence_type_label : ''}</b>
                  </Col>
                </Row>
               
              </Card> : ''}



            {props.qualificationData ?
              <Card title=
                {props.qualificationData ?? props.qualificationData ? props.qualificationData.type_label : ''}
                bordered={true}>
                <Row>
                  <Col xs={{ span: 9 }} md={{ span: 9 }}>
                    <p className="card_content">User Id</p>
                  </Col>
                  <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                    <b className="card_value">{props.qualificationData ? props.qualificationData.user_id : ''} </b>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{ span: 9 }} md={{ span: 9 }}>
                    <p className="card_content">Updated Date</p>
                  </Col>
                  <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                    <b className="card_value">{props.qualificationData ? moment(props.qualificationData.updated_at
                    ).format('ll') : ''} </b>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{ span: 9 }} md={{ span: 9 }}>
                    <p className="card_content">Expiry Date</p>
                  </Col>
                  <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                    <b className="card_value">{props.qualificationData.date_expiry? moment(props.qualificationData.date_expiry).format('ll') : '--'}</b>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{ span: 9 }} md={{ span: 9 }}>
                    <p className="card_content"> Name</p>
                  </Col>
                  <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                    <b className="card_value">{props.qualificationData ? props.qualificationData.name: ''} </b>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{ span: 9 }} md={{ span: 9 }}>
                    <p className="card_content">Completion</p>
                  </Col>
                  <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                    <b className="card_value">{props.qualificationData ? moment(props.qualificationData.completion).format('ll') : ''}</b>
                  </Col>
                </Row>
                
                <Row>
                  <Col xs={{ span: 9 }} md={{ span: 9 }}>
                    <p className="card_content">Institution</p>
                  </Col>
                  <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                    <b className="card_value">{props.qualificationData ? props.qualificationData.institution : ''}</b>
                  </Col>
                </Row>
              
                
              </Card> : ''}

              {props.emergencyData ?
              <Card title=
                {props.emergencyData ?? props.emergencyData ? props.emergencyData.type : ''}
                bordered={true}>
                <Row>
                  <Col xs={{ span: 9 }} md={{ span: 9 }}>
                    <p className="card_content">User Id</p>
                  </Col>
                  <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                    <b className="card_value">{props.emergencyData ? props.emergencyData.user_id : '--'} </b>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{ span: 9 }} md={{ span: 9 }}>
                    <p className="card_content">Full Name</p>
                  </Col>
                  <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                    <b className="card_value">{props.emergencyData ? moment(props.emergencyData.full_name
                    ).format('ll') : '--'} </b>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{ span: 9 }} md={{ span: 9 }}>
                    <p className="card_content">Email</p>
                  </Col>
                  <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                    <b className="card_value">{props.emergencyData.email? props.emergencyData.email : '--'}</b>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{ span: 9 }} md={{ span: 9 }}>
                    <p className="card_content">Phone Number</p>
                  </Col>
                  <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                    <b className="card_value">{props.emergencyData ? props.emergencyData.phone_number: ''} </b>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{ span: 9 }} md={{ span: 9 }}>
                    <p className="card_content">Updated Date</p>
                  </Col>
                  <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                    <b className="card_value">{props.emergencyData ? moment(props.emergencyData.updated_at).format('ll') : ''}</b>
                  </Col>
                </Row>
                
                <Row>
                  <Col xs={{ span: 9 }} md={{ span: 9 }}>
                    <p className="card_content">Relationship</p>
                  </Col>
                  <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                    <b className="card_value">{props.emergencyData ? props.emergencyData.relationship : ''}</b>
                  </Col>
                </Row>
              
                
              </Card> : ''}
          </Timeline.Item>

        </Timeline>

      </div>
    </HazardViewModal>
  );
};

export default LicenceViewModal;
